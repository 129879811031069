<ng-container *ngIf="user && (onboarding$ | async) as onboardingItems">
  <a
    *ngIf="onboardingItems.data?.length > 0"
    [routerLink]="['/onboarding']"
    class="onboarding-helper"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="32px"
    fxLayout.lt-md="column"
    fxLayoutGap.lt-md="8px"
    [class.onboarding-helper-completed]="onboardingItems.meta.pending_count === 0">
    <!-- Has not yet completed any items -->
    <ng-container *ngIf="onboardingItems.meta.pending_count === onboardingItems.meta.count">
      <img fxHide.lt-md class="onboarding-user-pic" [src]="user.picture" [alt]="user.name" />

      <div fxFlex fxLayout="column" fxLayoutGap="10px">
        <h2>{{ user.first_name }}, let's get started!</h2>
        <span class="grass-darker">
          Follow {{ onboardingItems.meta.pending_count }} simple steps to master the basics of
          {{ 'CORE.PRODUCT_NAME' | transloco }}.
        </span>
      </div>

      <div fxFlex="214px" fxFlex.lt-md="auto">
        <sbnb-button type="primary-teal" label="Get started" [fullWidth]="true"></sbnb-button>
      </div>
    </ng-container>

    <!-- Has completed some items -->
    <ng-container
      *ngIf="
        onboardingItems.meta.pending_count > 0 && onboardingItems.meta.pending_count !== onboardingItems.meta.count
      ">
      <img fxHide.lt-md class="onboarding-user-pic" [src]="user.picture" [alt]="user.name" />

      <div fxFlex fxLayout="column" fxLayoutGap="10px">
        <h2>{{ user.first_name }}, onwards and upwards!</h2>
        <span class="grass-darker">
          Complete the {{ onboardingItems.meta.pending_count }} remaining steps to master the basics of
          {{ 'CORE.PRODUCT_NAME' | transloco }}.
        </span>
      </div>

      <div fxFlex="214px" fxFlex.lt-md="auto">
        <sbnb-button type="primary-teal" label="See remaining items" [fullWidth]="true"></sbnb-button>
      </div>
    </ng-container>

    <!-- Has completed all items -->
    <ng-container *ngIf="onboardingItems.meta.pending_count === 0 && onboardingItems.data.length > 0">
      <img
        fxHide.lt-md
        class="onboarding-user-pic onboarding-badge-complete"
        src="/assets/images/onboarding/hospitable-badge.gif"
        alt="Hospitable badge" />

      <div fxFlex fxLayout="column" fxLayoutGap="10px">
        <h2>Congratulations, {{ user.first_name }}!</h2>
        <span class="grass-darker">
          You have received your Hospitable badge already, but you can revisit the onboarding at any time.
        </span>
      </div>

      <div fxFlex="214px" fxFlex.lt-md="auto">
        <sbnb-button type="primary-teal" label="Revisit the checklist" [fullWidth]="true"></sbnb-button>
      </div>
    </ng-container>
  </a>
</ng-container>
