<ng-container *ngIf="reservation">
  <section>
    <div class="flex flex-col gap-1">
      <div class="flex justify-between items-center gap-2">
        <div class="text-night-darker strong text-sm">Upsells</div>
      </div>

      <div *ngIf="reservation.upsells.products.length > 0">
        <div class="text-night-lighter text__small">Purchased:</div>

        <div *ngFor="let product of reservation.upsells.products">
          <span class="strong text-sm">
            {{ product.type == 'early_checkin' ? 'Early check-in - ' : '' }}
            {{ product.type == 'late_checkout' ? 'Late checkout - ' : '' }}
            {{ product.name }}
          </span>
        </div>
      </div>

      <div *ngIf="reservation.upsells.products.length === 0">
        <div class="text-night-lighter text__small">No upsells purchased yet.</div>
      </div>
    </div>
  </section>
</ng-container>
