import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  standalone: false,
  selector: 'sbnb-full-screen-modal',
  templateUrl: './full-screen-modal.component.html',
  styleUrls: ['./full-screen-modal.component.scss'],
})
export class FullScreenModalComponent implements OnInit {
  width = '640px';
  @Input() closeLink: string = null;
  @Input() goBack?: boolean = undefined;

  constructor(
    private readonly route: ActivatedRoute,
    public location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.data['width']) {
      this.width = this.route.snapshot.data['width'];
    }

    if (!this.closeLink) {
      let closeLink = this.route.snapshot.queryParamMap.get('closeLink') ?? this.route.snapshot.data['closeLink'];

      // Replace any :param in the closeLink with actual route params
      if (closeLink) {
        Object.keys(this.route.snapshot.params).forEach((param) => {
          closeLink = closeLink.replace(`:${param}`, this.route.snapshot.params[param]);
        });
      }

      this.closeLink = closeLink;
    }

    if (this.goBack === undefined) {
      const goBack = this.route.snapshot.queryParamMap.get('goBack') ?? this.route.snapshot.data['goBack'];
      this.goBack = goBack ?? false;
    }
  }

  navigateBack() {
    if (this.closeLink) {
      this.router.navigate([this.closeLink], { replaceUrl: true });
    }
  }
}
