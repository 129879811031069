<div class="flex flex-wrap gap-2">
  <button
    *ngFor="let tag of tags"
    type="button"
    (click)="toggleTag(tag.key)"
    [class]="[
      'tag-pill',
      isSelected(tag.key) ? 'selected' : 'unselected'
    ]"
    [disabled]="disabled"
    data-test="review-tag-pill">
    {{tag.description}}
  </button>
</div>
