<section fxLayoutAlign="end center">
  <sbnb-close [border]="true" (click)="dialogRef.close()"></sbnb-close>
</section>

<section class="w-full h-full flex justify-center items-center">
  <mat-spinner *ngIf="loading" [diameter]="32"></mat-spinner>

  <div class="fs-dialog-contents" *ngIf="!loading && property && stage === 1">
    <h2 class="mb-5">This property will be {{ dialogData.mode === 'mute' ? 'muted' : 'unmuted' }}</h2>

    <sbnb-property-card *ngIf="property?.id" [property]="property"></sbnb-property-card>

    <p class="mt-8 night-darker text__medium">What does this mean:</p>

    <!-- Help texts for a property about to be muted -->
    <div *ngIf="dialogData.mode === 'mute'">
      <ul>
        <li>
          All {{ 'CORE.PRODUCT_NAME' | transloco }} features, including automatic messaging, will stop working for this
          property immediately.
        </li>
        <li *ngIf="billing?.current_period_end">
          The property will be excluded from your next invoice on
          <span class="text__medium">{{ billing.current_period_end | date: 'mediumDate' }}</span>
          .
        </li>
        <li *ngIf="ownerStatementsEnabled$ | async">
          Unpublished Owner Statements for this property will be deleted, and new Statements will not be generated.
        </li>
        <li>You can unmute the property at any point in time.</li>
      </ul>

      <mat-checkbox class="text__medium night" [(ngModel)]="confirmCheckbox">
        I confirm I understand the above and wish to mute the property.
      </mat-checkbox>
    </div>

    <!-- Help text for a property to be unmuted -->
    <div *ngIf="dialogData.mode === 'unmute'">
      <ul>
        <li>
          All {{ 'CORE.PRODUCT_NAME' | transloco }} features, including automatic messaging, will be activated for this
          property immediately.
        </li>
        <li>{{ 'CORE.PRODUCT_NAME' | transloco }} will generate scheduled messages for upcoming reservations.</li>
        <li>
          If the property is active, it will be included in your next invoice on
          <span class="text__medium">{{ billing.current_period_end | date: 'mediumDate' }}</span>
          .
        </li>
      </ul>
    </div>

    <div class="flex justify-end items-center gap-4 mt-9">
      <sbnb-button type="secondary" label="Cancel" (click)="dialogRef.close()"></sbnb-button>

      <!-- Mute/Unmute button -->
      <sbnb-button
        type="primary"
        [label]="dialogData.mode === 'mute' ? 'Mute' : 'Unmute'"
        [loading]="muting"
        [disabled]="dialogData.mode === 'mute' && !confirmCheckbox"
        [loadingLabel]="dialogData.mode === 'mute' ? 'Muting...' : 'Unmuting...'"
        (click)="muteClicked()"></sbnb-button>
    </div>
  </div>

  <div *ngIf="stage === 2" class="fs-dialog-contents flex flex-col justify-start items-center gap-6 text-center">
    <sbnb-icon classes="text-grass-darker" icon="check-circle-overflow" [size]="80"></sbnb-icon>

    <h2 class="nomargin">The property is unmuted.</h2>

    <p>You can review the rules that apply to this property via the property onboarding assistant.</p>

    <sbnb-button (click)="openOnboarding()" label="Onboarding assistant"></sbnb-button>
  </div>
</section>
