import { Component, OnInit } from '@angular/core';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';

@Component({
  standalone: false,
  selector: 'sbnb-android-prominent-disclosure',
  templateUrl: './android-prominent-disclosure.component.html',
  styleUrls: ['./android-prominent-disclosure.component.scss'],
})
export class AndroidProminentDisclosureComponent implements OnInit {
  isAndroid: boolean;

  constructor(private deviceDetectionService: DeviceDetectionService) { }

  ngOnInit(): void {
    this.isAndroid = this.deviceDetectionService.isAndroidApp();
  }
}
