<div class="initiated-by-container">
  <h2 class="nomargin">Did the guest request this cancellation?</h2>
  <p>We will use this to determine how your cancellation policy would apply.</p>

  <div fxLayout="column" fxLayoutGap="24px" class="selection-container">
    <div fxLayout fxLayoutGap="16px">
      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="12px"
        class="initiated-by-selector"
        [class.selected]="initiatedBy === 'guest'"
        (click)="initiatedBy = 'guest'">
        <h3>Yes</h3>
        <span class="text__small">The guest would like to cancel this reservation.</span>
      </div>

      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="12px"
        class="initiated-by-selector"
        [class.selected]="initiatedBy === 'host'"
        (click)="initiatedBy = 'host'">
        <h3>No</h3>
        <span class="text__small">The host would like to cancel this reservation.</span>
      </div>
    </div>

    <div fxLayoutAlign="end center">
      <sbnb-button
        label="Continue"
        [disabled]="initiatedBy === null"
        (clicked)="continue.emit(initiatedBy)"></sbnb-button>
    </div>
  </div>
</div>
