import { Component, Input } from '@angular/core';
import { ThreadPayload } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-additional-guests-badge',
  templateUrl: './additional-guests-badge.component.html',
  styleUrls: ['./additional-guests-badge.component.scss'],
})
export class AdditionalGuestsBadgeComponent {
  @Input() guests: ThreadPayload['guests'];

  constructor() { }
}
