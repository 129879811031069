import { Pipe, PipeTransform } from '@angular/core';
import { isFuture } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isFuture',
})
export class IsFuturePipe implements PipeTransform {
  transform(value: Date | string): boolean {
    return isFuture(value);
  }
}
