export class Filter {
  id: string;
  uuid?: string;
  label: string;
  type: 'list' | 'date' | 'number' | 'static' | 'togglable';
  values?: string; // the href to retrieve the values
  value?: string | number | Date;
  loading?: boolean;
  lockable: boolean; // Can this filter be toggled off by the user?
  inverted?: boolean;
  valuesData?: {
    // We use this to store the responses from the API for allowable values
    key: string;
    label: string;
    img?: string;
  }[];
  selectedValue?: {
    key: string;
    label: string;
    img?: string;
    number?: number;
  };
}
