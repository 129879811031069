import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, PaginationMeta, PropertyList } from '@app/shared/interfaces';
import { Condition } from '@app/shared/interfaces/lib/custom-code-conditions.interface';
import { Channel } from '@app/shared/models/channel';
import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { environment } from '@env/environment';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export interface CreateReservationAgreementTemplatePayload {
  name: string;
  content: string;
  property_ids: number[];
  min_number_of_nights: number | null;
  max_number_of_nights: number | null;
}

export interface ReservationAgreementTemplate {
  is_active: boolean;
  uuid: string;
  name: string;
  content: string;
  version: number;
  property_ids: number[];
  platforms: Channel[];
  min_number_of_nights: number | null;
  max_number_of_nights: number | null;

  // conditions:
  //   | {
  //       key: ConditionKey;
  //       operator: ConditionOperator;
  //       value: number | string | boolean;

  //       // computed
  //       computed?: {
  //         operator_label?: string | null;
  //       };
  //     }[]
  //   | null;

  // computed on FE
  property_list?: PropertyList[];
  unscopedProperties?: number[];
}

export interface ReservationAgreement {
  uuid: string;
  reservation_code: string;
  template_version: number;
  template_uuid: string;
  name: string;
  content: string;
  signatory: string;
  signed_at: string;
  pdf_url: string;
  reservation: {
    check_in_date: string;
    check_out_date: string;
    guest: { name: string; picture: string; email: string; phone: string };
    email: string;
    name: string;
    phone: string;
    picture: string;
    reservation_code: string;
    thread_uuid: string;
  };
}

export interface ReservationAgreementConditionEntities {
  [key: string]: Condition;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationAgreementService {
  constructor(
    private http: HttpClient,
    private propertiesService: PropertiesService
  ) {}

  apiUrl = environment.apiUrl;

  private mergeProperties(items: any, properties: any[], itemKey?: string | null) {
    return items.map((item) => {
      const propertyIds = itemKey ? item[itemKey] : item.properties;
      item.property_list = propertyIds
        .map((propertyId) => {
          if (properties && properties.length > 0) {
            const property = properties.find((p) => p.id === propertyId);
            return property
              ? {
                  id: property.id,
                  name: property.name,
                  picture: property.picture,
                }
              : null;
          }

          return null;
        })
        .filter((property) => property !== null);
      return item;
    });
  }

  fetchReservationAgreementTemplates(): Observable<ReservationAgreementTemplate[]> {
    const agreements$ = this.http.get<{ data: ReservationAgreementTemplate[] }>(
      `${this.apiUrl}/reservation-agreement-templates`
    );

    const properties$ = this.propertiesService
      .getProperties({
        paginate: false,
        transformer: 'simple',
      })
      .pipe(map((res: { data: any[] }) => res.data));

    return combineLatest([agreements$, properties$]).pipe(
      map(([agreementsResponse, properties]) => {
        return this.mergeProperties(agreementsResponse.data, properties, 'property_ids');
      })
    );
  }

  fetchReservationAgreementTemplate(uuid: string): Observable<{
    agreement: ReservationAgreementTemplate;
    meta: {
      confirmation_rulesets_missing_guestportal_shortcode: { id: number; name: string }[];
    };
  }> {
    return this.http
      .get<{
        data: ReservationAgreementTemplate;
        meta: { confirmation_rulesets_missing_guestportal_shortcode: { id: number; name: string }[] };
      }>(`${this.apiUrl}/reservation-agreement-templates/${uuid}`)
      .pipe(
        map((res) => {
          return {
            agreement: res.data,
            meta: res.meta,
          };
        })
      );
  }

  createNewReservationAgreementTemplate(
    agreement: CreateReservationAgreementTemplatePayload
  ): Observable<ApiResponse<ReservationAgreementTemplate>> {
    return this.http.post<ApiResponse<ReservationAgreementTemplate>>(
      `${this.apiUrl}/reservation-agreement-templates`,
      agreement
    );
  }

  updateReservationAgreement(
    agreement: ReservationAgreementTemplate
  ): Observable<ApiResponse<ReservationAgreementTemplate>> {
    return this.http.put<ApiResponse<ReservationAgreementTemplate>>(
      `${this.apiUrl}/reservation-agreement-templates/${agreement.uuid}`,
      agreement
    );
  }

  deleteReservationAgreementTemplate(uuid: string) {
    return this.http.delete(`${this.apiUrl}/reservation-agreement-templates/${uuid}`);
  }

  fetchReservationAgreements(
    signed: boolean,
    searchCriteria: string,
    page: number
  ): Observable<{ data: ReservationAgreement[]; meta: { pagination: PaginationMeta } }> {
    const limit = 20;

    const agreements$ = this.http.get<{ data: ReservationAgreement[]; meta: { pagination: PaginationMeta } }>(
      `${this.apiUrl}/reservation-agreements?signed=${signed}&limit=${limit}&query=${searchCriteria}&offset=${(page - 1) * limit}`
    );

    return agreements$;
  }

  deleteReservationAgreement(reservationCode: string, agreementUuid: string) {
    return this.http.delete(`${this.apiUrl}/reservations/${reservationCode}/agreements/${agreementUuid}`);
  }

  // Fetch rental agreements for a reservation
  // Includes pending and signed agreements
  fetchRentalAgreementsForReservation(reservationUuid: string): Observable<ReservationAgreement[]> {
    return this.http
      .get<{ data: ReservationAgreement[] }>(`${this.apiUrl}/reservations/${reservationUuid}/agreements`)
      .pipe(map((res) => res.data));
  }

  requestRentalAgreement(reservationUuid: string, templateUuid: string, message: string) {
    return this.http
      .post<{
        data: ReservationAgreement;
      }>(`${this.apiUrl}/reservations/${reservationUuid}/agreement-templates/${templateUuid}/request`, {
        message,
      })
      .pipe(map((res) => res.data));
  }

  previewAgreement(content: string, reservationUUID: string) {
    return this.http.post(`${this.apiUrl}/reservation-agreement-templates/preview`, {
      content,
      reservation_uuid: reservationUUID,
    });
  }
}
