<div
  class="additional-guests-badge box-shadow"
  [satPopoverAnchor]="p"
  (mouseenter)="p.open()"
  (click)="p.open()"
  (mouseleave)="p.close()">
  +{{ guests.length - 1 }}
</div>

<sat-popover verticalAlign="below" horizontalAlign="center" #p>
  <div class="popover">
    <strong class="additional-guests-badge__heading">Guests in this thread</strong>
    <div *ngFor="let guest of guests">
      <div class="flex items-center">
        <sbnb-profile-img
          *ngIf="guest.picture"
          class="popover-guest-picture"
          [src]="guest.picture"
          [style]=""></sbnb-profile-img>

        <sbnb-profile-img-placeholder
          *ngIf="!guest.picture"
          class="popover-guest-placeholder"
          [firstName]="guest.first_name"
          [lastName]="guest.last_name"></sbnb-profile-img-placeholder>

        <div>
          {{ guest.first_name }} {{ guest.last_name }}
          <span class="night-lighter" *ngIf="guest.is_additional_guest">(Additional guest)</span>
        </div>
      </div>
    </div>
  </div>
</sat-popover>
