import { Component, Input, OnInit } from '@angular/core';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { TasksService } from '@app/shared/services/tasks/tasks.service';

@Component({
  standalone: false,
  selector: 'sbnb-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  selectedTask: any;
  loading: boolean;

  perms$ = this.userPermissionsService;
  Features = Features;

  @Input() threadUuid: string;
  @Input() lite: boolean;
  @Input() reservation: any;
  @Input() tasks: any[] = []; // Alternatively, provide your own tasks

  constructor(
    private readonly taskService: TasksService,
    private readonly userPermissionsService: UserPermissionsService
  ) { }

  ngOnInit() {
    if (!this.tasks || this.tasks.length === 0) {
      this.getTasks();
    }
  }

  getTasks() {
    this.loading = true;
    this.taskService.getTasksForThread(this.threadUuid).subscribe((res) => {
      this.tasks = res;
      this.loading = false;
    });
  }

  taskAssigned(newTaskData) {
    if (newTaskData.name) {
      const index = this.tasks.findIndex((x) => x.name === newTaskData.name);

      if (index > -1) {
        this.tasks[index] = newTaskData;
      }
    }
  }

  taskCreated(newTaskData) {
    this.tasks.push(newTaskData);
  }

  removeTask(uuid: string) {
    const index = this.tasks.findIndex((x) => x.uuid === uuid);

    if (index > -1) {
      this.tasks.splice(index, 1);
    }
  }
}
