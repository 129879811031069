import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-icon-phone',
  templateUrl: './icon-phone.component.html',
  styleUrls: ['./icon-phone.component.scss'],
})
export class IconPhoneComponent implements OnInit {
  @Input() color = '#ED3C6A';

  constructor() { }

  ngOnInit() { }
}
