import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingState } from '@app/shared/interfaces';
import { NewDirectCustomQuote, QuoteExpirations } from '@app/shared/services/direct-quotes/direct-quotes.service';
import { ConvertEnumToArray } from '@app/shared/utils';

@Component({
  standalone: false,
  selector: 'sbnb-direct-quote-hold-duration',
  templateUrl: './direct-quote-hold-duration.component.html',
  styleUrls: ['./direct-quote-hold-duration.component.scss'],
})
export class DirectQuoteHoldDurationComponent implements OnInit {
  @Input() quote: NewDirectCustomQuote;
  @Input() loadingState: LoadingState;
  @Output() next = new EventEmitter<NewDirectCustomQuote>();
  @Output() goBack = new EventEmitter<any>();

  LoadingState = LoadingState;
  expirations = ConvertEnumToArray<QuoteExpirations>(QuoteExpirations);

  constructor() { }

  ngOnInit(): void { }
  onNext() {
    this.next.emit(this.quote);
  }

  onGoBack() {
    this.goBack.emit();
  }
}
