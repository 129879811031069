<button
  mat-flat-button
  [type]="htmlButtonType"
  [class.button__primary]="type === 'primary' || type === 'primary-teal' || type === 'primary-grape'"
  [class.button__secondary]="type === 'secondary'"
  [class.button__text]="type === 'text'"
  [class.button__text-secondary]="type === 'text-secondary'"
  [class.button__small]="size === 'mini'"
  [class.button__primary-teal]="type === 'primary-teal'"
  [class.button__primary-grape]="type === 'primary-grape'"
  [class.button__secondary-danger]="type === 'secondary-danger'"
  [style.color]="labelColor"
  [class.disabled-btn]="loading || disabled"
  [disabled]="loading || disabled"
  [style.width]="_width"
  (click)="clicked.emit()">
  <sbnb-icon
    *ngIf="icon"
    [size]="18"
    class="button-icon mr-2"
    [ngClass]="{ 'preserve-color': preserveIconColor }"
    [icon]="icon"
    [style.color]="labelColor"></sbnb-icon>

  <span class="label">{{ loadingLabel && loading ? loadingLabel : label }}</span>

  <ng-content></ng-content>

  <span
    *ngIf="splitMenu"
    class="inline-flex items-center"
    [ngClass]="{ 'ml-3 pl-2': size === 'mini', 'ml-4 pl-3': size === 'normal' }">
    <span
      class="absolute -top-[1px] right-0 z-10 border-l"
      [ngClass]="{ 'h-8 w-9': size === 'mini', 'h-12 w-[58px]': size === 'normal' }"
      [matMenuTriggerFor]="splitMenu"
      (menuOpened)="menuIsOpen = true"
      (menuClosed)="menuIsOpen = false"
      (click)="$event.stopPropagation()"></span>
    <sbnb-icon
      [size]="size === 'mini' ? 16 : 20"
      class="button-icon"
      [class.rotate-180]="menuIsOpen"
      [style.color]="labelColor"
      icon="arrow-down-raspberry"></sbnb-icon>
  </span>

  <mat-spinner
    *ngIf="loading"
    class="button__spinner"
    [class.button__spinner-teal]="type === 'primary-teal'"
    [class.button__spinner-secondary]="type === 'secondary'"
    [diameter]="size === 'mini' ? 16 : 30"></mat-spinner>
</button>
