import { Component, Input, OnInit } from '@angular/core';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';

@Component({
  standalone: false,
  selector: 'sbnb-devices-subnav',
  templateUrl: 'devices-subnav.component.html',
  styleUrls: ['./devices-subnav.component.scss'],
})
export class DevicesSubnavComponent implements OnInit {
  @Input() demo = false;
  hasWritePermission$ = this.permissionsService.hasAccessTo(Features.PERM_SMARTLOCKS_DASHBOARD_WRITE);

  constructor(private permissionsService: UserPermissionsService) {}

  ngOnInit() {}
}
