<div class="night">
  <div class="guest-reviews__title">
    <strong>Latest Reviews</strong>
    <sbnb-prev-next [count]="reviews?.length" (activeIndexChange)="onReviewChange($event)"></sbnb-prev-next>
  </div>
  <div *ngIf="activeReview$ | async as review; else noReviews" class="text-sm">
    {{ review.comment }}
  </div>
</div>
<ng-template #noReviews>
  <div class="night-lighter text-sm">No reviews available for this guest.</div>
</ng-template>
