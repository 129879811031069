import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarRef as MatSnackBarRef,
  LegacyTextOnlySnackBar as TextOnlySnackBar,
} from '@angular/material/legacy-snack-bar';
import { NotificationType, NotificationVerticalPosition } from '@app/shared/interfaces';
import { countWords } from '@app/shared/utils';

interface Notification {
  message: string;
  action: string;
  type: NotificationType;
  durationOverride?: number;
}
@Injectable({
  providedIn: 'root',
})
export class ToastNotificationsService {
  private messages: Notification[] = [];
  private currentSnackBarRef: MatSnackBarRef<TextOnlySnackBar> | null = null;

  constructor(private snackbar: MatSnackBar) {}

  private showNextMessage(): void {
    if (this.messages.length === 0 || this.currentSnackBarRef) return;

    const nextMessage = this.messages.shift();

    this.display(
      nextMessage.message,
      nextMessage.action,
      nextMessage.type,
      nextMessage.durationOverride ? nextMessage.durationOverride : this.getToastDuration(nextMessage.message)
    );
  }

  private display(
    message: string,
    action = 'Dismiss',
    type: NotificationType,
    durationOverride?: number
  ): MatSnackBarRef<TextOnlySnackBar> {
    this.currentSnackBarRef = this.snackbar.open(message, action, {
      duration: durationOverride ? durationOverride : this.getToastDuration(message),
      verticalPosition: NotificationVerticalPosition.Top,
      panelClass: [type],
    });

    this.currentSnackBarRef.afterDismissed().subscribe(() => {
      this.currentSnackBarRef = null;
      this.showNextMessage();
    });

    return this.currentSnackBarRef;
  }

  open(
    message: string,
    action = 'Dismiss',
    type: NotificationType,
    durationOverride?: number
  ): MatSnackBarRef<TextOnlySnackBar> {
    const notification: Notification = { message, action, type, durationOverride };

    if (!this.currentSnackBarRef) {
      return this.display(message, action, type, durationOverride);
    }

    // Otherwise, add to queue
    this.messages.push(notification);
    return this.currentSnackBarRef;
  }

  notificationOpen(
    message: string,
    config: MatSnackBarConfig = {
      duration: 5000,
      verticalPosition: 'top',
    }
  ) {
    this.snackbar.open(message, '', config);
  }

  public success(message: string) {
    return this.open(message, 'Dismiss', NotificationType.Success);
  }

  public error(message: string) {
    return this.open(message, 'Dismiss', NotificationType.Error);
  }

  // Gets toast duration based on the amount of words in the message
  getToastDuration(message: string) {
    const minDuration = 8000;
    const msPerWord = 750;
    const wordCount = countWords(message);
    const duration = wordCount ? wordCount * msPerWord : minDuration;
    return duration > minDuration ? duration : minDuration;
  }
}
