<div class="calendar flex-col" [class.selectMode]="selectMode">
  <div class="month-nav flex">
    <button class="calendar__month-button" (click)="prevMonth()">
      <img src="/assets/iconography/small-arrow-left.svg" alt="Previous month" />
    </button>

    <div class="flex-1">
      <div class="calendar__month-label">
        {{ formatDate(currentDate, 'MMMM') }}
        {{ formatDate(currentDate, 'YYYY') }}
      </div>
    </div>

    <button class="calendar__month-button" (click)="nextMonth()">
      <img src="/assets/iconography/small-arrow-right.svg" alt="Next month" />
    </button>
  </div>

  <div class="month-grid flex-1">
    <div class="day-names flex justify-around">
      <div *ngFor="let name of dayNames" class="day-name text__xxsmall flex-1 flex items-center justify-center">
        {{ name }}
      </div>
    </div>
    <div class="weeks">
      <div *ngFor="let week of weeks" class="week flex justify-around">
        <div
          (click)="selectDateClick(day.date)"
          *ngFor="let day of week"
          class="date-text flex-1 flex items-center justify-center"
          [ngClass]="{
            'not-my-month': !isDayInSelectedMonth(day.date),
            'is-selected': day.selected,
            'is-primary': day.this_thread,
            'is-start': day.start,
            'is-end': day.end,
            unselectable:
              ((day.date | isBeforeToday) && !selectInPastAllowed) ||
              ((day.date | isAfterToday) && !selectInFutureAllowed)
          }">
          {{ formatDate(day.date, 'D') }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center items-center mt-5">
    <sbnb-button
      *ngIf="showTodayButton"
      label="Today"
      size="mini"
      type="secondary"
      (click)="selectDateClick()"></sbnb-button>
  </div>
</div>
