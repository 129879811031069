import { Component, Input, OnInit } from '@angular/core';
import { NoteType } from './note.interface';

@Component({
  standalone: false,
  selector: 'sbnb-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {
  @Input() id: string;
  @Input() content: string;
  @Input() type: NoteType = NoteType.Info;
  @Input() iconSrc: string;
  @Input() isDismissible: boolean = false;
  NoteType = NoteType;
  isDismissed = false;
  localStoragePrefix = 'note-';
  closeColor: string = '#B3AFBB';

  constructor() { }

  ngOnInit() {
    this.checkIsDismissed();
  }

  checkIsDismissed() {
    this.isDismissed = localStorage.getItem(this.localStoragePrefix + this.id) ? true : false;
  }

  dismiss() {
    localStorage.setItem(this.localStoragePrefix + this.id, 'true');
    this.checkIsDismissed();
  }
}
