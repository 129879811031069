import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'hasVisibleSegments',
})
export class HasVisibleSegmentsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const count = value.filter((x) => x.hidden === false);
    return count.length > 0;
  }
}
