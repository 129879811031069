import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SegmentEvent, SegmentIoService } from '../segmentIo/segment-io.service';

export interface ApiError {
  error: boolean;
  code: number;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class PropertyMutingService {
  constructor(
    private readonly http: HttpClient,
    private readonly segmentIoService: SegmentIoService
  ) { }

  public fetchPropertyAndBilling(propertyId: number): Observable<any | ApiError> {
    const property$ = this.http.get(`${config.API_URL}/properties/${propertyId}`);
    const billing$ = this.http.get(`${config.API_URL}/settings/billing/subscription`);

    return forkJoin([property$, billing$]).pipe(
      map((res: any) => {
        const result = {
          property: null,
          billing: null,
        };

        // Add property data
        result.property = res[0].data;

        // Add billing data
        result.billing = res[1];

        return result;
      }),
      catchError((error) => this.handleMutingError(error))
    );
  }

  public muteProperty(propertyId: number): Observable<Record<string, unknown> | ApiError> {
    return this.http.put(`${config.API_URL}/properties/${propertyId}/mute`, {}).pipe(
      map((res: any) => {
        this.segmentIoService.track(SegmentEvent.PropertyMuted, {});
        return res;
      }),
      catchError((error) => this.handleMutingError(error))
    );
  }

  public unmuteProperty(propertyId: number): Observable<Record<string, unknown> | ApiError> {
    return this.http.put(`${config.API_URL}/properties/${propertyId}/unmute`, {}).pipe(
      map((res: any) => {
        this.segmentIoService.track(SegmentEvent.PropertyUnmuted, {});
        return res;
      }),
      catchError(this.handleMutingError)
    );
  }

  public mutePropertiesParallel(propertyIds: number[]) {
    return forkJoin(
      propertyIds.map((propertyId) => {
        return this.muteProperty(propertyId);
      })
    );
  }

  private handleMutingError(httpError: HttpErrorResponse | any) {
    const error: ApiError = {
      error: true,
      code: httpError.error.code || 0,
      message: httpError.error.message,
    };

    this.segmentIoService.track(SegmentEvent.PropertyMutingError, {});

    return of(error);
  }
}
