<mat-menu class="filters__cdk-menu-new" (closed)="modalClose()">
  <div *ngIf="workingFilter" (click)="$event.stopPropagation()" class="filters__cdk-menu-new-inner">
    <!-- This date filter has the ability to select from multiple fields -->
    <ng-container *ngIf="workingFilter.columns && workingFilter.columns.length > 0">
      <mat-form-field class="input__mini fw">
        <mat-select
          [(ngModel)]="workingFilter.initial_payload.column"
          (ngModelChange)="this.strategies = findStrategies()"
          placeholder="Select a date field">
          <mat-option *ngFor="let option of workingFilter.columns" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!-- Managing date strategies -->
    <div>
      <mat-radio-group
        [(ngModel)]="workingFilter.initial_payload.strategy"
        (ngModelChange)="provideDefaults(workingFilter.initial_payload.strategy)"
        class="filters__date-menu">
        <!-- All time strategy (if enabled) -->
        <mat-radio-button
          *ngIf="strategies.indexOf(FilterStrategy.AllTime) > -1"
          fxFlexFill
          [value]="FilterStrategy.AllTime">
          All time
        </mat-radio-button>

        <!-- Today strategy (if enabled) -->
        <mat-radio-button
          *ngIf="strategies.indexOf(FilterStrategy.Today) > -1"
          fxFlexFill
          [value]="FilterStrategy.Today">
          Today
        </mat-radio-button>

        <!-- Yesterday strategy (if enabled) -->
        <mat-radio-button
          *ngIf="strategies.indexOf(FilterStrategy.Yesterday) > -1"
          fxFlexFill
          [value]="FilterStrategy.Yesterday">
          Yesterday
        </mat-radio-button>

        <!-- Tomorrow strategy (if enabled) -->
        <mat-radio-button
          *ngIf="strategies.indexOf(FilterStrategy.Tomorrow) > -1"
          fxFlexFill
          [value]="FilterStrategy.Tomorrow">
          Tomorrow
        </mat-radio-button>

        <!-- Current strategy (if enabled) -->
        <ng-container *ngIf="strategies.indexOf(FilterStrategy.Current) > -1">
          <mat-radio-button fxFlexFill [value]="FilterStrategy.Current">Current ...</mat-radio-button>

          <mat-form-field
            class="input__mini fw mb-2"
            *ngIf="workingFilter.initial_payload.strategy === FilterStrategy.Current">
            <mat-select [(ngModel)]="workingFilter.initial_payload.interval_type">
              <mat-option *ngFor="let option of dateIntervalTypes" [value]="option">
                {{ option | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <!-- Past strategy (if enabled) -->
        <ng-container *ngIf="strategies.indexOf(FilterStrategy.Past) > -1">
          <mat-radio-button fxFlexFill [value]="FilterStrategy.Past">Last ...</mat-radio-button>

          <div *ngIf="workingFilter.initial_payload.strategy === FilterStrategy.Past" fxLayout fxLayoutGap="12px">
            <mat-form-field class="input__mini fw mb-2">
              <!-- // Interval length input -->
              <input matInput type="number" [(ngModel)]="workingFilter.initial_payload.interval_length" />
            </mat-form-field>

            <mat-form-field class="input__mini fw mb-2">
              <mat-select [(ngModel)]="workingFilter.initial_payload.interval_type">
                <mat-option *ngFor="let option of dateIntervalTypes" [value]="option">
                  {{ option | titlecase }}{{ workingFilter.initial_payload.interval_length > 1 ? 's' : null }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <!-- Upcoming strategy (if enabled) -->
        <ng-container *ngIf="strategies.indexOf(FilterStrategy.Upcoming) > -1">
          <mat-radio-button fxFlexFill [value]="FilterStrategy.Upcoming">Upcoming ...</mat-radio-button>

          <div *ngIf="workingFilter.initial_payload.strategy === FilterStrategy.Upcoming" fxLayout fxLayoutGap="12px">
            <mat-form-field class="input__mini fw mb-2">
              <!-- // Interval length input -->
              <input matInput type="number" [(ngModel)]="workingFilter.initial_payload.interval_length" />
            </mat-form-field>

            <mat-form-field class="input__mini fw mb-2">
              <mat-select [(ngModel)]="workingFilter.initial_payload.interval_type">
                <mat-option *ngFor="let option of dateIntervalTypes" [value]="option">
                  {{ option | titlecase }}{{ workingFilter.initial_payload.interval_length > 1 ? 's' : null }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <!-- Custom strategy (if enabled) -->
        <ng-container
          *ngIf="
            strategies.indexOf(FilterStrategy.Custom) > -1 ||
            strategies.indexOf(FilterStrategy.CustomPastOnly) > -1 ||
            strategies.indexOf(FilterStrategy.CustomFutureOnly) > -1
          ">
          <mat-radio-button fxFlexFill [value]="FilterStrategy.Custom">Custom</mat-radio-button>

          <div *ngIf="workingFilter.initial_payload.strategy === FilterStrategy.Custom">
            <hr />
            <sbnb-mini-calendar
              [selectMode]="true"
              [selectInFutureAllowed]="
                strategies.indexOf(FilterStrategy.CustomFutureOnly) > -1 ||
                strategies.indexOf(FilterStrategy.Custom) > -1
                  ? true
                  : false
              "
              [selectInPastAllowed]="
                strategies.indexOf(FilterStrategy.CustomPastOnly) > -1 || strategies.indexOf(FilterStrategy.Custom) > -1
                  ? true
                  : false
              "
              (dateSelected)="customDatesSelected($event)"></sbnb-mini-calendar>
          </div>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>

  <button class="filter__btn-done">Done</button>
</mat-menu>
