<div *ngIf="reactions.length" fxFlex fxLayout="row" fxLayoutGap="4px" class="message-reactions">
  <div
    *ngFor="let group of reactionsGroups"
    class="message-reaction"
    [class.message-reaction--guest]="isGuestMessage"
    [class.message-reaction--host]="!isGuestMessage">
    {{ group.emoji }}

    <span *ngIf="group.reactions.length > 1" class="message-reaction__label">
      {{ group.reactions.length }}
    </span>
  </div>
</div>
