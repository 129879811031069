import { Component, Input, OnInit } from '@angular/core';
import { Article } from '../../../modules/support-documentation/models/support-documentation';

@Component({
  standalone: false,
  selector: 'sbnb-article-link',
  templateUrl: './article-link.component.html',
  styleUrls: ['./article-link.component.scss'],
})
export class ArticleLinkComponent implements OnInit {
  @Input() article: Article;

  constructor() { }

  ngOnInit(): void { }
}
