<sbnb-message
  type="system"
  senderImg="assets/iconography/smartbnb-avatar.svg"
  senderName="Hospitable"
  [timestamp]="message.created_at_label">
  <sbnb-collapsible-card [showDivider]="false" [defaultOpen]="!message.sent_response" spacing="12">
    <!-- Title -->
    <div title class="flex justify-between w-full items-center flex-wrap">
      <span class="strong">{{ message.from.name }} left a {{ message.rating }}-star review</span>
      <!-- Status -->
      <span
        *ngIf="!message.sent_response; else replySubmitted"
        class="text__small night-lighter flex items-center"
        style="gap: 6px">
        <span *ngIf="message.can_respond; else leavePublicReply">
          Leave a public reply.
        </span>
        <ng-template #leavePublicReply>
          <span *ngIf="message.platform !== Channel.Direct">
            Leave a public reply on {{ message.platform | platformLabel }}
          </span>
        </ng-template>
        <sbnb-icon
          *ngIf="(isMobile$ | async) === false && (message.can_respond || message.platform !== Channel.Direct)"
          [size]="14"
          [icon]="message.can_respond ? 'time' : 'details'"
          class="shrink-0 text-night-lighter"></sbnb-icon>
      </span>
      <ng-template #replySubmitted>
        <span class="text__small night-lighter">Public reply submitted</span>
      </ng-template>
    </div>
    <!-- Review content -->
    <ng-template sbnbCollapsibleCardContent>
      <div class="flex flex-col gap-6">
        <!-- Review content and ratings together -->
        <div class="flex gap-3">
          <sbnb-profile-img [src]="message.from.thumbnail_url"></sbnb-profile-img>
          <div class="flex flex-col gap-4 flex-1">
            <!-- Review text -->
            <div class="flex flex-col gap-1.5">
              <div class="review">"{{ message.translated_public_review ?? message.public_review }}"</div>
              <div *ngIf="message.private_review" class="private-feedback" matTooltip="Private feedback, visible only to you">
                <span><sbnb-icon icon="padlock" [size]="12" class="text-night-lighter"></sbnb-icon></span>
                <span>"{{ message.translated_private_review ?? message.private_review }}"</span>
              </div>
            </div>

            <!-- Category Ratings -->
            <div *ngIf="message.category_ratings && categoryRatingsEnabled" class="flex flex-col divide-y divide-smoke/30">
              <div *ngFor="let category of categories" class="py-3 first:pt-0 last:pb-0">
                <!-- Header and stars in one row -->
                <div class="flex items-center gap-3">
                  <span class="text-sm font-medium capitalize text-night">{{category}}</span>
                  <div class="flex items-center gap-1">
                    <span class="text-sm font-medium text-night">{{message.category_ratings[category].rating}}</span>
                    <sbnb-icon icon="star" [size]="14" class="text-smoke-darker"></sbnb-icon>
                  </div>
                </div>

                <!-- Tags in their own row -->
                <div *ngIf="message.category_ratings[category].tags?.length" class="flex flex-wrap gap-1.5 mt-2">
                  <span
                    *ngFor="let tag of message.category_ratings[category].tags"
                    class="px-2 py-1 text-xs bg-smoke-lighter text-night-darker rounded-full">
                    {{tag}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Reply form -->
        <sbnb-received-review-form [message]="message" [showGuestPicture]="false"></sbnb-received-review-form>
      </div>
    </ng-template>
  </sbnb-collapsible-card>
</sbnb-message>
