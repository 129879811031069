<div class="page-level-cta--wrapper">
  <div
    class="page-level-cta--content"
    [class.neutral]="cta.context === PageLevelCtaContext.Neutral"
    [class.positive]="cta.context === PageLevelCtaContext.Positive">
    <div class="page-level-cta--text">
      <h4>{{ cta.title }}</h4>
      <div
        fxLayout
        fxLayoutGap="20px"
        fxLayoutAlign="space-between center"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between center">
        <p class="page-level-cta--description" [innerHTML]="cta.description | safe: 'html'"></p>
      </div>
    </div>
    <div class="page-level-cta--actions" *ngIf="cta.actions && cta.actions.length > 0">
      <div fxLayout fxLayout.lt-md="row" fxLayoutGap="16px">
        <ng-container *ngFor="let action of cta.actions">
          <button
            *ngIf="action.url"
            mat-flat-button
            [class.button__secondary]="action.color === PageLevelCtaActionColor.Secondary"
            [class.button__text]="action.color === PageLevelCtaActionColor.Text">
            <a [class.white]="action.color === PageLevelCtaActionColor.Primary" [href]="action.url">
              {{ action.text }}
            </a>
          </button>

          <button
            *ngIf="action.callbackFn"
            mat-flat-button
            [class.button__secondary]="action.color === PageLevelCtaActionColor.Secondary"
            [class.button__text]="action.color === PageLevelCtaActionColor.Text"
            (click)="callback(action)">
            <span [class.white]="action.color === PageLevelCtaActionColor.Primary">{{ action.text }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
