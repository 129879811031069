import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-prev-next',
  template: `
    <span *ngIf="count > 0" class="text__xsmall night-lighter flex items-center justify-center gap-1">
      <button
        mat-button
        mat-icon-button
        aria-label="Next"
        [disabled]="activeIndex < 1"
        (click)="prev()"
        class="button__icon-small">
        <mat-icon style="font-size: 24px;">chevron_left</mat-icon>
      </button>
      <span>{{ activeIndex + 1 }}/{{ count }}</span>
      <button
        mat-button
        mat-icon-button
        aria-label="Next"
        [disabled]="activeIndex + 1 === count"
        (click)="next()"
        class="button__icon-small">
        <mat-icon style="font-size: 24px;">chevron_right</mat-icon>
      </button>
    </span>
  `,
})
export class PrevNextComponent implements OnInit {
  @Input() count = 0;
  @Output() activeIndexChange = new EventEmitter<number>();

  public activeIndex = 0;
  constructor() {}

  ngOnInit(): void {}

  public prev = () => {
    if (this.activeIndex > 0) {
      this.activeIndex--;
      this.activeIndexChange.emit(this.activeIndex);
    }
  };

  public next = () => {
    if (this.activeIndex + 1 < this.count) {
      this.activeIndex++;
      this.activeIndexChange.emit(this.activeIndex);
    }
  };
}
