import { Component, Input } from '@angular/core';
import { Reservation } from '@app/shared/interfaces';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';

@Component({
  standalone: false,
  selector: 'sbnb-upsells-status',
  templateUrl: './upsells-status.component.html',
  styleUrls: ['./upsells-status.component.scss'],
})
export class UpsellsStatusComponent {
  @Input() reservation: Reservation;

  public linkRecentlyCopied = false;
  public isMobile: boolean;

  constructor(private deviceDetectionService: DeviceDetectionService) {
    this.deviceDetectionService.isMobileViewport().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  public copiedUpsellLink() {
    this.linkRecentlyCopied = true;
    setTimeout(() => {
      this.linkRecentlyCopied = false;
    }, 1000);
  }
}
