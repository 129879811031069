<div class="p-2 md:p-6" style="container-type: inline-size">
  <div class="text-gray flex text-xs md:text-md align-middle whitespace-nowrap" [ngClass]="titleCssClass">
    {{ title }}
    <sbnb-icon *ngIf="tooltip" class="pl-1" [size]="14" icon="details" [matTooltip]="tooltip"></sbnb-icon>
  </div>
  <div class="my-2">
    <h2
      class="text-xl md:text-2xl font-bold text-gray-dark"
      style="font-size: clamp(0.5rem, 12cqw, 1.5rem)"
      [ngClass]="valueCssClass">
      {{ value }}
    </h2>
  </div>
  <div class="my-2">
    <span
      class="text-xs font-medium px-2.5 py-1 rounded-full"
      [ngClass]="changeInValueColour"
      *ngIf="changeInValue"
      [matTooltip]="badgeTooltip"
      [matTooltipPosition]="'right'">
      {{ changeInValue }}
    </span>
  </div>
</div>
