<section class="property-card" fxLayout fxLayoutGap="16px">
  <img class="property-card-picture" [src]="property.picture" [alt]="property.name" />
  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start">
    <span class="text__medium night ellipsis" style="width: 100%">{{ property.name }}</span>
    <div class="night text__small" fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
      <sbnb-icon-airbnb width="14px" height="14px" *ngIf="property.counts?.airbnb > 0"></sbnb-icon-airbnb>
      <sbnb-icon-homeaway width="14px" height="14px" *ngIf="property.counts?.homeaway > 0"></sbnb-icon-homeaway>
      <span>{{ property.types[0] }}</span>
    </div>
  </div>
</section>
