import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-save-indicator',
  templateUrl: './save-indicator.component.html',
  styleUrls: ['./save-indicator.component.scss'],
})
export class SaveIndicatorComponent implements OnInit {
  @Input('saveStatus') saveStatus: number;

  constructor() { }

  ngOnInit() { }
}
