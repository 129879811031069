import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { User } from '@app/shared/interfaces';

@Component({
  standalone: false,
  selector: 'sbnb-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, OnChanges {
  @Input() inlineMode = false;

  @Input() hours = 0;
  @Input() minutes = 0;
  @Input() readOnly = false;
  @Input() upsell = false;

  @Output() timeUpdated: EventEmitter<any> = new EventEmitter();
  @Output() closed: EventEmitter<any> = new EventEmitter();

  hoursArr: number[];
  minutesArr: number[];
  amPm: 'am' | 'pm' = 'am';

  user: User = this.auth.getUserDetails();

  @ViewChild('hoursInput', { static: true }) hoursInput: ElementRef;

  constructor(private readonly auth: AuthenticationService) { }

  ngOnInit() {
    if (this.user.uses_24_hour_format) {
      this.hoursArr = Array(24)
        .fill(0)
        .map((x, i) => i);
    } else {
      this.hoursArr = Array(11)
        .fill(0)
        .map((x, i) => i + 1);
    }

    this.minutesArr = Array(60)
      .fill(0)
      .map((x, i) => i);

    this.validateInputs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hours && !this.user.uses_24_hour_format) {
      if (changes.hours.currentValue > 11) {
        this.amPm = 'pm';
      }

      if (changes.hours.currentValue == 0) {
        this.hours = 12;
      }

      if (changes.hours.currentValue > 12) {
        this.hours = +changes.hours.currentValue - 12;
      }
    }
  }

  emit() {
    const hours = this.convert12to24();
    this.timeUpdated.emit({ hours: hours, minutes: this.minutes });
  }

  menuClosed() {
    const hours = this.convert12to24();
    this.closed.emit({ hours: hours, minutes: this.minutes });
  }

  convert12to24(): number {
    if (!this.user.uses_24_hour_format && this.amPm === 'pm' && this.hours != 12) {
      return +this.hours + 12;
    } else if (!this.user.uses_24_hour_format && this.amPm === 'am' && this.hours == 12) {
      return this.hours - 12;
    } else {
      return this.hours;
    }
  }

  validateInputs() {
    if (this.hours > 23 || this.hours < 0) {
      throw Error('Hours is invalid, should  be between 0 and 23');
    }

    if (this.minutes > 59 || this.hours < 0) {
      throw Error('Minutes is invalid, should be between 0 and 59');
    }
  }

  onChangeHours(event: KeyboardEvent) {
    const origHour = this.hours;

    const newHour = Number((<HTMLInputElement>event.target).value);

    if (newHour >= 0 && newHour < 24) {
      this.hours = newHour;
    } else {
      (<HTMLInputElement>event.target).value = String(origHour);
    }

    this.emit();
  }

  onChangeMinutes(event: KeyboardEvent) {
    const origMinutes = this.minutes;

    const newMins = Number((<HTMLInputElement>event.target).value);

    if (newMins >= 0 && newMins < 60) {
      this.minutes = newMins;
    } else {
      (<HTMLInputElement>event.target).value = String(origMinutes);
    }

    this.emit();
  }

  incrementHours() {
    this.hours++;

    const maxHours = this.user.uses_24_hour_format ? 24 : 13;

    if (this.hours === maxHours) {
      this.hours = maxHours === 24 ? 0 : 1;
    }

    this.checkAmPmRollover('increment');

    this.emit();
  }

  decrementHours() {
    this.hours--;

    const maxHours = this.user.uses_24_hour_format ? 23 : 12;

    if (this.hours === (maxHours === 23 ? -1 : 0)) {
      this.hours = maxHours;
    }

    this.checkAmPmRollover('decrement');

    this.emit();
  }

  checkAmPmRollover(mode: 'increment' | 'decrement') {
    if (this.user.uses_24_hour_format) {
      return;
    }

    switch (mode) {
      case 'increment':
        if (this.hours === 12) {
          this.changeAmPm();
        }
        break;
      case 'decrement':
        if (this.hours === 11) {
          this.changeAmPm();
        }
        break;
    }
  }

  incrementMinutes() {
    // Round up to next 5 minute increment
    this.minutes = Math.ceil((+this.minutes + 1) / 5) * 5;

    if (this.minutes === 60) {
      this.minutes = 0;
    }

    this.emit();
  }

  decrementMinutes() {
    // Round down to previous 5 minute increment
    this.minutes = Math.floor((+this.minutes - 1) / 5) * 5;

    if (this.minutes < 0) {
      this.minutes = 55;
    }

    this.emit();
  }

  changeAmPm() {
    if (this.amPm === 'am') {
      this.amPm = 'pm';
    } else {
      this.amPm = 'am';
    }

    this.emit();
  }
}
