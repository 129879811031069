<section>
  <header class="w-full flex justify-between gap-6">
    <div
      class="step"
      *ngFor="let step of steps; let i = index"
      [class.active]="selectedIndex === i"
      [class.completed]="step.completed"
      [class.next__completed]="steps.toArray()[i + 1]?.completed">
      <span class="step__label ellipsis">{{ step.label }}</span>
      <span class="stepping-stone"></span>
    </div>
  </header>

  <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
</section>
