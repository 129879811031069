import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { Language } from '@app/shared/models/language';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const LanguageLabels = {
  en: 'English',
  fr: 'French',
  de: 'German',
  es: 'Spanish',
  it: 'Italian',
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private http: HttpClient) {}

  getLanguages(): Observable<Language[]> {
    return this.http.get(`${config.API_URL}/languages`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
}
