<div
  class="team__icon"
  [style.width.px]="size"
  [style.height.px]="size"
  fxLayout
  fxLayoutAlign="center center"
  [class.allRoles]="member.all_services"
  [class.moreThan1Role]="member.services.length > 1 && !member.all_services"
  [class.checkInOutRole]="
    member.services.length === 1 &&
    (member.services_labels.indexOf('Checkin') > -1 || member.services_labels.indexOf('Checkout') > -1)
  "
  [class.maintenanceRole]="member.services.length === 1 && member.services_labels.indexOf('Maintenance') > -1"
  [class.cleaningRole]="member.services.length === 1 && member.services_labels.indexOf('Cleaning') > -1">
  <span *ngIf="member.initials">{{ member.initials }}</span>
  <span *ngIf="!member.initials">
    {{ member.first_name | uppercase | slice: 0 : 1 }}{{ member.last_name | uppercase | slice: 0 : 1 }}
  </span>
</div>
