import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { SmartDevicesService, ThermostatSchedule } from '@app/modules/smart-devices/service/smart-devices.service';
import { SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { CaughtApiError } from '@app/shared/services/smartlocks/smartlocks.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
@Component({
  selector: 'sbnb-thread-thermostat-schedules',
  templateUrl: './thread-thermostat-schedules.component.html',
  styleUrls: ['./thread-thermostat-schedules.component.scss'],
})
export class ThreadThermostatSchedulesComponent implements OnDestroy, OnChanges {
  thermostatSchedules: ThermostatSchedule[];
  loading: boolean;
  expanded = true;
  codeProvisionRecentlyRequested = false;

  reloadThermostatSchedulesTimerSubscription$: Subscription;

  @Input() reservationUuid: string;

  constructor(
    private readonly smartDevicesService: SmartDevicesService,
    private segmentIoService: SegmentIoService,
    private toast: ToastNotificationsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reservationUuid) {
      this.getThermostatSchedules();
    }
  }

  getThermostatSchedules() {
    // if there's not an actual reservation (like just an inquiry), don't make the request
    if (!this.reservationUuid) {
      return;
    }
    this.loading = true;
    const payload = {
      uuid: this.reservationUuid,
    };

    this.reloadThermostatSchedulesTimerSubscription$ = timer(0, 60_000)
      .pipe(mergeMap(() => this.smartDevicesService.getThermostatSchedulesForAThread(payload)))
      .subscribe((res) => {
        if ((res as CaughtApiError)?.error) {
          this.loading = false;
          return;
        }
        this.thermostatSchedules = res as ThermostatSchedule[];
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    if (
      this.reloadThermostatSchedulesTimerSubscription$ &&
      this.reloadThermostatSchedulesTimerSubscription$.unsubscribe
    ) {
      this.reloadThermostatSchedulesTimerSubscription$.unsubscribe();
    }
  }
}
