<div *ngIf="dialogData.agreement as agreement" class="flex flex-col gap-4 h-full">
  <div class="flex justify-between items-center flex-shrink-0">
    <h2 class="mb-0">{{ agreement.name }}</h2>
    <sbnb-close [border]="true" (click)="dialogRef.close()"></sbnb-close>
  </div>

  <div
    class="border border-smoke-darker rounded p-4 bg-smoke-lighter overflow-y-auto flex-grow"
    [innerHTML]="agreement.content"></div>

  <!-- sticky footer -->
  <div class="flex-shrink-0">
    <div class="flex gap-4 justify-between items-center">
      <div *ngIf="agreement.signatory">
        Signed by {{ agreement.signatory }} on {{ agreement.signed_at | date: 'mediumDate' }}
      </div>

      <div *ngIf="!agreement.signatory" class="italic">Pending signature</div>

      <div class="flex gap-4 justify-end">
        <ng-container *ngIf="agreement.signatory">
          <a
            *ngIf="agreement.pdf_url"
            class="flex items-center justify-end gap-4"
            [href]="agreement.pdf_url"
            target="_blank">
            <sbnb-button type="secondary" size="mini">Download a copy</sbnb-button>
          </a>
        </ng-container>

        <div class="flex flex-col md:flex-row gap-2">
          <a *ngIf="!agreement.signatory && (userPermissionsService.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) === true" class="cursor-pointer" (click)="deleteAgreement()">
            <sbnb-button type="secondary-danger" size="mini">Remove agreement</sbnb-button>
          </a>

          <a
            *ngIf="dialogData.showConversationButton"
            [routerLink]="['/inbox/thread/' + agreement.reservation.thread_uuid]"
            (click)="dialogRef.close()">
            <sbnb-button type="secondary" size="mini">Jump to conversation</sbnb-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
