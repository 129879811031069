import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'conditionToText',
})
export class ConditionToTextPipe implements PipeTransform {
  transform(condition: any, meta: any): any {
    const rightMeta = meta.find((obj) => obj.key === condition.key);

    let result = `${rightMeta.label_before} `;

    result += `${condition.operator !== '=' ? condition.operator + ' ' : ''}`;

    if (typeof condition.value === 'boolean') {
      if (rightMeta.bool_true) {
        result += `${condition.value ? rightMeta.bool_true : rightMeta.bool_false} `;
      }
    } else {
      result += `${condition.value} `;
    }

    result += `${condition.value === 1 ? rightMeta.label_after_singular : rightMeta.label_after}`;

    return result;
  }
}
