<div
  *ngIf="thread.guest?.first_name && thread.guest?.guest_id != '109881679'"
  fxLayout="row"
  fxLayoutGap="16px"
  class="insights__container"
  [class.removeBorders]="removeBorders">
  <div fxHide.lt-md class="insights__avatar" fxFlex="40px">
    <img src="/assets/iconography/smartbnb-avatar.svg" width="40px" height="40px" alt="" />
  </div>

  <div fxFlex class="insights__inner-container">
    <div
      fxLayout.lt-md="row wrap"
      fxLayoutAlign.lt-md="start start"
      fxLayout
      fxLayoutAlign="start center"
      class="insights__header">
      <h4 fxFlex>Insights about {{ thread.guest.first_name }}</h4>

      <span
        *ngIf="insights.activeFor"
        [class.muted]="insights.activeFor.accountLength > 14"
        fxLayout
        fxLayoutGap="4px"
        fxLayoutAlign="start center">
        <sbnb-icon [size]="18" [icon]="insights.activeFor.icon"></sbnb-icon>
        <span>{{ insights.activeFor.label }}</span>
      </span>
      <sbnb-button
        *ngIf="thread.platform === Channel.Direct && guestVettingData$ | async as vetting"
        label="Autohost insights"
        type="secondary"
        size="mini"
        (clicked)="openVettingDialog()"></sbnb-button>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap.lt-md="8px grid"
      fxLayoutGap="30px"
      class="insights__icon-container">
      <!-- Begin verified -->
      <div *ngIf="insights.verified" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <img [src]="insights.verified.icon" width="30px" height="30px" [alt]="insights.verified.label" />
        <div fxFlex="70px" class="text__xsmall insights__icon-text">{{ insights.verified.label }}</div>
      </div>
      <!-- End verified -->

      <!-- Autohost risk level for Direct Premium -->
      <ng-container
        *ngIf="thread.platform === Channel.Direct && (guestVettingData$ | async)?.summary as vettingSummary">
        <div *ngIf="vettingSummary.risk_color" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <div class="autohost-risk-color autohost-risk-color-{{ vettingSummary.risk_color }}"></div>
          <div fxFlex="70px" class="text__xsmall insights__icon-text">
            Autohost Risk - {{ vettingSummary.risk_color | humanize }}
          </div>
        </div>
      </ng-container>
      <!-- End Autohost risk level for Direct Premium -->

      <!-- Begin chance of booking -->
      <div *ngIf="insights.chancesOfBooking" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <img [src]="insights.chancesOfBooking.icon" width="30px" height="30px" alt="" />
        <div fxFlex="70px" class="text__xsmall insights__icon-text">{{ insights.chancesOfBooking.label }}</div>
      </div>
      <!-- End chance of booking -->

      <!-- Begin review count -->
      <div *ngIf="insights.reviewCount" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <div
          class="icon-rating-container-reviews"
          [class.insights__icon-text-high]="insights.reviewCount.count > 0"
          [class.insights__icon-rating-high]="insights.reviewCount.count > 0">
          {{ insights.reviewCount.count }}
        </div>
        <div fxFlex="70px" class="text__xsmall insights__icon-text">Public reviews</div>
      </div>
      <!-- End review count -->

      <!-- Begin same day booking -->
      <div *ngIf="insights.sameDayBooking" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <sbnb-icon [size]="30" [icon]="insights.sameDayBooking.icon"></sbnb-icon>
        <div fxFlex="70px" class="text__xsmall insights__icon-text">{{ insights.sameDayBooking.label }}</div>
      </div>
      <!-- End same day booking -->

      <!-- Begin Local guest -->
      <div *ngIf="insights.localGuest" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <sbnb-icon [size]="30" [icon]="insights.localGuest.icon"></sbnb-icon>
        <div fxFlex="70px" class="text__xsmall insights__icon-text">
          <span class="cera-heading">Local guest</span>
          <br />
          <span>{{ insights.localGuest.label }}</span>
        </div>
      </div>
      <!-- End local guest -->
    </div>

    <div class="insights__reviews-container" *ngIf="thread.platform !== Channel.Direct">
      <span class="cera-heading reviews__heading">Latest reviews</span>
      <ng-container *ngFor="let review of thread.guest.reviews; let i = index">
        <div
          *ngIf="i < numReviewsShown"
          fxLayout="row"
          fxLayoutGap="12px"
          fxLayoutAlign="start start"
          class="insights__review">
          <img class="insights__review-image" [src]="review.image" width="24px" height="24px" />
          <div fxFlex class="insights__review-comment">"{{ review.comment }}"</div>
        </div>
      </ng-container>

      <div *ngIf="thread.guest?.reviews?.length === 0" class="insights__no-reviews">
        No reviews available for this guest
      </div>

      <button
        *ngIf="numReviewsShown < numReviews"
        (click)="numReviewsShown = numReviewsShown + 5"
        class="button__text"
        mat-flat-button>
        Show
        {{ numReviews - numReviewsShown > 5 ? 5 : numReviews - numReviewsShown }}
        more reviews...
      </button>

      <ng-container
        *ngIf="
          thread.status === 'Inquiry' &&
          activeReservation?.supports?.pre_approvals &&
          (preapprovalSuccess$ | async) !== true
        ">
        <div class="insights__separator"></div>
        <div class="flex items-center justify-start gap-3">
          <h4 class="text-night m-0 hidden lg:block flex-1">
            {{
              (deadlineForInquiryResponse | isFuture)
                ? 'You can pre-approve this guest'
                : 'You have missed the deadline to respond to this inquiry'
            }}
          </h4>
          <sbnb-button
            size="mini"
            type="primary-teal"
            [loading]="preapprovalLoading$ | async"
            label="Pre-approve"
            icon="check"
            [disabled]="(preapprovalLoading$ | async) || (deadlineForInquiryResponse | isPast)"
            (click)="preapprove()"></sbnb-button>
          <sbnb-button
            *ngIf="[Channel.Airbnb, Channel.Booking].includes(thread.platform)"
            [loading]="preapprovalLoading$ | async"
            size="mini"
            type="secondary"
            [disabled]="(preapprovalLoading$ | async) || (deadlineForInquiryResponse | isPast)"
            label="Decline"
            (click)="decline()"></sbnb-button>
        </div>

        <p *ngIf="thread.platform === Channel.Booking && (deadlineForInquiryResponse | isFuture)">
          You have {{ deadlineForInquiryResponse | timeAgo: false }} left to approve or deny.
        </p>
      </ng-container>
    </div>

    <div
      *ngIf="thread.platform === Channel.Direct && guestVettingData$ | async as vetting"
      class="guest-vetting-risks-recommendations"
      fxLayout="column"
      fxLayoutGap="24px">
      <div *ngIf="vetting?.summary?.top_risks" fxLayout="column" fxLayoutGap="12px">
        <strong>Top risks from Autohost:</strong>
        <ng-container *ngFor="let risk of vetting.summary.top_risks">
          <div *ngIf="risk">{{ risk }}</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
