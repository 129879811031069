import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Reservation } from '@app/shared/interfaces';
import { GuestVettingOption } from '@app/shared/interfaces/lib/guest-vetting.interface';
import { GuestVettingService } from '@app/shared/services/guest-vetting/guest-vetting.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { StorylaneService } from '@app/shared/services/storylane.service';
import { Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
@Component({
  standalone: false,
  selector: 'sbnb-dialog-request-adhoc-guest-vetting',
  templateUrl: './dialog-request-adhoc-guest-vetting.component.html',
  styleUrls: ['./dialog-request-adhoc-guest-vetting.component.scss'],
})
export class DialogRequestAdhocGuestVettingComponent {
  currentStage = 1;
  guestVettingOptions$: Observable<Array<GuestVettingOption>> = of([]);
  messageTemplate = `Please complete the additional verification requirements found in your guest portal.
%guest_portal%

Upon verification, you will receive additional communication including check-in instructions.

Thank you,

%host_first_name%`;

  selectedVettings: string[] = [];
  message = this.messageTemplate;
  hostConfirmedCharge = false;
  submitting: boolean;

  constructor(
    private segmentIoService: SegmentIoService,
    private guestVettingService: GuestVettingService,
    private storylaneService: StorylaneService,
    private dialogRef: MatDialogRef<DialogRequestAdhocGuestVettingComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      guestName: Reservation['guest'] | null;
      reservationUuid: Reservation['uuid'];
    }
  ) {
    this.guestVettingOptions$ = this.fetchVettingOptions();
  }

  fetchVettingOptions(): Observable<Array<GuestVettingOption>> {
    return this.guestVettingService.fetchVettingOptions().pipe(
      shareReplay(1),
      tap((options) => {
        // Add the enabled_by_default options to the selectedVettings array
        if (this.selectedVettings.length === 0) {
          this.selectedVettings = options.filter((option) => option.enabled_by_default).map((option) => option.id);
        }
      })
    );
  }

  toggleVettingOption(optionId: string) {
    if (this.selectedVettings.includes(optionId)) {
      this.selectedVettings = this.selectedVettings.filter((id) => id !== optionId);
    } else {
      this.selectedVettings = [...this.selectedVettings, optionId];
    }
  }

  onMessageTemplateChange($event) {
    this.message = $event;
  }

  public continueToStage2() {
    this.currentStage = 2;
    this.segmentIoService.track(SegmentEvent.GuestVerificationRequestContinue, {});
  }

  previewGuestPortal() {
    this.storylaneService.previewGuestPortal();
  }

  submitVerificationRequest() {
    this.submitting = true;
    this.segmentIoService.track(SegmentEvent.GuestVerificationRequestConfirmAndSend, {});

    this.guestVettingService
      .initiateVetting(this.dialogData.reservationUuid, this.selectedVettings, this.message)
      .subscribe({
        next: () => {
          this.submitting = false;
          this.currentStage = 3;
        },
        error: () => {
          this.submitting = false;
        },
      });
  }

  close(refresh = false) {
    this.dialogRef.close(refresh);
  }
}
