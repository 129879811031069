import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { NotificationType } from '@app/shared/interfaces';
import { RuleService } from '@app/shared/services/rule/rule.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-edit-message',
  templateUrl: './dialog-edit-message.component.html',
  styleUrls: ['./dialog-edit-message.component.scss'],
})
export class DialogEditMessageComponent implements OnInit {
  messageId: string;
  messageType: string;
  messageDetails: any;
  actioning: boolean;

  initialMessage: string;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger | undefined;

  user = this.authService.getUserDetails();
  isRejectingAiImprovement = false;

  constructor(
    public dialogRef: MatDialogRef<DialogEditMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ruleService: RuleService,
    private authService: AuthenticationService,
    private toast: ToastNotificationsService
  ) { }

  ngOnInit() {
    this.messageId = this.data.messageId;
    this.messageType = this.data.messageType ? this.data.messageType : 'scheduled';

    if (this.messageType === 'activity') {
      this.messageType = 'activities';
    }

    this.ruleService.getSingleMessage(this.messageId, this.messageType).subscribe((res) => {
      if (res.error) {
        this.toast.open('Unable to open message', 'Dismiss', NotificationType.Error);
        this.dialogRef.close();
        return;
      }

      this.messageDetails = res;

      // If we have an AI message, and its not been rejected, we want to populate that instead of the prepared_message
      if (
        this.messageDetails.ai_improvement?.message &&
        this.messageDetails.ai_improvement?.rejected === false &&
        this.messageDetails.ai_improvement?.edited === false
      ) {
        this.messageDetails.updated_message = this.messageDetails.ai_improvement.message;
      } else {
        this.messageDetails.updated_message = this.messageDetails.prepared_message;
      }

      this.initialMessage = this.messageDetails.updated_message;

      this.messageDetails.scheduled_day = this.messageDetails.scheduled_for.substr(0, 10);

      this.messageDetails.scheduled_time_hours = this.messageDetails.scheduled_for.substr(11, 2);

      this.messageDetails.scheduled_time_minutes = this.messageDetails.scheduled_for.substr(14, 2);

      const temp = this.messageDetails.scheduled_for_label.split(' ');
      this.messageDetails.scheduled_timezone = temp[temp.length - 1];

      this.messageDetails.origDate = this.messageDetails.scheduled_day;
      this.messageDetails.origHour = this.messageDetails.scheduled_time_hours;
      this.messageDetails.origMin = this.messageDetails.scheduled_time_minutes;
    });
  }

  undoAiImprovement() {
    this.messageDetails.updated_message = this.messageDetails.prepared_message;
    this.initialMessage = this.messageDetails.updated_message;
    this.isRejectingAiImprovement = true;
  }

  deleteMessage() {
    this.actioning = true;
    this.ruleService.deleteSingleMessage(this.messageId, this.messageType).subscribe((res) => {
      this.actioning = false;
      // If successful, close the dialog
      if (res) {
        this.dialogRef.close({ status: 'deleted' });
      }
    });
  }

  saveUpdatedMessage(sendNow = false) {
    this.actioning = true;

    let scheduledTimeUpdated = false;

    if (
      this.messageDetails.origDate !== this.messageDetails.scheduled_day ||
      this.messageDetails.origHour !== this.messageDetails.scheduled_time_hours ||
      this.messageDetails.origMin !== this.messageDetails.scheduled_time_minutes
    ) {
      scheduledTimeUpdated = true;
    }

    this.ruleService
      .sendMessage(
        this.messageId,
        this.messageDetails.updated_message,
        sendNow,
        this.messageType,
        scheduledTimeUpdated ? this.messageDetails.scheduled_day : null,
        scheduledTimeUpdated ? this.messageDetails.scheduled_time_hours : null,
        scheduledTimeUpdated ? this.messageDetails.scheduled_time_minutes : null,
        this.isRejectingAiImprovement
      )
      .subscribe(
        (res) => {
          this.actioning = false;

          if (res.errors) {
            return;
          }

          if (sendNow) {
            this.dialogRef.close({ status: 'sent' });
          } else {
            this.dialogRef.close({ status: 'updated', updatedItem: res.data });
          }
        },
        (error) => {
          this.actioning = false;
        }
      );
  }

  messageUpdated(newMessage) {
    this.messageDetails.updated_message = newMessage;
  }

  newDateSelected($event) {
    this.messageDetails.scheduled_day = $event;
    this.menuTrigger?.closeMenu();
  }

  newTimeSelected($event) {
    this.messageDetails.scheduled_time_hours = $event.hours;
    this.messageDetails.scheduled_time_minutes = $event.minutes;
  }
}
