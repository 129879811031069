<mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>

<div *ngIf="!loading && tasks?.length === 0 && !lite" class="margin-20">
  <sbnb-no-items icon="task" text="No tasks"></sbnb-no-items>
</div>

<div class="dashboard__tab-innner" *ngIf="tasks && tasks.length > 0 && !lite">
  <div
    *ngFor="let task of tasks"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    class="rules__rule-container"
    [class.pointer-events]="(perms$.hasAccessTo(Features.PERM_TASKS_UPDATE_ALL) | async) === false"
    #assignMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="assignMenu"
    (menuOpened)="selectedTask = task">
    <div fxHide.lt-md fxFlex fxLayout fxLayoutAlign="start center" fxLayoutGap="16px">
      <strong>{{ task.task_rule?.name ? task.task_rule.name : task.task_type_string }}</strong>
      <span class="overview__effort text__xsmall strong">{{ task.duration }}</span>
    </div>

    <div fxFlex fxLayout fxLayoutAlign="start center" [fxLayoutGap]="task.teammate ? '0px' : '20px'">
      <ng-container *ngIf="!task.teammate">
        <span fxHide.lt-md>Unassigned</span>
      </ng-container>

      <ng-container *ngIf="task.teammate">
        <sbnb-team-icon fxFlex="32px" style="margin-right: 0 !important" [member]="task.teammate"></sbnb-team-icon>
        <span>{{ task.teammate.name }}</span>
      </ng-container>
    </div>

    <div fxLayout fxLayoutAlign="end center" fxLayoutGap="8px">
      <sbnb-icon icon="time" class="shrink-0 text-night"></sbnb-icon>
      <span fxHide.lt-md class="text__small" [matTooltip]="task.start_date_tooltip">{{ task.start_date_label }}</span>
      <span fxHide fxShow.lt-md class="text__small text-right">
        {{ task.start_date_label }}
      </span>
    </div>
  </div>

  <mat-menu
    *ngIf="perms$.hasAccessTo(Features.PERM_TASKS_UPDATE_ALL) | async"
    #assignMenu="matMenu"
    class="filters__cdk-menu filters__cdk-menu-320">
    <sbnb-menu-task-assign
      *ngIf="assignMenuTrigger?.menuOpen"
      [task]="selectedTask"
      (updatedTask)="taskAssigned($event)"
      (taskDeleted)="removeTask($event)"
      (closeMenu)="assignMenuTrigger.closeMenu()"></sbnb-menu-task-assign>
  </mat-menu>
</div>

<div *ngIf="(perms$.hasAccessTo(Features.PERM_TASKS) | async) && lite && !loading && reservation">
  <div fxLayout fxLayoutAlign="start center" style="margin-bottom: 12px">
    <span class="cera-heading night-darker" fxFlex>Tasks</span>

    <ng-container *ngIf="perms$.hasAccessTo(Features.PERM_TASKS_UPDATE_ALL) | async">
      <sbnb-button
        size="mini"
        label="+ New"
        type="secondary"
        [matMenuTriggerFor]="newTaskMenu"
        #trigger="matMenuTrigger"></sbnb-button>

      <!-- new task menu -->
      <mat-menu #newTaskMenu="matMenu" class="filters__cdk-menu filters__cdk-menu-320">
        <sbnb-menu-task-assign
          *ngIf="(perms$.hasAccessTo(Features.PERM_TASKS_UPDATE_ALL) | async) && trigger?.menuOpen"
          [mode]="'new'"
          [newDate]="{ date: reservation.start_date, hour: '11' }"
          [propertyId]="reservation.listing.property_id"
          (createdTask)="trigger.closeMenu(); taskCreated($event)"
          (closeMenu)="trigger.closeMenu()"></sbnb-menu-task-assign>
      </mat-menu>
    </ng-container>
  </div>

  <div *ngFor="let task of tasks" class="text__small" style="margin-bottom: 8px" fxLayout fxLayoutAlign="start center">
    <span
      fxFlex
      class="text__medium raspberry-darker clickable"
      (click)="selectedTask = task"
      [matMenuTriggerFor]="assignMenu"
      #assignMenuTrigger="matMenuTrigger">
      {{ task.task_rule?.name ? task.task_rule.name : task.task_type_string }}
    </span>
    <span>{{ task.start_date_label }}</span>
  </div>

  <!-- assign menu -->
  <mat-menu #assignMenu="matMenu" class="filters__cdk-menu filters__cdk-menu-320">
    <sbnb-menu-task-assign
      [task]="selectedTask"
      (updatedTask)="taskAssigned($event)"
      (taskDeleted)="removeTask($event)"
      (closeMenu)="assignMenuTrigger.closeMenu()"></sbnb-menu-task-assign>
  </mat-menu>
</div>
