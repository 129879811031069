'use strict';

/**
 * Expose `arrayFlatten`.
 */
module.exports = flatten;
module.exports.from = flattenFrom;
module.exports.depth = flattenDepth;
module.exports.fromDepth = flattenFromDepth;

/**
 * Flatten an array.
 *
 * @param  {Array} array
 * @return {Array}
 */
function flatten(array) {
  if (!Array.isArray(array)) {
    throw new TypeError('Expected value to be an array');
  }
  return flattenFrom(array);
}

/**
 * Flatten an array-like structure.
 *
 * @param  {Array} array
 * @return {Array}
 */
function flattenFrom(array) {
  return flattenDown(array, []);
}

/**
 * Flatten an array-like structure with depth.
 *
 * @param  {Array}  array
 * @param  {number} depth
 * @return {Array}
 */
function flattenDepth(array, depth) {
  if (!Array.isArray(array)) {
    throw new TypeError('Expected value to be an array');
  }
  return flattenFromDepth(array, depth);
}

/**
 * Flatten an array-like structure with depth.
 *
 * @param  {Array}  array
 * @param  {number} depth
 * @return {Array}
 */
function flattenFromDepth(array, depth) {
  if (typeof depth !== 'number') {
    throw new TypeError('Expected the depth to be a number');
  }
  return flattenDownDepth(array, [], depth);
}

/**
 * Flatten an array indefinitely.
 *
 * @param  {Array} array
 * @param  {Array} result
 * @return {Array}
 */
function flattenDown(array, result) {
  for (var i = 0; i < array.length; i++) {
    var value = array[i];
    if (Array.isArray(value)) {
      flattenDown(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
}

/**
 * Flatten an array with depth.
 *
 * @param  {Array}  array
 * @param  {Array}  result
 * @param  {number} depth
 * @return {Array}
 */
function flattenDownDepth(array, result, depth) {
  depth--;
  for (var i = 0; i < array.length; i++) {
    var value = array[i];
    if (depth > -1 && Array.isArray(value)) {
      flattenDownDepth(value, result, depth);
    } else {
      result.push(value);
    }
  }
  return result;
}