import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/core/app-config';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Tax } from '@app/shared/models/tax';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';

@Injectable({
  providedIn: 'root',
})
export class TaxService {
  private baseUrl = `${config.API_URL}/settings/taxes`;

  public definedPercentageRates: any[] = [
    {
      key: 'nightly-rate',
      label: 'Nightly Rate',
      checked: false,
    },
    {
      key: 'management-fees',
      label: 'Management Fees',
      checked: false,
    },
    {
      key: 'resort-fees',
      label: 'Resort Fees',
      checked: false,
    },
    {
      key: 'community-fees',
      label: 'Community Fees',
      checked: false,
    },
    {
      key: 'linen-fees',
      label: 'Linen Fees',
      checked: false,
    },
    {
      key: 'cleaning-fees',
      label: 'Cleaning Fees',
      checked: false,
    },
    {
      key: 'extra-person-fees',
      label: 'Extra Guest Fees',
      checked: false,
    },
    {
      key: 'pet-fees',
      label: 'Pet Fees',
      checked: false,
    },
    {
      key: 'misc-fees',
      label: 'Miscellaneous Fees (adjustments)',
      checked: false,
    },
    {
      key: 'cancellation-fees',
      label: 'Cancellation Fees',
      checked: false,
    },
    {
      key: 'early-checkin-late-checkout-fees',
      label: 'Early-Checkin/Late-Checkout Fees',
      checked: false,
    },
  ];

  public definedFixedRates: any[] = [
    {
      key: 'per-reservation',
      label: 'Per Reservation',
    },
    {
      key: 'per-guest-per-reservation',
      label: 'Per Guest Per Reservation',
    },
    {
      key: 'per-night',
      label: 'Per Night',
    },
    {
      key: 'per-guest-per-night',
      label: 'Per Guest Per Night',
    },
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private segmentIoService: SegmentIoService
  ) {}

  getTaxes() {
    const url = `${this.baseUrl}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  seedEmptyTax() {
    return {
      uuid: null,
      name: '',
      description: '',
      tax_identifier: '',
      charge_type: null,
      calculation_type: null,
      value: null,
      enabled: true,
      created_at: null,
      updated_at: null,
      properties: [],
      subjects: [],
    };
  }

  createTax(data) {
    const url = `${this.baseUrl}`;
    return this.http.post(url, data).pipe(
      map((res: any) => {
        this.segmentIoService.track(SegmentEvent.DirectTaxCreated, res);
        return res.data;
      })
    );
  }

  getTax(uuid) {
    const url = `${this.baseUrl}/${uuid}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  updateTax(uuid, data) {
    const url = `${this.baseUrl}/${uuid}`;
    return this.http.put(url, data).pipe(
      map((res: any) => {
        this.segmentIoService.track(SegmentEvent.DirectTaxUpdated, res);
        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  deleteTax(uuid) {
    const url = `${this.baseUrl}/${uuid}`;
    return this.http.delete(url).pipe(
      map((res: any) => {
        this.segmentIoService.track(SegmentEvent.DirectTaxDeleted, res);
        return res;
      })
    );
  }

  addPropertiesToTax(uuid: string, propertyIds: number[]) {
    const url = `${this.baseUrl}/${uuid}/properties`;

    return this.http.put(url, { properties: propertyIds }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  addPropertyToTax(uuid: string, propertyId: number) {
    const url = `${this.baseUrl}/${uuid}/properties/${propertyId}`;
    return this.http.patch(url, {}).pipe(
      map((res: any) => {
        this.segmentIoService.track(SegmentEvent.DirectTaxPropertyAdded, {
          tax_id: uuid,
          property_id: propertyId,
        });

        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  removePropertyFromTax(uuid: string, propertyId: number) {
    const url = `${this.baseUrl}/${uuid}/properties/${propertyId}`;
    return this.http.delete(url, {}).pipe(
      map((res: any) => {
        this.segmentIoService.track(SegmentEvent.DirectTaxPropertyRemoved, {
          tax_id: uuid,
          property_id: propertyId,
        });

        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  getFriendlySubjectLabel(key: string) {
    for (let i = 0; i < this.definedPercentageRates.length; i++) {
      if (this.definedPercentageRates[i].key === key) {
        return this.definedPercentageRates[i].label;
      }
    }
  }

  getFriendlyCalculationTypeLabel(key: string) {
    for (let i = 0; i < this.definedFixedRates.length; i++) {
      if (this.definedFixedRates[i].key === key) {
        return this.definedFixedRates[i].label;
      }
    }
  }
}
