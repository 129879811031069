<hr />
<div class="gv-item" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="8px">
  <div *ngIf="label" class="text-sm" fxFlex="220px" fxFlex.lt-md="none">{{ label }}</div>
  <div fxFlex class="gv-item-value">
    {{ value }}
  </div>
  <img
    class="clickable copy-to-clipboard"
    [matTooltip]="'Copy to clipboard'"
    src="/assets/iconography/copy-grey.svg"
    alt="Copy"
    ngxClipboard
    [cbContent]="value" />
</div>
