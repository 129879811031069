<div class="timeline--container" *ngIf="timelines && timelines.length > 0">
  <ol class="timelines">
    <li
      *ngFor="let timeline of timelines"
      class="{{ timeline.cssClass }}"
      [ngClass]="{
        'is-filled': timeline.isFilled
      }">
      <div class="timeline--row">
        <div class="text__small timeline--title" *ngIf="timeline.title">
          <span>{{ timeline.title }}</span>
        </div>
        <span class="text__xsmall night-lighter timeline--subtitle" *ngIf="timeline.subtitle">
          {{ timeline.subtitle }}
        </span>
      </div>
    </li>
  </ol>
</div>
