import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-reservation-details',
  templateUrl: './dialog-reservation-details.component.html',
  styleUrls: ['./dialog-reservation-details.component.scss'],
  providers: [SelectedReservationService],
})
export class DialogReservationDetailsComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogReservationDetailsComponent>,
    private selectedReservationService: SelectedReservationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.selectedReservationService.setActiveReservation(this.data.reservation);
    this.selectedReservationService.getThreadDataFromCalendar(this.data.reservation.thread_uuid);
  }
}
