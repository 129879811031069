<div [class.property-selector-border]="border" class="tax-selection">
  <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="8px" class="rule__scope-header"></div>
  <mat-spinner *ngIf="loading" [diameter]="32"></mat-spinner>
  <div *ngIf="!loading">
    <div>
      <sbnb-search-bar placeholderText="Search..." (searchChanged)="hostsSearchUpdated($event)"></sbnb-search-bar>
    </div>
    <div class="rules__scrollable">
      <ng-container *ngFor="let tax of possibleTaxes">
        <div *ngIf="tax.hidden != true" fxLayout fxLayoutGap="4px" fxLayoutAlign="start center">
          <mat-checkbox
            style="width: 100% !important"
            [checked]="selectedTaxesUuids.indexOf(tax.uuid) > -1"
            (change)="taxCheckboxChange(tax, $event)">
            <div fxLayout fxLayoutGap="16px" fxLayoutAlign="start center" style="width: 100%">
              <span class="fee-label">{{ tax.name }}</span>

              <span class="text__small">
                {{ tax.value }}
                <span *ngIf="tax.charge_type === 'percent'">
                  % of
                  <ng-container *ngIf="tax.subjects?.length > 0">
                    <span *ngFor="let subject of tax.subjects; let last = last">
                      {{ subject.replace('-', ' ') }}
                      <span *ngIf="!last">,</span>
                    </span>
                  </ng-container>
                </span>
                <span *ngIf="tax.calculation_type">{{ tax.calculation_type.replace('-', ' ') }}</span>
              </span>
              <mat-spinner [diameter]="16" *ngIf="itemLoading?.uuid == tax.uuid"></mat-spinner>
            </div>
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
</div>
