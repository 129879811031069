<div class="flex items-center gap-1" *ngIf="email && !canChangeEmail">
  <a [href]="'mailto:' + email" class="cursor-pointer flex items-center gap-1 underline">
    <sbnb-icon icon="message" class="text-smoke-darker"></sbnb-icon>
    <sbnb-truncate [text]="email"></sbnb-truncate>
  </a>
  <sbnb-copy-to-clipboard [mini]="true" [content]="email"></sbnb-copy-to-clipboard>
</div>

<div *ngIf="canChangeEmail" class="input__editable flex items-center gap-1">
  <sbnb-icon icon="message" class="text-smoke-darker"></sbnb-icon>
  <span #emailSpan class="py-0 invisible absolute -left-[9999px]">
    {{ email }}
  </span>

  <input
    class="input__borderless"
    type="text"
    [style.width.px]="email ? emailSpan.getBoundingClientRect().width + 10 : 260"
    [(ngModel)]="email"
    placeholder="Add an email address for this guest"
    (blur)="updateGuestEmail(email)"
    (keydown.enter)="$event.preventDefault(); updateGuestEmail(email)" />
</div>
