import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { DialogPreviewMessageComponent } from '@app/shared/components/dialog-preview-message/dialog-preview-message.component';
import { EditorApiService, SmartCode } from '@app/shared/services/editor/editor-api.service';
import {
  CUSTOM_CODE_REGEX,
  EditorService,
  SHORT_CODE_REGEX,
  SMART_CODE_INSERT_STRATEGY_MAP,
} from '@app/shared/services/editor/editor.service';
import { LanguageService } from '@app/shared/services/language/language.service';
import { RuleService } from '@app/shared/services/rule/rule.service';
import { TranslateService } from '@app/shared/services/translate/translate.service';
import { dedupeArray } from '@app/shared/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { TributeOptions } from 'tributejs';
import { DialogCreateCustomCodeComponent } from '../dialog-create-custom-code/dialog-create-custom-code.component';
import { DialogDeleteRuleComponent } from '../dialog-delete-rule/dialog-delete-rule.component';

declare let window: any;
declare let document: any;

interface TributeMenuItem {
  key: string;
  value: string;
  type: 'sc' | 'cc';
  addCode?: boolean;
}

const buildTributeCodeItems = (smartCodes: string[], customCodes: string[]): TributeMenuItem[] => {
  return [
    ...smartCodes.map(
      (sc) =>
        ({
          key: `${sc}`,
          value: `<span class="shortcode" contenteditable="false">%${sc}%</span>`,
          type: 'sc',
        }) as TributeMenuItem
    ),
    ...customCodes.map(
      (cc) =>
        ({
          key: `${cc}`,
          value: `<span class="customcode" contenteditable="false">%%${cc}%%</span>`,
          type: 'cc',
        }) as TributeMenuItem
    ),
  ];
};

const ADD_CUSTOM_CODE_OPTION = {
  key: '<strong style="color: #007cff">Create a custom code...</strong>',
  value: '',
  addCode: true,
};

const decorateSmartCode = (smartCodeKey: SmartCode['key'], position): string => {
  if (position === 'cursor') {
    return `\n%${smartCodeKey}%\n`;
  }

  if (position === 'prepend') {
    return `%${smartCodeKey}%\n`;
  }

  if (position === 'append') {
    return `\n%${smartCodeKey}%`;
  }
};

@Component({
  standalone: false,
  selector: 'sbnb-rule-editor',
  templateUrl: './rule-editor.component.html',
  styleUrls: ['./rule-editor.component.scss'],
  providers: [EditorService],
})
export class RuleEditorComponent implements OnInit, OnChanges {
  rules: any[];
  savePending: boolean;
  private subjectRuleEdit: Subject<any> = new Subject();
  private subjectMetadataEdit: Subject<any> = new Subject();

  @Input() ruleSetId: number;
  @Input() simpleMode = false; // Ignore all the Ruleset/Rule stuff, and just accept a simple input/output
  @Input() simpleInput: string;
  @Input() ruleType = 'all';
  @Input() importEnabled = false;
  @Input() codesEnabled = false;
  @Input() previewEnabled = true;
  @Input() emailSubject = false;
  @Input() ruleSetData: any;
  @Input() fixedHeight: string;

  @Output() saveStatusChange: EventEmitter<number> = new EventEmitter();
  @Output() simpleTextChange: EventEmitter<string> = new EventEmitter();

  public simpleInputHtml$ = new BehaviorSubject<string>('');

  simpleOutput: string;
  selectedTabIndex = 0;

  languages: any[];
  languagesWeAlreadyHave: any[] = [];

  importableCannedResponses: any[];

  languageSearchCriteria: string;
  importSearchCriteria: string;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChildren('messageEditor') messageEditor: QueryList<ElementRef>;
  @ViewChild('simpleEditor') simpleEditor: ElementRef;
  @ViewChild('langSearchInput') langSearchInput: ElementRef;
  @ViewChild('importSearchInput')
  importSearchInput: ElementRef;

  options: TributeOptions<any>;
  public importableCustomCodes$ = this.editorStateService.allCustomCodes$.pipe(
    map((codes) => codes.filter((code) => code.id !== Number(this.ruleSetId ?? 0)))
  );
  public smartCodesInUse$ = this.editorStateService.inUse$.pipe(map((inUse) => inUse.smart?.length));
  public editorPlaceholder = 'Write a message. You can use short codes and custom codes by typing %.';

  constructor(
    private dialog: MatDialog,
    private ruleService: RuleService,
    private editorService: EditorApiService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private readonly editorStateService: EditorService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    const ruleset = changes['ruleSetData'];

    if (ruleset && !ruleset.isFirstChange()) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    if (!this.simpleMode) {
      this.editorStateService
        .fetchSmartCodes(this.ruleType)
        .pipe(switchMap(() => this.ruleService.getRulesForRuleset(this.ruleSetId)))
        .subscribe((res) => {
          res.forEach((rule) => {
            rule.updatedTemplate = rule.template;
            rule.template = this.applySpanStylesToTributeEditor(rule);
            this.languagesWeAlreadyHave.push(rule.language.key);
          });

          this.rules = res;
        });

      this.languageService.getLanguages().subscribe((res) => {
        this.languages = res;
      });
    }

    if (!this.simpleMode || this.importEnabled) {
      if (this.simpleMode) {
        this.getImportableData('customCode');
      } else {
        this.getImportableData();
      }
    }

    if (!this.simpleMode || this.codesEnabled) {
      this.editorService.getCustomAndShortCodes(this.ruleType).subscribe((res) => {
        this.buildTributeOptions(buildTributeCodeItems(res.short, dedupeArray(res.custom)));
      });
    }

    if (this.simpleMode && !this.codesEnabled) {
      this.editorPlaceholder = 'Write a message.';
      this.buildTributeOptions(null);
    }
    this.applySpanStylesToSimpleEditor(this.simpleInput);

    this.subjectRuleEdit.pipe(debounceTime(1000)).subscribe((rule) => {
      this.editorStateService.processSmartCodeUsageInText(this.messageEditorElement?.innerText);
      if (!this.simpleMode) {
        this.saveRule(rule);
      } else {
        this.simpleTextChange.emit(this.simpleOutput);
      }
    });

    this.subjectMetadataEdit.pipe(debounceTime(1000)).subscribe((rule) => {
      this.saveRule(rule);
    });
  }

  buildTributeOptions(codes?: TributeMenuItem[]) {
    this.options = {
      trigger: '%',
      replaceTextSuffix: '', // What to insert AFTER replacing a token
      requireLeadingSpace: false,
      values: codes ? [...codes, ADD_CUSTOM_CODE_OPTION] : [],

      selectTemplate: (item) => {
        if (item.original.addCode) {
          this.openNewCustomCodeDialog();
          return '';
        }

        return item.original.value;
      },

      menuItemTemplate: function (item) {
        if (item.original.type === 'sc') {
          return `<span class="shortcode" contenteditable="false">%${item.string}%</span>`;
        }

        if (item.original.type === 'cc') {
          return `<span class="customcode" contenteditable="false">%%${item.string}%%</span>`;
        }

        if (item.original.addCode) {
          return `<strong style="color: #007cff">Create a custom code...</strong>`;
        }
      },
    };
  }

  onKeyUp(rule: any, newTemplate) {
    this.savePending = true;

    if (!this.simpleMode) {
      rule.updatedTemplate = newTemplate;
    } else {
      this.simpleOutput = newTemplate;
      this.simpleTextChange.emit(this.simpleOutput);
    }

    this.subjectRuleEdit.next(rule);
  }

  saveRule(rule) {
    this.saveStatusChange.emit(1);
    this.ruleService.updateRule(rule.id, rule).subscribe((res) => {
      this.saveStatusChange.emit(2);
      this.savePending = false;

      // Fetch fresh data for the rule - as some things may have changed (like rule warnings)
      const posInArray: number = this.rules.findIndex((rule) => rule.id === res.data.id);

      if (posInArray > -1) {
        this.rules[posInArray].warnings = res.data.warnings;
      }
    });
  }

  updateEmailSubject(rule, newContent) {
    if (!rule.metadata) {
      rule.metadata = {};
    }

    rule.metadata.subject_updated = newContent;
    this.subjectRuleEdit.next(rule);
  }

  deleteRulePrompt(rule) {
    const dialogRef = this.dialog.open(DialogDeleteRuleComponent, {
      width: '720px',
      data: {
        rule,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteRule(rule);
      }
    });
  }

  deleteRule(rule) {
    this.ruleService.deleteRule(rule).subscribe((res) => {
      if (res.success) {
        const index = this.rules.findIndex((mainRule) => mainRule.id === rule.id);

        if (index !== -1) {
          this.rules.splice(index, 1);

          // Make it possible again to add a rule for this language
          const langIndex = this.languagesWeAlreadyHave.findIndex((lang) => lang === rule.language.key);

          if (langIndex > -1) {
            this.languagesWeAlreadyHave.splice(langIndex, 1);
          }
        }
      }
    });
  }

  openNewCustomCodeDialog() {
    const dialogRef = this.dialog.open(DialogCreateCustomCodeComponent, {
      width: '720px',
      height: '80%',
      data: {
        routeOnCreate: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Refetch the list of codes available
      this.importableCustomCodes$ = this.editorStateService.refreshCustomCodes();
      this.getImportableData();
    });
  }

  stripStyles(e, rule) {
    e.preventDefault();

    if (e.clipboardData) {
      const content = (e.originalEvent || e).clipboardData.getData('text/plain');

      document.execCommand('insertText', false, content);
    } else if (window.clipboardData) {
      const content = window.clipboardData.getData('Text');

      document.selection.createRange().pasteHTML(content);
    }

    setTimeout(() => {
      if (rule) {
        // We set false on the line below, as setting it to true rebuilds the editor dom, and snaps the cursor back to the top of the textarea
        this.applySpanStylesToTributeEditor(rule, false);
      }
      this.applySpanStylesToSimpleEditor(this.messageEditorElement.innerText);
    }, 100);
  }

  applySpanStylesToTributeEditor(rule, updateDom = false) {
    const text = rule.updatedTemplate ? rule.updatedTemplate : rule.template;
    const updatedText = this.applySpanStyles(text);

    if (updateDom) {
      const index = this.rules.findIndex((r) => r.language.key === rule.language.key);
      this.messageEditor.toArray()[index].nativeElement.innerHTML = updatedText;
    }

    return updatedText;
  }

  private applySpanStyles(text: string) {
    let updatedText = `${text}`;
    this.editorStateService.processSmartCodeUsageInText(updatedText);
    if (updatedText.match(CUSTOM_CODE_REGEX)) {
      updatedText = updatedText.replace(
        CUSTOM_CODE_REGEX,
        '<span class="customcode" contenteditable="false">%%$1%%&#8291;</span>' // invisible char added to resolve regex overlaps
      );
    }

    if (updatedText.match(SHORT_CODE_REGEX)) {
      updatedText = updatedText.replace(SHORT_CODE_REGEX, (match) => {
        const klass = this.editorStateService.isSmartCode(match.split('%')[1]) ? 'smartcode' : 'shortcode';
        const code = this.editorStateService.getSmartCode(match.split('%')[1]) ?? { label: '' };

        return `<span class="${klass}" data-codeLabel="${code?.label}" contenteditable="false">${match}</span>`;
      });
    }
    return updatedText;
  }

  createNewRule(ruleSetId, langCode) {
    this.languageSearchCriteria = '';
    this.ruleService.createRule(ruleSetId, langCode).subscribe((res) => {
      this.rules.push(res);
      this.languagesWeAlreadyHave.push(res.language.key);
      this.tabGroup.selectedIndex = this.rules.length;

      this.translateRuleContents(res);
    });
  }

  translateRuleContents(newRule: any) {
    // fallback language is currently always 'en'
    if (!this.rules || this.rules.length === 0) {
      alert('not translating, cant find rules');
      return;
    }

    const index = this.rules.findIndex((x) => x.language.key === this.rules[this.selectedTabIndex].language.key);

    let fallbackRule;

    if (index > -1) {
      fallbackRule = this.rules[index];
    } else {
      alert(`We're having trouble translating this rule, please provide a translation yourself`);
      return;
    }

    const fromKey = fallbackRule.language.key;
    const toKey = newRule.language.key;
    let textToTranslate = fallbackRule.updatedTemplate ? fallbackRule.updatedTemplate : fallbackRule.template;

    // Rely on the browser innerText implementation to ensure that we're stripping HTML tags from freshly loaded rules
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = textToTranslate;
    const text = tempDiv.textContent || tempDiv.innerText || '';
    textToTranslate = text;

    // we have everything we need, lets party
    newRule.translating = true;

    this.translateService.translate(fromKey, toKey, textToTranslate).subscribe((res) => {
      if (res) {
        const index = this.rules.findIndex((x) => x.language.key === toKey);

        if (index > -1) {
          const rule = this.rules[index];
          rule.template = res;
          rule.updatedTemplate = res;
          rule.template = this.applySpanStylesToTributeEditor(rule, true);

          this.saveRule(rule);
        }
        newRule.translating = false;
      }
    });
  }

  insertIntoEditor(text, positionStrategy: 'cursor' | 'append' | 'prepend' = 'cursor') {
    if (this.simpleMode) {
      this.simpleOutput += text;
      this.simpleTextChange.emit(this.simpleOutput);
      this.applySpanStylesToSimpleEditor(this.simpleOutput);
      return;
    }

    const rule = this.rules[this.tabGroup?.selectedIndex ?? 0];
    const template = rule.updatedTemplate ?? rule.template;
    let inserted = false;

    if (positionStrategy === 'cursor') {
      inserted = this.pasteHtmlAtCaret(text);
      if (inserted) {
        rule.updatedTemplate = this.messageEditorElement.innerText;
      }
    }

    // fallback to appending the text if the cursor strategy fails
    if (!inserted || positionStrategy === 'append') {
      rule.updatedTemplate = template + text;
      this.messageEditorElement.innerHTML = rule.updatedTemplate;
    }

    if (positionStrategy === 'prepend') {
      rule.updatedTemplate = text + template;
      this.messageEditorElement.innerHTML = rule.updatedTemplate;
    }

    this.applySpanStylesToTributeEditor(rule, true);

    // If there are multiple rules (multiple languages), reprocess the other
    // templates to check smart code use. If the smartcode has not been included in
    // all of the templates, it should still be visible in the footer
    this.rules
      .filter((r) => r.id !== rule.id)
      .forEach((r) => {
        this.applySpanStylesToTributeEditor(r);
      });

    this.saveRule(rule);
  }

  pasteHtmlAtCaret(html) {
    let sel, range;
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection();
      if (
        sel.getRangeAt &&
        sel.rangeCount &&
        ((sel.anchorNode.classList && sel.anchorNode.classList.contains('rule__textarea-editor')) ||
          sel.anchorNode.parentNode.classList.contains('rule__textarea-editor') ||
          sel.anchorNode.parentNode.parentNode.classList.contains('rule__textarea-editor'))
      ) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        const el = document.createElement('div');
        el.innerHTML = html;
        let frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      } else {
        return false;
      }
    } else if (document.selection && document.selection.type != 'Control') {
      // IE < 9
      document.selection.createRange().pasteHTML(html);
    }

    return true;
  }

  getImportableData(type = 'ruleset') {
    this.ruleService.getRulesets('canned', null, null, null, 1000, this.ruleSetId, type).subscribe((res) => {
      this.importableCannedResponses = res.data.filter((rule) => rule.id !== Number(this.ruleSetId ?? 0));
    });
  }

  openMessagePreviewDialog() {
    const rule = this.rules[this.selectedTabIndex];
    let ruleId = null;

    if (rule) {
      ruleId = rule.id;
    }

    this.dialog.open(DialogPreviewMessageComponent, {
      width: '720px',
      maxHeight: '90vh',
      data: {
        rulesetId: this.ruleSetId,
        event: this.ruleType,
        rulesetData: this.ruleSetData,
        ruleId: ruleId,
      },
    });
  }

  changeActiveTab(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
  }

  filtersMatchSearchCriteria(lang: any, languageSearchCriteria: string): boolean {
    if (!languageSearchCriteria || languageSearchCriteria === '') {
      return true;
    }

    return (
      lang.code.toLowerCase().includes(languageSearchCriteria.toLowerCase()) ||
      lang.native.toLowerCase().includes(languageSearchCriteria.toLowerCase()) ||
      lang.english.toLowerCase().includes(languageSearchCriteria.toLowerCase())
    );
  }

  importMatchSearchCriteria(needle: string, importSearchCriteria: string): boolean {
    if (!importSearchCriteria || importSearchCriteria === '') {
      return true;
    }

    return needle.toLowerCase().includes(importSearchCriteria.toLowerCase());
  }

  focusLanguageSearch() {
    setTimeout(() => {
      this.langSearchInput.nativeElement.focus();
    }, 0);
  }

  focusImportSearch() {
    if (this.importSearchInput?.nativeElement) {
      setTimeout(() => {
        this.importSearchInput.nativeElement.focus();
      }, 0);
    }
  }

  public insertSmartCode(smartCodeKey: SmartCode['key']) {
    const position = SMART_CODE_INSERT_STRATEGY_MAP[smartCodeKey] ?? 'cursor';
    this.insertIntoEditor(decorateSmartCode(smartCodeKey, position), position);
  }

  // This WILL move the caret back to the start of the editor because it updates the DOM
  private applySpanStylesToSimpleEditor(text) {
    if (!this.simpleMode) return;

    if (this.codesEnabled || this.importEnabled) {
      const decoratedText = this.applySpanStyles(text);
      this.simpleInputHtml$.next(decoratedText);
    } else {
      this.simpleInputHtml$.next(text);
    }
  }

  private get messageEditorElement(): HTMLElement {
    if (this.simpleMode) {
      return this.simpleEditor?.nativeElement;
    }

    if (this.tabGroup) {
      return this.messageEditor.toArray()[this.tabGroup.selectedIndex ?? 0].nativeElement;
    }

    return this.messageEditor?.first?.nativeElement;
  }
}
