import { LoadingState, NotificationType, Reservation, SegmentEvent } from '@app/shared/interfaces';
import { ReservationFinancialItem, ReservationService } from '@app/shared/services/reservation/reservation.service';
import {
  ThreadService as ThreadApiService,
  ThreadFinancialItem,
  ThreadFinancialsPayload,
  ThreadPayload,
} from '@app/shared/services/thread/thread.service';
import { BehaviorSubject, Observable, combineLatest, of, zip } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { GuestInsightsService } from '@app/shared/services/guest-insights/guest-insights.service';
import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { dedupeArray } from '@app/shared/utils';

/**
 * Localized service to manage state between the different thread components
 */
@Injectable({
  providedIn: 'any',
})
export class SelectedReservationService {
  private permissions$ = zip(
    this.userPermissionsService.hasAccessTo(Features.PERM_INBOX),
    this.userPermissionsService.hasAccessTo(Features.PERM_RESERVATION_FINANCIALS)
  ).pipe(
    map(([inbox, financials]) => ({
      hasInboxAccess: inbox,
      hasFinancialsAccess: financials,
    })),
    shareReplay(1)
  );

  public activeReservation$ = new BehaviorSubject<Reservation>(null);
  public propertyData$ = this.activeReservation$.pipe(
    filter((reservation) => Boolean(reservation?.uuid)),
    switchMap((reservation) => this.propertiesService.getProperty(reservation.listing.property_id))
  );
  // requires Reservation Financials access
  public reservationFinancialsData$ = combineLatest([this.permissions$, this.activeReservation$]).pipe(
    filter(([{ hasFinancialsAccess }, reservation]) => hasFinancialsAccess && Boolean(reservation?.uuid)),
    switchMap(([, reservation]) => this.reservationService.getReservationFinancials(reservation.uuid)),
    shareReplay(1)
  );

  public extendedReservationData$ = this.activeReservation$.pipe(
    filter((reservation) => Boolean(reservation?.uuid)),
    switchMap((reservation) => this.reservationService.getReservationDetails(null, reservation._links.extended))
  );

  // requires Inbox access
  public threadData$ = new BehaviorSubject<ThreadPayload>(null);
  public threadFinancialData$ = new BehaviorSubject<ThreadFinancialsPayload>(null);

  // requires Inbox access
  public guestInsights$ = this.threadData$.pipe(
    filter((thread) => Boolean(thread)),
    map((thread) => this.guestInsightsService.guestInsightsTransformer(thread))
  );

  // requires Inbox access
  public guestSummary$ = this.threadData$.pipe(
    filter((thread) => Boolean(thread.guest.uuid)),
    switchMap((thread) => this.guestInsightsService.generateGuestOverview(thread.guest.uuid))
  );

  public preapprovalState$ = new BehaviorSubject<LoadingState>(LoadingState.NotSent);

  public tags$ = combineLatest([this.activeReservation$, this.threadData$]).pipe(
    map(([reservation, thread]) => {
      return dedupeArray(
        [...(reservation?.listing?.tags ?? []), ...(thread?.listing?.tags ?? [])].filter((tag) => tag?.length)
      );
    })
  );

  public languageChangeOptions$ = this.threadData$.pipe(
    filter((thread) => Boolean(thread)),
    switchMap((thread) => {
      return this.threadApiService.canThreadChangeLanguage(thread.uuid);
    })
  );

  constructor(
    private propertiesService: PropertiesService,
    private reservationService: ReservationService,
    private threadApiService: ThreadApiService,
    private route: ActivatedRoute,
    private guestInsightsService: GuestInsightsService,
    private userPermissionsService: UserPermissionsService,
    private toast: ToastNotificationsService,
    private segment: SegmentIoService
  ) { }

  public setActiveReservation(reservation: Reservation): void {
    this.activeReservation$.next(reservation);
  }

  public getThreadDataFromThread() {
    combineLatest([this.route.params, this.permissions$])
      .pipe(
        filter(([params, { hasInboxAccess }]) => Boolean(params['id'] && hasInboxAccess)),
        switchMap(([params]) => this.threadApiService.getThread(params['id']))
      )
      .subscribe((thread) => {
        this.threadData$.next(thread);
      });
  }

  public getThreadFinancials() {
    combineLatest([this.route.params, this.permissions$, this.threadData$])
      .pipe(
        filter(([params, { hasInboxAccess }, threadData]) =>
          Boolean(params['id'] && hasInboxAccess && ['inquiry', 'preapproved'].includes(threadData?.status_key))
        ),
        switchMap(([params]) => this.threadApiService.getThreadFinancials(params['id']))
      )
      .subscribe((data) => this.threadFinancialData$.next(data));
  }

  public getThreadDataFromCalendar(threadUuid: string) {
    this.permissions$
      .pipe(
        filter(({ hasInboxAccess }) => hasInboxAccess),
        switchMap(() => this.threadApiService.getThread(threadUuid))
      )
      .subscribe((thread) => {
        this.threadData$.next(thread);
      });
  }

  public getReservationFinanceLineItem$(type: ReservationFinancialItem['type']) {
    return this.reservationFinancialsData$.pipe(
      filter((financials) => Boolean(financials)),
      map(({ data }) => [...data.guest, ...data.host].find((item) => item.type === type))
    );
  }

  public getThreadFinanceLineItem$(type: ThreadFinancialItem['type']) {
    return this.threadFinancialData$.pipe(
      filter((financials) => Boolean(financials)),
      map((financials) => [...financials.guest, ...financials.host].find((item) => item.type === type))
    );
  }

  public preapprove(uuid: string): Observable<void | ThreadPayload> {
    this.preapprovalState$.next(LoadingState.Pending);

    return this.threadApiService.preapprove(uuid).pipe(
      tap((thread) => {
        this.preapprovalState$.next(LoadingState.Success);
        this.threadData$.next({ ...this.threadData$.value, status: thread.status });

        this.segment.track(SegmentEvent.PreapprovedInquiry, {
          thread_id: thread.thread_id,
          platform: thread.platform,
        });
      }),
      catchError(() => {
        this.preapprovalState$.next(LoadingState.Error);
        this.toast.open('Error pre-approving the request', 'Dismiss', NotificationType.Error);
        return of<void>();
      })
    );
  }

  public rejectPreapproval(uuid: string): Observable<void | ThreadPayload> {
    this.preapprovalState$.next(LoadingState.Pending);

    return this.threadApiService.decline(uuid).pipe(
      tap((thread) => {
        this.preapprovalState$.next(LoadingState.Success);
        this.threadData$.next({ ...this.threadData$.value, status: thread.status });

        this.segment.track(SegmentEvent.DeniedInquiry, {
          thread_id: thread.thread_id,
          platform: thread.platform,
        });
      }),
      catchError(() => {
        this.preapprovalState$.next(LoadingState.Error);
        this.toast.open('Error declining the request', 'Dismiss', NotificationType.Error);
        return of<void>();
      })
    );
  }

  public changeLanguage(threadUuid, newLanguageCode: string) {
    this.threadApiService.changeThreadLanguage(threadUuid, newLanguageCode).subscribe((res) => {
      this.toast.open(
        `Language updated, pending messages have been updated accordingly.`,
        'Dismiss',
        NotificationType.Success
      );
      this.threadData$.next({ ...this.threadData$.value });
    });
  }
}
