<sbnb-full-size-dialog (closeDialog)="onCloseDialog()">
  <sbnb-form-edit-review
    *ngIf="!showReviewRulePrompt; else reviewRulePrompt"
    [data]="data"
    (cancelled)="onCloseDialog()"
    (submitted)="onFormSubmit($event)"></sbnb-form-edit-review>
  <ng-template #reviewRulePrompt>
    <div class="flex flex-col" style="gap: 12px; max-width: 550px">
      <h3>Would you like to automate reviews for future guests?</h3>
      <div class="night-lighter">Automate your 5-star reviews for Airbnb guests</div>
      <p>
        Automate your Airbnb guest reviews by creating and enabling a variety of 5-star review templates. Hospitable
        will apply a review for each booking at random, which you can edit during the stay, if needed.
      </p>
      <div class="flex justify-end" style="gap: 24px">
        <sbnb-button type="secondary" (click)="onReviewRulePromptDismiss()">Later</sbnb-button>
        <sbnb-button type="primary" (click)="onReviewRulePromptDismiss(true)">Create Review Rule</sbnb-button>
      </div>
    </div>
  </ng-template>
</sbnb-full-size-dialog>
