<div class="settings-subnav">
  <h4 class="not-clickable no-details flex items-center gap-2.5">
    <img src="/assets/iconography/settings-subnav-profile.svg" alt="" />
    <span class="flex-1">Profile</span>
  </h4>

  <div class="subnav__container">
    <a [routerLink]="['/settings/profile']" routerLinkActive="active-link">Personal Info</a>
    <a [routerLink]="['/settings/security']" routerLinkActive="active-link">Security</a>
  </div>

  <div class="h-px w-full bg-gray-200 my-4 mobile-separator sbnbDesktop:hidden" data-section-separator></div>

  <h4 class="not-clickable flex items-center gap-2.5">
    <sbnb-icon icon="brands" [size]="20" class="text-smoke-darker"></sbnb-icon>
    <span class="flex-1">Brands</span>
  </h4>

  <div class="subnav__container">
    <a [routerLink]="['/settings/brands']" routerLinkActive="active-link">Your Brand</a>
  </div>

  <ng-container *ngIf="perms$.permissions | async as perms">
    <ng-container *ngIf="perms.is_admin">
      <ng-container *ngIf="perms?.is_team_owner">
        <h4 [routerLink]="['/settings/your-plan']" class="flex items-center gap-2.5 no-details">
          <sbnb-icon icon="subscription" [size]="20" class="text-smoke-darker"></sbnb-icon>
          <span class="flex-1">Subscription</span>
        </h4>

        <div class="subnav__container">
          <a [routerLink]="['/settings/your-plan']" routerLinkActive="active-link">Your Plan</a>
          <a *ngIf="notExpired$ | async" [routerLink]="['/settings/active-properties']" routerLinkActive="active-link">
            Active Properties
          </a>

          <!-- Even cancelled users can see their previous invoices -->
          <a [routerLink]="['/settings/invoices']" routerLinkActive="active-link">Invoices</a>
          <a *ngIf="notExpired$ | async" [routerLink]="['/settings/payment-methods']" routerLinkActive="active-link">
            Payment Methods
          </a>
          <!-- Even cancelled users can update their billing details, including tax id -->
          <a [routerLink]="['/settings/billing-details']" routerLinkActive="active-link">Billing Details</a>

          <a
            *ngIf="notExpired$ | async"
            [routerLink]="['/settings/support-tiers']"
            routerLinkActive="active-link"
            class="flex items-center gap-14">
            <span>Support Tiers</span>
          </a>
        </div>
        <div class="h-px w-full bg-gray-200 my-4 mobile-separator sbnbDesktop:hidden" data-section-separator></div>
      </ng-container>

      <ng-container *ngIf="perms?.is_team_owner">
        <h4
          [routerLink]="['/settings/user-management']"
          routerLinkActive="active-link"
          class="flex items-center gap-2.5"
          #rla5="routerLinkActive">
          <sbnb-icon icon="users" class="text-smoke-darker" [size]="20"></sbnb-icon>
          <span class="flex-1">User Management</span>
        </h4>
        <div class="h-px w-full bg-gray-200 my-4 mobile-separator sbnbDesktop:hidden" data-section-separator></div>
      </ng-container>

      <h4 class="not-clickable no-details flex items-center gap-2.5">
        <sbnb-icon icon="preferences" class="text-smoke-darker" [size]="20"></sbnb-icon>
        <span class="flex-1">Preferences</span>
      </h4>

      <div class="subnav__container">
        <a [routerLink]="['/settings/general']" routerLinkActive="active-link">General</a>
        <a [routerLink]="['/settings/notifications']" routerLinkActive="active-link">Notifications</a>
        <a [routerLink]="['/settings/gx']" routerLinkActive="active-link">Guest Experience</a>
        <a [routerLink]="['/settings/operations']" routerLinkActive="active-link">Operations</a>
        <a [routerLink]="['/settings/properties']" routerLinkActive="active-link">Properties</a>
        <a [routerLink]="['/settings/sync']" routerLinkActive="active-link">Sync</a>
        <a *ngIf="enableTaxes$ | async" [routerLink]="['/settings/taxes']" routerLinkActive="active-link">Taxes</a>
      </div>
    </ng-container>
  </ng-container>
</div>
