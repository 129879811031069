import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-support-documentation-title',
  templateUrl: './support-documentation-title.component.html',
  styleUrls: ['./support-documentation-title.component.scss'],
})
export class SupportDocumentationTitleComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit(): void { }
}
