<mat-spinner *ngIf="loading" diameter="16"></mat-spinner>

<ng-container *ngIf="resData && resData.length > 0">
  <section #sections *ngFor="let section of resData">
    <ng-container *ngIf="section.group_id !== 'debug' || isCS">
      <h3>{{ section.label }}</h3>

      <ng-container *ngFor="let item of section.values">
        <hr />
        <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="column" fxLayoutGap.lt-md="8px">
          <div
            [fxFlex]="section.group_id === 'payment-details' ? 'auto' : '220px'"
            fxFlex.lt-md="none"
            class="details__link"
            [ngClass.lt-md]="{ strong: true }">
            {{ item.key }}
          </div>
          <div
            [fxFlex]="section.group_id === 'payment-details' ? '220px' : 'auto'"
            fxFlex.lt-md="auto"
            [class.text-right]="section.group_id === 'payment-details'">
            <a *ngIf="item.url" class="raspberry-darker" target="_blank" [href]="item.url">
              {{ item.value }}
            </a>

            <span *ngIf="!item.url">
              <ng-container
                *ngIf="
                  section.group_id === GroupIds.ListingDetails &&
                    item.key.toLowerCase() === ListingDetailIds.Description;
                  else notListingDetailsDescription
                ">
                <span #descriptionRef [ngClass]="{ 'description-line-clamp': !showMore }">
                  {{ item.value }}
                </span>

                <a
                  *ngIf="!showMore && descriptionContentOverflows"
                  (click)="showMore = true"
                  class="show-more-less-button">
                  Show more
                </a>
                <a class="show-more-less-button" *ngIf="showMore" (click)="showMore = false">Show less</a>
              </ng-container>
              <ng-template #notListingDetailsDescription>
                {{ item.value }}
              </ng-template>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </section>
</ng-container>
