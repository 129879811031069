import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { StatementsFacade } from '@app/modules/statements/services/statements.facade';
import { PropertyMutingService } from '@app/shared/services/property-muting/property-muting.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-mute-property',
  templateUrl: './dialog-mute-property.component.html',
  styleUrls: ['./dialog-mute-property.component.scss'],
})
export class DialogMutePropertyComponent implements OnInit {
  property: any;
  billing: any;
  muting: boolean;
  loading: boolean;
  stage = 1;
  confirmCheckbox: boolean;
  public ownerStatementsEnabled$ = this.statementsService.showOwnerStatements$;

  constructor(
    private statementsService: StatementsFacade,
    private propertyMutingService: PropertyMutingService,
    private router: Router,
    public dialogRef: MatDialogRef<DialogMutePropertyComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      mode: 'mute' | 'unmute';
      propertyId: number;
    }
  ) { }

  ngOnInit(): void {
    this.fetchProperty();
  }

  fetchProperty() {
    this.loading = true;
    this.propertyMutingService.fetchPropertyAndBilling(this.dialogData.propertyId).subscribe((res) => {
      this.loading = false;
      if ('error' in res) {
        // todo
        this.dialogRef.close({ error: true });
        return;
      }

      this.property = res.property;
      this.billing = res.billing;
    });
  }

  muteClicked() {
    this.muting = true;

    switch (this.dialogData.mode) {
      case 'mute':
        this.propertyMutingService.muteProperty(this.dialogData.propertyId).subscribe((res) => {
          this.muting = false;
          this.dialogRef.close({ error: false, muted: true });
        });
        break;

      case 'unmute':
        this.propertyMutingService.unmuteProperty(this.dialogData.propertyId).subscribe((res) => {
          this.muting = false;

          this.stage = 2;
          // this.dialogRef.close({ error: false, muted: false });
        });
        break;
    }
  }

  openOnboarding() {
    this.dialogRef.close();
    this.router.navigate([`/properties/property/${this.property.id}/onboarding`]);
  }
}
