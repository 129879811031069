<div
  fxLayout="row"
  class="thread__guest-container"
  [ngClass.lt-md]="{ mobile: true }"
  *ngIf="thread?.guest?.first_name">
  <img
    fxHide.lt-md
    fxFlex="80px"
    class="thread__guest-img"
    [src]="thread.guest.picture"
    onerror="this.src='/assets/images/profile-default.png'" />
  <a
    *ngIf="thread.guest?.profile_url"
    fxHide.lt-md
    [attr.href]="thread.guest.profile_url.indexOf('https://') == 0 ? thread.guest.profile_url : null"
    target="_blank">
    <img
      class="inbox__guest-platform box-shadow"
      [class.inbox__guest-platform--with-guests]="thread.has_additional_guests"
      [src]="'/assets/images/' + (thread.guest.platform | lowercase) + '-16.svg'"
      alt="{{ thread.guest.platform }}" />
  </a>
  <img
    *ngIf="!thread.guest?.profile_url"
    fxHide.lt-md
    class="inbox__guest-platform box-shadow"
    [class.inbox__guest-platform--with-guests]="thread.has_additional_guests"
    [src]="'/assets/images/' + (thread.guest.platform | lowercase) + '-16.svg'"
    alt="{{ thread.guest.platform }}" />
  <sbnb-additional-guests-badge
    *ngIf="thread.has_additional_guests && thread.guests?.length"
    fxHide.lt-md
    class="inbox__additional-guests-badge"
    [guests]="thread.guests"></sbnb-additional-guests-badge>
  <div class="thread__guest-details">
    <h2 fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
      <img
        class="clickable mobile__padded-button"
        fxHide
        fxShow.lt-md
        src="/assets/iconography/left-arrow.svg"
        (click)="goBack()" />

      <div fxLayout fxLayoutAlign="start center">
        <img
          fxHide
          fxShow.lt-md
          class="thread__guest-img--small"
          [src]="thread.guest.picture"
          (error)="
            guestPictureError = true;
            mobilePlatformIconSize = '24px';
            mobilePlatformIconClass = 'inbox__guest-platform--without-photo'
          "
          onerror="this.style.display = 'none'" />

        <sbnb-additional-guests-badge
          *ngIf="thread.has_additional_guests && thread.guests?.length"
          fxHide
          fxShow.lt-md
          class="inbox__additional-guests-badge inbox__additional-guests-badge--mobile"
          [guests]="thread.guests"></sbnb-additional-guests-badge>

        <a
          fxHide
          *ngIf="thread.guest?.profile_url"
          fxShow.lt-md
          [attr.href]="thread.guest.profile_url.indexOf('https://') == 0 ? thread.guest.profile_url : null"
          target="_blank">
          <sbnb-icon-platform
            [ngClass]="mobilePlatformIconClass"
            [platform]="thread.guest.platform"
            [height]="mobilePlatformIconSize"
            [width]="mobilePlatformIconSize"></sbnb-icon-platform>
        </a>

        <div class="input__editable">
          <span
            #nameSpan
            style="padding-left: 0; padding-right: 8px; visibility: hidden; position: absolute; left: -9999px">
            {{ thread.guest?.custom_first_name }}
          </span>

          <input
            [matTooltip]="thread.guest.first_name !== thread.guest.custom_first_name ? thread.guest.first_name : null"
            class="input__borderless"
            type="text"
            [style.width.px]="nameSpan.getBoundingClientRect().width"
            [(ngModel)]="thread.guest.custom_first_name"
            (change)="updateGuestName()" />
        </div>

        <div class="input__editable">
          <span #nameSpan2 style="padding: 0 4px; visibility: hidden; position: absolute; left: -9999px">
            {{ thread.guest.custom_last_name }}
          </span>

          <input
            [matTooltip]="thread.guest.last_name !== thread.guest.custom_last_name ? thread.guest.last_name : null"
            class="input__borderless"
            type="text"
            [style.width.px]="nameSpan2.getBoundingClientRect().width"
            [(ngModel)]="thread.guest.custom_last_name"
            (change)="updateGuestName()" />
        </div>

        <mat-spinner *ngIf="updatingGuestName" style="margin-left: 12px" [diameter]="16"></mat-spinner>
        <img
          *ngIf="recentlyUpdatedGuestName"
          style="margin-left: 12px"
          src="/assets/images/onboarding/check.svg"
          alt="Completed" />
      </div>

      <!-- Start:  Star and unstar the conversation -->
      <sbnb-star-conversation
        [starred]="thread.starred"
        [threadUuid]="thread.uuid"
        (starredToggled)="thread.starred = $event"></sbnb-star-conversation>

      <!-- End:  Star and unstar the conversation -->

      <!-- Start:  Translate the conversation -->
      <sbnb-translate-conversation
        [usingTranslations]="thread.using_translations"
        [threadUuid]="thread.uuid"
        (translationsToggled)="translationsToggled($event)"></sbnb-translate-conversation>
      <!-- End:  Translate the conversation -->

      <!-- Start:  Resolve the conversation -->
      <sbnb-resolve-conversation
        [resolved]="thread.resolved"
        [threadUuid]="thread.uuid"
        (resolvedToggled)="thread.resolved = $event"></sbnb-resolve-conversation>
      <!-- End:  Resolve the conversation -->
    </h2>
    <div class="guest__details" fxLayout="row wrap" fxLayoutGap="16px grid">
      <div *ngIf="thread?.guest && thread?.guest?.ui_location" fxLayoutGap="8px" fxLayoutAlign="start center">
        <img src="/assets/iconography/location.svg" alt="location" />
        <span>{{ thread.guest.ui_location }}</span>
      </div>
      <div *ngIf="guestsTime()" fxLayoutGap="8px" fxLayoutAlign="start center">
        <sbnb-icon icon="time" class="shrink-0 text-smoke-darker"></sbnb-icon>
        <span>{{ guestsTime() }}</span>
      </div>
      <sbnb-language-switcher
        *ngIf="currentLanguage"
        [canChangeLanguage]="canChangeLanguage"
        [currentLanguage]="currentLanguage"
        [availableLanguages]="availableLanguages"
        (languageChange)="languageChanged($event)"></sbnb-language-switcher>

      <div
        *ngIf="thread.platform === 'airbnb-official' || thread.platform === 'homeaway'"
        fxLayoutGap="8px"
        fxLayoutAlign="start center">
        <img src="/assets/iconography/review.svg" alt="reviews" />

        <span *ngIf="thread.platform === 'airbnb-official'">{{ thread?.guest?.reviews?.length }} reviews</span>

        <span *ngIf="thread.platform === 'homeaway'">{{ thread?.guest?.ratings?.overall?.count || '0' }} reviews</span>
      </div>

      <div class="cursor-pointer flex items-center gap-1" *ngIf="thread?.guest?.phone">
        <a [href]="'tel:' + thread.guest.phone" class="flex items-center gap-1 underline">
          <sbnb-icon icon="phone" class="text-smoke-darker"></sbnb-icon>
          <span>{{ thread.guest.phone }}</span>
        </a>
        <sbnb-copy-to-clipboard
          class="hidden md:block"
          [mini]="true"
          [content]="thread.guest.phone"></sbnb-copy-to-clipboard>
      </div>

      <div class="cursor-pointer flex items-center gap-1" *ngIf="thread?.guest?.email && !canChangeEmail">
        <a [href]="'mailto:' + thread.guest.email" class="cursor-pointer flex items-center gap-1 underline">
          <sbnb-icon icon="message" class="text-smoke-darker"></sbnb-icon>
          <span>{{ thread.guest.email }}</span>
        </a>
        <sbnb-copy-to-clipboard
          class="hidden md:block"
          [mini]="true"
          [content]="thread.guest.email"></sbnb-copy-to-clipboard>
      </div>

      <div *ngIf="canChangeEmail" class="input__editable" fxLayout fxLayoutAlign="start start" fxLayoutGap="8px">
        <sbnb-icon icon="message" class="text-smoke-darker"></sbnb-icon>
        <span
          #emailSpan
          style="padding-left: 0; padding-right: 8px; visibility: hidden; position: absolute; left: -9999px">
          {{ thread.guest.email }}
        </span>

        <input
          class="input__borderless email-input"
          type="text"
          [style.width.px]="thread.guest.email ? emailSpan.getBoundingClientRect().width : 260"
          [(ngModel)]="thread.guest.email"
          placeholder="Add an email address for this guest"
          (change)="updateGuestEmail()" />
      </div>

      <ng-container *ngIf="tags$ | async as tags">
        <div *ngIf="tags?.length" class="flex gap-2">
          <sbnb-icon icon="tag" class="shrink-0 text-raspberry-darker"></sbnb-icon>
          <sbnb-tags [tags]="tags"></sbnb-tags>
        </div>
      </ng-container>
    </div>
  </div>
</div>
