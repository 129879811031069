import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

/**
 * This extends the default Angular route reuse strategy to allow for a custom shouldReuseRoute method
 * that uses a data attribute on the route config to determine if that route should be reused.
 * This is useful for routes where the user switches between params, like the single property calendar or
 * the inbox segments.
 * This is safer than setting the strategy in the component because setting it in the component
 * sets it globally for all routes after the setting change is made, which is often not what is intended and can
 * lead to unexpected behaviour.
 */
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.routeConfig !== curr.routeConfig) {
      return false;
    }
    return future.data?.shouldReuse ?? true;
  }
}
