import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'sbnb-fullscreen-upload-dropzone',
  template: `
    <div
      class="fixed inset-0 bg-black bg-opacity-40 text-white flex justify-center items-center text-2xl pointer-events-none z-[100]"
      *ngIf="isDragging$ | async">
      Drop here to upload your image
    </div>
    <div class="hidden" sbnbDragAndDrop (filesDropped)="selectFiles($event)" (isDragging)="setIsDragging($event)"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenUploadDropzoneComponent {
  private isDraggingSubject = new BehaviorSubject<boolean>(false);
  isDragging$ = this.isDraggingSubject.asObservable();

  @Output() filesDropped: EventEmitter<FileList> = new EventEmitter<FileList>();

  selectFiles(files: FileList) {
    if (files && files.length > 0) {
      this.filesDropped.emit(files);
    }
  }

  setIsDragging(dragging: boolean) {
    this.isDraggingSubject.next(dragging);
  }
}
