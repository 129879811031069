import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { User } from '@app/shared/interfaces';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import {
  BufferTimeUnits,
  NotificationSetting,
  SmartLockSettings,
  SmartlocksService,
} from '@app/shared/services/smartlocks/smartlocks.service';

@Component({
  standalone: false,
  selector: 'app-settings-smartlocks',
  templateUrl: './settings-smartlocks.component.html',
  styleUrls: ['./settings-smartlocks.component.scss'],
})
export class SettingsSmartlocksComponent implements OnInit {
  loading: boolean = true;
  saveStatus: number;
  savingSettings: boolean = false;
  smartlocksSettings: SmartLockSettings | null = null;
  user: User = this.authService.getUserDetails();

  notificationMetadata = {
    battery_status: {
      title: 'Battery status is low',
      tooltip: 'Low battery threshold is set in the device details.',
    },
    code_first_used: {
      title: 'Code first used',
    },
    device_errors: {
      title: 'Device warnings',
      hidden: true,
      tooltip:
        'Device related errors/warning will always show in the device details regardless of notification settings.',
    },
    other_issues: {
      title: 'Issues that require attention',
      disabled: true,
      hidden: true,
    },
  };

  @Input() canEditSettings = true; // notification settings can always be changed regardless of this setting
  @Input() showNotifications = true;

  constructor(
    private readonly smartlocksService: SmartlocksService,
    private authService: AuthenticationService,
    private optimizely: OptimizelyService
  ) {}

  ngOnInit(): void {
    this.getSmartlockSettings();
  }

  getSmartlockSettings() {
    this.loading = true;

    this.smartlocksService.getSmartlockSettings().subscribe((res: SmartLockSettings) => {
      this.smartlocksSettings = res;
      this.loading = false;
    });
  }

  saveSmartlockSettings() {
    this.saveStatus = 1;
    this.savingSettings = true;

    this.smartlocksService.saveSmartlockSettings(this.smartlocksSettings).subscribe((res) => {
      this.smartlocksSettings = res;
      this.saveStatus = 2;
      this.savingSettings = false;
    });
  }

  changeCheckInBufferUnits(unit: BufferTimeUnits) {
    this.smartlocksSettings.check_in_time_buffer.unit = unit;
  }

  changeCheckOutBufferUnits(unit: BufferTimeUnits): void {
    this.smartlocksSettings.check_out_time_buffer.unit = unit;
  }

  updateAutohostMethod(method: 'always' | 'exclude_last_minute') {
    this.smartlocksSettings.delay.method = method;
  }

  updateAddBuffersToTimings(addBuffers: boolean) {
    this.smartlocksSettings.add_buffers_to_comms = addBuffers;
    console.log(this.smartlocksSettings);
  }

  parentNotificationToggled(event: MatCheckboxChange, type: NotificationSetting) {
    if (!event.checked) {
      type.email = false;
      type.push = false;
    }
  }

  validateNumericInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = !!input.value && Math.abs(+input.value) >= 0 ? Math.abs(+input.value).toString() : '';
  }
}
