<div fxLayout fxLayoutAlign="start center">
  <strong fxFlex>Notes</strong>
  <sbnb-save-indicator [saveStatus]="saveStatus"></sbnb-save-indicator>
  <sbnb-button
    size="mini"
    label=" + Add"
    type="secondary"
    fxLayoutAlign="start center"
    *ngIf="!showNotesInput && !saveStatus"
    (click)="addNote($event)"></sbnb-button>
</div>

<div class="textarea-container" *ngIf="showNotesInput">
  <textarea
    autofocus
    placeholder="Add a note for this conversation"
    cdkTextareaAutosize
    cdkAutosizeMinRows="3"
    [(ngModel)]="note"
    (ngModelChange)="noteUpdated($event)"></textarea>
</div>
