import { Injectable } from '@angular/core';
import { BillingFacade } from '@app/shared/+state/billing';
import { UsersFacade } from '@app/shared/+state/users';
import { PosthogFeatureFlag } from '@app/shared/interfaces/lib/feature-flags.interface';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { ThreadPayload, ThreadService } from '@app/shared/services/thread/thread.service';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SentimentAnalysisService {
  public enabled$ = combineLatest([
    this.posthog.featureEnabled$(PosthogFeatureFlag.SentimentAnalysis),
    this.billingFacade.isMogulSubscription$,
    this.usersFacade.mogulAiEntitlement$
  ]).pipe(
    map(([featureEnabled, isMogul, mogulAiEntitlement]) => featureEnabled || isMogul || mogulAiEntitlement?.available),
    shareReplay(1) // Cache the latest value and share it among all subscribers
  );

  constructor(
    private posthog: PosthogService,
    private billingFacade: BillingFacade,
    private threadService: ThreadService,
    private readonly usersFacade: UsersFacade
  ) {}

  dismissAlert(threadId: string): Observable<ThreadPayload> {
    return this.threadService.dismissSentimentAlert(threadId);
  }

  cancelScheduledReviews(threadId: string): Observable<ThreadPayload> {
    return this.threadService.cancelScheduledReviews(threadId);
  }
}
