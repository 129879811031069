import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

@Component({
  standalone: false,
  selector: 'sbnb-truncate',
  template: `
    <div
      #tooltipRef="matTooltip"
      [sbnbThrottleTooltip]="tooltipRef"
      [matTooltip]="tooltip ?? text"
      [matTooltipPosition]="tooltipPosition"
      class="ellipsis">
      {{ text }}
    </div>
  `,
})
export class TruncateComponent implements OnInit {
  /** The text to be truncated */
  @Input() text: string;

  /** Custom tooltip text (defaults to the full text if not provided) */
  @Input() tooltip: string = null;

  /** Position of the tooltip relative to the truncated text */
  @Input() tooltipPosition: MatTooltip['position'] = 'above';

  constructor() {}

  ngOnInit(): void {}
}
