import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { DialogReportInvalidPaymentComponent } from '@app/shared/components/dialog-report-invalid-payment/dialog-report-invalid-payment.component';
import { DialogReservationDetailsComponent } from '@app/shared/components/dialog-reservation-details/dialog-reservation-details.component';
import { DialogReviewGuestComponent } from '@app/shared/components/dialog-review-guest/dialog-review-guest.component';
import { PosthogFeatureFlag, Reservation } from '@app/shared/interfaces';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { DialogManualBookingsService } from '@app/shared/services/manual-bookings/dialog-manual-bookings.service';
import { ManualBooking, ManualBookingsService } from '@app/shared/services/manual-bookings/manual-bookings.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { ReservationService } from '@app/shared/services/reservation/reservation.service';
import { SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';
import { SentimentAnalysisService } from '@app/shared/services/sentiment-analysis/sentiment-analysis.service';
import { ThreadPayload, ThreadService } from '@app/shared/services/thread/thread.service';
import { setHours, setMinutes } from 'date-fns';
import { filter, switchMap, take } from 'rxjs/operators';
import { DialogCancelReservationFlowComponent } from '../dialog-cancel-reservation-flow/dialog-cancel-reservation-flow.component';
import { DialogSendAlterationRequestComponent } from '../dialog-send-alteration-request/dialog-send-alteration-request.component';

@Component({
  standalone: false,
  selector: 'sbnb-reservation-card',
  templateUrl: './reservation-card.component.html',
  styleUrls: ['./reservation-card.component.scss'],
  providers: [DatePipe],
})
export class ReservationCardComponent implements OnInit, OnChanges {
  Features = Features;

  @Input() activeReservation: any;
  @Input() thread: ThreadPayload;
  @Input() showDetailsButton = true;
  @Output() eventUpdated: EventEmitter<any> = new EventEmitter();
  @Output() reservationNeedsUpdating: EventEmitter<any> = new EventEmitter();

  perms$ = this.userPermissionsService;
  public sentimentAnalysisEnabled$ = this.posthogService.featureEnabled$(PosthogFeatureFlag.SentimentAnalysis);

  parentChildBlockedBy: string | null = null;
  public reservationRevenue$ = this.selectedReservationService.getReservationFinanceLineItem$('revenue');
  public inquiryRevenue$ = this.selectedReservationService.getThreadFinanceLineItem$('revenue');

  isMobile$ = this.deviceDetectionService.isMobileViewport();
  sentimentEnabled$ = this.sentimentAnalysisService.enabled$;

  constructor(
    private reservationService: ReservationService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private userPermissionsService: UserPermissionsService,
    private manualBookingsService: ManualBookingsService,
    private dialogManualBookingsService: DialogManualBookingsService,
    private router: Router,
    private selectedReservationService: SelectedReservationService,
    private posthogService: PosthogService,
    private deviceDetectionService: DeviceDetectionService,
    private route: ActivatedRoute,
    private threadService: ThreadService,
    private sentimentAnalysisService: SentimentAnalysisService,
    private segmentIoService: SegmentIoService
  ) {}

  ngOnInit() {
    if (this.activeReservation.isParentChildReservation) {
      const trueBookingSource = this.activeReservation.parent_child_properties.find((prop) => {
        return prop.property_id === this.activeReservation.listing.property_id;
      });

      if (trueBookingSource) {
        this.parentChildBlockedBy = trueBookingSource.type;
      }
    }

    this.checkAndOpenGuestReviewModal();
  }

  public ngOnChanges() {
    this.selectedReservationService.setActiveReservation(this.activeReservation);
  }

  goToUrl(url: string | null) {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  public goToActiveReservationProperty() {
    if (!this.activeReservation.listing.property_id) return;

    this.router.navigateByUrl(`properties/property/${this.activeReservation.listing.property_id}`);
  }

  updateTime(inOrOut: string, event) {
    const payload = {
      checkin_hour:
        inOrOut === 'checkin' ? event.hours : +this.datePipe.transform(this.activeReservation.start_date, 'H'),
      checkin_minute:
        inOrOut === 'checkin' ? event.minutes : +this.datePipe.transform(this.activeReservation.start_date, 'm'),
      checkout_hour:
        inOrOut === 'checkout' ? event.hours : +this.datePipe.transform(this.activeReservation.end_date, 'H'),
      checkout_minute:
        inOrOut === 'checkout' ? event.minutes : +this.datePipe.transform(this.activeReservation.end_date, 'm'),
    };

    this.reservationService.updateReservationTimes(this.activeReservation.uuid, payload).subscribe((res) => {
      if (inOrOut === 'checkin') {
        this.activeReservation.start_date = setHours(this.activeReservation.start_date, event.hours);
        this.activeReservation.start_date = setMinutes(this.activeReservation.start_date, event.minutes);
      }

      if (inOrOut === 'checkout') {
        this.activeReservation.end_date = setHours(this.activeReservation.end_date, event.hours);
        this.activeReservation.end_date = setMinutes(this.activeReservation.end_date, event.minutes);
      }

      this.eventUpdated.emit(this.activeReservation);
    });
  }

  openThreadDetails(reservation: any) {
    this.dialog.open(DialogReservationDetailsComponent, {
      width: '700px',
      height: '80%',
      autoFocus: false,
      data: { reservation },
    });
  }

  openAlterationRequest(reservation: any) {
    const reservationUuid = reservation.uuid;

    const dialogRef = this.dialog.open(DialogSendAlterationRequestComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        reservationUuid,
        startDate: new Date(reservation.checkin),
        endDate: new Date(reservation.checkout),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reservationNeedsUpdating.emit();
      }
    });
  }

  openEditReservationDialog() {
    const manualReservationId = this.activeReservation.uuid;

    this.manualBookingsService
      .fetchBooking(manualReservationId, this.activeReservation.platform)
      .subscribe((booking: ManualBooking) => {
        this.dialogManualBookingsService
          .openDialog(true, 'update', booking, booking.checkInDate, booking.checkOutDate)
          .subscribe((res) => {
            this.reservationNeedsUpdating.emit(true);
          });
      });
  }

  openCancellationAndRefundDialog(cancelling = true) {
    const dialogRef = this.dialog.open(DialogCancelReservationFlowComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
      data: {
        isCancelling: cancelling,
        reservationUuid: this.activeReservation.uuid,
        reservation: this.activeReservation,
        checkin: this.activeReservation.checkin_label,
        checkout: this.activeReservation.checkout_label,
        guestName: this.activeReservation.guest,
        guestEmail: this.activeReservation.email,
        currency: this.activeReservation.currency_code,
        supported_actions: this.activeReservation.supports,
        isBookingDotCom: this.activeReservation.platform === 'booking',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.reservationNeedsUpdating.emit(true);
      }
    });
  }

  public hasEarlyCheckInUpsell() {
    if (!this.activeReservation.upsells?.products) return false;
    return this.activeReservation.upsells.products.some((product) => product.type === 'early_checkin');
  }

  public hasLateCheckOutUpsell() {
    if (!this.activeReservation.upsells?.products) return false;
    return this.activeReservation.upsells.products.some((product) => product.type === 'late_checkout');
  }

  checkAndOpenGuestReviewModal() {
    this.route.queryParams
      .pipe(
        filter((params) => params['evaluate_guest'] === '1'),
        switchMap(() => this.selectedReservationService.activeReservation$),
        filter((reservation) => Boolean(reservation)),
        take(1)
      )
      .subscribe((reservation) => {
        if (reservation) {
          this.openReviewGuestDialog(reservation);
        }
      });
  }

  openReviewGuestDialog(reservation: Reservation) {
    this.isMobile$.pipe(take(1)).subscribe((isMobile) => {
      const dialog = this.dialog.open(DialogReviewGuestComponent, {
        width: isMobile ? '100%' : '720px',
        height: isMobile ? '100%' : 'auto',
        maxWidth: isMobile ? '100vw' : '720px',
        data: { reservationUuid: reservation.uuid, guestName: reservation.guest },
      });

      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.reservationNeedsUpdating.emit(true);
        }
      });
    });
  }

  openReportInvalidCreditCardDialog() {
    const dialogRef = this.dialog.open(DialogReportInvalidPaymentComponent, {
      width: '500px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.reservationService.reportInvalidPaymentDetails(this.activeReservation.uuid).subscribe(() => {
          this.reservationNeedsUpdating.emit(true);
        });
      }
    });
  }
}
