import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import {
  FanMode,
  HvacMode,
  SmartDevicesService,
  ThermostatSettings,
} from '@app/modules/smart-devices/service/smart-devices.service';
import { User } from '@app/shared/interfaces';
import { finalize } from 'rxjs/operators';

interface NotificationSetting {
  push: boolean;
  email: boolean;
}

@Component({
  standalone: false,
  selector: 'app-settings-thermostats',
  templateUrl: './settings-thermostats.component.html',
  styleUrls: ['./settings-thermostats.component.scss'],
})
export class SettingsThermostatsComponent implements OnInit {
  loading = true;
  saveStatus: number;
  savingSettings = false;
  thermostatSettings: ThermostatSettings | null = null;
  user: User = this.authService.getUserDetails();

  notificationMetadata = {
    thermostat_offline: {
      title: 'If thermostat goes offline',
      tooltip: 'You will be notified when a thermostat loses connection.',
    },
    temperature: {
      title: 'If temperature inside property goes outside following range',
      tooltip: 'You will be notified when temperature goes above or below set thresholds.',
    },
  };

  @Input() canEditSettings = true;
  @Input() showNotifications = true;

  readonly hvacModes = Object.values(HvacMode);
  readonly fanModes = Object.values(FanMode);

  constructor(
    private readonly smartDevicesService: SmartDevicesService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.getThermostatSettings();
  }

  getThermostatSettings(): void {
    this.loading = true;

    this.smartDevicesService
      .getGlobalDeviceSettings()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((settings: ThermostatSettings) => {
        if (settings?.custom_presets) {
          settings.custom_presets.is_on = true;
        }
        this.thermostatSettings = settings;
      });
  }

  saveThermostatSettings(): void {
    if (!this.thermostatSettings) return;

    if (this.thermostatSettings.custom_presets) {
      this.thermostatSettings.custom_presets.is_on = true;
    }

    this.saveStatus = 1;
    this.savingSettings = true;

    this.smartDevicesService
      .updateGlobalDeviceSettings(this.thermostatSettings)
      .pipe(
        finalize(() => {
          this.savingSettings = false;
          this.saveStatus = 2;
        })
      )
      .subscribe((settings: ThermostatSettings) => {
        this.thermostatSettings = settings;
      });
  }

  updateGuestControl(allowed: boolean): void {
    if (!this.thermostatSettings) return;
    this.thermostatSettings.allow_guests_to_control_thermostat = allowed;
  }

  parentNotificationToggled(event: MatCheckboxChange, type: 'thermostat_offline' | 'temperature'): void {
    if (!this.thermostatSettings) return;

    if (!event.checked) {
      this.thermostatSettings.dashboard_notifications[type].email = false;
      this.thermostatSettings.dashboard_notifications[type].push = false;
    }
  }

  updateHvacMode(preset: 'occupied' | 'unoccupied' | 'long_term_unoccupied', mode: HvacMode): void {
    if (!this.thermostatSettings) return;
    this.thermostatSettings.custom_presets[preset].hvac_mode = mode;
  }

  updateFanMode(preset: 'occupied' | 'unoccupied' | 'long_term_unoccupied', mode: FanMode): void {
    if (!this.thermostatSettings) return;
    this.thermostatSettings.custom_presets[preset].fan_mode = mode;
  }
}
