import { Component, OnInit, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-icon-airbnb',
  templateUrl: './icon-airbnb.component.html',
  styleUrls: ['./icon-airbnb.component.scss'],
})
export class IconAirbnbComponent implements OnInit {
  @Input() color = '#ff5a5f';
  @Input() height = '18px';
  @Input() width = '18px';

  constructor() {}

  ngOnInit() {}
}
