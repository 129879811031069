import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'shortDateRange',
})
export class ShortDateRangePipe implements PipeTransform {
  transform(value: [Date | string, Date | string]): string | null {
    if (!value || value.length !== 2) return null;

    const [start, end] = value;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const currentYear = new Date().getFullYear();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = startDate.toLocaleString('default', { month: 'short' });
    const endMonth = endDate.toLocaleString('default', { month: 'short' });
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    // If the start and end year are different, we need to show the full label for each date
    if (startYear !== endYear) {
      return `${startMonth} ${startDay} ${startYear.toString()} - ${endMonth} ${endDay} ${endYear.toString()}`;
    }

    const sameMonth = `${startMonth} ${startDay} - ${endDay}`;
    const differentMonth = `${startMonth} ${startDay} - ${endMonth} ${endDay}`;

    // If it's the current year, we don't need to show the year
    if (startYear === currentYear) {
      startMonth === endMonth ? sameMonth : differentMonth;
    }

    // If it's not the current year, we need to show the year
    return `${startMonth === endMonth ? sameMonth : differentMonth} ${startYear.toString()}`;
  }
}
