import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-profile-img-placeholder',
  template: `
    <div ngClass.lt-md="mobile">
      {{ initials }}
    </div>
  `,
  styles: [
    `
      @import 'variables';

      div {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        background-color: $smoke-darker;
        color: $white;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
      }

      @media #{$mobile} {
        div {
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 10px;
        }
      }
    `,
  ],
})
export class ProfileImgPlaceholderComponent {
  @Input() firstName: string;
  @Input() lastName: string;

  initials: string = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.initials = this.getInitials();
  }

  private getInitials(): string {
    return [this.firstName, this.lastName]
      .filter((namePart) => !!namePart)
      .map((namePart) => namePart[0])
      .join('');
  }
}
