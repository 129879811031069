/* eslint-disable prefer-const */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNumeric } from '@app/shared/utils/lib/isNumeric.util';

@Component({
  standalone: false,
  selector: 'sbnb-rule-timing-recurring',
  templateUrl: './rule-timing-recurring.component.html',
  styleUrls: ['./rule-timing-recurring.component.scss'],
})
export class RuleTimingRecurringComponent implements OnInit {
  dayLabel: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('meta') meta: any;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ruleset') ruleset: any;

  @Output() triggerSave: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (!this.ruleset.metadata.recurring) {
      this.ruleset.metadata = {
        recurring: {},
      };
    }

    let day = this.ruleset.metadata.recurring.day ?? this.meta.day[0];
    this.dayChanged(day);

    if (this.ruleset.metadata.recurring.frequency == '' || this.ruleset.metadata.recurring.frequency == null) {
      this.ruleset.metadata.recurring.frequency = this.meta.frequency[0];
    }

    if (this.ruleset.metadata.recurring.hours == '' || this.ruleset.metadata.recurring.hours == null) {
      this.ruleset.metadata.recurring.hours = this.meta.hours;
    }

    if (this.ruleset.metadata.recurring.minutes == '' || this.ruleset.metadata.recurring.minutes == null) {
      this.ruleset.metadata.recurring.minutes = this.meta.minutes;
    }

    if (this.ruleset.metadata.recurring.checkin_day == '' || this.ruleset.metadata.recurring.checkin_day == null) {
      this.ruleset.metadata.recurring.checkin_day = this.meta.checkin_day[0];
    }
  }

  timeChanged(time: any) {
    this.ruleset.metadata.recurring.hours = time.hours;
    this.ruleset.metadata.recurring.minutes = time.minutes;
    this.save();
  }
  frequencyChanged(newFrequency: any) {
    this.ruleset.metadata.recurring.frequency = newFrequency;
    this.save();
  }

  dayChanged(day: any) {
    if (isNumeric(day)) {
      this.dayLabel = this.meta.day[day];
      this.save();
      return;
    }
    this.dayLabel = day;
    this.ruleset.metadata.recurring.day = this.meta.day.indexOf(day);
    this.save();
  }

  checkinDayChanged(newCheckinDayOption: any) {
    this.ruleset.metadata.recurring.checkin_day = newCheckinDayOption;
    this.save();
  }

  save() {
    this.triggerSave.emit();
  }
}
