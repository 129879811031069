<div
  class="pagination__container"
  flexLayout="row"
  [fxLayoutAlign]="position"
  *ngIf="hasMore == null; else CursorPagination">
  <mat-button-toggle-group class="paginator">
    <mat-button-toggle
      class="paginator__prevnext"
      [class.paginator__disabled]="currentPage === 1"
      value="back"
      (click)="changePageEmitter('-')"
      disableRipple>
      <img src="/assets/iconography/left-arrow.svg" />
    </mat-button-toggle>
    <ng-container *ngIf="pages.length <= 10; else lotsOfPages">
      <mat-button-toggle
        *ngFor="let page of pages"
        (click)="changePageEmitter(page)"
        [class.page__active]="currentPage === page"
        disableRipple>
        {{ page }}
      </mat-button-toggle>
    </ng-container>
    <ng-template #lotsOfPages>
      <mat-button-toggle (click)="changePageEmitter(1)" [class.page__active]="currentPage === 1" disableRipple>
        1
      </mat-button-toggle>
      <mat-button-toggle *ngIf="currentPage > 4" disabled="true" disableRipple>...</mat-button-toggle>

      <ng-container *ngFor="let index of [-2, -1, 0, +1, +2]">
        <mat-button-toggle
          *ngIf="currentPage + index > 1 && currentPage + index < pages.length"
          (click)="changePageEmitter(currentPage + index)"
          [class.page__active]="currentPage === currentPage + index"
          disableRipple>
          {{ currentPage + index }}
        </mat-button-toggle>
      </ng-container>

      <mat-button-toggle *ngIf="currentPage < pages.length - 3" disabled="true" disableRipple>...</mat-button-toggle>
      <mat-button-toggle
        (click)="changePageEmitter(pages.length)"
        [class.page__active]="currentPage === pages.length"
        disableRipple>
        {{ pages.length }}
      </mat-button-toggle>
    </ng-template>
    <mat-button-toggle
      class="paginator__prevnext"
      value="forward"
      [class.paginator__disabled]="currentPage === pages.length"
      (click)="changePageEmitter('+')"
      disableRipple>
      <img src="/assets/iconography/right-arrow.svg" />
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<ng-template #CursorPagination>
  <div class="flex justify-end" data-element="cursor-pagination">
    <mat-button-toggle-group class="cursor-paginator">
      <mat-button-toggle
        class="paginator__prevnext"
        [class.paginator__disabled]="!previous"
        [disabled]="!previous"
        value="back"
        (click)="onCursorPrevious()"
        disableRipple
        data-element="load-previous">
        <div class="img-container">
          <img src="/assets/iconography/left-arrow.svg" alt="Previous" />
          Previous
        </div>
      </mat-button-toggle>
      <mat-button-toggle
        class="paginator__prevnext"
        [class.paginator__disabled]="!next"
        [disabled]="!next"
        value="forward"
        (click)="onCursorNext()"
        disableRipple
        data-element="load-next">
        <div class="img-container">
          Next
          <img src="/assets/iconography/right-arrow.svg" alt="Next" />
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-template>
