import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

export enum PayoutMethodProvider {
  STRIPE_OAUTH = 'stripe-oauth',
  ADYEN = 'adyen',
}

export enum AdyenPayoutMethodStatus {
  PENDING = 'pending',
  INVALID = 'invalid',
  VALID = 'valid',
  REJECTED = 'rejected',
}

export interface PayoutMethod {
  uuid: string;
  name: string;
  property_scope: number[]; // Array of property IDs
  all_properties: boolean;
  property_list: { id: number; name: string; picture: string }[];
  enabled: boolean;
  provider: PayoutMethodProvider;
  can_delete: boolean;
  adyen: {
    kyc_required: boolean;
    legal_name: string;
    status: AdyenPayoutMethodStatus;
    verification_errors: string[];
    account_holder_id: string;
    bank_account_number: string | null;
    missing_tin: boolean;
  } | null;
  stripe: {
    public_key: string;
    secret_key: string;
  } | null;
}

export interface AdyenPayoutMethodCreateRequest {
  legal_entity: 'individual' | 'organization';
  first_name?: string; // Only required for legal_entity individual
  last_name?: string; // Only required for legal_entity individual
  legal_name?: string; // Only required for legal_entity organization
  country: string; // US only for now
  phone: string; // +1XXXXXXXXXX
  all_properties: boolean;
  properties: Array<number>;
  uuid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PayoutMethodsService {
  bookingApiUrl = environment.bookingApiUrl;

  constructor(
    private http: HttpClient,
    private segment: SegmentIoService,
    private propertiesService: PropertiesService
  ) {}

  fetchPayoutMethods(provider?: PayoutMethodProvider): Observable<PayoutMethod[]> {
    return this.http.get(`${this.bookingApiUrl}/payout-methods`).pipe(
      tap((res: any) => {
        const payoutMethods = res.data;
        const properties = [];

        if (!payoutMethods || payoutMethods.length === 0) {
          return;
        }

        // Custom sort function
        if (provider) {
          payoutMethods.sort((a, b) => {
            if (a.provider === provider && b.provider !== provider) {
              return -1;
            } else if (b.provider === provider && a.provider !== provider) {
              return 1;
            } else {
              return 0;
            }
          });
        }

        payoutMethods.forEach((payout) => {
          if (payout.property_scope?.length > 0) {
            properties.push(...payout.property_scope);
          }
        });

        res.properties = properties;

        res.payoutMethods = payoutMethods;
        delete res.data;
      }),
      switchMap((res) => {
        return this.propertiesService
          .getProperties({
            filterCriteria: [],
            paginate: false,
            transformer: 'simple',
            idList: res.properties,
          })
          .pipe(
            map((inner) => {
              res.properties = inner.data;
              return res;
            })
          );
      }),
      tap((res) => {
        if (res.payoutMethods?.length > 0) {
          res.payoutMethods.forEach((payout) => {
            if (!payout.property_list) {
              payout.property_list = [];
            }

            res.properties.forEach((property) => {
              if (payout.property_scope.indexOf(property.id) > -1) {
                payout.property_list.push(property);
              }
            });
          });
        }
      }),
      map((res) => (res.payoutMethods ? res.payoutMethods : []))
    );
  }

  createStripeOauthPayoutMethod(code: string) {
    return this.http
      .post(`${this.bookingApiUrl}/payout-methods/stripe-oauth`, {
        code,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((err: HttpErrorResponse) => {
          this.segment.track(SegmentEvent.DirectPayoutStripeOauthFailedToConnect, {
            code,
          });
          return of({ error: true, message: err.error.error });
        })
      );
  }

  createAdyenPayoutMethod(request: AdyenPayoutMethodCreateRequest) {
    return this.http.post(`${this.bookingApiUrl}/payout-methods/adyen`, request);
  }

  editPayoutMethod(payout: PayoutMethod): Observable<any> {
    return this.http.put(`${this.bookingApiUrl}/payout-methods/${payout.uuid}`, {
      property_scope: payout.property_scope,
      all_properties: payout.all_properties,
      name: payout.name,
      provider: payout.provider,
    });
  }

  setPayoutMethodForProperty(propertyId: any, newPaymentMethodUuid: string): Observable<any> {
    return this.http.patch(`${this.bookingApiUrl}/payout-methods/${newPaymentMethodUuid}/properties/${propertyId}`, {});
  }

  deletePayoutMethod(uuid: string): Observable<any> {
    return this.http.delete(`${this.bookingApiUrl}/payout-methods/${uuid}`);
  }

  adyenGetVerifyLink(uuid: string) {
    return this.http.get<{ data: { url: string } }>(`${this.bookingApiUrl}/payout-methods/adyen/${uuid}/kyc-url`);
  }

  async checkForOverlappingProperties(payoutMethod: any): Promise<any[]> {
    const overlappingProperties = [];

    // Get a list of the properties that the user wants to associate with this payout method
    let selectedPropertyIds: number[] = payoutMethod.properties || payoutMethod.property_scope;

    // And also fetch a list of all current payout methods, and their property scopes
    const existingPayoutMethods = await this.fetchPayoutMethods().toPromise();

    // Get a list of all properties currently scoped to a payout method
    const propertiesAlreadyScoped = this.mergeDedupe(
      existingPayoutMethods
        .filter((payout) => payout.all_properties === false)
        .filter((payout) => payout.property_scope.length > 0)
        .map((payout) => payout.property_scope)
    );

    // Find myself and exclude any properties already scoped to this payout method - for edit only
    if (payoutMethod.property_scope) {
      const myPayoutMethod = existingPayoutMethods.find((pm) => pm.uuid === payoutMethod.uuid);
      selectedPropertyIds = selectedPropertyIds.filter((id) => !myPayoutMethod.property_scope.includes(id));
    }

    // For each selected property, determine if it already is scoped to another payout method, if it is - we need to warn about it
    const overlappingPropertyIds = selectedPropertyIds.filter((element) => propertiesAlreadyScoped.includes(element));

    if (overlappingPropertyIds.length > 0) {
      await Promise.all(
        overlappingPropertyIds.map(async (propId) => {
          const property = await this.propertiesService.getProperty(propId.toString()).toPromise();

          overlappingProperties.push({
            name: property.name,
            picture: property.picture,
            payout: existingPayoutMethods.find((existingMethod) => existingMethod.property_scope.includes(propId)),
          });
        })
      );

      return overlappingProperties;
    } else {
      return [];
    }
  }

  getTaxInformation(payoutId: string) {
    return this.http.get(`${this.bookingApiUrl}/payout-methods/${payoutId}/tax-information`);
  }

  updateTaxInformation(payoutId: string, payload: any) {
    return this.http.post(`${this.bookingApiUrl}/payout-methods/${payoutId}/tax-information`, payload);
  }

  private mergeDedupe = (arr) => {
    return [...new Set([].concat(...arr))];
  };
}
