import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'sbnb-textarea',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaComponent,
    },
  ],
  template: `
    <div class="textarea-container" [class.bg-smoke-lighter]="disabled">
      <textarea
        matInput
        [disabled]="disabled"
        autocomplete="off"
        [placeholder]="placeholder"
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="minRows"
        [cdkAutosizeMaxRows]="maxRows"
        [value]="text"
        (keyup)="textChanged($event.target.value)"
        [class.bg-smoke-lighter]="disabled"></textarea>
    </div>
  `,
  styles: [
    `
      @import 'variables';

      .textarea-container {
        border: 1px solid $smoke;
        border-radius: $textarea-border-radius;
        padding: $textarea-padding;
      }

      textarea {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        resize: vertical;
      }
    `,
  ],
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() placeholder?: string;
  @Input() minRows = 2;
  @Input() maxRows = 8;

  public text = '';
  public touched = false;
  public disabled = false;

  constructor(private cd: ChangeDetectorRef) {}

  textChanged(text: string) {
    this.text = text;
    this.onChange(text);
    this.markAsTouched();
  }

  // ControlValueAccessor methods
  onChange = (value: string) => {};

  onTouched = () => {};

  onBlur = () => {
    this.markAsTouched();
  };

  writeValue(value?: string | null | undefined): void {
    this.text = value ?? '';
  }

  registerOnChange(onChange) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }

  registerOnBlur(onBlur) {
    this.onBlur = onBlur;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.cd.detectChanges();
  }
}
