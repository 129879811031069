import { Directive, EventEmitter, HostBinding, HostListener, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  standalone: false,
  selector: '[sbnbDragAndDrop]',
})
export class DragAndDropDirective implements OnInit {
  private isDragging$ = new BehaviorSubject<boolean>(false);
  private readonly destroy$ = new Subject<void>();

  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() isDragging = new EventEmitter<boolean>();

  @HostBinding('style.opacity') private zoneOpacity = '1';

  ngOnInit() {
    this.isDragging$.pipe(takeUntil(this.destroy$)).subscribe((dragging) => {
      this.isDragging.emit(dragging);
      this.zoneOpacity = dragging ? '0.5' : '1';
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:dragover', ['$event'])
  handleDragOver(event: DragEvent) {
    event.preventDefault();

    if (!this.isDragging$.value) {
      this.isDragging$.next(true);
    }
  }

  @HostListener('window:dragleave', ['$event'])
  handleDragLeave(event: DragEvent) {
    event.preventDefault();

    this.isDragging$.next(false);
  }

  @HostListener('window:drop', ['$event'])
  handleDrop(event: DragEvent) {
    event.preventDefault();

    this.isDragging$.next(false);

    const files = event.dataTransfer?.files;
    if (files?.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
