<ng-container *ngIf="!navTabsHide || !featureFlagMobileNavTabsEnabled">
  <div
    #button
    fxHide
    fxShow.lt-md
    class="mobile-open-sidebar"
    (click)="mobileSegments = true"
    [ngStyle]="!featureFlagMobileNavTabsEnabled ? {} : top === null ? {} : { top: top + 'px' }">
    <sbnb-icon icon="more" class="text-raspberry-darker"></sbnb-icon>
  </div>

  <div [style.visibility]="mobileSegments ? 'visible' : 'hidden'" class="mobile-backdrop"></div>

  <div
    #sidebar
    mainNavigation
    [style.visibility]="mobileSegments ? 'visible' : 'hidden'"
    class="mobile-sidebar box-shadow-heavier subnav__wrapper"
    [ngClass]="{ 'mobile-sidebar-reduced-padding': reducedPadding }">
    <div fxLayout fxLayoutAlign="start center" style="margin-bottom: 20px">
      <h2 class="nomargin" fxFlex>{{ title }}</h2>
      <span class="rules__close-btn clickable" (click)="mobileSegments = false">
        <img src="/assets/iconography/cancel.svg" width="24px" height="24px" />
      </span>
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>
