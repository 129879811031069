import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NotificationType } from '@app/shared/interfaces';
import { SegmentService } from '@app/shared/services/filter/segment.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-save-segment',
  templateUrl: './dialog-save-segment.component.html',
  styleUrls: ['./dialog-save-segment.component.scss'],
})
export class DialogSaveSegmentComponent implements OnInit {
  segmentName: string;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogSaveSegmentComponent>,
    public segmentService: SegmentService,
    private toast: ToastNotificationsService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit() { }

  okClicked() {
    this.loading = true;
    this.segmentService
      .saveCustomSegment(this.dialogData.key, this.segmentName, this.dialogData.filters)
      .subscribe((res) => {
        if (res.error) {
          let toastRef = this.toast.open(`Unable to create Saved Segment.`, 'Dismiss', NotificationType.Error, 999999);

          toastRef && toastRef.onAction
            ? toastRef.onAction().subscribe(() => {
              toastRef.dismiss();
            })
            : null;

          this.loading = false;
          return;
        }

        this.loading = false;
        this.dialogRef.close(true);
      });
  }

  cancelClicked() {
    this.dialogRef.close();
  }
}
