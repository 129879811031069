import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Filter } from '@app/shared/models/filter';
import { Alteration, AlterationsService } from '@app/shared/services/alterations/alterations.service';
import { PropertiesService } from '@app/shared/services/properties/properties.service';
import { ReservationService } from '@app/shared/services/reservation/reservation.service';
import { addDays, format, subDays } from 'date-fns';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  standalone: false,
  templateUrl: './dialog-send-alteration-request.component.html',
  styleUrls: ['./dialog-send-alteration-request.component.scss'],
})
export class DialogSendAlterationRequestComponent implements OnInit {
  canChangeProperty = true;

  bookingAlterationConfirmed = false;

  properties: any[] = [];

  guestBreakdownExpanded = false;

  reservations: { start: string; end: string }[];

  alteration: Alteration;
  propertyId: number;

  propertyHasChanged = false;

  originalPropertyName: string;

  proposedNewAccommodationPrice: number;

  amountToRefund: string;

  private alterationChangedSubject: Subject<string> = new Subject();

  saving: boolean;
  fetchingQuote: boolean;

  stage: 'initiate' | 'quote' | 'review' = 'initiate';

  initiatedBy: 'host' | 'guest' = null;

  constructor(
    public dialogRef: MatDialogRef<DialogSendAlterationRequestComponent>,
    private propertyService: PropertiesService,
    private alterationsService: AlterationsService,
    private reservationsService: ReservationService,
    private propertiesService: PropertiesService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      reservationUuid: string;
    }
  ) {}

  ngOnInit(): void {
    this.getInitialReservationState(this.dialogData.reservationUuid);

    this.alterationChangedSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.amountToRefund = null;
      this.getPriceQuote();
    });
  }

  getInitialReservationState(reservationUuid: string) {
    return this.alterationsService.fetchReservation(reservationUuid).subscribe((res) => {
      this.alteration = res;
      this.propertyId = this.alteration.listing.property_id;

      this.canChangeProperty = this.isAirbnb();
      this.guestBreakdownExpanded = !this.isAirbnb();

      if (this.isBookingDotCom()) {
        this.stage = 'quote';
        this.initiatedBy = 'host';
        this.proposedNewAccommodationPrice = Number(this.alteration.original_state.accommodationPrice);
      }

      if (this.isAirbnb()) {
        this.stage = 'quote';
        this.initiatedBy = 'host';
      }

      this.getProperties();
      this.fetchReservations();
    });
  }

  getProperties() {
    const payload = [];

    if (this.isAirbnb()) {
      const hostFilter = this.buildHostFilter();
      payload.push(hostFilter);
    }

    this.propertyService.getProperties({ filterCriteria: payload, paginate: false }).subscribe((res) => {
      this.properties = res.data;

      this.originalPropertyName = this.getPropertyName(this.alteration.original_state.listingId);
    });
  }

  fetchReservations() {
    this.reservationsService
      .getReservationsByDate(subDays(new Date(), 90), addDays(new Date(), 1000), [this.propertyId], false, true)
      .subscribe((res) => {
        this.reservations = res
          .filter((item) => item.code != this.alteration.reservation_code) // Don't show the current reservation on the calendar, as we want to still be able to select those dates
          .map((item) => {
            return { start: item.checkin, end: item.checkout };
          });
      });
  }

  datesSelected(dates: [string, string]) {
    const newCheckInDate = format(dates[0], 'YYYY-MM-DD');
    const newCheckOutDate = format(dates[1], 'YYYY-MM-DD');
    let datesHaveChangedInThisSelection = false;

    if (
      this.alteration.proposed_state.checkInDate !== newCheckInDate ||
      this.alteration.proposed_state.checkOutDate !== newCheckOutDate
    ) {
      datesHaveChangedInThisSelection = true;
    }

    this.alteration.proposed_state.checkInDate = newCheckInDate;
    this.alteration.proposed_state.checkOutDate = newCheckOutDate;

    if (datesHaveChangedInThisSelection) {
      this.alterationChangedSubject.next(null);
    }
  }

  propertyChanged(newPropertyId: number) {
    this.propertyId = newPropertyId;
    this.propertyHasChanged = newPropertyId == this.propertyId;
    this.fetchReservations();

    // Update the alteration's proposed state with the new property ID
    this.alteration.proposed_state.propertyId = newPropertyId;

    this.alterationChangedSubject.next(null);
  }

  updateGuestCount() {
    if (!this.isBookingDotCom()) {
      this.alterationChangedSubject.next(null);
    }
  }

  getPropertyByListing(listingId: string) {
    return this.properties.find((item) => item.listings.find((listing) => listing.listing_id == listingId));
  }

  getPropertyName(listingId: string): string {
    return this.getPropertyByListing(listingId)?.name ?? '';
  }

  updateNewAccommodationPrice() {
    this.alteration.proposed_state.accommodationPrice = this.proposedNewAccommodationPrice.toString();
    this.alterationChangedSubject.next(null);
  }

  sendAlterationRequest() {
    this.saving = true;
    this.alterationsService
      .sendAlterationRequest(this.dialogData.reservationUuid, this.alteration, this.initiatedBy, this.amountToRefund)
      .subscribe(
        (res) => {
          this.saving = false;
          this.dialogRef.close({ created: this.alteration });
        },
        () => {
          this.saving = false;
        }
      );
  }

  getPriceQuote() {
    // Booking.com doesn't allow price reductions
    if (
      this.isBookingDotCom() &&
      this.proposedNewAccommodationPrice < Number(this.alteration.original_state.accommodationPrice)
    ) {
      return;
    }

    this.fetchingQuote = true;

    this.alterationsService
      .fetchPriceQuote(this.dialogData.reservationUuid, this.alteration, this.proposedNewAccommodationPrice)
      .subscribe(
        (res) => {
          this.alteration = res;
          this.fetchingQuote = false;
        },
        () => {
          // error
          this.fetchingQuote = false;
        }
      );
  }

  private buildHostFilter() {
    const hostFilter = new Filter();
    hostFilter.id = 'properties-hosts';
    hostFilter.label = 'Host';
    hostFilter.loading = false;
    hostFilter.lockable = false;
    hostFilter.selectedValue = {
      key: this.alteration.listing.user_id,
      label: '',
    };
    hostFilter.type = 'list';
    hostFilter.uuid = '?';
    hostFilter.valuesData = [
      {
        key: this.alteration.listing.user_id,
        label: '',
      },
    ];

    return hostFilter;
  }

  isAirbnb() {
    return this.alteration.platform === 'airbnb-official';
  }

  isBookingDotCom() {
    return this.alteration.platform === 'booking';
  }

  canEditGuests() {
    return !this.isBookingDotCom();
  }

  canEditAccommodationPrice() {
    return this.isAirbnb() || this.isBookingDotCom();
  }
}
