<div
  *ngIf="allHosts; else hostsContainer"
  class="hosts__all clickable"
  (click)="$event.preventDefault(); $event.stopPropagation()">
  All
</div>

<div
  *ngIf="!allHosts && hosts.length === 0"
  class="hosts__none"
  (click)="$event.preventDefault(); $event.stopPropagation()">
  None
</div>

<ng-template #hostsContainer>
  <div
    fxLayout
    class="clickable"
    [matTooltip]="tooltip"
    (click)="$event.preventDefault(); $event.stopPropagation(); openHostsModal()">
    <ng-container *ngFor="let host of hosts; let i = index">
      <img
        class="host__image"
        [style.left.px]="i * -5"
        *ngIf="i < 3"
        [src]="host.picture"
        onerror="this.src='/assets/images/profile-default.png'" />
    </ng-container>
    <span *ngIf="hosts.length > 3" class="host__more">+{{ hosts.length - 3 }}</span>
  </div>
</ng-template>
