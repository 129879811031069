// paste-handler.directive.ts
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  standalone: false,
  selector: '[appPasteHandler]',
})
export class PasteHandlerDirective {
  @Output() imagePasted = new EventEmitter<File>();

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    const items = event.clipboardData?.items;

    if (items) {
      const itemsArray = Array.from(items);

      for (const item of itemsArray) {
        if (item.kind === 'file' && item.type.startsWith('image/')) {
          const file = item.getAsFile();
          if (file) {
            this.imagePasted.emit(file);
            event.preventDefault(); // this prevents us copying the filename into the target if an image is pasted
          }
        }
      }
    }
  }
}
