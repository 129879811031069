<div
  *ngFor="let channel of channels"
  [ngClass]="['channel', channel.key.toLowerCase(), channel.enabled ? 'enabled' : '']">
  <div class="planet">
    <div class="inner-planet" fxLayoutAlign="center center">
      <img *ngIf="channel.enabled && channel.asset" src="/assets/iconography/{{ channel.asset }}" />
    </div>
  </div>
</div>

<img id="sun" [src]="avatar" onerror="this.src='/assets/images/signup/avatar.svg'" />
