import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-icon-agoda',
  templateUrl: './icon-agoda.component.html',
  styleUrls: ['./icon-agoda.component.scss'],
})
export class IconAgodaComponent implements OnInit {
  @Input() color = '#2a6ebb';
  @Input() height = '16px';
  @Input() width = '16px';

  constructor() { }

  ngOnInit(): void { }
}
