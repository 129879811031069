import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';

@Component({
  selector: 'sbnb-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() parentPath: string;
  @Input() navigateBack = true;

  constructor(
    public deviceDetectionService: DeviceDetectionService,
    private location: Location,
    private router: Router
  ) {}

  goBack() {
    if (this.parentPath) {
      this.router.navigate([this.parentPath], { replaceUrl: true });
    } else {
      this.location.back();
    }
  }
}
