@if (loadingData()) {
  <mat-spinner diameter="24"></mat-spinner>
} @else if (verificationDetails() !== null) {
  <div class="flex flex-col gap-8">
    <!-- Verification Status Header -->
    @switch (verificationDetails()?.status) {
      @case ('pending') {
        <sbnb-alert-card context="neutral" class="flex gap-3">
          <sbnb-icon icon="alert-triangle" [size]="20" class="text-grape mt-1"></sbnb-icon>
          <div class="flex flex-col gap-1">
            <div class="flex gap-2 items-center">
              <h4 class="text-lg">Verification Status:</h4>
              <sbnb-badge context="neutral" volume="high">Pending</sbnb-badge>
            </div>
            <span>The guest has not yet completed identity verification.</span>
          </div>
        </sbnb-alert-card>
      }
      @case ('approved') {
        <sbnb-alert-card context="positive" class="flex flex-col gap-2">
          <div class="flex gap-2 items-center">
            <h4 class="text-lg">Verification Status:</h4>
            <sbnb-badge context="positive" volume="high">Approved</sbnb-badge>
          </div>
          <span class="text-night">
            The guest has completed identity verification and based on the identified risk factors, this is a relatively
            low risk reservation.
          </span>
        </sbnb-alert-card>
      }
      @case ('denied') {
        <sbnb-alert-card context="negative" class="flex flex-col gap-2">
          <div class="flex gap-2 items-center">
            <h4 class="text-lg">Verification Status:</h4>
            <sbnb-badge context="negative" volume="high">Denied</sbnb-badge>
          </div>
          <span>The guest failed the guest verification check.</span>
        </sbnb-alert-card>
      }
      @case ('review') {
        <sbnb-alert-card context="warning" class="flex flex-col gap-2">
          <div class="flex gap-2 items-center">
            <h4 class="text-lg">Verification Status:</h4>
            <sbnb-badge context="warning" volume="high">In Review</sbnb-badge>
          </div>
          <span>
            The guest has completed identity verification, but there are some risk factors that require review by
            Hospitable's support team.
          </span>
        </sbnb-alert-card>
      }
      @case ('suspected') {
        <sbnb-alert-card context="warning" class="flex flex-col gap-2">
          <div class="flex gap-2 items-center">
            <h4 class="text-lg">Verification Status:</h4>
            <sbnb-badge context="warning" volume="high">Needs Review</sbnb-badge>
          </div>
          <span>
            The guest has completed identity verification, but there are some risk factors that should be reviewed
            before accepting this reservation.
          </span>
        </sbnb-alert-card>
      }
    }

    <!-- Purpose of Stay -->
    @if (verificationPurposeOfStayCategoryLabel()) {
      <div class="flex flex-col gap-2 border-b border-smoke pb-8">
        <h3>Purpose of Stay</h3>
        <div>{{ verificationPurposeOfStayCategoryLabel() | humanize }}</div>

        <div>{{ verificationPurposeOfStayExperiences() | toSentence | humanize }}</div>
      </div>
    }

    <!-- Verification Details -->
    <div class="flex flex-col gap-4 border-b border-smoke pb-8">
      <h3>Verification Details</h3>
      <div class="flex flex-col gap-2">
        <div class="strong text-sm">Checks Assessed</div>

        @if (verificationDetails()?.assessments?.identity_verification) {
          <div class="flex flex-col gap-2">
            <div class="flex items-center gap-2">
              <sbnb-icon
                [icon]="
                  verificationDetails()?.assessments?.identity_verification === 'approved' ? 'check' : 'alert-triangle'
                "
                [class]="
                  verificationDetails()?.assessments?.identity_verification === 'approved'
                    ? 'text-grass-darker'
                    : 'text-lemon-darker'
                "></sbnb-icon>
              <span>ID Check: {{ verificationDetails()?.assessments?.identity_verification | titlecase }}</span>
            </div>
          </div>
        }

        @if (verificationDetails()?.assessments?.sex_offender_check) {
          <div class="flex flex-col gap-2">
            <div class="flex items-center gap-2">
              <sbnb-icon
                [icon]="
                  verificationDetails()?.assessments?.sex_offender_check === 'approved' ? 'check' : 'alert-triangle'
                "
                [class]="
                  verificationDetails()?.assessments?.sex_offender_check === 'approved'
                    ? 'text-grass-darker'
                    : 'text-lemon-darker'
                "></sbnb-icon>
              <span>
                Sexual Offender Check: {{ verificationDetails()?.assessments?.sex_offender_check | titlecase }}
              </span>
            </div>
          </div>
        }
      </div>
    </div>

    <!-- Risk Factors -->
    @if (verificationDetails()?.status !== 'pending' && verificationDetails()?.risk_level) {
      <div class="flex flex-col gap-2 border-b border-smoke pb-8">
        <h3>Risk level</h3>
        <div
          [class.text-grass-darker]="verificationDetails()?.risk_level === 'low'"
          [class.text-lemon-darker]="verificationDetails()?.risk_level === 'moderate'"
          [class.text-grape-darker]="verificationDetails()?.risk_level === 'high'"
          [class.text-grape-darker]="verificationDetails()?.risk_level === 'critical'">
          {{ verificationDetails()?.risk_level | humanize }}
        </div>
      </div>
    }

    <!-- Risk Factors -->
    @if (verificationDetails()?.status !== 'pending') {
      <div class="flex flex-col gap-2 border-b border-smoke pb-8">
        <h3>Risk Factors</h3>
        @if (verificationDetails()?.risks?.length > 0) {
          <ul class="list-disc my-2 space-y-3">
            @for (risk of verificationDetails()?.risks || []; track risk) {
              <li class="">{{ risksMetadata[risk] ?? risk }}</li>
            }
          </ul>
        } @else {
          <div class="">No risk factors identified.</div>
        }
      </div>
    }

    <!-- Guest Information Section -->
    <div class="flex flex-col gap-4 border-b border-smoke pb-8">
      <h3>Guest Information</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="flex flex-col gap-1">
          <div class="strong text-sm">Name</div>
          <div class="">{{ verificationDetails()?.guest?.full_name }}</div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="strong text-sm">Phone</div>
          <div class="">{{ verificationDetails()?.guest?.phone }}</div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="strong text-sm">Email</div>
          <div class="">{{ verificationDetails()?.guest?.email }}</div>
        </div>

        @if (verificationDetails()?.guest?.address) {
          <div class="flex flex-col gap-1">
            <div class="strong text-sm">Address</div>
            <div class="">{{ verificationDetails()?.guest?.address }}</div>
          </div>
        }
      </div>
    </div>

    @if (verificationDetails()?.identification && verificationDetails()?.identification?.document_type) {
      <div class="flex flex-col gap-2 border-b border-smoke pb-8">
        <h3>Identity Document Details</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          @if (verificationDetails()?.identification?.document_type) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Document Type</div>
              <div class="">
                {{
                  documentTypeMetadata[verificationDetails()?.identification?.document_type] ??
                    verificationDetails()?.identification?.document_type
                }}
              </div>
            </div>
          }

          @if (verificationDetails()?.identification?.full_name) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Full name</div>
              <div class="">{{ verificationDetails()?.identification?.full_name }}</div>
            </div>
          }

          @if (verificationDetails()?.identification?.address) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Address</div>
              <div class="">{{ verificationDetails()?.identification?.address }}</div>
            </div>
          }

          @if (verificationDetails()?.identification?.birth_date) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Birth Date</div>
              <div class="">
                {{ verificationDetails()?.identification?.birth_date
                }}{{ guestAge() !== null ? ' (' + guestAge() + ' years old)' : '' }}
              </div>
            </div>
          }

          @if (verificationDetails()?.identification?.issue_date) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Issue Date</div>
              <div class="">{{ verificationDetails()?.identification?.issue_date }}</div>
            </div>
          }

          @if (verificationDetails()?.identification?.expiry_date) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Expiry Date</div>
              <div class="">{{ verificationDetails()?.identification?.expiry_date }}</div>
            </div>
          }
        </div>
      </div>
    }

    <!-- Document Files -->
    @if (
      verificationDetails()?.document_files &&
      (verificationDetails()?.document_files.document_front_image ||
        verificationDetails()?.document_files.document_back_image ||
        verificationDetails()?.document_files.selfie_image ||
        verificationDetails()?.document_files.document_front_video ||
        verificationDetails()?.document_files.document_back_video ||
        verificationDetails()?.document_files.selfie_video)
    ) {
      <div class="flex flex-col gap-2 border-b border-smoke pb-8">
        <h3>Document Files</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          @if (verificationDetails()?.document_files?.document_front_image) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Front of Document</div>
              <img
                [src]="verificationDetails()?.document_files?.document_front_image"
                alt="Front of document"
                class="max-w-full h-auto rounded-lg border border-smoke" />
            </div>
          }
          @if (verificationDetails()?.document_files?.document_back_image) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Back of Document</div>
              <img
                [src]="verificationDetails()?.document_files?.document_back_image"
                alt="Back of document"
                class="max-w-full h-auto rounded-lg border border-smoke" />
            </div>
          }
          @if (verificationDetails()?.document_files?.selfie_image) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Selfie</div>
              <img
                [src]="verificationDetails()?.document_files?.selfie_image"
                alt="Selfie"
                class="max-w-full h-auto rounded-lg border border-smoke" />
            </div>
          }
          @if (verificationDetails()?.document_files?.document_front_video) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Front Video</div>
              <video controls class="max-w-full h-auto rounded-lg border border-smoke">
                <source [src]="verificationDetails()?.document_files?.document_front_video" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          }
          @if (verificationDetails()?.document_files?.document_back_video) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Back Video</div>
              <video controls class="max-w-full h-auto rounded-lg border border-smoke">
                <source [src]="verificationDetails()?.document_files?.document_back_video" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          }
          @if (verificationDetails()?.document_files?.selfie_video) {
            <div class="flex flex-col gap-2">
              <div class="strong text-sm">Selfie Video</div>
              <video controls class="max-w-full h-auto rounded-lg border border-smoke">
                <source [src]="verificationDetails()?.document_files?.selfie_video" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          }
        </div>
      </div>
    }

    <!-- Autohost SDK Embed -->
    @if (verificationDetails()?.provider === 'autohost') {
      <sbnb-autohost-sdk-embed [reservationUuid]="reservationUuid()"></sbnb-autohost-sdk-embed>
    }

    <!-- <pre>{{ verificationDetails() | json }}</pre> -->
  </div>
} @else {
  <div class="flex flex-col gap-2">
    <h3>Verification Details</h3>
    <div class="">You have not requested a verification for this reservation.</div>
  </div>
}
