<!-- Individually selected thermostats -->
<div fxLayout fxLayoutAlign="center center" fxLayoutGap="-4px">
  <div *ngIf="showIndividualAsWellAsAll || (!allThermostats && thermostats.length !== 0)">
    <div class="clickable" fxLayout [matTooltip]="tooltip" (click)="$event.preventDefault(); $event.stopPropagation()">
      <ng-container *ngFor="let thermostat of thermostats; let i = index">
        <img
          class="thermostat__image"
          [style.left.px]="i * -7"
          *ngIf="i < 3"
          src="/assets/iconography/smart-house-temperature.svg" />
      </ng-container>
      <span *ngIf="overrideLength > 3" class="thermostat__more">+{{ overrideLength - 3 }}</span>
    </div>
  </div>

  <!-- All thermostats -->
  <div *ngIf="allThermostats" class="thermostats__all clickable">
    {{ labelForAllThermostats }}
  </div>

  <!-- No thermostats -->
  <div *ngIf="!allThermostats && thermostats.length === 0" class="thermostats__none">None</div>
</div>
