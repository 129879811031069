<div class="content-header">
  <app-feature-flag-tabs>
    <sbnb-page-header navTabs [navigateBack]="navigateBack">
      <div [class.sticky-header]="sticky" class="header w-full" [class.scrolling]="sticky && isScrolled">
        <div fxLayout fxLayoutAlign="space-between center">
          <div fxLayoutAlign="start center" fxLayoutGap="12px">
            <sbnb-back-button
              *ngIf="backLink && navigateBack"
              [iconOnly]="true"
              [routerLink]="backLink"></sbnb-back-button>
            <h2 class="nomargin">{{ title }}</h2>
          </div>

          <div *ngIf="saveButtonShown" fxLayout fxLayoutGap="12px">
            <sbnb-button
              *ngIf="!recentlySaved"
              type="primary"
              size="mini"
              label="Save"
              [loading]="saving"
              (clicked)="saveClicked.emit()"></sbnb-button>

            <div *ngIf="recentlySaved" fxLayout fxLayoutAlign="center center" fxLayoutGap="4px">
              <mat-icon class="grass-darker">check</mat-icon>
              <span class="grass-darker">Saved</span>
            </div>
          </div>
        </div>
      </div>
    </sbnb-page-header>

    <div [class.sticky-header]="sticky" class="header" [class.scrolling]="sticky && isScrolled">
      <div fxLayout fxLayoutAlign="space-between center">
        <div fxLayoutAlign="start center" fxLayoutGap="12px">
          <sbnb-back-button *ngIf="backLink" [iconOnly]="true" [routerLink]="backLink"></sbnb-back-button>
          <h2 class="nomargin">{{ title }}</h2>
        </div>

        <div *ngIf="saveButtonShown" fxLayout fxLayoutGap="12px">
          <sbnb-button
            *ngIf="!recentlySaved"
            type="primary"
            size="mini"
            label="Save"
            [loading]="saving"
            (clicked)="saveClicked.emit()"></sbnb-button>

          <div *ngIf="recentlySaved" fxLayout fxLayoutAlign="center center">
            <mat-icon class="grass-darker">check</mat-icon>
            <span class="grass-darker">Saved</span>
          </div>
        </div>
      </div>
    </div>
  </app-feature-flag-tabs>

  <sbnb-helptext-wrapper
    *ngIf="novaContentId"
    class="helptext-wrapper-type-content"
    [area]="'content'"
    [pageId]="novaContentId"></sbnb-helptext-wrapper>
</div>
