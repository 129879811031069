import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-progress-stepper',
  templateUrl: './progress-stepper.component.html',
  styleUrls: ['./progress-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: ProgressStepperComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressStepperComponent extends CdkStepper {
  ngOnInit(): void {}

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
