import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioCardComponent implements OnInit {
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() context: 'neutral' | 'positive' = 'positive';
  @Input() size: "small" | "large" = "large";

  @HostBinding('style.pointer-events') get pointerEvents(): string {
    return this.disabled || this.readonly ? 'none' : 'auto';
  }

  constructor() { }

  ngOnInit(): void { }
}
