<div fxLayout fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">
  <span>Start this task every</span>

  <button
    data-intercom-target="occurrence-timing-rule"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="occurrenceMenu">
    <span class="occurrence" data-intercom-target="occurrence">{{ ruleset.metadata.recurring.occurrence }}</span>
  </button>

  <button
    data-intercom-target="frequency-timing-rule"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="frequencyMenu">
    <span class="frequency" data-intercom-target="frequency">{{ transformedFrequency }}</span>
  </button>

  <span>on a</span>

  <button
    data-intercom-target="day-timing-rule"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="dayMenu">
    <span class="day" data-intercom-target="recurring-day">{{ meta.day[ruleset.metadata.recurring.day] }}</span>
  </button>

  <span>at</span>

  <sbnb-time-picker
    class="recurring-ruleset"
    data-intercom-target="time-timing-rule"
    [hours]="ruleset.metadata.recurring.hours"
    [minutes]="ruleset.metadata.recurring.minutes"
    (timeUpdated)="timeChanged($event)"
    (closed)="save()"></sbnb-time-picker>

  <span>starting from</span>

  <div class="assign__dates" fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
    <button
      mat-flat-button
      data-intercom-target="start-date-btn"
      class="filter__option-btn button__small button__text"
      #dateMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="dateMenu">
      {{ ruleset.metadata.recurring.start_date | date: 'mediumDate' }}
    </button>

    <mat-menu #dateMenu="matMenu">
      <sbnb-date-picker
        [startDate]="ruleset.metadata.recurring.start_date"
        [numMonths]="1"
        [checkInOutStyling]="false"
        (click)="$event.stopPropagation()"
        (datesSelected)="
          ruleset.metadata.recurring.start_date = $event; dateMenuTrigger.closeMenu(); save()
        "></sbnb-date-picker>
    </mat-menu>
  </div>
</div>

<mat-menu #occurrenceMenu="matMenu" class="filters__cdk-menu">
  <button *ngFor="let occurrence of meta.occurrence" mat-menu-item (click)="occurrenceChanged(occurrence)">
    {{ occurrence }}
  </button>
</mat-menu>

<mat-menu #frequencyMenu="matMenu" class="filters__cdk-menu">
  <button *ngFor="let frequency of meta.frequency" mat-menu-item (click)="frequencyChanged(frequency)">
    {{ ruleset.metadata.recurring.occurrence != 1 ? frequency + 's' : frequency }}
  </button>
</mat-menu>

<mat-menu #dayMenu="matMenu" class="filters__cdk-menu">
  <button *ngFor="let day of meta.day" mat-menu-item (click)="dayChanged(day)">
    {{ day }}
  </button>
</mat-menu>
