import { Node, mergeAttributes } from '@tiptap/core';

export const CustomCodeExtension = Node.create({
  name: 'customcode',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      class: {
        default: 'customcode',
      },
      text: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.customcode',
        getAttrs: (dom) => ({ text: dom.textContent }),
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes), node.attrs.text];
  },

  addNodeView() {
    return ({ node }) => {
      const span = document.createElement('span');
      span.classList.add('customcode');
      span.textContent = node.attrs.text;

      return {
        dom: span,
      };
    };
  },
});
