import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'alterationDisplayGuestsNumbers',
})
export class AlterationDisplayGuestsNumbersPipe implements PipeTransform {
  transform(alteration: any): string {
    return [this.getAdultsString(alteration), this.getInfantsString(alteration), this.getPetsString(alteration)]
      .filter(Boolean)
      .join(', ');
  }

  private getAdultsString(alteration: any): string {
    let guestsNumber = alteration.adults + alteration.children;

    return `${guestsNumber} guest${guestsNumber > 1 ? 's' : ''}`;
  }

  private getInfantsString(alteration: any): string {
    if (alteration.infants == 0) {
      return '';
    }

    return `${alteration.infants} infant${alteration.infants > 1 ? 's' : ''}`;
  }

  private getPetsString(alteration: any): string {
    if (alteration.pets == 0) {
      return '';
    }

    return `${alteration.pets} pet${alteration.pets > 1 ? 's' : ''}`;
  }
}
