<div class="review-container">
  <h2 class="nomargin">Review & confirm</h2>

  <ul>
    <li *ngIf="isCancelling">
      The reservation for {{ guestName }} from {{ checkin }} to {{ checkout }} will be cancelled.
    </li>

    <li *ngIf="amountToRefund > 0 && refundDetails.payment_enabled">
      {{ 'CORE.PRODUCT_NAME' | transloco }} will refund {{ currency }}{{ amountToRefund }} to the guest on your behalf.
    </li>

    <li *ngIf="directPlan === 'premium'">
      The refund will be deducted from this reservation's payout, or from future payouts if the amount exceeds your
      payout, or if we have already transferred your payout to you.
    </li>

    <li *ngIf="amountToRefund > 0 && refundDetails.payment_enabled">
      The guest will be notified about the refund via email to
      {{ guestEmail }}.
    </li>

    <li *ngIf="amountToRefund > 0 && refundDetails.payment_enabled">
      It may take 5 - 10 business days for the guest to receive the refund.
    </li>

    <li *ngIf="amountToRefund === 0 && refundDetails.payment_enabled">
      At this time no refund will be issued for this reservation.
    </li>

    <li *ngIf="amountToRefund < refundDetails.max_refundable_amount">
      You will be able to issue further refunds later.
    </li>
  </ul>

  <div class="buttons-container" fxLayoutAlign="end center" fxLayoutGap="12px">
    <sbnb-button
      [label]="isCancelling ? 'Do not cancel' : 'Do not refund'"
      [disabled]="actioning"
      type="secondary"
      mat-dialog-close></sbnb-button>

    <sbnb-button
      *ngIf="isCancelling"
      [loading]="actioning"
      label="Confirm & cancel"
      (click)="cancelAndRefund()"></sbnb-button>

    <sbnb-button
      *ngIf="!isCancelling"
      [loading]="actioning"
      label="Confirm & refund"
      (click)="issueRefund()"></sbnb-button>
  </div>
</div>
