import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'fn',
  pure: true,
})
export class FunctionPipe implements PipeTransform {
  transform(value: unknown, fn: Function, context: any = null, ...args: any[]): any {
    if (typeof fn !== 'function') {
      console.warn('[FunctionPipe]: second argument must be a function');
      return null;
    }

    try {
      return fn.apply(context, [value, ...args]);
    } catch (error) {
      console.error('[FunctionPipe]: Error executing function', error);
      return null;
    }
  }
}
