import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BillingFacade } from '@app/shared/+state/billing';
import { UsersFacade } from '@app/shared/+state/users';
import { PosthogFeatureFlag } from '@app/shared/interfaces/lib/feature-flags.interface';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

interface CopilotRequest {
  input: string;
  conversation_id?: string;
  current_page?: string;
}

interface CopilotMessage {
  id: number;
  uuid: string | null;
  role: 'user' | 'assistant';
  content: string;
  created_at: string;
}

interface CopilotConversation {
  id: number;
  uuid: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface CopilotConversationDetail extends CopilotConversation {
  messages: CopilotMessage[];
}

interface QuestionSuggestions {
  data: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CopilotService {
  public enabled$ = combineLatest([
    this.posthog.featureEnabled$(PosthogFeatureFlag.Copilot),
    this.billingFacade.isMogulSubscription$,
    this.usersFacade.mogulAiEntitlement$
  ]).pipe(
    map(([featureEnabled, isMogul, mogulAiEntitlement]) => featureEnabled || isMogul || mogulAiEntitlement?.available),
    shareReplay(1) // Cache the latest value and share it among all subscribers
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private posthog: PosthogService,
    private billingFacade: BillingFacade,
    private readonly usersFacade: UsersFacade
  ) {}

  getQuestionSuggestions(): Observable<QuestionSuggestions> {
    return this.http.get<QuestionSuggestions>(`${environment.apiUrl}/copilot/question-suggestions`);
  }

  getConversations(): Observable<{ data: CopilotConversation[] }> {
    return this.http.get<{ data: CopilotConversation[] }>(`${environment.apiUrl}/copilot/conversations`);
  }

  getConversationDetail(uuid: string): Observable<{ data: CopilotConversationDetail }> {
    return this.http.get<{ data: CopilotConversationDetail }>(`${environment.apiUrl}/copilot/conversations/${uuid}`);
  }

  askQuestion(question: string, conversationId?: string): Observable<{ data: CopilotConversationDetail }> {
    const currentPage = this.getCurrentPage();

    const payload: CopilotRequest = {
      input: question,
      current_page: currentPage,
      ...(conversationId && { conversation_id: conversationId }),
    };
    return this.http.put<{ data: CopilotConversationDetail }>(`${environment.apiUrl}/copilot/ask`, payload);
  }

  deleteConversation(uuid: string): Observable<{ data: CopilotConversation[] }> {
    return this.http.delete<{ data: CopilotConversation[] }>(`${environment.apiUrl}/copilot/conversations/${uuid}`);
  }

  /**
   * Gets the current page/route the user is on
   * @returns A string representing the current page
   */
  private getCurrentPage(): string {
    return this.router.url;
  }
}
