<div class="reservation-card__summary">
  <div class="reservation-card__info">
    <h3>
      <sbnb-truncate
        class="cursor-pointer"
        (click)="goToActiveReservationProperty()"
        [text]="activeReservation.property?.name || activeReservation.listing?.property_name"></sbnb-truncate>
    </h3>
    <ng-container *ngIf="activeReservation.listing.public_url; else noPublicListingLink">
      <a [attr.href]="activeReservation.listing.public_url" target="_blank" class="reservation-card__listing">
        <span class="flex-row">
          <sbnb-icon-platform
            [platform]="activeReservation.platform"
            [height]="'12px'"
            [width]="'12px'"
            [matTooltip]="
              (activeReservation.platform | titlecase) +
              (activeReservation.platform === 'direct'
                ? ' - ' + (activeReservation.payment_provider === 'adyen' ? 'Premium' : 'Basic')
                : '')
            "></sbnb-icon-platform>
          <sbnb-truncate [text]="activeReservation.listing?.name"></sbnb-truncate>
        </span>

        <mat-icon inline="true">open_in_new</mat-icon>
      </a>
    </ng-container>

    <ng-template #noPublicListingLink>
      <div class="reservation-card__listing">
        <span class="flex-row">
          <sbnb-icon-platform
            [platform]="activeReservation.platform"
            [height]="'12px'"
            [width]="'12px'"
            [matTooltip]="
              (activeReservation.platform | titlecase) +
              (activeReservation.platform === 'direct'
                ? ' - ' + (activeReservation.payment_provider === 'adyen' ? 'Premium' : 'Basic')
                : '')
            "></sbnb-icon-platform>
          <sbnb-truncate [text]="activeReservation.listing?.name"></sbnb-truncate>
        </span>
      </div>
    </ng-template>

    <!-- Copy to clipboard reservation code -->
    <sbnb-copy-to-clipboard *ngIf="activeReservation.code" [content]="activeReservation.code">
      {{ activeReservation.code }}
    </sbnb-copy-to-clipboard>

    <ng-container *ngIf="activeReservation.platform === 'ical'">
      <div>
        via
        <strong>{{ activeReservation.channel }}</strong>
      </div>
    </ng-container>

    <!-- Reservation Status / Blocked by parent/child -->
    <div
      [class.positive]="activeReservation.status === 'Accepted' || activeReservation.status === 'Pre-approved'"
      class="reservation-card__status">
      <ng-container *ngIf="!activeReservation.isParentChildReservation; else parentChildReservation">
        {{ activeReservation.status }}
      </ng-container>
      <ng-template #parentChildReservation>Blocked by {{ parentChildBlockedBy }}</ng-template>
    </div>

    <div class="flex items-center gap-2 mt-2" *ngIf="thread?.label !== null && (sentimentAnalysisEnabled$ | async)">
      <sbnb-icon
        icon="alert-triangle"
        class="text-smoke-darker"
        style="min-width: 16px; height: 16px; width: 16px"></sbnb-icon>
      <sbnb-sentiment-label [label]="thread.label" dataElement="thread-guest-label"></sbnb-sentiment-label>
    </div>
  </div>
</div>

<div class="thread__listing-card-inner">
  <div class="thread__item">
    <div fxLayoutGap="8px" fxLayoutAlign="start center">
      <img src="/assets/iconography/check-in.svg" alt="Check in icon" matTooltip="Check-in date" />
      <span class="strong" fxFlex>{{ activeReservation.start_date | date: 'EEE, LLL d, yyyy' }}</span>
      <span class="strong">
        <sbnb-time-picker
          [hours]="activeReservation.start_date | date: 'H' | number"
          [minutes]="activeReservation.start_date | date: 'm' | number"
          [upsell]="hasEarlyCheckInUpsell()"
          [readOnly]="
            activeReservation.isParentChildReservation ||
            (perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) !== true
          "
          (closed)="updateTime('checkin', $event)"></sbnb-time-picker>
      </span>
    </div>
  </div>

  <div class="thread__item">
    <div fxLayoutGap="8px" fxLayoutAlign="start center">
      <img src="/assets/iconography/check-out.svg" alt="Check out icon" matTooltip="Check-out date" />
      <span class="strong" fxFlex>{{ activeReservation.end_date | date: 'EEE, LLL d, yyyy' }}</span>
      <span class="strong">
        <sbnb-time-picker
          [hours]="activeReservation.end_date | date: 'H' | number"
          [minutes]="activeReservation.end_date | date: 'm' | number"
          [upsell]="hasLateCheckOutUpsell()"
          [readOnly]="
            activeReservation.isParentChildReservation ||
            (perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) !== true
          "
          (closed)="updateTime('checkout', $event)"></sbnb-time-picker>
      </span>
    </div>
  </div>

  <ng-container *ngIf="!activeReservation.isParentChildReservation">
    <div fxLayout="row" class="thread__item">
      <div fxFlex fxLayoutGap="8px" fxLayoutAlign="start center">
        <img src="/assets/iconography/date.svg" alt="Number of nights" matTooltip="Number of nights" />
        <span class="strong">{{ activeReservation.num_nights }}</span>
      </div>

      <div
        *ngIf="(reservationRevenue$ | async) || (inquiryRevenue$ | async) as revenue"
        fxFlex
        fxLayoutGap="8px"
        fxLayoutAlign="start center">
        <img src="/assets/iconography/subtotal.svg" alt="Gross Revenue" matTooltip="Gross Revenue" />
        <span class="strong">{{ revenue.amount_formatted }}</span>
      </div>
    </div>

    <div fxLayout="row" class="thread__item">
      <div *ngIf="activeReservation.num_adults" fxFlex fxLayoutGap="8px" fxLayoutAlign="start center">
        <sbnb-icon icon="adults" [size]="16" class="text-smoke-darker"></sbnb-icon>
        <span class="strong">{{ activeReservation.num_adults }}</span>
      </div>
      <div fxFlex fxLayoutGap="8px" fxLayoutAlign="start center">
        <img src="/assets/iconography/kids.svg" alt="Children" matTooltip="Children" />
        <span class="strong">{{ activeReservation.num_children }}</span>
      </div>
      <div fxFlex fxLayoutGap="8px" fxLayoutAlign="start center">
        <img src="/assets/iconography/infants.svg" alt="Infants" matTooltip="Infants" />
        <span class="strong">{{ activeReservation.num_infants }}</span>
      </div>
      <div fxFlex fxLayoutGap="8px" fxLayoutAlign="start center">
        <mat-icon matTooltip="Pets" class="pet-icon">pets</mat-icon>
        <span class="strong">{{ activeReservation.num_pets }}</span>
      </div>
    </div>

    <!-- Smartlock code display -->
    <a
      *ngIf="activeReservation.smart_lock?.code"
      [attr.href]="activeReservation.smart_lock.url ? activeReservation.smart_lock.url : null"
      target="_blank"
      class="remotelock__container remotelock__{{ activeReservation.smart_lock.status }}"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      [matTooltipPosition]="'above'"
      [matTooltip]="
        activeReservation.smart_lock.status === 0
          ? 'Code is inactive'
          : activeReservation.smart_lock.status === 1
            ? 'Code is active'
            : 'Error when granting access'
      ">
      <sbnb-icon icon="key" class="text-grass-darker"></sbnb-icon>
      <span>{{ activeReservation.smart_lock.code }}</span>
    </a>
    <!-- Smartlock code display -->
  </ng-container>
</div>

<ng-container *ngIf="!activeReservation.isParentChildReservation">
  <div fxLayout="column" fxLayoutGap="8px">
    <sbnb-button
      *ngIf="showDetailsButton"
      label="Details"
      size="mini"
      [fullWidth]="true"
      (clicked)="openThreadDetails(activeReservation)"></sbnb-button>

    <sbnb-button
      *ngIf="
        (perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) &&
        activeReservation.status === 'Accepted' &&
        activeReservation.supports?.alterations
      "
      size="mini"
      type="secondary"
      label="Alter reservation"
      [fullWidth]="true"
      (clicked)="openAlterationRequest(activeReservation)"></sbnb-button>

    <sbnb-button
      *ngIf="(perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) && activeReservation.supports?.editing"
      size="mini"
      label="Edit reservation"
      type="secondary"
      [fullWidth]="true"
      (clicked)="openEditReservationDialog()"></sbnb-button>

    <!-- Adjustments (Direct refunds and additional charges)-->
    <sbnb-button
      *ngIf="
        (perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) &&
        (activeReservation.supports?.adjustments || activeReservation.supports?.charges)
      "
      label="Send or request money"
      size="mini"
      type="secondary"
      [fullWidth]="true"
      (clicked)="openCancellationAndRefundDialog(false)"></sbnb-button>

    <sbnb-button
      *ngIf="
        (perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) &&
        activeReservation.supports?.leave_guest_review
      "
      label="Review guest"
      size="mini"
      type="secondary"
      [fullWidth]="true"
      (clicked)="openReviewGuestDialog(activeReservation)"></sbnb-button>

    <sbnb-button
      *ngIf="
        (perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) &&
        ((activeReservation.status === 'Accepted' && activeReservation.supports?.cancellations) ||
          activeReservation.supports?.cancellation_due_to_invalid_cc ||
          activeReservation.supports?.cancellation_due_to_no_show)
      "
      label="Cancel reservation"
      size="mini"
      type="secondary"
      [fullWidth]="true"
      (clicked)="openCancellationAndRefundDialog(true)"
      data-element="cancel-reservation-button"></sbnb-button>

    <sbnb-button
      *ngIf="
        (perms$.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) &&
        activeReservation.supports?.report_invalid_payment_details
      "
      label="Report invalid credit card"
      size="mini"
      type="secondary"
      [fullWidth]="true"
      (clicked)="openReportInvalidCreditCardDialog()"></sbnb-button>
  </div>
</ng-container>
