import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { User } from "@app/shared/interfaces";
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { SidebarCountsService } from '@app/shared/services/sidebar-counts/sidebar-counts.service';
import { OnboardingService, OnboardingStore } from '@app/modules/onboarding/onboarding.service';
import { OnDestroyComponent } from '@app/shared/components/on-destroy/on-destroy.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sbnb-menu-mobile-tabs',
  styleUrls: ['./menu-mobile-tabs.component.scss'],
  templateUrl: './menu-mobile-tabs.component.html'
})
export class MenuMobileTabsComponent extends OnDestroyComponent implements OnInit {

  public currentUser$: Observable<User>;
  navItems: any[] = [];
  badgeCounts: object;


  constructor(
    private authService: AuthenticationService,
    private userPermissionsService: UserPermissionsService,
    private sidebarCountsService: SidebarCountsService,
    private onboardingService: OnboardingService
  ) { 
    super();
  }

  ngOnInit() {
    this.currentUser$ = this.authService.user$;


    combineLatest([this.currentUser$, this.userPermissionsService.permissions])
    .pipe(takeUntil(this.destroyed$))
    .subscribe(([user, permissions]) => {
      if (user) {
        if (permissions.is_team_owner || permissions.is_admin || permissions.all_features) {
          this.navItems = this.getNavItems(user, [], true);
        } else {
          this.navItems = this.getNavItems(user, permissions.features);
        }
      }
    });

    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (user) {
        this.getBadgeCounts();
      }
    });
  }

  getNavItems(user: User, features = [], all = false) {
    const allNavItems = {
      inbox: {
        link: '/inbox',
        expandedText: 'Inbox',
        tooltipText: 'Inbox',
        id: 'nav-inbox',
        icon: 'message-simple',
        badgeCountKey: 'unread_messages',
      },
      calendar: {
        link: '/calendar',
        expandedText: 'Calendar',
        id: 'nav-calendar',
        icon: 'calendar',
      },
      metrics: {
        link: '/metrics',
        expandedText: 'Metrics',
        id: 'nav-metrics',
        icon: 'metrics-simple',
      },
    };

    const nav = [];
    const allFeatures = [
      'inbox',
      'calendar',
      'metrics',
    ];

    if (all) {
      features = allFeatures;
    }

    allFeatures.forEach((feature) => {
      if (features.includes(feature) && allNavItems[feature]) {
        nav.push(allNavItems[feature]);
      }
    });

    return nav;
  }

  getBadgeCounts() {
    this.sidebarCountsService.getCountsFromApi().subscribe((res) => {
      this.badgeCounts = res;

      this.onboardingService.onboardingItems.subscribe((items: OnboardingStore) => {
        // Don't show an unread count if the user has not tried connecting an account yet
        if (!items.meta.has_ever_connected_airbnb_account) {
          return;
        }

        this.badgeCounts['incomplete_onboarding_steps'] = items.meta.pending_count;
      });
    });
  }

}