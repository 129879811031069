import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { cloneDeep } from 'lodash-es';
import { format } from 'date-fns';
import { Filter, FilterStrategy, FilterInterval } from '@app/shared/interfaces/lib/filter.interface';
import { ConvertEnumToArray } from '@app/shared/utils';

@Component({
  selector: 'hospitable-filter-menu-date',
  templateUrl: './filter-menu-date.component.html',
  styleUrls: ['./filter-menu-date.component.scss'],
})
export class FilterMenuDateComponent implements OnInit, OnChanges {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @Input() filter: Filter;
  @Output() applyFilter: EventEmitter<Filter> = new EventEmitter();

  workingFilter: Filter; // a working, local copy of the input
  strategies: FilterStrategy[];
  dateIntervalTypes: FilterInterval[] = ConvertEnumToArray<FilterInterval[]>(FilterInterval);

  FilterStrategy = FilterStrategy;

  ngOnInit(): void {
    this.strategies = this.findStrategies();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter']) {
      this.workingFilter = cloneDeep(changes['filter'].currentValue);
    }
  }

  provideDefaults(strategy: FilterStrategy) {
    if (
      (strategy === FilterStrategy.Current || strategy === FilterStrategy.Past) &&
      !this.workingFilter.initial_payload.interval_type
    ) {
      this.workingFilter.initial_payload.interval_type = this.dateIntervalTypes[0];
    }

    if (strategy === FilterStrategy.Past && !this.workingFilter.initial_payload.interval_length) {
      this.workingFilter.initial_payload.interval_length = 1;
    }
  }

  modalClose() {
    if (JSON.stringify(this.workingFilter) !== JSON.stringify(this.filter)) {
      if (!this.workingFilter.initial_payload.interval_length) {
        this.workingFilter.initial_payload.interval_length = 1;
      }

      if (
        this.workingFilter &&
        this.workingFilter.initial_payload &&
        this.workingFilter.initial_payload.strategy === FilterStrategy.Custom
      ) {
        if (this.workingFilter.initial_payload.start && this.workingFilter.initial_payload.end) {
          this.applyFilter.emit(this.workingFilter);
        } else {
          return;
        }
      } else {
        this.applyFilter.emit(this.workingFilter);
      }
    }
  }

  customDatesSelected(selectedDates: any) {
    if (selectedDates && selectedDates[0] && selectedDates[1]) {
      this.workingFilter.initial_payload.start = format(selectedDates[0], 'YYYY-MM-DD');
      this.workingFilter.initial_payload.end = format(selectedDates[1], 'YYYY-MM-DD');
    }
  }

  findStrategies(): FilterStrategy[] {
    if (this.workingFilter.columns && this.workingFilter.columns.length) {
      // We have multiple columns

      // Find the active column and it's available strategies
      const activeCol = this.workingFilter.initial_payload.column;

      if (!activeCol) {
        throw new Error('Cant find the defaultCol in findStrategies');
      }

      // Find the strategies available for this column
      const strategiesForCol = this.workingFilter.columns.find((col: any) => col.value === activeCol);

      if (!strategiesForCol) {
        throw new Error(`Cant find the strategies for ${activeCol} in findStrategies`);
      }

      return strategiesForCol.strategies;
    } else {
      return this.workingFilter.strategies as FilterStrategy[];
    }
  }
}
