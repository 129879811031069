import { Component, OnInit } from '@angular/core';
import { TurnoService } from '@app/shared/services/turno.service';

@Component({
  standalone: false,
  templateUrl: './turno-activate.component.html',
  styleUrls: ['./turno-activate.component.scss'],
})
export class TurnoActivateComponent implements OnInit {
  error: boolean = false;

  constructor(private turno: TurnoService) { }

  ngOnInit(): void {
    this.turno.activateTurnoAccount().subscribe(
      (res) => {
        if (res.redirect) {
          window.location.href = res.redirect;
        } else {
          this.error = true;
        }
      },
      (err) => {
        this.error = true;
      }
    );
  }

  goBack() {
    window.history.back();
  }
}
