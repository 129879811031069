export interface ProgressBarProgress {
  value: number;
  formatted?: string;
  colour: ProgressBarColour;
}

export interface ProgressBarDifference {
  value: number;
  formatted?: string;
  colour: ProgressBarColour;
}

export enum ProgressBarColour {
  Positive,
  Neutral,
  Danger,
}

export const ProgressBarColourMapper = {
  bar: {
    [ProgressBarColour.Positive]: 'bg-grass-darker',
    [ProgressBarColour.Neutral]: 'bg-lemon-darker',
    [ProgressBarColour.Danger]: 'bg-rose-darker',
  },
  text: {
    [ProgressBarColour.Positive]: 'text-grass-darker',
    [ProgressBarColour.Neutral]: 'text-lemon-darker',
    [ProgressBarColour.Danger]: 'text-rose-darker',
  },
  difference: {
    [ProgressBarColour.Positive]: 'bg-grass-darker',
    [ProgressBarColour.Neutral]: 'bg-lemon-darker',
    [ProgressBarColour.Danger]: 'bg-rose-darker',
  },
};

export interface ProgressBar {
  title: string;
  progress: ProgressBarProgress;
  difference?: ProgressBarProgress;
}
