import { Pipe, PipeTransform } from '@angular/core';

/**
 * Inspired by ruby's to_sentence method
 *
 * @example
 * {{ ['apple', 'banana', 'cherry'] | toSentence }}
 * // Output: "apple, banana and cherry"
 */
@Pipe({
  standalone: false,
  name: 'toSentence',
})
export class ToSentencePipe implements PipeTransform {
  transform(value: unknown, connector = ',', lastConnector = ', and', key?: string): unknown {
    if (!Array.isArray(value) || value.length === 0) {
      return value;
    }

    if (typeof value[0] === 'object' && key) {
      const items = value.map((item) => item[key]);
      return this.join(items, connector, lastConnector);
    }

    return this.join(value, connector, lastConnector);
  }

  private join(value: string[], connector: string, lastConnector: string) {
    if (value.length === 1) {
      return value[0];
    }

    const lastElement = value.pop();
    return value.join(connector + ' ') + lastConnector + ' ' + lastElement;
  }
}
