import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Filter } from '@app/shared/models/filter';
import { FilterService } from '@app/shared/services/filter/filter.service';
import { SegmentService } from '@app/shared/services/filter/segment.service';
import { DialogDeleteSegmentComponent } from '../dialog-delete-segment/dialog-delete-segment.component';

@Component({
  standalone: false,
  selector: 'sbnb-saved-segments',
  templateUrl: './saved-segments.component.html',
  styleUrls: ['./saved-segments.component.scss'],
})
export class SavedSegmentsComponent implements OnInit {
  @Input() key: string;
  @Input() activeId: string; // used for default handling to set the routerLinkActive correctly
  @Input() quickFilterKeys: string[];
  @Input() inMobileMenu: boolean = false;
  @Output() quickFilterChange: EventEmitter<any> = new EventEmitter();

  quickFilters: any[] = [];
  globalSegments: Filter[];
  savedSegments: Filter[];
  loading: boolean;

  showAllMinSize = 5;
  showAllQuickFilters = false;
  showAllSegments = false;

  constructor(
    private segmentService: SegmentService,
    private dialog: MatDialog,
    private filterService: FilterService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.globalSegments = [];
    this.savedSegments = [];

    this.buildQuickFilters();

    // Hydrate the list of segments
    this.segmentService.getSegmentListForKey(this.key).subscribe((res) => {
      this.loading = false;
    });

    this.segmentService.segmentsSbj.subscribe((res) => {
      // Group by the group parameter
      this.globalSegments = res
        .filter((filter) => filter.is_global === true)
        .reduce((r, a) => {
          r[a.group] = r[a.group] || [];
          r[a.group].push(a);
          return r;
        }, Object.create(null));

      this.savedSegments = res
        .filter((filter) => filter.is_global === false)
        .reduce((r, a) => {
          r[a.group] = r[a.group] || [];
          r[a.group].push(a);
          return r;
        }, Object.create(null));
    });
  }

  applyQuickFilter(filter: any, selectedValue: any) {
    filter.selectedValue = {
      key: selectedValue.key,
      label: selectedValue.label,
    };
    this.quickFilterChange.emit(filter);
  }

  buildQuickFilters() {
    if (!this.quickFilterKeys || this.quickFilterKeys.length < 0) {
      return;
    }

    this.filterService.getFiltersForKey(this.key).subscribe((res) => {
      if (res && res.length) {
        res.forEach((filter) => {
          if (this.quickFilterKeys.includes(filter.id)) {
            this.filterService.getFilterValuesForKey(filter.values).subscribe((res) => {
              filter.valuesData = res;
              this.quickFilters.push(filter);
            });
          }
        });
      }
    });
  }

  linkify(id: string): string {
    if (this.key === 'messaging-rules') {
      return `/gx/rules/segments/${id}`;
    }

    if (this.key === 'notifications') {
      return `/operations/notifications/segments/${id}`;
    }

    return `/${this.key}/segments/${id}`;
  }

  deleteSegment(id: string, name: string) {
    const dialogRef = this.dialog.open(DialogDeleteSegmentComponent, {
      width: '500px',
      data: { segmentName: name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.segmentService.deleteSegment(id, this.key).subscribe((res) => {
          const index = this.savedSegments['Saved Segments'].findIndex((obj) => obj.uuid === id);

          if (index !== -1) {
            this.savedSegments['Saved Segments'].splice(index, 1);
          }
        });
      }
    });
  }
}
