<div
  class="flex flex-1 flex-row items-center relative box-border page-header justify-center sbnbDesktop:justify-start"
  [ngClass]="{ 'min-h-0': !navigateBack }">
  <sbnb-icon
    icon="left-arrow"
    class="clickable nav-back-icon pr-2 block sbnbDesktop:hidden"
    (click)="goBack()"
    *ngIf="navigateBack"></sbnb-icon>
  <ng-content></ng-content>
</div>
