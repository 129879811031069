<a class="support-documentation__article-link" [routerLink]="['/support-documentation/article', article.slug]">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow">
    <path
      d="M3.33325 8H12.6666"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M8 3.33334L12.6667 8.00001L8 12.6667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
  <div class="content">
    <h4>{{ article.title }}</h4>
    <p *ngIf="article.category && article.section">
      <em>in</em>
      {{ article.category.name }} > {{ article.section.name }}
    </p>
    <p *ngIf="article.category && !article.section">
      <em>in</em>
      {{ article.category.name }}
    </p>
    <p *ngIf="!article.category && article.section">
      <em>in</em>
      {{ article.section.name }}
    </p>
  </div>
</a>
