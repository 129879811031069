import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'doesRuleHavePlatform',
})
export class DoesRuleHavePlatformPipe implements PipeTransform {
  transform(rule: any, platform: string): any {
    if (rule.all_platforms === true) {
      return true;
    }

    if (rule.platforms.includes(platform)) {
      return true;
    }

    return false;
  }
}
