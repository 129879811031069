<div class="flex gap-4" id="thread_alert">
  <sbnb-reservation-icon></sbnb-reservation-icon>

  <sbnb-split-card [imgSrc]="message.listing?.image" [imgHeadine]="message.listing?.name">
    <div class="flex flex-col gap-4 p-6">
      <h3>Booking request</h3>
      <div class="flex flex-col gap-3">
        <div class="flex gap-2">
          <img src="/assets/iconography/check-in.svg" alt="Check in icon" />
          <span>{{ startDate | date: "EEE, LLL d ''yy" }}</span>
        </div>
        <div class="flex gap-2">
          <img src="/assets/iconography/check-out.svg" alt="Check out icon" />
          <span>{{ endDate | date: "EEE, LLL d ''yy" }}</span>
        </div>

        <div *ngIf="message.subtotal" class="flex gap-2">
          <img src="/assets/iconography/subtotal.svg" alt="Subtotal" />
          <span>{{ message.currency_code }}{{ message.revenue }}</span>
        </div>

        <div class="flex gap-2">
          <div *ngIf="message.num_adults" class="flex gap-2">
            <sbnb-icon icon="adults" class="text-smoke-darker"></sbnb-icon>
            <span>{{ message.num_adults }}</span>
          </div>
          <div *ngIf="message.num_children > 0" class="flex gap-2">
            <img src="/assets/iconography/kids.svg" alt="Children" />
            <span>{{ message.num_children }}</span>
          </div>
          <div *ngIf="message.num_infants > 0" class="flex gap-2">
            <img src="/assets/iconography/infants.svg" alt="Children" />
            <span>{{ message.num_infants }}</span>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap-reverse gap-3 justify-between">
        <sbnb-button
          label="Reject"
          type="secondary"
          [loading]="rejectLoading"
          [disabled]="acceptLoading"
          (clicked)="reject.emit(message)"></sbnb-button>

        <!-- Direct premium specific logic -->
        <ng-container *ngIf="message.payment_provider === 'adyen'; else acceptButton">
          <ng-container
            *ngIf="
              guestVetting?.summary?.supervised_status === SupervisedStatus.APPROVE ||
                guestVetting?.summary?.guest_portal_status === SupervisedStatus.COMPLETED ||
                guestVetting?.summary?.verification_status === VerificationStatus.VERIFIED;
              else checkGuestPortalStatus
            ">
            <sbnb-button
              label="Accept"
              type="primary-teal"
              [loading]="acceptLoading"
              [disabled]="rejectLoading"
              (clicked)="accept.emit(message)"></sbnb-button>
          </ng-container>

          <ng-template #checkGuestPortalStatus>
            <span class="flex align-center justify-center gap-3 italic items-center">
              <ng-container
                *ngIf="
                  guestVetting?.summary?.verification_status === VerificationStatus.REVIEW;
                  else checkGuestPortalReviewStatus
                ">
                Awaiting review
              </ng-container>

              <ng-template #checkGuestPortalReviewStatus>
                <ng-container
                  *ngIf="guestVetting?.summary?.verification_status === VerificationStatus.PENDING; else acceptButton">
                  Verification incomplete
                </ng-container>
              </ng-template>
            </span>
          </ng-template>
        </ng-container>

        <ng-template #acceptButton>
          <sbnb-button
            label="Accept"
            type="primary-teal"
            [loading]="acceptLoading"
            [disabled]="rejectLoading"
            (clicked)="accept.emit(message)"></sbnb-button>
        </ng-template>
      </div>
    </div>
  </sbnb-split-card>
</div>
