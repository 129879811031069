import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GuestPortalStatus,
  GuestVettingData,
  SupervisedStatus,
  VerificationStatus,
} from '@app/shared/interfaces/lib/guestvetting.interface';
import { MessageReservation } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-booking-request',
  templateUrl: './booking-request.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRequestComponent implements OnInit {
  @Input() message: MessageReservation;
  @Input() acceptLoading: boolean;
  @Input() rejectLoading: boolean;
  @Input() guestVetting: GuestVettingData;
  @Output() accept = new EventEmitter();
  @Output() reject = new EventEmitter();

  SupervisedStatus = SupervisedStatus;
  VerificationStatus = VerificationStatus;
  GuestPortalStatus = GuestPortalStatus;

  startDate: string;
  endDate: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.message) return;
    this.startDate = this.message.start_date?.split('T')[0];
    this.endDate = this.message.end_date?.split('T')[0];
  }
}
