import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { NotificationType } from '@app/shared/interfaces';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  standalone: false,
  selector: 'sbnb-rule-timing',
  templateUrl: './rule-timing.component.html',
  styleUrls: ['./rule-timing.component.scss'],
})
export class RuleTimingComponent implements OnInit, OnChanges {
  overrideUnits: boolean;
  overrideTiming: boolean;
  originalMode: string;

  @Input('meta') meta: any;
  @Input('ruleset') ruleset: any;

  @Output() triggerSave: EventEmitter<any> = new EventEmitter();

  constructor(private toast: ToastNotificationsService) { }

  ngOnInit() {
    if (this.ruleset.timing.unit.value === 0) {
      this.overrideTiming = true;
    }

    const index = this.meta.modes.findIndex((mode) => mode.key === this.ruleset.mode);

    if (index > -1) {
      if (this.meta.modes[index].overrideUnits) {
        this.overrideUnits = true;
      }
    }

    this.originalMode = this.ruleset.mode;

    this.generateModeLabel();
    this.generateUnitsLabel();
  }

  ngOnChanges() {
    this.generateModeLabel();
    this.generateUnitsLabel();
  }

  modeChanged(newMode: any) {
    this.ruleset.mode = newMode.value;
    this.generateModeLabel();

    this.overrideUnits = newMode.overrideUnits;
  }

  whenChanged(newWhen: any) {
    this.ruleset.timing.when = newWhen.value;
  }

  unitsChanged(event: MatRadioChange) {
    if (!event.value) {
      return;
    }

    const newUnitObj = event.value;

    if (newUnitObj.type === 'static') {
      this.ruleset.timing.unit.value = newUnitObj.value;
    } else {
      if (this.ruleset.timing.unit.value === 0) {
        this.ruleset.timing.unit.value = 1;
      }
    }

    this.ruleset.timing.unit.value_append = newUnitObj.value_append;

    this.overrideTiming = newUnitObj.override_timing;

    this.generateUnitsLabel();
  }

  unitIntInputChange($event, unit) {
    const newValue = $event.target.value;
    const validation = unit.validation;

    if (!validation) {
      this.ruleset.timing.unit.value = newValue;

      this.generateUnitsLabel();
    } else {
      let validationPassed = true;
      const max = validation.find((val) => val.type === 'max');
      const min = validation.find((val) => val.type === 'min');

      if (max && newValue > max.value) {
        validationPassed = false;

        this.toast.open(`Please enter a number less than or equal to ${max.value}`, 'Dismiss', NotificationType.Error);
      }

      if (min && newValue < min.value) {
        validationPassed = false;

        this.toast.open(
          `Please enter a number greater than or equal to ${min.value}`,
          'Dismiss',
          NotificationType.Error
        );
      }

      if (validationPassed) {
        this.ruleset.timing.unit.value = newValue;

        this.generateUnitsLabel();
      }
    }
  }

  generateModeLabel() {
    const activeMode = this.ruleset.mode;

    const index = this.meta.modes.findIndex((mode) => mode.value === activeMode);

    if (index !== -1) {
      this.ruleset.mode_label = this.meta.modes[index].label;
    } else {
      this.ruleset.mode_label = this.ruleset.mode;
    }
  }

  generateUnitsLabel() {
    const rulesetTiming = this.ruleset.timing.unit;
    let result = '';

    if (rulesetTiming.value === 0) {
      result = 'immediately';
    } else {
      result = `${rulesetTiming.value} ${this.friendlyAppend(rulesetTiming.value_append)}`;
    }

    rulesetTiming.value_label = result;
  }

  friendlyAppend(value_append: string): string {
    switch (value_append) {
      case 'm':
        return 'minutes';

      case 'h':
        return 'hours';

      case 'd':
        return 'days';

      default:
        break;
    }
  }

  save() {
    const origTime = this.ruleset.time;
    const origMode = this.originalMode;

    this.originalMode = this.ruleset.mode;

    if (this.ruleset.mode !== 'passive') {
      this.ruleset.time = `${this.ruleset.timing.when === 'before' ? '-' : ''}${this.ruleset.timing.unit.value}${this.ruleset.timing.unit.value_append ? this.ruleset.timing.unit.value_append : ''
        }`;
    } else {
      this.ruleset.time = 0;
    }

    if (this.ruleset.time !== origTime || this.ruleset.mode != origMode) {
      this.triggerSave.emit();
    }
  }
}
