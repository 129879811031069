import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'hasOverlapWithOtherTasks',
})
export class HasOverlapWithOtherTasksPipe implements PipeTransform {
  transform(task: any, taskBucket: any[]): any {
    const startDate = task.start_date;

    return taskBucket.filter((t) => isSameDay(t.start_date, startDate)).length;
  }
}
