<div
  class="radio-card"
  [ngClass]="{
    selected: selected,
    disabled: disabled,
    readonly: readonly,
    positive: context === 'positive',
    neutral: context === 'neutral',

    '!py-4 !px-5': size === 'small',
  }">
  <input [readonly]="readonly" [disabled]="disabled" class="radio-control" type="radio" [checked]="selected" />

  <ng-content></ng-content>
</div>
