<div
  *ngIf="!inlineMode"
  class="time__container relative"
  [class.time__container-border]="!readOnly"
  [class.pointer-events]="readOnly">
  <div class="time__display" fxLayoutAlign="start center" fxLayoutGap="3px" [matMenuTriggerFor]="editMenu">
    <!-- Move the SVG here and add positioning styles -->
    <svg
      *ngIf="upsell"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="rounded-full bg-raspberry-darker text-white h-4 w-4 time__icon absolute -top-2 -right-2">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>

    <span>
      {{ user.uses_24_hour_format ? (hours | number: '2.0') : hours
      }}{{ minutes > 0 || user.uses_24_hour_format ? ':' + (minutes | number: '2.0') : null
      }}{{ !user.uses_24_hour_format ? amPm : null }}
    </span>

    <img *ngIf="!readOnly" class="arrow" src="/assets/iconography/arrow-down-raspberry.svg" width="14" height="14" />
  </div>

  <mat-menu #editMenu="matMenu" class="filters__cdk-menu filters__cdk-menu-130" (closed)="menuClosed()">
    <ng-container *ngTemplateOutlet="pickerTemplate"></ng-container>
  </mat-menu>
</div>

<div *ngIf="inlineMode">
  <ng-template [ngTemplateOutlet]="pickerTemplate"></ng-template>
</div>

<ng-template #pickerTemplate>
  <div (click)="$event.stopPropagation()" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
    <div fxFlex="50px" fxLayout="column" fxLayoutAlign="start center" class="time__editor-segment">
      <img
        fxFlex
        (click)="incrementHours()"
        class="clickable"
        src="/assets/images/up-arrow.svg"
        alt="Increment Hours" />
      <input
        fxFlex
        type="text"
        [ngModel]="user.uses_24_hour_format ? (hours | number: '2.0') : hours"
        (change)="onChangeHours($event)"
        #hoursInput />
      <img
        fxFlex
        (click)="decrementHours()"
        class="clickable"
        src="/assets/images/down-arrow.svg"
        alt="Decrement Hours" />
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="time__editor-segment">
      <span>:</span>
    </div>

    <div fxFlex="50px" fxLayout="column" fxLayoutAlign="start center" class="time__editor-segment">
      <img
        fxFlex
        (click)="incrementMinutes()"
        class="clickable"
        src="/assets/images/up-arrow.svg"
        alt="Increment Minutes" />
      <input type="text" [ngModel]="minutes | number: '2.0'" (change)="onChangeMinutes($event)" />
      <img
        fxFlex
        (click)="decrementMinutes()"
        class="clickable"
        src="/assets/images/down-arrow.svg"
        alt="Decrement Minutes" />
    </div>

    <div
      *ngIf="!user.uses_24_hour_format"
      fxFlex="50px"
      fxLayout="column"
      fxLayoutAlign="start center"
      class="time__editor-segment">
      <img fxFlex class="clickable" (click)="changeAmPm()" src="/assets/images/up-arrow.svg" alt="Change am/pm" />
      <input type="text" disabled [ngModel]="amPm" />
      <img fxFlex class="clickable" src="/assets/images/down-arrow.svg" alt="Change am/pm" (click)="changeAmPm()" />
    </div>
  </div>
</ng-template>
