<div
  *ngFor="let subItem of items | slice: 0 : show"
  [ngClass]="{ 'financial-alert': subItem.status === 'alert' }"
  class="value space-between sub-section">
  <span class="flex-1 leading-snug" [matTooltip]="subItem.info">
    {{ subItem.title }}
  </span>
  <span class="financial">{{ subItem.amount_formatted }}</span>
  <span *ngIf="subItem.warning" class="warning"></span>
</div>
<div class="flex w-full items-center justify-center">
  <sbnb-show-more [length]="items.length" [limit]="8" (showMore)="show = $event"></sbnb-show-more>
</div>
