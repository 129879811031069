import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogAddManualBookingComponent } from '@app/shared/components/dialog-add-manual-booking/dialog-add-manual-booking.component';
import { Observable } from 'rxjs';
import { SegmentEvent, SegmentIoService } from '../segmentIo/segment-io.service';
import { ManualBooking } from './manual-bookings.service';

@Injectable({
  providedIn: 'root',
})
export class DialogManualBookingsService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly segmentIoService: SegmentIoService
  ) {}

  public openDialog(
    hasReservationCode: boolean,
    mode: 'create' | 'update' = 'create',
    booking?: ManualBooking,
    startDate?: Date | string, // YYYY-MM-DD
    endDate?: Date | string, // YYYY-MM-DD
    propertyId?: number
  ): Observable<any> {
    // Track the open event
    this.segmentIoService.track(SegmentEvent.ManualBookingDialogOpened, {});

    const dialogRef = this.dialog.open(DialogAddManualBookingComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
      data: {
        hasReservationCode,
        mode,
        booking,
        startDate,
        endDate,
        propertyId,
      },
    });

    return dialogRef.afterClosed();
  }
}
