<ng-container *ngFor="let guest of guests; let i = index">
  <img
    *ngIf="i < 5"
    [style.left.px]="i * -6"
    class="guest-image"
    [src]="guest.picture"
    onerror="this.src='/assets/images/profile-default.png'"
    [title]="guest.name" />
</ng-container>

<span *ngIf="guests.length >= 5" class="property__more">+{{ guests.length - 5 }}</span>
