import { Pipe, PipeTransform } from '@angular/core';

interface Discounts {
  [k: string]: {
    title: string;
    value: string;
  };
}

@Pipe({
  standalone: false,
  name: 'subTotalMinusDiscounts',
})
export class SubTotalMinusDiscountsPipe implements PipeTransform {
  transform(subtotal: number, discounts: Discounts): string {
    if (!subtotal) {
      return '0';
    }
    if (!discounts) {
      return subtotal.toString();
    }
    const discountValues = Object.values(discounts);

    if (!discountValues.length) {
      return subtotal.toString();
    }

    const discountTotal = discountValues.reduce((acc, curr) => {
      return acc + Number(curr.value);
    }, 0);

    return (subtotal - discountTotal).toFixed(2);
  }
}
