import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Filter } from '@app/shared/models/filter';
import { FilterService } from '@app/shared/services/filter/filter.service';
import { isEqual } from 'date-fns';
import { DialogSaveSegmentComponent } from '../dialog-save-segment/dialog-save-segment.component';

@Component({
  standalone: false,
  selector: 'sbnb-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnChanges {
  numListOptionsForSearchBar = 10;
  numListOptionsForSearchOnly = 1000;

  @Input() componentName: string; // Used as the key to ask the API what filters are available
  @Input() defaultFilters: Filter[];
  @Input() hideSaveSegment = false;
  @Input() activeSegment: string; // the uuid of the active segment - we need this in some instances for context for the API
  @Input() buttonLabel = '+ Filter';
  @Input() customEndpoint: string; // If specified, we don't use the typical filter URL's, we use the one provided here
  @Input() sortByLabel = true;

  @Output() filterChanged: EventEmitter<Filter[]> = new EventEmitter();
  @Output('reset') resetEmitter: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren('inputUpcoming') inputUpcoming: any;
  @ViewChildren('inputPrevious') inputPrevious: any;
  @ViewChildren('inputExactly') inputExactly: any;
  @ViewChildren('inputMoreThan') inputMoreThan: any;
  @ViewChildren('inputLessThan') inputLessThan: any;
  @ViewChildren(MatMenuTrigger) filterMenuTrigger!: QueryList<MatMenuTrigger>;

  filters: Filter[];
  availableFilters: Filter[];
  activatedFilters: Filter[];
  searchCriteria: any;
  loading: boolean;

  searchTimeOut;
  searchTimeOutDuration = 500;

  private pipe = new DatePipe('en-US');

  constructor(
    public dialog: MatDialog,
    private filterService: FilterService
  ) {}

  ngOnInit() {
    this.loading = true;

    this.availableFilters = [];
    this.activatedFilters = [];
    this.searchCriteria = {};

    this.filterService.getFiltersForKey(this.componentName, this.customEndpoint).subscribe((filters) => {
      this.filters = filters;

      this.buildAvailableFiltersList(this.filters);

      if (this.defaultFilters) {
        this.checkDefaultFilters(this.defaultFilters);
      }

      this.loading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  // If we received a list of filters to apply on init via @Input, set these as our active
  private checkDefaultFilters(filters: Filter[]): void {
    filters.forEach((filter) => {
      if (!filter.selectedValue && filter.type !== 'static' && filter.type !== 'togglable') {
        return;
      }

      const index = this.availableFilters.findIndex((avFil) => avFil.id === filter.id);

      if (index > -1) {
        this.availableFilters.splice(index, 1);
      }
    });

    this.activatedFilters = filters;
  }

  // Build a list of available filters
  private buildAvailableFiltersList(filters: any[]): void {
    const avFilters = [];
    this.filters.forEach((filter) => {
      // Only push non-lockable filters to the list
      if (!filter.lockable) {
        avFilters.push(filter);
      }
    });

    this.availableFilters = avFilters;
  }

  // Mark a filter as active and remove it from being available
  public addFilter(index: number): void {
    const filter = this.availableFilters[index];
    this.activatedFilters.push(filter);
    this.availableFilters.splice(index, 1);
    if (filter.type === 'togglable') {
      this.valueChangeEmit();
    } else {
      setTimeout(() => {
        this.openMenu(filter.id);
      }, 100);
    }
  }

  // Sets the active value for a 'List' type filter
  public setFilterValue(filter: any[], option: {}): void {
    filter['selectedValue'] = option;

    this.valueChangeEmit();
  }

  // Sets the active value for a 'Number' type filter
  public setNumberValue(filter: any[], measure: string, number?: number) {
    const current = filter['selectedValue'];
    const displayNum: number = number ? number : current ? current['number'] : 1;

    switch (measure) {
      case 'eq':
        setTimeout(() => {
          this.inputExactly.first.nativeElement.focus();
        }, 0);
        break;

      case 'gt':
        setTimeout(() => {
          this.inputMoreThan.first.nativeElement.focus();
        }, 0);
        break;

      case 'lt':
        setTimeout(() => {
          this.inputLessThan.first.nativeElement.focus();
        }, 0);
        break;

      default:
        break;
    }

    filter['selectedValue'] = {
      key: measure,
      number: displayNum,
      label: `${this.friendlyNumberLabel(measure, displayNum)}`,
    };

    this.valueChangeEmit();
  }

  // Sets the active value for a 'Date' type filter
  public setDateValue(filter: any[], measure: string, number?: number | Date[]) {
    const current = filter['selectedValue'];

    const desiredNum = number ? number : current && !Array.isArray(current.number) ? current.number : 1;

    switch (measure) {
      case 'upcoming':
        setTimeout(() => {
          this.inputUpcoming.first.nativeElement.focus();
        }, 0);
        break;

      case 'previous':
        setTimeout(() => {
          this.inputPrevious.first.nativeElement.focus();
        }, 0);
        break;

      default:
        break;
    }

    filter['selectedValue'] = {
      key: measure,
      number: desiredNum,
      label: this.friendlyDateLabel(measure, desiredNum, current),
    };

    if (measure === 'custom' && typeof number !== 'object') {
      // We used a custom filter, but don't yet have date objects selected, we do nothing
      return;
    }

    this.valueChangeEmit();
  }

  valueChangeEmit() {
    this.filterChanged.emit(this.activatedFilters);
  }

  // A helper function to handle the display label for a 'Date' filter
  private friendlyDateLabel(measure: string, number: number | Date[], current?: number) {
    if (measure === 'today' || measure === 'tomorrow') {
      return measure;
    } else if (measure === 'custom') {
      return `${number && number[0] ? this.pipe.transform(number[0], 'mediumDate') : ''} ${
        number && number[1] && !isEqual(number[0], number[1]) ? `- ${this.pipe.transform(number[1], 'mediumDate')}` : ''
      }`;
    }
    const displayNum: number = number ? number : current ? current['number'] : 1;
    return `${measure} ${displayNum} day${displayNum > 1 ? 's' : ''}`;
  }

  // A helper function to handle the display label for a 'Number' filter
  private friendlyNumberLabel(measure: string, number: number): string {
    let resp = '';

    switch (measure) {
      case 'eq':
        resp = `${number}`;
        break;

      case 'gt':
        resp = `> ${number}`;
        break;

      case 'lt':
        resp = `< ${number}`;
        break;

      default:
        break;
    }

    return resp;
  }

  // A shim which simulates the button click to auto-open the menu when adding a new filter
  private openMenu(filterKey: string): void {
    const elem = document.getElementById(`filter-button-${filterKey}`);
    const evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    if (elem) {
      elem.dispatchEvent(evt);
    }
  }

  // Given a list of options, and a search string, identify if the string appears in any options and return a filtered list
  public filtersMatchSearchCriteria(option, searchCriteria: string) {
    if (!searchCriteria || searchCriteria === '') {
      return true;
    }

    return option.label.toLowerCase().includes(searchCriteria.toLowerCase());
  }

  public reset(): void {
    this.resetEmitter.emit(true);
  }

  public selfReset(): void {
    this.activatedFilters = [];
    this.availableFilters = [];

    this.filters.forEach((filter) => {
      if (filter.selectedValue) {
        delete filter.selectedValue;
      }
    });

    this.buildAvailableFiltersList(this.filters);

    this.valueChangeEmit();
  }

  public saveSegment(): void {
    const dialogRef = this.dialog.open(DialogSaveSegmentComponent, {
      width: '380px',
      data: {
        key: this.componentName,
        filters: this.activatedFilters,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Nothing at the mo
    });
  }

  // Checks to ensure that we have at least one value selected across all activated filters
  public atLeastOneFilterValue() {
    return this.activatedFilters.find((filter: any) => {
      return filter.selectedValue;
    });
  }

  // Checks to ensure that we have at least one value selected on unlocked filters across all activated filters
  public atLeastOneUnlockedFilterValue() {
    return this.activatedFilters.find((filter: Filter) => {
      return (filter.selectedValue && !filter.lockable) || (filter.type === 'togglable' && !filter.lockable);
    });
  }

  public dateSelectChanged(filter, newDateArray: Date[]) {
    this.setDateValue(filter, 'custom', newDateArray);
  }

  fetchListMenuItems(filter: Filter): void {
    if (filter.type !== 'list') {
      if (filter.type === 'togglable') {
        this.filterMenuTrigger.forEach((trigger) => trigger.closeMenu());
      }
      return;
    }

    if (typeof filter.values !== 'string') {
      return;
    }

    // Very basic caching - if we already have filter values, don't bother getting them again
    // This will probably need enhancing before launch
    if (filter.valuesData && filter.valuesData.length > 0) {
      return;
    }

    filter.loading = true;

    this.filterService.getFilterValuesForKey(filter.values, this.activeSegment).subscribe((res) => {
      // find the corresponding filter and update its available options
      const filterIndex = this.filters.findIndex((poss) => filter.id === poss.id);

      if (filterIndex === -1) {
        // no matching filter - we have a mismatch, do nothing
        return;
      }

      const filterToUpdate: Filter = this.filters[filterIndex];

      filterToUpdate.valuesData = res;
      filter.loading = false;
    });
  }

  removeFilter(filter: Filter, event) {
    const idx = this.activatedFilters.findIndex((i) => i.id === filter.id);

    const filters = [...this.availableFilters];

    if (idx !== -1) {
      this.activatedFilters.splice(idx, 1);
      filters.push(filter);
      this.availableFilters = [...filters];
      this.valueChangeEmit();
    }
  }
}
