/* eslint-disable prefer-const */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-task-rule-timing-recurring',
  templateUrl: './task-rule-timing-recurring.component.html',
  styleUrls: ['./task-rule-timing-recurring.component.scss'],
})
export class TaskRuleTimingRecurringComponent implements OnInit {
  dayLabel: string;
  transformedFrequency: string;
  @Input('meta') meta: any;
  @Input('ruleset') ruleset: any;

  @Output() triggerSave: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (!this.ruleset.metadata.recurring) {
      this.ruleset.metadata = {
        recurring: {},
      };
    }

    let day = this.ruleset.metadata.recurring.day ?? this.meta.day[0];

    if (this.ruleset.metadata.recurring.occurrence == '' || this.ruleset.metadata.recurring.occurrence == null) {
      this.ruleset.metadata.recurring.occurrence = this.meta.occurrence[0];
    }

    if (this.ruleset.metadata.recurring.frequency == '' || this.ruleset.metadata.recurring.frequency == null) {
      this.ruleset.metadata.recurring.frequency = this.meta.frequency[0];
    }

    if (this.ruleset.metadata.recurring.hours == '' || this.ruleset.metadata.recurring.hours == null) {
      this.ruleset.metadata.recurring.hours = this.meta.hours;
    }

    if (this.ruleset.metadata.recurring.minutes == '' || this.ruleset.metadata.recurring.minutes == null) {
      this.ruleset.metadata.recurring.minutes = this.meta.minutes;
    }

    this.transformFrequency();
  }

  timeChanged(time: any) {
    this.ruleset.metadata.recurring.hours = time.hours;
    this.ruleset.metadata.recurring.minutes = time.minutes;
    this.save();
  }

  frequencyChanged(newFrequency: any) {
    this.ruleset.metadata.recurring.frequency = newFrequency;
    this.transformFrequency();
    this.save();
  }

  occurrenceChanged(newOccurrence: any) {
    this.ruleset.metadata.recurring.occurrence = newOccurrence;
    this.transformFrequency();
    this.save();
  }

  dayChanged(day: any, save: boolean = false) {
    this.ruleset.metadata.recurring.day = this.meta.day.indexOf(day);
    this.save();
  }

  save() {
    this.triggerSave.emit();
  }

  transformFrequency() {
    if (this.ruleset.metadata.recurring.occurrence != 1) {
      this.transformedFrequency = this.ruleset.metadata.recurring.frequency + 's';
    } else {
      this.transformedFrequency = this.ruleset.metadata.recurring.frequency;
    }
  }
}
