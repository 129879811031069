export interface ApiResponse<D, M = { pagination: PaginationMeta }> {
  data: D;
  meta?: M;
}

// This is currently a blended approach to both cursor and non-cursor pagination
// todo: I'd love to tidy this up and make it clearer
export class PaginationMeta {
  per_page: number; // used in both
  total?: number; // non-cursor only
  this_page?: number; // number of results on this page - non-cursor only
  offset?: number; // non-cursor only
  page_numbers: {
    // cursor returns these, but returns 0 for both it seems. Non-cursor uses these
    total_pages: number;
    this_page: number;
  };
  has_more?: boolean; // cursor based pagination uses this, if its missing its non-cursor based pagination
  _links: {
    next: string | null; // used in both cursor and non-cursor
    prev?: string | null; // cursor only
    previous?: string | null; // non-cursor only
    first?: string; // non-cursor only
    last?: string; // non-cursor only
  };
}

export interface LaravelPagination {
  current_page: number;
  from: number; // first record number on the current page
  last_page: number; // the number of the last page
  links: unknown; // we dont use this
  path: string; // the root path of the api
  per_page: number; // number of records per page
  to: number; // last record number on the current page
  total: number; // total number of records overall
}

export interface ApiErrorResponse<
  E = {
    code: number;
    errors: ApiValidationError;
    message: string;
    error_reason?: string;
  },
> {
  headers?: Record<string, unknown>;
  status?: number;
  statusText?: string;
  url?: string;
  ok?: boolean;
  name?: string;
  message: string;
  error: E;
}

export interface ApiValidationError {
  [key: string]: string;
}

export interface SimpleApiValidationError {
  success?: boolean;
  error: boolean;
  error_reason?: string;
  reason?: string;
  message?: string;
}

export function isSimplifiedError(err: any): err is SimpleApiValidationError {
  return err.error !== undefined;
}

export function parseApiErrorResponseForMessages(response: ApiErrorResponse) {
  let message = '';

  const error = response.error && response.error.errors ? response.error.errors : response.message;

  if (error && typeof error === 'object') {
    const keys = Object.keys(error);
    const needsLineBreak = keys.length > 1;

    keys.forEach((key) => {
      message += needsLineBreak ? error[key] + '<br/>' : error[key];
    });
  } else if (error && typeof error === 'string') {
    message += error;
  } else {
    message = 'Unknown error type';
  }

  return message;
}
