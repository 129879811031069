<section class="flex justify-end">
  <sbnb-close [border]="true" (click)="dialogRef.close()"></sbnb-close>
</section>

<section>
  <ng-container>
    <div class="fs-dialog-contents mx-auto">
      <!-- Heading-->
      <section class="mb-4">
        <h2 class="mt-4 mb-4">Would you recommend {{ data.guestName }} to other hosts?</h2>

        <p class="night nomargin">
          Hospitable will share your experience with other Hospitable hosts, but your review will not be shared with the
          guest.
        </p>
      </section>

      <!-- Recommended -->
      <section class="mb-8">
        <mat-radio-group fxLayout="row" [(ngModel)]="recommended">
          <div>
            <mat-radio-button class="full-width-radio-button" [value]="true">Yes</mat-radio-button>
          </div>
          <div>
            <mat-radio-button class="full-width-radio-button" [value]="false">No</mat-radio-button>
          </div>
        </mat-radio-group>
      </section>

      <!-- Comment -->
      <section class="mb-8 flex flex-col gap-1">
        <label class="form-label" for="comment"><strong>Comment</strong></label>
        <sbnb-textarea
          [minRows]="4"
          name="comment"
          placeholder="Tell us about your experience hosting {{ data.guestName }}"
          [(ngModel)]="comment"></sbnb-textarea>
      </section>

      <div class="flex justify-end">
        <sbnb-button label="Submit review" [loading]="loading" (clicked)="submit()"></sbnb-button>
      </div>
    </div>
  </ng-container>
</section>
