import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import {
  addMonths,
  endOfWeek,
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  eachDay,
  endOfMonth,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

@Component({
  standalone: false,
  selector: 'sbnb-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() numMonths = 2;
  @Input() startDate: string | Date = format(new Date(), 'YYYY-MM-DD'); // YYYY-MM-DD
  @Input() rangeSelect = false;
  @Input() initialDateRange: string[]; // YYYY-MM-DD
  @Input() reservations: { start: string; end: string; highlight?: boolean }[];
  @Input() checkInOutStyling = true;
  @Input() showDurationLabels = true;
  @Input() preventSelection = false;
  @Input() allowBothRangeAndSingle = false;
  @Input() preventPastDatesSelection = false;
  @Input() disabled = false;

  @Output() dateClicked: EventEmitter<string> = new EventEmitter();
  @Output() datesSelected: EventEmitter<string | string[]> = new EventEmitter();

  blockedDates: any = {};

  public dates: string[][]; // YYYY-MM-DD
  public cellSizePx = 37;
  public cellMarginPx = 2;

  public selectedFirstDateInRange: string;
  public selectedSecondDateInRange: string;
  public hoveredDates: string[];
  public selectedDates: string[];

  public daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  startOfWeekPref: number;

  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.startOfWeekPref = this.auth.getStartOfWeekPreference();
    if (this.startOfWeekPref === 0) {
      this.daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }

    this.dates = this.buildDates();

    if (this.initialDateRange?.length > 1) {
      this.selectedDate(format(this.initialDateRange[0], 'YYYY-MM-DD'));
      this.selectedDate(format(this.initialDateRange[1], 'YYYY-MM-DD'));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.startDate = format(this.startDate, 'YYYY-MM-DD');

    if (changes.reservations) {
      if (!changes.reservations.isFirstChange()) {
        // this.selectedFirstDateInRange = '';
        // this.selectedSecondDateInRange = '';
        // this.selectedDates = [];
        // this.hoveredDates = [];
      }

      this.buildBlockedDatesFromReservations(this.reservations);
    }
  }

  private buildBlockedDatesFromReservations(reservations) {
    if (!reservations || reservations.length === 0) {
      this.blockedDates = {};
      return;
    }

    const blockedDates = {};

    reservations.forEach((reservation) => {
      if (reservation.highlight) {
        this.rangeSelect = true;
        this.selectedDate(format(reservation.start, 'YYYY-MM-DD'));
        this.selectedDate(format(reservation.end, 'YYYY-MM-DD'));
        return;
      }

      const daysInReservation = eachDay(reservation.start, reservation.end).map((date) => format(date, 'YYYY-MM-DD'));

      daysInReservation.forEach((day, index) => {
        if (index === 0) {
          // Is there already a checkout on this day, if so its a changeover
          if (blockedDates[day] === 'checkout') {
            blockedDates[day] = `changeover`;
          } else {
            // We have a checkin on day 0
            blockedDates[day] = `checkin`;
          }

          return;
        }

        if (index === daysInReservation.length - 1) {
          // We have a checkout on the final day
          blockedDates[day] = `checkout`;
          return;
        }

        blockedDates[day] = `staying`;
      });
    });

    this.blockedDates = blockedDates;
  }

  public changeMonth(monthsToAdvance: number) {
    if (monthsToAdvance === 0 || this.disabled) {
      return;
    }

    this.startDate = format(addMonths(this.startDate, monthsToAdvance), 'YYYY-MM-DD');

    this.dates = this.buildDates();
  }

  private isTryingToSelectUnavailableDays(startDate: string, endDate: string): boolean {
    if (!this.blockedDates || this.blockedDates.length === 0) {
      return;
    }

    const days = eachDay(startDate, endDate).map((date) => format(date, 'YYYY-MM-DD'));

    // Remove the start date from our checks. It's possible the checkin is on another checkout, and this is allowed. We don't need to check that first date
    days.shift();

    let conflict = false;

    days.forEach((day) => {
      const dayStatus = this.blockedDates[day];
      // We allow your end date to be someone elses check in, but nothing else
      if (dayStatus === 'checkout' || dayStatus === 'staying' || dayStatus === 'changeover') {
        conflict = true;
      }
    });

    return conflict;
  }

  clickedDate(date: string) {
    if (!date || date === undefined || this.disabled) {
      return;
    }

    this.dateClicked.emit(date);
    this.selectedDate(date);
  }

  public selectedDate(date: string) {
    if (!date || date === undefined || this.disabled) {
      return;
    }

    // Range select is off, just emit
    if (!this.rangeSelect) {
      this.selectedFirstDateInRange = date;
      this.datesSelected.emit(this.selectedFirstDateInRange);
      return;
    }

    // Set the first date as clicked, clear the second
    if (!this.selectedFirstDateInRange || this.selectedSecondDateInRange) {
      if (this.blockedDates[date] === 'checkin') {
        // We already have a checkin on this day, do nothing
        return;
      }

      this.selectedFirstDateInRange = date;
      this.selectedSecondDateInRange = null;
      this.selectedDates = [];
      this.hoveredDates = [];
      return;
    }

    // Second click on checkout date
    if (
      isAfter(date, this.selectedFirstDateInRange) ||
      (this.allowBothRangeAndSingle && isEqual(date, this.selectedFirstDateInRange))
    ) {
      // First check to make sure we're not encroaching on any unavailable days
      if (this.isTryingToSelectUnavailableDays(this.selectedFirstDateInRange, date)) {
        // If yes, let's just reset their first selected date to the end date
        this.selectedFirstDateInRange = date;
        this.hoveredDates = [];
        return;
      }

      this.selectedSecondDateInRange = date;

      const selectedDates = eachDay(this.selectedFirstDateInRange, this.selectedSecondDateInRange);

      this.selectedDates = selectedDates.map((date) => {
        return format(date, 'YYYY-MM-DD');
      });

      this.datesSelected.emit([this.selectedFirstDateInRange, this.selectedSecondDateInRange]);
    } else {
      this.selectedFirstDateInRange = date;
    }
  }

  public hoveredDate(date: string) {
    if (!this.rangeSelect || !this.selectedFirstDateInRange) {
      return;
    }

    let hoveredDates = [];

    if (isBefore(this.selectedFirstDateInRange, date)) {
      hoveredDates = eachDay(this.selectedFirstDateInRange, date);
    }

    this.hoveredDates = hoveredDates.map((date) => {
      return format(date, 'YYYY-MM-DD');
    });
  }

  private buildDates(): string[][] {
    const months = [];

    for (let index = 0; index < this.numMonths; index++) {
      const month = addMonths(this.startDate, index);

      // Since we use weeks, we need some dates before this month, and some after
      const firstDayWeNeed = format(
        startOfWeek(startOfMonth(month), {
          weekStartsOn: this.startOfWeekPref,
        }),
        'YYYY-MM-DD'
      );
      const lastDayWeNeed = format(endOfWeek(endOfMonth(month), { weekStartsOn: this.startOfWeekPref }), 'YYYY-MM-DD');

      // Generate our list of dates, formatted to 'YYYY-MM-DD' for each
      const daysInMonth: any = eachDay(firstDayWeNeed, lastDayWeNeed);

      daysInMonth.forEach((day, index) => {
        // format each day as YYYY-MM-DD
        daysInMonth[index] = format(day, 'YYYY-MM-DD');

        // null out the outlier dates that don't belong in the month, we just use these for blank spacers
        if (!isSameMonth(month, day)) {
          daysInMonth[index] = null;
        }
      });

      months.push(daysInMonth);
    }

    return months;
  }
}
