import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { SubNavItem } from '@app/shared/models/sub-nav-item';

@Component({
  standalone: false,
  selector: 'sbnb-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss'],
})
export class SubnavComponent implements OnInit {
  @Input() header: string;
  @Input() settingsLink: string;
  @Input() items: SubNavItem[];
  @Input() badge: string | null = null;
  @Output() itemClicked = new EventEmitter<any>();

  @HostBinding('class') class = 'hidden lg:flex max-w-[280px] min-w-[280px]';

  constructor() { }

  ngOnInit() { }
}
