import { Pipe, PipeTransform } from '@angular/core';
import { format, isSameDay } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isSameDayBooking',
})
export class IsSameDayBookingPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const formattedCheckIn = format(value.checkinDate, 'YYYY-MM-DD');
    const formattedCreatedAt = format(value.createdAt, 'YYYY-MM-DD');

    if (isSameDay(formattedCheckIn, formattedCreatedAt)) {
      return true;
    } else {
      return false;
    }
  }
}
