import { Component, Input, OnInit } from '@angular/core';

export enum PageLevelCtaActionColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Text = 'text',
}

export enum PageLevelCtaContext {
  Positive = 'positive',
  Neutral = 'neutral',
}

export interface PageLevelCta {
  title: string;
  context: PageLevelCtaContext;
  description: string;
  actions: {
    text: string;
    color: PageLevelCtaActionColor;
    url?: string;
    callbackFn?: () => void;
  }[];
}

@Component({
  standalone: false,
  selector: 'sbnb-page-level-cta',
  templateUrl: './page-level-cta.component.html',
  styleUrls: ['./page-level-cta.component.scss'],
})
export class PageLevelCtaComponent implements OnInit {
  @Input() cta: PageLevelCta;

  PageLevelCtaActionColor = PageLevelCtaActionColor;
  PageLevelCtaContext = PageLevelCtaContext;
  constructor() { }

  ngOnInit() { }

  callback(action) {
    action?.callbackFn();
  }
}
