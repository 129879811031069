import { AfterViewInit, Component, inject, signal, viewChildren } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatTab } from '@angular/material/tabs';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { Reservation } from '@app/shared/interfaces';
import { GuestPortalTaskMetadata } from '@app/shared/interfaces/lib/guest-verification.interface';
import { GuestPortalService } from '@app/shared/services/guest-portal/guest-portal.service';

@Component({
  templateUrl: './dialog-guest-portal-tasks.component.html',
})
export class DialogGuestPortalTasksComponent implements AfterViewInit {
  Features = Features;

  private readonly dialogRef = inject(MatDialogRef);
  private readonly GuestPortalService = inject(GuestPortalService);
  public readonly userPermissionsService = inject(UserPermissionsService);

  tabs = viewChildren<MatTab>('tab');

  selectedIndex = signal(0);

  data: {
    initialTab: GuestPortalTaskMetadata['type'];
    reservation: Reservation;
    propertyId: number;
  } = inject(MAT_DIALOG_DATA);

  ngAfterViewInit() {
    this.setDefaultTab();
  }

  setDefaultTab(): void {
    let index = 0;

    const initialTabLabel = this.GuestPortalService.taskMetadata[this.data.initialTab].label;

    // find the tab that has a data-type that matches the initialTab belonging to tabgroup
    this.tabs().forEach((tab, i) => {
      const tabLabel = tab.textLabel;

      if (tabLabel === initialTabLabel) {
        index = i;
      }
    });

    this.selectedIndex.set(index);
  }

  close() {
    this.dialogRef.close();
  }
}
