import { Component, Input } from '@angular/core';
import { LoadingSize, LoadingType } from './loading.component.interface';

@Component({
  selector: 'hospitable-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingMonoComponent {
  @Input() type: LoadingType = LoadingType.Spinner;
  @Input() size: LoadingSize = LoadingSize.Medium;
  @Input() label: string;
}
