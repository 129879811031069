import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { PropertiesService } from '@app/shared/services/properties/properties.service';

@Component({
  standalone: false,
  selector: 'sbnb-property-selection',
  templateUrl: './property-selection.component.html',
  styleUrls: ['./property-selection.component.scss'],
})
export class PropertySelectionComponent implements OnInit, OnChanges {
  @Input() selectedProperties: any[] = [];
  @Input() allProperties: boolean;
  @Input() allPropertiesEnabled = true;
  @Input() border = false;
  @Input() listHeight: number;
  @Input() hideChildProperties = false;
  @Input() disabled = false; // puts the component into read only mode
  @Input() disabledProperties: { id: number; reason: string }[] = [];
  @Input() title = 'Properties';

  @Output() propertiesChange = new EventEmitter<{
    all_properties: boolean;
    properties: number[];
  }>();

  @Output() newPropertyChecked = new EventEmitter<{
    property: number;
  }>();

  loading: boolean;
  possibleProperties: any[];

  constructor(private propertyService: PropertiesService) {}

  ngOnInit() {
    this.getPossiblePropertiesFromApi();
  }

  ngOnChanges() {
    this.sortProperties();
  }

  getPossiblePropertiesFromApi() {
    this.loading = true;

    this.propertyService
      .getProperties({
        filterCriteria: [],
        paginate: false,
        transformer: 'simple',
        includes: this.hideChildProperties ? ['children'] : [],
      })
      .subscribe((res) => {
        this.loading = false;
        this.possibleProperties = res.data;

        // Sort properties
        this.sortProperties();

        this.setPropertiesIfAllIsSet();
      });
  }

  sortProperties() {
    // Sort the possibleProperties
    if (!this.possibleProperties) {
      return;
    }

    this.possibleProperties.sort((a, b) => {
      const aDisabled = this.isPropertyDisabled(a.id);
      const bDisabled = this.isPropertyDisabled(b.id);

      if (aDisabled && !bDisabled) {
        return 1; // a comes after b
      }

      if (!aDisabled && bDisabled) {
        return -1; // a comes before b
      }

      return 0; // a and b are equal (either both enabled or both disabled)
    });
  }

  setPropertiesIfAllIsSet() {
    if (this.allProperties) {
      this.selectedProperties = this.possibleProperties
        ?.filter((x) => !this.isPropertyDisabled(x.id)) // filter out disabled properties
        .map((x) => x.id);
      this.emitChanges();
    }
  }

  emitChanges() {
    this.propertiesChange.emit({
      all_properties: this.allProperties,
      properties: this.selectedProperties,
    });
  }

  emitNewPropertyChecked(property) {
    this.newPropertyChecked.emit({
      property: property,
    });
  }

  propertyCheckboxChange(property, event: MatCheckboxChange) {
    if (event.checked) {
      if (this.selectedProperties?.indexOf(property.id) === -1) {
        this.selectedProperties?.push(property.id);
        this.emitNewPropertyChecked(property.id);
      }
    } else {
      this.allProperties = false;
      const index = this.selectedProperties?.findIndex((r) => r === property.id);

      if (index !== -1) {
        this.selectedProperties?.splice(index, 1);
      }
    }
    this.emitChanges();
  }

  hostsSearchUpdated(search: string) {
    this.possibleProperties.forEach((pp, index) => {
      if (pp.name.toLowerCase().includes(search.toLowerCase())) {
        this.possibleProperties[index].hidden = false;
      } else {
        this.possibleProperties[index].hidden = true;
      }
    });
  }

  clearProperties() {
    this.allProperties = false;
    this.selectedProperties = [];
    this.emitChanges();
  }

  isPropertyDisabled(propertyId: number): boolean {
    return this.disabledProperties.some((prop) => prop.id === propertyId);
  }

  getDisabledPropertyReason(propertyId: number): string {
    const disabledProperty = this.disabledProperties.find((prop) => prop.id === propertyId);
    return disabledProperty ? disabledProperty.reason : null;
  }
}
