<div class="image-carousel-container">
  <div
    *ngFor="let image of images; let i = index"
    class="image-container fade"
    [class.active-img]="i === activeImageIndex">
    <div class="numbertext">{{ activeImageIndex + 1 }} / {{ images.length }}</div>
    <img [src]="image" style="width: 100%" (mouseenter)="stopAutoCycle()" (mouseleave)="startAutoCycle()" />
  </div>

  <!-- Next and previous buttons -->
  <a class="prev" (click)="goToPreviousImage()">&#10094;</a>
  <a class="next" (click)="goToNextImage()">&#10095;</a>
</div>

<div class="dots">
  <span
    *ngFor="let image of images; let i = index"
    class="dot"
    [class.active]="i === activeImageIndex"
    (click)="goToImage(i)"></span>
</div>
