import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogRequestAdhocGuestVettingComponent } from '@app/shared/components/dialog-request-adhoc-guest-vetting/dialog-request-adhoc-guest-vetting.component';
import { AutohostBasicDetails, Reservation } from '@app/shared/interfaces';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { GuestVettingService, Mode } from '@app/shared/services/guest-vetting/guest-vetting.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';

enum DisplayStatus {
  approved = 'Approved',
  declined = 'Declined',
  requested = 'Requested',
  not_requested = 'Not requested',
  pending = 'pending',
  verified = 'verified',
}

@Component({
  standalone: false,
  selector: 'sbnb-guest-vetting-status',
  templateUrl: './guest-vetting-status.component.html',
  styleUrls: ['./guest-vetting-status.component.scss'],
})
export class GuestVettingStatusComponent {
  public ModeEnum = Mode;
  public DisplayStatusEnum = DisplayStatus;
  public isMobile: boolean;
  public vettingData$ = this.selectedReservationService.activeReservation$.pipe(
    filter((reservation) => !!reservation.uuid),
    switchMap((reservation) =>
      this.guestVettingService
        .fetchBasicAutohostDataForReservation(reservation.uuid)
        .pipe(map((autohost) => [reservation, autohost]))
    ),
    map(([reservation, autohost]: [Reservation, AutohostBasicDetails]) => ({
      reservation,
      canRequestAdhocVerification: reservation.supports.ad_hoc_verification,
      autohost,
      hasFreeCheck: autohost.is_free,
      mode: this.guestVettingService.determineMode(autohost, reservation),
      displayStatus: this.getDisplayStatus(autohost)
    })),
    tap((data) => {
      if (data.mode === Mode.adhoc) {
        this.segment.track(SegmentEvent.GuestVerificationAdHocAvailable, {});
      }
    })
  );

  constructor(
    private dialog: MatDialog,
    private segment: SegmentIoService,
    private deviceDetectionService: DeviceDetectionService,
    private selectedReservationService: SelectedReservationService,
    private guestVettingService: GuestVettingService
  ) {
    this.deviceDetectionService.isMobileViewport().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  getDisplayStatus(autohost: AutohostBasicDetails): DisplayStatus {
    if (autohost?.status === null) {
      return autohost?.id ? DisplayStatus.requested : DisplayStatus.not_requested;
    }

    return autohost.supervised ? this.mapSupervisedStatus(autohost.supervised) : (autohost.status as any);
  }

  private mapSupervisedStatus(supervisedStatus: string): DisplayStatus {
    switch (supervisedStatus) {
      case 'approve':
        return DisplayStatus.approved;
      case 'decline':
        return DisplayStatus.declined;
    }
  }

  onRequestVerificationClicked({ guestName, reservationUuid }: { guestName: string; reservationUuid: string }) {
    this.segment.track(SegmentEvent.GuestVerificationFlowStarted, {});

    const dialogRef = this.dialog.open(DialogRequestAdhocGuestVettingComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
      data: {
        guestName,
        reservationUuid,
      },
    });

    dialogRef.afterClosed().subscribe((refresh) => {
      if (refresh) {
        // what actually changes here?
        // There may be a delay before we are pending, since autohost is asynchronous webhooks passing through multiple of our microservices
        // the nuclear approach is to refresh the page
        // but even with that - we might not have the new status yet from autohost
        // TODO: revisit
        window.location.reload();
      }
    });
  }

  public openVettingDetails({ reservation, autohostBasicDetails }) {
    this.guestVettingService.openVettingDetails({ reservation, autohostBasicDetails });
  }
}
