import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { config } from '@app/core/app-config';
import { NotificationType, Reservation } from '@app/shared/interfaces';
import { GuestVettingData } from '@app/shared/interfaces/lib/guestvetting.interface';
import { GuestVettingService } from '@app/shared/services/guest-vetting/guest-vetting.service';
import { AutohostSdkToken, ReservationService } from '@app/shared/services/reservation/reservation.service';
import { ScriptLoaderService } from '@app/shared/services/script-loader/script-loader.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { ThreadService } from '@app/shared/services/thread/thread.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

declare const AutohostSDK: any;

@Component({
  standalone: false,
  selector: 'sbnb-dialog-guest-vetting-extended-details',
  templateUrl: './dialog-guest-vetting-extended-details.component.html',
  styleUrls: ['./dialog-guest-vetting-extended-details.component.scss'],
})
export class DialogGuestVettingExtendedDetailsComponent implements OnInit {
  config = config;
  loading = false;
  loadingCheckin = false; // in the process of allowing a check-in
  autohostToken = '';
  error: string | null = null;

  isViewingConfirmationMessageToAllowCheckIn = false;

  guestVettingData$: Observable<GuestVettingData>;

  constructor(
    public dialogRef: MatDialogRef<DialogGuestVettingExtendedDetailsComponent>,
    private scriptLoader: ScriptLoaderService,
    private reservationService: ReservationService,
    private threadService: ThreadService,
    private toastService: ToastNotificationsService,
    private guestVettingService: GuestVettingService,
    private segmentIoService: SegmentIoService,

    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      reservationUuid: Reservation['uuid'];
      source: string;
    }
  ) { }

  ngOnInit(): void {
    if (!this.dialogData?.reservationUuid) {
      console.error('Opened the Guest vetting dialog without a valid reservation');
      this.toastService.open('Failed to load guest vetting data.', 'Dismiss', NotificationType.Error);
      this.dialogRef.close();
      return;
    }

    this.loading = true;
    this.loadGuestVettingData();
    this.loadAutohostSDK();
  }

  loadGuestVettingData() {
    this.guestVettingData$ = this.threadService.getGuestVettingData(this.dialogData.reservationUuid).pipe(
      tap(() => (this.loading = false)),
      catchError(() => {
        this.loading = false;
        this.error = 'Failed to load guest vetting data. Please try again later.';
        return of(null);
      })
    );
  }

  loadAutohostSDK(): void {
    this.scriptLoader
      .load({
        name: 'Autohost SDK',
        src: environment.autohostSdk.scriptSrc,
      })
      .subscribe(() => {
        this.reservationService.getAutohostSdkToken(this.dialogData.reservationUuid).subscribe(
          (token: AutohostSdkToken) => {
            this.loading = false;
            this.autohostToken = token.token;

            AutohostSDK.init({
              sandbox: environment.autohostSdk.sandbox,
              reservationId: token.autohost_reservation_id,
            }).then((client) => {
              const options = {
                reservationId: token.autohost_reservation_id,
                apiToken: this.autohostToken,
                styles: {
                  card: { margin: '0px', 'margin-bottom': '12px', 'box-shadow': 'none', display: 'none' },
                  header: { padding: '0px', 'margin-bottom': '12px', 'font-size': '18px', color: 'rgb(35, 11, 65)' },
                  'MuiCardContent-root': { padding: '0px' },
                  'definition-list__item-bookingSource': { display: 'none' },
                  'definition-list__item-confirmationCode': { display: 'none' },
                  'card_top-verifications': { display: 'none' },
                  'card_id-verification-document': { display: 'block' },
                  'id-image_container_back': { display: 'none' },
                  'card_top-concerns': { display: 'none' },
                  'card_top-recommendations': { display: 'none' },
                },
              };

              client.component('ReservationResults', options).mount('#autohost-sdk');
            });
          },
          (err) => {
            console.error('Failed to get Autohost SDK token', err);
            this.loading = false;
            this.error = 'Failed to load guest vetting data. Please try again later.';
          }
        );
      });
  }

  allowCheckInForAdhoc() {
    this.loadingCheckin = true;
    this.segmentIoService.track(SegmentEvent.GuestVerificationAllowCheckIn, {});
    this.guestVettingService.allowCheckInForAdhoc(this.dialogData.reservationUuid).subscribe(
      (res) => {
        if (res) {
          this.dialogRef.close();
          this.toastService.open('Check-in allowed for this guest.', 'Dismiss', NotificationType.Success);
          window.location.reload();
          this.loadingCheckin = false;
        } else {
          this.loadingCheckin = false;
        }
      },
      (err) => {
        this.loadingCheckin = false;
      }
    );
  }

  public ignoreWarning() {
    this.segmentIoService.track(SegmentEvent.GuestVerificationWarningIgnored, {});
    this.isViewingConfirmationMessageToAllowCheckIn = true;
  }

  public restrictCheckIn() {
    this.segmentIoService.track(SegmentEvent.GuestVerificationCheckInRestricted, {});
    this.isViewingConfirmationMessageToAllowCheckIn = false;
  }
}
