import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogEditAiReviewComponent } from '@app/shared/components/dialog-edit-ai-review/dialog-edit-ai-review.component';
import { DialogEditMessageComponent } from '@app/shared/components/dialog-edit-message/dialog-edit-message.component';
import { DialogEditReviewComponent } from '@app/shared/components/dialog-edit-review/dialog-edit-review.component';
import { NotificationType } from '@app/shared/interfaces';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { MessageSendReview, ThreadService } from '@app/shared/services/thread/thread.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { isBefore } from 'date-fns';

@Component({
  standalone: false,
  selector: 'sbnb-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss'],
  providers: [DatePipe],
})
export class ActivityFeedComponent implements OnChanges {
  activity: any[] = [];
  expanded = true;
  loading: boolean;
  now = new Date();

  public isMobile$ = this.deviceDetectionService.isMobileViewport();

  @Input() threadUuid: string;
  @Input() lite = false;
  @Input() openInboxReviewModal = false;

  constructor(
    private threadService: ThreadService,
    private toast: ToastNotificationsService,
    private dialog: MatDialog,
    private deviceDetectionService: DeviceDetectionService
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.threadUuid) {
      this.getActivityItems();
    }
  }

  isOldEntry(date) {
    return isBefore(date, this.now);
  }

  eventTooltip(event) {
    if (event.status) {
      // Activity item
      return event.status + ' at ' + event.sort_time_label;
    } else {
      if (event.sent_at) {
        return `${event.review_id ? 'Review' : 'Message'} sent at ${event.sent_at_label}`;
      }
      if (event.cancelled_at) {
        return `${event.review_id ? 'Review' : 'Message'} not sent as scheduled because it was cancelled at ${
          event.cancelled_at_label
        }`;
      }
      if (event.scheduled_for) {
        return `${event.review_id ? 'Review' : 'Message'} scheduled to be sent at ${event.scheduled_for_label}`;
      }
    }
  }

  getActivityItems() {
    if (!this.threadUuid) return;

    this.loading = true;

    this.threadService.getActivity(this.threadUuid).subscribe((res) => {
      if (res.error) {
        this.toast.open(`Cannot load activity feed`, 'Dismiss', NotificationType.Error);

        this.loading = false;
        return;
      }

      this.activity = res;
      this.loading = false;
    });
  }

  openEditMessageDialog(messageId: string, type: string) {
    if (type !== 'review') {
      const dialogRef = this.dialog.open(DialogEditMessageComponent, {
        width: '720px',
        panelClass: 'dialog__no-padding',
        maxHeight: '90vh',
        data: {
          messageId,
          messageType: type,
        },
      });

      dialogRef.afterClosed().subscribe((result: { status: 'deleted' | 'sent' }) => {
        // Check if they just saved, sent now, or deleted and update the list accordingly
        if (!result?.status) {
          return;
        }
        if (result.status === 'deleted') {
          const index = this.activity.findIndex((x) => x.randomkey === messageId);
          if (index > -1) {
            this.activity[index].cancelled_at = new Date();
            this.activity[index]._links.update = null;
            this.activity[index]._links.cancel = null;
          }
        }

        if (result.status === 'sent') {
          const index = this.activity.findIndex((x) => x.randomkey === messageId);

          if (index > -1) {
            this.activity[index].sent_at = new Date();
            this.activity[index]._links.update = null;
            this.activity[index]._links.cancel = null;
          }
        }
      });
    } else {
      let dialogRef;
      if (this.openInboxReviewModal) {
        dialogRef = this.dialog.open(DialogEditAiReviewComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          data: {
            reviewId: messageId,
          },
        });
      } else {
        dialogRef = this.dialog.open(DialogEditReviewComponent, {
          width: '720px',
          maxHeight: '90vh',
          panelClass: 'dialog__no-padding',
          data: {
            reviewId: messageId,
          },
        });
      }

      dialogRef.afterClosed().subscribe((result: Partial<MessageSendReview> | { cancelled_at: Date }) => {
        // Check if they just saved, sent now, or deleted and update the list accordingly
        if ((result as { cancelled_at: Date })?.cancelled_at) {
          const index = this.activity.findIndex((x) => x.review_id === messageId);

          if (index > -1) {
            this.activity[index].cancelled_at = new Date();
          }
        }

        if ((result as Partial<MessageSendReview>)?.submitted) {
          const index = this.activity.findIndex((x) => x.review_id === messageId);

          if (index > -1) {
            this.activity[index].sent_at = new Date();
          }
        }
      });
    }
  }

  cancelActivityItem(event) {
    this.threadService.cancelActivityItem(event.key).subscribe((res) => {
      if (res.success) {
        event.has_been_cancelled = true;
      } else {
        this.toast.open(`Error cancelling scheduled activity`, 'Dismiss', NotificationType.Error);
      }
    });
  }
}
