import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  standalone: false,
  selector: '[scrollNearEnd]',
})
export class ScrollNearEndDirective {
  @Output() scrolledToBottom: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  @HostListener('scroll', ['$event.target'])
  onScroll(target: HTMLElement) {
    const isAtBottom = target.scrollHeight - target.scrollTop === target.clientHeight;
    if (isAtBottom) {
      this.scrolledToBottom.emit(true);
    }
  }
}
