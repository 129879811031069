import { Pipe, PipeTransform } from '@angular/core';
import { MessageReservation, ThreadMessage } from '@app/shared/services/thread/thread.service';

@Pipe({
  standalone: false,
  name: 'isReservationMessage',
})
export class IsReservationMessagePipe implements PipeTransform {
  transform(value: ThreadMessage): value is MessageReservation {
    return value.entity_type === 'reservation';
  }
}
