import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
@Component({
  standalone: false,
  selector: 'sbnb-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss'],
  host: {
    '(document:click)': 'clickOutside($event)',
    '(document:touchend)': 'clickOutside($event)',
  },
})
export class MobileSidebarComponent implements OnInit {
  @Input('title') title: string;
  @Input('reducedPadding') reducedPadding: boolean;
  @Input('top') top: number;
  @Input() navTabsHide: boolean = false;
  @ViewChild('sidebar', { static: false }) sidebar: ElementRef;
  @ViewChild('button', { static: false }) button: ElementRef;

  mobileSegments: boolean;
  mobileMenuVisible: boolean;
  featureFlagMobileNavTabsEnabled: boolean = false;
  constructor(private posthog: PosthogService) {}

  ngOnInit() {
    this.posthog.mobileNavTabsEnabled$.pipe(take(1)).subscribe((enabled) => {
      this.featureFlagMobileNavTabsEnabled = !!enabled;
    });
  }

  clickOutside(event: MouseEvent): void {
    if (!this.sidebar?.nativeElement) return;
    if (
      !this.sidebar.nativeElement.contains(event.target) &&
      !this.button.nativeElement.contains(event.target) &&
      this.mobileSegments
    ) {
      // Close the expanded sidebr
      this.mobileSegments = false;
    }
  }
}
