import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GuestVettingData, VerificationStatus } from '@app/shared/interfaces/lib/guestvetting.interface';
import { MessageReservation } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-pending-quote',
  templateUrl: './pending-quote.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingQuoteComponent implements OnInit {
  @Input() message: MessageReservation;
  @Input() guestVetting: GuestVettingData;
  @Input() rejectLoading: boolean;
  @Output() reject = new EventEmitter();

  VerificationStatus = VerificationStatus;

  startDate: string;
  endDate: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.message) return;
    this.startDate = this.message.start_date?.split('T')[0];
    this.endDate = this.message.end_date?.split('T')[0];
  }
}
