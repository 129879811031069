import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/utils';

@Component({
  standalone: false,
  selector: 'sbnb-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input('position') position: 'start' | 'center' | 'end' = 'end';
  @Input('pageCount') pageCount: number;
  @Input('currentPage') currentPage: number;

  @Output('pageChanged') pageChanged = new EventEmitter<number>();

  /* cursor pagination */
  @Input() next: string | null = null;
  @Input() previous: string | null = null;
  @Input() hasMore?: boolean;
  @Output() cursorChanged = new EventEmitter<string>();

  public pages: number[];

  constructor() {}

  ngOnInit() {
    if (isNullOrUndefined(this.hasMore)) {
      this.pages = Array.from(new Array(this.pageCount), (val, index) => index + 1);

      if (this.currentPage < 1 || this.currentPage > this.pages.length) {
        throw Error(`You entered an invalid current page: Page ${this.currentPage} of ${this.pages.length}`);
      }
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  public changePageEmitter(page: number | '+' | '-') {
    let beforeChangeCurrentPage = this.currentPage;

    if (typeof page === 'number') {
      this.currentPage = page;
    }

    if (page === '+' && this.currentPage < this.pages.length) {
      this.currentPage++;
    }

    if (page === '-' && this.currentPage > 1) {
      this.currentPage--;
    }

    let pageChanged = this.currentPage !== beforeChangeCurrentPage;

    if (pageChanged) {
      this.pageChanged.emit(this.currentPage);
    }
  }

  isCursorPagination() {
    return this.next || this.previous;
  }

  /* cursor methods */
  onCursorNext(): void {
    if (this.next) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.cursorChanged.emit(this.next);
    }
  }

  onCursorPrevious(): void {
    if (this.previous) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.cursorChanged.emit(this.previous);
    }
  }
}
