<sbnb-message
  *ngIf="state$ | async as message"
  type="system"
  senderImg="assets/iconography/smartbnb-avatar.svg"
  senderName="Hospitable"
  [timestamp]="message.submitted ? message.scheduled_for_label : null">
  <sbnb-collapsible-card [showDivider]="false" [defaultOpen]="!message.submitted" spacing="12">
    <!-- Title -->
    <div title class="flex justify-between w-full items-center flex-wrap">
      <span class="strong">
        {{ message.submitted ? 'Public review left for' : 'Leave a public review for' }} {{ message.guest?.name }}
      </span>
      <!-- need to include the link to the log -->
      <span *ngIf="!message.submitted" class="muted">
        {{
          message.scheduled_for
            ? 'Scheduled to publish in ' + (message.scheduled_for | timeAgo: false)
            : message.expires_at
              ? 'Can be published within ' + (message.expires_at | timeAgo: false)
              : 'Not scheduled to publish'
        }}
      </span>
    </div>
    <!-- Content -->
    <ng-template sbnbCollapsibleCardContent>
      <div class="flex flex-col" style="gap: 24px">
        <div
          [matTooltip]="message.submitted ? 'View in log' : 'Edit review'"
          class="line-clamp-3 clickable"
          (click)="!message.submitted ? openEditReviewDialog() : routeToReviewLog()">
          {{ message.review }}
        </div>

        <div class="flex justify-between flex-wrap" style="gap: 6px">
          <!-- This is pretty ugly on mobile. How should it look? -->
          <div *ngIf="message.rating" class="rating-summary muted flex flex-wrap" style="gap: 6px">
            <sbnb-review-scores-summary [messageScores]="message.scores"></sbnb-review-scores-summary>

            <span>&#x2022;</span>
            <span *ngIf="message?.scores">
              {{ message.scores.recommend ? 'Recommending to other hosts' : 'Not recommending to other hosts' }}
            </span>
            <span class="dot-divider">&#x2022;</span>
            <span class="mobile-full-width">
              {{ message.private_feedback ? 'Includes private feedback' : 'No private feedback' }}
            </span>
          </div>
          <sbnb-button *ngIf="!message.submitted" size="mini" type="secondary" (click)="openEditReviewDialog()">
            <div class="flex items-center" style="gap: 4px">
              <span>Edit</span>
              <img
                class="chatgpt-button--icon"
                src="/assets/iconography/magic.svg"
                onload="SVGInject(this)"
                alt="Generate a reply with AI" />
            </div>
          </sbnb-button>
        </div>
      </div>
    </ng-template>
  </sbnb-collapsible-card>
</sbnb-message>
