import { BreakpointObserver } from '@angular/cdk/layout';
import { KeyValue, getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NotificationType } from '@app/shared/interfaces';
import { NumNightsPipe } from '@app/shared/pipes/num-nights/num-nights.pipe';
import {
  DirectCustomQuoteEstimatePricingPayload,
  DirectFee,
  DirectQuotesService,
  NewDirectCustomQuote,
} from '@app/shared/services/direct-quotes/direct-quotes.service';
import { SimpleProperty } from '@app/shared/services/properties/properties.service';
import { ReservationService } from '@app/shared/services/reservation/reservation.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { ConvertEnumToArray, isNullUndefinedOrEmptyString } from '@app/shared/utils';
import { addDays, subDays } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-direct-quote-set-amount',
  templateUrl: './direct-quote-set-amount.component.html',
  styleUrls: ['./direct-quote-set-amount.component.scss'],
})
export class DirectQuoteSetAmountComponent implements OnInit {
  @Input() quote: NewDirectCustomQuote;
  @Input() properties: SimpleProperty[];
  @Output() next = new EventEmitter<NewDirectCustomQuote>();
  @Output() goBack = new EventEmitter<any>();

  isMobile$ = this.breakpointObserver.observe(['(max-width: 960px)']).pipe(map((res) => res.matches));
  localQuote: NewDirectCustomQuote;

  DirectFee = DirectFee;
  getCurrencySymbol = getCurrencySymbol;
  isNullUndefinedOrEmptyString = isNullUndefinedOrEmptyString;

  feeOptions = ConvertEnumToArray<DirectFee[]>(DirectFee);
  filteredFeeOptions$: Observable<string[]>;
  defaultCurrency = 'USD';
  newFee = new UntypedFormControl('');
  newFeeAmount: number;
  priceChanged = new Subject<string>();
  isEstimatingPricing = false;

  reservations: { start: string; end: string }[];
  showAmountTable = false;

  constructor(
    private directQuotesService: DirectQuotesService,
    private breakpointObserver: BreakpointObserver,
    private reservationsService: ReservationService,
    private numNightsPipe: NumNightsPipe,
    private toast: ToastNotificationsService
  ) { }

  ngOnInit(): void {
    this.reservations = [];
    this.priceChanged.pipe(debounceTime(3000)).subscribe(() => {
      this.onRecalculate();
    });
    this.onRecalculate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['quote']) {
      this.localQuote = cloneDeep(changes['quote'].currentValue);
      this.updateCurrencyUsingPropertyCurrency();
    }
  }

  onSelectedPropertyChanged(propertyId) {
    if (propertyId) {
      this.localQuote.property_id = propertyId;
      this.fetchReservations();
      this.onRecalculate();
      this.updateCurrencyUsingPropertyCurrency();
    }
  }

  updateCurrencyUsingPropertyCurrency() {
    if (this.localQuote.property_id && this.properties && this.properties.length > 0) {
      const property = this.properties.find((property) => property.id === parseInt(this.localQuote.property_id));

      if (property && property.currency) {
        this.localQuote.currency = property.currency;
      }
    }
  }

  canShowAmountTable() {
    const showAmountTable =
      !isNullUndefinedOrEmptyString(this.localQuote.property_id) &&
        !isNullUndefinedOrEmptyString(this.localQuote.checkin) &&
        !isNullUndefinedOrEmptyString(this.localQuote.checkout) &&
        this.localQuote.computed.pricing
        ? true
        : false;

    this.showAmountTable = showAmountTable;
    return showAmountTable;
  }

  getPropertyName(id) {
    if (this.properties && this.properties.length > 0) {
      return this.properties.find((property) => property.id === id);
    }
  }

  fetchReservations() {
    const propertyId = this.localQuote.property_id;

    this.reservationsService
      .getReservationsByDate(subDays(new Date(), 90), addDays(new Date(), 1000), [propertyId], false, true)
      .subscribe((res) => {
        this.reservations = res.map((item) => {
          return { start: item.checkin, end: item.checkout };
        });
      });
  }

  datesSelected(dates: string[]) {
    this.localQuote.checkin = dates[0];
    this.localQuote.checkout = dates[1];
    this.onRecalculate();
  }

  priceModelChange() {
    this.isEstimatingPricing = true;
    this.priceChanged.next(null);
  }

  onRecalculate() {
    if (
      !isNullUndefinedOrEmptyString(this.localQuote.property_id) &&
      !isNullUndefinedOrEmptyString(this.localQuote.checkin) &&
      !isNullUndefinedOrEmptyString(this.localQuote.checkout)
    ) {
      this.isEstimatingPricing = true;
      const estimatePricingPayload = this.getDirectCustomQuoteEstimatePricingPayload();

      this.directQuotesService.customQuoteEstimatePricing(estimatePricingPayload).subscribe(
        (res) => {
          if (res && res.original && res.data && res.data.total) {
            this.localQuote.computed = {
              ...this.localQuote.computed,
              ...{
                pricing: {
                  ...this.localQuote.computed.pricing,
                  original: res.original,
                  estimated: res.data,
                },
              },
            };
            this.localQuote.warnings = res.warnings ? res.warnings : [];
            this.localQuote.has_calendar_blocks = res.has_calendar_blocks ? res.has_calendar_blocks : false;
            this.isEstimatingPricing = false;
          }
        },
        (err) => {
          this.isEstimatingPricing = false;
          this.toast.open(
            err.error && err.error.error ? err.error.error : 'We are unable to estimate pricing at this time.',
            'Dismiss',
            NotificationType.Error
          );
        }
      );
    }
  }

  getDirectCustomQuoteEstimatePricingPayload(): DirectCustomQuoteEstimatePricingPayload {
    const { checkin, checkout, guest_details, num_guests, property_id } = this.localQuote;
    const estimatedPricing = this.localQuote?.computed?.pricing?.estimated;

    const nightly_total = estimatedPricing?.nightly?.value ?? null;
    const customFees = this.localQuote?.computed?.pricing?.customFees ?? {};
    let fees = estimatedPricing?.fees ?? {};
    fees = { ...fees, ...customFees };

    const feesWithoutEmptyStrings = {};
    Object.keys(fees).forEach((key) => {
      if (!isNullUndefinedOrEmptyString(fees[key].value)) {
        feesWithoutEmptyStrings[key] = fees[key].value;
      }
    });

    const estimatePricingPayload: DirectCustomQuoteEstimatePricingPayload = {
      checkin,
      checkout,
      guest_details,
      num_guests,
      property_id,
      pricing: {
        nightly_total,
        fees: feesWithoutEmptyStrings,
      },
    };

    return estimatePricingPayload;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    if (this.newFee.value && this.newFee.value !== '') {
      this.onAddFee();
    }
  }

  onAddFee() {
    if (!this.localQuote.computed.pricing.customFees) {
      this.localQuote.computed.pricing.customFees = {};
    }
    this.localQuote.computed.pricing.customFees[this.newFee.value] = {
      value: this.newFeeAmount ? this.newFeeAmount.toString() : '',
      title: this.newFee.value,
    };
    this.newFee.reset();
    this.newFeeAmount = null;
    this.onRecalculate();
  }

  onRemoveFee(key: string) {
    delete this.localQuote.computed.pricing.customFees[key];
    this.onRecalculate();
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  onNext() {
    const propertyName = this.getPropertyName(this.localQuote.property_id);
    this.localQuote.computed['propertyName'] = propertyName ? propertyName.name : '';
    this.localQuote.computed['numberOfNights'] = this.numNightsPipe.transform(
      this.localQuote.checkin,
      this.localQuote.checkout
    );

    this.next.emit(this.localQuote);
  }

  onGoBack() {
    this.goBack.emit();
  }

  trackByFn(index, item) {
    return item.key; // or any unique property of the fee
  }

  onReset() {
    this.localQuote.computed.pricing.estimated = {
      ...this.localQuote.computed.pricing.original,
      ...{},
    };
    this.localQuote.computed.pricing.customFees = {};
    this.newFee.reset();
    this.newFeeAmount = null;
  }
}
