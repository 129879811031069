import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  standalone: false,
  selector: 'sbnb-app-version',
  template: `
    <div class="app-version text-night-lighter text-xs">
      Version: {{ environment.name }} : {{ environment.version }}
    </div>
  `,
})
export class AppVersionComponent {
  environment = environment;
}
