import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogDeleteUpcomingScheduledMessagesComponent } from '@app/modules/guest-experience/components/dialog-delete-upcoming-scheduled-messages/dialog-delete-upcoming-scheduled-messages.component';
import { LogService } from '../logs/log.service';

export type ScheduledMessageCancellerMode = 'review_marked_bad' | 'review_deleted';

@Injectable({
  providedIn: 'root',
})
export class ScheduledMessagesCancellerService {
  constructor(
    private readonly logService: LogService,
    private readonly dialog: MatDialog
  ) {}

  checkAndOpenScheduledMessageCanceller(
    reservation_code: string,
    mode: ScheduledMessageCancellerMode,
    guest_name: string
  ) {
    this.logService.getScheduledMessagesForReservation(reservation_code).subscribe((res) => {
      if (res.length > 0) {
        // open the dialog, we have scheduled messages
        this.dialog.open(DialogDeleteUpcomingScheduledMessagesComponent, {
          maxHeight: '90vh',
          maxWidth: '720px',
          autoFocus: false,
          data: {
            mode: mode,
            scheduled_messages: res,
            guest_name: guest_name,
            reservation_code,
          },
        });
      } else {
        // no scheduled messages, do nothing
      }
    });
  }
}
