import { Injectable } from '@angular/core';

declare const Storylane: any;

@Injectable({
  providedIn: 'root',
})
export class StorylaneService {
  constructor() {}

  public tourOwnerStatements() {
    try {
      Storylane.Play({
        type: 'popup',
        demo_type: 'image',
        scale: '0.95',
        width: 2560,
        height: 1469.6296296296296,
        demo_url: 'https://hospitable.storylane.io/demo/bcmiun6j619l?embed=popup',
        padding_bottom: 'calc(57.41% + 25px)',
      });
    } catch (error) {
      console.error('Error playing Storylane tour:', error);
    }
  }

  public previewGuestPortal() {
    try {
      Storylane.Play({
        type: 'popup',
        demo_type: 'image',
        width: 3444,
        height: 1910,
        scale: '0.95',
        demo_url: 'https://hospitable.storylane.io/demo/efqmdq7es0tr?embed=popup',
        padding_bottom: 'calc(55.46% + 25px)',
      });
    } catch (error) {
      console.error('Error playing Storylane tour:', error);
    }
  }
}
