import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { StatementsFacade } from '@app/modules/statements/services/statements.facade';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-operations-subnav',
  templateUrl: './operations-subnav.component.html',
  styleUrls: ['./operations-subnav.component.scss'],
})
export class OperationsSubnavComponent implements OnInit {
  @Input() showSegments: string;
  perms$ = this.userPermissionsService;
  Features = Features;

  public showOwnerStatements$ = combineLatest([
    this.statementsService.showOwnerStatements$,
    this.userPermissionsService.isPrimaryUser(),
  ]).pipe(
    map(([showOwnerStatements, isPrimaryUser]) => ({
      hasAccess: showOwnerStatements,
      showUpsell: !showOwnerStatements && isPrimaryUser,
    }))
  );

  public showOwnerStatementsSubItems$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(this.router.url),
    map(() => this.router.url.includes('statements') && this.router.url !== '/operations/statements/onboarding')
  );

  public showMyBusiness$ = this.posthog.myBusinessEnabled$;
  public showMyBusinessSubItems$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(this.router.url),
    map(() => this.router.url.includes('my-business'))
  );

  /* remove when mobile nav tabs are stable */
  public featureFlagMobileNavTabsEnabled$ = this.posthog.mobileNavTabsEnabled$;

  constructor(
    private statementsService: StatementsFacade,
    private userPermissionsService: UserPermissionsService,
    private posthog: PosthogService,
    private router: Router,
    public deviceDetectionService: DeviceDetectionService
  ) {}

  ngOnInit() {}
}
