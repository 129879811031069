<div
  *ngIf="canChangeLanguage"
  class="clickable"
  fxLayoutGap="4px"
  fxLayoutAlign="start center"
  [matMenuTriggerFor]="languageMenu"
  (menuOpened)="langMenuOpened()">
  <img src="/assets/iconography/language.svg" alt="language" />
  <span>{{ currentLanguage?.english }}</span>
  <img src="/assets/iconography/small-arrow-bottom.svg" alt="select a language" />
</div>

<div *ngIf="!canChangeLanguage" fxLayoutGap="8px" fxLayoutAlign="start center">
  <img src="/assets/iconography/language.svg" alt="language" />
  <span>{{ currentLanguage?.english }}</span>
</div>

<mat-menu #languageMenu="matMenu" class="filters__cdk-menu">
  <input
    #searchInput
    *ngIf="availableLanguages?.length > 4"
    class="filters__input-list-search"
    [(ngModel)]="searchCriteria"
    type="text"
    placeholder="Search"
    (click)="$event.stopPropagation()" />
  <ng-container *ngFor="let lang of availableLanguages">
    <button
      mat-menu-item
      *ngIf="
        !searchCriteria ||
        lang.english.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
        lang.native.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1
      "
      (click)="selectedNewLanguage(lang.code)">
      {{ lang.native }} ({{ lang.english }})
    </button>
  </ng-container>
</mat-menu>
