import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  ReservationAgreement,
  ReservationAgreementService,
  ReservationAgreementTemplate,
} from '@app/modules/guest-experience/services/reservation-agreements.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'dialog-add-new-rental-agreement-to-reservation',
  templateUrl: 'dialog-add-new-rental-agreement-to-reservation.component.html',
})
export class DialogAddNewRentalAgreementToReservationComponent implements OnInit {
  currentStage = 1;
  messageTemplate = `There is a rental agreement that requires your signature.

Please review and sign the agreement in our guest portal here %guest_portal_tasks%

Thank you,

%host_first_name%`;

  message = this.messageTemplate;
  submitting = false;

  allAgreementTemplates$: Observable<ReservationAgreementTemplate[]> = of([]);
  agreementsAlreadyOnReservation$: Observable<ReservationAgreement[]> = of([]);
  availableTemplates$: Observable<ReservationAgreementTemplate[]> = of([]);
  selectedTemplate: ReservationAgreementTemplate | null = null;

  constructor(
    private dialogRef: MatDialogRef<DialogAddNewRentalAgreementToReservationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { reservationUuid: string; propertyId: string; guestName: string },
    private reservationAgreementService: ReservationAgreementService
  ) {}

  ngOnInit() {
    // We don't have an easy way to get all applicable, unsigned rental agreements for this reservation
    // So we get them all, filter out those already associated, then filter out those not matching our property scope
    // And then we have our list of available rental agreement templates
    this.allAgreementTemplates$ = this.reservationAgreementService.fetchReservationAgreementTemplates();

    this.agreementsAlreadyOnReservation$ = this.reservationAgreementService.fetchRentalAgreementsForReservation(
      this.dialogData.reservationUuid
    );

    this.availableTemplates$ = combineLatest([this.allAgreementTemplates$, this.agreementsAlreadyOnReservation$]).pipe(
      map(([templates, agreements]) =>
        templates.filter(
          (template) =>
            // Filter out templates that have already been requested, and those not matching our property scope
            !agreements.some((agreement) => agreement.template_uuid === template.uuid) &&
            template.property_ids.includes(Number(this.dialogData.propertyId))
        )
      )
    );
  }

  closeDialog(agreementSentForSignature = false) {
    this.dialogRef.close(agreementSentForSignature);
  }

  onMessageTemplateChange(event: string) {
    this.message = event;
  }

  sendRentalAgreementRequest() {
    this.submitting = true;

    this.reservationAgreementService
      .requestRentalAgreement(this.dialogData.reservationUuid, this.selectedTemplate.uuid, this.message)
      .subscribe(() => {
        this.currentStage = 3;
      });
  }
}
