import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-split-card',
  template: `
    <div class="container">
      <div class="img" [style.background-image]="imgSrc ? 'url(' + imgSrc + ')' : undefined" data-chromatic="ignore">
        <span *ngIf="imgHeadine" [matTooltip]="imgHeadine" matTooltipPosition="below">
          {{ imgHeadine }}
        </span>
      </div>
      <div class="content"><ng-content></ng-content></div>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      .container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        border: 1px solid $smoke;
        overflow: hidden;
        max-width: 100%;
      }
      .content {
        max-width: 100%;
      }

      .img {
        background-image: url('https://picsum.photos/400');
        background-size: cover;
        background-position: center;
        position: relative;
        height: 100%;
        min-height: 100px;
        max-width: 100%;

        span {
          font-size: 1.125em;
          font-family: 'Cera Headings', 'Helvetica Neue', sans-serif;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          margin-bottom: 4px;
          padding: 0 24px;
          position: relative;
          top: 25px;
          color: #fff;
          text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
          max-width: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitCardComponent implements OnInit {
  @Input() imgSrc: string;
  @Input() imgHeadine: string;
  constructor() {}

  ngOnInit(): void {}
}
