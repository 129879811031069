import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-delete-segment',
  templateUrl: './dialog-delete-segment.component.html',
  styleUrls: ['./dialog-delete-segment.component.scss'],
})
export class DialogDeleteSegmentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteSegmentComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit() { }

  okClicked() {
    this.dialogRef.close(true);
  }

  cancelClicked() {
    this.dialogRef.close(false);
  }
}
