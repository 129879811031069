<div class="overview__box" (click)="clicked.emit()">
  <div fxLayout fxLayoutAlign="start start">
    <h1 *ngIf="title" fxFlex>
      {{ title }}
    </h1>
    <h1 *ngIf="title2" fxFlex>
      {{ title2 }}
    </h1>
    <div *ngIf="tooltipText" [class.clickable]="tooltipLink" class="tooltip-icon" [matTooltip]="tooltipText">
      <a [href]="tooltipLink" target="_blank" *ngIf="tooltipLink">
        <sbnb-icon icon="details" class="text-night-lighter"></sbnb-icon>
      </a>
      <sbnb-icon *ngIf="!tooltipLink" icon="details" class="text-night-lighter"></sbnb-icon>
    </div>
  </div>

  <div fxLayout fxLayoutAlign="start start" class="overview__subtitle">
    <span *ngIf="subtitle" fxFlex class="ocean">{{ subtitle }}</span>
    <span *ngIf="subtitle2" fxFlex class="night-lighter">{{ subtitle2 }}</span>
  </div>

  <p *ngIf="body" class="text__small" [innerHTML]="body"></p>
</div>
