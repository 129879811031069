import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'alterationHasNumberOfGuestsChanged',
})
export class AlterationHasNumberOfGuestsChangedPipe implements PipeTransform {
  transform(message: any): boolean {
    if (message.original_state.adults != message.proposed_state.adults) {
      return true;
    }

    if (message.original_state.children != message.proposed_state.children) {
      return true;
    }

    if (message.original_state.infants != message.proposed_state.infants) {
      return true;
    }

    if (message.original_state.pets != message.proposed_state.pets) {
      return true;
    }

    return false;
  }
}
