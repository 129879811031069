import { Component, Input, OnInit } from '@angular/core';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { environment } from '@env/environment';

@Component({
  standalone: false,
  selector: 'sbnb-cs-debug-item',
  templateUrl: './cs-debug-item.component.html',
  styleUrls: ['./cs-debug-item.component.scss'],
})
export class CsDebugItemComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() borderTop: boolean;
  @Input() borderBottom: boolean;
  @Input() link: string;
  @Input() linkLabel = 'Link';
  @Input() coreNovaLink: string;
  @Input() directNovaLink: string;

  isCS: boolean;
  debugMode$ = this.csService.debugMode;

  constructor(private csService: InternalCsService) { }

  ngOnInit() {
    this.isCS = this.csService.isCS();

    if (this.coreNovaLink) {
      this.link = environment.coreNovaUrl + this.coreNovaLink;
    } else if (this.directNovaLink) {
      this.link = environment.directNovaUrl + this.directNovaLink;
    }
  }
}
