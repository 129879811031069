import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { PosthogFeatureFlag } from '@app/shared/interfaces/lib/feature-flags.interface';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sbnb-app-maintenance-mode',
  template: `
    <div
      style="z-index: 9999;"
      class="flex flex-col items-center justify-center h-screen w-screen fixed top-0 left-0 bg-white px-4">
      <div class="flex flex-col items-center justify-center text-center" *ngIf="!loading">
        <h1 class="text-3xl font-bold">Hospitable is currently under maintenance.</h1>
        <span class="text-lg text-gray-500 mt-4">{{ message }}</span>

        <!-- Looking to start a free trial? Submit your details and our team will get back to you -->
        <div *ngIf="!isLoggedIn" class="mt-12">
          <a
            href="https://form.typeform.com/to/hB7rUQ?typeform-source=hospitable.com#&hubspot_page_name=Contact&hubspot_page_url=contact-us&hubspot_source=Form"
            target="_blank"
            class="block p-6 bg-white border border-smoke rounded-lg hover:bg-smoke-lightest transition-colors duration-200 text-center hover:bg-grass-lighter"
            data-maintenance-cta="free-trial">
            <h3 class="block text-grape-darker font-medium text-primary mb-2">Looking to start a free trial?</h3>
            <span>Submit your details and our team will get back to you</span>
          </a>
        </div>

        <span class="text-sm text-gray-500 mt-16 flex items-center gap-2">
          <sbnb-loading-spinner [size]="16"></sbnb-loading-spinner>
          <span>You'll be automatically redirected back to Hospitable once service is restored.</span>
        </span>
      </div>

      <div class="flex flex-col items-center justify-center" *ngIf="loading">
        <sbnb-loading-spinner></sbnb-loading-spinner>
      </div>
    </div>
  `,
})
export class AppMaintenanceModeComponent implements OnInit, OnDestroy {
  MESSAGE_UPDATE_INTERVAL = 10_000;
  DEFAULT_MESSAGE = `We'll be back soon.`;

  message = this.DEFAULT_MESSAGE;

  loading = true;
  isLoggedIn = this.authService.isLoggedInSubject.value;

  private destroy$ = new Subject<void>();

  constructor(
    private posthogService: PosthogService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.registerForMessageUpdates();

    this.addStatuspageScript();
  }

  ngOnDestroy() {
    this.removeStatuspageScript();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private addStatuspageScript() {
    const script = document.createElement('script');
    script.src = 'https://status.hospitable.com/embed/script.js';
    document.head.appendChild(script);
  }

  private removeStatuspageScript() {
    const script = document.querySelector('script[src="https://status.hospitable.com/embed/script.js"]');
    if (script) {
      script.remove();
    }
  }

  private registerForMessageUpdates() {
    timer(1_000, this.MESSAGE_UPDATE_INTERVAL)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.posthogService
          .getRemoteConfigPayload$<{
            maintenance_mode_active: boolean;
            message: string;
            estimated_utc_resolution: string;
          }>(PosthogFeatureFlag.AppMaintenanceMode)
          .subscribe((payload) => {
            this.loading = false;

            this.setMessage(payload?.message || this.DEFAULT_MESSAGE, payload?.estimated_utc_resolution);
          });
      });
  }

  private setMessage(message: string, estimatedUtcResolution: string) {
    if (message && estimatedUtcResolution) {
      const formattedDateTime = this.formatISOToLocalTime(estimatedUtcResolution);

      this.message = message.replace('{{time}}', formattedDateTime);
    } else {
      this.message = message;
    }
  }

  private formatISOToLocalTime = (isoString: string) => {
    const date = new Date(isoString);

    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();

    const formattedDateTime = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    }).format(date);

    if (!isToday) {
      return `${date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })} - ${formattedDateTime}`;
    }

    return formattedDateTime;
  };
}
