import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { config } from '@app/core/app-config';
import { map, tap } from 'rxjs/operators';
import { Filter } from '@app/shared/models/filter';
import { Segment } from '@app/shared/models/segment';
import { ApiCacheService } from '../api-cache.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private getSegmentListUrl = `${config.API_URL}/segments/`;

  segmentsSbj = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private apiCache: ApiCacheService
  ) {}

  getSegmentListForKey(key: string) {
    const url = `${this.getSegmentListUrl}${key}`;
    return this.http.get<Segment[]>(url).pipe(
      map((response) => {
        this.segmentsSbj.next(response['data']);
      })
    );
  }

  getSegmentById(id: string, key?: string): Observable<any> {
    let url;

    if (id === 'default' && key) {
      url = `${this.getSegmentListUrl}default/${key}`;
    } else {
      url = `${this.getSegmentListUrl}${id}`;
    }

    return this.http.get(url).pipe(
      map((response) => {
        return {
          filters: response['data']['filter_body'],
          title: response['data']['label'],
          uuid: response['data']['uuid'],
          identifier: response['data']['identifier'],
        };
      })
    );
  }

  deleteSegment(id: string, key: string): Observable<any> {
    const url = `${this.getSegmentListUrl}${id}`;

    return this.http.delete(url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveCustomSegment(key: string, name: string, filters: Filter[]): Observable<any> {
    const url = `${this.getSegmentListUrl}`;

    const postData = {
      group: 'Saved Segments',
      type: key,
      name,
      filters,
    };

    this.apiCache.delete(`${environment.apiUrl}/segments/${key}`);

    return this.http.post<any>(url, postData).pipe(
      map((response) => response['data']),
      tap((saveSuccess) => {
        if (saveSuccess) {
          this.getSegmentListForKey(key).subscribe();
        }
      })
    );
  }
}
