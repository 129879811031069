<div class="cards">
  <!-- Guest details -->
  <sbnb-collapsible-card
    *ngIf="thread$ | async as thread"
    [defaultOpen]="(cardsState$ | async)?.guestDetails ?? true"
    (openToggled)="saveCollapsedCard('guestDetails', $event?.open)">
    <h4 title>About {{ thread.guest?.first_name ?? 'Guest' }}</h4>
    <ng-template sbnbCollapsibleCardContent>
      <div class="section" *ngIf="guestInsights$ | async as insights">
        <!-- Location -->
        <div *ngIf="insights.location" class="flex gap-2 items-center">
          <ng-container *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.location }"></ng-container>
          <div>{{ insights.location.label }}</div>
        </div>

        <!-- Local time -->
        <div *ngIf="insights.localTime" class="flex gap-2 items-center">
          <ng-container *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.localTime }"></ng-container>
          <div>{{ insights.localTime.timeZone | currentTimeInTz: insights.localTime.format | async }}</div>
        </div>

        <!-- Language -->
        <ng-container *ngIf="languageChangeOptions$ | async as opts">
          <sbnb-language-switcher
            *ngIf="opts.current_language"
            [canChangeLanguage]="opts.can_change_language"
            [currentLanguage]="opts.current_language"
            [availableLanguages]="opts.available_languages"
            (languageChange)="languageChanged(thread.uuid, $event)"></sbnb-language-switcher>
        </ng-container>
        <!-- Phone -->
        <div *ngIf="insights.phone" class="flex gap-2 items-center">
          <a [href]="'tel:' + insights.phone.label" class="flex items-center gap-1 underline">
            <sbnb-icon [icon]="insights.phone.icon" class="text-smoke-darker"></sbnb-icon>
            <span>{{ insights.phone.label }}</span>
          </a>
          <sbnb-copy-to-clipboard [mini]="true" [content]="insights.phone.label"></sbnb-copy-to-clipboard>
        </div>

        <!-- Email -->
        <sbnb-guest-email
          *ngIf="thread?.guest?.email?.length"
          [email]="thread?.guest?.email"
          [canChangeEmail]="thread.supports?.email_change === true"
          [threadUuid]="thread.uuid"></sbnb-guest-email>

        <!-- Begin verified -->
        <div *ngIf="insights.verified" class="flex gap-2 items-center">
          <img [src]="insights.verified.icon" alt="verified" />
          <div>{{ insights.verified.label }}</div>
        </div>
        <!-- End verified -->

        <!-- Begin Active For -->
        <div *ngIf="insights.activeFor" class="flex gap-2 items-center">
          <ng-container *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.activeFor }"></ng-container>
          <div>{{ insights.activeFor.label }}</div>
        </div>
        <!-- End Active For -->

        <!-- Begin chance of booking -->
        <div *ngIf="insights.chancesOfBooking" class="flex gap-2 items-center">
          <img [src]="insights.chancesOfBooking.icon" alt="chance of booking" />
          <div>{{ insights.chancesOfBooking.label }}</div>
        </div>
        <!-- End chance of booking -->

        <!-- Begin review count -->
        <div *ngIf="insights.reviewCount" class="flex gap-2 items-center">
          <ng-container
            *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.reviewCount }"></ng-container>
          <div>{{ insights.reviewCount.count }} Public reviews</div>
        </div>
        <!-- End review count -->

        <!-- Begin same day booking -->
        <div *ngIf="insights.sameDayBooking" class="flex gap-2 items-center">
          <ng-container
            *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.sameDayBooking }"></ng-container>
          <div>{{ insights.sameDayBooking.label }}</div>
        </div>
        <!-- End same day booking -->

        <!-- Begin Local guest -->
        <div *ngIf="insights.localGuest" class="flex gap-2 items-center">
          <ng-container
            *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.localGuest }"></ng-container>
          <div class="cera-heading">Local guest - {{ insights.localGuest.label }}</div>
        </div>
        <!-- End local guest -->

        <!-- Begin returning guest -->
        <div *ngIf="insights.returningGuest" class="flex gap-2 items-center">
          <ng-container
            *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.returningGuest }"></ng-container>
          <div>{{ insights.returningGuest.label }}</div>
        </div>
        <!-- End returning guest -->

        <!-- Begin guest recommendations (Direct) -->
        <div *ngIf="insights.recommendations && insights.recommendations.amount > 0" class="flex gap-2 items-center">
          <ng-container
            *ngTemplateOutlet="guestInsightIcon; context: { $implicit: insights.recommendations }"></ng-container>
          <div>
            Recommended by {{ insights.recommendations.amount }} {{ 'CORE.PRODUCT_NAME_SHORT' | transloco }}
            {{ 'hosts' | pluralize: insights.recommendations.amount | titlecase }}
          </div>
        </div>
        <!-- End guest recommendations (Direct) -->

        <!-- Tags -->
        <ng-container *ngIf="tags$ | async as tags">
          <div *ngIf="tags?.length" class="flex items-center gap-2">
            <sbnb-icon icon="tag" class="shrink-0 text-night-lighter"></sbnb-icon>
            <sbnb-tags [tags]="tags"></sbnb-tags>
          </div>
        </ng-container>

        <!-- Guest summary -->
        <ng-container *ngIf="guestSummary$ | async as summary">
          <sbnb-guest-summary [summary]="summary.summary"></sbnb-guest-summary>
        </ng-container>

        <!-- Guest reviews -->
        <sbnb-guest-reviews [reviews]="thread.guest?.reviews"></sbnb-guest-reviews>
      </div>
    </ng-template>
  </sbnb-collapsible-card>
  <!-- Property details -->
  <sbnb-collapsible-card
    *ngIf="propertyDetails$ | async as property"
    [defaultOpen]="(cardsState$ | async)?.propertyDetails ?? true"
    (openToggled)="saveCollapsedCard('propertyDetails', $event?.open)">
    <h4 class="title" title>Property Details</h4>
    <ng-template sbnbCollapsibleCardContent>
      <div class="section">
        <div>
          <div class="label">Address</div>
          <div class="value">
            <sbnb-copy-to-clipboard [content]="property.address">{{ property.address }}</sbnb-copy-to-clipboard>
          </div>
        </div>

        <div>
          <div class="label">Wifi name</div>
          <sbnb-copy-to-clipboard [content]="property.wifi_name">
            <ng-container *ngTemplateOutlet="optionalValue; context: { $implicit: property.wifi_name }"></ng-container>
          </sbnb-copy-to-clipboard>
        </div>

        <div>
          <div class="label">Wifi password</div>
          <div class="value">
            <sbnb-copy-to-clipboard [content]="property.wifi_password">
              <ng-container
                *ngTemplateOutlet="optionalValue; context: { $implicit: property.wifi_password }"></ng-container>
            </sbnb-copy-to-clipboard>
          </div>
        </div>

        <div>
          <div class="label">House Rules</div>
          <div class="value space-between">
            <span>Max number of guests:</span>
            <span>{{ property.person_capacity }}</span>
          </div>
          <div class="value space-between">
            <span>Pets:</span>
            <ng-container *ngTemplateOutlet="allowed; context: { $implicit: property.allow_pets }"></ng-container>
          </div>
          <div class="value space-between">
            <span>Smoking:</span>
            <ng-container *ngTemplateOutlet="allowed; context: { $implicit: property.allow_smoking }"></ng-container>
          </div>
          <div class="value space-between">
            <span>Parties &#x2f; events:</span>
            <ng-container *ngTemplateOutlet="allowed; context: { $implicit: property.allow_events }"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </sbnb-collapsible-card>
  <!-- Financials -->
  <ng-container *ngIf="financials$ | async as financials">
    <sbnb-collapsible-card
      *ngIf="financials.data?.host?.length"
      [defaultOpen]="(cardsState$ | async)?.reservationFinancials ?? true"
      (openToggled)="saveCollapsedCard('reservationFinancials', $event?.open)">
      <h4 class="title" title>Reservation Financials</h4>
      <ng-template sbnbCollapsibleCardContent>
        <div class="section">
          <ng-container *ngFor="let item of financials.data.host">
            <ng-container *ngTemplateOutlet="financialItem; context: { $implicit: item }"></ng-container>
          </ng-container>
        </div>
      </ng-template>
    </sbnb-collapsible-card>
    <sbnb-collapsible-card
      *ngIf="financials.data?.guest?.length"
      [defaultOpen]="(cardsState$ | async)?.guestPayment ?? true"
      (openToggled)="saveCollapsedCard('guestPayment', $event?.open)">
      <h4 class="title" title>Guest Payment</h4>
      <ng-template sbnbCollapsibleCardContent>
        <div class="section">
          <ng-container *ngFor="let item of financials.data.guest">
            <ng-container *ngTemplateOutlet="financialItem; context: { $implicit: item }"></ng-container>
          </ng-container>
        </div>
      </ng-template>
    </sbnb-collapsible-card>
    <sbnb-collapsible-card
      *ngIf="(reservation$ | async)?.payment_card?.balance?.amount_formatted"
      [defaultOpen]="(cardsState$ | async)?.bookingVCCData ?? true"
      (openToggled)="saveCollapsedCard('bookingVCCData', $event?.open)">
      <h4 class="title" title>{{ platform$ | async }} Payment Details</h4>
      <ng-template sbnbCollapsibleCardContent>
        <p class="!leading-tight text-sm">Displays the credit card information you can use to charge the guest.</p>

        <div class="section">
          <dl class="flex flex-col gap-1 [&_dt~dt]:mt-2 [&_dd]:ml-0">
            <div class="flex justify-between">
              <dt class="font-heading">Card balance</dt>
              <dd class="font-semibold font-serif slashed-zero tabular-nums">
                {{ (reservation$ | async).payment_card.balance.amount_formatted }}
              </dd>
            </div>

            <p class="col-span-2 m-0 !leading-tight text-sm">This is the available funds in the card.</p>

            <!--
            <dt>Card owner</dt>
            <dd class="pl-3 font-semibold antialiased font-heading">John Doe</dd>

            <dt>Card number</dt>
            <dd class="pl-3 font-semibold font-serif slashed-zero tabular-nums">4436 1232 5677 1234</dd>

            <dt>Security code (CVC)</dt>
            <dd class="pl-3 font-semibold font-serif slashed-zero tabular-nums">123</dd>

            <dt>Expiry date (MM/YY)</dt>
            <dd class="pl-3 font-semibold font-serif slashed-zero tabular-nums">12/28</dd> -->
          </dl>
        </div>
      </ng-template>
    </sbnb-collapsible-card>
  </ng-container>

  <!-- Inquiry/thread financials -->
  <ng-container *ngIf="threadFinancials$ | async as financials">
    <sbnb-collapsible-card
      *ngIf="financials.host?.length"
      [defaultOpen]="(cardsState$ | async)?.inquiryFinancials ?? true"
      (openToggled)="saveCollapsedCard('inquiryFinancials', $event?.open)">
      <h4 class="title" title>Inquiry Financials</h4>
      <ng-template sbnbCollapsibleCardContent>
        <div class="section">
          <ng-container *ngFor="let item of financials.host">
            <ng-container *ngTemplateOutlet="financialItem; context: { $implicit: item }"></ng-container>
          </ng-container>
        </div>
      </ng-template>
    </sbnb-collapsible-card>
    <sbnb-collapsible-card
      *ngIf="financials?.guest?.length"
      [defaultOpen]="(cardsState$ | async)?.guestPayment ?? true"
      (openToggled)="saveCollapsedCard('guestPayment', $event?.open)">
      <h4 class="title" title>Guest Payment</h4>
      <ng-template sbnbCollapsibleCardContent>
        <div class="section">
          <ng-container *ngFor="let item of financials.guest">
            <ng-container *ngTemplateOutlet="financialItem; context: { $implicit: item }"></ng-container>
          </ng-container>
        </div>
      </ng-template>
    </sbnb-collapsible-card>
  </ng-container>

  <!-- Cancellation Policy (if applicable) -->
  <ng-container *ngIf="cancellationPolicy$ | async as cancellationPolicy">
    <sbnb-collapsible-card
      *ngIf="cancellationPolicy?.values?.length"
      [defaultOpen]="(cardsState$ | async)?.cancellationPolicy ?? true"
      (openToggled)="saveCollapsedCard('cancellationPolicy', $event?.open)">
      <h4 class="title" title>Cancellation Policy</h4>
      <ng-template sbnbCollapsibleCardContent>
        <div class="section">
          <div>
            <ng-container *ngFor="let item of cancellationPolicy.values">
              <div class="value space-between">
                <span>{{ item.key }}</span>
                <span>{{ item.value }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </sbnb-collapsible-card>
  </ng-container>
</div>

<!-- Templates -->
<!-- Allowed / Not allowed -->
<ng-template #allowed let-allowed>
  <span>{{ allowed ? 'Allowed' : 'Not Allowed' }}</span>
</ng-template>

<!-- Empty value -->
<ng-template #optionalValue let-value>
  <div class="value" [class.empty]="!value">
    <ng-container *ngIf="value">{{ value }}</ng-container>
    <ng-container *ngIf="!value">&mdash;</ng-container>
  </div>
</ng-template>

<ng-template #financialItem let-item>
  <div *ngIf="item">
    <div class="label space-between">
      <span>{{ item.title }}</span>
      <span *ngIf="item.amount_formatted" class="financial">{{ item.amount_formatted }}</span>
    </div>
    <sbnb-financial-section *ngIf="item.items?.length" [items]="item.items"></sbnb-financial-section>
  </div>
</ng-template>

<ng-template #guestInsightIcon let-insight>
  <sbnb-icon [icon]="insight.icon" class="text-smoke-darker shrink-0"></sbnb-icon>
</ng-template>
