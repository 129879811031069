import { Pipe, PipeTransform } from '@angular/core';
import { isAfter, endOfDay } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isAfterToday',
})
export class IsAfterTodayPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return isAfter(value, endOfDay(new Date()));
  }
}
