import { Component, OnInit } from '@angular/core';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { LocalStorageKey } from '@app/shared/interfaces';
import { Channel } from '@app/shared/models/channel';
import { toPlatformLabel } from '@app/shared/utils/lib/general.util';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-reservation-details',
  templateUrl: './reservation-details.component.html',
  styleUrls: ['./reservation-details.component.scss'],
})
export class ReservationDetailsComponent implements OnInit {
  public propertyDetails$ = this.selectedReservationService.propertyData$;
  /**
   * filter the extended reservation data down to the only group we care about,
   * the cancellation policy
   */
  public cancellationPolicy$ = this.selectedReservationService.extendedReservationData$.pipe(
    map((reservation) => reservation?.data.find(({ group_id }) => group_id === 'cancellation-policy'))
  );
  public reservation$ = this.selectedReservationService.activeReservation$;
  public platform$ = this.reservation$.pipe(map((reservation) => toPlatformLabel(reservation.platform)));
  public financials$ = this.selectedReservationService.reservationFinancialsData$;
  public thread$ = this.selectedReservationService.threadData$;
  public threadFinancials$ = this.selectedReservationService.threadFinancialData$;

  public guestInsights$ = this.selectedReservationService.guestInsights$;
  public Channel = Channel;
  public guestSummary$ = this.selectedReservationService.guestSummary$;
  public cardsState$ = new BehaviorSubject<{ [key: string]: boolean }>(
    localStorage.getItem(LocalStorageKey.sidebarCardsState) ? JSON.parse(localStorage.getItem('sidebarCardsState')) : {}
  );
  public tags$ = this.selectedReservationService.tags$;
  public languageChangeOptions$ = this.selectedReservationService.languageChangeOptions$;

  constructor(
    private selectedReservationService: SelectedReservationService,
    private userPermissionsService: UserPermissionsService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.selectedReservationService.threadData$,
      this.userPermissionsService.hasAccessTo(Features.PERM_RESERVATION_FINANCIALS),
    ]).subscribe(([thread, hasResFinancialPerm]) => {
      if (['inquiry', 'preapproved'].includes(thread?.status_key) && hasResFinancialPerm) {
        this.selectedReservationService.getThreadFinancials();
      }
    });
  }

  public saveCollapsedCard(cardId: string, isOpen: boolean): void {
    this.cardsState$.next({ ...this.cardsState$.value, [cardId]: isOpen });
    localStorage.setItem(LocalStorageKey.sidebarCardsState, JSON.stringify(this.cardsState$.value));
  }

  public languageChanged(threadUuid: string, newLanguageCode: string) {
    this.selectedReservationService.changeLanguage(threadUuid, newLanguageCode);
  }
}
