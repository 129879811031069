<mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>

<div *ngIf="!loading" [ngClass]="{'mobile-menu-saved-segments': inMobileMenu}">
  <ng-container *ngFor="let segment of globalSegments | keyvalue">
    <ng-container *ngIf="segment.value | hasVisibleSegments">
      <h4 *ngIf="segment.key !== ''" fxLayout fxLayoutGap="8px">
        <sbnb-icon [size]="20" [icon]="segment.value[0].group_icon" class="text-smoke-darker"></sbnb-icon>
        <span fxFlex>{{ segment.key }}</span>
      </h4>

      <nav class="nav__subnav">
        <ng-container *ngFor="let item of segment.value">
          <div
            *ngIf="!item.hidden"
            fxLayout
            fxLayoutAlign="start center"
            [class.segment__highlighted-parent]="item.is_highlighted">
            <a
              [routerLink]="linkify(item.uuid)"
              routerLinkActive="subnav__active-link"
              [class.subnav__active-link]="activeId === item.uuid">
              <span *ngIf="item.is_highlighted" class="segment__highlighted"></span>
              {{ item.label }}
            </a>
          </div>
        </ng-container>
      </nav>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let qf of quickFilters">
    <h4 fxLayout="8px">
      <img
        [src]="'/assets/iconography/filter-icons/' + qf.id + '.svg'"
        class="quickFilter__icon"
        onerror="this.src='/assets/iconography/filter-icons/default.svg'" />
      <span fxFlex>{{ qf.label }}</span>
    </h4>

    <nav class="nav__subnav">
      <ng-container
        *ngFor="
          let value of qf.valuesData | slice: 0 : (showAllQuickFilters ? undefined : showAllMinSize);
          let i = index
        ">
        <div fxLayout fxLayoutAlign="start center">
          <a class="clickable" (click)="applyQuickFilter(qf, value)" fxFlex>
            {{ value.label }}
          </a>
        </div>

        <ng-container *ngIf="qf.valuesData && qf.valuesData.length > showAllMinSize">
          <div fxLayout fxLayoutAlign="start center" *ngIf="i === showAllMinSize - 1 && !showAllQuickFilters">
            <button (click)="showAllQuickFilters = true" mat-button class="pl-0 teal">Show more</button>
          </div>

          <div *ngIf="i === qf.valuesData.length - 1 && showAllQuickFilters" fxLayout fxLayoutAlign="start center">
            <button mat-button class="pl-0 teal" (click)="showAllQuickFilters = false">Show less</button>
          </div>
        </ng-container>
      </ng-container>
    </nav>
  </ng-container>

  <ng-container *ngFor="let segment of savedSegments | keyvalue">
    <ng-container *ngIf="segment.value.length > 0">
      <h4 fxLayout fxLayoutGap="8px">
        <img [src]="'/assets/iconography/' + segment.value[0].group_icon + '.svg'" style="width: 20px; height: 20px" />
        <span fxFlex>{{ segment.key }}</span>
      </h4>

      <nav class="nav__subnav">
        <ng-container
          *ngFor="let item of segment.value | slice: 0 : (showAllSegments ? undefined : showAllMinSize); let i = index">
          <div fxLayout fxLayoutAlign="start center">
            <a [routerLink]="linkify(item.uuid)" routerLinkActive="subnav__active-link" fxFlex>{{ item.label }}</a>
            <img
              class="on-hover"
              (click)="deleteSegment(item.uuid, item.label)"
              width="20"
              height="20"
              src="/assets/iconography/cancel-smoke.svg" />
          </div>

          <ng-container *ngIf="segment.value.length > showAllMinSize">
            <div fxLayout fxLayoutAlign="start center" *ngIf="i === showAllMinSize - 1 && !showAllSegments">
              <button mat-button class="pl-0 teal" (click)="showAllSegments = true">Show more</button>
            </div>

            <div *ngIf="i === segment.value.length - 1 && showAllSegments" fxLayout fxLayoutAlign="start center">
              <button mat-button class="pl-0 teal" (click)="showAllSegments = false">Show less</button>
            </div>
          </ng-container>
        </ng-container>
      </nav>
    </ng-container>
  </ng-container>
</div>
