import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-overview-box',
  templateUrl: './overview-box.component.html',
  styleUrls: ['./overview-box.component.scss'],
})
export class OverviewBoxComponent implements OnInit {
  /**
   * The title used to display in the box
   */
  @Input() title: string;

  /**
   * The subtitle displayed under the title in the box
   */
  @Input() subtitle: string;

  /**
   * (Optional): The second title used to display in the box
   */
  @Input() title2: string;

  /**
   * (Optional): The second subtitle used to display in the box
   */
  @Input() subtitle2: string;

  /**
   * The main body text. It is possible to use basic HTML tags in here, or just specify a normal string.
   */
  @Input() body: string;

  /**
   * (Optional): The tooltip text to show on hover of the tooltip. If not specified, tooltip icon will be hidden
   */
  @Input() tooltipText: string;

  /**
   * If specified, the tooltip will be clickable, and will direct users to the URL provided in a new tab
   */
  @Input() tooltipLink: string;

  @Output() clicked: EventEmitter<null> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }
}
