import { ReasonMapping } from '@app/shared/models/reason-mapping';

const cancel = 'cancel';
const failure = 'failure';
const timing = 'timing';

// cancellation codes
const platform_account_disconnected = 'platform_account_disconnected';
const platform_account_invalid = 'platform_account_invalid';
const possible_duplicate = 'possible_duplicate';
const manually_cancelled_by_user = 'manually_cancelled_by_user';
const subscription_expired = 'subscription_expired';
const no_active_messaging_settings = 'no_active_messaging_settings';
const ruleset_does_not_exist = 'ruleset_does_not_exist';
const ruleset_is_not_active = 'ruleset_is_not_active';
const twin_review_completed_or_public = 'twin_review_completed_or_public';
const reservation_is_not_accepted = 'reservation_is_not_accepted';
const listing_is_deleted = 'listing_is_deleted';
const property_is_muted = 'property_is_muted';
const prepared_message_empty = 'prepared_message_empty';
const low_sentiment = 'low_sentiment';

// failure codes
const exhausted_retries = 'exhausted_retries';
const validation_failed = 'validation_failed';

// timing codes
const rescheduled_one_day_early_at_latest_time = 'rescheduled_one_day_early_at_latest_time';
const rescheduled_one_day_early_same_time = 'rescheduled_one_day_early_same_time';
const short_notice_30_minutes_after_booking = 'short_notice_30_minutes_after_booking';
const short_notice_immediately_after_booking = 'short_notice_immediately_after_booking';
const guest_not_validated_yet = 'guest_not_validated_yet';
const message_sent_later_than_expected_schedule = 'message_sent_later_than_expected_schedule';
const no_message_prepared_reschedule_30s = 'no_message_prepared_reschedule_30s';
const smartlock_code_already_sent = 'smartlock_code_already_sent';

export const ReasonCodes: ReasonMapping[] = [
  new ReasonMapping(cancel, platform_account_disconnected, 'REASON.PLATFORM_ACCOUNT_DISCONNECTED'),
  new ReasonMapping(cancel, platform_account_invalid, 'REASON.PLATFORM_ACCOUNT_INVALID'),
  new ReasonMapping(cancel, possible_duplicate, 'REASON.POSSIBLE_DUPLICATE'),
  new ReasonMapping(cancel, manually_cancelled_by_user, 'REASON.MANUALLY_CANCELLED_BY_USER'),
  new ReasonMapping(cancel, low_sentiment, 'REASON.LOW_SENTIMENT'),
  new ReasonMapping(cancel, subscription_expired, 'REASON.SUBSCRIPTION_EXPIRED'),
  new ReasonMapping(cancel, no_active_messaging_settings, 'REASON.NO_ACTIVE_MESSAGING_SETTINGS'),
  new ReasonMapping(cancel, ruleset_does_not_exist, 'REASON.RULESET_DOES_NOT_EXIST'),
  new ReasonMapping(cancel, ruleset_is_not_active, 'REASON.RULESET_IS_NOT_ACTIVE'),
  new ReasonMapping(cancel, twin_review_completed_or_public, 'REASON.TWIN_REVIEW_COMPLETED_OR_PUBLIC'),
  new ReasonMapping(cancel, reservation_is_not_accepted, 'REASON.RESERVATION_IS_NOT_ACCEPTED'),
  new ReasonMapping(cancel, listing_is_deleted, 'REASON.LISTING_IS_DELETED'),
  new ReasonMapping(cancel, property_is_muted, 'REASON.PROPERTY_IS_MUTED'),
  new ReasonMapping(cancel, prepared_message_empty, 'REASON.PREPARED_MESSAGE_EMPTY'),
  new ReasonMapping(cancel, smartlock_code_already_sent, 'REASON.SMARTLOCK_CODE_ALREADY_SENT'),
  new ReasonMapping(failure, exhausted_retries, 'REASON.EXHAUSTED_RETRIES'),
  new ReasonMapping(failure, validation_failed, 'REASON.VALIDATION_FAILED'),
  new ReasonMapping(
    timing,
    rescheduled_one_day_early_at_latest_time,
    'REASON.RESCHEDULED_ONE_DAY_EARLY_AT_LATEST_TIME'
  ),
  new ReasonMapping(timing, rescheduled_one_day_early_same_time, 'REASON.RESCHEDULED_ONE_DAY_EARLY_SAME_TIME'),
  new ReasonMapping(timing, short_notice_30_minutes_after_booking, 'REASON.SHORT_NOTICE_30_MINUTES_AFTER_BOOKING'),
  new ReasonMapping(timing, short_notice_immediately_after_booking, 'REASON.SHORT_NOTICE_IMMEDIATELY_AFTER_BOOKING'),
  new ReasonMapping(timing, guest_not_validated_yet, 'REASON.GUEST_NOT_VALIDATED_YET'),
  new ReasonMapping(
    timing,
    message_sent_later_than_expected_schedule,
    'REASON.MESSAGE_SENT_LATER_THAN_EXPECTED_SCHEDULE'
  ),
  new ReasonMapping(timing, no_message_prepared_reschedule_30s, 'REASON.NO_MESSAGE_PREPARED_RESCHEDULE_30S'),
];
