<div fxFlexFill id="modal-fs">
  <div fxLayout fxLayoutAlign="space-between center">
    <a [routerLink]="['/']">
      <img class="sbnb-logo" src="/assets/images/logo-transparent-dark.svg" alt="Hospitable logo" />
    </a>
    <sbnb-close *ngIf="closeLink" [border]="true" (click)="navigateBack()"></sbnb-close>
    <sbnb-close *ngIf="goBack" [border]="true" (click)="location.back()"></sbnb-close>
  </div>

  <section fxFlexFill fxLayout fxLayoutAlign="center center">
    <div class="fs-dialog-contents" [style.width]="width" [style.maxWidth]="width">
      <router-outlet></router-outlet>
      <ng-content></ng-content>
    </div>
  </section>
</div>
