import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationType } from '@app/shared/interfaces';
import { GuestVettingData, VerificationStatus } from '@app/shared/interfaces/lib/guestvetting.interface';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { ThreadMessage, ThreadService } from '@app/shared/services/thread/thread.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

export enum MessageAutomationName {
  SmartLockBackUpCode = 'Smart lock backup code',
  SmartLockCodeCommunicationMessage = 'Smart lock code communication',
}

@Component({
  standalone: false,
  selector: 'sbnb-thread-messages',
  templateUrl: './thread-messages.component.html',
  styleUrls: ['./thread-messages.component.scss'],
})
export class ThreadMessagesComponent implements OnInit {
  MessageAutomationName = MessageAutomationName;
  acceptLoading: boolean;
  rejectLoading: boolean;
  cancelLoading: boolean;
  isCS: boolean;
  VerificationStatus = VerificationStatus;

  @Input() messages: ThreadMessage[] = [];
  @Input() guestVettingData: GuestVettingData;
  @Input() threadId: string;
  @Input() withNameLabels: boolean;
  @Input() alwaysReverse = false;
  @Output() reservationNeedsUpdating: EventEmitter<any> = new EventEmitter();
  @Output() resendMessage: EventEmitter<any> = new EventEmitter();

  constructor(
    private threadService: ThreadService,
    private toast: ToastNotificationsService,
    private csService: InternalCsService
  ) {}

  ngOnInit() {
    this.isCS = this.csService.isCS();
  }

  resendTheMessage(id: any) {
    this.resendMessage.emit(id);
  }

  messageTrackBy(index: number, message: any) {
    return message.id;
  }

  toggleAutoTextContainer(outer: HTMLElement, toggleBtn: HTMLImageElement) {
    const isExpanded = outer.style.maxHeight === '5000px';

    if (!isExpanded) {
      outer.style.maxHeight = '5000px';
      toggleBtn.setAttribute('src', '/assets/iconography/small-arrow-top.svg');
    } else {
      outer.style.maxHeight = '180px';
      toggleBtn.setAttribute('src', '/assets/iconography/small-arrow-bottom.svg');
    }
  }

  openTab(url: string): void {
    (<any>window).open(url, '_blank');
  }

  acceptBooking(message) {
    this.acceptLoading = true;

    this.threadService.acceptBooking(message.uuid).subscribe(
      (res) => {
        message.actionable = false;

        this.triggerParentReservationStatusUpdate(message, res);
      },
      () => {
        this.acceptLoading = false;
      }
    );
  }

  triggerParentReservationStatusUpdate(message, res) {
    this.reservationNeedsUpdating.emit({ message, res });
  }

  rejectBooking(message) {
    this.rejectLoading = true;

    this.threadService.rejectBooking(message.uuid).subscribe((res) => {
      if (res.error) {
        this.rejectLoading = false;

        this.toast.open(`Error rejecting booking request`, 'Dismiss', NotificationType.Error);
        return;
      }

      message.actionable = false;

      this.triggerParentReservationStatusUpdate(message, res);
    });
  }

  acceptAlteration(message) {
    this.acceptLoading = true;
    this.threadService.acceptAlteration(message.uuid).subscribe((res) => {
      if (res.error) {
        this.acceptLoading = false;

        this.toast.open(`Error accepting alteration request`, 'Dismiss', NotificationType.Error);

        return;
      }

      this.triggerParentReservationStatusUpdate(message, res);
    });
  }

  declineAlteration(message) {
    this.rejectLoading = true;
    this.threadService.declineAlteration(message.uuid).subscribe((res) => {
      if (res.error) {
        this.rejectLoading = false;

        this.toast.open(`Error rejecting alteration request`, 'Dismiss', NotificationType.Error);
        return;
      }

      this.triggerParentReservationStatusUpdate(message, res);
    });
  }

  cancelAlteration(message) {
    this.cancelLoading = true;
    this.threadService.cancelAlteration(message.uuid).subscribe((res) => {
      if (res.error) {
        this.cancelLoading = false;

        this.toast.open(`Error cancelling alteration request`, 'Dismiss', NotificationType.Error);

        return;
      }

      this.triggerParentReservationStatusUpdate(message, res);
    });
  }
}
