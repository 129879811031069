<!-- Individually selected properties -->
<div fxLayout fxLayoutAlign="center center" fxLayoutGap="-4px">
  <div *ngIf="showIndividualAsWellAsAll || (!allProperties && properties.length !== 0)">
    <div
      class="clickable"
      fxLayout
      [matTooltip]="tooltip"
      (click)="$event.preventDefault(); $event.stopPropagation(); openPropertiesModal()">
      <ng-container *ngFor="let property of properties; let i = index">
        <img class="property__image" [style.left.px]="i * -7" *ngIf="i < 3" [src]="property.picture" />
      </ng-container>
      <span *ngIf="overrideLength > 3" class="property__more">+{{ overrideLength - 3 }}</span>
    </div>
  </div>

  <!-- All properties -->
  <div
    *ngIf="allProperties"
    class="properties__all clickable"
    (click)="$event.preventDefault(); $event.stopPropagation()">
    {{ labelForAllProperties }}
  </div>

  <!-- No properties -->
  <div
    *ngIf="!allProperties && properties.length === 0"
    class="properties__none"
    (click)="$event.preventDefault(); $event.stopPropagation()">
    None
  </div>
</div>
