<nav class="device-subnav" fxLayout="column">
  <div>
    <h4
      [routerLink]="demo ? ['/demo/devices'] : ['/devices']"
      routerLinkActive="raspberry-darker rla"
      [routerLinkActiveOptions]="{ exact: true }"
      class="flex items-center gap-2">
      <sbnb-icon icon="heart" [size]="20" class="shrink-0 text-smoke-darker"></sbnb-icon>
      <span>Dashboard</span>
    </h4>
  </div>

  <div>
    <h4
      [routerLink]="['/devices/thermostat-presets']"
      routerLinkActive="raspberry-darker rla"
      class="flex items-center gap-2">
      <sbnb-icon icon="smart-house-temperature" class="text-smoke-darker"></sbnb-icon>
      <span>Thermostat Presets</span>
    </h4>
    <h4 [routerLink]="['/devices/setup']" routerLinkActive="raspberry-darker rla" class="flex items-center gap-2">
      <img src="/assets/iconography/settings.svg" onload="SVGInject(this)" width="20" height="20" alt="Setup icon" />
      <span>Connect Devices</span>
    </h4>
    <h4
      *ngIf="!demo && (hasWritePermission$ | async)"
      [routerLink]="['/devices/settings']"
      routerLinkActive="raspberry-darker rla"
      class="flex items-center gap-2">
      <sbnb-icon icon="cog" class="text-smoke-darker"></sbnb-icon>
      <span>Settings</span>
    </h4>
  </div>
</nav>
