import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogGuestVettingExtendedDetailsComponent } from '@app/shared/components/dialog-guest-vetting-extended-details/dialog-guest-vetting-extended-details.component';
import { AutohostBasicDetails, Reservation } from '@app/shared/interfaces';
import { GuestVettingOption } from '@app/shared/interfaces/lib/guest-vetting.interface';
import { SegmentIoService, SegmentEvent } from '@app/shared/services/segmentIo/segment-io.service';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum Mode {
  adhoc = 'adhoc',
  byo_autohost = 'byo_autohost',
  direct_premium = 'direct_premium',
  hidden = 'hidden',
}
@Injectable({ providedIn: 'root' })
export class GuestVettingService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private segmentIoService: SegmentIoService
  ) {
  }

  fetchBasicAutohostDataForReservation(reservationUuid: Reservation['uuid']): Observable<AutohostBasicDetails> {
    return this.http
      .get<{ data: AutohostBasicDetails }>(`${environment.apiUrl}/reservations/${reservationUuid}/guest-verification`)
      .pipe(map((response) => response.data));
  }

  fetchVettingOptions(): Observable<Array<GuestVettingOption>> {
    return this.http.get<{ data: GuestVettingOption[] }>(`${environment.apiUrl}/guest-verification/checks`).pipe(
      map((response) => response.data),
      catchError(() => {
        return of([]);
      })
    );
  }

  initiateVetting(reservationUuid: Reservation['uuid'], checks: string[], message: string): Observable<boolean> {
    return this.http
      .post(`${environment.apiUrl}/reservations/${reservationUuid}/guest-verification`, {
        checks,
        message,
      })
      .pipe(map(() => true));
  }

  allowCheckInForAdhoc(reservationUuid: Reservation['uuid']): Observable<boolean> {
    return this.http
      .post(`${environment.apiUrl}/reservations/${reservationUuid}/guest-verification/approve`, {
        // not currently used
        note: null,
      })
      .pipe(map(() => true));
  }

  /**
   * Helper methods
   */

  public openVettingDetails({
    reservation,
    autohostBasicDetails,
  }: {
    reservation: Reservation;
    autohostBasicDetails: AutohostBasicDetails;
  }) {
    this.segmentIoService.track(SegmentEvent.GuestVerificationDetailsOpened, {});
    const mode = this.determineMode(autohostBasicDetails, reservation);
    const { id, source } = autohostBasicDetails;
    if (!id) {
      return;
    }

    // For BYO, we just open the autohost portal directly
    if (mode === Mode.byo_autohost) {
      const autohostPortalBaseUrl = environment.autohostPortalUrl;
      window.open(`${autohostPortalBaseUrl}/${reservation.code}`, '_blank');
      return;
    }


    // For everyone else, we open a modal
    return this.dialog.open(DialogGuestVettingExtendedDetailsComponent, {
      width: '720px',
      maxHeight: '100%',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass:'overflow-scroll',
      data: {
        reservationUuid: reservation.uuid,
        source,
      },
    });
  }

  public determineMode(autohost: AutohostBasicDetails, reservation: Reservation): Mode {
    const autohostSource = autohost?.source;
    const autohostId = autohost?.id;

    if (autohostSource === 'integration' && autohost.status) {
      return Mode.byo_autohost;
    }

    if (autohostSource === 'direct') {
      return autohost.id ? Mode.direct_premium : Mode.hidden;
    }

    if (autohostSource !== 'adhoc') {
      return Mode.hidden;
    }

    //If we have don't have an id and we cant initialise then there is nothing to show!
    if (!autohostId && !reservation.supports.ad_hoc_verification) {
      return Mode.hidden;
    }

    return Mode.adhoc;
  }
}
