import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { config } from '@app/core/app-config';
import {
  GuestPortalTaskAction,
  GuestPortalTaskActionMetadata,
  GuestPortalTaskMetadata,
  GuestPortalTaskStatus,
  GuestPortalTaskStatusMetadata,
  GuestPortalTasks,
} from '@app/shared/interfaces/lib/guest-verification.interface';
import { Observable, map } from 'rxjs';

export enum Provider {
  GVS = 'gvs',
  AUTOHOST = 'autohost',
}

export enum VerificationRatingStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  REVIEW = 'review',
  SUSPECTED = 'suspected',
}

export interface Guest {
  full_name: string;
  email: string;
  phone: string;
  address: string;
}

export interface Identification {
  document_type: string | null;
  full_name: string | null;
  address: string | null;
  birth_date: string | null;
  issue_date: string | null;
  expiry_date: string | null;
}

export interface PurposeOfStay {
  category: string | null;
  experiences: string[];
}

export interface DocumentFiles {
  document_front_image: string | null;
  document_front_video: string | null;
  document_back_image: string | null;
  document_back_video: string | null;
  selfie_image: string | null;
  selfie_video: string | null;
}

export interface VerificationData {
  provider: Provider;
  provider_data: unknown;
  status: VerificationRatingStatus;
  risk_level: 'low' | 'moderate' | 'high' | 'critical';
  risks: string[];
  guest: Guest;
  identification: Identification;
  purpose_of_stay: PurposeOfStay;
  document_files: DocumentFiles;
  assessments: {
    identity_verification?: VerificationRatingStatus;
    sex_offender_check?: VerificationRatingStatus;
  };
}

@Injectable({
  providedIn: 'root',
})
export class GuestPortalService {
  private readonly http = inject(HttpClient);
  config = config;

  guestVerification = signal<VerificationData | null>(null);

  public readonly taskMetadata: Record<GuestPortalTaskMetadata['type'], GuestPortalTaskMetadata> = {
    'guest-verification': {
      type: 'guest-verification',
      icon: 'users',
      label: 'Guest Verification',
    },
    'rental-agreement': {
      type: 'rental-agreement',
      icon: 'office-sign-document',
      label: 'Rental Agreements',
    },
    'security-deposit': {
      type: 'security-deposit',
      icon: 'accounting-coins-stack',
      label: 'Security Deposit',
    },
  };

  public readonly taskStatusMetadata: Record<GuestPortalTaskStatus, GuestPortalTaskStatusMetadata> = {
    unrequested: {
      label: 'Not Requested',
      color: 'text-night-lighter',
    },
    pending: {
      label: 'Pending',
      color: 'text-lemon-darker',
    },
    completed: {
      label: 'Completed',
      color: 'text-grass-darker',
    },
    optional: {
      label: 'Optional',
      color: 'text-night-lighter',
    },
  };

  public readonly guestVerificationStatusMetadata: Record<VerificationRatingStatus, string> = {
    pending: 'Pending',
    approved: 'Approved',
    denied: 'Denied',
    review: 'In Review',
    suspected: 'Needs Review',
  };

  public readonly guestPortalTaskActionsMetadata: Record<GuestPortalTaskAction, GuestPortalTaskActionMetadata> = {
    'request-rental-agreement': {
      label: 'Request new signature',
      icon: 'office-sign-document',
    },
  };

  public readonly risksMetadata: Record<string, string> = {
    address_missing: 'Address information is missing',
    within_50miles_to_property:
      'The guest location was detected to be within 50 miles of the listing. Local guests may be at higher risk for parties, damage, and rule violations.',
    within_30miles_to_property:
      'The guest location was detected to be within 30 miles of the listing. Local guests may be at higher risk for parties, damage, and rule violations.',
    within_10miles_to_property:
      'The guest location was detected to be within 10 miles of the listing. Local guests may be at higher risk for parties, damage, and rule violations.',
    birthdate_missing: 'Birth date information is missing',
    age_below_18: 'The guest is under 18 and is not legally eligible to book.',
    age_below_21: 'The guest is under 21, which is a higher risk group for parties, damage, and rule violations.',
    age_below_25: 'The guest is under 25, which is a higher risk group for parties, damage, and rule violations.',
    id_missing: 'ID information is missing',
    lastname_mismatch: 'Guest last name on the ID does not match the name on the reservation.',
    firstname_mismatch:
      'Guest first name on the ID does not match the name on the reservation. The guest could be using a nickname.',
    ip_address_missing: 'IP address information is missing',
    ip_address_is_server:
      "The guest's IP address is associated with a server, which can sometimes indicate an attempt to hide their location. However, it may also be due to legitimate reasons, such as using a VPN for privacy, or booking from a corporate network.",
    ip_address_is_proxy:
      'The guest is using a proxy, which can sometimes indicate an attempt to hide their real location.',
    property_missing: 'Property information is missing',
    reservation_missing: 'Reservation information is missing',
    capacity_usage_below_two_thirds: 'Guest count is below two-thirds of maximum occupancy',
    capacity_usage_below_one_thirds:
      "The guest count is unusually low compared to the property's maximum occupancy. Confirm the guest count with the guest to ensure accuracy.",
    purpose_of_stay_missing: 'Purpose of stay information is missing',
    party_experience:
      'The guest has indicated the purpose of their stay is a party. If you choose to accept this reservation, you will not receive damage protection.',
    lead_time_less_than_2days: 'This is a last minute reservation, which can indicate a higher risk for fraud.',
    lead_time_less_than_1day: 'This is a last minute reservation, which can indicate a higher risk for fraud.',
    length_of_stay_1night:
      'This is a one night reservation, which can pose a risk for parties, fraud, or unauthorized use of the property.',
    length_of_stay_1night_weekend:
      'This is a one night reservation, which can pose a higher risk for parties, fraud, or unauthorized use of the property.',
    browser_missing: 'Browser information is missing',
    browser_is_bot: "The guest's browser behavior suggests automated or bot-like activity, which may indicate fraud.",
  };

  public readonly documentTypeMetadata: Record<string, string> = {
    TYPE_DL: 'DRIVERS LICENSE',
    TYPE_PASSPORT: 'PASSPORT',
  };

  public fetchGuestPortalTasks(reservationUuid: string): Observable<GuestPortalTasks> {
    return (
      this.http
        .get<{
          data: Record<string, GuestPortalTasks[number]>;
        }>(`${config.API_URL}/reservations/${reservationUuid}/guest-portal/tasks`)
        // We're not showing the security deposit task in the guest portal yet
        .pipe(map((response) => Object.values(response.data).filter((task) => task.type !== 'security-deposit')))
    );
  }

  public fetchGuestVerificationDetails(reservationUuid: string): Observable<VerificationData | null> {
    this.guestVerification.set(null);
    return this.http
      .get<{
        data: VerificationData | null;
      }>(`${config.API_URL}/reservations/${reservationUuid}/guest-portal/verification`)
      .pipe(
        map((response) => {
          this.guestVerification.set(response.data);
          return response.data;
        })
      );
  }
}
