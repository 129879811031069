/**
 * @deprecated - Use PosthogFeatureFlag instead
 */
export enum OptimizelyFeatureFlag {
  SignupMessagingRules = 'fe_signup_messaging_rules',
  OwnerStatements = 'fe_owner_statements',
  DirectInstantBook = 'frontend_direct_instant_book',
  OnboardingBanner = 'onboarding_banner_enabled',
  BillingDisabled = 'ui_billing_disabled',
  BookingComPropertyManagment = 'booking_com_property_management',
  AirbnbNotifications = 'airbnb_notifications',
  AirbnbOpportunities = 'airbnb_opportunities',
  CalendarMaxNights = 'calendar_max_nights',
  ReviewCategoryTags = 'review_category_tags',
  CheckoutTasks = 'checkout_tasks',
}

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

/**
 * @deprecated - Use PosthogFeatureFlag instead
 */
export interface OptimizelyFeatureSwitchMeta {
  [OptimizelyFeatureFlag.SignupMessagingRules]?: {
    scenarios: {
      key: string;
      description: string;
      criteria: {
        properties: number[];
        rulesSelected: number[];
        logicalOperator: LogicalOperator;
      };
      primaryCta: number;
      secondaryCta: number | null;
    }[];
  };
}

export enum PosthogFeatureFlag {
  PaymentTerms = 'payment_terms',
  Upsells = 'upsells',
  DirectVideoHeader = 'direct-video-header',
  YearInReviewCta = 'year_in_review_cta',
  EarningReports = 'earning-reports',
  SentimentAnalysis = 'sentiment_analysis',
  SignupV2 = 'signup-v2',
  CalendarOptimisticUpdate = 'calendar-optimistic-updates',
  AppMaintenanceMode = 'app_maintenance_mode',
  MobileNavTabs = 'mobile-nav-tabs',
  OwnerStatementsAdjustments = 'os_reservation_adjustments',
  GXInboxAI = 'gx_inbox_ai',
  Copilot = 'copilot',
  AirbnbFees = 'airbnb-fees',
  AirbnbDiscounts = 'airbnb-discounts',
  AirbnbSupportImages = 'airbnb-support-images',
  CanChangeUiLanguage = 'can-change-ui-language',
  MyBusiness = 'my-business',
}
