export interface GeneratedReply {
  reply: string;
  uuid: string;
  model: string;
  prompt_version: string;
  reasoning_version?: string;
  reasoning_included?: boolean;
  using_knowledge_hub: boolean;
  knowledge_hub_fetched: boolean;
  host_reply_fetched: boolean;
  thread_status?: string;
  thread_stage?: string;
  is_prewritten?: boolean;
  bucketed_duration_500ms?: number;
  bucketed_duration_1s?: number;
  bucketed_duration_2s?: number;
}

export interface Diff {
  inserts: string;
  deletes: string;
}

export enum AIMode {
  SUGGEST = 'suggest',
  IMPROVE = 'improve',
}
