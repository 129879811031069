<header class="flex justify-between items-center mb-6">
  <h2 class="text-xl mb-0">Claim security deposit</h2>
  <sbnb-close [border]="true" (click)="close()"></sbnb-close>
</header>

<!-- Summary -->
<div class="mb-4">
  <div
    class="flex flex-col sbnbDesktop:flex-row items-start sbnbDesktop:items-center justify-between border-b border-smoke pb-4 gap-4">
    <h4 class="text-grass-darker">{{ dialogData.securityDeposit.amount.formatted_amount }} secured</h4>

    <div class="text-sm">
      Unless claimed, this amount will be released back to the guest in {{ daysUntilRelease }}
      {{ 'days' | pluralize: daysUntilRelease }}.
    </div>
  </div>
</div>

<!-- Form -->
<div class="space-y-4">
  <div class="flex items-center">
    <div class="w-1/3">
      <label class="text-sm">Charge amount</label>
    </div>
    <div class="flex-1">
      <mat-form-field class="w-1/3 nopadding">
        <span matPrefix class="prefix-bg">
          <span class="text__small" style="padding-right: 12px">
            {{ dialogData.reservation.currency | currencyCode }}
          </span>
        </span>
        <input matInput [(ngModel)]="securityDepositClaim.amount" />
      </mat-form-field>
    </div>
  </div>

  <div class="flex items-center">
    <div class="w-1/3">
      <label class="text-sm">Incident type</label>
    </div>
    <div class="flex-1">
      <mat-form-field appearance="outline" class="w-full nopadding">
        <mat-select [(ngModel)]="securityDepositClaim.incident_type">
          <mat-option value="accidental_damage">Accidental damage</mat-option>
          <mat-option value="broken_house_rule">Broken house rule</mat-option>
          <mat-option value="missing_or_stolen_items">Missing or stolen items</mat-option>
          <mat-option value="malicious_damage">Malicious damage</mat-option>
          <mat-option value="other">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="flex items-start">
    <div class="w-1/3">
      <label class="text-sm block mt-3">Incident description</label>
    </div>
    <div class="flex-1">
      <sbnb-textarea
        [minRows]="6"
        placeholder="Describe the incident"
        [(ngModel)]="securityDepositClaim.incident_description"></sbnb-textarea>
    </div>
  </div>

  <!-- <div class="flex items-start">
    <div class="w-1/3">
      <label class="text-sm pt-1">Incident evidence</label>
    </div>
    <div class="flex-1 text-sm">Uploading evidence is not yet supported.</div>
  </div> -->

  <div class="flex items-center gap-2">
    <mat-checkbox [(ngModel)]="userConfirmation" class="block">
      <span class="pl-2 block text-sm">
        I confirm these details accurately reflect damages or charges resulting from this reservation.
      </span>
    </mat-checkbox>
  </div>
</div>

<!-- Actions -->
<div class="mt-4 flex justify-end">
  <sbnb-button (clicked)="claim()" [disabled]="submitting || !userConfirmation">Charge guest</sbnb-button>
</div>
