import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TributeValue, TributeValueType } from '@app/shared/interfaces/lib/tribute.interface';
import { Editor } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/extension-bubble-menu';
import Link from '@tiptap/extension-link';
import { TextAlign } from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import Tribute from 'tributejs';
import { CustomCodeExtension } from './customcode-extension';
import { ShortCodeExtension } from './shortcode-extension';

@Component({
  standalone: false,
  selector: 'sbnb-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
  @Input() content: string;
  @Input() placeholderExtension: any;
  @Input() placeholderContent: string;
  @Input() tributeValues: TributeValue[];
  @Output() contentUpdated: EventEmitter<string> = new EventEmitter();
  @ViewChild('editorContent', { static: false }) editorContent: ElementRef;
  @ViewChild('bubbleMenu', { static: false }) bubbleMenu: ElementRef;
  public editor: Editor;

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.editor = new Editor({
      element: this.editorContent.nativeElement,
      content: this.content,
      extensions: [
        StarterKit,
        Underline,
        BubbleMenu.configure({
          element: this.bubbleMenu.nativeElement,
        }),
        Link.configure({
          openOnClick: 'whenNotEditable',
          linkOnPaste: true,
          HTMLAttributes: {
            class: 'editor-link',
            target: '_blank',
          },
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          alignments: ['left', 'center', 'right', 'justify'],
        }),
        this.placeholderExtension ? this.placeholderExtension(this.editor) : null,
        ShortCodeExtension,
        CustomCodeExtension,
      ],
      onUpdate: ({ editor }) => {
        this.content = editor.getHTML();
        if (this.placeholderContent && this.content.includes(this.placeholderContent)) {
          this.content = this.content.replace(this.placeholderContent, '');
          this.editor.commands.setContent(this.content);
        }
        this.contentUpdated.emit(this.content);
      },
      onFocus: ({ editor, event }) => {
        this.removePlaceholderContent(editor, event);
      },
      onBlur: ({ editor }) => {
        this.addPlaceholderContent(editor);
      },
    });

    this.initializeTribute();
  }

  removePlaceholderContent(editor, event) {
    setTimeout(() => {
      this.content = editor.getHTML();

      if (this.placeholderContent && this.content.includes(this.placeholderContent)) {
        this.content = this.content.replace(this.placeholderContent, '');
        this.editor.commands.setContent(this.content, false);
      }
    }, 200);
  }

  addPlaceholderContent(editor) {
    this.content = editor.getHTML();

    if ((!this.content || this.content === '<p></p>') && this.placeholderContent) {
      this.editor.commands.setContent(this.placeholderContent);
      return;
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  initializeTribute(): void {
    if (this.tributeValues && this.tributeValues.length > 0) {
      const tribute = new Tribute({
        values: this.tributeValues,
        trigger: '%',
        replaceTextSuffix: '',
        requireLeadingSpace: false,
        // menuContainer: this.editorContent.nativeElement,
        // positionMenu: false,
        selectTemplate: (item) => {
          if (item.original.type === TributeValueType.ShortCode) {
            return `<span class="shortcode" contenteditable="false" >%${item.string}%</span>`;
          }
          if (item.original.type === TributeValueType.CustomCode) {
            return `<span class="customcode" contenteditable="false" >%%${item.string}%%</span>`;
          }
        },
        menuItemTemplate: function (item: any) {
          if (item.original.type === TributeValueType.ShortCode) {
            return `<span class="shortcode" contenteditable="false">%${item.string}%</span>`;
          }
          if (item.original.type === TributeValueType.CustomCode) {
            return `<span class="customcode" contenteditable="false">%%${item.string}%%</span>`;
          }
        },
      });
      tribute.attach(this.editorContent.nativeElement.querySelector('.ProseMirror'));
    }
  }

  setTypography(type: string): void {
    switch (type) {
      case 'paragraph':
        this.editor.chain().focus().setParagraph().run();
        break;
      case 'heading1':
        this.editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case 'heading2':
        this.editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case 'heading3':
        this.editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
      case 'heading4':
        this.editor.chain().focus().toggleHeading({ level: 4 }).run();
        break;
      case 'heading5':
        this.editor.chain().focus().toggleHeading({ level: 5 }).run();
        break;
    }
  }

  getActiveTypography(): string {
    if (this.editor.isActive('heading', { level: 1 })) {
      return 'heading1';
    } else if (this.editor.isActive('heading', { level: 2 })) {
      return 'heading2';
    } else if (this.editor.isActive('heading', { level: 3 })) {
      return 'heading3';
    } else if (this.editor.isActive('heading', { level: 4 })) {
      return 'heading4';
    } else if (this.editor.isActive('heading', { level: 5 })) {
      return 'heading5';
    } else {
      return 'paragraph';
    }
  }

  toggleLink(): void {
    const previousUrl = this.editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // If the user cancels the prompt, return early
    if (url === null) {
      return;
    }

    // If the URL is empty, remove the link
    if (url === '') {
      this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // Otherwise, set the link with the provided URL
    this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }

  toggleBulletList(): void {
    this.editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList(): void {
    this.editor.chain().focus().toggleOrderedList().run();
  }

  alignText(alignment: 'left' | 'center' | 'right' | 'justify'): void {
    this.editor.chain().focus().setTextAlign(alignment).run();
  }
}
