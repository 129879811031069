import { Component, Input, OnInit } from '@angular/core';
import { Article } from '../../../modules/support-documentation/models/support-documentation';

@Component({
  standalone: false,
  selector: 'sbnb-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent implements OnInit {
  @Input() articles: Article[];
  @Input() id: string;
  @Input() title: string;
  @Input() icon: string;
  @Input() columns: number = 1;

  public rows: number = 1;
  public chunkedArticles: Article[][];

  constructor() { }

  ngOnInit(): void {
    this.rows = Math.ceil(this.articles.length / this.columns);
    this.chunkedArticles = this.chunkArticles(this.articles, this.rows);
  }

  chunkArticles(articles: Article[], chunkSize: number): Article[][] {
    const chunks = [];
    for (var i = 0, j = articles.length; i < j; i += chunkSize) {
      chunks.push(articles.slice(i, i + chunkSize));
    }
    return chunks;
  }
}
