<div class="flex flex-col-reverse" [ngClass]="{ 'md:flex-col': !alwaysReverse }">
  <ng-container *ngFor="let message of messages; trackBy: messageTrackBy">
    <div
      *ngIf="message | isChatMessage"
      [fxLayout]="message.is_guest ? 'row' : 'row-reverse'"
      fxLayoutGap="16px"
      fxLayoutGap.lt-md="0px">
      <sbnb-profile-img
        *ngIf="!message.is_additional_guest"
        class="profile-img"
        [class.profile-img--with-name]="withNameLabels && message.is_guest"
        [showSystemBadge]="message.is_automated"
        [src]="message.from.thumbnail_url"></sbnb-profile-img>

      <sbnb-profile-img-placeholder
        *ngIf="message.is_additional_guest"
        class="profile-img-placeholder"
        [class.profile-img-placeholder--with-name]="withNameLabels"
        [firstName]="message.from.first_name"
        [lastName]="message.from.last_name"></sbnb-profile-img-placeholder>

      <div
        fxLayout="column"
        fxLayoutGap="8px"
        [fxLayoutAlign]="message.is_guest ? 'start start' : 'start end'"
        fxFlex="75"
        fxFlex.lt-md="100"
        style="padding: 20px">
        <div
          *ngIf="withNameLabels && message.is_guest"
          class="text__xsmall night"
          Layout
          fxLayoutAlign="start center"
          fxLayoutGap="8px">
          {{ message.from.name }}
        </div>

        <div
          class="thread_message"
          [ngClass.lt-md]="{ mobile: true }"
          [class.thread_message-detected]="message.intents && message.intents.length > 0"
          [class.thread_message-auto]="message.is_automated"
          [class.thread_message-host]="!message.is_guest"
          [class.thread_message-guest]="message.is_guest"
          #outer>
          <div
            *ngIf="message.is_automated"
            class="thread__auto-container flex flex-row flex-wrap justify-between"
            style="gap: 6px">
            <div>
              <strong>{{ message.automation?.name }}</strong>
            </div>
            <div
              fxHide.lt-md
              class="thread__edit-container text__xsmall"
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutGap.lt-md="8px">
              <a
                *ngIf="
                  message.automation?.ruleset_id &&
                  message.automation?.name !== MessageAutomationName.SmartLockBackUpCode &&
                  message.automation?.name !== MessageAutomationName.SmartLockCodeCommunicationMessage
                "
                class="thread__edit-button flex items-center"
                style="gap: 4px"
                [routerLink]="['/gx/rule/' + message.automation?.ruleset_id]">
                <sbnb-icon icon="edit" [size]="14" class="text-rose"></sbnb-icon>
                <div class="ocean" fxFlex>Edit Rule</div>
              </a>
              <span *ngIf="!message.automation?.ruleset_id" class="ocean" fxFlex>Rule deleted</span>
              <a
                *ngIf="message.automation?.randomkey && message.automation?.type"
                class="thread__edit-button flex items-center"
                style="gap: 4px"
                [routerLink]="[
                  '/gx/log/' +
                    (message.automation.type === 'activity' ? 'activities' : message.automation.type) +
                    '/' +
                    message.automation.randomkey
                ]">
                <sbnb-icon [size]="14" icon="details" class="text-rose"></sbnb-icon>
                <div class="ocean" fxFlex>Details</div>
              </a>
            </div>
          </div>
          <div class="thread_message-auto-inner" [innerHTML]="message.message | linkify | nl2br" #inner></div>

          <div *ngIf="message.images?.length > 0">
            <img
              *ngFor="let image of message.images"
              [ngStyle.lt-md]="{ width: '50vw' }"
              class="thread__image clickable"
              [src]="image"
              (click)="openTab(image)"
              onerror="this.src='/assets/images/image-error.png'" />
          </div>

          <div
            *ngIf="message.attachments?.length > 0"
            class="message-attachments"
            fxLayout
            fxLayout.lt-md="column"
            fxLayoutGap="12px">
            <a
              *ngFor="let attachment of message.attachments"
              class="message-attachment"
              fxLayout
              fxLayoutAlign="start center"
              fxLayoutGap="8px"
              [href]="attachment.url"
              target="_blank">
              <img src="/assets/iconography/attachment.svg" alt="attachment" />
              <span>{{ attachment.name }}</span>
            </a>
          </div>
        </div>

        <sbnb-message-reactions
          *ngIf="message.reactions?.length"
          [isGuestMessage]="message.is_guest"
          [reactions]="message.reactions"></sbnb-message-reactions>

        <div fxLayout="row">
          <div class="text__xsmall night" Layout fxLayoutAlign="start center" fxLayoutGap="8px">
            <mat-spinner *ngIf="message.pending" diameter="12"></mat-spinner>
            <span *ngIf="message.failed && !message.pending">
              Failed to send.
              <span class="strong raspberry-darker clickable" (click)="resendTheMessage(message.id)">Retry</span>
            </span>
            <span class="flex items-center gap-1" *ngIf="!message.failed && !message.pending">
              <span>{{ message.created_label ? message.created_label : (message.created | timeAgo) }}</span>
              <span *ngIf="message.author && !message.ai?.auto_reply">- Sent by {{ message.author }}</span>
              <span *ngIf="message.ai?.auto_reply">- Sent by AI Auto Reply</span>
              <span *ngIf="message.ai?.auto_reply" style="height: 18px">
                <img class="chatgpt-button--icon" src="/assets/iconography/magic.svg" onload="SVGInject(this)"
                  alt="Generate a reply with AI" />
              </span>
            </span>

            <sbnb-cs-debug-item label="message_id" [value]="message.id"></sbnb-cs-debug-item>
          </div>
          <ng-container *ngIf="message.intents && message.intents?.length > 0">
            <div
              *ngFor="let intent of message.intents; let index = index"
              class="text__xxsmall thread__detected-ruleName">
              {{ intent }} {{ index === message.intents.length ? ',' : '' }}
            </div>
          </ng-container>
        </div>
        <div *ngIf="message.is_automated" class="thread_message-auto-expander">
          <img
            src="/assets/iconography/small-arrow-bottom.svg"
            (click)="toggleAutoTextContainer(outer, $event.target)"
            style="width: 24px; height: 24px" />
        </div>
      </div>
    </div>

    <div
      *ngIf="(message | isEventMessage) && message.status !== 'Pending Booking'"
      class="thread__event-container"
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayoutAlign="center center">
      <div fxFlex class="thread__event-separator"></div>
      <img src="/assets/iconography/event.svg" width="24" height="24" />
      <div>
        <div class="thread__event-title">{{ message.status }}</div>
        <sbnb-timestamp [timestamp]="message.created_at_label"></sbnb-timestamp>
      </div>
      <div fxFlex class="thread__event-separator"></div>
    </div>

    <sbnb-received-review *ngIf="message.entity_type === 'review'" [message]="message"></sbnb-received-review>
    <sbnb-send-review *ngIf="message.entity_type === 'send-review'" [message]="message"></sbnb-send-review>

    <ng-container
      *ngIf="
        (message | isReservationMessage) &&
        message.actionable === true &&
        message.payment_status !== 'capture_pending' &&
        message.payment_status !== 'cancellation_pending' &&
        !message.is_custom_quote
      ">
      <!-- Gives some space and keeps the timestamp from reversing order on mobile -->
      <div class="p-5">
        <sbnb-booking-request
          [message]="message"
          [acceptLoading]="acceptLoading"
          [rejectLoading]="rejectLoading"
          [guestVetting]="guestVettingData"
          (accept)="acceptBooking($event)"
          (reject)="rejectBooking($event)"></sbnb-booking-request>

        <sbnb-timestamp class="thread__timestamp-booking" [timestamp]="message.created_at_label"></sbnb-timestamp>
      </div>
    </ng-container>

    <!-- Pending Quote -->
    <ng-container
      *ngIf="
        (message | isReservationMessage) &&
        message.actionable === true &&
        message.payment_status !== 'capture_pending' &&
        message.payment_status !== 'cancellation_pending' &&
        message.is_custom_quote
      ">
      <sbnb-pending-quote
        [message]="message"
        [guestVetting]="guestVettingData"
        [rejectLoading]="rejectLoading"
        (reject)="rejectBooking($event)"></sbnb-pending-quote>

      <sbnb-timestamp class="thread__timestamp-booking" [timestamp]="message.created_at_label"></sbnb-timestamp>
    </ng-container>

    <ng-container *ngIf="message | isAlterationMessage">
      <div class="alteration-card" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px" id="thread_alert">
        <sbnb-reservation-icon></sbnb-reservation-icon>
        <div fxFlex.lt-md fxLayout="row" fxLayout.lt-md="column" class="thread__alteration">
          <div fxFlex="100" fxLayout="column" class="thread__alteration-content">
            <h3>
              {{ message.from === 'host' ? 'Alteration request sent to guest' : 'Alteration requested by guest' }}
            </h3>

            <div
              fxLayout
              fxLayoutAlign="start center"
              fxLayoutGap="16px"
              class="properties__container"
              [ngClass.lt-md]="{ mobile: true }">
              <img fxHide.lt-md class="properties__img" fxFlex="96px" [src]="message.listing.image" />
              <div fxFlex fxLayout="column" fxLayoutGap="0px">
                <div fxLayout fxLayoutAlign="start center">
                  <span class="strong">{{ message.listing.name }}</span>
                </div>
                <div fxLayout fxLayoutAlign="start center" fxLayoutGap="40px">
                  <div fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
                    <span class="text__small night-lighter">{{ message.listing.listing_type }}</span>
                    <span>•</span>
                    <span class="text__small night-lighter">
                      {{ message.listing.beds }} {{ 'beds' | pluralize: +message.listing.beds }}
                    </span>
                    <span>•</span>
                    <span class="text__small night-lighter">
                      {{ message.listing.baths }} {{ 'baths' | pluralize: +message.listing.baths }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="thread__alteration-card-inner" fxLayout="column">
              <div
                class="thread__alteration-card-inner__information"
                fxFlex
                fxFlex.lt-md
                fxLayout="row"
                fxLayout.lt-md="column">
                <div fxFlex="50">
                  <h4>
                    {{ (message | alterationHaveDatesChanged) ? 'New dates' : 'Dates' }}
                  </h4>
                  <div class="thread__item" fxLayout fxLayoutGap="8px" fxLayoutAlign="start start">
                    <img src="/assets/iconography/date.svg" alt="Check in icon" />
                    <div *ngIf="message | alterationHaveDatesChanged" fxLayout="column" fxLayoutGap="4px">
                      <span>
                        {{ message.proposed_state.checkInDate | date: "LLL d ''yy" }}
                        -
                        {{ message.proposed_state.checkOutDate | date: "LLL d ''yy" }}
                      </span>
                      <span class="thread__alteration-old-values">
                        {{ message.original_state.checkInDate | date: "LLL d ''yy" }}
                        -
                        {{ message.original_state.checkOutDate | date: "LLL d ''yy" }}
                      </span>
                    </div>

                    <div *ngIf="!(message | alterationHaveDatesChanged)" fxLayout="column" fxLayoutGap="4px">
                      <span>
                        {{ message.original_state.checkInDate | date: "LLL d ''yy" }}
                        -
                        {{ message.original_state.checkOutDate | date: "LLL d ''yy" }}
                      </span>
                    </div>
                  </div>

                  <h4 style="padding-top: 10px">
                    {{ (message | alterationHasNumberOfGuestsChanged) ? 'New guests' : 'Guests' }}
                  </h4>
                  <div fxLayout="row" class="thread__item">
                    <div fxFlex fxLayoutGap="8px" fxLayoutAlign="start start">
                      <sbnb-icon icon="adults" class="text-smoke-darker"></sbnb-icon>
                      <div *ngIf="message | alterationHasNumberOfGuestsChanged" fxLayout="column" fxLayoutGap="4px">
                        <span>{{ message.proposed_state | alterationDisplayGuestsNumbers }}</span>
                        <span class="thread__alteration-old-values">
                          {{ message.original_state | alterationDisplayGuestsNumbers }}
                        </span>
                      </div>

                      <div *ngIf="!(message | alterationHasNumberOfGuestsChanged)" fxLayout="column" fxLayoutGap="4px">
                        <span>{{ message.original_state | alterationDisplayGuestsNumbers }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div fxFlex="50" class="thread__alteration-payout" [ngClass.lt-md]="{ mobile: true }">
                  <h4>Payout</h4>
                  <div class="thread__item">
                    <div fxLayoutGap="14px" fxLayoutAlign="start center">
                      <span class="" fxFlex>New payout</span>
                      <span class="">
                        {{ message.proposed_state.payoutFormatted }}
                      </span>
                    </div>
                  </div>
                  <div class="thread__item">
                    <div fxLayoutGap="14px" fxLayoutAlign="start center">
                      <span class="" fxFlex>Original payout</span>
                      <span class="">
                        {{ message.original_state.payoutFormatted }}
                      </span>
                    </div>
                  </div>
                  <div class="thread__item thread__alteration-price-difference" [ngClass.lt-md]="{ mobile: true }">
                    <div fxLayoutGap="14px" fxLayoutAlign="start center">
                      <span class="strong" fxFlex>Price difference</span>
                      <span class="strong">
                        {{ message.price_difference }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutGap="16px"
                class="thread__alteration-buttons-container"
                *ngIf="message.from === 'guest'">
                <button
                  (click)="acceptAlteration(message)"
                  fxFlex
                  [disabled]="acceptLoading || rejectLoading"
                  class="thread__alteration-confirm-btn"
                  type="submit"
                  mat-flat-button>
                  Accept
                  <mat-spinner *ngIf="acceptLoading" class="button__spinner" diameter="30"></mat-spinner>
                </button>

                <button
                  (click)="declineAlteration(message)"
                  class="button__secondary"
                  fxFlex
                  [disabled]="acceptLoading || rejectLoading"
                  type="submit"
                  mat-flat-button>
                  Decline
                  <mat-spinner *ngIf="rejectLoading" class="button__spinner" diameter="30"></mat-spinner>
                </button>
              </div>

              <div
                fxLayout="row"
                fxLayoutGap="16px"
                class="thread__alteration-buttons-container"
                *ngIf="message.from === 'host'">
                <button
                  (click)="cancelAlteration(message)"
                  fxFlex
                  [disabled]="cancelLoading"
                  class="button__secondary"
                  type="submit"
                  mat-flat-button>
                  Cancel request
                  <mat-spinner *ngIf="cancelLoading" class="button__spinner" diameter="30"></mat-spinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <sbnb-timestamp class="thread__timestamp-booking" [timestamp]="message.created_at_label"></sbnb-timestamp>
    </ng-container>
  </ng-container>
</div>
