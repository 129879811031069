<h3>Save Segment</h3>
<p class="text__small help__text">This segment will be saved to the sidebar for easy access in the future</p>

<mat-form-field class="fw">
  <input matInput placeholder="Segment name" [(ngModel)]="segmentName" (keyup.enter)="okClicked()" />
</mat-form-field>

<div fxLayout="row" fxLayoutGap="16px">
  <button fxFlex [disabled]="loading" type="submit" mat-flat-button class="button__text" (click)="cancelClicked()">
    Cancel
  </button>
  <button fxFlex [disabled]="loading" type="submit" mat-flat-button (click)="okClicked()">
    Save
    <mat-spinner *ngIf="loading" class="button__spinner" diameter="30"></mat-spinner>
  </button>
</div>
