import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-financial-section',
  templateUrl: './financial-section.component.html',
  styleUrls: ['./financial-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialSectionComponent implements OnInit {
  @Input() public items: any;
  public show = 8;
  constructor() { }

  ngOnInit(): void { }
}
