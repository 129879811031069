import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-support-documentation-icon',
  templateUrl: './support-documentation-icon.component.html',
  styleUrls: ['./support-documentation-icon.component.scss'],
})
export class SupportDocumentationIconComponent implements OnInit {
  @Input() icon: string = 'default';

  constructor() { }

  ngOnInit(): void { }
}
