import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-icon-smartbnb',
  templateUrl: './icon-smartbnb.component.html',
  styleUrls: ['./icon-smartbnb.component.scss'],
})
export class IconSmartbnbComponent implements OnInit {
  @Input() color = '#ed3c6a';
  @Input() height = '16px';
  @Input() width = '13px';

  constructor() { }

  ngOnInit(): void { }
}
