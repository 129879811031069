import { Component, Input, OnInit } from '@angular/core';
import { Channel } from '@app/shared/models/channel';

@Component({
  standalone: false,
  selector: 'sbnb-icon-platform',
  templateUrl: './icon-platform.component.html',
})
export class IconPlatformComponent implements OnInit {
  @Input() height = '14px';
  @Input() width = '14px';
  @Input() platform: Channel;
  @Input() cssClass: string;

  Channel = Channel;

  constructor() { }

  ngOnInit(): void { }
}
