import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'sbnb-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleButtonsComponent,
    },
  ],
})
export class ToggleButtonsComponent implements OnInit, ControlValueAccessor {
  @Input() size = 24;
  @Input() muted = false;

  public value$ = new BehaviorSubject<boolean | null>(null);
  public touched = false;
  public disabled = false;

  constructor() { }

  ngOnInit(): void { }

  valChanged(val: boolean) {
    this.value$.next(val);
    this.onChange(val);
    this.markAsTouched();
  }

  // ControlValueAccessor methods
  onChange = (value: boolean) => { };

  onTouched = () => { };

  writeValue(value?: boolean | null | undefined): void {
    this.value$.next(value);
  }

  registerOnChange(onChange) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
