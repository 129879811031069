<section fxLayoutAlign="end center">
  <sbnb-close [border]="true" (click)="dialogRef.close()"></sbnb-close>
</section>
<sbnb-loading-spinner *ngIf="!alteration"></sbnb-loading-spinner>

<section fxFlexFill fxLayout fxLayoutAlign="center center" *ngIf="alteration && stage === 'initiate'">
  <div class="initiated-by-container fs-dialog-contents">
    <h2 class="nomargin">Did the guest request this alteration?</h2>
    <p>
      This will determine how much will be refunded to the guest if the alteration reduces the overall cost of the
      reservation.
    </p>

    <div fxLayout="column" fxLayoutGap="24px" class="selection-container">
      <div fxLayout fxLayoutGap="16px">
        <div
          fxFlex
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="12px"
          class="initiated-by-selector"
          [class.selected]="initiatedBy === 'guest'"
          (click)="initiatedBy = 'guest'">
          <h3>Yes</h3>
          <span class="text__small">The guest would like to alter this reservation.</span>
        </div>

        <div
          fxFlex
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="12px"
          class="initiated-by-selector"
          [class.selected]="initiatedBy === 'host'"
          (click)="initiatedBy = 'host'">
          <h3>No</h3>
          <span class="text__small">The host would like to alter this reservation.</span>
        </div>
      </div>

      <div fxLayoutAlign="end center">
        <sbnb-button label="Continue" [disabled]="initiatedBy === null" (clicked)="stage = 'quote'"></sbnb-button>
      </div>
    </div>
  </div>
</section>

<section
  class="alteration-quote"
  fxFlexFill
  fxLayout
  fxLayoutAlign="center center"
  *ngIf="alteration && stage === 'quote'">
  <div class="fs-dialog-contents" style="margin: auto">
    <h2>
      {{ (alteration | alterationIsAirbnb) ? 'Send an Alteration Request' : 'Alter reservation' }}
    </h2>

    <section fxLayout="column" fxLayoutGap="24px">
      <div>
        <!-- Start property -->
        <div [class.disabled]="!(alteration | alterationIsAirbnb)">
          <label class="form-label">Property</label>
          <sbnb-property-select-dropdown
            *ngIf="properties?.length > 0"
            [selectedPropertyId]="propertyId"
            [properties]="properties"
            (selectedPropertyChanged)="propertyChanged($event)"></sbnb-property-select-dropdown>
        </div>

        <div *ngIf="propertyHasChanged" class="text__small night-lighter">Original: {{ originalPropertyName }}</div>
      </div>
      <!-- End property -->

      <div fxLayout="column" fxLayoutGap="6px">
        <label class="form-label" for="email">Check-in / Check-out</label>
        <div class="date-picker-container">
          <sbnb-date-picker
            *ngIf="reservations"
            [rangeSelect]="true"
            [initialDateRange]="[alteration.original_state.checkInDate, alteration.original_state.checkOutDate]"
            [reservations]="reservations"
            (datesSelected)="datesSelected($event)"></sbnb-date-picker>
        </div>

        <div *ngIf="alteration | alterationHaveDatesChanged" class="text__small night-lighter text-center">
          Original:
          {{ alteration.original_state.checkInDate | date: 'fullDate' }} -
          {{ alteration.original_state.checkOutDate | date: 'fullDate' }} ({{
            alteration.original_state.checkInDate | numNights: alteration.original_state.checkOutDate
          }}
          night stay)
        </div>
      </div>

      <div
        *ngIf="alteration | alterationIsAirbnb"
        fxLayout
        fxLayout.lt-md="column"
        fxLayoutGap="24px"
        fxLayoutGap.lt-md="0">
        <div fxFlex fxLayout="column" fxLayoutGap="4px">
          <label class="form-label" for="number">New guests</label>

          <mat-form-field class="fw">
            <input
              [disabled]="true"
              name="number"
              [value]="alteration.proposed_state | alterationDisplayGuestsNumbers"
              matInput
              (click)="guestBreakdownExpanded = true" />
            <mat-hint *ngIf="alteration | alterationHasNumberOfGuestsChanged">
              Original:
              {{ alteration.original_state | alterationDisplayGuestsNumbers }}
            </mat-hint>
          </mat-form-field>

          <span
            *ngIf="!guestBreakdownExpanded"
            class="grass-darker text__xsmall clickable"
            style="position: relative; top: -20px"
            (click)="guestBreakdownExpanded = true">
            [+] Guests breakdown
          </span>
        </div>

        <div fxFlex fxLayout="column" fxLayoutGap="4px">
          <label class="form-label" for="name">Accommodation cost (optional)</label>

          <mat-form-field class="fw">
            <input
              name="name"
              matInput
              [(ngModel)]="proposedNewAccommodationPrice"
              (ngModelChange)="updateNewAccommodationPrice($event)" />
            <span matPrefix>{{ alteration.currency }}&nbsp;</span>
            <mat-hint>
              Original:
              {{ alteration.original_state.accommodationPriceFormatted }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div [hidden]="!guestBreakdownExpanded || !canEditGuests()">
        <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="column" fxLayoutGap.lt-md="0">
          <div fxFlex fxLayout="column" fxLayoutGap="4px">
            <label class="form-label" for="adults">Adults</label>

            <mat-form-field class="fw">
              <input
                name="adults"
                [(ngModel)]="alteration.proposed_state.adults"
                (ngModelChange)="updateGuestCount()"
                type="number"
                matInput
                min="1"
                [max]="alteration.listing.guest_capacity - alteration.proposed_state.children" />
              <mat-hint *ngIf="alteration.proposed_state.adults !== alteration.original_state.adults">
                Original: {{ alteration.original_state.adults }}
              </mat-hint>
            </mat-form-field>
          </div>

          <div fxFlex fxLayout="column" fxLayoutGap="4px">
            <label class="form-label" for="children">Children</label>

            <mat-form-field class="fw">
              <input
                name="children"
                [(ngModel)]="alteration.proposed_state.children"
                (ngModelChange)="updateGuestCount()"
                type="number"
                matInput
                [max]="alteration.listing.guest_capacity - alteration.proposed_state.adults" />
              <mat-hint *ngIf="alteration.proposed_state.children !== alteration.original_state.children">
                Original: {{ alteration.original_state.children }}
              </mat-hint>
            </mat-form-field>
          </div>

          <div fxFlex fxLayout="column" fxLayoutGap="4px">
            <label class="form-label" for="infants">Infants</label>

            <mat-form-field class="fw">
              <input
                name="infants"
                [(ngModel)]="alteration.proposed_state.infants"
                (ngModelChange)="updateGuestCount()"
                type="number"
                matInput
                max="5" />
              <mat-hint *ngIf="alteration.proposed_state.infants !== alteration.original_state.infants">
                Original: {{ alteration.original_state.infants }}
              </mat-hint>
            </mat-form-field>
          </div>

          <div fxFlex fxLayout="column" fxLayoutGap="4px">
            <label class="form-label" for="pets">Pets</label>

            <mat-form-field class="fw">
              <input
                name="pets"
                [(ngModel)]="alteration.proposed_state.pets"
                (ngModelChange)="updateGuestCount()"
                type="number"
                matInput
                max="5" />
              <mat-hint *ngIf="alteration.proposed_state.pets !== alteration.original_state.pets">
                Original: {{ alteration.original_state.pets }}
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="
            alteration.proposed_state.adults + alteration.proposed_state.children > alteration.listing.guest_capacity
          "
          style="padding-bottom: 12px; padding-top: 12px; font-size: 14px; text-align: center">
          This property only allows a maximum of
          {{ alteration.listing.guest_capacity }} guests, not including infants.
        </div>
      </div>

      <div *ngIf="canEditAccommodationPrice()">
        <div class="mt-6">
          <div fxLayout="column" fxLayoutGap="4px">
            <label class="form-label" for="price">Price</label>
            <mat-form-field class="fw">
              <input
                name="price"
                [(ngModel)]="proposedNewAccommodationPrice"
                (ngModelChange)="updateNewAccommodationPrice()"
                type="text"
                inputmode="decimal"
                matInput
                [min]="alteration.original_state.accommodationPrice"
                [value]="alteration.original_state.accommodationPrice" />
              <mat-hint>Original: {{ alteration.original_state.accommodationPriceFormatted }}</mat-hint>
            </mat-form-field>
            <mat-error *ngIf="proposedNewAccommodationPrice < +alteration.original_state.accommodationPrice">
              Price cannot be lower than the original accommodation price
            </mat-error>
          </div>
        </div>
      </div>
    </section>

    <div *ngIf="fetchingQuote" class="payout-skeleton">
      <sbnb-loading-spinner></sbnb-loading-spinner>
    </div>

    <section *ngIf="!fetchingQuote">
      <div
        *ngIf="alteration | alterationIsAirbnb"
        fxLayout="column"
        fxLayoutGap="24px"
        class=""
        style="border-top: 1px solid #ccc; margin-top: 24px; margin-bottom: 32px; padding-top: 24px">
        <h4>Payout</h4>
        <div class="thread__item">
          <div class="flex items-center gap-3">
            <span class="flex-1">New payout</span>
            <span>
              {{ alteration.proposed_state.payoutFormatted }}
            </span>
          </div>
        </div>
        <div class="thread__item">
          <div class="flex items-center gap-3">
            <span class="flex-1">Original payout</span>
            <span>
              {{ alteration.original_state.payoutFormatted }}
            </span>
          </div>
        </div>
        <div class="thread__item thread__alteration-price-difference" [ngClass.lt-md]="{ mobile: true }">
          <div class="flex items-center gap-3">
            <span class="strong" fxFlex>Price difference</span>
            <span class="strong">
              {{ alteration.price_difference }}
            </span>
          </div>
        </div>
      </div>

      <div
        *ngIf="!(alteration | alterationIsAirbnb)"
        fxLayout="column"
        fxLayoutGap="24px"
        class=""
        style="border-top: 1px solid #ccc; margin-top: 24px; margin-bottom: 32px; padding-top: 24px">
        <h4>Reservation total</h4>
        <div class="thread__item">
          <div class="flex items-center gap-3">
            <span class="flex-1">New reservation total for the guest</span>
            <span>
              {{ alteration.proposed_state.totalPriceFormatted }}
            </span>
          </div>
        </div>
        <div class="thread__item">
          <div class="flex items-center gap-3">
            <span class="flex-1">Original reservation total for the guest</span>
            <span>
              {{ alteration.original_state.totalPriceFormatted }}
            </span>
          </div>
        </div>
        <div class="thread__item" *ngIf="alteration.remaining_payment_terms_total">
          <div class="flex items-center gap-3">
            <span class="flex-1">Remaining scheduled payment terms</span>
            <span>
              {{ alteration.remaining_payment_terms_total }}
            </span>
          </div>
        </div>
        <div class="thread__item thread__alteration-price-difference" [ngClass.lt-md]="{ mobile: true }">
          <div class="flex items-center gap-3">
            <span class="strong" fxFlex>
              {{
                alteration.price_difference.indexOf('-') === 0
                  ? 'Amount to be refunded to the guest'
                  : 'New amount to be collected from the guest'
              }}
            </span>
            <ng-template #priceDisplay>
              <span class="strong">{{ alteration.price_difference }}</span>
            </ng-template>

            <mat-form-field
              class="nopadding"
              style="width: 180px"
              *ngIf="
                initiatedBy === 'guest' &&
                  alteration.price_difference.indexOf('-') === 0 &&
                  alteration.payment_provider !== 'adyen';
                else priceDisplay
              ">
              <input
                matInput
                class="text-right amount-input"
                type="number"
                autocomplete="off"
                inputmode="decimal"
                placeholder="0 - {{ alteration.price_difference.substring(1) }}"
                [(ngModel)]="amountToRefund" />
            </mat-form-field>
          </div>
        </div>
        <sbnb-alert-card
          context="warning"
          *ngIf="
            alteration?.proposed_state?.remainingPaymentTermsTotal > 0 &&
            (alteration.original_state.checkInDate !== alteration.proposed_state.checkInDate ||
              alteration.price_difference.indexOf('-') === 0)
          "
          class="gap-2">
          <sbnb-icon icon="warning" class="warning-icon w-5 flex-shrink-0"></sbnb-icon>
          <div>
            This reservation has scheduled payments. Altering the reservation will cancel scheduled payments and collect
            the remaining balance now.
          </div>
        </sbnb-alert-card>
      </div>

      <div fxLayoutAlign="end center">
        <sbnb-button
          [loading]="saving"
          [disabled]="
            !alteration.proposed_state.checkInDate ||
            !alteration.proposed_state.checkOutDate ||
            !alteration.proposed_state.adults ||
            alteration.proposed_state.adults + alteration.proposed_state.children > alteration.listing.guest_capacity ||
            !(
              propertyHasChanged ||
              (alteration | alterationHaveDatesChanged) ||
              (alteration | alterationHasNumberOfGuestsChanged) ||
              proposedNewAccommodationPrice !== undefined
            )
          "
          label="Continue"
          (clicked)="stage = 'review'"></sbnb-button>
      </div>
    </section>
  </div>
</section>

<section fxFlexFill fxLayout fxLayoutAlign="center center" *ngIf="alteration && stage === 'review'">
  <div class="initiated-by-container fs-dialog-contents">
    <h2 class="nomargin">Review & confirm</h2>

    <ul>
      <li>
        The reservation for {{ alteration.guest.name }} will be altered.
        <span *ngIf="!(alteration | alterationIsAirbnb)">
          This alteration is at the request of the {{ initiatedBy }}.
        </span>
      </li>

      <li
        *ngIf="
          alteration.original_state.checkInDate !== alteration.proposed_state.checkInDate ||
          alteration.original_state.checkOutDate !== alteration.proposed_state.checkOutDate
        ">
        The guests will now check-in on
        {{ alteration.proposed_state.checkInDate | date: 'fullDate' }} and check-out on
        {{ alteration.proposed_state.checkOutDate | date: 'fullDate' }}.
      </li>

      <li
        *ngIf="
          alteration.original_state.adults !== alteration.proposed_state.adults ||
          alteration.original_state.children !== alteration.proposed_state.children ||
          alteration.original_state.infants !== alteration.proposed_state.infants
        ">
        The number of guests will be amended to:
        {{ alteration.proposed_state.adults }} adults, {{ alteration.proposed_state.children }} children, and
        {{ alteration.proposed_state.infants }}
        infants.
      </li>

      <li *ngIf="alteration.original_state.pets !== alteration.proposed_state.pets">
        The number of pets has changed to {{ alteration.proposed_state.pets }}.
      </li>

      <li *ngIf="amountToRefund === 0; else amountBlock">
        The guest will not be charged or refunded for this alteration.
      </li>

      <ng-template #amountBlock>
        <li
          *ngIf="
            alteration.payment_enabled &&
            !(alteration | alterationIsAirbnb) &&
            alteration.price_difference.indexOf('-') === 0
          ">
          {{
            amountToRefund ? (amountToRefund | currency: alteration.currency) : alteration.price_difference.substring(1)
          }}
          will be refunded immediately to the guest. Hospitable will automatically send this amount to the guest on your
          behalf.
        </li>

        <li
          *ngIf="
            alteration.payment_enabled &&
            !(alteration | alterationIsAirbnb) &&
            alteration.price_difference.indexOf('-') !== 0
          ">
          {{ alteration.price_difference }} will be charged immediately to the guest. Hospitable will automatically
          collect this amount from the guest on your behalf using the same payment method that they used to make the
          reservation.
        </li>

        <li *ngIf="alteration.payment_enabled">
          A receipt for the transaction will be sent to the guest via email to
          {{ alteration.guest.email }}
        </li>

        <li *ngIf="alteration.payment_enabled">
          If we are unable to process the funds, the reservation will remain unaltered.
        </li>

        <li *ngIf="alteration.platform === 'booking'">
          It may take a few minutes for the changes to be reflected on the reservation.
        </li>
      </ng-template>
    </ul>

    <div *ngIf="alteration.platform === 'booking'">
      <hospitable-checkbox [(ngModel)]="bookingAlterationConfirmed" data-test="booking-alteration-confirm">
        I understand that this alteration will be
        <strong>immediately effective</strong>
        .
      </hospitable-checkbox>
    </div>

    <div class="mt-12" fxLayoutAlign="end center" fxLayoutGap="12px">
      <sbnb-button label="Do not alter" [disabled]="saving" type="secondary" mat-dialog-close></sbnb-button>

      <sbnb-button
        [loading]="saving"
        label="Confirm the alteration"
        [disabled]="(alteration.platform === 'booking' && !bookingAlterationConfirmed) || saving"
        (clicked)="sendAlterationRequest()"></sbnb-button>
    </div>
  </div>
</section>
