import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  format,
  isBefore,
  isSameMonth,
  parse,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';

export interface CalendarDate {
  date: Date;
  selected?: boolean;
  this_thread?: boolean;
  start?: boolean;
  end?: boolean;
}

@Component({
  standalone: false,
  selector: 'sbnb-mini-calendar',
  templateUrl: './mini-calendar.component.html',
  styleUrls: ['./mini-calendar.component.scss'],
})
export class MiniCalendarComponent implements OnInit, OnChanges {
  currentDate = new Date();
  dayNames = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  weeks: CalendarDate[][] = [];

  @Input() startDate: Date = new Date();
  @Input() events: {
    start: Date;
    end: Date;
    this_thread: boolean;
    uuid?: number;
  }[] = [];
  @Input() selectMode = false;

  @Input() showTodayButton = false;

  @Input() selectInPastAllowed = true;
  @Input() selectInFutureAllowed = true;

  @Output() dateSelected: EventEmitter<Date[]> = new EventEmitter();

  // Used in select mode
  selectedStartDate: Date;
  selectedEndDate: Date;

  constructor() {}

  ngOnInit(): void {
    this.currentDate = this.startDate;
    this.generateCalendar();
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks: CalendarDate[][] = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;

    if (this.events.length > 0) {
      this.checkEvents();
    }
  }

  checkEvents(): void {
    this.events.forEach((event) => {
      event.start = parse(event.start);
      event.end = parse(event.end);

      let numDays = Math.abs(differenceInCalendarDays(event.start, event.end));

      const dayArray = [];
      const primaryEventArray = [];

      if (this.selectMode) {
        numDays++;
      }

      for (let i = 0; i < numDays; i++) {
        if (event.this_thread) {
          primaryEventArray.push(format(addDays(event.start, i), 'YYYY-MM-DD'));
        } else {
          dayArray.push(format(addDays(event.start, i), 'YYYY-MM-DD'));
        }
      }

      this.weeks.forEach((week) => {
        week.map((day) => {
          if (dayArray.includes(format(day.date, 'YYYY-MM-DD'))) {
            day.selected = true;
          }

          if (primaryEventArray.includes(format(day.date, 'YYYY-MM-DD'))) {
            day.this_thread = true;
          }

          if (format(day.date, 'YYYY-MM-DD') === format(event.start, 'YYYY-MM-DD')) {
            day.start = true;
          }

          let ender;
          if (this.selectMode) {
            ender = event.end;
          } else {
            ender = subDays(event.end, 1);
          }
          if (format(day.date, 'YYYY-MM-DD') === format(ender, 'YYYY-MM-DD')) {
            day.end = true;
          }

          return day;
        });
      });
    });
  }

  formatDate(date: Date, formatStr: string) {
    return format(date, formatStr);
  }

  fillDates(currentDate: Date): CalendarDate[] {
    const dayOfWeekFirstOfMonth = startOfMonth(currentDate).getDay();
    const firstDayOfGrid = subDays(startOfMonth(currentDate), dayOfWeekFirstOfMonth - 1);

    const dates = [];
    for (let i = 0; i < 42; i++) {
      const day = addDays(firstDayOfGrid, i);

      dates.push({
        today: false,
        selected: false,
        date: day,
      });
    }

    return dates;
  }

  isDayInSelectedMonth(date: Date): boolean {
    return isSameMonth(this.currentDate, date);
  }

  prevMonth(): void {
    this.currentDate = subMonths(this.currentDate, 1);
    this.generateCalendar();
  }

  nextMonth(): void {
    this.currentDate = addMonths(this.currentDate, 1);
    this.generateCalendar();
  }

  selectDateClick(date: Date = new Date()) {
    if (!this.selectMode) {
      return;
    }

    if (this.selectedStartDate && isBefore(date, this.selectedStartDate)) {
      this.selectedStartDate = date;
      this.selectedEndDate = null;
    } else if (!this.selectedStartDate || this.selectedEndDate) {
      this.selectedStartDate = date;
      this.selectedEndDate = null;
    } else {
      this.selectedEndDate = date;
    }

    this.events = []; // release the events

    this.events = [
      {
        start: this.selectedStartDate,
        end: this.selectedEndDate ? this.selectedEndDate : this.selectedStartDate,
        this_thread: true,
      },
    ];

    this.dateSelected.emit([
      this.selectedStartDate,
      this.selectedEndDate ? this.selectedEndDate : this.selectedStartDate,
    ]);

    this.generateCalendar();
  }
}
