import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { User } from '@app/shared/interfaces';
import { Language } from '@app/shared/models/language';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';
import { ThreadService } from '@app/shared/services/thread/thread.service';
import { formatToTimeZone } from 'date-fns-timezone/src/formatToTimeZone.js';

@Component({
  standalone: false,
  selector: 'sbnb-guest-banner',
  templateUrl: './guest-banner.component.html',
  styleUrls: ['./guest-banner.component.scss'],
})
export class GuestBannerComponent implements OnInit, OnChanges {
  @Input() thread: any;

  // Ability to change the language of the conversation
  @Input() canChangeLanguage: boolean;
  @Input() currentLanguage: Language;
  @Input() availableLanguages: Language[];

  @Input() canChangeEmail = false;

  @Output() languageChange: EventEmitter<string> = new EventEmitter();
  @Output() translationChange: EventEmitter<boolean> = new EventEmitter();
  @Output() guestNameUpdated: EventEmitter<{ firstname: string; lastname: string }> = new EventEmitter();

  user: User = this.authenticationService.getUserDetails();
  uses_24_hour_format = false;
  updatingGuestName = false;
  recentlyUpdatedGuestName = false;

  guestPictureError = true;
  mobilePlatformIconSize = '16px';
  mobilePlatformIconClass = 'inbox__guest-platform--with-photo';
  public tags$ = this.selectedReservationService.tags$;

  constructor(
    private router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly threadService: ThreadService,
    private readonly segmentIoService: SegmentIoService,
    private readonly selectedReservationService: SelectedReservationService
  ) { }

  ngOnInit() { }

  ngOnChanges() {
    if (this.user?.uses_24_hour_format) {
      this.uses_24_hour_format = true;
    }

    if (!this.thread.guest) {
      return;
    }

    // Pre-populate custom name with original name if required
    if (!this.thread.guest?.custom_first_name) {
      this.thread.guest.custom_first_name = this.thread.guest.first_name;
    }

    if (!this.thread.guest?.custom_last_name) {
      this.thread.guest.custom_last_name = this.thread.guest.last_name;
    }
  }

  guestsTime() {
    // Its possible that datefns doesn't know about the timezone, so we need to catch the error
    try {
      const format = this.uses_24_hour_format ? 'HH:mm (z)' : 'h:mma (z)';
      if (this.thread && this.thread.guest && this.thread.guest.timezone && this.thread.guest.timezone !== '0') {
        return formatToTimeZone(new Date(), format, {
          timeZone: this.thread.guest.timezone,
        });
      }

      return false;
    } catch {
      return false;
    }
  }

  goBack() {
    this.router.navigate(['/inbox/segments/default']);
  }

  languageChanged(newLanguageCode: string) {
    this.languageChange.emit(newLanguageCode);
  }

  translationsToggled(event: boolean) {
    this.thread.using_translations = event;
    this.translationChange.emit(event);
  }

  updateGuestName() {
    this.updatingGuestName = true;

    // nulling empty values for the API to clear
    if (this.thread.guest.custom_first_name === '') {
      this.thread.guest.custom_first_name = null;
    }
    if (this.thread.guest.custom_last_name === '') {
      this.thread.guest.custom_last_name = null;
    }

    this.threadService
      .updateGuestName(this.thread.uuid, this.thread.guest.custom_first_name, this.thread.guest.custom_last_name)
      .subscribe((res) => {
        if (res.success) {
          // successful update
          this.segmentIoService.track(SegmentEvent.RenamedGuest, {});
          this.updatingGuestName = false;
          this.recentlyUpdatedGuestName = true;

          this.guestNameUpdated.emit({
            firstname: this.thread.guest.custom_first_name,
            lastname: this.thread.guest.custom_last_name,
          });

          setTimeout(() => {
            this.recentlyUpdatedGuestName = false;
          }, 2000);
        }
      });
  }

  updateGuestEmail() {
    this.threadService.updateGuestEmail(this.thread.uuid, this.thread.guest.email).subscribe((res) => { });
  }
}
