import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputValidators } from '../inputs.interface';

interface FormValues {
  checkbox: boolean;
}

@Component({
  selector: 'hospitable-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit {
  @Input() checked = false;
  @Input() label: string;
  @Input() isRequired = false;
  @Input() isDisabled = false;
  @Input() name: string;
  @Input() cssClass: string;
  @Input() automationTag = 'data-cy-checkbox-input';
  @Input() ariaLabel: string;
  @Input() validators: InputValidators[] = [];
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  get value(): FormValues {
    return this.form.value;
  }

  set value(value: FormValues) {
    this.form.patchValue({ checkbox: value });
    this.onChange(value);
    this.onTouched();
  }

  get control() {
    return this.form.controls['checkbox'];
  }

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    const validatorsFn = this.validators.map((validator) => (validator && validator.fn ? validator.fn : []));

    this.form = this.formBuilder.group({
      checkbox: [
        {
          value: this.checked ? this.checked : false,
          disabled: this.isDisabled,
        },
        validatorsFn ? validatorsFn : [],
      ],
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value: FormValues) => {
        this.onChange(value.checkbox);
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  onTouched: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: FormValues) {
    if (value === null) {
      this.form.reset();
    } else {
      this.value = value;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.form.valid ? null : { valid: false };
  }

  changed(event: { checked: boolean }) {
    this.change.emit(event.checked);
  }
}
