export enum Channel {
  Airbnb = 'airbnb',
  AirbnbOfficial = 'airbnb-official',
  Booking = 'booking',
  Vrbo = 'homeaway',
  Agoda = 'agoda',
  Manual = 'manual',
  Direct = 'direct',
}

export const ChannelLabelMap = {
  [Channel.Airbnb]: 'Airbnb',
  [Channel.AirbnbOfficial]: 'Airbnb',
  [Channel.Booking]: 'Booking.com',
  [Channel.Vrbo]: 'Vrbo',
  [Channel.Agoda]: 'Agoda',
  [Channel.Manual]: 'Manual',
  [Channel.Direct]: 'Direct',
} as const;

export enum IcalChannel {
  Hopper = 'hopper.com',
  HipCamp = 'hipcamp.com',
  Houfy = 'houfy.com',
  Lodgify = 'lodgify.com',
  OwnerRez = 'ownerrez.com',
  MisterBnB = 'misterbandb.com',
  Hostfully = 'hostfully.com',
  FurnishedFinder = 'furnishedfinder.com',
  Expedia = 'expedia.com',
  Tripadvisor = 'tripadvisor.com',
}
