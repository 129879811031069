<div class="dpicker" [class.check-in-out-styling]="checkInOutStyling" [class.preventSelection]="preventSelection">
  <section>
    <div class="dpicker-prev-month-btn" fxLayoutAlign="center center" (click)="changeMonth(-1)">
      <img src="/assets/iconography/small-arrow-left.svg" />
    </div>

    <div class="dpicker-next-month-btn" fxLayoutAlign="center center" (click)="changeMonth(1)">
      <img src="/assets/iconography/small-arrow-right.svg" />
    </div>

    <div *ngFor="let month of dates" class="dpicker-month-container" [style.width.px]="cellSizePx * 7">
      <div class="dpicker-month-title">
        <span>{{ month[10] | date: 'MMM' }}</span>
        <span class="ml-1">{{ month[10] | date: 'yy' }}</span>
      </div>

      <div>
        <div *ngFor="let dow of daysOfWeek" class="dpicker-dow">{{ dow }}</div>
      </div>

      <div class="dpicker-month-dates">
        <div
          *ngFor="let date of month"
          class="dpicker-date"
          [class.dpicker-picked-date]="
            (selectedFirstDateInRange && selectedFirstDateInRange === date) ||
            (selectedDates?.length > 0 && selectedDates.indexOf(date) > -1)
          "
          [class.dpicker-hovered-date]="
            !selectedSecondDateInRange && hoveredDates?.length > 0 && hoveredDates.indexOf(date) > -1
          "
          [class.dpicker-hovered-date-final]="
            !selectedSecondDateInRange && hoveredDates?.length > 0 && hoveredDates[hoveredDates.length - 1] === date
          "
          [class.no-day]="date === null"
          [class.first-picked-date]="selectedFirstDateInRange === date && date !== null"
          [class.last-picked-date]="selectedDates?.length > 0 && selectedDates[selectedDates.length - 1] === date"
          [class.blocked-checkin]="blockedDates[date] === 'checkin'"
          [class.blocked-staying]="blockedDates[date] === 'staying'"
          [class.blocked-checkout]="blockedDates[date] === 'checkout'"
          [class.blocked-changeover]="blockedDates[date] === 'changeover'"
          [class.date-unselectable]="preventPastDatesSelection && (date | isBeforeToday)"
          [style.width.px]="cellSizePx"
          [style.height.px]="cellSizePx"
          fxLayoutAlign="center center"
          (click)="clickedDate(date)"
          (mouseover)="hoveredDate(date)">
          <span>{{ date ? (date | date: 'd') : null }}</span>
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="showDurationLabels" class="text__small night selected-labels strong" fxLayout fxLayoutGap="4px">
    <span *ngIf="selectedFirstDateInRange && selectedSecondDateInRange">
      {{ selectedFirstDateInRange ? (selectedFirstDateInRange | date) : null }}
      -
      {{ selectedSecondDateInRange ? (selectedSecondDateInRange | date) : null }}
    </span>
    <span *ngIf="selectedFirstDateInRange && selectedSecondDateInRange">
      ({{ selectedFirstDateInRange | numNights: selectedSecondDateInRange }} night stay)
    </span>
    <span>&nbsp;</span>
  </div>
</div>
