<mat-form-field class="fw slimline property__selector">
  <mat-select
    #dropdown
    [(ngModel)]="selectedPropertyId"
    (ngModelChange)="selectedPropertyChange($event)"
    placeholder="Select a property">
    <mat-select-trigger>
      <div *ngIf="selectedProperty" class="flex items-center gap-2">
        <img class="lite__property-image" [src]="selectedProperty.picture" />
        <span class="ellipsis">{{ selectedProperty.name }}</span>
      </div>
    </mat-select-trigger>

    <mat-option>
      <ngx-mat-select-search
        ngModel
        (ngModelChange)="filterMyOptions($event)"
        placeholderLabel="Search by name or address..."
        noEntriesFoundLabel="No properties found"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngIf="selectedPropertyId && showPlaceholder" [value]="null" style="color: #bbb">
      Select a property
    </mat-option>
    <ng-container *ngFor="let prop of filteredProperties">
      <mat-option [value]="prop.id">
        <div class="flex items-center gap-2">
          <img class="lite__property-image" [src]="prop.picture" />
          <span
            [matTooltip]="prop.muted || !prop.listed ? 'This property is ' + (prop.muted ? 'muted' : 'unlisted') : ''"
            [ngClass]="{ 'text-night-lighter': prop.muted || !prop.listed }">
            {{ prop.name }}
          </span>
        </div>
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
