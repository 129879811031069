import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NotificationType } from '@app/shared/interfaces';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import {
  CaughtApiError,
  SmartlockCode,
  SmartlocksService,
  SmartlockStatuses,
} from '@app/shared/services/smartlocks/smartlocks.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-thread-smartlock-codes',
  templateUrl: './thread-smartlock-codes.component.html',
  styleUrls: ['./thread-smartlock-codes.component.scss'],
})
export class ThreadSmartlockCodesComponent implements OnDestroy, OnChanges {
  SmartlockStatuses = SmartlockStatuses;
  smartlockCodes: SmartlockCode[];
  loading: boolean;
  expanded = true;
  codeProvisionRecentlyRequested = false;

  reloadSmartLockCodesTimerSubscription$: Subscription;

  @Input() reservationUuid: string;

  constructor(
    private readonly smartlocksService: SmartlocksService,
    private segmentIoService: SegmentIoService,
    private toast: ToastNotificationsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reservationUuid) {
      this.getSmartlockCodes();
    }
  }

  getSmartlockCodes() {
    // if there's not an actual reservation (like just an inquiry), don't make the request
    if (!this.reservationUuid) {
      return;
    }
    this.loading = true;
    const payload = {
      uuid: this.reservationUuid,
    };

    this.reloadSmartLockCodesTimerSubscription$ = timer(0, 60_000)
      .pipe(mergeMap(() => this.smartlocksService.getSmartlockCodesForAThread(payload)))
      .subscribe((res) => {
        if ((res as CaughtApiError)?.error) {
          this.loading = false;
          return;
        }
        this.smartlockCodes = res as SmartlockCode[];
        this.loading = false;
      });
  }

  triggerPinCodeSet(code) {
    if (this.codeProvisionRecentlyRequested) {
      return;
    }

    const payload = {
      reservation_id: code.reservation_id,
      lock_id: code.smartlock.lock_id,
      reservation_uuid: this.reservationUuid,
    };

    this.smartlocksService.triggerPinCodeSet(payload).subscribe((res) => {
      this.codeProvisionRecentlyRequested = true;
    });

    setTimeout(function () {
      this.codeProvisionRecentlyRequested = false;
    }, 600000);
  }

  trackEvent() {
    this.segmentIoService.track(SegmentEvent.ClickAddSmartLockFromReservation, {
      label: 'Smartlocks',
    });
  }

  copiedToClipboard() {
    this.toast.open(`Copied to clipboard`, 'Dismiss', NotificationType.Success, 2500);
  }

  ngOnDestroy(): void {
    if (this.reloadSmartLockCodesTimerSubscription$ && this.reloadSmartLockCodesTimerSubscription$.unsubscribe) {
      this.reloadSmartLockCodesTimerSubscription$.unsubscribe();
    }
  }
}
