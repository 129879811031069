import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AIMode } from '@app/shared/+state/AI/AI.models';
import { LoadingState } from '@app/shared/interfaces';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';

@Component({
  standalone: false,
  selector: 'sbnb-chatgpt-generate-button',
  templateUrl: './chatgpt-generate-button.component.html',
  styleUrls: ['./chatgpt-generate-button.component.scss'],
})
export class ChatgptGenerateButtonComponent implements OnInit, OnChanges {
  @Input() isDisabled: string;
  @Input() loadingState: boolean;
  @Input() mode: AIMode = AIMode.SUGGEST;
  @Output() buttonClicked: EventEmitter<AIMode> = new EventEmitter();
  @Output() disabledButtonClicked: EventEmitter<boolean> = new EventEmitter();

  LoadingState = LoadingState;
  AIMode = AIMode;
  isMobile$ = this.deviceDetectionService.isMobileViewport();

  constructor(
    private deviceDetectionService: DeviceDetectionService,
    private optimizely: OptimizelyService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void { }

  onButtonClick(isDisabled) {
    if (isDisabled) {
      this.disabledButtonClicked.emit(true);
    } else {
      this.buttonClicked.emit(this.mode);
    }
  }
}
