import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManualQuoteFee, Money } from '@app/shared/interfaces';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AlgoliaService } from '../algolia.service';

export interface ManualBooking {
  id?: string;
  platform?: string;
  reservationCode?: string; // The code entered by the user when prompted
  channel?: string;
  propertyId?: number;
  checkIn?: string; // 2021-03-28T11:00:00 - same format as the calendar uses
  checkOut?: string; // 2021-03-28T11:00:00 - same format as the calendar uses
  checkInDate?: string; // 2021-03-28 - for places where we don't need utc offset
  checkOutDate?: string; // 2021-03-28 - for places where we don't need utc offset
  guestEmail?: string;
  guestName?: string;
  guestFirstName?: string;
  guestLastName?: string;
  guestAddress?: {
    name: string;
    city: string;
    state?: string;
    country: string;
    country_code: string;
    postcode: string;
    latitude: number;
    longitude: number;
  };
  guestPhone?: string;
  note?: string;
  guestLanguage?: string;
  numGuests?: number;
  currency?: string; // USD / EUR / etc.
  total?: string;
  adults?: number;
  children?: number;
  infants?: number;
  pets?: number;
  fees?: { amount: number; type: string; label: string }[];
}

@Injectable({
  providedIn: 'root',
})
export class ManualBookingsService {
  constructor(
    private readonly http: HttpClient,
    private algoliaService: AlgoliaService
  ) {
    this.algoliaService.requestSecureAlgoliaKey().subscribe();
  }

  public fetchBooking(bookingId: string, platform = 'manual') {
    return this.http
      .get(`${environment.apiUrl}/bookings/${platform}/${bookingId}`)
      .pipe(map((res: { data: ManualBooking }) => res.data));
  }

  public createBooking(booking: ManualBooking, financials: ManualQuoteFee[]): Observable<any> {
    booking = this.addFinancialsToBooking(booking, financials);

    return this.http.post(`${environment.apiUrl}/bookings/manual`, booking);
  }

  private addFinancialsToBooking(booking: ManualBooking, financials: ManualQuoteFee[]): ManualBooking {
    if (!financials || financials.length === 0) {
      return booking;
    }

    const transformedFees = [...financials.map((fee) => ({ ...fee, amount: fee.amount.amount || 0 }))];

    booking.fees = transformedFees;

    return booking;
  }

  public updateBooking(booking: ManualBooking, financials: ManualQuoteFee[], platform: string): Observable<any> {
    booking = this.addFinancialsToBooking(booking, financials);

    return this.http.put(`${environment.apiUrl}/bookings/${platform}/${booking.id}`, booking);
  }

  public cancelBooking(booking: ManualBooking): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/bookings/${this.getApiChannel(booking)}/${booking.id}`, {});
  }

  public searchGuests(searchString: string): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/guests/search`, {
        search_key: this.algoliaService.algoliaSecureApiKey,
        query: searchString,
      })
      .pipe(
        catchError((error) => {
          // Request a new Algolia key and retry the search
          return this.algoliaService.requestSecureAlgoliaKey().pipe(
            switchMap((newKey) => {
              // Retry the search with the new key
              return this.http.post(`${environment.apiUrl}/guests/search`, {
                search_key: newKey,
                query: searchString,
              });
            })
          );
        })
      );
  }

  getQuoteForManualBooking(
    propertyId,
    checkIn,
    checkOut,
    fees: ManualQuoteFee[],
    platform: string,
    reservationCode?: string
  ): Observable<{ fees: ManualQuoteFee[]; total: Money }> {
    const transformedFees = [...fees.map((fee) => ({ ...fee, amount: fee.amount.amount || 0 }))];

    const payload = {
      propertyId,
      checkIn,
      checkOut,
      fees: transformedFees,
    };

    if (reservationCode) {
      payload['reservationCode'] = reservationCode;
    }

    return this.http
      .post<GetQuoteForManualBookingApiResponse>(`${environment.apiUrl}/bookings/${platform}/quote`, payload)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  private getApiChannel(booking: ManualBooking): string {
    return booking.channel === 'direct' ? 'direct' : 'manual';
  }
}

interface GetQuoteForManualBookingApiResponse {
  data: {
    fees: ManualQuoteFee[];
    total: Money;
  };
}
