<div class="filters__container" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="6px">
  <!-- Currently activated filters -->
  <ng-container *ngFor="let filter of activatedFilters">
    <button
      *ngIf="filter.label"
      [disabled]="filter.lockable"
      mat-flat-button
      class="filter__option-btn button__small button__text"
      [class.btn__no-value]="!filter.selectedValue && filter.type !== 'togglable'"
      id="filter-button-{{ filter.id }}"
      [matMenuTriggerFor]="filterMenu"
      #filterMenuTrigger
      (menuOpened)="fetchListMenuItems(filter)"
      (click)="filter.type === 'togglable' ? valueChangeEmit() : null">
      <img
        [src]="'/assets/iconography/filter-icons/' + filter.id + '.svg'"
        style="margin-right: 8px"
        onerror="this.src='/assets/iconography/filter-icons/default.svg'" />

      <span>{{ filter.label }}</span>
      <span *ngIf="filter.selectedValue && filter.selectedValue.label !== filter.label">
        : {{ filter.selectedValue.label }}
      </span>
      <img
        *ngIf="!filter.lockable"
        class="filter__clearable"
        src="/assets/iconography/cancel-smoke.svg"
        (click)="removeFilter(filter)" />
    </button>

    <mat-menu #filterMenu="matMenu" class="filters__cdk-menu">
      <ng-template matMenuContent *ngIf="filter.type === 'list'">
        <mat-spinner *ngIf="filter.loading" style="margin: auto" diameter="30"></mat-spinner>
        <input
          *ngIf="filter.valuesData && filter.valuesData.length >= numListOptionsForSearchBar"
          class="filters__input-list-search"
          [(ngModel)]="searchCriteria[filter.id]"
          type="text"
          placeholder="Search"
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()" />

        <span
          *ngIf="filter.valuesData?.length >= numListOptionsForSearchOnly && !searchCriteria[filter.id]"
          class="search-large-amount-helper">
          {{ this.numListOptionsForSearchOnly }}+ entries detected. Start typing to see results.
        </span>

        <div
          class="filter__list-fixed-height"
          *ngIf="
            filter.valuesData?.length < numListOptionsForSearchOnly ||
            (filter.valuesData?.length >= numListOptionsForSearchOnly && searchCriteria[filter.id])
          ">
          <ng-container *ngFor="let option of filter.valuesData | sortBy: (sortByLabel ? 'label' : '')">
            <button
              *ngIf="filtersMatchSearchCriteria(option, searchCriteria[filter.id])"
              (click)="setFilterValue(filter, option)"
              mat-menu-item
              disableRipple>
              <span fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
                <img class="filter__option-img" *ngIf="option.img" [src]="option.img" />
                <span>{{ option.label }}</span>
                <ng-container *ngIf="option.platform">
                  <sbnb-icon-airbnb *ngIf="option.platform === 'airbnb'"></sbnb-icon-airbnb>
                  <sbnb-icon-homeaway *ngIf="option.platform === 'homeaway'"></sbnb-icon-homeaway>
                </ng-container>
              </span>
            </button>
          </ng-container>
          <div
            *ngIf="filter.valuesData && filter.valuesData.length === 0 && filter.id === 'thread-listing-tags'"
            style="padding: 20px">
            You have not created any tags yet.
            <br />
            <br />
            You can do so on the
            <a [routerLink]="['/properties']">properties page</a>
          </div>
        </div>
      </ng-template>

      <ng-template matMenuContent *ngIf="filter.type === 'number'">
        <mat-radio-group
          [(ngModel)]="searchCriteria[filter.id]"
          fxLayout="column"
          class="filters__input-number-rg"
          (click)="$event.stopPropagation()">
          <mat-radio-button value="eq" (change)="setNumberValue(filter, 'eq')">Exactly</mat-radio-button>
          <div *ngIf="searchCriteria[filter.id] === 'eq'" class="filters__number-inner">
            <mat-form-field>
              <input
                type="number"
                #inputExactly
                matInput
                [value]="filter.selectedValue?.number"
                (change)="setNumberValue(filter, 'eq', $event.target.value)" />
            </mat-form-field>
          </div>

          <mat-radio-button value="gt" (change)="setNumberValue(filter, 'gt')">More Than</mat-radio-button>
          <div *ngIf="searchCriteria[filter.id] === 'gt'" class="filters__number-inner">
            <mat-form-field>
              <input
                type="number"
                #inputMoreThan
                matInput
                [value]="filter.selectedValue?.number"
                (change)="setNumberValue(filter, 'gt', $event.target.value)" />
            </mat-form-field>
          </div>

          <mat-radio-button value="lt" (change)="setNumberValue(filter, 'lt')">Less Than</mat-radio-button>
          <div *ngIf="searchCriteria[filter.id] === 'lt'" class="filters__number-inner">
            <mat-form-field>
              <input
                type="number"
                #inputLessThan
                matInput
                [value]="filter.selectedValue?.number"
                (change)="setNumberValue(filter, 'lt', $event.target.value)" />
            </mat-form-field>
          </div>
        </mat-radio-group>
        <button class="filter__btn-done">Done</button>
      </ng-template>

      <ng-template matMenuContent *ngIf="filter.type === 'date'">
        <mat-radio-group [(ngModel)]="searchCriteria[filter.id]" fxLayout="column" class="filters__input-number-rg">
          <mat-radio-button value="today" (change)="setDateValue(filter, 'today')">Today</mat-radio-button>

          <mat-radio-button value="tomorrow" (change)="setDateValue(filter, 'tomorrow')">Tomorrow</mat-radio-button>

          <mat-radio-button
            value="upcoming"
            (change)="setDateValue(filter, 'upcoming')"
            (click)="$event.stopPropagation()">
            Upcoming
          </mat-radio-button>
          <div
            *ngIf="searchCriteria[filter.id] === 'upcoming'"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="filters__number-inner"
            (click)="$event.stopPropagation()"
            (click)="$event.stopPropagation()">
            <span>in</span>
            <mat-form-field fxFlex>
              <input
                fxFlex
                type="number"
                #inputUpcoming
                matInput
                [value]="filter.selectedValue?.number"
                (change)="setDateValue(filter, 'upcoming', $event.target.value)" />
            </mat-form-field>
            <span>days</span>
          </div>

          <mat-radio-button
            value="previous"
            (change)="setDateValue(filter, 'previous')"
            (click)="$event.stopPropagation()">
            Previous
          </mat-radio-button>
          <div
            *ngIf="searchCriteria[filter.id] === 'previous'"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="filters__number-inner"
            (click)="$event.stopPropagation()">
            <mat-form-field fxFlex>
              <input
                fxFlex
                type="number"
                #inputPrevious
                matInput
                [value]="filter.selectedValue?.number"
                (change)="setDateValue(filter, 'previous', $event.target.value)" />
            </mat-form-field>
            <span>days ago</span>
          </div>

          <mat-radio-button value="custom" (change)="setDateValue(filter, 'custom')" (click)="$event.stopPropagation()">
            Custom
          </mat-radio-button>

          <sbnb-date-picker
            *ngIf="searchCriteria[filter.id] === 'custom'"
            numMonths="1"
            [rangeSelect]="true"
            [allowBothRangeAndSingle]="true"
            [checkInOutStyling]="false"
            [showDurationLabels]="false"
            (datesSelected)="dateSelectChanged(filter, $event)"
            (click)="$event.stopPropagation()"></sbnb-date-picker>
        </mat-radio-group>

        <button class="filter__btn-done">Done</button>
      </ng-template>

      <ng-template matMenuContent *ngIf="filter.type === 'single-date'">
        <mat-radio-group [(ngModel)]="searchCriteria[filter.id]" fxLayout="column" class="filters__input-number-rg">
          <mat-radio-button value="today" (change)="setDateValue(filter, 'today')">Today</mat-radio-button>

          <mat-radio-button value="custom" (change)="setDateValue(filter, 'custom')" (click)="$event.stopPropagation()">
            Custom
          </mat-radio-button>

          <sbnb-date-picker
            *ngIf="searchCriteria[filter.id] === 'custom'"
            numMonths="1"
            [rangeSelect]="true"
            [checkInOutStyling]="false"
            [showDurationLabels]="false"
            (datesSelected)="dateSelectChanged(filter, $event)"
            (click)="$event.stopPropagation()"></sbnb-date-picker>
        </mat-radio-group>

        <button class="filter__btn-done">Done</button>
      </ng-template>
    </mat-menu>
  </ng-container>

  <!-- Add new filter and associated drop down menu -->
  <mat-spinner *ngIf="loading" diameter="15" style="margin-top: 8px; margin-left: 8px"></mat-spinner>
  <button
    *ngIf="availableFilters.length > 0"
    [matMenuTriggerFor]="addNewMenu"
    mat-flat-button
    class="button__small button__text">
    {{ buttonLabel }}
  </button>

  <mat-menu class="filters__cdk-menu" #addNewMenu="matMenu" yPosition="below">
    <ng-container *ngIf="availableFilters && availableFilters.length > 0">
      <button
        *ngFor="let filter of availableFilters | sortBy: 'label'; let i = index"
        (click)="addFilter(i)"
        mat-menu-item
        disableRipple>
        <span fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
          <img
            [src]="'/assets/iconography/filter-icons/' + filter.id + '.svg'"
            onerror="this.src='/assets/iconography/filter-icons/default.svg'" />
          <span>{{ filter.label }}</span>
        </span>
      </button>
    </ng-container>
  </mat-menu>

  <button
    *ngIf="activatedFilters.length > 0 && atLeastOneUnlockedFilterValue() && !hideSaveSegment"
    mat-flat-button
    class="button__small button__text"
    (click)="saveSegment()">
    Save
  </button>

  <button
    *ngIf="activatedFilters.length > 0 && atLeastOneUnlockedFilterValue()"
    mat-flat-button
    class="button__small button__text"
    (click)="reset()">
    Clear
  </button>
</div>
