<div fxLayout fxLayoutGap="8px" fxLayoutAlign="start center" class="navigation__back" (click)="goBack()">
  <app-feature-flag-tabs>
    <ng-container navTabs>
      <img class="hidden sbnbDesktop:block" src="/assets/iconography/back.svg" alt="Back" />
      <sbnb-icon icon="left-arrow" class="clickable nav-back-icon pr-2 block sbnbDesktop:hidden"></sbnb-icon>
    </ng-container>
    <img src="/assets/iconography/back.svg" alt="Back" />
  </app-feature-flag-tabs>
  <span *ngIf="!iconOnly">{{ label ? label : 'Back' }}</span>
</div>
