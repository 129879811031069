import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';
import { SentimentAnalysisService } from '@app/shared/services/sentiment-analysis/sentiment-analysis.service';
import { ThreadPayload, ThreadService } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-guest-banner-thread',
  templateUrl: './guest-banner-thread.component.html',
  styleUrls: ['./guest-banner-thread.component.scss'],
})
export class GuestBannerThreadComponent implements OnInit, OnChanges {
  @Input() thread: ThreadPayload;
  @Input() canChangeEmail = false;
  @Output() translationChange: EventEmitter<boolean> = new EventEmitter();
  @Output() guestNameUpdated: EventEmitter<{ firstname: string; lastname: string }> = new EventEmitter();

  updatingGuestName = false;
  recentlyUpdatedGuestName = false;

  guestPictureError = true;
  mobilePlatformIconSize = '16px';
  mobilePlatformIconClass = 'inbox__guest-platform--with-photo';
  public tags$ = this.selectedReservationService.tags$;
  public languageChangeOptions$ = this.selectedReservationService.languageChangeOptions$;
  public activeReservation$ = this.selectedReservationService.activeReservation$;
  public sentimentAnalysisEnabled$ = this.sentimentAnalysisService.enabled$;

  constructor(
    private router: Router,
    private readonly threadService: ThreadService,
    private readonly segmentIoService: SegmentIoService,
    private readonly selectedReservationService: SelectedReservationService,
    private readonly posthog: PosthogService,
    private sentimentAnalysisService: SentimentAnalysisService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    // Pre-populate custom name with original name if required
    if (!this.thread.guest.custom_first_name) {
      this.thread.guest.custom_first_name = this.thread.guest.first_name;
    }

    if (!this.thread.guest.custom_last_name) {
      this.thread.guest.custom_last_name = this.thread.guest.last_name;
    }
  }

  goBack() {
    this.router.navigate(['/inbox/segments/default']);
  }

  translationsToggled(event: boolean) {
    this.thread.using_translations = event;
    this.translationChange.emit(event);
  }

  updateGuestName() {
    this.updatingGuestName = true;

    // nulling empty values for the API to clear
    if (this.thread.guest.custom_first_name === '') {
      this.thread.guest.custom_first_name = null;
    }
    if (this.thread.guest.custom_last_name === '') {
      this.thread.guest.custom_last_name = null;
    }

    this.threadService
      .updateGuestName(this.thread.uuid, this.thread.guest.custom_first_name, this.thread.guest.custom_last_name)
      .subscribe((res) => {
        if (res.success) {
          // successful update
          this.segmentIoService.track(SegmentEvent.RenamedGuest, {});
          this.updatingGuestName = false;
          this.recentlyUpdatedGuestName = true;

          this.guestNameUpdated.emit({
            firstname: this.thread.guest.custom_first_name,
            lastname: this.thread.guest.custom_last_name,
          });

          setTimeout(() => {
            this.recentlyUpdatedGuestName = false;
          }, 2000);
        }
      });
  }
}
