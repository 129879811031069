import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-cancel-reservation-initiated-by',
  templateUrl: './dialog-cancel-reservation-initiated-by.component.html',
  styleUrls: ['./dialog-cancel-reservation-initiated-by.component.scss'],
})
export class DialogCancelReservationInitiatedByComponent implements OnInit {
  initiatedBy: 'guest' | 'host' | null = null;

  @Output() continue: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }
}
