import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @Input() timestamp: string;
  @Input() senderImg: string;
  @Input() senderName: string;
  @Input() type: 'sent' | 'received' | 'system' = 'sent';

  @HostBinding('class')
  get cssClass(): Record<string, boolean> {
    return {
      [`type-${this.type}`]: Boolean(this.type),
    };
  }

  constructor() { }

  ngOnInit(): void { }
}
