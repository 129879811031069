import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'alterationHaveDatesChanged',
})
export class AlterationHaveDatesChangedPipe implements PipeTransform {
  transform(message: any): boolean {
    if (message.original_state.checkInDate != message.proposed_state.checkInDate) {
      return true;
    }

    if (message.original_state.checkOutDate != message.proposed_state.checkOutDate) {
      return true;
    }

    return false;
  }
}
