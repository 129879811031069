import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingState } from '@app/shared/interfaces';
import { NewDirectCustomQuote } from '@app/shared/services/direct-quotes/direct-quotes.service';
import { map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-direct-quote-confirm',
  templateUrl: './direct-quote-confirm.component.html',
  styleUrls: ['./direct-quote-confirm.component.scss'],
})
export class DirectQuoteConfirmComponent implements OnInit {
  @Input() quote: NewDirectCustomQuote;
  @Input() loadingState: LoadingState;
  @Input() calendarRestricted: boolean;
  @Output() next = new EventEmitter<NewDirectCustomQuote>();
  @Output() goBack = new EventEmitter<any>();
  LoadingState = LoadingState;
  defaultCurrency = 'USD';
  isMobile$ = this.breakpointObserver.observe(['(max-width: 960px)']).pipe(map((res) => res.matches));

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void { }
  onNext() {
    this.next.emit(this.quote);
  }

  onGoBack() {
    this.goBack.emit();
  }
}
