<div class="flex gap-4" data-element="issue-alert">
  <sbnb-icon
    icon="alert-triangle"
    class="mt-6 mr-2 text-smoke-darker"
    [size]="12"
    fxHide
    fxShow.lt-md
    data-element="sentiment-alert-icon-mobile"></sbnb-icon>

  <sbnb-icon
    icon="alert-triangle"
    class="mt-6 mr-2 text-smoke-darker"
    [size]="24"
    fxHide.lt-md
    data-element="sentiment-alert-icon-desktop"></sbnb-icon>

  <div class="flex flex-col gap-4 p-6 bg-white rounded-lg shadow border text-smoke-darker">
    <h3 class="text-lg font-semibold text-gray-900">Possible bad guest experience</h3>
    <div class="text-gray-700">
      <p>We've identified a potential concern in this conversation:</p>
      <p>
        <strong>{{ label }}</strong>
      </p>
      <p>Would you like to cancel the scheduled review requests to avoid a possible negative review?</p>
    </div>
    <div class="flex flex-wrap gap-3 justify-center">
      <sbnb-button
        label="No"
        type="secondary"
        [loading]="loading"
        (clicked)="handleNo()"
        data-element="acknowledge-sentiment"></sbnb-button>

      <sbnb-button
        label="Yes"
        type="primary"
        [loading]="loading"
        (clicked)="handleYes()"
        data-element="cancel-reviews"></sbnb-button>
    </div>
  </div>
</div>
