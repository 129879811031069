import { Component, OnChanges, ChangeDetectionStrategy, Input } from '@angular/core';
import { MessageSendReview } from '@app/shared/services/thread/thread.service';

const getMinMax = (range: number[]): [number, number] => {
  return [Math.min(...range), Math.max(...range)];
};

const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

@Component({
  standalone: false,
  selector: 'sbnb-review-scores-summary',
  template: `
    <span class="flex items-center gap-1" *ngIf="ratingRange">
      <sbnb-icon [size]="16" icon="review"></sbnb-icon>
      <span class="flex gap-0">
        <span>{{ ratingRange[0] }}</span>
        <ng-container *ngIf="ratingRange[0] !== ratingRange[1]">
          <span>-</span>
          <span>{{ ratingRange[1] }}</span>
        </ng-container>
      </span>
    </span>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewScoresSummaryComponent implements OnChanges {
  @Input() messageScores: MessageSendReview['scores'];

  public ratingRange: [number, number] = [5, 5];

  constructor() {}

  ngOnChanges(): void {
    if (!this.messageScores) {
      return;
    }

    const ratings = Object.values(this.messageScores).filter(isNumber);
    this.ratingRange = getMinMax(ratings);
  }
}
