import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-scoping-icons',
  templateUrl: './scoping-icons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopingIconsComponent {
  @Input() showTooltips = true;
  @Input() scoped = [];
  @Input() listingCounts = {
    // Hospitable is a special case, it's the sum of direct, manual, and gvr. But we only want to show 1 or 0. We don't want to show duplicates. Putting this logic in here rather than forcing every consumer to implement conditional checks
    hospitable: 0,
    airbnb: 0,
    homeaway: 0,
    booking: 0,
    agoda: 0,
  };
}
