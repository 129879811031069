<div fxLayout="column" fxLayoutGap="24px">
  <section>
    <div fxLayout fxLayoutAlign="start center">
      <h3 fxFlex>Upcoming scheduled messages</h3>
      <sbnb-close (click)="dialogRef.close()"></sbnb-close>
    </div>
  </section>

  <section class="messages__description">
    <span *ngIf="dialogData.mode === 'review_marked_bad'">
      The review for {{ dialogData.guest_name }}
      <span class="text__medium">has been delayed</span>
      to the very last minute of the review period. Would you also like to cancel upcoming scheduled messages for this
      guest?
    </span>

    <span *ngIf="dialogData.mode === 'review_deleted'">
      The review for {{ dialogData.guest_name }}
      <span class="text__medium">has been cancelled</span>
      . Would you also like to cancel upcoming scheduled messages for this guest?
    </span>
  </section>

  <section *ngIf="dialogData.scheduled_messages?.length > 0" class="messages__list">
    <div
      *ngFor="let message of dialogData.scheduled_messages"
      fxLayout
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap="16px"
      class="messages__message">
      <span fxFlex fxFlex.lt-md="100" class="text__medium ellipsis" [ngClass.lt-md]="{ ellipsis: false }">
        {{ message.name }}
      </span>
      <span class="text__xsmall night">{{ message.scheduled_for }}</span>
    </div>
  </section>

  <section fxLayout.lt-md="column" fxLayoutAlign="end center" fxLayoutAlign.lt-md="start stretch" fxLayoutGap="16px">
    <button mat-flat-button tabindex="-1" class="button__secondary" [disabled]="deleting" (click)="dialogRef.close()">
      Keep scheduled messages
    </button>
    <button tabindex="-1" mat-flat-button class="button__delete" [disabled]="deleting" (click)="deleteMessages()">
      Cancel scheduled messages
      <mat-spinner *ngIf="deleting" class="button__spinner" [diameter]="30"></mat-spinner>
    </button>
  </section>
</div>
