import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Reaction } from '@app/shared/services/thread/thread.service';
import { groupBy } from 'lodash-es';

type ReactionsGroup = {
  emoji: string;
  reactions: Reaction[];
};

@Component({
  standalone: false,
  selector: 'sbnb-message-reactions',
  templateUrl: './message-reactions.component.html',
  styleUrls: ['./message-reactions.component.scss'],
})
export class MessageReactionsComponent implements OnChanges {
  @Input() isGuestMessage: string;
  @Input() reactions: Reaction[];

  reactionsGroups: ReactionsGroup[] = this.getGroupedReactions();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.reactionsGroups = this.getGroupedReactions();
  }

  private getGroupedReactions(): ReactionsGroup[] {
    const groups = groupBy(this.reactions, (reaction: Reaction) => reaction.emoji);

    return Object.keys(groups).map((emoji: string) => ({ emoji, reactions: groups[emoji] }));
  }
}
