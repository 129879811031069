import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Labels {
  trueSelected: string;
  falseSelected: string;
  true: string;
  false: string;
}

@Component({
  standalone: false,
  selector: 'sbnb-toggle-pills',
  template: `
    <div class="flex gap-3 items-center justify-stretch w-full">
      <button class="true" [class.selected]="value === true" (click)="changed.emit(true)" [disabled]="disabled">
        {{ value === true ? labels.trueSelected : labels.true }}
      </button>
      <button class="false" [class.selected]="value === false" (click)="changed.emit(false)" [disabled]="disabled">
        {{ value === false ? labels.falseSelected : labels.false }}
      </button>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      button {
        padding: 6px 12px;
        border-radius: 8px;
        border: 1px solid transparent;
        color: lighten($night, 10%);
        font-size: 14px;
        font-family: 'Cera Headings', 'Helvetica Neue', sans-serif;
        flex-grow: 1;

        &[disabled] {
          pointer-events: none;
          opacity: 0.6;
        }

        &:hover:not(.selected) {
          border: 1px solid $night;
          color: $night;
          background-color: $white;
        }

        &.selected {
          color: $white;
        }

        &.true.selected {
          background-color: darken($grass-darker, 5%);
        }

        &.false.selected {
          background-color: $night;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TogglePillsComponent implements OnInit {
  /**
   * Custom labels for the toggle buttons
   * @default { trueSelected: 'True', falseSelected: 'False', true: 'True', false: 'False' }
   */
  @Input() labels: Labels = {
    trueSelected: 'True',
    falseSelected: 'False',
    true: 'True',
    false: 'False',
  };

  /**
   * Current toggle state
   */
  @Input() value: boolean;

  /**
   * Whether the toggle is disabled
   * @default false
   */
  @Input() disabled = false;

  /**
   * Emits when the toggle state changes
   */
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
