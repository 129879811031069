import { ProductType } from '@app/modules/product-catalog/services/product-catalog.service';
import { Money } from '@app/shared/interfaces/lib/money.interface';

/*
 * https://hospitable-eng.stoplight.io/docs/api-docs/9qsp654tiz7ii-reservation
 *
 *  Questions:
 *  - Why do we have a start_date and end_date as well as checkin and checkout?
 */
export interface Reservation {
  _links: {
    accept: string; // URL to accept the reservation
    reject: string; // URL to reject the reservation
    extended: string; // URL to API to fetch extended reservation details
    upsells: string; // URL to the Guest Portal upsells page for the reservation
  };
  actionable: boolean;
  autohost: {
    id: string | null;
    status: string | null; // TODO: Define the possible values (Seen so far: null, "pending" | 'review' | 'verified')
    supervised: string | null; // TODO: Define the possible values (Seen so far: null, "approve", "decline")
    source: string; // TODO: Define the possible values (Seen so far: "direct", "adhoc", "integration")
    is_free: boolean;
  };
  checkin: string; // Example: "2024-06-27T16:00:00"
  checkin_label: string; // Example: "Jun 27th 4pm"
  checkin_tooltip: string; // Example: "EDT"
  checkout: string;
  checkout_label: string;
  checkout_tooltip: string;
  channel: string; // TODO: Define the possible values (Seen so far: "direct") - Potentially maps to `src/app/shared/models/channel.ts` but needs to be confirmed
  code: string; // Example: "HOST-J5S4ZF"
  created_at: string; // Example: "2024-06-27T07:09:29-04:00"
  created_at_label: string; // Example: "Jun 27th 7:09 am"
  currency: string; // Example: "USD"
  currency_code: string; // Example: "$"
  editable: boolean; // TODO: What is this used for?
  email: string; // Of the primary guest
  end_date: string; // Example: "2024-06-28T11:00:00"
  entity_type: string; // TODO: Define the possible values (Seen so far: "reservation")
  expires_at: string; // TODO: What the fuck is expiring? Example: "2024-06-28T07:09:30-04:00"
  guest: string; // For some very strange reason, this is just the guests name as a string. y u no guest object? Example: "Ben Davies"
  guest_id: string; // Example: "6084c9bed4d374c98a62072879b36e628376d9fb"
  guest_picture: string; // A URL to the guests picture. Sometimes just contains "#" for fun.
  guest_thumbnail: string; // A URL to the guests thumbnail. Sometimes also contains "#" for fun.
  is_booking_request: boolean; // Unsure what this is, we don't seemt to use it though for anything
  is_custom_quote: boolean; // TODO: I tried to track down exactly when this is true, ended up in the booking service, still unclear.
  listing: {
    // TODO: Should probably be its own type under properties.interface, can't find a match that exists just yet
    property_id: number;
    property_name: string;
    name: string;
    image: string;
    tags: string[];
    public_url: string;
  };
  listing_id: string; // Why the fuck isn't this in the listing object?
  manual: boolean; // Reservation is a manual booking - TODO: unclear if this includes direct quotes
  note: string; // The note that's been added to the _thread_ associated to the reservation
  num_adults: number;
  num_children: number;
  num_infants: number;
  num_pets: number;
  parent_child_properties: any[]; // TODO: Type. Looking at the backend it might be an array of objects with type and property_id
  payment_enabled: boolean; // Used for Direct reservations to determine if we have a payout method linked to this reservation
  payment_provider: string; // TODO: Define the possible values (Seen so far: "adyen", "stripe-oauth")
  payment_status: ReservationPaymentStatus;

  payment_terms: ReservationPaymentTerm[];
  pending_alteration: boolean; // Only used for Direct?
  phone: string; // Guests phone number. Example "+31616710123"
  platform: string; // TODO: How does this differ from channel?
  start_date: string; // See question at top of file - how does this differ to check in?
  status: string; // TODO: Define possible values (Seen so far: "no_status"). Does this differ per platform?
  subtotal: number;
  subtotal_formatted: string; // Example: "$1.00"
  upsells: {
    products: ReservationUpsell[];
  };
  supports: {
    upsells: boolean;
    editing: boolean;
    alterations: boolean;
    cancellations: boolean;
    cancellation_due_to_invalid_cc: boolean;
    cancellation_due_to_no_show: boolean;
    report_invalid_payment_details: boolean;
    refunds: boolean;
    charges: boolean;
    adjustments: boolean;
    ad_hoc_verification: boolean;
    pre_approvals?: boolean;
  };
  this_thread: boolean; // Sam added this a few years ago. What is it??
  thread_id: string;
  thread_reply_to: string; // TODO: What is this? We don't use it. Example: "7bd80e10-2e8f-4d4b-aad0-12508ad8cb7a@messaging.hospitable.com"
  thread_uuid: string;
  timezone: string; // Example: "America/New_York"
  user_id: string; // I think this is the channel/platforms user id. Example: "38a7b3d2-6b43-5f0f-947a-e49c9ae0f070"
  uuid: string;
  scheduled_messages_paused?: boolean; // If true, scheduled messages are paused due to incomplete guest portal requirements on the guest.
}

export enum ReservationPaymentStatus {
  UNKNOWN = 'unknown',
  AUTHORISED = 'authorised',
  AUTHORISATION_PENDING = 'authorisation_pending',
  AUTHORISATION_FAILURE = 'authorisation_failure',
  CAPTURE_PENDING = 'capture_pending',
  CAPTURE_FAILURE = 'capture_failure',
  CAPTURED = 'captured',
  CANCELLATION_PENDING = 'cancellation_pending',
  CANCELLATION_FAILURE = 'cancellation_failure',
  CANCELLED = 'cancelled',
  REFUND_PENDING = 'refund_pending',
  REFUND_FAILURE = 'refund_failure',
  REFUNDED = 'refunded',
  NOT_REQUIRED = 'not_required',
}

export interface ReservationPaymentTerm {
  uuid: string;
  payment_uuid: string;
  due_at: string;
  grace_period_end: string;
  status: ReservationPaymentStatus;
  can_extend: boolean;
}

export interface ManualQuoteFee {
  amount: Money;
  label: string;
  type: string;
}

export interface ReservationUpsell {
  uuid: string;
  type: ProductType;
  name: string;
  quantity?: number;
  captured_at: string;
  product_uuid: string;
  variation_uuid: string;
  metadata: {
    [key: string]: any;
  }
}
