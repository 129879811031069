import { Component, EventEmitter, Input, OnChanges, OnInit, Output, inject } from '@angular/core';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { PickerOptions, PickerResponse } from 'filestack-js';

@Component({
  selector: 'sbnb-upload-assets',
  templateUrl: './upload-assets.component.html',
  styleUrl: './upload-assets.component.scss',
})
export class UploadAssetsComponent implements OnInit, OnChanges {
  @Input() thumbnails: string[];
  @Input() label: string;
  @Input() assetType: 'image' | 'video' | 'logo' | 'icon';
  @Input() options: PickerOptions;

  @Output() uploadSuccess = new EventEmitter<PickerResponse>();
  @Output() remove = new EventEmitter<number>();

  filteredThumbnails: string[];
  isMobile$ = inject(DeviceDetectionService).isMobileViewport();

  ngOnInit() {
    this.filterThumbnails();
  }

  ngOnChanges() {
    this.filterThumbnails();
  }

  private filterThumbnails() {
    this.filteredThumbnails = this.thumbnails ? this.thumbnails.filter((thumbnail) => !!thumbnail) : [];
  }
}
