import { Component, OnInit, computed, inject, input, signal } from '@angular/core';
import { GuestPortalService, VerificationData } from '@app/shared/services/guest-portal/guest-portal.service';

@Component({
  selector: 'sbnb-guest-verification-details',
  templateUrl: './guest-verification-details.component.html',
})
export class GuestVerificationDetailsComponent implements OnInit {
  reservationUuid = input<string>();
  verificationDetails = signal<VerificationData | null>(null);
  verificationPurposeOfStayCategoryLabel = computed(() => {
    if (
      this.verificationDetails().purpose_of_stay?.category &&
      this.verificationDetails().purpose_of_stay?.category === 'both'
    ) {
      return 'Business & pleasure';
    }

    return this.verificationDetails().purpose_of_stay?.category;
  });
  verificationPurposeOfStayExperiences = computed((): string[] => {
    const experiences = this.verificationDetails()?.purpose_of_stay?.experiences;
    if (!experiences) return [];

    // trim "Others", its useless to us
    return experiences.filter((experience) => experience !== 'others');
  });

  guestAge = computed(() => {
    const birthDate = this.verificationDetails()?.identification?.birth_date;
    if (!birthDate) return null;

    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }

    return age;
  });

  loadingData = signal(true);

  GuestPortalService = inject(GuestPortalService);
  risksMetadata = this.GuestPortalService.risksMetadata;
  documentTypeMetadata = this.GuestPortalService.documentTypeMetadata;

  ngOnInit() {
    this.GuestPortalService.fetchGuestVerificationDetails(this.reservationUuid()).subscribe((details) => {
      this.verificationDetails.set(details);
      this.loadingData.set(false);
    });
  }
}
