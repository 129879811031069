<div *ngIf="!messageId || !messageDetails" style="padding: 24px">
  <mat-spinner diameter="16"></mat-spinner>
</div>

<div class="dialog__container" *ngIf="messageId && messageDetails">
  <div class="dialog__header">
    <h3 fxLayout fxLayoutAlign="start center" fxLayoutGap="10px" class="nomargin">
      <div fxFlex fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
        <span style="max-width: 460px !important" class="ellipsis">{{ messageDetails.title }}</span>
      </div>
      <div fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
        <a
          fxHide.lt-md
          [routerLink]="['/gx/log/' + messageType + '/' + messageDetails.randomkey]"
          (click)="dialogRef.close()">
          <sbnb-button label="View log" type="secondary" size="mini"></sbnb-button>
        </a>
        <sbnb-close (click)="dialogRef.close()"></sbnb-close>
      </div>
    </h3>

    <div
      class="night"
      style="margin-top: 12px; line-height: 1.6"
      fxLayout="row wrap"
      fxLayoutGap="8px"
      fxLayoutAlign="start center">
      <sbnb-icon fxHide.lt-md icon="time" [size]="16" class="text-lemon-darker"></sbnb-icon>
      <span>
        This message will be sent to
        <strong>{{ messageDetails.guest.name }}</strong>
        at
      </span>

      <strong *ngIf="messageDetails.type === 'activity'">{{ messageDetails.scheduled_for_label }}</strong>

      <ng-container *ngIf="messageDetails.type !== 'activity'">
        <span class="datepicker-badge" [matMenuTriggerFor]="dateMenu" #trigger="matMenuTrigger">
          {{ this.messageDetails.scheduled_day | date: 'mediumDate' }}
        </span>

        <sbnb-time-picker
          [hours]="this.messageDetails.scheduled_time_hours"
          [minutes]="this.messageDetails.scheduled_time_minutes"
          (timeUpdated)="newTimeSelected($event)"></sbnb-time-picker>

        <span>{{ messageDetails.scheduled_timezone }}</span>

        <mat-menu #dateMenu="matMenu" class="filters__cdk-menu">
          <div>
            <sbnb-date-picker
              [numMonths]="1"
              [startDate]="messageDetails.scheduled_day"
              [rangeSelect]="false"
              [checkInOutStyling]="false"
              [showDurationLabels]="false"
              (click)="$event.stopPropagation()"
              (datesSelected)="newDateSelected($event)"></sbnb-date-picker>
          </div>
        </mat-menu>
      </ng-container>
    </div>

    <div
      *ngIf="
        messageDetails.ai_improvement?.message &&
        messageDetails.ai_improvement?.edited === false &&
        messageDetails.ai_improvement?.rejected === false
      "
      class="night flex"
      style="gap: 8px; margin-top: 12px">
      <img fxHide.lt-md src="assets/iconography/magic.svg" alt="Night" style="width: 16px; height: 16px" />
      <span>This message was improved by Guest Experience AI automatically.</span>
      <span
        *ngIf="messageDetails.ai_improvement?.rejected === false && !isRejectingAiImprovement"
        class="ai-undo clickable"
        (click)="undoAiImprovement()">
        Undo improvements
      </span>
    </div>

    <!-- Mobile view log button -->
    <a
      fxHide
      fxShow.lt-md
      [routerLink]="['/gx/log/scheduled/' + messageDetails.randomkey]"
      (click)="dialogRef.close()"
      style="display: block; width: 100%; margin-top: 16px">
      <sbnb-button [fullWidth]="true" label="View log" type="secondary" size="mini"></sbnb-button>
    </a>
  </div>

  <div class="dialog__contents">
    <div class="rule__container">
      <sbnb-rule-editor
        [simpleMode]="true"
        [simpleInput]="initialMessage"
        (simpleTextChange)="messageUpdated($event)"></sbnb-rule-editor>
    </div>

    <div fxLayout fxLayoutAlign="space-between" fxLayout.lt-md="row wrap" fxLayoutGap="16px grid">
      <sbnb-button
        *ngIf="messageDetails._links.cancel"
        label="Don't send"
        fxFlex.lt-md
        [fullWidth]="true"
        [disabled]="actioning"
        type="text"
        labelColor="#ed3c6a"
        icon="not-allowed"
        (clicked)="deleteMessage()"></sbnb-button>

      <sbnb-button
        fxHide
        fxShow.lt-md
        fxFlex.lt-md
        [fullWidth]="true"
        label="Send now"
        [disabled]="actioning"
        type="secondary"
        (clicked)="saveUpdatedMessage(true)"></sbnb-button>

      <sbnb-button
        fxHide
        fxShow.lt-md
        fxFlex="100"
        [fullWidth]="true"
        label="Save"
        [disabled]="actioning"
        type="primary-teal"
        (clicked)="saveUpdatedMessage()"></sbnb-button>

      <div *ngIf="messageDetails._links.update" fxHide.lt-md fxLayout fxLayout.lt-md="column" fxLayoutGap="16px">
        <sbnb-button
          label="Send now"
          [disabled]="actioning"
          type="secondary"
          (clicked)="saveUpdatedMessage(true)"></sbnb-button>

        <sbnb-button
          label="Save"
          [disabled]="actioning"
          type="primary-teal"
          (clicked)="saveUpdatedMessage()"></sbnb-button>
      </div>
    </div>
  </div>
</div>
