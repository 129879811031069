import { Component, Input, OnInit } from '@angular/core';
import { Video } from '../../../modules/support-documentation/models/support-documentation';

@Component({
  standalone: false,
  selector: 'sbnb-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent implements OnInit {
  @Input() video: Video;

  constructor() { }

  ngOnInit(): void { }
}
