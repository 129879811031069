import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface Day {
  value: number;
  label: string;
}

const DAYS: Day[] = [
  { label: 'M', value: 1 },
  { label: 'T', value: 2 },
  { label: 'W', value: 3 },
  { label: 'T', value: 4 },
  { label: 'F', value: 5 },
  { label: 'S', value: 6 },
  {
    label: 'S',
    value: 0, // 0 = Sunday
  },
];

/**
 * Form control compatible component for selecting days of the week.
 * Can be used with reactive forms or by emission of selected values.
 */
@Component({
  standalone: false,
  selector: 'sbnb-day-toggle-group',
  templateUrl: './day-toggle-group.component.html',
  styleUrls: ['./day-toggle-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DayToggleGroupComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayToggleGroupComponent implements OnInit, ControlValueAccessor {
  @Input() startWeekOnSunday = false;
  @Input() formControlName: string;
  @Input() size: 'small' | 'medium' | 'large' = 'large';
  @Input() useStrikethrough = false;
  // Use days and selected to use without a form control
  @Input() days?: number[] = [];
  @Output() selected = new EventEmitter<number[]>();

  // Internal options and state
  // Spreading it here because if there are multiple instances of
  // the component on the page, they will use it by reference
  public options: Day[] = [...DAYS];
  public touched = false;
  public disabled = false;
  public selection = new SelectionModel<number>(
    true, // multiple selection
    []
  );

  constructor() { }

  ngOnInit(): void {
    if (this.startWeekOnSunday) {
      this.options.unshift(this.options.pop());
    }

    if (this.days?.length) {
      this.writeValue(this.days);
    }
  }

  onChange = (selected: number[]) => { };

  onTouched = () => { };

  buttonClick(day: Day) {
    if (this.disabled) {
      return;
    }
    this.markAsTouched();
    this.selection.toggle(day.value);
    this.onChange(this.selection.selected);
    this.selected.emit(this.selection.selected);
  }

  writeValue(selected: number[]) {
    this.selection.select(...selected);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
