<mat-select
  #select
  [value]="value"
  [placeholder]="placeholder"
  [multiple]="multiple"
  (selectionChange)="onSelectionChange($event)"
  class="w-full">
  <mat-select-trigger>
    <ng-container *ngIf="!multiple">
      <div class="flex items-center gap-2">
        <img
          *ngIf="selectedOption?.image"
          [src]="selectedOption?.image?.src"
          [alt]="selectedOption?.image?.alt || selectedOption?.label"
          class="option-image"
          [class.sm]="selectedOption?.image?.size === 'sm'"
          [class.md]="selectedOption?.image?.size === 'md'"
          [class.lg]="selectedOption?.image?.size === 'lg'"
          data-cy="selected-option-image" />
        <span class="flex-1 ellipsis">{{ selectedOption?.label }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="multiple">
      <sbnb-selected-labels
        [options]="options"
        [selectedValues]="value"
        data-cy="selected-labels"></sbnb-selected-labels>
    </ng-container>
  </mat-select-trigger>

  <mat-option *ngIf="showSearch">
    <ngx-mat-select-search
      [formControl]="searchControl"
      placeholderLabel="Search..."
      noEntriesFoundLabel="No matching options found"></ngx-mat-select-search>
  </mat-option>
  <mat-option *ngIf="actionOption" [disabled]="true" class="border-b border-night-lighter">
    <ng-content></ng-content>
  </mat-option>
  <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.value" [disabled]="option.disabled">
    <div class="flex items-center gap-2">
      <img
        *ngIf="option.image"
        [src]="option.image.src"
        [alt]="option.image.alt || option.label"
        class="option-image"
        [class.sm]="option.image?.size === 'sm'"
        [class.md]="option.image?.size === 'md'"
        [class.lg]="option.image?.size === 'lg'"
        data-cy="option-image" />
      <span class="flex-1 ellipsis">{{ option.label }}</span>
    </div>
  </mat-option>
</mat-select>
