<div class="toggle-group">
  <button
    type="button"
    [id]="option.label"
    [attr.role]="'button'"
    class="toggle-group__button"
    [ngClass]="{
      checked: selection.isSelected(option.value),
      'p-[6px]': size === 'small',
      'p-2': size === 'medium',
      'p-3': size === 'large',
      'use-strikethrough': useStrikethrough
    }"
    [attr.tabindex]="disabled ? -1 : null"
    [attr.aria-pressed]="selection.isSelected(option.value)"
    [attr.aria-checked]="selection.isSelected(option.value)"
    [disabled]="disabled"
    [attr.aria-label]="'Select ' + option.label"
    [attr.aria-disabled]="disabled ? 'true' : null"
    *ngFor="let option of options"
    (click)="buttonClick(option)">
    <span class="font-mono">{{ option.label }}</span>
  </button>
</div>
