import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(value: any[], column: string): any[] {
    if (!value || !column || column === '') {
      return value;
    } // no array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    // sort by value
    return value.sort(function (a, b) {
      if (a[column] < b[column]) {
        return -1;
      }
      if (a[column] > b[column]) {
        return 1;
      }
      return 0;
    });
  }
}
