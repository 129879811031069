<header class="flex justify-end">
  <sbnb-close [border]="true" (click)="close()"></sbnb-close>
</header>

<section style="width: 100%; max-width: 760px; margin: 0 auto">
  <sbnb-progress-stepper [selectedIndex]="currentStage - 1">
    <cdk-step label="Select checks" [completed]="currentStage !== 1">
      <div class="mt-9">
        <h2 class="mb-4">Feel confident about the guests you host</h2>

        <ng-container *ngIf="guestVettingOptions$ | async as vettingOptions; else loadingOptionsTemplate">
          <ng-container *ngIf="vettingOptions.length > 0; else noOptionsTemplate">
            <p class="text-night mt-1 mb-3">
              Invite
              <strong>{{ dialogData.guestName }}</strong>
              to a professional guest portal that securely collects guest information and runs verification checks that
              you can trust.
            </p>

            <div class="grid grid-cols-2 md:grid-cols-3 gap-2 text-night text__small mx-4">
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Legal name
              </div>
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Email
              </div>
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Identification
              </div>
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Address
              </div>
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Phone number
              </div>
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Purpose of stay
              </div>
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Birth date
              </div>
              <div class="flex gap-2 items-center">
                <sbnb-icon [size]="16" icon="check" class="text-grass-darker"></sbnb-icon>
                Selfie
              </div>
            </div>

            <section class="mt-12">
              <h3 class="mb-4">Select the verification measures you need</h3>

              <div class="flex flex-col gap-2">
                <div
                  class="flex gap-2 items-center border rounded-lg p-2"
                  [class.border-night-lighter]="selectedVettings.includes(option.id)"
                  [class.border-smoke]="!selectedVettings.includes(option.id)"
                  (click)="toggleVettingOption(option.id)"
                  *ngFor="let option of vettingOptions">
                  <mat-checkbox
                    [checked]="selectedVettings.includes(option.id)"
                    (change)="toggleVettingOption(option.id)"
                    (click)="$event.stopPropagation()"></mat-checkbox>
                  <img
                    class="block max-w-[50px]"
                    [src]="'/assets/images/guest-vetting/' + option.id + '.svg'"
                    [alt]="option.label" />
                  <div class="flex flex-col">
                    <h3>{{ option.label }}</h3>
                    <p class="text-sm flex-grow m-0 text-night">{{ option.description }}</p>
                    <div class=" ">
                      <div class="flex gap-1 items-center">
                        <span [class.line-through]="option.is_free" class="text-lg text-night">
                          {{ option.price.formatted_string }}
                        </span>
                        <sbnb-icon *ngIf="option.is_free" icon="present" class="text-grass-darker pb-1"></sbnb-icon>
                        <div *ngIf="option.is_free" class="text-grass-darker italic text-sm">
                          The first one is on us!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <sbnb-alert-card
              context="warning"
              *ngIf="selectedVettings.includes('sex_offenders') && !selectedVettings.includes('identity')"
              class="mt-8 flex items-center gap-2">
              <sbnb-icon icon="alert-triangle" class="text-lemon-darker"></sbnb-icon>

              <span>
                The identification used to run this check will not be verified unless
                <strong>Identity verification</strong>
                is also selected.
              </span>
            </sbnb-alert-card>

            <div [class.opacity-0]="selectedVettings.length === 0" class="text-right text-night mt-8">
              Total:
              <span class="strong text-xl">
                {{ selectedVettings | guestVettingTotal: vettingOptions | currency: vettingOptions[0].price.currency }}
              </span>
            </div>
          </ng-container>
        </ng-container>
        <div class="flex justify-between items-center mt-6">
          <sbnb-button type="secondary" (clicked)="close()">Cancel</sbnb-button>
          <sbnb-button [disabled]="selectedVettings.length === 0" (clicked)="continueToStage2()">
            Preview & send
          </sbnb-button>
        </div>
      </div>
    </cdk-step>
    <cdk-step label="Preview & send request" [completed]="currentStage === 3">
      <div class="mt-9">
        <div class="flex gap-2 items-center mt-2">
          <sbnb-icon icon="message"></sbnb-icon>
          <h3>Message</h3>
        </div>
        <p class="text-night mt-2">Personalize the message that will be sent to {{ dialogData.guestName }}.</p>

        <sbnb-rule-editor
          [simpleMode]="true"
          [simpleInput]="messageTemplate"
          (simpleTextChange)="onMessageTemplateChange($event)"
          [importEnabled]="false"
          [codesEnabled]="true"
          [previewEnabled]="false"></sbnb-rule-editor>

        <sbnb-alert-card
          context="warning"
          *ngIf="message && !message.includes('%guest_portal%')"
          class="mt-2 flex items-center gap-2">
          <img src="/assets/iconography/warning.svg" onload="SVGInject(this)" alt="Warning" />
          <span>This message must include the %guest_portal% short code.</span>
        </sbnb-alert-card>

        <div *ngIf="guestVettingOptions$ | async as vettingOptions" class="mt-3">
          <mat-checkbox [(ngModel)]="hostConfirmedCharge">
            I understand that a one-time charge of
            <strong>
              {{ selectedVettings | guestVettingTotal: vettingOptions | currency: vettingOptions[0].price.currency }}
            </strong>
            will be added to my next invoice.
          </mat-checkbox>
        </div>

        <div class="flex flex-col md:flex-row justify-between gap-4 mt-6">
          <sbnb-button [fullWidth]="true" type="secondary" (clicked)="currentStage = 1">Go back</sbnb-button>
          <div class="flex flex-col md:flex-row gap-2">
            <sbnb-button
              [fullWidth]="true"
              [disabled]="!message.includes('%guest_portal%')"
              [type]="hostConfirmedCharge ? 'secondary' : 'primary'"
              (clicked)="previewGuestPortal()">
              Preview example portal
            </sbnb-button>
            <sbnb-button
              [fullWidth]="true"
              [loading]="submitting"
              [disabled]="!hostConfirmedCharge || !message.includes('%guest_portal%')"
              (clicked)="submitVerificationRequest()">
              Confirm & send
            </sbnb-button>
          </div>
        </div>
      </div>
    </cdk-step>
    <cdk-step label="Done">
      <div class="mt-9">
        <sbnb-confetti #confetti></sbnb-confetti>
        <sbnb-hero-card [positive]="true">
          <h2 class="nomargin">Verification requested!</h2>
          <div class="flex flex-col gap-2 mt-4">
            <div class="flex gap-2 items-center">
              <sbnb-icon icon="check" class="text-grass-darker"></sbnb-icon>
              A request for additional verification has been sent to {{ dialogData.guestName }}!
            </div>

            <div class="flex gap-2 items-center">
              <sbnb-icon icon="message"></sbnb-icon>
              Check the status of the verification anytime in the inbox thread with your guest.
            </div>
          </div>
        </sbnb-hero-card>

        <h3 class="mt-6">What to expect next:</h3>
        <ul class="text-night leading-relaxed">
          <li>Your guest needs to complete the required tasks in their guest portal.</li>
          <li>
            Once completed, the verification results will be shared with you and, if the guest passes, check-in messages
            will be shared as scheduled.
          </li>
          <li>
            If a guest is flagged for your manual review, we'll let you know so you can decide how you want to move
            forward.
          </li>
        </ul>

        <sbnb-button class="block mt-8" (clicked)="close(true)">Done</sbnb-button>
      </div>
    </cdk-step>
  </sbnb-progress-stepper>
</section>

<ng-template #loadingOptionsTemplate>
  <sbnb-loading-spinner></sbnb-loading-spinner>
</ng-template>

<ng-template #noOptionsTemplate>
  <sbnb-alert-card context="warning">Unable to load available checks. Please try again later.</sbnb-alert-card>
</ng-template>
