import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-reservation-icon',
  template: `
    <sbnb-icon [size]="40" icon="post-stay" class="mt-5 md:block hidden text-lemon-darker"></sbnb-icon>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationIconComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
