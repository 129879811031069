<section fxFlexFill fxLayout fxLayoutAlign="center center" class="add-manual-booking" *ngIf="quote">
  <div class="fs-dialog-contents" style="margin: auto">
    <div
      class="overview__item"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap.lt-md="24px">
      <h2 fxFlex class="nomargin">How long would you like to hold the booking?</h2>
    </div>

    <div>
      <p>
        <mat-form-field class="input__mini nopadding">
          <mat-select [(ngModel)]="quote.expires_in">
            <mat-option *ngFor="let expiration of expirations" [value]="expiration">{{ expiration }}</mat-option>
          </mat-select>
        </mat-form-field>
      </p>
    </div>

    <div>
      <p>
        If the quote expires before payment is received, Hospitable will unblock the dates. The guest can still use the
        link to book as long as the dates remain available.
      </p>
    </div>

    <div style="margin-top: 40px" fxLayout fxLayoutGap="24px" fxLayout.lt-md="row" fxLayoutGap.lt-md="8px">
      <div fxFlex>
        <sbnb-button [fullWidth]="true" type="secondary" label="Go Back" (clicked)="onGoBack()"></sbnb-button>
      </div>
      <div fxFlex>
        <sbnb-button [fullWidth]="true" label="Continue" (clicked)="onNext()"></sbnb-button>
      </div>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div class="fs-dialog-contents">
      <mat-spinner diameter="32"></mat-spinner>
    </div>
  </ng-template>
</section>
