import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Channel } from '@app/shared/models/channel';
import { ThreadPayload } from '@app/shared/services/thread/thread.service';
import { sbnbIcon } from '@app/ui';
import { differenceInCalendarDays, distanceInWordsToNow, format, isSameDay } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const GUESTS_URL = `${config.API_URL}/guests`;

export const parseAISummary = (summary: string): string[] => {
  return summary.split('\n').map((item) => item.trim());
};

export type GuestInsights = ReturnType<GuestInsightsService['guestInsightsTransformer']>;

const verified = (verified: boolean, platform: ThreadPayload['platform']) => {
  if (platform === Channel.Direct) {
    return null;
  }
  return {
    icon: verified ? '/assets/insight-icons/verified-all.svg' : '/assets/insight-icons/verified-none.svg',
    label: verified ? 'Verified' : 'Not verified',
  };
};

const chancesOfBooking = (chances: ThreadPayload['chances_of_booking'], platform: ThreadPayload['platform']) => {
  if (platform === Channel.Direct) {
    return null;
  }
  switch (chances) {
    case 'Booked':
      return {
        icon: '/assets/insight-icons/chance-of-booking-high.svg',
        label: 'Booked',
      };
    case 'None':
      return {
        icon: '/assets/insight-icons/chance-of-booking-none.svg',
        label: 'No chance of booking',
      };
    case 'Very Low':
    case 'Low':
    case 'Medium':
      return {
        icon: '/assets/insight-icons/chance-of-booking-low.svg',
        label: `${chances} chance of booking`,
      };
    case 'High':
    case 'Quite High':
      return {
        icon: '/assets/insight-icons/chance-of-booking-high.svg',
        label: `${chances} chance of booking`,
      };
    default:
      return {
        icon: '/assets/insight-icons/chance-of-booking-none.svg',
        label: 'No chance of booking',
      };
  }
};

const reviewCount = (
  reviews: ThreadPayload['guest']['reviews'],
  ratings: ThreadPayload['guest']['ratings'],
  platform: ThreadPayload['platform']
) => {
  if (platform === Channel.AirbnbOfficial) {
    return {
      icon: 'review',
      count: reviews.length,
      label: 'Public reviews',
    };
  }

  if (platform === Channel.Vrbo) {
    return {
      icon: 'review',
      count: ratings?.overall?.count ?? 0,
      label: 'Public reviews',
    };
  }
};

const isSameDayBooking = (checkinDate: Date, createdAt: Date) => {
  const formattedCheckIn = format(checkinDate, 'YYYY-MM-DD');
  const formattedCreatedAt = format(createdAt, 'YYYY-MM-DD');

  const same = isSameDay(formattedCheckIn, formattedCreatedAt);
  if (!same) {
    return null;
  }

  return {
    icon: 'same_day_booking',
    label: 'Same day booking',
  };
};

const localGuest = (distanceStr: ThreadPayload['distance'] = '') => {
  const distance = Number(distanceStr);
  if (!distance || distance >= 100) return null;

  return {
    icon: 'local_guest',
    label: `${Math.round(distance)} km away`,
  };
};

const returningGuest = (returning: boolean) => {
  if (!returning) return null;

  return {
    icon: 'returning_guest',
    label: 'Returning guest',
  };
};

const activeFor = (createdAt: ThreadPayload['guest']['account_created_at']) => {
  if (!createdAt) return null;
  const accountLength = differenceInCalendarDays(new Date(), createdAt);

  return {
    icon: accountLength < 14 ? 'alert-triangle' : 'time',
    label: `Active for ${distanceInWordsToNow(createdAt)}`,
    accountLength,
  };
};

const location = (location) => {
  if (!location) return null;

  return {
    icon: 'location',
    label: location,
  };
};

const localTime = (uses24HourFormat, timeZone) => {
  if (!timeZone || timeZone === 0) {
    return null;
  }
  // Its possible that datefns doesn't know about the timezone, so we need to catch the error
  try {
    const format = uses24HourFormat ? 'HH:mm (z)' : 'h:mma (z)';

    return {
      icon: 'time',
      label: formatToTimeZone(new Date(), format, {
        timeZone,
      }),
      timeZone,
      format,
    };
  } catch {
    return null;
  }
};

@Injectable({
  providedIn: 'root',
})
export class GuestInsightsService {
  constructor(
    private http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) {}

  public guestInsightsTransformer({
    platform,
    guest,
    chances_of_booking,
    checkin,
    created_at,
    distance,
    returning_guest,
  }: Pick<
    ThreadPayload,
    'platform' | 'guest' | 'chances_of_booking' | 'checkin' | 'created_at' | 'distance' | 'returning_guest'
  >) {
    const insights = {
      verified: verified(guest?.verified, platform),
      chancesOfBooking: chancesOfBooking(chances_of_booking, platform),
      reviewCount: reviewCount(guest?.reviews, guest?.ratings, platform),
      sameDayBooking: isSameDayBooking(checkin, new Date(created_at)),
      localGuest: localGuest(distance),
      returningGuest: returningGuest(returning_guest),
      activeFor: activeFor(guest?.account_created_at),
      location: location(guest?.ui_location),
      localTime: localTime(this.uses24HourFormat, guest?.timezone),
      phone: guest?.phone ? { icon: 'phone' as sbnbIcon, label: guest.phone } : null,
      recommendations: guest?.recommendations
        ? {
            amount: guest?.recommendations?.amount,
            unanimously_recommended: guest?.recommendations?.unanimously_recommended,
            icon: 'review',
          }
        : null,
    };
    return insights;
  }

  public generateGuestOverview(guestUuid: string): Observable<{ summary: string[] }> {
    return this.http.get(`${GUESTS_URL}/${guestUuid}/generate-overview`).pipe(
      map((res: any) => {
        return { summary: parseAISummary(res.data.summary) };
      })
    );
  }

  private get uses24HourFormat() {
    return this.authenticationService.getUserDetails()?.uses_24_hour_format;
  }
}
