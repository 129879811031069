import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DialogEditAiReviewComponent } from '@app/shared/components/dialog-edit-ai-review/dialog-edit-ai-review.component';
import { MessageSendReview } from '@app/shared/services/thread/thread.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-send-review',
  templateUrl: './send-review.component.html',
  styleUrls: ['./send-review.component.scss'],
})
export class SendReviewComponent implements OnChanges, OnDestroy {
  @Input() message: MessageSendReview;

  // local state so that we can update the UI optimistically
  public state$ = new BehaviorSubject<MessageSendReview>(null);
  private destroyed$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) { }

  // Lifecycle
  public ngOnChanges(changes: SimpleChanges): void {
    this.state$.next(changes.message.currentValue);
  }

  public openEditReviewDialog() {
    const dialogRef = this.dialog.open(DialogEditAiReviewComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        reviewId: this.message.review_id,
      },
    });

    // Optimistically update the state
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((message: Partial<MessageSendReview> | { cancelled_at: Date }) => {
        if (!message) {
          return;
        }
        if ((message as { cancelled_at: Date }).cancelled_at) {
          this.state$.next(null);
          return;
        }
        if (message) {
          this.state$.next({
            ...this.state$.value,
            ...message,
            rating: (message as Partial<MessageSendReview>).scores.rating ?? this.state$.value.scores.rating,
          });
        }
      });
  }

  public routeToReviewLog() {
    this.router.navigate(['/gx/log/reviews', this.message.review_id]);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
