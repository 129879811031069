<section class="text-night-darker flex flex-col gap-4">
  <header class="text-night-darker strong flex items-center justify-between gap-1">
    <h4 class="text-base">Guest portal</h4>
    <sbnb-copy-to-clipboard [content]="GUEST_PORTAL_URL + reservation().uuid" tooltipText="Copy guest portal link" />
  </header>

  <!-- task status and list-->
  @if (tasks().length > 0) {
    <section class="text-sm">
      <div class="flex items-center gap-2 mb-2">
        <div class="strong">Tasks</div>
        @switch (taskStatus()) {
          @case ('issues') {
            <sbnb-icon
              [size]="16"
              [icon]="'alert-circle'"
              class="text-lemon-darker"
              [matTooltip]="
                'Check-in messages and code communications will not be sent until the guest completes the pending tasks.'
              " />
          }
        }
      </div>

      <div class="tasks-container flex flex-col gap-1">
        @for (task of tasks(); track task.type) {
          <div
            *ngIf="taskMetadataMap && taskMetadataMap[task.type] as taskMetadata"
            class="flex items-center gap-1 justify-between cursor-pointer hover:opacity-80"
            (click)="openDetailsDialog(task.type)">
            <div class="flex items-center gap-2">
              <div>{{ taskMetadata.label }}</div>
            </div>

            @if (task.type === 'guest-verification') {
              <div
                [class]="{
                  'text-lemon-darker': ['pending', 'suspected', 'review'].includes(task.metadata.verification_status),
                  'text-raspberry-darker': task.metadata.verification_status === 'denied',
                  'text-grass-darker': task.metadata.verification_status === 'approved'
                }">
                {{ guestVerificationStatusMetadata[task.metadata.verification_status] || 'Not Requested' }}
              </div>
            } @else {
              <div [class]="taskStatusMetadata[task.status].color">
                {{ taskStatusMetadata[task.status].label }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <!-- actions -->
    <section class="flex flex-col gap-2">
      @for (action of availableActions(); track action) {
        <sbnb-button
          type="secondary"
          [fullWidth]="true"
          [size]="'mini'"
          [label]="availableActionsMetadata[action].label"
          (clicked)="handleAction(action)" />
      }
    </section>
  } @else {
    <!-- Loading state -->
    <section class="text-sm">
      <div class="flex items-center gap-2 mb-2">
        <div class="h-4 w-20 bg-gray-200 animate-pulse rounded"></div>
      </div>

      <div class="tasks-container flex flex-col gap-1">
        @for (i of [1, 2, 3]; track i) {
          <div class="flex items-center gap-1 justify-between">
            <div class="flex items-center gap-2">
              <div class="h-4 w-32 bg-gray-200 animate-pulse rounded"></div>
            </div>
            <div class="h-4 w-20 bg-gray-200 animate-pulse rounded"></div>
          </div>
        }
      </div>
    </section>
  }
</section>
