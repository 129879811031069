import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { EnhancedAIReviewSuggestions, Review, ReviewSuggestion, RuleService, SendReviewPayload } from '@app/shared/services/rule/rule.service';
import {
  ScheduledMessageCancellerMode,
  ScheduledMessagesCancellerService,
} from '@app/shared/services/scheduled-messages-canceller/scheduled-messages-canceller.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { MessageSendReview } from '@app/shared/services/thread/thread.service';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export interface EditReviewFormData {
  reviewId: string;
}

export type EditReviewFormSubmissionData = Partial<MessageSendReview> | { cancelled_at: Date };

@Component({
  standalone: false,
  selector: 'sbnb-form-edit-review',
  templateUrl: './form-edit-review.component.html',
  styleUrls: ['./form-edit-review.component.scss'],
})
export class FormEditReviewComponent implements OnInit {
  @Input() data: EditReviewFormData;
  @Output() submitted = new EventEmitter<Partial<MessageSendReview> | { cancelled_at: Date }>();
  @Output() cancelled = new EventEmitter<void>();

  public review: Review;
  public submitting$ = new BehaviorSubject(false);

  public reviewForm = this.fb.group({
    rating: [5],
    review: ['', [Validators.required]],
    suggestedReview: [''],
    leavePrivateFeedback: [false],
    privateFeedback: [''],
    delay: [false],
    recommend: [false],
    cleanliness: [5],
    respectHouseRules: [5],
    communication: [5],
    scheduledFor: [''],
  });

  private aiSuggestions = new Map<number, ReviewSuggestion>();

  public selectedTags: { [category: string]: Set<string> } = {
    cleanliness: new Set(),
    respect_house_rules: new Set(),
    communication: new Set(),
  };

  public readonly Array = Array;

  public tagsEnabled = false;

  constructor(
    private readonly ruleService: RuleService,
    private readonly fb: UntypedFormBuilder,
    private readonly cancellerService: ScheduledMessagesCancellerService,
    private readonly segmentIoService: SegmentIoService,
    private readonly optimizelyService: OptimizelyService
  ) {
    this.optimizelyService.reviewCategoryTagsEnabled$.subscribe(
      enabled => this.tagsEnabled = enabled
    );
  }

  ngOnInit(): void {
    this.segmentIoService.track(SegmentEvent.AIReviewEditActioned, { review_id: this.data.reviewId });
    this.ruleService.getSingleReview(this.data.reviewId).subscribe((review: Review) => {
      this.review = review;

      // Initialize with saved tags if they exist
      if (review.review.category_tags) {
        this.updateSelectedTagsFromSuggestion(review.review.category_tags);
      }

      // Set AI suggestions after initial data is loaded
      this.setAiSuggestions(review.enhanced_suggestions);

      this.reviewForm.patchValue(
        {
          review: review.message ?? review.prepared_message,
          leavePrivateFeedback: Boolean(review.private_feedback?.length),
          privateFeedback: review.private_feedback ?? '',
          delay: review.review.bad_review ?? false,
          recommend: review.review.grades.recommend ?? true,
          cleanliness: review.review.grades.cleanliness,
          respectHouseRules: review.review.grades.respect_house_rules,
          communication: review.review.grades.communication,
          rating: review.review.grades.rating,
        },
        { emitEvent: false }
      );

      if (!this.reviewForm.controls['leavePrivateFeedback'].value) {
        this.reviewForm.controls['privateFeedback'].disable();
      }

      // On first load, fill private feedback
      if (!review.private_feedback) {
        this.automaticallyFillPrivateFeedback(review.review.grades.rating);
      }

      if (review.sent_at) {
        this.reviewForm.disable();
      }
    });

    // When rating changes, automatically fill in the review, scores, and recommend
    this.reviewForm.controls['rating'].valueChanges.pipe(distinctUntilChanged()).subscribe((rating) => {
      this.automaticallyFillReview(rating);
      this.automaticallyFillPrivateFeedback(rating);
    });

    this.reviewForm.controls['leavePrivateFeedback'].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((leavePrivateFeedback) => {
        this.toggleLeavePrivateFeedback(leavePrivateFeedback);
      });
  }

  public postSubmission(data?: Partial<MessageSendReview> | { cancelled_at: Date }): void {
    this.submitted.emit(data);
  }

  public onSaveReview(sendNow?: boolean): void {
    this.submitting$.next(true);
    const {
      rating,
      review,
      recommend,
      cleanliness,
      respectHouseRules,
      communication,
      delay,
      leavePrivateFeedback,
      privateFeedback,
    } = this.reviewForm.value;

    // if the user specifically chooses "send now", then do that. Otherwise, the default behavior is dictated by whether a schedule already exists.
    const sendReplyNow = sendNow ?? !this.review.scheduled_for;

    if (rating !== 5 && this.userHasChangedReviewOrPrivateFeedback()) {
      this.segmentIoService.track(SegmentEvent.AIReviewEdited, {
        ai_proposed_review: this.getAISuggestion(rating, 'public'),
        user_review: review,
        ai_proposed_private_feedback: this.getAISuggestion(rating, 'private'),
        user_private_feedback: leavePrivateFeedback ? privateFeedback : null,
      });
    }

    const reviewData: SendReviewPayload = {
      reviewId: this.data.reviewId,
      message: review,
      grades: {
        recommend,
        cleanliness,
        respect_house_rules: respectHouseRules,
        communication,
        rating,
      },
      send_now: sendReplyNow,
      bad_review: delay,
      private_feedback: leavePrivateFeedback ? privateFeedback : null,
    };

    // Only include tags if feature is enabled
    if (this.tagsEnabled) {
      reviewData.cleanliness_tags = Array.from(this.selectedTags.cleanliness);
      reviewData.respect_house_rules_tags = Array.from(this.selectedTags.respect_house_rules);
      reviewData.communication_tags = Array.from(this.selectedTags.communication);

      // Track when a review with tags is submitted
      this.segmentIoService.track(SegmentEvent.ReviewTagsSaved, {review_id: this.data.reviewId});
    }

    this.ruleService.sendReview(reviewData).subscribe((res) => {
      this.submitting$.next(false);
      if (res.errors) {
        return;
      }

      if (delay) {
        this.showScheduledMessageCancellerDialog('review_marked_bad');
      }

      this.postSubmission({
        submitted: sendReplyNow,
        scores: {
          recommend,
          cleanliness,
          respect_house_rules: respectHouseRules,
          communication,
          rating,
        },
        bad_review: delay,
        review,
        private_feedback: privateFeedback,
        scheduled_for: res.data.scheduled_for,
        scheduled_for_label: res.data.scheduled_for_label,
      });
    });
  }

  public onCancelReview(): void {
    this.submitting$.next(true);
    this.ruleService.deleteSingleReview(this.data.reviewId).subscribe((res) => {
      this.submitting$.next(false);
      if (res.data) {
        this.showScheduledMessageCancellerDialog('review_deleted');
        this.postSubmission({ cancelled_at: new Date() });
      }
    });
  }

  public setStarField(rating: number, name): void {
    this.reviewForm.controls[name].setValue(rating);
    this.reviewForm.controls[name].markAsDirty();
  }

  private setAiSuggestions(suggestions: EnhancedAIReviewSuggestions): void {
    if (!suggestions) {
      return;
    }

    this.aiSuggestions = new Map([
      [5, suggestions.excellent_review],
      [4, suggestions.good_review],
      [3, suggestions.decent_review],
      [2, suggestions.bad_review],
      [1, suggestions.terrible_review],
    ]);
  }

  private automaticallyFillPrivateFeedback(rating: number): void {
    if (!this.userHasChangedPrivateFeedback()) {
      this.useAISuggestionForPrivateFeedback(rating);
    }
  }

  private automaticallyFillReview(rating: number): void {
    if (!this.userHasChangedReview()) {
      this.useAISuggestionForRating(rating);
    }

    if (!this.userHasChangedPrivateFeedback()) {
      this.useAISuggestionForPrivateFeedback(rating);
    }

    if (!this.userHasChangedScores()) {
      this.reviewForm.controls['cleanliness'].setValue(rating);
      this.reviewForm.controls['respectHouseRules'].setValue(rating);
      this.reviewForm.controls['communication'].setValue(rating);
    }

    if (!this.userHasChangedRecommend()) {
      this.reviewForm.controls['recommend'].setValue(rating >= 3);
    }

    // Update tags based on current rating's suggestion
    const currentTags = this.getCurrentSuggestionTags(rating);
    if (currentTags) {
      this.updateSelectedTagsFromSuggestion(currentTags);
    }
  }

  private showScheduledMessageCancellerDialog(reason: ScheduledMessageCancellerMode): void {
    this.cancellerService.checkAndOpenScheduledMessageCanceller(
      this.review.reservation_code,
      reason,
      this.review.guest?.name
    );
  }

  private userHasChangedPrivateFeedback(): boolean {
    return this.reviewForm.controls['privateFeedback'].dirty;
  }

  private userHasChangedReview(): boolean {
    return this.reviewForm.controls['review'].dirty;
  }

  private userHasChangedReviewOrPrivateFeedback(): boolean {
    return this.userHasChangedReview() || this.userHasChangedPrivateFeedback();
  }

  private userHasChangedScores(): boolean {
    return (
      this.reviewForm.controls['cleanliness'].dirty ||
      this.reviewForm.controls['respectHouseRules'].dirty ||
      this.reviewForm.controls['communication'].dirty
    );
  }

  private userHasChangedRecommend(): boolean {
    return this.reviewForm.controls['recommend'].dirty;
  }

  private useAISuggestionForPrivateFeedback(rating: number): void {
    if (!this.aiSuggestions.size) {
      return;
    }

    // If the user did have a canned response set before, use the existing private feedback instead of the AI suggestion
    if (rating === 5) {
      const cannedReview = this.review.review.previous_review ?? this.review.prepared_message;

      if (cannedReview) {
        this.reviewForm.controls['privateFeedback'].setValue(
          this.review.private_feedback ?? this.getAISuggestion(rating, 'private'),
          {
            emitEvent: false,
          }
        );
        return;
      }
    }

    this.reviewForm.controls['privateFeedback'].setValue(this.getAISuggestion(rating, 'private'));
  }

  private useAISuggestionForRating(rating: number): void {
    if (!this.aiSuggestions.size) {
      return;
    }

    // If the user did have a canned response set before, use that instead of the AI suggestion
    if (rating === 5) {
      const cannedReview = this.review.review.previous_review ?? this.review.prepared_message;
      this.reviewForm.controls['review'].setValue(cannedReview ?? this.getAISuggestion(rating, 'public'), {
        emitEvent: false,
      });

      this.reviewForm.controls['privateFeedback'].setValue(
        this.review.private_feedback ?? this.getAISuggestion(rating, 'private'),
        {
          emitEvent: false,
        }
      );

      return;
    }

    this.reviewForm.controls['review'].setValue(this.getAISuggestion(rating, 'public'), {
      emitEvent: false,
    });
    this.reviewForm.controls['privateFeedback'].setValue(this.getAISuggestion(rating, 'private'), {
      emitEvent: false,
    });
  }

  private getAISuggestion(rating: number, type: 'public' | 'private'): string {
    return this.aiSuggestions?.get(rating)?.[type] ?? '';
  }

  private toggleLeavePrivateFeedback(leavePrivateFeedback): void {
    if (leavePrivateFeedback) {
      this.reviewForm.controls['privateFeedback'].enable();
    } else {
      this.reviewForm.controls['privateFeedback'].disable();
    }
  }

  private getCurrentSuggestionTags(rating: number): {
    cleanliness_tags: string[],
    respect_house_rules_tags: string[],
    communication_tags: string[]
  } | undefined {
    const suggestion = this.aiSuggestions.get(rating);
    if (suggestion) {
      return {
        cleanliness_tags: suggestion.cleanliness_tags,
        respect_house_rules_tags: suggestion.respect_house_rules_tags,
        communication_tags: suggestion.communication_tags
      };
    }
    return undefined;
  }

  public onTagsChange(category: string, selectedTags: string[]): void {
    this.selectedTags[category] = new Set(selectedTags);
    this.reviewForm.markAsDirty();
  }

  private updateSelectedTagsFromSuggestion(currentTags: {
    cleanliness_tags: string[],
    respect_house_rules_tags: string[],
    communication_tags: string[]
  }): void {
    this.selectedTags.cleanliness = new Set(currentTags.cleanliness_tags);
    this.selectedTags.respect_house_rules = new Set(currentTags.respect_house_rules_tags);
    this.selectedTags.communication = new Set(currentTags.communication_tags);
  }
}
