import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

/**
 * The property select dropdown component.
 */
@Component({
  standalone: false,
  selector: 'sbnb-property-select-dropdown',
  templateUrl: './property-select-dropdown.component.html',
  styleUrls: ['./property-select-dropdown.component.scss'],
})
export class PropertySelectDropdownComponent implements OnInit {
  /**
   * The properties to display in the dropdown.
   */
  @Input() properties: any[];

  /**
   * Whether to show the placeholder.
   */
  @Input() showPlaceholder = true;
  @Input() selectedPropertyId: any;
  @Input() defaultOpen = false;
  @Output() selectedPropertyChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild('dropdown') dropdown: MatSelect;

  selectedProperty: any;
  searchCriteria: string;
  filteredProperties: any[];

  constructor() {}

  ngOnInit(): void {
    this.getPropertyObjFromId();
    this.filteredProperties = this.properties;
    if (this.defaultOpen) {
      setTimeout(() => this.dropdown.open(), 100);
    }
  }

  getPropertyObjFromId() {
    this.selectedProperty = this.properties.find((prop) => prop.id === this.selectedPropertyId);
  }

  selectedPropertyChange(newPropertyId) {
    this.filteredProperties = this.properties;
    this.getPropertyObjFromId();
    this.selectedPropertyChanged.emit(newPropertyId);
  }

  filterMyOptions(searchCriteria) {
    if (!searchCriteria || searchCriteria === '') {
      this.filteredProperties = this.properties;
    }

    this.filteredProperties = this.properties.filter((prop) => {
      return (
        prop.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
        prop.location.apt?.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
        prop.location.street?.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1 ||
        prop.location.city?.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1
      );
    });
  }
}
