import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Alteration {
  uuid?: string;
  created_at: string;
  requested_at: string;
  from: string;
  listing: {
    property_id: number;
    listing_id: string;
    user_id: string;
    name: string;
    listing_type: string;
    beds: number;
    baths: number;
    guest_capacity: number;
  };
  original_state: AlterationState;
  proposed_state: AlterationState;
  reservation_code?: string;
  currency?: string;
  price_difference?: string;
  platform: string;
  guest: {
    name: string;
    picture: string;
    email: string;
  };
  payment_enabled: boolean;
  payment_provider: string;
  remaining_payment_terms_total?: string;
}

export interface AlterationState {
  checkInDate?: string; // 2022-03-03
  checkOutDate?: string; // 2022-03-05
  adults?: number;
  children?: number;
  infants?: number;
  pets?: number;
  payout?: string;
  payoutFormatted?: string;
  accommodationPrice?: string;
  accommodationPriceFormatted?: string;
  listingId?: string;
  propertyId?: number;
  totalPriceFormatted: string;
  totalPrice: string;
  remainingPaymentTermsTotal?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlterationsService {
  constructor(private readonly http: HttpClient) {}

  public fetchReservation(bookingId: string) {
    return this.http
      .get(`${environment.apiUrl}/alterations/${bookingId}`)
      .pipe(map((res: { data: Alteration }) => res.data));
  }

  public fetchPriceQuote(bookingId: string, alteration: Alteration, proposedAccommodationPrice: number) {
    return this.http
      .post(`${environment.apiUrl}/alterations/${bookingId}/price-quote`, {
        property_id: alteration.proposed_state.propertyId ?? alteration.listing.property_id,
        start_date: alteration.proposed_state.checkInDate,
        end_date: alteration.proposed_state.checkOutDate,
        number_of_adults: alteration.proposed_state.adults,
        number_of_children: alteration.proposed_state.children,
        number_of_infants: alteration.proposed_state.infants,
        number_of_pets: alteration.proposed_state.pets,
        accommodation_price: proposedAccommodationPrice,
      })
      .pipe(map((res: { data: Alteration }) => res.data));
  }

  public sendAlterationRequest(
    bookingId: string,
    alteration: Alteration,
    initiatedBy = 'host',
    refundAmount = null
  ): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/alterations/${bookingId}`, {
        property_id: alteration.proposed_state.propertyId ?? alteration.listing.property_id,
        start_date: alteration.proposed_state.checkInDate,
        end_date: alteration.proposed_state.checkOutDate,
        number_of_adults: alteration.proposed_state.adults,
        number_of_children: alteration.proposed_state.children,
        number_of_infants: alteration.proposed_state.infants,
        number_of_pets: alteration.proposed_state.pets,
        accommodation_price: alteration.proposed_state.accommodationPrice,
        initiated_by: initiatedBy,
        refund_amount: refundAmount,
      })
      .pipe(map((res: { data: Alteration }) => res.data));
  }
}
