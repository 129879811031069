import { Component, Inject, OnInit } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import {
  EditReviewFormData,
  EditReviewFormSubmissionData,
} from '@app/shared/components/form-edit-review/form-edit-review.component';
import { RuleService } from '@app/shared/services/rule/rule.service';

import { MessageSendReview } from '@app/shared/services/thread/thread.service';

const REVIEW_RULE_PROMPT_SEEN_COUNT = 'review_rule_prompt_seen_count';
const REVIEW_RULE_PROMPT_LIMIT = 3;
@Component({
  standalone: false,
  selector: 'sbnb-dialog-edit-ai-review',
  templateUrl: './dialog-edit-ai-review.component.html',
  styleUrls: ['./dialog-edit-ai-review.component.scss'],
})
export class DialogEditAiReviewComponent implements OnInit {
  public showReviewRulePrompt = false;
  public formData: EditReviewFormSubmissionData;
  constructor(
    private readonly dialogRef: MatDialogRef<DialogEditAiReviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: EditReviewFormData,
    private readonly ruleService: RuleService,
    private readonly router: Router
  ) { }

  public ngOnInit(): void {
    // never checked for rules before, apply the limit
    if (this.getPromptCount() === null) {
      localStorage.setItem(REVIEW_RULE_PROMPT_SEEN_COUNT, String(REVIEW_RULE_PROMPT_LIMIT));
    }
  }

  public onFormSubmit(data: EditReviewFormSubmissionData): void {
    this.formData = data;
    // if they didn't submit anything, or they've seen the prompt 3 times, or they've cancelled
    // or they've marked the review as bad, close the dialog to go back to the thread
    if (
      !this.formData ||
      this.reachedLimit() ||
      (this.formData as { cancelled_at: Date }).cancelled_at ||
      (this.formData as Partial<MessageSendReview>).bad_review
    ) {
      this.onCloseDialog();
      return;
    }
    this.triggerReviewRulePrompt();
  }

  public onCloseDialog(): void {
    this.dialogRef.close(this.formData);
  }

  public onReviewRulePromptDismiss(redirectToRules = false): void {
    if (!redirectToRules) {
      return this.dialogRef.close(this.formData);
    }

    this.dialogRef.close();
    this.router.navigate(['/gx/reviews']);
  }

  private triggerReviewRulePrompt(): void {
    this.decrementReviewRulePromptCount();
    this.ruleService.getReviewRulesets({ limit: 1 }).subscribe(({ data }) => {
      if (data.length) {
        // They do have rules, don't show the prompt or ever check again.
        // Close the dialog and go back to the thread
        localStorage.setItem(REVIEW_RULE_PROMPT_SEEN_COUNT, '0');
        this.dialogRef.close(this.formData);
      } else {
        this.showReviewRulePrompt = true;
      }
    });
  }

  // User has rules or has dismissed the prompt 3 times
  private reachedLimit(): boolean {
    return this.getPromptCount() === '0';
  }

  private decrementReviewRulePromptCount(): void {
    const count = Number(this.getPromptCount());
    localStorage.setItem(REVIEW_RULE_PROMPT_SEEN_COUNT, String(count - 1));
  }

  private getPromptCount(): string {
    return localStorage.getItem(REVIEW_RULE_PROMPT_SEEN_COUNT);
  }
}
