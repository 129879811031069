<header class="flex justify-end">
  <sbnb-close [border]="true" (click)="closeDialog()"></sbnb-close>
</header>

<section style="width: 100%; max-width: 760px; margin: 0 auto">
  <sbnb-progress-stepper [selectedIndex]="currentStage - 1">
    <cdk-step label="Select agreement" [completed]="currentStage !== 1">
      <div class="mt-9">
        <h2 class="mb-4">Add a new rental agreement to this reservation</h2>

        <ng-container *ngIf="availableTemplates$ | async as agreements; else loadingOptionsTemplate">
          <ng-container *ngIf="agreements.length > 0; else noOptionsTemplate">
            <p class="text-night mt-1 mb-3">
              Invite
              <strong>{{ dialogData.guestName }}</strong>
              to sign a new rental agreement. Which rental agreement would you like to add?
            </p>

            <section class="mt-4">
              <mat-form-field class="w-full">
                <mat-select [(ngModel)]="selectedTemplate">
                  <mat-option *ngFor="let template of agreements" [value]="template">
                    {{ template.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </section>

            <!-- preview template -->
            <div *ngIf="selectedTemplate" class="mt-4">
              <h4 class="mb-2">Preview</h4>
              <div
                class="border rounded border-smoke p-4 h-96 overflow-y-auto bg-smoke-lighter"
                [innerHTML]="selectedTemplate?.content"></div>
            </div>
          </ng-container>
        </ng-container>

        <div class="flex justify-between items-center mt-6">
          <sbnb-button type="secondary" (clicked)="closeDialog()">Cancel</sbnb-button>
          <sbnb-button [disabled]="!selectedTemplate" (clicked)="currentStage = 2">Continue</sbnb-button>
        </div>
      </div>
    </cdk-step>

    <cdk-step label="Preview & send request" [completed]="currentStage === 3">
      <div class="mt-9">
        <div class="flex gap-2 items-center mt-2">
          <sbnb-icon icon="message"></sbnb-icon>
          <h3>Message</h3>
        </div>
        <p class="text-night mt-2">Personalize the message that will be sent to {{ dialogData.guestName }}.</p>

        <sbnb-rule-editor
          [simpleMode]="true"
          [simpleInput]="messageTemplate"
          (simpleTextChange)="onMessageTemplateChange($event)"
          [importEnabled]="false"
          [codesEnabled]="true"
          [previewEnabled]="false"></sbnb-rule-editor>

        <sbnb-alert-card
          context="warning"
          *ngIf="message && !message.includes('%guest_portal%') && !message.includes('%guest_portal_tasks%')"
          class="mt-2 flex items-center gap-2">
          <img src="/assets/iconography/warning.svg" onload="SVGInject(this)" alt="Warning" />
          <span>This message must include the %guest_portal% or %guest_portal_tasks% short code.</span>
        </sbnb-alert-card>

        <div class="flex flex-col md:flex-row justify-between gap-4 mt-6">
          <sbnb-button [fullWidth]="true" type="secondary" (clicked)="currentStage = 1">Go back</sbnb-button>
          <div class="flex flex-col md:flex-row gap-2">
            <sbnb-button
              [fullWidth]="true"
              [loading]="submitting"
              [disabled]="!message.includes('%guest_portal%') && !message.includes('%guest_portal_tasks%')"
              (clicked)="sendRentalAgreementRequest()">
              Confirm & send
            </sbnb-button>
          </div>
        </div>
      </div>
    </cdk-step>

    <cdk-step label="Done">
      <div class="mt-9">
        <sbnb-confetti #confetti></sbnb-confetti>

        <sbnb-hero-card [positive]="true">
          <h2 class="nomargin">Rental agreement requested!</h2>
          <div class="flex flex-col gap-2 mt-4">
            <div class="flex gap-2 items-center">
              <sbnb-icon icon="check" class="text-grass-darker"></sbnb-icon>
              A request to sign this rental agreement has been sent to {{ dialogData.guestName }}!
            </div>

            <div class="flex gap-2 items-center">
              <sbnb-icon icon="message" class="text-grass-darker"></sbnb-icon>
              You can see the status of the rental agreement in the inbox at any time.
            </div>
          </div>
        </sbnb-hero-card>

        <div class="flex w-full justify-end mt-10">
          <sbnb-button (clicked)="closeDialog(true)">Done</sbnb-button>
        </div>
      </div>
    </cdk-step>
  </sbnb-progress-stepper>
</section>

<ng-template #loadingOptionsTemplate>
  <sbnb-loading-spinner></sbnb-loading-spinner>
</ng-template>

<ng-template #noOptionsTemplate>
  <sbnb-alert-card context="warning">
    Unable to load available rental agreements. Please try again later.
  </sbnb-alert-card>
</ng-template>
