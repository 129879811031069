<section fxFlexFill fxLayout fxLayoutAlign="center center" class="add-manual-booking">
  <div class="fs-dialog-contents space-y-4" style="margin: auto">
    <div
      class="overview__item"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap.lt-md="24px">
      <h2 fxFlex class="nomargin">Review & Confirm</h2>
    </div>

    <div *ngIf="quote.guest_details">
      <ul style="line-height: 40px">
        <li>
          Hospitable will email
          <strong>{{ quote.guest_details.first_name }} {{ quote.guest_details.last_name }}</strong>
          a link to complete booking and pay.
        </li>
        <li>
          The booking is for {{ quote.computed.numberOfNights }} night
          <span *ngIf="quote.computed.numberOfNights > 1">s</span>
          from
          <strong>{{ quote.checkin | date: 'mediumDate' }}</strong>
          to
          <strong>{{ quote.checkout | date: 'mediumDate' }}</strong>
          at {{ quote.computed.propertyName }}.
        </li>
        <li>
          The total cost of the booking to the guest is
          <strong
            *ngIf="
              quote.computed &&
                quote.computed.pricing &&
                quote.computed.pricing.estimated &&
                quote.computed.pricing.estimated.total;
              else defaultTotal
            ">
            {{
              quote.computed.pricing.estimated.total | currency: quote.currency || defaultCurrency : 'symbol' : '1.2-2'
            }}
          </strong>

          <ng-template #defaultTotal>
            <strong>
              {{
                quote?.computed?.pricing?.original?.total
                  | currency: quote.currency || defaultCurrency : 'symbol' : '1.2-2'
              }}
            </strong>
          </ng-template>
          .
        </li>

        <li>
          If the guest does not complete the payment in
          <strong>
            {{ quote.expires_in }}
          </strong>
          the dates will be unblocked.
        </li>

        <li *ngIf="calendarRestricted">
          Your calendar is limited. Hospitable is unable to automatically block these dates on any connected channels.
        </li>

        <ng-container *ngIf="quote.warnings && quote.warnings.length > 0">
          <li class="raspberry-darker" *ngFor="let warning of quote.warnings">
            <img class="quote-warning-icon" src="/assets/iconography/warning.svg" onload="SVGInject(this)" />
            {{ warning }}
          </li>
        </ng-container>
      </ul>
    </div>

    <div>
      <sbnb-alert-card
        *ngIf="quote.has_calendar_blocks"
        context="warning"
        class="mb-4 gap-2"
        data-element="calendar-blocks-warning-banner">
        <sbnb-icon icon="alert-triangle" class="text-lemon-darker"></sbnb-icon>
        <div class="flex-1">
          <strong>Warning: Selected date(s) are blocked</strong>
          <p class="mt-2">
            One or more of the selected date(s) was blocked on the Calendar, but has not been reserved. If you proceed,
            you will be sending a custom quote for a stay that overlaps with a blocked date.
          </p>
          <p class="mt-2">
            If the guest accepts this quote, the booking will be confirmed. If the guest does not accept this quote, the
            dates will remain blocked.
          </p>
          <p class="mt-2">To proceed, please confirm that you understand to send the quote.</p>
        </div>
      </sbnb-alert-card>

      <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="row" fxLayoutGap.lt-md="8px">
        <div fxFlex>
          <sbnb-button [fullWidth]="true" type="secondary" label="Go Back" (clicked)="onGoBack()"></sbnb-button>
        </div>
        <div fxFlex>
          <sbnb-button
            [loading]="loadingState === LoadingState.Pending"
            [fullWidth]="true"
            [label]="(isMobile$ | async) ? 'Create & notify guest' : 'Create quote and notify guest'"
            (clicked)="onNext()"></sbnb-button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div class="fs-dialog-contents">
      <mat-spinner diameter="32"></mat-spinner>
    </div>
  </ng-template>
</section>
