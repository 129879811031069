<div fxFlexFill id="modal-fs">
  <div fxLayout fxLayoutAlign="space-between center">
    <a [routerLink]="['/']">
      <img class="sbnb-logo" src="/assets/images/logo-transparent-dark.svg" alt="Hospitable logo" />
    </a>
    <sbnb-close [border]="true" mat-dialog-close></sbnb-close>
  </div>

  <section fxFlexFill fxLayout fxLayoutAlign="center center">
    <div class="fs-dialog-contents">
      <mat-spinner *ngIf="loadingRefundDetails" diameter="32"></mat-spinner>

      <ng-container [ngSwitch]="phase" *ngIf="!loadingRefundDetails">
        <!-- Booking.com Cancellation Basis Phase -->
        <div *ngSwitchCase="'booking-cancellation-basis'" class="p-6">
          <h2 class="text-2xl font-semibold mb-4">What is the basis for cancellation?</h2>
          <p class="text-smoke-darker mb-6">Please select the reason for cancelling this Booking.com reservation.</p>

          <div class="grid grid-rows-2 md:grid-rows-none md:grid-cols-2 gap-4">
            <sbnb-radio-card
              (click)="selectCancellationBasis('no_show')"
              [disabled]="!dialogData.supported_actions?.cancellation_due_to_no_show"
              data-element="no-show-option">
              <div class="text-xl font-bold">No-show</div>
              <div class="text-sm">Guest did not show up for check-in.</div>
              <div *ngIf="!dialogData.supported_actions?.cancellation_due_to_no_show" class="text-sm text-red-500 mt-2">
                Not available for this reservation right now.
              </div>
            </sbnb-radio-card>

            <sbnb-radio-card
              (click)="selectCancellationBasis('invalid_cc')"
              [disabled]="!dialogData.supported_actions?.cancellation_due_to_invalid_cc"
              data-element="invalid-cc-option">
              <div class="text-xl font-bold">Invalid Credit Card</div>
              <div class="text-sm">Guest's credit card is invalid or declined</div>
              <div *ngIf="!dialogData.supported_actions?.cancellation_due_to_invalid_cc" class="text-sm text-red-500 mt-2">
                Not available for this reservation right now.
              </div>
            </sbnb-radio-card>
          </div>
        </div>

        <!-- Waive Fees Phase -->
        <div *ngSwitchCase="'waive-fees'" class="p-6">
          <h2 class="text-2xl font-semibold mb-4">Would you like to waive any fees?</h2>
          <p class="text-smoke-darker mb-6">Since the guest did not show up, you can choose to waive some or all of the fees.</p>

          <div class="grid grid-rows-2 md:grid-rows-none md:grid-cols-2 gap-4">
            <sbnb-radio-card (click)="waiveFeesComplete(true)" data-element="waive-fees-yes">
              <div class="text-xl font-bold">Yes</div>
              <div class="text-sm">I want to waive some or all fees</div>
            </sbnb-radio-card>

            <sbnb-radio-card (click)="waiveFeesComplete(false)" data-element="waive-fees-no">
              <div class="text-xl font-bold">No</div>
              <div class="text-sm">Keep all fees</div>
            </sbnb-radio-card>
          </div>
        </div>

        <!-- No-show Review Phase -->
        <div *ngSwitchCase="'no-show-review'" class="p-6">
          <h2 class="text-2xl font-semibold mb-4">Review and confirm</h2>
          <p class="text-smoke-darker mb-6">Please review the details below before confirming the no-show report.</p>

          <div class="space-y-4 mb-6">
            <div class="flex justify-between items-center">
              <span class="text-smoke-darker">Guest</span>
              <strong>{{ dialogData.guestName }}</strong>
            </div>

            <div class="flex justify-between items-center">
              <span class="text-smoke-darker">Check-in</span>
              <strong>{{ dialogData.checkin }}</strong>
            </div>

            <div class="flex justify-between items-center">
              <span class="text-smoke-darker">Check-out</span>
              <strong>{{ dialogData.checkout }}</strong>
            </div>

            <div class="flex justify-between items-center">
              <span class="text-smoke-darker">Waive fees</span>
              <strong>{{ waiveFees ? 'Yes' : 'No' }}</strong>
            </div>
          </div>

          <div class="flex flex-col-reverse md:flex-row items-center justify-end gap-4">
            <sbnb-button
              type="secondary"
              label="Go back"
              (clicked)="phase = 'waive-fees'"
              data-element="go-back-button"></sbnb-button>
            <sbnb-button
              [loading]="attemptingCancellation"
              label="Confirm no-show"
              (clicked)="submitNoShow()"
              data-element="confirm-no-show-button"></sbnb-button>
          </div>
        </div>

        <!-- Invalid CC Review Phase -->
        <div *ngSwitchCase="'invalid-cc-review'" class="p-6">
          <h2 class="text-2xl font-semibold mb-4">Cancel due to invalid credit card</h2>
          <p class="text-smoke-darker mb-6">
            Are you sure you want to cancel the reservation for {{ dialogData.guestName }} due to invalid credit card?
          </p>

          <div class="flex justify-end gap-4">
            <sbnb-button
              type="secondary"
              label="Go back"
              (clicked)="phase = 'booking-cancellation-basis'"
              data-element="go-back-button"></sbnb-button>
            <sbnb-button
              [loading]="attemptingCancellation"
              label="Yes, cancel reservation"
              (clicked)="submitInvalidCc()"
              data-element="confirm-cancel-button"></sbnb-button>
          </div>
        </div>

        <div *ngSwitchCase="'charge-or-refund'" class="charge-or-refund">
          <h2>What would you like to do?</h2>
          <div fxLayoutGap="12px" fxLayoutAlign="start stretch">
            <div
              class="charge-or-refund-option"
              [class.selected]="chargeOrRefund === 'refund'"
              fxFlex
              (click)="chargeOrRefund = 'refund'">
              <img
                width="60"
                height="60"
                [src]="
                  chargeOrRefund === 'refund' ? '/assets/iconography/refund.svg' : '/assets/iconography/refund-grey.svg'
                "
                alt="Refund icon" />
              <h4>Send a refund</h4>
            </div>
            <div
              class="charge-or-refund-option"
              [class.selected]="chargeOrRefund === 'charge'"
              [class.not-clickable]="!canChargeGuest"
              [class.pointer-events]="!canChargeGuest"
              fxFlex
              (click)="chargeOrRefund = 'charge'">
              <img
                width="48"
                height="60"
                [src]="
                  chargeOrRefund === 'charge'
                    ? '/assets/iconography/credit-card.svg'
                    : '/assets/iconography/credit-card-grey.svg'
                "
                alt="Credit card icon" />
              <h4>Request payment</h4>
              <span *ngIf="!canChargeGuest">Coming soon</span>
            </div>
          </div>

          <sbnb-alert-card *ngIf="hasUpcomingPayments" context="warning" class="mt-4">
            <div class="flex items-center gap-2">
              <div class="w-6">
                  <sbnb-icon icon="warning"></sbnb-icon>
              </div>
              <div class="flex-1">
                This reservation has upcoming scheduled payments. The requested amount will not be applied to your guest's current
                balance. Use this option only to request extra fees that were not part of the original booking.
              </div>
            </div>
          </sbnb-alert-card>


          <div fxLayoutAlign="end center" style="margin-top: 24px">
            <sbnb-button
              [disabled]="!chargeOrRefund"
              label="Continue"
              (click)="chargeOrRefund === 'refund' ? (phase = 'amount') : (phase = 'charge')"></sbnb-button>
          </div>
        </div>

        <div *ngSwitchCase="'charge'" class="charge-container">
          <h2 class="nomargin">How much would you like to collect?</h2>
          <span *ngIf="dialogData.reservation?.payment_provider === PayoutMethodProvider.ADYEN" class="helper__text">
            Taxes and fees will be deducted from this amount.
          </span>

          <div fxLayoutAlign="space-between center">
            <span>Charge amount:</span>
            <mat-form-field class="nopadding">
              <span matPrefix class="prefix-bg">
                <span class="text__small" style="padding-right: 12px">{{ dialogData.currency }}</span>
              </span>
              <input matInput type="number" [(ngModel)]="chargeAmount" />
            </mat-form-field>
          </div>

          <div fxLayoutAlign="end center" style="margin-top: 24px">
            <sbnb-button [disabled]="chargeAmount <= 0" label="Continue" (click)="showReasonsScreen()"></sbnb-button>
          </div>
        </div>

        <div *ngSwitchCase="'charge-review'" class="charge-review-container">
          <h2 class="nomargin">Review & confirm</h2>

          <ul style="margin-bottom: 36px; line-height: 1.6">
            <li>
              We will send a payment request for {{ dialogData.currency }}{{ chargeAmount }} to
              {{ dialogData.guestName }}.
            </li>

            <li>We'll notify you when the payment has been captured.</li>

            <li>
              Once the payment is captured you will see it on your Payout History page and in your metrics reports.
            </li>
          </ul>

          <div class="buttons-container" fxLayoutAlign="end center" fxLayoutGap="12px">
            <sbnb-button label="Cancel" type="secondary" mat-dialog-close></sbnb-button>

            <sbnb-button
              label="Confirm"
              [loading]="chargingGuest"
              (click)="chargeGuest(chargeAmount, chargeName, chargeDescription)"></sbnb-button>
          </div>
        </div>

        <div *ngSwitchCase="'reasons-refund'" class="reasons">
          <h2>Why are you issuing a refund?</h2>

          <div fxLayout="column" fxLayoutGap="20px">
            <mat-form-field class="fw nopadding">
              <mat-select placeholder="Select a reason">
                <mat-option value="discount">Negotiated a discount</mat-option>
                <mat-option value="service_unsatisfactory">Service was unsatisfactory</mat-option>
                <mat-option value="property_unsatisfactory">Property was unsatisfactory</mat-option>
                <mat-option value="other">Other</mat-option>
              </mat-select>
            </mat-form-field>

            <textarea class="noresize" placeholder="Enter a note to share with the guest (optional)"></textarea>

            <textarea class="noresize" placeholder="Enter an internal note (optional)"></textarea>
          </div>

          <div fxLayoutAlign="end center" style="margin-top: 24px">
            <sbnb-button [disabled]="!chargeOrRefund" label="Continue" (click)="phase = 'review'"></sbnb-button>
          </div>
        </div>

        <div *ngSwitchCase="'reasons-charge'" class="reasons">
          <h2>What is the payment for?</h2>

          <div fxLayout="column" fxLayoutGap="20px">
            <mat-form-field class="fw">
              <input matInput [(ngModel)]="chargeName" type="text" placeholder="Enter a name" />
              <mat-hint>A name that describes the charge (eg. "Pool fee")</mat-hint>
            </mat-form-field>

            <textarea
              [(ngModel)]="chargeDescription"
              class="noresize"
              placeholder="Enter a note to share with the guest (optional)"></textarea>
            <mat-hint class="textarea-hint">An optional note to send with the payment request</mat-hint>
          </div>

          <div fxLayoutAlign="end center" style="margin-top: 24px">
            <sbnb-button [disabled]="!chargeName" label="Continue" (click)="phase = 'charge-review'"></sbnb-button>
          </div>
        </div>

        <sbnb-dialog-cancel-reservation-initiated-by
          *ngSwitchCase="'initiated-by'"
          (continue)="initiatedByComplete($event)"></sbnb-dialog-cancel-reservation-initiated-by>

        <sbnb-dialog-cancel-reservation-amount
          *ngSwitchCase="'amount'"
          [initiatedBy]="initiatedBy"
          [refundDetails]="refundDetails"
          [directPlan]="directPlan"
          [isCancelling]="dialogData.isCancelling"
          (continue)="amountComplete($event)"></sbnb-dialog-cancel-reservation-amount>

        <sbnb-dialog-cancel-reservation-review
          *ngSwitchCase="'review'"
          [initiatedBy]="initiatedBy"
          [amountToRefund]="amountToRefund"
          [refundDetails]="refundDetails"
          [reservationUuid]="dialogData.reservationUuid"
          [isCancelling]="dialogData.isCancelling"
          [checkin]="dialogData.checkin"
          [checkout]="dialogData.checkout"
          [guestName]="dialogData.guestName"
          [guestEmail]="dialogData.guestEmail"
          [directPlan]="directPlan"
          [currency]="dialogData.currency"
          (savedSuccessfully)="cancellationOrRefundSuccess()"></sbnb-dialog-cancel-reservation-review>
      </ng-container>
    </div>
  </section>
</div>
