<div class="flex items-center justify-between mb-3 clickable" (click)="expanded = !expanded">
  <div class="cera-heading night-darker">Thermostat</div>
  <sbnb-icon icon="small-arrow-bottom"></sbnb-icon>
</div>

<mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>

<div *ngIf="!loading && thermostatSchedules?.length" [hidden]="!expanded">
  <ng-container *ngFor="let thermostatSchedule of thermostatSchedules">
    <ng-container
      *ngIf="
        thermostatSchedule.thermostat &&
        thermostatSchedule.thermostat.device_properties &&
        thermostatSchedule.thermostat.device_properties.climate_setting
      ">
      <div class="text__small flex items-center justify-center mb-2">
        <div class="active-preset" data-element="thermostat-preset-name">
          {{ thermostatSchedule.thermostat.device_properties.climate_setting.display_name }}
        </div>
      </div>

      <div class="text__small mb-4">
        Switching to Occupied Mode on
        {{ thermostatSchedule.thermostat_schedule.start | date: 'dd-MMM'
        }}{{ thermostatSchedule.thermostat_schedule.start | date: ' @ h:mma' | lowercase }}
      </div>

      <!-- Current Temperature -->
      <div class="flex items-center justify-between mb-2">
        <span class="text__small font-bold night-darker">Current Temperature</span>
        <div class="text__small">{{ thermostatSchedule.thermostat.device_properties.temperature.fahrenheit }}°F</div>
      </div>

      <!-- Thermostat Setting -->
      <div class="flex items-center justify-between mb-2">
        <span class="text__small font-bold night-darker">Thermostat Setting</span>
        <div class="flex items-center gap-3">
          <div
            class="flex items-center gap-2 text__small"
            *ngIf="thermostatSchedule.thermostat.device_properties.climate_setting.heating_point">
            <sbnb-icon icon="matches-fire" class="text-coral small-icon"></sbnb-icon>
            {{ thermostatSchedule.thermostat.device_properties.climate_setting.heating_point.fahrenheit }}°F
          </div>

          <div
            class="flex items-center gap-2 text__small"
            *ngIf="thermostatSchedule.thermostat.device_properties.climate_setting.cooling_point">
            <sbnb-icon icon="temperature-snowflake" class="text-aqua small-icon"></sbnb-icon>
            {{ thermostatSchedule.thermostat.device_properties.climate_setting.cooling_point.fahrenheit }}°F
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
