<div class="flex justify-center items-center content-center ">
  <div>
    <svg width="50" height="40" viewBox="0 0 90 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.47656 42.3789L40.9009 3.92191C42.0504 2.77921 43.607 2.14019 45.2278 2.14554C46.8486 2.15089 48.4009 2.80016 49.5429 3.95042L87.9998 42.3789"
        stroke="#E5E2E9" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M75.7819 30.169V79.0313H14.6938V30.1487" stroke="#E5E2E9" stroke-width="4" stroke-linecap="round"
        stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M33 43C33 44.6569 31.6569 46 30 46C28.3431 46 27 44.6569 27 43C27 41.3431 28.3431 40 30 40C31.6569 40 33 41.3431 33 43ZM63 43C63 44.6569 61.6569 46 60 46C58.3431 46 57 44.6569 57 43C57 41.3431 58.3431 40 60 40C61.6569 40 63 41.3431 63 43ZM37.9152 59.8545C37.1972 60.2678 36.2801 60.0209 35.8668 59.3029C35.4534 58.585 35.7004 57.6679 36.4183 57.2546C39.3867 55.5455 47.0985 53.4341 55.4995 57.6005C56.2417 57.9686 56.5449 58.8686 56.1768 59.6108C55.8088 60.353 54.9087 60.6562 54.1666 60.2882C46.8001 56.6348 40.1545 58.5651 37.9152 59.8545Z"
        fill="#D0CDD4" />
    </svg>
  </div>
  <h5 class="pl-5 ">{{message}}</h5>
</div>
<ng-content></ng-content>