<div class="note" [ngClass]="type" *ngIf="!isDismissible || (isDismissible && !isDismissed)">
  <div class="note__content">
    <div class="note-banner__graphic">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="NoteType.Info">
          <sbnb-icon [size]="24" icon="alert-circle" class="text-smoke-darker"></sbnb-icon>
        </ng-container>
        <ng-container *ngSwitchCase="NoteType.Warning">
          <sbnb-icon [size]="24" icon="alert-triangle" class="text-raspberry-darker"></sbnb-icon>
        </ng-container>
        <ng-container *ngSwitchCase="NoteType.Success">
          <sbnb-icon [size]="24" icon="check-circle" class="text-grass-darker"></sbnb-icon>
        </ng-container>

        <ng-container *ngSwitchCase="NoteType.Custom">
          <img [src]="iconSrc" onload="SVGInject(this)" />
        </ng-container>
      </ng-container>
    </div>
    <div class="note-banner__text" [innerHTML]="content | safe: 'html'"></div>
    <div class="note-banner__dismiss" *ngIf="isDismissible">
      <div class="clickable" fxLayoutAlign="center center" (click)="dismiss()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" class="x-button" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.75 16.25L16.25 3.75"
            [attr.stroke]="closeColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M3.75 3.75L16.25 16.25"
            [attr.stroke]="closeColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
