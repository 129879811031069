import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThreadService } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-translate-conversation',
  template: `
    <button
      [ngClass]="{ 'text-raspberry-darker': usingTranslations, 'text-night-lighter': !usingTranslations }"
      [matTooltip]="usingTranslations ? 'Turn translation off' : 'Turn translation on'"
      (click)="toggleTranslations()">
      <sbnb-icon [size]="24" icon="language-translation"></sbnb-icon>
    </button>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslateConversationComponent implements OnInit {
  @Input() usingTranslations = false;
  @Input() threadUuid: string;
  @Output() translationsToggled = new EventEmitter<boolean>();

  constructor(private readonly threadService: ThreadService) { }

  ngOnInit(): void { }

  public toggleTranslations() {
    this.threadService.toggleTranslations(this.threadUuid, !this.usingTranslations).subscribe((res) => {
      if (res.success) {
        this.translationsToggled.emit(!this.usingTranslations);
      }
    });
  }
}
