export enum DefaultRuleSet {
  CheckInInstructions = 'Check-in instructions',
  CheckOutInstructions = 'Check-out instructions',
  ExpiredPreApproval = 'Expired pre-approval',
  FirstMorning = 'First morning',
  NewBookingInquiry = 'New booking inquiry',
  NewPreApproval = 'New pre-approval',
  NewRequestToBook = 'New request to book',
  NewReservation = 'New reservation',
  PaymentIssues = 'Payment issues',
  ReviewRequest = 'Review request',
}
