<div *ngIf="securityDeposit">
  <div class="flex items-center justify-between mb-1">
    <div class="text-night-darker text-sm strong">Security deposit</div>
    <div
      class="strong text-sm"
      [class.text-grass-darker]="securityDeposit.status === SecurityDepositStatus.SECURED"
      [class.text-raspberry-darker]="securityDeposit.status === SecurityDepositStatus.RETRYING">
      {{ securityDeposit.status | titlecase }}
    </div>
  </div>
  <div class="text-night-lighter text__small">
    {{ securityDeposit.amount.formatted_amount }} deposit from {{ securityDeposit.secure_at | date: 'MMM d' }} -
    {{ securityDeposit.release_at | date: 'MMM d' }}
  </div>
  <sbnb-button
    *ngIf="securityDeposit.status === SecurityDepositStatus.SECURED && securityDeposit.claim === null"
    class="mt-4 block"
    size="mini"
    type="secondary"
    [fullWidth]="true"
    (clicked)="openClaimSecurityDepositDialog()">
    Charge deposit
  </sbnb-button>
</div>
