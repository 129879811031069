<div class="support-documentation__article-list">
  <sbnb-support-documentation-title [id]="id" [title]="title" [icon]="icon"></sbnb-support-documentation-title>
  <div class="wrapper">
    <div class="chunk" *ngFor="let chunk of chunkedArticles">
      <div class="article" *ngFor="let article of chunk">
        <sbnb-article-link [article]="article"></sbnb-article-link>
      </div>
    </div>
  </div>
</div>
