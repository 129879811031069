<div *ngIf="!reviewId || !reviewDetails" style="padding: 24px">
  <mat-spinner diameter="16"></mat-spinner>
</div>

<div *ngIf="reviewId && reviewDetails" class="dialog__container">
  <div class="dialog__header">
    <h3 fxLayout fxLayoutAlign="start center" fxLayoutGap="10px" class="nomargin">
      <div fxFlex fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
        <span class="ellipsis">{{ reviewDetails.title }}</span>
        <sbnb-pill fxHide.lt-md theme="lemon" label="Scheduled"></sbnb-pill>
      </div>
      <div fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
        <a fxHide.lt-md [routerLink]="['/gx/log/reviews/' + reviewId]" (click)="dialogRef.close()">
          <sbnb-button label="View log" type="primary" size="mini"></sbnb-button>
        </a>
        <sbnb-close (click)="dialogRef.close()"></sbnb-close>
      </div>
    </h3>
    <div class="night" style="margin-top: 12px; line-height: 1.6">
      This review will be sent to {{ reviewDetails.guest.name }} on
      <span>{{ reviewDetails.scheduled_for_label }}</span>
    </div>

    <a
      fxHide
      fxShow.lt-md
      [routerLink]="['/gx/log/reviews/' + reviewId]"
      (click)="dialogRef.close()"
      style="display: block; width: 100%; margin-top: 16px">
      <sbnb-button [fullWidth]="true" label="View log" type="primary" size="mini"></sbnb-button>
    </a>
  </div>

  <div class="dialog__contents">
    <div class="rule__container">
      <sbnb-rule-editor
        [fixedHeight]="160"
        [simpleMode]="true"
        [simpleInput]="reviewDetails.prepared_message"
        (simpleTextChange)="updatedPreparedMessage = $event"></sbnb-rule-editor>
    </div>
    <div class="flex w-full review__item">
      <strong class="flex-1">Private feedback</strong>
      <span class="flex-1">
        <textarea
          [(ngModel)]="reviewDetails.private_feedback"
          placeholder="Write your private feedback here"
          class="review__textarea"></textarea>
      </span>
    </div>

    <div class="flex items-center review__item">
      <strong class="flex-1">Recommend</strong>
      <span class="flex flex-1" style="gap: 18px">
        <img
          class="clickable"
          [src]="
            reviewDetails.review.grades.recommend
              ? '/assets/iconography/thumbs-down.svg'
              : '/assets/iconography/thumbs-down-red.svg'
          "
          (click)="reviewDetails.review.grades.recommend = false" />
        <img
          class="clickable"
          [src]="
            !reviewDetails.review.grades.recommend
              ? '/assets/iconography/thumbs-up.svg'
              : '/assets/iconography/thumbs-up-green.svg'
          "
          (click)="reviewDetails.review.grades.recommend = true" />
      </span>
    </div>

    <div class="flex items-center review__item">
      <strong class="flex-1">Cleanliness</strong>
      <span class="flex-1">
        <sbnb-stars
          [startingCount]="reviewDetails.review.grades.cleanliness"
          (updated)="reviewDetails.review.grades.cleanliness = $event"></sbnb-stars>
      </span>
    </div>

    <div fxLayout fxLayoutAlign="start center" class="review__item">
      <strong class="flex-1">Respect of house rules</strong>
      <span class="flex-1">
        <sbnb-stars
          [startingCount]="reviewDetails.review.grades.respect_house_rules"
          (updated)="reviewDetails.review.grades.respect_house_rules = $event"></sbnb-stars>
      </span>
    </div>

    <div fxLayout fxLayoutAlign="start center" class="review__item">
      <strong class="flex-1">Communication</strong>
      <span class="flex-1">
        <sbnb-stars
          [startingCount]="reviewDetails.review.grades.communication"
          (updated)="reviewDetails.review.grades.communication = $event"></sbnb-stars>
      </span>
    </div>

    <div class="bad__review" fxLayout fxLayout.lt-md="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px">
      <mat-slide-toggle
        [checked]="reviewDetails.review.bad_review"
        (change)="reviewDetails.review.bad_review = $event.checked"></mat-slide-toggle>
      <strong>Bad review</strong>
      <span
        class="night-lighter text__small bad__review-helper"
        [ngClass.gt-sm]="{ 'bad__review-helper-mobile': true }">
        Delay the publishing of this review to the very last minute of the review period.
      </span>
    </div>

    <div fxLayout fxLayoutAlign="space-between" fxLayout.lt-md="row wrap" fxLayoutGap="16px grid">
      <sbnb-button
        *ngIf="reviewDetails._links.cancel"
        label="Don't sent"
        fxFlex.lt-md
        [fullWidth]="true"
        [disabled]="deleting || saving"
        type="text"
        labelColor="#ed3c6a"
        icon="not-allowed"
        (clicked)="deleteMessage()"></sbnb-button>

      <sbnb-button
        fxHide
        fxShow.lt-md
        fxFlex.lt-md
        [fullWidth]="true"
        label="Send now"
        [disabled]="deleting || saving || reviewDetails.review.bad_review"
        type="secondary"
        (clicked)="saveUpdatedMessage(true)"></sbnb-button>

      <sbnb-button
        fxHide
        fxShow.lt-md
        fxFlex="100"
        [fullWidth]="true"
        label="Save"
        [disabled]="deleting || saving"
        type="primary-teal"
        (clicked)="saveUpdatedMessage()"></sbnb-button>

      <div *ngIf="reviewDetails._links.update" fxHide.lt-md fxLayout fxLayout.lt-md="column" fxLayoutGap="16px">
        <sbnb-button
          label="Send now"
          [disabled]="deleting || saving || reviewDetails.review.bad_review"
          type="secondary"
          (clicked)="saveUpdatedMessage(true)"></sbnb-button>

        <sbnb-button
          label="Save"
          [disabled]="deleting || saving"
          type="primary-teal"
          (clicked)="saveUpdatedMessage()"></sbnb-button>
      </div>
    </div>
  </div>
</div>
