import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AlgoliaService } from '../algolia.service';

// export interface DirectQuote {

//   sub_total?: {
//     value: number;
//     title: string;
//   };

//   fees?: {
//     [key in DirectFee | string]: { title: string; value: string };
//   };

// }

export type DirectCustomQuote = NewDirectCustomQuote;

export interface NewDirectCustomQuote {
  property_id?: string;
  checkin: string;
  checkout: string;
  num_guests: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  guest_details: {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    note?: string;
  };
  locale: string;
  currency: string;

  warnings?: string[];
  has_calendar_blocks?: boolean;
  expires_in: QuoteExpirations;

  // FE computed variables
  computed?: {
    guestName?: string;
    isSuperhogVerified?: boolean;
    propertyName?: string;
    numberOfNights?: number;

    pricing?: {
      customFees: {
        [key: string]: EstimatePricingItem;
      };
      original: DirectCustomQuoteEstimatePricingResponse['original'];
      estimated: DirectCustomQuoteEstimatePricingResponse['data'];
    };
  };
}

export interface CreateDirectCustomQuotePayload {
  property_id: string;
  checkin: string;
  checkout: string;
  num_guests: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  guest_details: {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    note?: string;
  };
  locale: string;
  currency: string;
  pricing?: {
    nightly_total: number;
    fees: {
      [key in DirectFee | string]: number | string;
    };
  };
  expires_in: QuoteExpirations;
}

export interface DirectCustomQuoteEstimatePricingPayload {
  checkin: string;
  checkout: string;
  guest_details: {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    note?: string;
  };
  num_guests: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  property_id: string;
  pricing: {
    nightly_total: string;
    fees: {
      [key in DirectFee | string]: number | string;
    };
  };
}

export interface EstimatePricingItem {
  value: string;
  title: string;
  subtitle?: string;
}

export interface DirectCustomQuotePricing {
  nightly: EstimatePricingItem;
  fees: {
    [key in DirectFee | string]: EstimatePricingItem;
  };
  tax: string;
  service_fee: string;
  total: string;
}

export interface DirectCustomQuoteEstimatePricingResponse {
  data: DirectCustomQuotePricing;
  original: DirectCustomQuotePricing;
  warnings: string[];
}

export enum DirectFee {
  Cleaning = 'cleaning_fee',
  Linens = 'linens_fee',

  Community = 'community_fee',
  Resort = 'resort_fee',
  Pet = 'pet_fee',
  PricePerExtraPerson = 'price_per_extra_person',
}

export enum QuoteExpirations {
  TwentyFourHours = '24 hours',
  FortyEightHours = '48 hours',
  SeventyTwoHours = '72 hours',
  OneWeek = '1 week',
}

@Injectable({
  providedIn: 'root',
})
export class DirectQuotesService {
  constructor(
    private readonly http: HttpClient,
    private algoliaService: AlgoliaService
  ) {
    this.algoliaService.requestSecureAlgoliaKey().subscribe();
  }

  // public createQuote(quote: DirectQuote): Observable<any> {
  //   return this.http.post(`${environment.bookingApiUrl}/properties/${quote.property_id}/quote`, quote);
  // }

  public customQuoteEstimatePricing(quote: DirectCustomQuoteEstimatePricingPayload): Observable<any> {
    return this.http.post(
      `${environment.bookingApiUrl}/properties/${quote.property_id}/custom-quote/estimated-pricing/v2`,
      quote
    );
  }

  public createCustomQuote(quote: CreateDirectCustomQuotePayload): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/properties/${quote.property_id}/custom-quote`, quote);
  }

  public searchGuests(searchString: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/guests/search`, {
      search_key: this.algoliaService.algoliaSecureApiKey,
      query: searchString,
    });
  }
}
