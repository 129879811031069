import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { DirectSubscriptionService } from '@app/modules/direct/services/direct-subscription.service';
import { BillingFacade } from '@app/shared/+state/billing';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-settings-subnav',
  templateUrl: './settings-subnav.component.html',
  styleUrls: ['./settings-subnav.component.scss'],
})
export class SettingsSubnavComponent implements OnInit {
  environment = environment;

  perms$ = this.userPermissionsService;
  Features = Features;
  public enableTaxes$ = this.directSubscription.isBasic$;
  public notExpired$ = this.authService.isLoggedIn$.pipe(
    switchMap((isLoggedIn) => {
      if (!isLoggedIn) {
        return of(false);
      }
      return this.billingFacade.notExpiredCheck$;
    }),
    shareReplay(1)
  );

  constructor(
    public authService: AuthenticationService,
    public optimizely: OptimizelyService,
    private readonly userPermissionsService: UserPermissionsService,
    private directSubscription: DirectSubscriptionService,
    private readonly billingFacade: BillingFacade
  ) {}

  ngOnInit() {}
}
