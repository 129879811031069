import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'matchPropertySearchCriteria',
})
export class MatchPropertySearchCriteriaPipe implements PipeTransform {
  transform(propertyName: any, searchCriteria: any): any {
    if (!searchCriteria || searchCriteria === '') {
      return true;
    } else {
      return propertyName.toLowerCase().includes(searchCriteria.toLowerCase());
    }
  }
}
