import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  RefundDetails,
  ReservationCancellationRefundService,
} from '@app/shared/services/reservation-cancellation-refund.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-cancel-reservation-review',
  templateUrl: './dialog-cancel-reservation-review.component.html',
  styleUrls: ['./dialog-cancel-reservation-review.component.scss'],
})
export class DialogCancelReservationReviewComponent implements OnInit {
  @Input() initiatedBy: 'host' | 'guest';
  @Input() amountToRefund: number;
  @Input() refundDetails: RefundDetails;
  @Input() isCancelling: boolean;
  @Input() checkin: string;
  @Input() checkout: string;
  @Input() guestName: string;
  @Input() guestEmail: string;
  @Input() currency: string;
  @Input() reservationUuid: string;
  @Input() directPlan: 'premium' | 'basic';

  @Output() savedSuccessfully: EventEmitter<boolean> = new EventEmitter();

  actioning: boolean = false;

  constructor(private cancelRefundService: ReservationCancellationRefundService) { }

  ngOnInit(): void { }

  cancelAndRefund() {
    this.actioning = true;

    this.cancelRefundService.cancelReservation(this.reservationUuid, this.initiatedBy).subscribe(
      (res) => {
        this.actioning = false;
        this.savedSuccessfully.emit(true);
      },
      (err) => {
        this.actioning = false;
      }
    );
  }

  issueRefund() {
    this.actioning = true;

    this.cancelRefundService.issueRefund(this.reservationUuid, this.initiatedBy, this.amountToRefund).subscribe(
      (res) => {
        this.actioning = false;
        this.savedSuccessfully.emit(true);
      },
      (err) => {
        this.actioning = false;
      }
    );
  }
}
