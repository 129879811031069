import { Pipe, PipeTransform } from '@angular/core';
import { MessageChat, ThreadMessage } from '@app/shared/services/thread/thread.service';

@Pipe({
  standalone: false,
  name: 'isChatMessage',
})
export class IsChatMessagePipe implements PipeTransform {
  transform(value: ThreadMessage): value is MessageChat {
    return value.entity_type === 'chat';
  }
}
