import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DialogCreateEmailRouteComponent } from '@app/modules/apps/components/dialog-create-email-route/dialog-create-email-route.component';
import { PayoutMethodProvider } from '@app/modules/direct/services/payout-methods.service';
import { NotificationType } from '@app/shared/interfaces';
import { ReservationStatus } from '@app/shared/interfaces/lib/guestvetting.interface';
import {
  RefundDetails,
  ReservationCancellationRefundService,
} from '@app/shared/services/reservation-cancellation-refund.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

type CancellationPhase =
  | 'charge-or-refund'
  | 'initiated-by'
  | 'amount'
  | 'reasons'
  | 'reasons-charge'
  | 'review'
  | 'charge-review'
  | 'charge'
  | 'booking-cancellation-basis'
  | 'waive-fees'
  | 'no-show-review'
  | 'invalid-cc-review';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-cancel-reservation-flow',
  templateUrl: './dialog-cancel-reservation-flow.component.html',
  styleUrls: ['./dialog-cancel-reservation-flow.component.scss'],
})
export class DialogCancelReservationFlowComponent implements OnInit, OnChanges {
  PayoutMethodProvider = PayoutMethodProvider;

  phase: CancellationPhase = 'initiated-by';

  initiatedBy: 'host' | 'guest' | null = null;
  amountToRefund: number;

  refundDetails: RefundDetails = null;
  loadingRefundDetails = true;

  directPlan: 'premium' | 'basic';

  chargeOrRefund: 'charge' | 'refund' | null = null;

  chargeAmount: number | null = null;
  chargeName = '';
  chargeNoteToGuest = '';
  chargeDescription = '';
  chargingGuest = false;

  attemptingCharge = false;
  attemptingCancellation = false;

  canChargeGuest = Boolean(this.dialogData.supported_actions?.charges);

  hasUpcomingPayments = false;

  // New properties for Booking.com flows
  cancellationBasis: 'no_show' | 'invalid_cc' | null = null;
  waiveFees: boolean | null = null;
  isBookingDotCom = false;

  constructor(
    private cancelRefundService: ReservationCancellationRefundService,
    public dialogRef: MatDialogRef<DialogCreateEmailRouteComponent>,
    private refundService: ReservationCancellationRefundService,
    private toast: ToastNotificationsService,

    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      reservationUuid: string;
      reservation: any;
      isCancelling: boolean;
      checkin: string;
      checkout: string;
      guestName: string;
      guestEmail: string;
      currency: string;
      supported_actions: any;
      isBookingDotCom?: boolean;
    }
  ) {
    this.isBookingDotCom = dialogData.isBookingDotCom || false;
  }

  ngOnInit(): void {
    if (!this.areWeCancelling()) {
      this.phase = 'charge-or-refund';
      this.initiatedBy = 'guest';
      this.chargeOrRefund = 'refund';
      this.fetchRefundDetails();
    } else if (this.isBookingDotCom) {
      this.initiatedBy = 'host';
      this.phase = 'booking-cancellation-basis';
      this.loadingRefundDetails = false;
    } else {
      this.loadingRefundDetails = false;
    }

    this.updateHasUpcomingPayments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dialogData && changes.dialogData.currentValue) {
      this.updateHasUpcomingPayments();
    }
  }

  private updateHasUpcomingPayments(): void {
    const reservation = this.dialogData.reservation;

    if (!reservation) {
      this.hasUpcomingPayments = false;
      return;
    }

    if (reservation.status.toLowerCase() !== ReservationStatus.ACCEPTED) {
      this.hasUpcomingPayments = false;
      return;
    }

    const paymentTerms = reservation.payment_terms;
    if (!paymentTerms || !Array.isArray(paymentTerms)) {
      this.hasUpcomingPayments = false;
      return;
    }

    this.hasUpcomingPayments = paymentTerms.some((term) => term.status === 'unknown' || term.can_extend === true);
  }

  showReasonsScreen() {
    this.phase = 'reasons-charge';
  }

  fetchRefundDetails() {
    if (!this.dialogData?.reservationUuid) {
      this.dialogRef.close();
      return;
    }

    this.cancelRefundService.fetchRefundDetails(this.dialogData.reservationUuid).subscribe((res) => {
      this.loadingRefundDetails = false;

      if (!res) {
        this.dialogRef.close();
        return;
      }

      this.refundDetails = res;

      if (this.refundDetails.payment_provider === 'adyen') {
        this.directPlan = 'premium';
      } else {
        this.directPlan = 'basic';
      }
    });
  }

  areWeCancelling(): boolean {
    return this.dialogData.isCancelling;
  }

  initiatedByComplete(initiatedBy: 'host' | 'guest') {
    this.initiatedBy = initiatedBy;

    const processRefundDetails = () => {
      this.directPlan = this.refundDetails.payment_provider === 'adyen' ? 'premium' : 'basic';
      this.phase = this.refundDetails.max_refundable_amount > 0 ? 'amount' : 'review';

      if (this.refundDetails.max_refundable_amount <= 0) {
        this.amountToRefund = 0;
      }
    };

    if (this.areWeCancelling()) {
      // Wait for fetchCancellationQuote to complete before proceeding
      this.loadingRefundDetails = true;

      this.cancelRefundService
        .fetchCancellationQuote(this.dialogData.reservationUuid, this.initiatedBy)
        .subscribe((res) => {
          this.loadingRefundDetails = false;

          if (!res) {
            this.dialogRef.close();
            return;
          }

          this.refundDetails = res;
          processRefundDetails();
        });

      return;
    }

    processRefundDetails();
  }

  amountComplete(amount: number) {
    this.amountToRefund = amount;
    this.phase = 'review';
  }

  chargeGuest(chargeAmount: number, chargeName: string, chargeNoteToGuest: string) {
    this.attemptingCharge = true;
    this.refundService
      .adhocChargeGuest(this.dialogData.reservationUuid, chargeAmount, chargeName, chargeNoteToGuest)
      .subscribe(
        (res) => {
          this.attemptingCharge = false;
          this.dialogRef.close(true);
        },
        (err) => {
          this.attemptingCharge = false;

          this.toast.open('Unable to send a payment link for this reservation', 'OK', NotificationType.Error);
        }
      );
  }

  cancellationOrRefundSuccess() {
    this.dialogRef.close(true);
  }

  waiveFeesComplete(waiveFees: boolean) {
    this.waiveFees = waiveFees;
    this.phase = 'no-show-review';
  }

  selectCancellationBasis(basis: 'no_show' | 'invalid_cc') {
    this.cancellationBasis = basis;
    if (basis === 'no_show') {
      this.phase = 'waive-fees';
    } else {
      this.phase = 'invalid-cc-review';
    }
  }

  submitNoShow() {
    this.attemptingCancellation = true;

    this.cancelRefundService
      .cancelReservation(this.dialogData.reservationUuid, 'host', this.waiveFees, 'no_show', true)
      .subscribe(
        () => {
          this.attemptingCancellation = false;
          this.dialogRef.close(true);
        },
        (err) => {
          this.attemptingCancellation = false;
          const message =
            err instanceof HttpErrorResponse ? err.error.error : 'Unable to report no-show for this reservation.';
          this.toast.open(message, 'Dismiss', NotificationType.Error);
        }
      );
  }

  submitInvalidCc() {
    this.attemptingCancellation = true;

    this.cancelRefundService
      .cancelReservation(this.dialogData.reservationUuid, 'host', false, 'invalid_cc', true)
      .subscribe(
        () => {
          this.attemptingCancellation = false;
          this.dialogRef.close(true);
        },
        (err) => {
          this.attemptingCancellation = false;
          const message =
            err instanceof HttpErrorResponse
              ? err.error.error
              : 'Unable to cancel reservation due to invalid credit card.';
          this.toast.open(message, 'Dismiss', NotificationType.Error);
        }
      );
  }
}
