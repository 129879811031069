<div fxLayout>
  <h3 fxFlex>Properties for {{ ruleName }}</h3>
  <img class="clickable" src="/assets/iconography/cancel.svg" width="24px" height="24px" (click)="close()" />
</div>

<div class="property__container">
  <div
    *ngFor="let property of properties"
    class="property"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="14px"
    [routerLink]="property?.id && ['/properties/property', property.id, 'merge-match']"
    [class.link-emphasized]="property?.id"
    data-element="property-link"
    (click)="property?.id && close()">
    <img class="property__picture" [src]="property.picture" />
    <span>{{ property.name }}</span>
  </div>

  <div *ngIf="dialogData.propertyCount - properties.length > 0" class="text__small night-lighter">
    + {{ dialogData.propertyCount - properties.length }} more
  </div>
</div>
