<div *ngIf="{ agreements: (agreements$ | async) } as vm" [class.updated-styling]="updatedStyling">
  <header>
    <div class="text-night-darker text-sm mb-1 strong">Rental agreements</div>
  </header>

  <ng-container *ngIf="loading; else loadedTemplate">
    <mat-spinner diameter="24"></mat-spinner>
  </ng-container>

  <ng-template #loadedTemplate>
    <ng-container *ngIf="vm.agreements; else noAgreements">
      <section *ngIf="vm.agreements.length > 0; else noAgreements">
        <div
          *ngFor="let agreement of vm.agreements"
          class="flex justify-between gap-3 text-sm agreement"
          (click)="openRentalAgreementDialog(agreement)">
          <span class="w-full truncate">{{ agreement.name }}</span>
          <a
            class="strong cursor-pointer w-auto flex-shrink-0"
            [class.link]="agreement.signatory"
            [class.link-emphasized]="!agreement.signatory">
            {{ agreement.signatory ? 'Signed' : 'Pending' }}
          </a>
        </div>
      </section>
    </ng-container>

    <ng-container *ngIf="availableTemplates$ | async as templates">
      <sbnb-button
        *ngIf="canAddAgreement && templates.length > 0"
        class="mt-4 block"
        [size]="updatedStyling ? 'normal' : 'mini'"
        [type]="updatedStyling ? 'primary' : 'secondary'"
        [fullWidth]="true"
        (clicked)="openRequestNewRentalAgreementDialog()">
        Request new signature
      </sbnb-button>
    </ng-container>
  </ng-template>

  <ng-template #noTemplates>
    <div class="text-night-lighter text__small px-4 py-2">No rental agreement templates available.</div>
  </ng-template>

  <ng-template #noAgreements>
    <div class="text-night-lighter text__small">No rental agreements requested.</div>
  </ng-template>
</div>
