import { Pipe, PipeTransform } from '@angular/core';
import { isPast } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isPast',
})
export class IsPastPipe implements PipeTransform {
  transform(value: Date | string): boolean {
    return isPast(value);
  }
}
