import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownOption } from './dropdown.component';

@Component({
  selector: 'sbnb-selected-labels',
  template: `
    <div class="flex items-center gap-1">
      <ng-container *ngFor="let option of selectedOptions; let last = last">
        <span class="ellipsis">{{ option.label }}</span>
        <span *ngIf="!last">,</span>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedLabelsComponent {
  @Input() options: DropdownOption[] = [];
  @Input() selectedValues: string | string[] = '';

  get selectedOptions(): DropdownOption[] {
    if (!this.selectedValues) {
      return [];
    }
    const values = Array.isArray(this.selectedValues) ? this.selectedValues : [this.selectedValues];
    return values
      .map((value) => this.options.find((option) => option.value === value))
      .filter((option): option is DropdownOption => !!option);
  }
}
