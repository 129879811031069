import { Component, Input, OnInit } from '@angular/core';
import { IcalChannel } from '@app/shared/models/channel';

@Component({
  standalone: false,
  selector: 'sbnb-icon-ical-platform',
  templateUrl: './icon-ical-platform.component.html',
})
export class IconIcalPlatformComponent implements OnInit {
  @Input() height = '14px';
  @Input() width = '14px';
  @Input() platform: IcalChannel;
  @Input() name: string | null;
  @Input() cssClass: string;

  Channel = IcalChannel;

  constructor() {}

  ngOnInit(): void {}

  hasChannelPlatform(platform: IcalChannel): boolean {
    return Object.values(IcalChannel).indexOf(platform) !== -1;
  }
}
