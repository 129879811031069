import { Component, Input } from '@angular/core';
import { SentimentAnalysisService } from '@app/shared/services/sentiment-analysis/sentiment-analysis.service';

@Component({
  selector: 'sbnb-sentiment-label',
  templateUrl: './sentiment-label.component.html',
})
export class SentimentLabelComponent {
  @Input() label: string;
  @Input() dataElement: string = 'sentiment-label';

  public enabled$ = this.sentimentAnalysisService.enabled$;

  constructor(private sentimentAnalysisService: SentimentAnalysisService) {}
}
