<div class="flex gap-2 items-center">
  <button
    [style.height.px]="size"
    [style.width.px]="size"
    [class.selected]="(value$ | async) === false"
    (click)="valChanged(false)"
    [disabled]="disabled"
    [ngClass]="{ muted: muted }">
    <mat-icon [style.font-size.px]="size" [style.height.px]="size" [style.width.px]="size">close</mat-icon>
  </button>
  <button
    [style.height.px]="size"
    [style.width.px]="size"
    [class.selected]="(value$ | async) === true"
    (click)="valChanged(true)"
    [disabled]="disabled"
    [ngClass]="{ muted: muted }">
    <mat-icon [style.font-size.px]="size" [style.height.px]="size" [style.width.px]="size">done</mat-icon>
  </button>
</div>
