import { ChangeDetectionStrategy, Component, ContentChild, Directive, OnInit, TemplateRef } from '@angular/core';
import { UpsellService } from '@app/shared/+state/shared/upsell.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  standalone: false,
  selector: '[sbnbDeviceUpsellButtonContent]',
})
export class DeviceUpsellButtonContentDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  standalone: false,
  selector: 'sbnb-device-upsell-button',
  template: `
    <ng-container *ngIf="conditions$ | async as conditions">
      <!-- If the entitlement is not available at all, send the host to
       upgrade their plain -->
      <ng-container *ngIf="conditions.entitlement.available; else upgradeLink">
        <!-- If the entitlement is available but it requires an addon agreement,
         send them into the Manage Device Addon wizard -->
        <ng-container *ngIf="!conditions.requiresDeviceAddon; else addAddonLink">
          <!-- Show the original content -->
          <ng-container *ngTemplateOutlet="content?.templateRef"></ng-container>
        </ng-container>
      </ng-container>

      <ng-template #upgradeLink>
        <a [routerLink]="['/settings/your-plan']">
          <sbnb-button>Upgrade for access</sbnb-button>
        </a>
      </ng-template>
      <ng-template #addAddonLink>
        <a [routerLink]="['/settings/manage-device-addon']" [queryParams]="{ closeLink: '/devices/setup' }">
          <sbnb-button>Connect a device account</sbnb-button>
        </a>
      </ng-template>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceUpsellButtonComponent implements OnInit {
  @ContentChild(DeviceUpsellButtonContentDirective) content!: DeviceUpsellButtonContentDirective;
  public conditions$ = combineLatest([
    this.upsellService.hasSmartDeviceEntitlement$,
    this.upsellService.requiresDeviceAddon$,
  ]).pipe(
    map(([entitlement, requiresDeviceAddon]) => ({
      entitlement,
      requiresDeviceAddon,
    }))
  );
  constructor(private readonly upsellService: UpsellService) {}

  ngOnInit(): void {}
}
