import { Pipe, PipeTransform } from '@angular/core';
import { humanize } from '@app/shared/utils/lib/general.util';

/**
 * Converts a string from snake_case to human readable format
 * Capitalizes the first letter of each word
 */
@Pipe({
  standalone: false,
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: string): string {
    return humanize(value);
  }
}
