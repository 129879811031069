import { Component, Input } from '@angular/core';
import { SignupChannelWrapper } from '@app/modules/user/signup/signup.interface';

@Component({
  standalone: false,
  selector: 'sbnb-channels-image',
  templateUrl: './channels-image.component.html',
  styleUrls: ['./channels-image.component.scss'],
})
export class ChannelsImageComponent {
  @Input() channels: SignupChannelWrapper[] = [];
  @Input() avatar?: string;
}
