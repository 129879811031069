import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-empty-state-template',
  templateUrl: './empty-state-template.component.html',
  styleUrls: ['./empty-state-template.component.scss'],
})
export class EmptyStateTemplateComponent implements OnInit {
  @Input() title: string;
  @Input() image: string;
  @Input() imageAlt: string;

  constructor() { }

  ngOnInit(): void { }
}
