import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'taskWidth',
})
export class TaskWidthPipe implements PipeTransform {
  minutesInADay = 1440;

  transform(task: any, dayWidth: number): any {
    const minDiff = differenceInMinutes(task.end_date, task.start_date);
    const pixelsForOneMinute = dayWidth / this.minutesInADay;

    return minDiff * pixelsForOneMinute;
  }
}
