<span
  *ngFor="let star of starArray; let i = index"
  class="star"
  [ngClass]="{
    star__filled: (hoverArray && hoverArray[i]) || (!hoverArray && star),
    star__gold: color === 'gold',
    star__dark: color === 'dark'
  }"
  (mouseover)="mouseOver(i + 1, $event)"
  (mouseout)="mouseOut(i + 1, $event)"
  (click)="starClicked(i + 1)">
  ⭑
</span>

<!-- ⭑⭑⭑⭑⭑
⭒⭒⭒⭒⭒ -->
