import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationMeta, Property } from '@app/shared/interfaces';
import { Filter } from '@app/shared/models/filter';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { FilterService } from '@app/shared/services/filter/filter.service';
import { environment } from '@env/environment';
import { Observable, of, Subject } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import {
  Code,
  Device,
  DeviceAccount,
  DeviceExtended,
  IntegrationName,
  MatchedDevice,
  MatchedThermostat,
  SmartProperty,
  ThermostatPreset,
  UnmatchedDevice,
} from '../data/smart-devices.interface';

import { CaughtApiError } from '@app/shared/services/smartlocks/smartlocks.service';

export interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  resolved_at: string | null; // iso8601
  read_at: string | null;
  created_at: string;
  updated_at: string;
}

export enum NotificationType {
  CODE_FIRST_USED_AT_PROPERTY = 'code-first-used-at-property',
  LOW_BATTERY = 'low-battery',
  ACCOUNT_DISCONNECTED = 'account-disconnected',
}

interface SmartDevicesApiResponse<T> {
  data: T;
  meta: {
    pagination: PaginationMeta;
  };
}

export interface ThermostatSettings {
  allow_guests_to_control_thermostat: boolean;
  temperature_unit: 'F' | 'C';
  dashboard_notifications: {
    temperature: {
      push: boolean;
      above: number;
      below: number;
      email: boolean;
      dashboard: boolean;
    };
    thermostat_offline: {
      push: boolean;
      email: boolean;
      dashboard: boolean;
    };
  };
  custom_presets: {
    is_on: boolean;
    occupied: ThermostatSettingsPreset;
    unoccupied: ThermostatSettingsPreset;
    long_term_unoccupied: ThermostatSettingsPreset;
  };
}

interface ThermostatSettingsPreset {
  fan_mode: 'auto' | 'on' | 'off';
  hvac_mode: 'heat_cool' | 'off' | 'heat' | 'cool';
  cooling_set_point: number;
  heating_set_point: number;
}

export interface ThermostatPresetFull {
  uuid: string;
  id: string;
  name: string;
  heating_set_point: number;
  cooling_set_point: number;
  hvac_mode: 'HEAT' | 'COOL' | 'HEAT_COOL' | 'OFF';
  fan_mode: 'AUTO' | 'ON' | 'OFF';
  preset_type: 'hospitable-occupied' | 'hospitable-unoccupied' | 'hospitable-long-term-unoccupied';
  thermostats: {
    id: string;
    name: string;
    display_name: string;
  }[];
}

export interface ThermostatSchedule {
  reservation_id: string;
  thermostat: {
    actions: string[];
    integration_key: string;
    id: string;
    name: string;
    device_type: string;
    manufacturer: string;
    issues: any[];
    device_properties: {
      online: boolean;
      temperature: {
        fahrenheit: number;
        celsius: number;
      };
      humidity: number;
      climate_setting: {
        cooling_point: {
          fahrenheit: number;
          celsius: number;
        };
        heating_point: {
          fahrenheit: number;
          celsius: number;
        };
        mode: string;
        fan_mode: string;
        display_name: string;
      };
      status: {
        is_cooling: boolean;
        is_heating: boolean;
        is_fan_running: boolean;
      };
      available_fan_mode_settings: string[];
      available_hvac_mode_settings: string[];
      supported_temperatures: {
        max_cooling_set_point_celsius: number;
        max_cooling_set_point_fahrenheit: number;
        max_heating_set_point_celsius: number;
        max_heating_set_point_fahrenheit: number;
        min_cooling_set_point_celsius: number;
        min_cooling_set_point_fahrenheit: number;
        min_heating_cooling_delta_celsius: number;
        min_heating_cooling_delta_fahrenheit: number;
        min_heating_set_point_celsius: number;
        min_heating_set_point_fahrenheit: number;
      };
    };
    integration: string;
    unsupported_message: string | null;
  };
  thermostat_schedule: {
    start: string;
    end: string;
  };
  thermostat_preset: {
    type: string | null;
    name: string;
    cooling_set_point: number;
    heating_set_point: number;
  };
}

export enum HvacMode {
  HEAT = 'heat',
  COOL = 'cool',
  HEAT_COOL = 'heat_cool',
  OFF = 'off',
}

export enum FanMode {
  AUTO = 'auto',
  ON = 'on',
  OFF = 'off',
}

export enum PresetType {
  HOSPITABLE_OCCUPIED = 'occupied',
  HOSPITABLE_UNOCCUPIED = 'unoccupied',
  HOSPITABLE_LONG_TERM_UNOCCUPIED = 'long-term-unoccupied',
}

@Injectable({
  providedIn: 'root',
})
export class SmartDevicesService {
  refreshReservationsAndDevicesFromApi$ = new Subject<boolean>();
  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private demoService: DemoService
  ) {}

  get getDeviceApiUrl(): string {
    if (this.demoService.isDemo()) {
      return environment.apiUrl;
    } else {
      return environment.smartDevicesApiUrl;
    }
  }
  getCodes(
    offset = 0,
    searchQuery = '',
    filters: Filter[] = [],
    type: 'reservation' | 'manual' = 'reservation'
  ): Observable<SmartDevicesApiResponse<Code[]>> {
    if (filters.length > 0) {
      filters = this.filterService.transformFilters(filters);
    }

    return this.http.post<SmartDevicesApiResponse<Code[]>>(
      `${environment.apiUrl}/smartlocks/codes/${type}?offset=${offset}&query=${searchQuery}&limit=20`,
      { filters }
    );
  }

  getReservationDetails(reservationCode: string): Observable<Code[]> {
    return this.http
      .get<{ data: Code[] }>(`${environment.apiUrl}/smartlocks/codes/${reservationCode}`)
      .pipe(map((res) => res.data));
  }

  getThermostatSchedulesForAThread(payload: { uuid: string }): Observable<ThermostatSchedule[] | CaughtApiError> {
    return this.http
      .get<{ data: ThermostatSchedule[] }>(`${environment.apiUrl}/thermostats/thermostat-schedule?uuid=${payload.uuid}`)
      .pipe(map((res) => res.data));
  }

  getDevices(offset = 0, searchQuery = '', filters: Filter[]): Observable<SmartDevicesApiResponse<MatchedDevice[]>> {
    if (filters.length > 0) {
      filters = this.filterService.transformFilters(filters);
    }

    return this.http.post<SmartDevicesApiResponse<MatchedDevice[]>>(
      `${environment.apiUrl}/smartlocks/devices/smartlocks?offset=${offset}&query=${searchQuery}&limit=20`,
      { filters }
    );
  }

  getThermostats(
    offset = 0,
    searchQuery = '',
    filters: Filter[],
    limit = 20
  ): Observable<SmartDevicesApiResponse<MatchedThermostat[]>> {
    if (filters && filters.length > 0) {
      filters = this.filterService.transformFilters(filters);
    }

    return this.http.post<SmartDevicesApiResponse<MatchedDevice[]>>(
      `${environment.apiUrl}/smartlocks/devices/thermostats?offset=${offset}&query=${searchQuery}&limit=${limit}`,
      { filters }
    );
  }

  getDeviceDetails(deviceId: Device['id'], deviceType: Device['device_type']): Observable<DeviceExtended> {
    return this.http.get<DeviceExtended>(
      `${environment.apiUrl}/smartlocks/devices/${deviceId}?device_type=${deviceType}`
    );
  }

  getAccessCodesForDevice(deviceId: Device['id'], page = 1) {
    const limit = 20;
    return this.http.post<{ data: Code[]; meta: { pagination: PaginationMeta } }>(
      `${environment.apiUrl}/smartlocks/codes`,
      {
        offset: (page - 1) * limit,
        limit: limit,
        filters: [
          {
            id: 'dashboard-codes-device',
            value: deviceId,
            inverted: false,
          },
        ],
      }
    );
  }

  lockDevice(deviceId: Device['id']) {
    return this.http.post(`${this.getDeviceApiUrl}/devices/${deviceId}/lock`, {});
  }

  unlockDevice(deviceId: Device['id']) {
    return this.http.post(`${this.getDeviceApiUrl}/devices/${deviceId}/unlock`, {});
  }

  createManualCode(
    name: string,
    code: string,
    startDate: string,
    endDate: string,
    alwaysOn: boolean,
    propertyIds: Property['id'][]
  ) {
    const payload = {
      name,
      code,
      properties: propertyIds,
    };

    if (!alwaysOn) {
      payload['starts_at'] = startDate;
      payload['ends_at'] = endDate;
    }

    return this.http.post(`${this.getDeviceApiUrl}/properties/code/manual`, payload);
  }

  editCode(
    propertyIds: Property['id'][],
    reservationId: Code['reservation']['reservation_id'],
    name: string,
    code: string,
    startDate: string,
    endDate: string,
    alwaysOn: boolean,
    isManual = false
  ) {
    const payload = {
      name,
      code,
      properties: propertyIds,
    };

    if (!alwaysOn) {
      payload['starts_at'] = startDate;
      payload['ends_at'] = endDate;
    }

    let url;

    if (isManual) {
      url = `${this.getDeviceApiUrl}/properties/code/${reservationId}`;
    } else {
      url = `${this.getDeviceApiUrl}/properties/${propertyIds[0]}/code/${reservationId}`;
    }

    return this.http.put(url, payload);
  }

  deleteCode(propertyId: Property['id'], reservationId: Code['reservation']['reservation_id']) {
    return this.http.delete(`${this.getDeviceApiUrl}/properties/${propertyId}/code/${reservationId}`);
  }

  getPropertiesWithDevices(): Observable<SmartProperty[]> {
    return this.http
      .get<{ data: SmartProperty[] }>(`${environment.apiUrl}/smartlocks/properties`)
      .pipe(map((res) => res.data));
  }

  getUnmatchedDevices(connectAccountId?: string): Observable<UnmatchedDevice[]> {
    let url;

    if (connectAccountId) {
      url = `${this.getDeviceApiUrl}/devices/unlinked/${connectAccountId}`;
    } else {
      url = `${this.getDeviceApiUrl}/devices/unlinked`;
    }

    return this.http.post<UnmatchedDevice[]>(url, {});
  }

  editDevice(
    id: Device['id'],
    integration_id: Device['integration'],
    code_length: MatchedDevice['code_length'],
    add_properties: Property['id'][],
    remove_properties: Property['id'][],
    battery_threshold: Device['device_properties']['battery']['threshold'] = 30,
    guest_access = true,
    device_type: Device['device_type']
  ): Observable<Device> {
    return this.http.put<Device>(`${environment.apiUrl}/smartlocks/${id}`, {
      integration_id,
      code_length,
      add_properties,
      remove_properties,
      battery_threshold,
      guest_access,
      device_type,
    });
  }

  /**
   * Connected accounts
   **/
  getConnectedAccounts(): Observable<DeviceAccount[]> {
    return this.http.get<DeviceAccount[]>(`${this.getDeviceApiUrl}/connections`);
  }

  connectDeviceAccount(): Observable<string> {
    return this.http.get<{ url: string }>(`${this.getDeviceApiUrl}/onboarding`, {}).pipe(map((res) => res.url));
  }

  reconnectDeviceAccount(provider: IntegrationName): Observable<string> {
    return this.http
      .get<{ url: string }>(`${this.getDeviceApiUrl}/onboarding/${provider}`, {})
      .pipe(map((res) => res.url));
  }

  deleteDeviceAccount(account: DeviceAccount): Observable<boolean> {
    return this.http.delete(`${this.getDeviceApiUrl}/connections/${account.id}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  getNotifications(cursor?: string) {
    const url = cursor
      ? `${environment.apiUrl}/smartlocks/notifications?cursor=${cursor}`
      : `${environment.apiUrl}/smartlocks/notifications`;

    return this.http.get<{
      data: Notification[];
      links: unknown;
      meta: {
        next_cursor: string;
      };
    }>(url);
  }

  markNotificationAsRead(notificationId: Notification['id']) {
    return this.http.put(`${environment.apiUrl}/smartlocks/notifications/${notificationId}`, {});
  }

  stubNotifications$ = of([
    {
      id: '1',
      type: NotificationType.CODE_FIRST_USED_AT_PROPERTY,
      message: 'Princess Peach has used their code',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '2',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Account bigboosmansion@gmail.com is disconnected',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '3',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '4',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '1',
      type: NotificationType.CODE_FIRST_USED_AT_PROPERTY,
      message: 'Princess Peach has used their code',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '2',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Account bigboosmansion@gmail.com is disconnected',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '3',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '4',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '1',
      type: NotificationType.CODE_FIRST_USED_AT_PROPERTY,
      message: 'Princess Peach has used their code',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '2',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Account bigboosmansion@gmail.com is disconnected',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '3',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '4',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '1',
      type: NotificationType.CODE_FIRST_USED_AT_PROPERTY,
      message: 'Princess Peach has used their code',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '2',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Account bigboosmansion@gmail.com is disconnected',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '3',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
    {
      id: '4',
      type: NotificationType.ACCOUNT_DISCONNECTED,
      message: 'Your lock Rainbow Road Garage has a poor connection',
      created_at: '2021-02-01T12:00:00Z',
      updated_at: '2021-02-01T12:00:00Z',
      resolved_at: null,
      read_at: null,
    },
  ]).pipe(delay(2000));

  changeThermostatSettings(deviceId: string, payload: any): Observable<any> {
    return this.http.post<any>(`${this.getDeviceApiUrl}/devices/${deviceId}/change_thermostat_settings`, payload);
  }

  getGlobalDeviceSettings(): Observable<ThermostatSettings> {
    return this.http.get<ThermostatSettings>(`${this.getDeviceApiUrl}/settings/devices/global`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateGlobalDeviceSettings(settings: ThermostatSettings): Observable<ThermostatSettings> {
    return this.http.put<ThermostatSettings>(`${this.getDeviceApiUrl}/settings/devices/global`, settings).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getThermostatPresets(searchQuery = ''): Observable<ThermostatPreset[]> {
    return this.http.get<ThermostatPreset[]>(`${this.getDeviceApiUrl}/thermostats/presets?query=${searchQuery}`);
  }

  activeOrDeactivateThermostatPreset(presetId: string, isActive: boolean): Observable<any> {
    return this.http.put<any>(`${this.getDeviceApiUrl}/thermostats/presets/${presetId}/update_status`, {
      is_active: isActive,
    });
  }

  createThermostatPreset(): Observable<ThermostatPresetFull> {
    return this.http.post<ThermostatPresetFull>(`${this.getDeviceApiUrl}/thermostats/presets`, {});
  }

  getThermostatPreset(presetId: string): Observable<ThermostatPresetFull> {
    return this.http.get<ThermostatPresetFull>(`${this.getDeviceApiUrl}/thermostats/presets/${presetId}`);
  }

  updateThermostatPreset(presetId: string, preset: Partial<ThermostatPreset>): Observable<ThermostatPreset> {
    const updatedPreset: any = { ...preset };

    // Transform thermostats to a simple array of IDs before we update
    if (updatedPreset.thermostats) {
      updatedPreset.thermostats = updatedPreset.thermostats.map((t) => t.id);
    }

    return this.http.put<ThermostatPreset>(`${this.getDeviceApiUrl}/thermostats/presets/${presetId}`, updatedPreset);
  }

  deleteThermostatPreset(presetId: string): Observable<void> {
    return this.http.delete<void>(`${this.getDeviceApiUrl}/thermostats/presets/${presetId}`);
  }

  copyThermostatPreset(presetId: string): Observable<{ data: ThermostatPresetFull }> {
    return this.http.post<{ data: ThermostatPresetFull }>(
      `${this.getDeviceApiUrl}/thermostats/presets/${presetId}/copy`,
      {}
    );
  }
}
