<table class="block w-full " mat-table [dataSource]="data.dataSource">

  <ng-container *ngFor="let column of data.displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell *matCellDef="let element"> {{element[column]}}
    <td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="data.displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: data.displayedColumns;"></tr>
</table>


<hospitable-pagination *ngIf="data.meta && data.meta.pagination" [pagination]="data.meta.pagination"
  (pageChanged)="onPageChanged($event)"></hospitable-pagination>