import { Component, Input, OnInit } from '@angular/core';
import { Listing } from '@app/shared/services/properties/properties.service';

@Component({
  standalone: false,
  selector: 'sbnb-listing-card',
  templateUrl: './listing-card.component.html',
})
export class ListingCardComponent implements OnInit {
  @Input() listing: Listing;

  constructor() { }

  ngOnInit(): void { }
}
