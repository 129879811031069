import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Optional,
  Self,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export interface DropdownOption {
  label: string;
  value: string | number;
  disabled?: boolean;
  image?: {
    src: string;
    alt?: string;
    size?: 'sm' | 'md' | 'lg';
  };
}

@Component({
  selector: 'sbnb-dropdown',
  templateUrl: './dropdown.component.html',
  styles: `
    ::ng-deep .mat-select-search-clear {
      top: -3px !important;
    }

    ::ng-deep .mat-select-search-inner {
      background-color: #ffffff;
    }

    :ng-deep .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }

    .option-image {
      border-radius: 4px;
      object-fit: cover;
    }

    .option-image.sm {
      height: 24px;
    }

    .option-image.md {
      height: 32px;
    }

    .option-image.lg {
      height: 40px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DropdownComponent,
    },
  ],
})
export class DropdownComponent implements MatFormFieldControl<string | string[]>, ControlValueAccessor, OnChanges {
  @Input() options: DropdownOption[] = [];
  @Input() placeholder = 'Select an option';
  @Input() multiple = false;
  @Input() actionOption = false;
  @Output() selectionChange = new EventEmitter<string | string[]>();
  @ViewChild('select') select: MatSelect;

  private _required = false;
  private _disabled = false;
  private _optionsSubject = new BehaviorSubject<DropdownOption[]>([]);

  public value: string | string[] = '';
  public touched = false;
  public searchControl = new FormControl('');
  public filteredOptions$ = combineLatest([
    this._optionsSubject,
    this.searchControl.valueChanges.pipe(startWith(''), debounceTime(200), distinctUntilChanged()),
  ]).pipe(map(([options, search]) => this.filterOptions(search, options)));

  // MatFormFieldControl properties
  static nextId = 0;
  id = `sbnb-dropdown-${DropdownComponent.nextId++}`;
  describedBy = '';
  stateChanges = new Subject<void>();
  focused = false;
  private _errorState = false;
  controlType = 'sbnb-dropdown';

  // ControlValueAccessor methods
  onChange = (value: string | string[]) => {};
  onTouched = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public _formField: MatFormField
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  get errorState(): boolean {
    return this._errorState;
  }

  set errorState(value: boolean) {
    this._errorState = value;
    this.stateChanges.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this._optionsSubject.next(this.options);
    }

    const hasSingularOption = !this.multiple && this.options?.length === 1;
    if (changes['options'] && hasSingularOption && !this.value) {
      const singleOption = this.options[0];
      if (!singleOption.disabled) {
        this.onSelectionChange({ value: singleOption.value as string });
      }
    }
  }

  writeValue(value: string | string[]): void {
    this.value = value;
    this.stateChanges.next();
  }

  registerOnChange(onChange: (value: string | string[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  onSelectionChange(event: { value: string | string[] }): void {
    this.value = event.value;
    this.markAsTouched();
    this.onChange(event.value);
    this.selectionChange.emit(event.value);
    this.stateChanges.next();
    this.updateErrorState();
  }

  private markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.updateErrorState();
    }
  }

  private updateErrorState(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
    }
  }

  private filterOptions(search: string, options: DropdownOption[]): DropdownOption[] {
    if (!search) {
      return options;
    }
    const searchLower = search.toLowerCase();
    return options.filter((option) => option.label.toLowerCase().includes(searchLower));
  }

  get showSearch(): boolean {
    return this.options.length >= 6;
  }

  get selectedOption(): DropdownOption | undefined {
    if (this.multiple || !this.value) {
      return undefined;
    }
    return this.options.find((option) => option.value === this.value);
  }

  // MatFormFieldControl methods
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.select?.open();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  get empty(): boolean {
    return !this.value || (Array.isArray(this.value) && this.value.length === 0);
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
}
