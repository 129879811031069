<ng-container>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 4604 4978" style="enable-background:new 0 0 600 309;" xml:space="preserve"
    [attr.width]="width"
    [attr.height]="height"
    >
    <g>
      <path
        d="M1325.5 4279C1132.79 4279 976 4435.79 976 4628.5C976 4821.21 1132.79 4978 1325.5 4978C1518.21 4978 1675 4821.21 1675 4628.5C1675 4435.79 1518.21 4279 1325.5 4279Z"
        fill="#FCB615" />
      <path
        d="M349.5 4279C156.789 4279 0 4435.79 0 4628.5C0 4821.21 156.789 4978 349.5 4978C542.211 4978 699 4821.21 699 4628.5C699 4435.79 542.211 4279 349.5 4279Z"
        fill="#ED353F" />
      <path
        d="M2302 4279C2109.56 4279 1953 4435.79 1953 4628.5C1953 4821.21 2109.56 4978 2302 4978C2494.44 4978 2651 4821.21 2651 4628.5C2651 4435.79 2494.44 4279 2302 4279Z"
        fill="#02AF56" />
      <path
        d="M4254.5 4279C4061.79 4279 3905 4435.79 3905 4628.5C3905 4821.21 4061.79 4978 4254.5 4978C4447.21 4978 4604 4821.21 4604 4628.5C4604 4435.79 4447.21 4279 4254.5 4279Z"
        fill="#00A8DF" />
      <path
        d="M3278.58 4279C3085.87 4279 2929 4435.79 2929 4628.5C2929 4821.21 3085.79 4978 3278.5 4978C3471.21 4978 3628 4821.21 3628 4628.5C3628.08 4435.79 3471.37 4279 3278.58 4279Z"
        fill="#A14699" />
      <path
        d="M3470.91 1789.36C3470.91 1054 2916.06 499.298 2180.5 499.298C1444.94 499.298 890.085 1054 890.085 1789.36C890.085 2524.37 1444.94 3079.42 2180.5 3079.42C2915.71 3079.42 3470.91 2524.37 3470.91 1789.36ZM349 1789.36C349 769.942 1167.51 0 2180.5 0C3200.2 0 4012 762.885 4012 1782.3V3349.71C4012 3509.2 3900.82 3620 3734.57 3620C3561.27 3620 3457.15 3509.2 3457.15 3349.71V2892.05H3429.27C3214.31 3259.73 2784.06 3578.72 2145.91 3578.72C1160.8 3578.72 349 2815.83 349 1789.36Z"
        fill="#595A5A" />
    </g>
  </svg>
</ng-container>
