<ng-container *ngIf="vettingData$ | async as data">
  <section *ngIf="data.mode !== ModeEnum.hidden" class="guest-vetting">
    <div class="flex flex-col gap-1">
      <div class="flex justify-between items-center gap-2">
        <div class="text-night-darker strong text-sm">Guest verification</div>
        <a
          *ngIf="data.autohost?.id"
          class="text-sm strong badge"
          [ngClass]="{
            'badge-grass':
              data.displayStatus === DisplayStatusEnum.approved || data.displayStatus === DisplayStatusEnum.verified,
            'badge-lemon':
              data.displayStatus === DisplayStatusEnum.pending || data.displayStatus === DisplayStatusEnum.requested,
            'badge-danger': data.displayStatus === DisplayStatusEnum.declined,
            'cursor-pointer': data.autohost?.id
          }"
          (click)="
            openVettingDetails({
              reservation: data.reservation,
              autohostBasicDetails: data.autohost
            })
          ">
          {{ data.displayStatus | humanize }}
        </a>
      </div>
      <div class="text-night-lighter text__small">Reduce the risk of bad guests.</div>
    </div>

    <!-- Request adhoc verification-->
    <div *ngIf="data.canRequestAdhocVerification" class="mt-4 flex flex-col gap-1 justify-center items-center">
      <sbnb-button
        class="w-full"
        [fullWidth]="true"
        type="secondary"
        size="mini"
        (clicked)="
          onRequestVerificationClicked({ guestName: data.reservation.guest, reservationUuid: data.reservation.uuid })
        ">
        Request verification
      </sbnb-button>
      <div *ngIf="data.hasFreeCheck" class="italic text__xsmall text-night-lighter">The first one is on us!</div>
    </div>
  </section>
</ng-container>
