<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M15.75 1.5625H2.25C1.62868 1.5625 1.125 2.06618 1.125 2.6875V11.6875C1.125 12.3088 1.62868 12.8125 2.25 12.8125H15.75C16.3713 12.8125 16.875 12.3088 16.875 11.6875V2.6875C16.875 2.06618 16.3713 1.5625 15.75 1.5625Z"
    [attr.stroke]="color"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M16.6209 1.97498L10.5129 6.67298C10.0792 7.00662 9.54734 7.18752 9.00015 7.18752C8.45295 7.18752 7.92111 7.00662 7.4874 6.67298L1.37939 1.97498"
    [attr.stroke]="color"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
