import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/core/app-config';
import { map, catchError } from 'rxjs/operators';
import { Filter } from '@app/shared/models/filter';
import { FilterService } from '../filter/filter.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogDeleteUpcomingScheduledMessagesComponent } from '@app/modules/guest-experience/components/dialog-delete-upcoming-scheduled-messages/dialog-delete-upcoming-scheduled-messages.component';
import { Reason } from '@app/shared/models/reason';
import { ReasonMapping } from '@app/shared/models/reason-mapping';
import { ReasonCodes } from '@app/shared/services/logs/reason-codes';

export enum LogStatus {
  Bounced = 'bounced',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Scheduled = 'scheduled',
  Sent = 'sent',
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private logsUrl = `${config.API_URL}/reports`;
  private opsLogsUrl = `${config.API_URL}/operations/logs`;
  private resultsPerPage = 20;
  private reasonCodeMappings: ReasonMapping[] = ReasonCodes;

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private dialog: MatDialog
  ) {}

  getLogs(type: string, filterCriteria?: Filter[], offset?: any, search?: string): Observable<any> {
    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    let url = `${config.API_URL}/gx/log/${type}?limit=${this.resultsPerPage}`;

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }

    return this.http.post(`${url}`, { filters: apiFilters }).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getOperationsLogs(offset?: any, search?: string): Observable<any> {
    let url = `${this.opsLogsUrl}?limit=${this.resultsPerPage}`;

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }

    return this.http.get(`${url}`).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getLog(type: string, uuid: string) {
    const url = `${config.API_URL}/gx/log/${type}/${uuid}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  restoreLog(uuid: string, type: string): Observable<any> {
    const url = `${config.API_URL}/gx/log/${type}/${uuid}/restore`;

    return this.http.post(url, {}).pipe(
      map((res) => res),
      catchError(this.handleError)
    );
  }

  getOperationsLog(uuid: string) {
    const url = `${this.opsLogsUrl}/${uuid}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  updateOperationsLog(uuid: string, message: string) {
    const url = `${this.opsLogsUrl}/${uuid}`;

    return this.http.put(url, { message }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  cancelOperationsLog(uuid: string) {
    const url = `${this.opsLogsUrl}/${uuid}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  getScheduledMessagesForReservation(code: string) {
    return this.http.get(`${config.API_URL}/gx/log/scheduled/${code}/pending`).pipe(
      map((res: { data: { name: string; scheduled_for: string }[] }) => {
        return res.data;
      })
    );
  }

  getReasonCodeMessage(code: string): string {
    for (let i = 0; i < this.reasonCodeMappings.length; i++) {
      if (code === this.reasonCodeMappings[i].code) {
        return this.reasonCodeMappings[i].message;
      }
    }
  }

  public resolveReasons(reasonsData: Reason): Reason {
    if (!reasonsData) {
      return {};
    }

    if (reasonsData.cancel?.has_cancel_reason) {
      reasonsData.cancel.description = this.getReasonCodeMessage(reasonsData.cancel.code);
    }

    if (reasonsData.failure?.has_failure_reason) {
      reasonsData.failure.description = this.getReasonCodeMessage(reasonsData.failure.code);
    }

    if (reasonsData.timing?.has_timing_reason) {
      reasonsData.timing.description = this.getReasonCodeMessage(reasonsData.timing.code);
    }
    return reasonsData;
  }

  public deleteScheduledMessagesForReservation(code: string) {
    return this.http.delete(`${config.API_URL}/gx/log/scheduled/${code}/pending`).pipe(
      map((res) => {
        return true;
      })
    );
  }

  private handleError(err) {
    return of({
      error: true,
      message: err.error.message,
    });
  }
}
