import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-guest-vetting-line-item',
  templateUrl: './guest-vetting-line-item.component.html',
  styleUrls: ['./guest-vetting-line-item.component.scss'],
})
export class GuestVettingLineItemComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;

  constructor() { }

  ngOnInit(): void { }
}
