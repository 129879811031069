import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';

/**
 * Directive to show/hide elements based on the debug mode of the customer support team
 *
 * Pass extra conditions to the directive to further limit visibility or
 * use `true` if there aren't any extra conditions.
 *
 * Usage:
 *
 * ```html
 * <div *sbnbCSDebug="true">Support only</div>
 * <div *sbnbCSDebug="someOtherCondition">Support only</div>
 * ```
 */
@Directive({
  standalone: false,
  selector: '[sbnbCSDebug]',
})
export class CustomerSupportDebugDirective {
  private debugMode = false;
  private sub;
  @Input() set sbnbCSDebug(condition: boolean) {
    if (condition && this.debugMode) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private csService: InternalCsService
  ) {
    this.sub = this.csService.isCSDebug$.subscribe((debugMode) => {
      this.debugMode = debugMode;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
