<div class="flex flex-col h-full gap-4">
  <!-- header-->
  <div class="flex-shrink-0 flex items-center justify-between">
    <h2 class="my-0">Guest verification</h2>
    <sbnb-close [border]="true" (click)="dialogRef.close()"></sbnb-close>
  </div>

  <!-- body-->
  <div class="flex-grow overflow-y-auto">
    <div>
      <!-- Loading state -->
      <ng-container *ngIf="loading">
        <ng-template [ngTemplateOutlet]="loadingSkeletonTemplate"></ng-template>
      </ng-container>

      <!-- Error state -->
      <span *ngIf="error">{{ error }}</span>

      <!-- Success state -->
      <ng-container *ngIf="guestVettingData$ | async as vetting">
        <div class="vetting-section vetting-section-guests">
          <h3>Guests</h3>

          <sbnb-guest-vetting-line-item
            label="Primary guest name"
            value="{{ vetting.reservation.guest.first_name }} {{
              vetting.reservation.guest.last_name
            }}"></sbnb-guest-vetting-line-item>
          <sbnb-guest-vetting-line-item
            label="Primary email"
            value="{{ vetting.reservation.guest.email }}"></sbnb-guest-vetting-line-item>
          <sbnb-guest-vetting-line-item
            label="Primary guest phone"
            value="{{ vetting.reservation.guest.phone }}"></sbnb-guest-vetting-line-item>
        </div>

        <div class="vetting-section vetting-section-general">
          <h3>General</h3>

          <sbnb-guest-vetting-line-item
            label="Risk"
            value="{{ vetting.summary.risk_color | humanize }}"></sbnb-guest-vetting-line-item>
          <sbnb-guest-vetting-line-item
            label="Social verification"
            value="{{ vetting.summary.details.social_verification.value }}"></sbnb-guest-vetting-line-item>
          <ng-container *ngIf="dialogData.source === 'adhoc' || dialogData.source === 'direct'; else guestPortalLink">
            <sbnb-guest-vetting-line-item
              label="Guest portal link"
              value="{{ config.GUEST_PORTAL_URL + dialogData.reservationUuid }}"></sbnb-guest-vetting-line-item>
          </ng-container>
          <ng-template #guestPortalLink>
            <sbnb-guest-vetting-line-item
              label="Guest vetting link"
              value="{{ vetting.reservation.guest_portal_url }}"></sbnb-guest-vetting-line-item>
          </ng-template>
        </div>

        <div class="vetting-section vetting-section-risks">
          <h3>Risks</h3>

          <sbnb-guest-vetting-line-item
            *ngFor="let risk of vetting.summary.top_risks"
            value="{{ risk }}"></sbnb-guest-vetting-line-item>
        </div>

        <div class="vetting-section vetting-section-recomendations">
          <h3>Recommendations</h3>

          <sbnb-guest-vetting-line-item
            *ngFor="let recommendation of vetting.summary.top_recommendations"
            value="{{ recommendation }}"></sbnb-guest-vetting-line-item>
        </div>

        <div class="vetting-section vetting-section-checks">
          <h3>Checks</h3>

          <sbnb-guest-vetting-line-item
            label="ID check"
            value="{{
              vetting.summary.details.identification_document.value | humanize
            }}"></sbnb-guest-vetting-line-item>
        </div>
      </ng-container>

      <!-- The autohost SDK iframe is injected here -->
      <div class="ph-no-capture">
        <div id="autohost-sdk"></div>
      </div>
    </div>
  </div>

  <!-- footer-->
  <ng-container *ngIf="guestVettingData$ | async as vetting">
    <div class="flex-shrink-0">
      <!-- Adhoc. Guest was flagged for manual review -->
      <ng-container *ngIf="dialogData.source === 'adhoc' && vetting.summary.verification_composite_status === 'review'">
        <div class="p-4 bg-lemon-lighter border rounded border-lemon-darker">
          <h4>Autohost has flagged this guest for manual review</h4>
          <ul class="ml-0 pl-4 mb-0 leading-relaxed">
            <li>Review the Risks and Recommendations above to determine if you want to allow this guest to stay.</li>
            <li>Check-in messages and code communications will not be sent.</li>
          </ul>
        </div>
        <div class="mt-3 flex justify-end">
          <sbnb-button *ngIf="!isViewingConfirmationMessageToAllowCheckIn" type="secondary" (click)="ignoreWarning()">
            Ignore this warning and allow check-in
          </sbnb-button>
        </div>
      </ng-container>

      <!-- Adhoc. Pending guest portal but hosts wants to just override and allow -->
      <ng-container
        *ngIf="dialogData.source === 'adhoc' && vetting.summary.verification_composite_status === 'pending'">
        <div class="p-4 bg-lemon-lighter border rounded border-lemon-darker">
          <h4>The guest has not completed the guest portal</h4>
          <ul class="ml-0 pl-4 mb-0 leading-relaxed">
            <li>
              Check-in messages and code communications will not be sent until the guest has completed the guest portal.
            </li>
          </ul>
        </div>
        <div class="mt-3 flex justify-end">
          <sbnb-button *ngIf="!isViewingConfirmationMessageToAllowCheckIn" type="secondary" (click)="ignoreWarning()">
            Ignore this warning and allow check-in
          </sbnb-button>
        </div>
      </ng-container>

      <!-- Confirmation message to allow check-in -->
      <ng-container *ngIf="isViewingConfirmationMessageToAllowCheckIn">
        <h3 class="mb-4 mt-2">Are you sure you want to allow this guest to check-in?</h3>

        <div class="flex gap-4 justify-between">
          <div
            class="border rounded border-smoke p-4 cursor-pointer hover:bg-raspberry-lighter"
            (click)="restrictCheckIn()">
            <h4 class="mb-2">No, continue restricting check-in</h4>
            <span class="text-sm">
              Check-in messages and code communications will not be sent. You can choose to allow check-in later.
            </span>
          </div>

          <div
            class="border rounded border-smoke p-4 cursor-pointer hover:bg-grass-lighter"
            [class.loading]="loadingCheckin"
            (click)="allowCheckInForAdhoc()">
            <div class="flex gap-2">
              <h4 class="mb-2 flex-1">Yes, allow check-in</h4>
              <mat-spinner *ngIf="loadingCheckin" diameter="20"></mat-spinner>
            </div>

            <span class="text-sm">
              Check-in messages and code communications will sent as scheduled. This can not be undone.
            </span>
          </div>
        </div>
      </ng-container>

      <!-- Adhoc. Guest was verified -->
      <ng-container
        *ngIf="
          dialogData.source === 'adhoc' &&
          (vetting.summary.verification_composite_status === 'verified' ||
            vetting.summary.verification_composite_status === 'approve')
        ">
        <div class="p-4 bg-grass-lighter border rounded border-grass-darker">
          <h4>Autohost has verified this guest</h4>
          <span>Check-in messages and code communications will be sent as scheduled.</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #loadingSkeletonTemplate>
  <div *ngFor="let i of [].constructor(10)" class="animate-pulse mb-6">
    <div class="rounded h-8 w-32 bg-smoke"></div>
    <div class="w-full border-t border-smoke my-4"></div>

    <ng-container *ngFor="let i of [].constructor(3)">
      <div class="flex pr-4 h-9 items-center">
        <div class="w-[220px]">
          <div class="rounded h-4 w-32 bg-smoke"></div>
        </div>
        <div class="rounded h-4 w-full bg-smoke"></div>
      </div>
      <div class="w-full border-t border-smoke my-4"></div>
    </ng-container>
  </div>
</ng-template>
