<div [class.property-selector-border]="border" [class.disabled]="disabled">
  <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="8px" class="rule__scope-header">
    <mat-checkbox *ngIf="allPropertiesEnabled" [(ngModel)]="allProperties" (ngModelChange)="setPropertiesIfAllIsSet()">
      <h4>{{ title }}</h4>
    </mat-checkbox>

    <h4 *ngIf="!allPropertiesEnabled">{{ title }}</h4>

    <span role="button" class="clickable grass-darker text__small" (click)="clearProperties()">Clear selection</span>
  </div>
  <mat-spinner *ngIf="loading" [diameter]="32"></mat-spinner>
  <div *ngIf="!loading">
    <div>
      <sbnb-search-bar placeholderText="Search..." (searchChanged)="hostsSearchUpdated($event)"></sbnb-search-bar>
    </div>
    <div [style.height.px]="listHeight ? listHeight : null" class="overflow">
      <ng-container *ngFor="let property of possibleProperties">
        <div
          *ngIf="!property.hidden"
          fxLayout
          fxLayoutGap="4px"
          fxLayoutAlign="start center"
          [class.disabled]="isPropertyDisabled(property.id)"
          [matTooltip]="isPropertyDisabled(property.id) ? getDisabledPropertyReason(property.id) : null">
          <mat-checkbox
            style="width: 100% !important"
            [checked]="allProperties || selectedProperties?.indexOf(property.id) > -1"
            [disabled]="isPropertyDisabled(property.id)"
            (change)="propertyCheckboxChange(property, $event)">
            <span fxLayout fxLayoutGap="8px" fxLayoutAlign="start center" [class.property__unlisted]="!property.listed">
              <img [src]="property.picture" class="property__img" />
              <span fxFlex [matTooltip]="!property.listed ? 'This property is unlisted' : null">
                {{ property.name }}
                <em>{{ hideChildProperties && property.children?.length > 0 ? '(& all children)' : '' }}</em>
              </span>
            </span>
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
</div>
