<div>
  <div class="title_with_icon">
    <strong>AI Summary</strong>
    <img src="/assets/iconography/magic.svg" width="14px" height="14px" alt="Highlights with AI" />
  </div>
  <ul>
    <li *ngFor="let item of summary" class="text-sm">
      {{ item }}
    </li>
  </ul>
</div>
