import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { config } from '@app/core/app-config';
import { DirectSubscriptionService } from '@app/modules/direct/services/direct-subscription.service';
import { LoadingState, NotificationType } from '@app/shared/interfaces';
import { DirectQuotesService, NewDirectCustomQuote } from '@app/shared/services/direct-quotes/direct-quotes.service';
import { LanguageService } from '@app/shared/services/language/language.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { isNullUndefinedOrEmptyString } from '@app/shared/utils';

import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-direct-quote-guest-details',
  templateUrl: './direct-quote-guest-details.component.html',
  styleUrls: ['./direct-quote-guest-details.component.scss'],
})
export class DirectQuoteGuestDetailsComponent implements OnInit {
  @Input() quote: NewDirectCustomQuote;
  @Input() loadingState: LoadingState;
  @Output() next = new EventEmitter<NewDirectCustomQuote>();
  @Output() goBack = new EventEmitter<any>();
  LoadingState = LoadingState;
  isNullUndefinedOrEmptyString = isNullUndefinedOrEmptyString;

  config = config;
  additionalGuestDetails: boolean = true;
  languages: any[];

  private guestSearchString = new Subject<string>();
  guestSearchResults: string[] = [];
  isLoadingGuestResults: boolean;
  isPremium$ = this.directSubscriptionService.isPremium$;

  constructor(
    private languageService: LanguageService,
    private directQuotesService: DirectQuotesService,
    private toast: ToastNotificationsService,
    private directSubscriptionService: DirectSubscriptionService
  ) { }

  ngOnInit(): void {
    this.getLanguages();

    this.guestSearchString
      .pipe(
        tap(() => {
          this.isLoadingGuestResults = true;
          this.guestSearchResults = [];
        }),
        debounceTime(300),
        switchMap((term) => this.searchGuests(term))
      )
      .subscribe((results) => {
        if (results.data) {
          this.guestSearchResults = results.data;
        }
      });
  }

  onGuestNameChange(newTerm: string) {
    this.quote.computed.isSuperhogVerified = false;
    if (newTerm.length > 2) {
      this.guestSearchString.next(newTerm);
    } else {
      this.guestSearchResults = [];
    }
  }

  onOptionSelected(event) {
    const selectedOption = event.option.value;

    this.quote.guest_details = {
      first_name: selectedOption.first_name,
      last_name: selectedOption.last_name,
      email: selectedOption.email,
      phone: selectedOption.phone,
    };

    this.quote.computed.guestName = selectedOption.first_name + ' ' + selectedOption.last_name;
    this.quote.computed.isSuperhogVerified = selectedOption.is_superhog_verified ? true : false;

    if (selectedOption.locale) {
      this.quote.locale = selectedOption.locale;
    }
  }

  searchGuests(term: string) {
    return this.directQuotesService.searchGuests(term).pipe(
      tap((res) => {
        this.isLoadingGuestResults = false;
      })
    );
  }

  getLanguages() {
    this.languageService.getLanguages().subscribe((res) => {
      this.languages = res;
    });
  }

  onNext() {
    const { guestName } = this.quote.computed;
    let fullName = guestName.trim().split(' ');
    this.quote.guest_details.first_name = fullName[0];
    this.quote.guest_details.last_name =
      fullName[fullName.length - 1] !== fullName[0] ? fullName[fullName.length - 1] : null;

    if (!this.quote.guest_details.last_name) {
      this.toast.open('Guest last name is required', 'Dismiss', NotificationType.Error, 999999);
    } else {
      this.next.emit(this.quote);
    }
  }

  onGoBack() {
    this.goBack.emit();
  }
}
