import { Pipe, PipeTransform } from '@angular/core';
import { CELL_WIDTH } from '@app/modules/calendar/constants';
import { differenceInCalendarDays } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'eventWidth',
})
export class EventWidthPipe implements PipeTransform {
  transform(event: any): any {
    const dayDiff = differenceInCalendarDays(event.end_date, event.start_date);

    return dayDiff * CELL_WIDTH - 2;
  }
}
