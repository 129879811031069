import { AfterContentInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { fromEvent, Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

/**
 * These tooltips are sometimes being shown over other form fields in a very tight space (like the calendar sidebar).
 * Delaying the tooltip does not actually throttle the events and when the user moves their mouse from the tooltip
 * target to the field they want to edit, the delayed tooltip shows up under their cursor and then stays (bc tooltips
 * stay visible while being interacted with). This actually throttles the events so that a tooltip is never shown
 * after the user moves their mouse from the tooltip target and it immediately hides it when the mouse leaves the
 * trigger target. One side effect is that these tooltips cannot be interacted with
 *
 * @example ```<span #tooltipRef="matTooltip" [sbnbThrottleTooltip]="tooltipRef" [throttleTime]="1000" matTooltip="a tooltip"></span>```
 *
 */
@Directive({
  standalone: false,
  selector: '[sbnbThrottleTooltip]',
})
export class ThrottleTooltipDirective implements AfterContentInit, OnDestroy {
  @Input('sbnbThrottleTooltip') tooltip: MatTooltip;
  @Input() throttleTime = 500;

  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    if (!this.tooltip) {
      console.warn('[ThrottleTooltipDirective] No tooltip provided');
      return;
    }
    this.setupTooltipThrottling();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupTooltipThrottling() {
    this.tooltip.disabled = true;
    const mouseEnter$ = fromEvent(this.elementRef.nativeElement, 'mouseenter');
    const mouseLeave$ = fromEvent(this.elementRef.nativeElement, 'mouseleave');

    mouseEnter$
      .pipe(
        switchMap(() => timer(this.throttleTime).pipe(takeUntil(mouseLeave$))),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.tooltip.disabled = false;
        this.tooltip.show();
      });

    mouseLeave$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.tooltip.hide();
      this.tooltip.disabled = true;
    });
  }
}
