import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { User } from '@app/shared/interfaces';
import { RuleService } from '@app/shared/services/rule/rule.service';

class ImageSnippet {
  constructor(
    public src: string,
    public file: File
  ) { }
}

@Component({
  standalone: false,
  selector: 'sbnb-rule-settings',
  templateUrl: './rule-settings.component.html',
  styleUrls: ['./rule-settings.component.scss'],
})
export class RuleSettingsComponent implements OnInit {
  @Input('rulesetType') rulesetType: string;
  @Input('rulesetId') rulesetId: string;
  @Input('settings') settings: any = {};
  @Input('image') image: string;
  @Input('showSkipLastMinute') showSkipLastMinute = true;
  @Input('rulesetTiming') rulesetTiming: any = {};
  @Input('notifyVia') notifyVia: string;

  @Output('updated') updated: EventEmitter<any> = new EventEmitter();

  imageLoading: boolean;
  removingImage: boolean;
  selectedFile: ImageSnippet;
  isQuestionRuleset: boolean;
  isTeamRuleset: boolean;
  currentUser: User;

  constructor(
    private rulesetService: RuleService,
    private authService: AuthenticationService
  ) {
    this.currentUser = this.authService.getUserDetails(true);
  }

  questionStages: {
    prebooking: boolean;
    precheckin: boolean;
    staying: boolean;
  };

  ngOnInit() {
    if (this.rulesetType.startsWith('question')) {
      this.isQuestionRuleset = true;
    }

    if (this.rulesetType.startsWith('team')) {
      this.isTeamRuleset = true;
    }

    if (!this.settings.conditions && (this.rulesetType === 'checkin' || this.rulesetType === 'checkout')) {
      this.settings.conditions = {};
    }

    if (!this.settings.delay) {
      this.settings.delay = {
        has_delay_condition: false,
        delay_class: 'autohost',
      };
    }

    if (this.isQuestionRuleset) {
      this.questionStages = {
        prebooking: false,
        precheckin: false,
        staying: false,
      };

      if (this.settings.question_stages) {
        if (this.settings.question_stages.includes('prebooking')) {
          this.questionStages.prebooking = true;
        }
        if (this.settings.question_stages.includes('precheckin')) {
          this.questionStages.precheckin = true;
        }
        if (this.settings.question_stages.includes('staying')) {
          this.questionStages.staying = true;
        }
      }
    }
  }

  processImage(imageInput: any) {
    this.imageLoading = true;
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);

      this.rulesetService.uploadImage(this.rulesetId, this.selectedFile.file).subscribe(
        (res) => {
          this.imageLoading = false;
          this.image = res.data.url;
        },
        (err) => {
          this.imageLoading = false;
        }
      );
    });

    reader.readAsDataURL(file);
  }

  save() {
    this.updated.emit(this.settings);
  }

  removeImage() {
    this.removingImage = true;
    this.rulesetService.deletePicture(this.rulesetId).subscribe((res) => {
      this.removingImage = false;
      delete this.image;
    });
  }

  removeNightRestriction(event: MatCheckboxChange) {
    if (!event.checked) {
      delete this.settings.conditions.nights;
      this.save();
    }
  }

  questionCheckboxChange(newValue: boolean, key: string) {
    this.settings.question_stages = [];

    this.questionStages[key] = newValue;

    // build the array
    if (this.questionStages.prebooking) {
      this.settings.question_stages.push('prebooking');
    }
    if (this.questionStages.precheckin) {
      this.settings.question_stages.push('precheckin');
    }
    if (this.questionStages.staying) {
      this.settings.question_stages.push('staying');
    }

    this.updated.emit(this.settings);
  }

  maybeDeleteImage(event: MatCheckboxChange) {
    if (!this.image) {
      return;
    }

    if (this.image && !event.checked) {
      this.removeImage();
    }
  }
}
