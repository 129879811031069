import { Pipe, PipeTransform } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'numNights',
})
export class NumNightsPipe implements PipeTransform {
  transform(checkin: any, checkout?: any): any {
    return differenceInCalendarDays(checkout, checkin);
  }
}
