import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThreadService } from '@app/shared/services/thread/thread.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-thread-notes',
  templateUrl: './thread-notes.component.html',
  styleUrls: ['./thread-notes.component.scss'],
})
export class ThreadNotesComponent implements OnInit {
  @Input() note: string;
  @Input() uuid: string;
  @Output() noteSaved: EventEmitter<string> = new EventEmitter();

  private debouncedNoteUpdate: Subject<string> = new Subject();
  public saveStatus: number;
  public showNotesInput: boolean;

  constructor(private threadService: ThreadService) {
    this.showNotesInput = Boolean(this.note?.length);
  }

  ngOnInit() {
    this.debouncedNoteUpdate.pipe(debounceTime(2000)).subscribe((res) => {
      this.save();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.note) {
      this.showNotesInput = Boolean(changes.note?.currentValue?.length);
    }
  }

  public noteUpdated(note: string) {
    this.debouncedNoteUpdate.next(note);
  }

  public addNote(event) {
    this.showNotesInput = true;
    event.stopPropagation();
  }

  private save() {
    this.saveStatus = 1;

    this.threadService.saveNote(this.uuid, this.note).subscribe(
      (res) => {
        this.saveStatus = 2;
        this.noteSaved.emit(this.note);

        setTimeout(() => {
          this.saveStatus = 0;
        }, 2000);
      },
      (err) => {
        this.saveStatus = -1;
      }
    );
  }
}
