import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-icon-homeaway',
  templateUrl: './icon-homeaway.component.html',
  styleUrls: ['./icon-homeaway.component.scss'],
})
export class IconHomeawayComponent implements OnInit {
  @Input() color = '#2a6ebb';
  @Input() height = '18px';
  @Input() width = '18px';

  constructor() { }

  ngOnInit() { }
}
