import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: `hospitable-metrics-widget`,
  templateUrl: './metric-widget.component.html',
  styleUrls: ['./metric-widget.component.scss', './metric-widget.component.mobile.scss'],
})
export class MetricWidgetComponent implements OnInit {
  @Input() title: string | null;
  @Input() tooltip: string | null;
  @Input() badgeTooltip: string | null;
  @Input() value: string | null;
  @Input() changeInValue: string | null;
  @Input() changeInValueColour: string | null;
  @Input() titleCssClass: string | null;
  @Input() valueCssClass: string | null;

  constructor() {}

  ngOnInit(): void {}
}
