<ng-container *ngIf="chart">
  <h3 *ngIf="chart.title">{{ chart.title }}</h3>
  <div
    *ngIf="chart && chart.datasets && chart.datasets.length > 0 && chart.datasets[0].data && chart.datasets[0].data.length > 0;else noData"
    class="mt-6 grid grid-cols-1 lg:grid-cols-2 gap-2">
    <div class=" mr-4" *ngIf="chart.legend">
      <ul role="list">
        <li class="flex justify-between gap-x-6 py-2" *ngFor="let legend of chart.legend">
          <div class="flex min-w-0 gap-x-4 align-middle">
            <div class="min-w-0 flex-auto">
              <p class="md:text-lg font-semibold leading-6 text-gray-900">
                <span class="inline-flex h-3 w-3 bg-gray mr-2 rounded-full"
                  [ngStyle]="{ background: legend.colour }"></span>
                {{legend.title}}
              </p>
            </div>
          </div>
          <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
            <p class="text-lg leading-6">{{legend.value}}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="hidden sbnbDesktop:block">
      <canvas *ngIf="chartOptions" width="200px" baseChart class="chart" [data]="chartData" [type]="'doughnut'"
        [options]="chartOptions"> </canvas>
    </div>
  </div>

  <ng-template #noData>
    <div class="flex items-center justify-center mt-8">
      <hospitable-page-level-error [message]="'No data found'">
      </hospitable-page-level-error>
    </div>
  </ng-template>
</ng-container>