import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Tax } from '@app/shared/models/tax';
import { TaxService } from '@app/shared/services/taxes/tax.service';

@Component({
  standalone: false,
  selector: 'sbnb-tax-selection',
  templateUrl: './tax-selection.component.html',
  styleUrls: ['./tax-selection.component.scss'],
})
export class TaxSelectionComponent implements OnInit {
  @Input() border = false;
  @Input() selectedTaxesUuids: any[] = [];
  @Input() property;

  @Output() taxesChange: EventEmitter<any> = new EventEmitter<any>();

  loading: boolean;
  itemLoading: Tax;
  possibleTaxes: any[];
  private taxRemoved: any;
  private taxAdded: any;

  constructor(private taxService: TaxService) { }

  ngOnInit() {
    this.getPossibleTaxes();
  }

  getPossibleTaxes() {
    this.loading = true;
    this.taxService.getTaxes().subscribe((res) => {
      this.loading = false;
      this.possibleTaxes = res;
    });
  }

  emitChanges() {
    this.taxesChange.emit({
      taxes: this.selectedTaxesUuids,
      added: this.taxAdded,
      removed: this.taxRemoved,
    });
  }

  addTax(taxUuid: string) {
    this.taxService.addPropertyToTax(taxUuid, this.property.id).subscribe((res) => {
      this.itemLoading = null;
      this.selectedTaxesUuids.push(taxUuid);
      this.emitChanges();
    });
  }

  removeTax(taxUuid, index = -1) {
    this.taxService.removePropertyFromTax(taxUuid, this.property.id).subscribe((res) => {
      this.itemLoading = null;
      if (index !== -1) {
        this.selectedTaxesUuids.splice(index, 1);
      }

      this.emitChanges();
    });
  }

  taxCheckboxChange(tax: Tax, event: MatCheckboxChange) {
    if (event.checked) {
      if (this.selectedTaxesUuids.indexOf(tax.uuid) === -1) {
        this.itemLoading = tax;
        this.taxAdded = tax;
        this.addTax(tax.uuid);
      }
    } else {
      const index = this.selectedTaxesUuids.findIndex((r) => r === tax.uuid);

      if (index !== -1) {
        this.itemLoading = tax;
        this.taxRemoved = tax;
        this.removeTax(tax.uuid, index);
      }
    }
    this.emitChanges();
  }

  hostsSearchUpdated(search: string) {
    this.possibleTaxes.forEach((pp, index) => {
      if (pp.name.toLowerCase().includes(search.toLowerCase())) {
        this.possibleTaxes[index].hidden = false;
      } else {
        this.possibleTaxes[index].hidden = true;
      }
    });
  }
}
