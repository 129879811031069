import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { config } from '@app/core/app-config';
import { ManualQuoteFee, Money } from '@app/shared/interfaces';
import { Language } from '@app/shared/models/language';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { LanguageService } from '@app/shared/services/language/language.service';
import { ManualBooking, ManualBookingsService } from '@app/shared/services/manual-bookings/manual-bookings.service';
import { PropertiesService, SimpleProperty } from '@app/shared/services/properties/properties.service';
import { ReservationService } from '@app/shared/services/reservation/reservation.service';
import { getBoolean } from '@app/shared/utils';
import { addDays, format, subDays } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  standalone: false,
  templateUrl: './dialog-add-manual-booking.component.html',
  styleUrls: ['./dialog-add-manual-booking.component.scss'],
})
export class DialogAddManualBookingComponent implements OnInit, OnDestroy {
  config = config;
  isMobile$ = this.deviceDetectionService.isMobileViewport();

  channelOptions = [
    { value: 'agoda', label: 'Agoda' },
    { value: 'tripadvisor', label: 'Tripadvisor' },
    { value: 'misterbnb', label: 'Misterb&b' },
    { value: 'expedia', label: 'Expedia' },
    { value: 'flipkey', label: 'Flipkey' },
    { value: 'glampinghub', label: 'GlampingHub' },
    { value: 'kid&coe', label: 'Kid & Coe' },
    { value: 'wimdu', label: 'Wimdu' },
    { value: 'evolve', label: 'Evolve' },
    { value: 'holidaylettings', label: 'Holiday Lettings' },
    { value: 'hostelworld', label: 'Hostelworld' },
    { value: 'other', label: 'Other' },
  ];

  properties: SimpleProperty[] = [];
  public selectedProperty$: BehaviorSubject<SimpleProperty> = new BehaviorSubject<SimpleProperty>(null);
  languages: Language[];
  reservations: { start: string; end: string }[];

  hasReservationCode = false;

  manualBooking: ManualBooking = {
    platform: 'manual',
    reservationCode: '',
    channel: '',
    guestLanguage: 'en',
    numGuests: 2,
    adults: 2,
    children: 0,
    infants: 0,
    pets: 0,
    currency: 'USD',
  };
  financialQuote: { fees: ManualQuoteFee[]; total: Money; orig_total?: Money };
  @ViewChild('phoneModel') phoneModel!: NgModel;
  saving: boolean;

  private guestSearchString = new Subject<string>();
  guestSearchResults: string[] = [];
  isLoadingGuestResults: boolean;
  recalculatingQuote: boolean;
  public requiresReviewAndConfirm = false;
  public showReviewAndConfirm = false;
  public loadingProperty$ = new BehaviorSubject<boolean>(false);

  private recalculateQuote$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  public loadingReservation$ = new BehaviorSubject<boolean>(true);

  constructor(
    public dialogRef: MatDialogRef<DialogAddManualBookingComponent>,
    private propertyService: PropertiesService,
    private languageService: LanguageService,
    private manualBookingsService: ManualBookingsService,
    private reservationsService: ReservationService,
    private deviceDetectionService: DeviceDetectionService,

    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      hasReservationCode: boolean;
      mode: 'create' | 'update';
      booking?: ManualBooking;
      startDate?: Date;
      endDate?: Date;
      propertyId?: number;
    }
  ) {}

  get isPhoneInvalid() {
    if (!this.phoneModel) {
      return false;
    }

    return this.phoneModel?.invalid && (this.phoneModel?.dirty || this.phoneModel?.touched);
  }

  ngOnInit(): void {
    this.loadingReservation$.next(true);

    this.getLanguages();
    this.getProperties();

    this.hasReservationCode = this.dialogData.hasReservationCode
      ? getBoolean(this.dialogData.hasReservationCode)
      : false;

    let shouldTriggerQuote = false;

    if (this.dialogData.booking && this.dialogData.mode === 'update') {
      this.manualBooking = this.dialogData.booking;

      shouldTriggerQuote = true;

      this.fetchReservations();
    } else {
      this.reservations = [];
    }

    if (this.dialogData.startDate) {
      this.manualBooking.checkIn = format(this.dialogData.startDate, 'YYYY-MM-DD');
    }

    if (this.dialogData.endDate) {
      this.manualBooking.checkOut = format(this.dialogData.endDate, 'YYYY-MM-DD');
    }

    if (this.dialogData.propertyId) {
      this.manualBooking.propertyId = this.dialogData.propertyId;
    }

    this.loadingReservation$.next(false);

    this.guestSearchString
      .pipe(
        tap(() => {
          this.isLoadingGuestResults = true;
          this.guestSearchResults = [];
        }),
        debounceTime(300),
        switchMap((term) => this.searchGuests(term))
      )
      .subscribe((results) => {
        if (results.data) {
          this.guestSearchResults = results.data;
        }
      });

    this.recalculateQuote$
      .pipe(
        switchMap(() =>
          this.manualBookingsService.getQuoteForManualBooking(
            this.manualBooking.propertyId,
            this.manualBooking.checkIn,
            this.manualBooking.checkOut,
            this.financialQuote?.fees ?? [],
            this.manualBooking.platform,
            this.manualBooking.reservationCode
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.recalculatingQuote = false;
          this.financialQuote.total = res.total;
        },
        error: () => {
          this.recalculatingQuote = false;
        },
      });

    if (shouldTriggerQuote) {
      this.getQuote();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onGuestNameChange(newTerm: string) {
    if (newTerm?.length > 2) {
      this.guestSearchString.next(newTerm);
    } else {
      this.guestSearchResults = [];
    }
  }

  onOptionSelected(event) {
    const selectedOption = event.option.value;
    if (!selectedOption) {
      return;
    }
    this.manualBooking.guestEmail = selectedOption.email;
    this.manualBooking.guestFirstName = selectedOption.first_name;
    this.manualBooking.guestLastName = selectedOption.last_name;
    this.manualBooking.guestPhone = selectedOption.phone;

    if (selectedOption.locale) {
      this.manualBooking.guestLanguage = selectedOption.locale;
    }
  }

  searchGuests(term: string) {
    return this.manualBookingsService.searchGuests(term).pipe(
      tap(() => {
        this.isLoadingGuestResults = false;
      })
    );
  }

  fetchReservations() {
    const propertyId = this.manualBooking.propertyId;

    this.reservationsService
      .getReservationsByDate(subDays(new Date(), 90), addDays(new Date(), 1000), [propertyId], false, true)
      .subscribe((res) => {
        // If we are in edit mode, we need to remove the existing reservation from our reservation blocks
        if (this.dialogData.mode === 'update') {
          // The API doesnt consistently return HOST- prefix in all endpoints. So we'll filter twice to be sure we've removed the matching reservation
          res = res.filter((booking) => booking.code !== `HOST-${this.manualBooking.reservationCode}`);

          res = res.filter((booking) => booking.code !== `ICAL-${this.manualBooking.reservationCode}`);

          res = res.filter((booking) => booking.code !== `${this.manualBooking.reservationCode}`);
        }

        this.reservations = res.map((item) => {
          return { start: item.checkin, end: item.checkout };
        });
      });
  }

  datesSelected(dates: string[]) {
    this.manualBooking.checkIn = dates[0];
    this.manualBooking.checkOut = dates[1];

    this.getQuote();
  }

  getLanguages() {
    this.languageService.getLanguages().subscribe((res) => {
      this.languages = res;
    });
  }

  getProperties() {
    this.propertyService
      .getProperties({
        filterCriteria: [],
        paginate: false,
        transformer: 'simple',
      })
      .subscribe(
        (res) => {
          if (this.manualBooking.platform === 'ical') {
            // can't change property of an iCal booking
            this.properties = res.data.filter((prop) => prop.id === this.manualBooking.propertyId);
          } else {
            this.properties = res.data;
          }

          if (this.dialogData.propertyId) {
            this.setSelectedProperty(this.dialogData.propertyId);
          }
        },
        () => {
          this.loadingProperty$.next(false);
        }
      );
  }

  onSelectedPropertyChanged(propertyId: number) {
    this.manualBooking.propertyId = propertyId;
    this.setSelectedProperty(propertyId);
    this.fetchReservations();
    this.getQuote();
  }

  getSelectedChannelObj(value: string): any {
    let resolvedValue = value;

    if (resolvedValue == 'manual') {
      resolvedValue = 'other';
    }

    return this.channelOptions?.find((op) => op.value === resolvedValue);
  }

  createBooking() {
    if (this.isPhoneInvalid) return;

    this.saving = true;
    this.manualBookingsService.createBooking(this.manualBooking, this.financialQuote.fees).subscribe(
      () => {
        this.saving = false;
        this.dialogRef.close({ created: this.manualBooking });
      },
      () => {
        this.showReviewAndConfirm = false;
        this.saving = false;
      }
    );
  }

  updateBooking() {
    if (this.isPhoneInvalid) return;

    this.saving = true;
    this.manualBookingsService
      .updateBooking(this.manualBooking, this.financialQuote.fees, this.manualBooking.platform)
      .subscribe(
        () => {
          this.saving = false;
          this.dialogRef.close({ updated: this.manualBooking });
        },
        () => {
          this.showReviewAndConfirm = false;
          this.saving = false;
        }
      );
  }

  onGuestsChanged() {
    // Request a new quote
    this.getQuote();
  }

  getQuote() {
    if (
      !this.manualBooking.propertyId ||
      !this.manualBooking.checkIn ||
      !this.manualBooking.checkOut ||
      (!this.manualBooking.adults && this.manualBooking.platform !== 'ical')
    ) {
      return;
    }

    this.manualBookingsService
      .getQuoteForManualBooking(
        this.manualBooking.propertyId,
        this.manualBooking.checkIn,
        this.manualBooking.checkOut,
        [],
        this.manualBooking.platform,
        this.manualBooking.reservationCode
      )
      .subscribe((res) => {
        this.financialQuote = res;
        this.financialQuote.orig_total = res.total;
      });
  }

  recalculateQuoteTotal() {
    if (
      !this.manualBooking.propertyId ||
      !this.manualBooking.checkIn ||
      !this.manualBooking.checkOut ||
      (!this.manualBooking.adults && this.manualBooking.platform !== 'ical')
    ) {
      return;
    }

    this.recalculatingQuote = true;

    // Emit a new value to trigger the switchMap
    this.recalculateQuote$.next();
  }

  addFee() {
    this.financialQuote.fees.push({
      type: 'other_fee_manual',
      amount: {
        amount: 0,
        currency: this.manualBooking.currency,
        formatted_string: null,
        formatted_decimal: '0.00',
      },
      label: '',
    });
  }

  removeFee(fee: ManualQuoteFee) {
    this.financialQuote.fees = this.financialQuote.fees.filter((x) => x !== fee);
    this.recalculateQuoteTotal();
  }

  public continue() {
    this.showReviewAndConfirm = true;
  }

  public goBack() {
    this.showReviewAndConfirm = false;
  }

  private setCurrency() {
    this.manualBooking.currency = null;

    if (this.selectedProperty$.value) {
      this.manualBooking.currency = this.selectedProperty$.value.currency;
    }
  }

  private setSelectedProperty(propertyId: number) {
    this.loadingProperty$.next(true);
    this.selectedProperty$.next(this.properties.find((x) => x.id === propertyId));
    this.setCurrency();
    this.propertyService.getProperty(propertyId).subscribe((res) => {
      this.requiresReviewAndConfirm = res.calendar_restricted;
      this.loadingProperty$.next(false);
    });
  }
}
