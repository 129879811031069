<div class="editor-menu">
  <ng-container *ngTemplateOutlet="menu"></ng-container>
</div>

<div class="editor-content" #editorContent></div>

<div #bubbleMenu class="bubble-menu">
  <ng-container *ngTemplateOutlet="menu"></ng-container>
</div>

<ng-template #menu>
  <ng-container *ngIf="this.editor">
    <select class="editor-menu--item" (change)="setTypography($event.target.value)" [value]="getActiveTypography()">
      <option value="paragraph">Paragraph</option>
      <option value="heading1">Heading 1</option>
      <option value="heading2">Heading 2</option>
      <option value="heading3">Heading 3</option>
      <option value="heading4">Heading 4</option>
      <option value="heading5">Heading 5</option>
    </select>
    <button class="editor-menu--item" (click)="this.editor.chain().focus().toggleBold().run()"
      [class.active]="this.editor.isActive('bold')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z" />
      </svg>
    </button>
    <button class="editor-menu--item" (click)="this.editor.chain().focus().toggleItalic().run()"
      [class.active]="this.editor.isActive('italic')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
      </svg>
    </button>
    <button class="editor-menu--item" (click)="this.editor.chain().focus().toggleUnderline().run()"
      [class.active]="this.editor.isActive('underline')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M12 17c3.31 0 6-2.69 6-6V4h-2v7c0 2.21-1.79 4-4 4s-4-1.79-4-4V4H6v7c0 3.31 2.69 6 6 6zM5 20v2h14v-2H5z" />
      </svg>
    </button>

    <button class="editor-menu--item" (click)="alignText('left')"
      [class.active]="this.editor.isActive({ textAlign: 'left' })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
      </svg>
    </button>

    <button class="editor-menu--item" (click)="alignText('center')"
      [class.active]="this.editor.isActive({ textAlign: 'center' })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 6.75h12M4.5 12h15m-12 5.25h9" />
      </svg>

    </button>

    <button class="editor-menu--item" (click)="alignText('right')"
      [class.active]="this.editor.isActive({ textAlign: 'right' })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
      </svg>

    </button>

    <button class="editor-menu--item" (click)="toggleLink()" [class.active]="this.editor.isActive('link')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M3.9,12a4.971,4.971,0,0,1,1.42-3.54L9.83,3.95a5.018,5.018,0,0,1,7.07,0,4.974,4.974,0,0,1,0,7.07L14.83,13.1l-1.42-1.42,2.83-2.83a2.99,2.99,0,0,0,0-4.24,3.007,3.007,0,0,0-4.24,0L6.34,9.46a3.003,3.003,0,0,0,0,4.24l1.42,1.42Zm16.2,0a4.971,4.971,0,0,1-1.42,3.54l-4.51,4.51a5.018,5.018,0,0,1-7.07,0,4.974,4.974,0,0,1,0-7.07l2.83-2.83,1.42,1.42-2.83,2.83a2.99,2.99,0,0,0,0,4.24,3.007,3.007,0,0,0,4.24,0l4.51-4.51a3.003,3.003,0,0,0,0-4.24l-1.42-1.42Z" />
      </svg>
    </button>

    <button class="editor-menu--item" (click)="toggleOrderedList()"
      [class.active]="this.editor.isActive('orderedList')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M8 5h13v2H8zM8 11h13v2H8zM8 17h13v2H8zM3.5 11H5v5h-1V12H3v-1zm0-3H5V5H4.5l-1 1h1v1H2V7h2zm0 8.5h1V19H3v-1.5z" />
      </svg>
    </button>

    <button class="editor-menu--item" (click)="toggleBulletList()" [class.active]="this.editor.isActive('bulletList')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M8 5h13v2H8zM8 11h13v2H8zM8 17h13v2H8zM3.5 11H5v2h-1v-1H2v1.5h1.5v.5H2V15h3v-2H4v1H3.5v-.5H5V12h-1V11zm0-3H5V5H3V4h2v5h-1v-1h-1v1H2V8h2V7zm0 10h1.5V19H3v-1.5H2V17h1.5v-1z" />
      </svg>
    </button>
  </ng-container>
</ng-template>