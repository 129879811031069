import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { OnboardingService } from '@app/modules/onboarding/onboarding.service';
import { User } from '@app/shared/interfaces';

@Component({
  standalone: false,
  selector: 'sbnb-onboarding-helper',
  templateUrl: './onboarding-helper.component.html',
  styleUrls: ['./onboarding-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingHelperComponent implements OnInit {
  user: User;
  onboarding$ = this.onboardingService.onboardingItems;

  constructor(
    private readonly authService: AuthenticationService,
    private onboardingService: OnboardingService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserDetails();
  }
}
