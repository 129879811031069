import { Component, Input, OnInit } from '@angular/core';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { DirectSubscriptionService } from '@app/modules/direct/services/direct-subscription.service';
import { BillingFacade } from '@app/shared/+state/billing';
import { UsersFacade } from '@app/shared/+state/users';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { environment } from '@env/environment';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
@Component({
  standalone: false,
  selector: 'sbnb-gx-subnav',
  templateUrl: './gx-subnav.component.html',
  styleUrls: ['./gx-subnav.component.scss'],
})
export class GxSubnavComponent implements OnInit {
  environment = environment;

  @Input() showSegments: string;

  public isUpsellEnabled: boolean = false;
  public isInboxAIEnabled: boolean = false;

  /* remove when mobile nav tabs are stable */
  public featureFlagMobileNavTabsEnabled: boolean = false;

  constructor(
    private userPermissionsService: UserPermissionsService,
    private directSubscriptionService: DirectSubscriptionService,
    private posthog: PosthogService,
    public deviceDetectionService: DeviceDetectionService,
    private readonly billingFacade: BillingFacade,
    private readonly usersFacade: UsersFacade
  ) {}

  ngOnInit() {
    /* remove when mobile nav tabs are stable */
    this.posthog.mobileNavTabsEnabled$.pipe(take(1)).subscribe((enabled) => {
      this.featureFlagMobileNavTabsEnabled = !!enabled;
    });

    this.directSubscriptionService.isPremium$.pipe(take(1)).subscribe((isPremium) => {
      this.isUpsellEnabled = !!isPremium;
    });

    combineLatest([
      this.billingFacade.isMogulSubscription$,
      this.posthog.gxInboxAIEnabled$,
      this.userPermissionsService.hasAccessTo(Features.PERM_INBOX),
      this.usersFacade.mogulAiEntitlement$
    ])
      .pipe(map(([isMogul, featureEnabled, hasAccess, mogulAiEntitlement]) => (this.isInboxAIEnabled = (isMogul || featureEnabled || mogulAiEntitlement?.available) && hasAccess)))
      .subscribe();
  }
}
