import { Pipe, PipeTransform } from '@angular/core';
import { Alteration } from '../services/alterations/alterations.service';

@Pipe({
  standalone: false,
  name: 'alterationIsAirbnb',
})
export class AlterationIsAirbnbPipe implements PipeTransform {
  transform(alteration: Alteration): boolean {
    return alteration.platform === 'airbnb-official';
  }
}
