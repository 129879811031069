import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Host } from '@app/shared/models/host';
import { DialogHostsComponent } from '../dialog-hosts/dialog-hosts.component';

@Component({
  standalone: false,
  selector: 'sbnb-hosts',
  templateUrl: './hosts.component.html',
  styleUrls: ['./hosts.component.scss'],
})
export class HostsComponent implements OnInit {
  @Input() hosts: Host[];
  @Input() allHosts = false;
  @Input() ruleName: string;

  tooltip: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.buildTooltip();
  }

  buildTooltip(): void {
    this.tooltip = '';

    if (this.allHosts || !this.hosts || this.hosts.length === 0) {
      return;
    }

    if (this.hosts.length > 10) {
      this.tooltip = 'Click to see full list';
      return;
    }

    this.hosts.forEach((host: Host) => {
      this.tooltip += `${host.name} \n`;
    });
  }

  openHostsModal(): void {
    if (this.allHosts || !this.hosts || this.hosts.length === 0) {
      return;
    }

    this.dialog.open(DialogHostsComponent, {
      width: '486px',
      maxHeight: '90vh',
      data: {
        hosts: this.hosts,
        ruleName: this.ruleName,
      },
    });
  }
}
