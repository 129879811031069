import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DirectService } from '@app/modules/direct/services/direct.service';
import { NotificationType } from '@app/shared/interfaces/lib/notification.interface';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  selector: 'dialog-review-guest',
  templateUrl: 'dialog-review-guest.component.html',
  styles: [
    `
      .fs-dialog-contents {
        max-width: 800px;
        width: 100%;
      }
    `,
  ],
})
export class DialogReviewGuestComponent {
  recommended: boolean = null;
  comment = '';
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogReviewGuestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reservationUuid: string; guestName: string },
    private readonly direct: DirectService,
    private toast: ToastNotificationsService
  ) {}

  submit() {
    this.loading = true;

    this.direct.leaveGuestReview(this.data.reservationUuid, this.recommended, this.comment).subscribe((res) => {
      if (res.error) {
        this.toast.open(res.message, 'Dismiss', NotificationType.Error);

        this.loading = false;

        return;
      }

      // We're caveman delaying this for now as core -> booking service -> core webhooks takes a bit of time before the `supports` flag flips back
      // TODO: Optimistically update the `supports` flag on the reservation
      setTimeout(() => {
        this.toast.open('Successfully submitted review', 'Dismiss', NotificationType.Success);

        this.loading = false;

        this.dialogRef.close(true);
      }, 8000);
    });
  }
}
