import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'eventWidthTask',
})
export class EventWidthTaskPipe implements PipeTransform {
  minutesInADay = 1440;

  transform(event: any, dayWidth: number): any {
    // const dayDiff = differenceInCalendarDays(event.end_date, event.start_date);

    // return (dayDiff * dayWidth) - 1;
    const minDiff = differenceInMinutes(event.end_date, event.start_date);
    const pixelsForOneMinute = dayWidth / this.minutesInADay;

    return minDiff * pixelsForOneMinute;
  }
}
