<!-- Header with close button -->
<section class="flex justify-end items-center p-4">
  <sbnb-close [border]="true" (click)="onCancel()"></sbnb-close>
</section>

<!-- Main content -->
 <section>
  <ng-container>
    <div class="fs-dialog-contents mx-auto">
      <!-- Heading-->
      <section class="mb-4">
        <h2 class="mt-4 mb-4">
          Report Invalid Credit Card
        </h2>

        <p class="night nomargin">
          This action will send an email to the guest to inform them that their credit card is invalid.
          It will provide them a chance to update their credit card information. Are you sure you want to proceed?
        </p>
      </section>

      <!-- Action buttons -->
      <section class="flex justify-end gap-3">
        <sbnb-button
          label="Cancel"
          type="secondary"
          size="mini"
          [disabled]="saving"
          data-element="report-invalid-payment-cancel"
          (clicked)="onCancel()">
        </sbnb-button>
        <sbnb-button
          label="Report"
          type="primary"
          size="mini"
          [loading]="saving"
          data-element="report-invalid-payment-confirm"
          (clicked)="onConfirm()">
        </sbnb-button>
      </section>
    </div>
  </ng-container>
</section>
