import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { CompactThreadPayload, MessageReview } from '@app/shared/services/thread/thread.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { FilterService } from '../filter/filter.service';

export interface AIReviewSuggestions {
  terrible_review: string;
  terrible_private_feedback: string;
  bad_review: string;
  bad_private_feedback: string;
  decent_review: string;
  decent_private_feedback: string;
  good_review: string;
  good_private_feedback: string;
  excellent_review: string;
  excellent_private_feedback: string;
}

export interface ReviewSuggestion {
  public: string;
  private: string;
  cleanliness_tags: string[];
  respect_house_rules_tags: string[];
  communication_tags: string[];
}

export interface EnhancedAIReviewSuggestions {
  terrible_review: ReviewSuggestion;
  bad_review: ReviewSuggestion;
  decent_review: ReviewSuggestion;
  good_review: ReviewSuggestion;
  excellent_review: ReviewSuggestion;
}

export interface Review {
  message: string;
  thread: {
    uuid: string;
    language: any;
  };
  languages: string;
  review_id: string;
  type: string;
  title: string;
  platform_human: string;
  platform: string;
  reservation_code: string;
  scheduled_for: string;
  scheduled_for_label: string;
  editable: boolean;
  sent_at: string | null;
  sent_at_label: string | null;
  cancelled_at: string | null;
  cancelled_at_label: string | null;
  failed: boolean;
  prepared_message: string | null;
  private_feedback: string | null;
  review: {
    bad_review: boolean;
    grades: ReviewGrades;
    previous_review: string | null;
    category_tags: {
      cleanliness_tags: string[];
      respect_house_rules_tags: string[];
      communication_tags: string[];
    }
  };
  guest: {
    name: string;
    picture: string;
  };
  attributes: Attribute[];
  _links: Links;
  ruleset: Ruleset | null;
  suggestions?: AIReviewSuggestions;
  enhanced_suggestions?: EnhancedAIReviewSuggestions;
}

export interface ReviewGrades {
  rating: number;
  cleanliness: number;
  respect_house_rules: number;
  communication: number;
  recommend: boolean;
  previous_review?: string; // the original generated 5-star review
}

export interface Attribute {
  id: string;
  label: string;
  type: string;
  name?: string;
  image?: string;
  thread_uuid?: string;
  text?: string;
  value?: string;
  ruleset_id?: number;
  event_type?: string;
}

export interface Links {
  cancel: string;
  update: string;
  show: string;
}

export interface Ruleset {
  id: number;
  name: string;
  event: string;
}

export interface SendReviewPayload {
  reviewId: string;
  message: string;
  grades: ReviewGrades;
  send_now: boolean;
  bad_review: boolean;
  private_feedback?: string;
  cleanliness_tags?: string[];
  respect_house_rules_tags?: string[];
  communication_tags?: string[];
}

export interface PreviewMessageResponse {
  message: string;
  subject: string;
  simulating_products: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RuleService {
  private getRulesetsUrl = `${config.API_URL}/rulesets/`;
  private getSingleRuleSetUrl = `${config.API_URL}/rulesets/`;
  private getRulesForRuleSetUrl = `${config.API_URL}/rules?ruleset_id=`;
  private updateRuleUrl = `${config.API_URL}/rules/`;
  private resultsPerPage = 20;

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private errorService: ErrorService
  ) {}

  getRulesets(
    type = 'other',
    filterCriteria?: any[],
    offset?: number,
    search?: string,
    limit?: number,
    limitToRuleSetScope?: number,
    limitType?: string,
    limitToThreadUuid?: string
  ): Observable<any> {
    let url = `${this.getRulesetsUrl}?type=${type}&limit=${limit ? limit : this.resultsPerPage}`;

    if (limitToRuleSetScope && limitType === 'ruleset') {
      url += `&relevant_to_ruleset_id=${limitToRuleSetScope}`;
    }

    if (limitToRuleSetScope && limitType === 'customCode') {
      url += `&relevant_to_customcode_id=${limitToRuleSetScope}`;
    }

    if (limitToThreadUuid) {
      url += `&limit_to_thread=${limitToThreadUuid}`;
    }

    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }

    if (type === 'canned') {
      url = `${url}&order_by=name`;
    }

    return this.http.post(url, { filters: apiFilters }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  public getReviewRulesets({ limit }): Observable<any> {
    const url = `${this.getRulesetsUrl}?type=review&limit=${limit ?? 20}`;
    return this.http.post(url, { filters: {} }).pipe(catchError(this.handleError));
  }

  getRuleSetById(id, withTimingMeta = true) {
    let url = `${this.getRulesetsUrl}${id}`;

    if (withTimingMeta) {
      url = `${url}?with_timings=1`;
    }

    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => of(err))
    );
  }

  activeOrDeactivateRuleset(id: string, activate: boolean) {
    const mode = activate ? 'activate' : 'deactivate';

    const url = `${this.getRulesetsUrl}${id}/${mode}`;

    return this.http.put(url, {}).pipe(
      map((res) => res),
      catchError((err: HttpErrorResponse) => of(err))
    );
  }

  updateRuleSet(id, payload) {
    const url = `${this.getRulesetsUrl}${id}`;

    return this.http.post(url, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleUpdateError)
    );
  }

  deleteRuleset(id: any): Observable<any> {
    const url = `${this.getSingleRuleSetUrl}${id}`;

    return this.http.delete(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  copyRuleset(id: any): Observable<any> {
    const url = `${this.getRulesetsUrl}${id}/copy`;

    return this.http.put(url, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getRulesForRuleset(id: any): Observable<any> {
    const url = `${this.getRulesForRuleSetUrl}${id}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  createRule(rulesetId, langCode) {
    const url = `${this.updateRuleUrl}`;

    const payload = {
      ruleset_id: rulesetId,
      language: langCode,
    };

    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateRule(id, payload) {
    const url = `${this.updateRuleUrl}${id}`;

    const putPayload = {
      language: payload.language.key,
      template: payload.updatedTemplate ? payload.updatedTemplate : payload.template,
      metadata: {},
    };

    putPayload.template = putPayload.template.replace(/\n{3,}/g, '\n\n');

    if (payload.metadata && (payload.metadata.subject || payload.metadata.subject_updated)) {
      putPayload.metadata = {
        subject: payload.metadata.subject_updated ? payload.metadata.subject_updated : payload.metadata.subject,
      };
    }

    return this.http.put(url, putPayload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteRule(rule) {
    const url = `${this.updateRuleUrl}${rule.id}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  createRuleset(payload) {
    const url = `${this.getRulesetsUrl}store`;

    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getOverviewCounts() {
    const url = `${config.API_URL}/counts/ge`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getScheduledMessages() {
    const url = `${config.API_URL}/gx/overview/scheduled`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  // Fetching a list of pending review replies for the overview
  getReviewReplies(): Observable<MessageReview[]> {
    return this.http.get(`${config.API_URL}/gx/overview/review-replies`).pipe(
      map((res: { data: MessageReview[] }) => {
        return res.data;
      })
    );
  }

  getSingleMessage(id, type = 'scheduled') {
    if (type === 'activity') {
      type = 'activities';
    }

    const url = `${config.API_URL}/gx/log/${type}/${id}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  sendMessage(
    id,
    message,
    send_now,
    type = 'scheduled',
    date?: string,
    hour?: string,
    minute?: string,
    ai_rejected = false
  ) {
    const url = `${config.API_URL}/gx/log/${type}/${id}`;

    const payload: any = {
      message,
      send_now,
      ai_rejected,
    };

    if (date != null && hour != null && minute != null) {
      if (+hour < 10) {
        hour = `0${+hour}`;
      }
      if (+minute < 10) {
        minute = `0${+minute}`;
      }
      payload.date = date.toString();
      payload.hour = hour.toString();
      payload.minute = minute.toString();
    }

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteSingleMessage(id: string, type = 'scheduled') {
    const url = `${config.API_URL}/gx/log/${type}/${id}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getScheduledReviews() {
    const url = `${config.API_URL}/gx/overview/reviews`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  getSingleReview(id) {
    const url = `${config.API_URL}/gx/log/reviews/${id}`;

    return this.http.get<{ data: Review }>(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  deleteSingleReview(id) {
    const url = `${config.API_URL}/gx/log/reviews/${id}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  sendReview({ reviewId, message, grades, send_now, bad_review, private_feedback, cleanliness_tags, respect_house_rules_tags, communication_tags }: SendReviewPayload) {
    const url = `${config.API_URL}/gx/log/reviews/${reviewId}`;

    const payload: {
      message: string;
      grades: ReviewGrades;
      bad_review: boolean;
      send_now: boolean;
      private_feedback: string;
      cleanliness_tags?: string[];
      respect_house_rules_tags?: string[];
      communication_tags?: string[];
    } = {
      message,
      grades,
      bad_review,
      send_now: bad_review ? false : send_now,
      private_feedback,
    };

    // Add tags only if they exist
    if (cleanliness_tags) {
      payload.cleanliness_tags = cleanliness_tags;
    }
    if (respect_house_rules_tags) {
      payload.respect_house_rules_tags = respect_house_rules_tags;
    }
    if (communication_tags) {
      payload.communication_tags = communication_tags;
    }

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public uploadImage(rulesetId: string, image: File): Observable<any> {
    const url = `${config.API_URL}/rulesets/${rulesetId}/picture?ngsw-bypass`;

    const formData = new FormData();

    const headers = new HttpHeaders().set('ngsw-bypass', '1');

    formData.append('picture', image);

    return this.http.post(url, formData, { headers });
  }

  deletePicture(rulesetId: string) {
    const url = `${config.API_URL}/rulesets/${rulesetId}/picture`;

    return this.http.delete(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  private handleError(err) {
    return of({
      error: true,
      message: err.error.message,
    });
  }

  private handleUpdateError(err) {
    return of({
      error: true,
      message: err.error.message,
    });
  }

  threadsForMessagePreview(
    rulesetId: string
  ): Observable<CompactThreadPayload[] | { error: boolean; message: string }> {
    const url = `${config.API_URL}/ruleset/preview/${rulesetId}/threads`;

    return this.http.get(url).pipe(
      map((res: { data: CompactThreadPayload[] }) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  previewMessage(threadId, rulesetId, ruleId?: string, team_uuid?: string): Observable<PreviewMessageResponse> {
    let url = `${config.API_URL}/ruleset/preview/${rulesetId}/thread/${threadId}?rule_id=${ruleId}`;

    if (team_uuid) {
      url = `${url}&teammate_uuid=${team_uuid}`;
    }

    return this.http.get(url).pipe(
      map((res: { data: PreviewMessageResponse }) => {
        return res.data;
      }),
      catchError((err: HttpErrorResponse) => of(null))
    );
  }
}
