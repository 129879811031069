export enum LoadingType {
  Spinner = 'spinner',
}

export enum LoadingSize {
  XSmall = 15,
  Small = 20,
  Medium = 30,
  Large = 50,
  XLarge = 75,
}
