import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Channel } from '@app/shared/models/channel';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { MessageReview } from '@app/shared/services/thread/thread.service';

interface CategoryRating {
  rating: number;
  comments: string | null;
  tags: string[];
}

interface CategoryRatings {
  cleanliness: CategoryRating;
  communication: CategoryRating;
  accuracy: CategoryRating;
  checkin: CategoryRating;
  location: CategoryRating;
  value: CategoryRating;
}

@Component({
  standalone: false,
  selector: 'sbnb-received-review',
  templateUrl: `./received-review.component.html`,
  styleUrls: ['./received-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedReviewComponent {
  @Input() message: MessageReview & {
    category_ratings?: CategoryRatings;
  };
  public Channel = Channel;

  public readonly isMobile$ = this.deviceDetectionService.isMobileViewport();
  public categoryRatingsEnabled = false;

  // Helper to get all categories
  public readonly categories = [
    'cleanliness',
    'communication',
    'accuracy',
    'checkin',
    'location',
    'value'
  ] as const;

  constructor(
    private readonly deviceDetectionService: DeviceDetectionService,
    private readonly optimizelyService: OptimizelyService
  ) {
    this.optimizelyService.reviewCategoryTagsEnabled$.subscribe(
      enabled => this.categoryRatingsEnabled = enabled
    );
  }
}
