import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PayoutMethodProvider } from '@app/modules/direct/services/payout-methods.service';
import { LoadingState, Reservation } from '@app/shared/interfaces';
import { GuestVettingData } from '@app/shared/interfaces/lib/guestvetting.interface';
import { Channel } from '@app/shared/models/channel';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { GuestInsights, GuestInsightsService } from '@app/shared/services/guest-insights/guest-insights.service';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';
import { ThreadPayload, ThreadService } from '@app/shared/services/thread/thread.service';
import { addHours, isPast } from 'date-fns';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { DialogGuestVettingExtendedDetailsComponent } from '../dialog-guest-vetting-extended-details/dialog-guest-vetting-extended-details.component';

@Component({
  standalone: false,
  selector: 'sbnb-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
})
export class InsightsComponent implements OnInit, OnChanges {
  Channel = Channel;
  PayoutMethodProvider = PayoutMethodProvider;
  numReviewsShown = 5;
  numReviews = 0;
  guestVettingData$: Observable<GuestVettingData>;
  deadlineForInquiryResponse: Date | null = null;

  @Input() thread: ThreadPayload;
  @Input() removeBorders = false;
  @Input() activeReservation: Reservation;

  @Output() preapproveClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() declineClicked: EventEmitter<boolean> = new EventEmitter();
  public insights: GuestInsights;
  public preapprovalLoading$ = this.selectedReservationService.preapprovalState$.pipe(
    map((state) => state === LoadingState.Pending)
  );

  public preapprovalSuccess$ = this.selectedReservationService.preapprovalState$.pipe(
    map((state) => state === LoadingState.Success)
  );

  constructor(
    private threadService: ThreadService,
    private dialog: MatDialog,
    private deviceDetectionService: DeviceDetectionService,
    private guestInsightsService: GuestInsightsService,
    private selectedReservationService: SelectedReservationService
  ) { }

  ngOnInit() {
    this.deviceDetectionService.isMobileViewport().subscribe((isMobile) => {
      isMobile && (this.numReviewsShown = 2);
    });

    if (this.thread.guest && this.thread.guest.reviews) {
      this.numReviews = this.thread.guest.reviews.length;
    }

    this.insights = this.guestInsightsService.guestInsightsTransformer(this.thread);
    this.calculateDeadlineForInquiryResponse();
  }

  ngOnChanges() {
    this.calculateDeadlineForInquiryResponse();

    if (
      this.activeReservation?.platform === Channel.Direct &&
      this.activeReservation?.payment_enabled &&
      this.activeReservation?.payment_provider === PayoutMethodProvider.ADYEN &&
      this.activeReservation?.autohost?.id
    ) {
      this.fetchReservationDetails();
    }
  }

  fetchReservationDetails() {
    // could be an inquiry, which doesn't have a uuid
    if (this.activeReservation?.uuid) {
      this.guestVettingData$ = this.threadService.getGuestVettingData(this.activeReservation.uuid).pipe(share());
    }
  }

  calculateDeadlineForInquiryResponse() {
    const deadline = addHours(this.thread.created_at, 24);

    this.deadlineForInquiryResponse = isPast(deadline) ? null : deadline;
  }

  preapprove() {
    this.selectedReservationService.preapprove(this.thread.uuid).subscribe();
  }

  decline() {
    this.selectedReservationService.rejectPreapproval(this.thread.uuid).subscribe();
  }

  openVettingDialog() {
    this.dialog.open(DialogGuestVettingExtendedDetailsComponent, {
      width: '700px',
      height: '80%',
      autoFocus: false,
      data: {
        reservationUuid: this.activeReservation.uuid,
        source: this.activeReservation?.autohost?.source,
      },
    });
  }
}
