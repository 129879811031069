import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThreadService } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-star-conversation',
  template: `
    <button
      [ngClass]="{ 'text-lemon-darker': starred, 'text-night-lighter': !starred }"
      [matTooltip]="starred ? 'Unstar this conversation' : 'Star this conversation'"
      (click)="starred ? markAsUnstarred() : markAsStarred()">
      <sbnb-icon [size]="24" icon="star"></sbnb-icon>
    </button>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarConversationComponent implements OnInit {
  @Input() starred = false;
  @Input() threadUuid: string;
  @Output() starredToggled = new EventEmitter<boolean>();

  constructor(private readonly threadService: ThreadService) { }

  ngOnInit(): void { }

  markAsStarred() {
    this.threadService.markAsStarred(this.threadUuid).subscribe((res) => {
      if (res.success) {
        this.starredToggled.emit(true);
      }
    });
  }

  public markAsUnstarred() {
    this.threadService.markAsUnstarred(this.threadUuid).subscribe((res) => {
      if (res.success) {
        this.starredToggled.emit(false);
      }
    });
  }
}
