import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-icon-email',
  templateUrl: './icon-email.component.html',
  styleUrls: ['./icon-email.component.scss'],
})
export class IconEmailComponent implements OnInit {
  @Input() color = '#ED3C6A';

  constructor() { }

  ngOnInit() { }
}
