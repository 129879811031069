<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 13 14"
  fill="none"
  style="display: block"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M6.37122 10.4241C5.60007 9.45639 5.14645 8.60813 4.99525 7.88234C4.84404 7.29264 4.90452 6.8239 5.16157 6.47613C5.43374 6.06788 5.842 5.87131 6.37122 5.87131C6.90044 5.87131 7.30869 6.06788 7.58086 6.47613C7.83791 6.8239 7.89839 7.29264 7.74719 7.88234C7.58086 8.62325 7.12725 9.47 6.37122 10.4241ZM11.8479 11.0743C11.742 11.785 11.2582 12.3747 10.5929 12.6619C9.30763 13.2214 8.03599 12.3293 6.94731 11.1197C8.74817 8.86518 9.08082 7.1112 8.30816 5.97564C7.85455 5.32546 7.20436 5.00793 6.37122 5.00793C4.69284 5.00793 3.76897 6.42926 4.13186 8.07891C4.34355 8.97102 4.90301 9.98561 5.79513 11.1197C5.23567 11.7396 4.70645 12.1781 4.23771 12.4503C3.87482 12.6468 3.52704 12.7678 3.19439 12.798C1.66722 13.0248 0.47118 11.543 1.01552 10.0143C1.09112 9.81777 1.24233 9.45488 1.49938 8.89542L1.5145 8.86518C2.34613 7.0492 3.36072 4.99281 4.525 2.70809L4.55524 2.63249L4.88789 1.99742C5.14494 1.52869 5.25079 1.317 5.65904 1.05995C5.85561 0.938986 6.09754 0.878504 6.36971 0.878504C6.91405 0.878504 7.33742 1.19604 7.51887 1.45308C7.60959 1.58917 7.71544 1.77062 7.85152 1.99742L8.16905 2.61737L8.21441 2.70809C9.3787 4.99281 10.3933 7.0492 11.2249 8.86518L11.24 8.8803L11.5424 9.57584L11.7239 10.0143C11.863 10.3636 11.8932 10.7114 11.8479 11.0743ZM12.5434 9.71193C12.4376 9.37928 12.2561 8.98614 12.0293 8.51741V8.50229C10.9558 6.21757 9.9563 4.16118 9.00371 2.31496L8.94322 2.22423C8.2628 0.833142 7.77894 0 6.37122 0C4.98013 0 4.38891 0.967715 3.78409 2.22423L3.73873 2.31496C2.78614 4.16118 1.78667 6.21757 0.713108 8.50229V8.53253L0.395577 9.22807C0.274612 9.51536 0.21413 9.66657 0.19901 9.71193C-0.572138 11.8303 1.01552 13.6901 2.93734 13.6901C2.95246 13.6901 3.01294 13.6901 3.08855 13.675H3.30023C4.29819 13.5541 5.3279 12.919 6.37122 11.7834C7.41454 12.9175 8.44425 13.5541 9.4422 13.675H9.65389C9.72949 13.6901 9.78998 13.6901 9.8051 13.6901C11.7269 13.6917 13.3146 11.8303 12.5434 9.71193Z"
    [attr.fill]="color" />
</svg>
