import { Pipe, PipeTransform } from '@angular/core';
import { toPlatformLabel } from '@app/shared/utils/lib/general.util';

@Pipe({
  name: 'platformLabel',
})
export class PlatformLabelPipe implements PipeTransform {
  transform(value: string): string {
    return toPlatformLabel(value);
  }
}
