import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-profile-img',
  template: `
    <div ngClass.lt-md="mobile" class="container">
      <img class="img" [alt]="alt" [src]="src" onerror="this.src='/assets/images/profile-default.png'" />
      <div *ngIf="showSystemBadge" class="system-badge"></div>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      :host {
        min-width: fit-content;
      }

      .container {
        position: relative;
        width: fit-content;
      }

      .img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .system-badge {
        width: 22px;
        height: 22px;
        background: url('/assets/iconography/smartbnb-badge.svg') no-repeat;
        position: absolute;
        top: 24px;
        right: -5px;
      }

      @media #{$mobile} {
        .system-badge {
          display: none;
        }

        .img {
          width: 20px;
          height: 20px;
        }
      }
    `,
  ],
})
export class ProfileImgComponent {
  /**
   * URL of the profile image
   * Falls back to default profile image if URL is invalid
   */
  @Input() src: string;

  /**
   * Alt text for the profile image
   * @default 'Profile image'
   */
  @Input() alt = 'Profile image';

  /**
   * Whether to show the system badge overlay
   * @default false
   */
  @Input() showSystemBadge: boolean;

  constructor() {}
}
