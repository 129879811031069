import { EntitlementEnum, Money, SubscriptionData } from '@app/shared/interfaces';

export enum SubscriptionFeature {
  ChatGptGenerateMessage = 'CHATGPT_GENERATE_MESSAGE',
}

export type Subscription = SubscriptionData;

export interface Plan {
  plan: PlanLabel;
  plan_id: PlanId;
  current_plan: boolean;
  can_change: boolean;
  problems: [];
  base_price: Money;
  additional_properties: Money;
  estimated_price: Money;
  prorated_price: Money;
  included_properties: number;
}

export enum SubscriptionErrorReasons {
  ActiveListingCountHasChanged = 'active_listing_count_has_changed',
  PaymentMethodCouldNotBeCreated = 'payment_method_could_not_be_created',
  SubscriptionCannotBeCreated = 'subscription_cannot_be_created',
  SubscriptionCannotBeResubscribedTo = 'subscription_cannot_be_resubscribed_to',
}

export enum PlanId {
  Host = 'host',
  Professional = 'professional',
  Legacy = 'legacy',
  Mogul = 'mogul',
}

type PlanLabel = 'Host' | 'Professional' | 'Legacy';

export interface StashedPaymentMethod {
  type: 'card' | 'paypal';
  token: string;
}

export interface DowngradeChecks {
  can_downgrade: boolean;
  entitlements: DowngradeEntitlement[];
}

export interface DowngradeEntitlement {
  entitlement: EntitlementEnum;
  needs_action: boolean;
  limit: number;
  current: number;
  disable: number;
  data?: { id: number; image_url: string; listing_count: number; name: string }[];
}

export interface DeviceAddonResponse {
  activated: boolean;
  activated_at?: string;
}

export const isExpiredSubscription = (subscription: Pick<SubscriptionData, 'status'>) =>
  Boolean(subscription?.status === 'none' || subscription?.status === 'cancelled' || subscription?.status === 'ended');

export const isTrialSubscription = (subscription: Pick<SubscriptionData, 'status'>) =>
  Boolean(subscription?.status === 'trialing');

export const isNotSubscribed = (subscription: Pick<SubscriptionData, 'status'>) =>
  isExpiredSubscription(subscription) || isTrialSubscription(subscription);

export const isPendingSubscription = (subscription: Pick<SubscriptionData, 'status'>) =>
  Boolean(subscription?.status === 'pending');

export const canManageAddons = (subscription: Pick<SubscriptionData, 'status'>) =>
  Boolean(subscription?.status === 'active' || subscription?.status === 'grace' || subscription?.status === 'pending');

export const HOST_PLAN_FEATURES = [
  'Properties: 1 included | 2 max',
  'Max 2 secondary users',
  'Unified Inbox',
  'Unlimited AI responses',
  'Operations Management',
  'App marketplace',
];

export const PRO_PLAN_FEATURES = [
  'Properties: 2 included | No max limit',
  'Unlimited Secondary users',
  'Parent-child Property Configuration',
  'Financial + Performance Metrics',
  'Managed Smart Locks + Devices',
  'Direct Bookings + Hosted Site',
];

const LEGACY_PLAN_FEATURES = [
  'Properties: 2 included | No max limit',
  'Unlimited Secondary users',
  'Parent-child Property Configuration',
  'Financial + Performance Metrics',
  'Smart devices are a paid add-on',
  'Direct is a paid add-on',
];

const MOGUL_PLAN_FEATURES = [
  'Automated Owner Statements',
  'Accounting Integrations',
  'AI Incident Detection',
  'AI after-hours responder',
  'Early access to all of our AI features',
];

const MOGUL_COMING_SOON = [
  'Branded emails, Direct Sites and Statements',
  'Owner Portals',
  'Owner payouts and payments',
  'Trust accounting',
];

export const HOST_LOST_BENEFITS = [
  'No more than 2 Properties',
  'Cannot share acccess with more than 2 Users',
  'Cannot accept Direct Bookings or use our hosted sites and widgets',
  'Cannot connect Smart Locks or IoT devices to automate access and schedules',
  'No Parent-Child Property Setups are allowed',
  'No access to cross-platform Metrics + Financials',
];

export const PRO_ALWAYS_INCLUDED = [
  'Dynamic automated messaging',
  'Unified Inbox',
  'Operations Management',
  'Unlimited AI responses',
  'App marketplace',
];

export const PLANS_MAP = {
  host: {
    name: 'Host',
    features: HOST_PLAN_FEATURES,
    lostBenefits: HOST_LOST_BENEFITS,
  },

  professional: {
    name: 'Professional',
    features: PRO_PLAN_FEATURES,
    alwaysIncluded: PRO_ALWAYS_INCLUDED,
  },
  legacy: {
    name: 'Legacy',
    features: LEGACY_PLAN_FEATURES,
  },
  mogul: {
    name: 'Mogul',
    features: MOGUL_PLAN_FEATURES,
    comingSoon: MOGUL_COMING_SOON,
  },
  unknown: {
    name: 'Unknown',
  },
};

export const MANAGED_SMART_DEVICES_FEATURES = [
  'Automate the creation and communication of your access codes',
  'Access to the Devices Dashboard to manage all your devices, lock codes, and schedules in one place',
  'Notifications when a code has been used, low battery status, device issues, and more',
  'Ensure seamless access with automated backup codes',
];

export const PRIORITY_SUPPORT_FEATURES = [
  'Jump the queue: all your conversations are marked as priority and answered first',
  'Zoom Support: access to invitation-only 1:1 Zoom meetings with our product experts',
  'WhatsApp Support: help even when you are on the go, and leave us a voice message',
];
