import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { ActionError, MessageReview, ThreadService } from '@app/shared/services/thread/thread.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'sbnb-received-review-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './received-review-form.component.html',
  styleUrls: ['./received-review-form.component.scss'],
})
export class ReceivedReviewFormComponent implements OnChanges {
  @Input() message: MessageReview;
  @Input() showGuestPicture = true;
  @Input() showReviewContent = false;

  @Output() replySent = new EventEmitter<MessageReview['id']>();

  public reply = new UntypedFormControl('', [Validators.required]);
  public readonly isMobile$ = this.deviceDetectionService.isMobileViewport();

  public submitting$ = new BehaviorSubject<boolean>(false);
  // local state so that we can update the UI optimistically
  public state$ = new BehaviorSubject<MessageReview>(null);

  constructor(
    private readonly deviceDetectionService: DeviceDetectionService,
    private readonly threadApiService: ThreadService,
    private readonly segmentIoService: SegmentIoService
  ) { }

  // Lifecycle
  public ngOnChanges(changes: SimpleChanges): void {
    this.state$.next(changes.message.currentValue);
    const newVal = changes.message.currentValue;
    if (newVal) {
      this.submitting$.next(false);
      this.reply.setValue(newVal.sent_response ?? this.message.ai_proposed_response);
      this.reply.setErrors(null);
      if (newVal.sent_response) {
        this.reply.disable();
      }
    }
  }

  // public
  public submitReviewReply(): void {
    if (!this.message.respond_url) {
      throw new Error('No respond URL for review reply');
    }

    if (this.reply.invalid) {
      throw new Error('Review reply is invalid');
    }

    this.submitting$.next(true);
    this.reply.disable();

    if (this.state$.value.ai_proposed_response === this.reply.value) {
      this.segmentIoService.track(SegmentEvent.AIEngaged, {
        engagement_type: 'review reply sent as is',
      });
    }
    this.segmentIoService.track(SegmentEvent.AIReviewReplySent, {
      ai_proposed_response: this.state$.value.ai_proposed_response,
      user_reply: this.reply.value,
    });

    this.threadApiService
      .submitReviewReply(this.message.respond_url, {
        response: this.reply.value,
        generated_reply_id: this.message.generated_reply_id,
      })
      .subscribe((response) => {
        this.submitting$.next(false);

        if ((response as ActionError)?.error) {
          this.reply.setErrors({ submission: true });
          return;
        }

        this.replySent.emit(this.message.id);

        // Optimistically update the state while we
        // wait for pusher notification to reload the thread
        // messages in the thread component
        this.state$.next({
          ...this.message,
          sent_response: this.reply.value,
          can_respond: false,
        });
      });
  }

  public clearReply() {
    this.reply.setValue('');
  }

  public useAIReply() {
    this.reply.setValue(this.message.ai_proposed_response);
  }
}
