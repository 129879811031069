import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NotificationType } from '@app/shared/interfaces';
import { Review, RuleService } from '@app/shared/services/rule/rule.service';
import { ScheduledMessagesCancellerService } from '@app/shared/services/scheduled-messages-canceller/scheduled-messages-canceller.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-edit-review',
  templateUrl: './dialog-edit-review.component.html',
  styleUrls: ['./dialog-edit-review.component.scss'],
})
export class DialogEditReviewComponent implements OnInit {
  reviewId: string;
  public reviewDetails: Review;
  public updatedPreparedMessage: string;
  public saving: boolean;
  public deleting: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogEditReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ruleService: RuleService,
    private readonly cancellerService: ScheduledMessagesCancellerService,
    private readonly toast: ToastNotificationsService
  ) { }

  ngOnInit() {
    this.reviewId = this.data.reviewId;

    this.ruleService.getSingleReview(this.reviewId).subscribe((res) => {
      if (res.error) {
        this.toast.open('Unable to open review', 'Dismiss', NotificationType.Error);
        this.dialogRef.close();
        return;
      }

      this.reviewDetails = res;
      this.updatedPreparedMessage = this.reviewDetails.prepared_message;
    });
  }

  deleteMessage() {
    this.deleting = true;
    this.ruleService.deleteSingleReview(this.reviewId).subscribe((res) => {
      this.cancellerService.checkAndOpenScheduledMessageCanceller(
        this.reviewDetails.reservation_code,
        'review_deleted',
        this.reviewDetails.guest.name
      );

      this.deleting = false;
      // If successful, close the dialog
      if (res.data) {
        this.dialogRef.close({ cancelled_at: true });
      }
    });
  }

  saveUpdatedMessage(sendNow = false) {
    this.saving = true;

    this.ruleService
      .sendReview({
        reviewId: this.reviewId,
        message: this.updatedPreparedMessage,
        private_feedback: this.reviewDetails.private_feedback,
        grades: this.reviewDetails.review.grades,
        send_now: sendNow,
        bad_review: this.reviewDetails.review.bad_review,
      })
      .subscribe((res) => {
        this.saving = false;

        if (res.errors) {
          return;
        }

        if (this.reviewDetails.review.bad_review) {
          this.cancellerService.checkAndOpenScheduledMessageCanceller(
            this.reviewDetails.reservation_code,
            'review_marked_bad',
            this.reviewDetails.guest.name
          );
        }

        if (sendNow) {
          this.dialogRef.close({ submitted: true });
        } else {
          this.dialogRef.close({ submitted: false });
        }
      });
  }
}
