<div *ngIf="saveStatus === 1" class="flex items-center justify-center gap-2">
  <span class="lemon">Saving...</span>
</div>
<div *ngIf="saveStatus === -1" class="flex items-center justify-center gap-2">
  <img src="/assets/iconography/delete.svg" alt="Save Error" />
  <span class="rose">Save error</span>
</div>
<div *ngIf="saveStatus === 2" class="flex items-center justify-center gap-2">
  <sbnb-icon icon="check" class="grass-darker"></sbnb-icon>
  <span class="grass-darker">Saved</span>
</div>
