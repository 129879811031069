import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { ScriptLoaderService } from '@app/shared/services/script-loader/script-loader.service';
import { environment } from '@env/environment';

export interface GooglePlaceResult {
  name: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  formattedAddress: string;
}

@Directive({
  selector: '[sbnbGooglePlaces]',
})
export class GooglePlacesDirective implements OnInit {
  @Output() placeChanged = new EventEmitter<GooglePlaceResult>();

  private autocomplete: google.maps.places.Autocomplete;

  constructor(
    private elementRef: ElementRef,
    private scriptLoader: ScriptLoaderService
  ) {}

  ngOnInit() {
    this.scriptLoader
      .load({
        name: 'Google Places',
        src: `https://maps.googleapis.com/maps/api/js?key=${environment.google.places.apiKey}&libraries=places`,
      })
      .subscribe(() => {
        this.initializeGooglePlaces();
      });
  }

  private initializeGooglePlaces() {
    this.autocomplete = new google.maps.places.Autocomplete(this.elementRef.nativeElement, {
      types: ['address'],
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      const result: GooglePlaceResult = {
        name: '',
        streetNumber: '',
        streetName: '',
        city: '',
        state: '',
        country: '',
        countryCode: '',
        postalCode: '',
        latitude: place.geometry?.location?.lat() || 0,
        longitude: place.geometry?.location?.lng() || 0,
        formattedAddress: place.formatted_address || '',
      };

      for (const component of place.address_components || []) {
        const type = component.types[0];
        switch (type) {
          case 'street_number':
            result.streetNumber = component.long_name;
            break;
          case 'route':
            result.streetName = component.long_name;
            break;
          case 'locality':
          case 'postal_town':
            result.city = component.long_name;
            break;
          case 'administrative_area_level_1':
            result.state = component.long_name;
            break;
          case 'country':
            result.country = component.long_name;
            result.countryCode = component.short_name;
            break;
          case 'postal_code':
            result.postalCode = component.long_name;
            break;
        }
      }

      result.name = result.streetNumber ? `${result.streetNumber} ${result.streetName}` : result.streetName;

      this.placeChanged.emit(result);
    });
  }
}
