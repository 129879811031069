import { DirectPlan } from '@app/modules/direct/models/direct-subscription.interface';
import { Entitlement } from '@app/shared/interfaces/lib/billing.interface';
import { SignupV2Value } from '@app/shared/interfaces/lib/posthog-flags.interface';

export interface User {
  id: string;
  autohost_enabled: boolean;
  beta_features_enabled: boolean;
  migrate_to_seam_schlage?: boolean;
  id_hash: string;
  first_name: string;
  last_name: string;
  email: string;
  picture?: string;
  company: string;
  name?: string;
  phone?: string;
  timezone?: string;
  intercom_hash?: string;
  commandbar_hash?: string;
  priority_support?: {
    priority_support: boolean;
    priority_support_plan: string | null;
    priority_support_activated_at: string | null;
    priority_support_activated_with_properties: number | null;
    priority_support_cancelled_at: string | null;
    priority_support_ends_at: string | null;
  };
  prefs?: [];
  isCS?: boolean;
  timezone_offset: number;
  team?: { id: number; owner_email: string; owner_first_name: string; owner_last_name: string; owner_picture: string };
  created_at?: string;
  createdAt?: string;
  uses_24_hour_format?: boolean;
  week_starts_on: string;
  signup: { airbnb: boolean; homeaway: boolean; booking: boolean };
  has_smartlocks?: boolean;
  has_seam_smartlocks?: boolean;
  has_thermostats?: boolean;
  entitlements: Entitlement[];
  token?: string;
  direct_subscription_plan?: DirectPlan;
}

export interface UserTrialConnectAccountCta {
  platformUi: string;
  connectAccountUrl: string;
}

export interface SignUpWithAirbnbUrl {
  url: string;
  state: string;
}

export interface SignUpWithAirbnbCallback {
  user: SignupUser;
  channel_user_id: string;
}

export interface SignupUser {
  id?: number; // Obfuscated user ID
  status: 'new' | 'returning' | 'invited' | 'existing' | 'login_restricted';
  current_step: number;
  email: string;
  token?: string; // Never provided as null.
  avatar?: string;
  first_name?: string;
  last_name?: string;
  mobile_phone?: string;
  country_code?: string;
  agreed_to_tos?: boolean;
  property_count?: number;
  platforms?: string[];
  onboarding_type?: 'blank' | 'jumpstart' | 'outsourced';
  support_preference?: SupportPreference;
  flow?: SignupV2Value;
}

export enum SupportPreference {
  SelfService = 'self_service',
  RealPerson = 'real_person',
}

export enum EntitlementEnum {
  Direct = 'direct',
  Properties = 'maximum-properties',
  Metrics = 'metrics',
  ParentChild = 'parent-child',
  SmartDevices = 'smart-devices',
  MaxSecondaryUsers = 'maximum-secondary-users',
  OwnerStatements = 'owner-statements',
  Accounting = 'accounting',
  MogulAI = 'mogul-ai',
}

export interface CsTime {
  timezone: string; // UTC
  start_time: number; // Start hour
  end_time: number; // End hour
}
