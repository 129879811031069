import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-timestamp',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    {{ timestamp }}
  `,
  styles: [
    `
      @import 'variables';
      :host {
        color: $night-lighter;
        font-size: 0.75rem;
      }
    `,
  ],
})
export class TimestampComponent implements OnInit {
  @Input() timestamp: string;
  constructor() { }

  ngOnInit(): void { }
}
