import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { fadeInUpAnimation } from 'src/animations';

@Component({
  standalone: false,
  selector: 'sbnb-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  animations: [fadeInUpAnimation],
})
export class ImageCarouselComponent implements OnChanges, OnDestroy {
  @Input() images: string[];
  @Input() autoCycle = false;
  CYCLE_INTERVAL = 4000;

  activeImageIndex = 0;
  autoCycleInterval: any;

  ngOnChanges() {
    if (this.autoCycle) {
      this.startAutoCycle();
    }
  }

  ngOnDestroy() {
    this.stopAutoCycle();
  }

  startAutoCycle() {
    this.autoCycleInterval = setInterval(() => {
      this.goToNextImage();
    }, this.CYCLE_INTERVAL);
  }

  stopAutoCycle() {
    clearInterval(this.autoCycleInterval);
  }

  goToPreviousImage() {
    this.activeImageIndex--;

    if (this.activeImageIndex < 0) {
      this.activeImageIndex = this.images.length - 1;
    }
  }

  goToNextImage() {
    this.activeImageIndex++;

    if (this.activeImageIndex >= this.images.length) {
      this.activeImageIndex = 0;
    }
  }

  goToImage(index: number) {
    this.activeImageIndex = index;
  }
}
