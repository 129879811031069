import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { GeneratedReply } from '@app/shared/+state/AI/AI.models';
import { GuestVettingData } from '@app/shared/interfaces/lib/guestvetting.interface';
import { Channel } from '@app/shared/models/channel';
import { Filter } from '@app/shared/models/filter';
import { Language } from '@app/shared/models/language';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AlgoliaService } from '../algolia.service';
import { FilterService } from '../filter/filter.service';
import { SegmentEvent, SegmentIoService } from '../segmentIo/segment-io.service';

interface LanguageChangeOptions {
  can_change_language: boolean;
  current_language: Language;
  available_languages: Language[];
}
export interface ActionError {
  error: boolean;
  message?: string;
}

export interface ThreadPaginationMeta {
  pagination: {
    total: number;
    this_page: number; // number of results on this page
    per_page: number;
    offset: number;
    page_numbers: {
      total_pages: number;
      this_page: number;
    };
    has_more?: boolean;
    _links: {
      first: string;
      last: string;
      next: string;
      previous: string;
      prev?: string | null /* token-based,  can we have nextPageToken & previousPageToken or similar in this version?*/;
    };
  };
}

export interface ThreadResponse {
  data: ThreadPayload[];
  meta: ThreadPaginationMeta;
}

export interface ThreadPayload {
  uuid: string;
  thread_id: string;
  last_message: string;
  last_message_received_at: string;
  last_message_at: Date;
  last_message_at_label: string;
  advanced_status: string;
  label: string;
  show_sentiment_alert: boolean;
  checkin: Date;
  checkout: Date;
  status_key: string;
  status: string;
  reservation_code: string;
  actionable_reservation_uuid: null;
  total: number;
  currency: string;
  source: string;
  returning_guest: boolean;
  guest: Guest;
  guests: GuestShortInfo[];
  chances_of_booking: string;
  adults: number;
  children: number;
  nights: number;
  unread: boolean;
  hosts: Host[];
  listing: Listing;
  note: string;
  resolved: boolean;
  starred: boolean;
  using_translations: boolean;
  archived: boolean;
  distance: string;
  language: Language;
  host: Host;
  platform: Channel;
  supports: Supports;
  created_at: string;
  updated_at: string;
  _links: Links;
}

export interface CompactThreadPayload {
  uuid: string;
  thread_id: string;
  read: boolean;
  last_message: string;
  last_message_at: Date;
  last_message_at_label: string;
  advanced_status: string;
  actionable_reservation_uuid: string;
  has_alteration_request: boolean;
  resolved: boolean;
  starred: boolean;
  booking: {
    status: {
      code: string;
      description_key: string;
      description: string;
    };
    payment_status: string;
    is_custom_quote: boolean;
  };
  checkin: Date | null;
  checkout: Date | null;
  guest: {
    image: string;
    name: string;
    platform: string;
  };
  listing: {
    image: string;
    name: string;
    tags: string[];
    property_name: string;
  };
  _links: {
    'mark-as-read': string;
    resolve: string;
    star: string;
    chat: string;
    messages: string;
    activity: string;
    events: string;
    reservations: string;
  };
  supports: {
    email_change: boolean;
    pre_approvals: boolean;
  };
}

/**
 * This essentially has the same structure as `ReservationFinancialItem`. It's copied over to avoid
 * superficial abstraction/reuse.
 * @see ReservationFinancialItem
 */
export interface ThreadFinancialItem {
  type: 'revenue' | 'accomodation' | 'mixed' | 'host_service_fee' | 'host_tax' | 'guest_total_price';
  title: string;
  info?: string;
  amount: string;
  amount_formatted: string;
}

export interface ThreadFinancialsPayload {
  host: Array<ThreadFinancialItem>;
  guest: Array<ThreadFinancialItem>;
}

interface Links {
  'mark-as-read': string;
  chat: string;
  messages: string;
  activity: string;
  events: string;
  reservations: string;
}

interface Guest {
  uuid: string;
  account_created_at: null;
  guest_id: string;
  picture: string;
  first_name: string;
  last_name: string;
  email: string;
  email_anonymous: string;
  phone: string;
  timezone: string;
  about: string;
  verified: boolean;
  locale: string;
  city: string;
  state: string;
  country: string;
  ui_location: string;
  address: string;
  trip_count: number;
  ratings: Ratings;
  reviews: any[];
  profile_url: string;
  platform: string;
  platform_human: string;
  custom_first_name?: string | null;
  custom_last_name?: string | null;
  recommendations?: {
    amount: number;
    unanimously_recommended: boolean;
  };
}

interface GuestShortInfo {
  guest_id: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  picture: string | null;
  is_additional_guest: boolean;
}

interface Ratings {
  overall: Rating;
  communications: Rating;
  respects_house_rules: Rating;
  cleanliness: Rating;
}

interface Rating {
  count: number;
  average: number;
}

interface Host {
  name: string;
  image: null;
  user_id: string;
}

interface Listing {
  page_url: string;
  tags: any[];
}

interface Supports {
  email_change: boolean;
  pre_approvals: boolean;
  bdc_rtb_messaging?: boolean | null;
}

export interface Reaction {
  emoji: string;
}

export type ThreadMessage = MessageChat | MessageEvent | MessageReservation | MessageAlteration | MessageReview;

interface Message {
  entity_type: 'reservation' | 'alteration' | 'event' | 'chat' | 'review' | 'send-review';
  created_at_label: string;
  id: string | number;
}

export interface MessageSendReview extends Message {
  bad_review: boolean;
  private_feedback?: string;
  rating: number;
  review?: string;
  review_id: string;
  scheduled_for?: string;
  scheduled_for_label?: string;
  submitted: boolean;
  scores: {
    cleanliness: number;
    communication: number;
    rating: number;
    recommend: boolean;
    respect_house_rules: number;
  };
  guest: {
    name: string;
  };
}

export interface MessageReview extends Message {
  id: number;
  platform: Channel;
  public_review: string;
  private_review?: string;
  translated_public_review?: string;
  translated_private_review?: string;
  ai_proposed_response: string;
  generated_reply_id: string;
  sent_response: string;
  can_respond: boolean;
  created_at: string;
  sorted_by: string;
  expires_at: string;
  expires_at_label: string;
  created_at_label: string;
  respond_url: string;
  rating: number;
  from: {
    name: string;
    locale: string;
    picture_url: string;
    thumbnail_url: string;
  };
  property?: {
    name: string;
    image_url: string;
  };
}

export interface MessageChat extends Message {
  is_guest: boolean;
  is_additional_guest: boolean;
  is_automated: boolean;
  pending: boolean;
  failed: boolean;
  created: string;
  created_label: string;
  author?: string;
  ai?: {
    auto_reply: boolean;
  },
  message: string;
  from: {
    first_name?: string;
    last_name?: string;
    name?: string;
    thumbnail_url: string;
    picture_url: string;
    has_profile_image: boolean;
    locale: string;
  };
  automation: {
    type: string;
    randomkey: string;
    name: string;
    ruleset_id: number;
  };
  images?: string[];
  intents?: any[];
  attachments?: any[];
  reactions: Reaction[];
  sorted_by: string;
}

export interface MessageEvent extends Message {
  status: string;
}

export interface MessageReservation extends Message {
  actionable: boolean;
  payment_status: string;
  is_custom_quote: boolean;
  start_date: string;
  end_date: string;
  subtotal: string;
  revenue: string;
  currency_code: string;
  num_adults: number;
  num_children: number;
  num_infants: number;
  payment_provider?: string;
  timezone?: string;
  listing: {
    image: string;
    name: string;
  };
}

export interface MessageAlteration extends Message {
  from: string;
  price_difference: string;
  listing: {
    image: string;
    name: string;
    listing_type: string;
    beds: string | number;
    baths: string | number;
  };
  original_state: {
    checkInDate: string;
    checkOutDate: string;
    payoutFormatted: string;
  };
  proposed_state: {
    checkInDate: string;
    checkOutDate: string;
    payoutFormatted: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ThreadService {
  private resultsPerPage = 20;
  private threadsUrl = `${config.API_URL}/threads?compact=true&limit=${this.resultsPerPage}`;
  private threadUrl = `${config.API_URL}/threads/`;
  private reservationsUrl = `${config.API_URL}/reservations/`;
  private alterationsUrl = `${config.API_URL}/alterations/`;
  private cancelActivityUrl = `${config.API_URL}/scheduled/`;

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private readonly segmentIoService: SegmentIoService,
    private algoliaService: AlgoliaService,
    private auth: AuthenticationService
  ) {}

  getThreads(
    filterCriteria?: Filter[],
    offset?: number,
    search?: string,
    cursor?: string,
    filterMatchScopeOnRulesetId?: any,
    filterMatchScopeOnRulesetEvent?: any,
    filterMatchScopeOnQuestionStatus?: any
  ): Observable<ThreadResponse> {
    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    const url = this.threadsUrl;
    let params = new HttpParams();

    if (offset) {
      params = params.set('offset', offset.toString());
    }

    if (cursor) {
      params = params.set('cursor', cursor.toString());
    }

    if (filterMatchScopeOnRulesetId) {
      params = params.set('relevant_to_ruleset_id', filterMatchScopeOnRulesetId);
    }

    if (filterMatchScopeOnRulesetEvent) {
      params = params.set('relevant_to_ruleset_event', filterMatchScopeOnRulesetEvent);
    }

    if (filterMatchScopeOnQuestionStatus) {
      params = params.set('thread_stages', filterMatchScopeOnQuestionStatus);
    }

    const user = this.auth.getUserDetails();

    // If we have a search query, request secure key (if we don't have one already)
    const secureKey$ =
      search && !this.algoliaService.algoliaSecureApiKey
        ? this.algoliaService.requestSecureAlgoliaKey()
        : of(this.algoliaService.algoliaSecureApiKey);

    return secureKey$.pipe(
      switchMap((key) => {
        if (search) {
          params = params.set('query', search);

          // Add the Algolia secure search key
          if (key) {
            params = params.set(config.ALGOLIA_SEARCH_KEY_NAME, key);
          }
        }

        return this.http.post<ThreadResponse>(url, { filters: apiFilters }, { params }).pipe(
          map((res) => {
            return res;
          }),
          catchError(this.handleError)
        );
      })
    );
  }

  getNextThreads(nextUrl: string): Observable<ThreadResponse> {
    return this.http.post<ThreadResponse>(nextUrl, {}).pipe(
      map((res) => res),
      catchError(this.handleError)
    );
  }

  getMessages(threadUuid: string): Observable<ThreadMessage[]> {
    const url = `${this.threadUrl}${threadUuid}/messages`;

    return this.http.get<{ data: ThreadMessage[] }>(url).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getPrewrittenAIMessage(threadUuid: string): Observable<GeneratedReply | null> {
    const url = `${this.threadUrl}${threadUuid}/ai/prewritten`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getActivity(threadUuid: string): Observable<any> {
    const url = `${config.API_URL}/inbox/${threadUuid}/activity`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res.data;
      }),
      catchError((err) => err)
    );
  }

  sendMessage(
    threadUuid: string,
    message: string,
    userId?: string,
    generatedReplyUuid?: string,
    pictures?: any
  ): Observable<any> {
    const url = `${this.threadUrl}${threadUuid}/chat`;

    const formData = new FormData();

    if (pictures) {
      pictures.forEach((file, index) => {
        formData.append('pictures[]', file);
      });
    }

    if (message) {
      formData.append('message', message);
    }

    formData.append('user_id', userId);
    formData.append('generated_reply_id', generatedReplyUuid);

    return this.http.post<any>(url, formData).pipe(
      map((res) => {
        return res.data[0];
      }),
      catchError((err) => of({ error: true }))
    );
  }

  preapprove(threadUuid: string): Observable<ThreadPayload> {
    return this.http
      .put(`${this.threadUrl}${threadUuid}/pre-approve`, {})
      .pipe(map((res: { data: ThreadPayload }) => res.data));
  }

  decline(threadUuid: string): Observable<ThreadPayload> {
    return this.http
      .put(`${this.threadUrl}${threadUuid}/deny`, {})
      .pipe(map((res: { data: ThreadPayload }) => res.data));
  }

  markAsRead(threadUuid: string): Observable<any> {
    const url = `${this.threadUrl}${threadUuid}/read`;

    return this.http.put(url, {}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  markAsResolved(threadUuid: string): Observable<any> {
    const url = `${this.threadUrl}${threadUuid}/resolve`;

    this.segmentIoService.track(SegmentEvent.ResolvedThread, {});

    return this.http.post(url, {}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  markAsUnresolved(threadUuid: string): Observable<any> {
    const url = `${this.threadUrl}${threadUuid}/unresolve`;

    this.segmentIoService.track(SegmentEvent.UnresolvedThread, {});

    return this.http.post(url, {}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  markAsStarred(threadUuid: string): Observable<any> {
    const url = `${this.threadUrl}${threadUuid}/star`;

    this.segmentIoService.track(SegmentEvent.StarredThread, {});

    return this.http.post(url, {}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  markAsUnstarred(threadUuid: string): Observable<any> {
    const url = `${this.threadUrl}${threadUuid}/unstar`;

    this.segmentIoService.track(SegmentEvent.UnstarredThread, {});

    return this.http.post(url, {}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  toggleTranslations(threadUuid: string, toggleTranslations: boolean): Observable<any> {
    const url = `${this.threadUrl}${threadUuid}/toggle_translations`;

    if (toggleTranslations) {
      this.segmentIoService.track(SegmentEvent.TurnOffThreadTranslations, {});
    } else {
      this.segmentIoService.track(SegmentEvent.TurnOnThreadTranslations, {});
    }

    return this.http
      .post(url, {
        show_message_translations: toggleTranslations,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  acceptBooking(threadUuid: string): Observable<any> {
    const url = `${this.reservationsUrl}${threadUuid}/accept`;

    return this.http.put(url, {}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  rejectBooking(threadUuid: string): Observable<any> {
    const url = `${this.reservationsUrl}${threadUuid}/reject`;

    return this.http.put(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleActionError)
    );
  }

  acceptAlteration(alterationUuid: string): Observable<any> {
    const url = `${this.alterationsUrl}${alterationUuid}/accept`;

    return this.http.put(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleActionError)
    );
  }

  declineAlteration(alterationUuid: string): Observable<any> {
    const url = `${this.alterationsUrl}${alterationUuid}/decline`;

    return this.http.put(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleActionError)
    );
  }

  cancelAlteration(alterationUuid: string): Observable<any> {
    const url = `${this.alterationsUrl}${alterationUuid}/cancel`;

    return this.http.put(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleActionError)
    );
  }

  cancelActivityItem(key: string): Observable<any> {
    const url = `${this.cancelActivityUrl}${key}`;

    return this.http.delete(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateGuestName(threadUuid: string, firstname: string, lastname: string): Observable<any> {
    return this.http.put(`${this.threadUrl}${threadUuid}/name`, {
      first_name: firstname,
      last_name: lastname,
    });
  }

  updateGuestEmail(threadUuid: string, email: string): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(`${this.threadUrl}${threadUuid}/email`, {
      email,
    });
  }

  stubGetThreads(): Observable<any> {
    const items = [];
    for (let i = 0; i < 10; i++) {
      items.push({
        id: i,
        read: Math.random() >= 0.5,
        lastmessage:
          'The monkey-rope is found in all whalers; but it was The monkey-rope is found in all whalers; but it was The monkey-rope is found in all whalers; but it was',
        received: this.randomDate(new Date('2018-01-01'), new Date(), '1', '23'),
        booking: {
          status: {
            code: Math.floor(Math.random() * 2) + 1,
            desc: 'Awaiting Approval',
          },
        },
        guest: {
          img: 'https://a0.muscache.com/im/users/4444575/profile_pic/1430520520/original.jpg?aki_policy=profile_x_medium',
          name: 'Pierre-Camille Hamana',
          platform: Math.random() >= 0.5 ? 'airbnb' : 'homeaway',
        },
        listing: {
          img: 'https://a0.muscache.com/im/pictures/106281560/857e46f9_original.jpg?aki_policy=large',
          name: '1026 North Paulina Street 1F',
          tag: Math.random() >= 0.5 ? 'My Awesome Tag' : null,
        },
      });
    }
    items.sort((a, b) => b.received - a.received);

    return of(items);
  }

  getThread(threadId: string): Observable<ThreadPayload> {
    const url = `${this.threadUrl}${threadId}`;
    return this.http.get<{ data: ThreadPayload }>(url).pipe(
      map((res: { data: ThreadPayload }) => {
        return res.data;
      }),
      catchError(this.handleGetThreadError)
    );
  }

  getThreadFinancials(threadId: string): Observable<ThreadFinancialsPayload> {
    const url = `${this.threadUrl}${threadId}/financials`;
    return this.http.get<{ data: ThreadFinancialsPayload }>(url).pipe(map((res) => res.data));
  }

  prepareCannedResponse(threadUuid, cannedResponseId, reservationCode?) {
    let url = `${config.API_URL}/inbox/canned/prepare/${cannedResponseId}?thread_uuid=${threadUuid}`;

    if (reservationCode) {
      url = `${url}&reservation_code=${reservationCode}`;
    }

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data.message;
      }),
      catchError((err: HttpErrorResponse) => of(err))
    );
  }

  getCanned(paginate = false, threadUuid?: string, orderBy = 'name', orderDirection = 'asc') {
    let url = `${config.API_URL}/inbox/canned?pagination=${paginate}&order_by=${orderBy}&order_direction=${orderDirection}`;

    if (threadUuid) {
      url += `&limit_to_thread=${threadUuid}`;
    }

    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  saveNote(uuid: string, note: string) {
    const url = `${config.API_URL}/threads/${uuid}/note`;

    return this.http.put(url, { note }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  canThreadChangeLanguage(threadId: string) {
    return this.http.get<LanguageChangeOptions>(`${config.API_URL}/threads/${threadId}/languages`);
  }

  changeThreadLanguage(threadId: string, newLangCode: string): Observable<any> {
    const payload = {
      current_language: {
        code: newLangCode,
      },
    };

    return this.http.put<LanguageChangeOptions>(`${config.API_URL}/threads/${threadId}/languages`, payload);
  }

  getGuestVettingData(reservationUuid: string): Observable<GuestVettingData> {
    return this.http.get<GuestVettingData>(`${environment.apiUrl}/reservations/${reservationUuid}/guest-vetting`);
  }

  public submitReviewReply(respondUrl: string, payload: { response: string; generated_reply_id: string }) {
    return this.http.post(respondUrl, payload).pipe(catchError(this.handleActionError));
  }

  private randomDate(start, end, startHour, endHour) {
    const date = new Date(+start + Math.random() * (end - start));
    const hour = (startHour + Math.random() * (endHour - startHour)) | 0;
    date.setHours(hour);
    return date;
  }

  private handleError(err: HttpErrorResponse | any) {
    return of({
      data: [],
      meta: {
        pagination: {
          per_page: 20,
          has_more: false,
          _links: {
            next: null,
            prev: null,
          },
        },
      },
    } as ThreadResponse);
  }

  private handleActionError(err: HttpErrorResponse | any): Observable<ActionError> {
    const response = { error: true, message: null };

    if (err.error?.message) {
      response.message = err.error.message;
    }

    return of(response);
  }

  private handleGetThreadError(err: HttpErrorResponse | any) {
    return of(err);
  }

  public dismissSentimentAlert(threadUuid: string): Observable<ThreadPayload> {
    return this.http
      .post<{ data: ThreadPayload }>(`${this.threadUrl}${threadUuid}/dismiss-sentiment-alert`, {})
      .pipe(map((res) => res.data));
  }

  public cancelScheduledReviews(threadUuid: string): Observable<ThreadPayload> {
    return this.http
      .post<{ data: ThreadPayload }>(`${this.threadUrl}${threadUuid}/cancel-scheduled-review-requests`, {})
      .pipe(map((res) => res.data));
  }
}
