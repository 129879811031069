import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { ReservationService } from '@app/shared/services/reservation/reservation.service';

enum GroupIds {
  BookingDetails = 'booking-details',
  ListingDetails = 'listing-details',
  PaymentDetails = 'payment-details',
  ReservationDetails = 'reservation-details',
  Debug = 'debug',
}

enum ListingDetailIds {
  Name = 'listing-name',
  Address = 'listing-address',
  Description = 'description',
  PropertyType = 'listing-property-type',
  Bedrooms = 'listing-bedrooms',
  Beds = 'listing-beds',
  Bathrooms = 'listing-bathrooms',
}

@Component({
  standalone: false,
  selector: 'sbnb-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent implements OnInit {
  @Input() extendedApiUrl: string;
  @Output() failedToLoad: EventEmitter<boolean> = new EventEmitter();
  @Output() loaded: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren('sections') sections: QueryList<any>;
  @ViewChild('descriptionRef', { static: false }) descriptionRef: ElementRef;

  resData: any;
  loading: boolean;
  isCS: boolean;

  GroupIds = GroupIds;
  ListingDetailIds = ListingDetailIds;

  showMore = false;
  descriptionContentOverflows = false;

  constructor(
    private reservationService: ReservationService,
    private csService: InternalCsService
  ) { }

  ngOnInit() {
    this.isCS = this.csService.isCS();
    this.getReservationData();
  }

  getReservationData() {
    this.loading = true;
    this.reservationService.getReservationDetails(null, this.extendedApiUrl).subscribe(
      (res) => {
        this.resData = res.data;
        this.loading = false;
        this.loaded.emit(true);
      },
      (err) => {
        this.failedToLoad.emit(true);
      }
    );
  }

  ngAfterViewInit() {
    this.sections.changes.subscribe((t) => {
      this.sectionsHaveRendered();
    });
  }

  sectionsHaveRendered() {
    let overflows = false;
    if (this.descriptionRef && this.descriptionRef.nativeElement) {
      overflows = this.descriptionRef.nativeElement.scrollHeight > this.descriptionRef.nativeElement.clientHeight;
    }

    if (this.descriptionContentOverflows !== overflows) {
      setTimeout(() => {
        this.descriptionContentOverflows = overflows;
      }, 0);
    }
  }
}
