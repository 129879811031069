import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-delete-generic',
  templateUrl: './dialog-delete-generic.component.html',
  styleUrls: ['./dialog-delete-generic.component.scss'],
})
export class DialogDeleteGenericComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteGenericComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      name: string;
      action: string;
      additionalText?: string;
      identifier?: string;
      list?: string[];
    }
  ) {}

  ngOnInit() {}

  okClicked() {
    this.dialogRef.close(true);
  }

  cancelClicked() {
    this.dialogRef.close(false);
  }

  public get identifier() {
    return this.dialogData.identifier ? ` (${this.dialogData.identifier})` : '';
  }
}
