import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit {
  /**
   * The label displayed on the pill
   */
  @Input() label: string;

  /**
   * The theme to use for this pill. We're going to keep this to a predefined set for now to prevent arbitrary values being passed in
   */
  @Input() theme: 'teal' | 'lemon' | 'rose';

  /**
   * If we want to manually constrain the width, enter a string value here (e.g. '70px', '2rem')
   * This can be useful if you need all instances of a pill to be the same width
   */
  @Input() fixedWidth: string;

  constructor() { }

  ngOnInit(): void { }
}
