import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { ApiResponse, Task } from '@app/shared/interfaces';
import { Filter } from '@app/shared/models/filter';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FilterService } from '../filter/filter.service';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private getTaskRulesUrl = `${config.API_URL}/operations/task-rules`;
  private createTaskRuleUrl = `${config.API_URL}/operations/task-rules/create`;
  private countsUrl = `${config.API_URL}/counts/operations`;
  private getUnassigedTasksUrl = `${config.API_URL}/operations/tasks?unassigned=1&timezones=false`;
  private updateTaskUrl = `${config.API_URL}/operations/tasks/`;
  private resultsPerPage = 20;

  constructor(
    private http: HttpClient,
    private filterService: FilterService
  ) {}

  getTasks(filterCriteria?: Filter[], offset?: number, search?: string, filterMatchScopeOnRulesetId?: any) {
    let url = `${this.getTaskRulesUrl}?limit=${this.resultsPerPage}`;

    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }
    return this.http.post(url, { filters: apiFilters }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getTasksForThread(threadUuid: string) {
    return this.http.get(`${this.updateTaskUrl}?thread_uuid=${threadUuid}&timezones=false`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getTasksByDate(
    startDate: Date,
    endDate: Date,
    propertyIDs?: any[],
    withTimezones = false,
    pagination = true
  ): Observable<any> {
    let dateUrlParam = `${format(startDate, 'YYYY-MM-DD')}_${format(endDate, 'YYYY-MM-DD')}`;
    let url = `${this.updateTaskUrl}?starts_or_ends_between=${dateUrlParam}&timezones=${withTimezones}`;

    if (!pagination) {
      url = `${url}&pagination=${pagination}`;
    }

    if (propertyIDs) {
      url = `${url}&property_ids=${propertyIDs}`;
    }

    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  getUpcomingTasks({ offset, cursor }: { offset?: number; cursor?: string }): Observable<ApiResponse<Task[]>> {
    let url = `${this.updateTaskUrl}?upcoming=true&limit=20&timezones=false`;

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (cursor) {
      url = `${url}&cursor=${cursor}`;
    }

    return this.http.get<ApiResponse<Task[]>>(url).pipe(
      map((response) => {
        response.data = response.data.map((task) => ({ ...task, computed: {} }));
        return response;
      })
    );
  }

  getTaskRule(uuid: string, withMeta: boolean = true) {
    const url = `${this.getTaskRulesUrl}/${uuid}${withMeta ? '?with_meta=true' : ''}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => of(err))
    );
  }

  updateTaskRule(uuid: string, payload: any): Observable<any> {
    const url = `${this.getTaskRulesUrl}/${uuid}`;

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  createTaskRule(taskType: number, time: number, relativeTo: string): Observable<any> {
    const url = this.createTaskRuleUrl;

    const payload = {
      task_type: taskType,
      time,
      relative_to: relativeTo,
    };

    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteTaskRule(uuid: string) {
    const url = `${this.getTaskRulesUrl}/${uuid}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  copyTaskRule(uuid: string) {
    const url = `${this.getTaskRulesUrl}/${uuid}/copy`;

    return this.http.put(url, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getCounts() {
    return this.http.get(this.countsUrl).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUnassigedTasks(offset: number = 0, limit = 20) {
    const url = `${this.getUnassigedTasksUrl}&offset=${offset}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateTask(uuid: string, payload: any) {
    const url = `${this.updateTaskUrl}${uuid}?timezones=false`;

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  deleteTask(uuid: string) {
    const url = `${this.updateTaskUrl}${uuid}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  createTask(payload: any) {
    const url = `${this.updateTaskUrl}create?timezones=false`;

    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  activateOrDeactivateTaskRule(taskUuid: string, activate: boolean): Observable<any> {
    const url = `${this.getTaskRulesUrl}/${taskUuid}/${activate ? 'activate' : 'deactivate'}`;

    return this.http.put(url, {}).pipe(
      map((res) => res),
      catchError(this.handleError)
    );
  }

  private handleError(err) {
    return of({
      error: true,
      message: err.error.message,
      errors: err.error.errors,
    });
  }
}
