import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import {
  ReservationAgreement,
  ReservationAgreementService,
  ReservationAgreementTemplate,
} from '@app/modules/guest-experience/services/reservation-agreements.service';
import { DialogAddNewRentalAgreementToReservationComponent } from '@app/shared/components/dialog-add-new-rental-agreement-to-reservation/dialog-add-new-rental-agreement-to-reservation.component';
import { RentalAgreementsDetailsDialogComponent } from '@app/shared/components/rental-agreements-details-dialog/rental-agreements-details-dialog.component';
import { Reservation, User } from '@app/shared/interfaces';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-rental-agreements-sidebar-list',
  templateUrl: './sbnb-rental-agreements-sidebar-list.component.html',
  styleUrls: ['./sbnb-rental-agreements-sidebar-list.component.scss'],
})
export class RentalAgreementsSidebarListComponent implements OnInit {
  isMobile = false;
  user: User = this.auth.getUserDetails();
  loading = true;

  agreements$: Observable<ReservationAgreement[]>;
  templates$: Observable<ReservationAgreementTemplate[]>;
  availableTemplates$: Observable<ReservationAgreementTemplate[]>;

  @Input() reservationUuid: string;

  // Temporarily need this to determine whether we show rental agreements at all - currently Direct Basic/Premium only for beta hosts
  @Input() reservation: Reservation;

  @Input() canAddAgreement = false;
  @Input() propertyId: number;

  @Input() updatedStyling = false;

  constructor(
    private dialog: MatDialog,
    private deviceDetectionService: DeviceDetectionService,
    private agreementService: ReservationAgreementService,
    private auth: AuthenticationService
  ) {
    this.deviceDetectionService.isMobileViewport().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit(): void {
    this.loading = true;

    this.agreements$ = this.agreementService
      .fetchRentalAgreementsForReservation(this.reservationUuid)
      .pipe(finalize(() => (this.loading = false)));

    this.templates$ = this.agreementService.fetchReservationAgreementTemplates();

    this.availableTemplates$ = combineLatest([this.templates$, this.agreements$]).pipe(
      map(([templates, agreements]) =>
        templates.filter(
          (template) =>
            // Filter out templates that have already been requested, and those not matching our property scope
            !agreements.some((agreement) => agreement.template_uuid === template.uuid) &&
            template.property_ids.includes(this.propertyId)
        )
      )
    );
  }

  openRentalAgreementDialog(agreement: ReservationAgreement) {
    const dialog = this.dialog.open(RentalAgreementsDetailsDialogComponent, {
      width: this.isMobile ? '100%' : '720px',
      height: this.isMobile ? '100%' : '80%',
      maxWidth: this.isMobile ? '100vw' : '720px',
      autoFocus: false,
      data: {
        agreement,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result?.deleted) {
        this.ngOnInit();
      }
    });
  }

  openRequestNewRentalAgreementDialog() {
    const dialog = this.dialog.open(DialogAddNewRentalAgreementToReservationComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      autoFocus: false,
      data: {
        reservationUuid: this.reservationUuid,
        propertyId: this.propertyId,
        guestName: this.reservation.guest,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
}
