import { Pipe, PipeTransform } from '@angular/core';
import { isBefore, startOfDay } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isBeforeToday',
})
export class IsBeforeTodayPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return isBefore(value, startOfDay(new Date()));
  }
}
