import { Pipe, PipeTransform } from '@angular/core';
import { GuestVettingOption } from '@app/shared/interfaces/lib/guest-vetting.interface';

@Pipe({
  standalone: false,
  name: 'guestVettingTotal',
})
export class GuestVettingTotalPipe implements PipeTransform {
  transform(selectedVettings: string[], guestVettingData: GuestVettingOption[]): number {
    let total = 0;

    selectedVettings.forEach((vetting) => {
      const relevantOption = guestVettingData.find((option) => option.id === vetting && !option.is_free);
      total += +relevantOption?.price.formatted_decimal || 0;
    });

    return total;
  }
}
