<!-- Loading state-->
<mat-spinner *ngIf="loading" [diameter]="32"></mat-spinner>

<div *ngIf="thermostatSettings">

  <div class="flex flex-col gap-2" [class.is-disabled]="thermostatSettings.allow_guests_to_control_thermostat">
      <mat-slide-toggle
        [(ngModel)]="thermostatSettings.allow_guests_to_control_thermostat">
        <strong>Allow guests to control thermostat during stay</strong>
      </mat-slide-toggle>

      <div class="smartlock-settings-description">
        Enable guests to adjust the thermostat during their stay. After check-out, the system will automatically revert to your preset temperature settings.
      </div>
  </div>
    
  <section *ngIf="showNotifications" class="notifications-settings" style="margin-top: 28px">
    <strong style="display: block; margin-bottom: 12px">Notifications</strong>

    <ng-container *ngFor="let item of thermostatSettings.dashboard_notifications | keyvalue">
      <section *ngIf="!notificationMetadata[item.key]?.hidden">
        <div style="display: flex; gap: 6px; align-items: center">
          <mat-slide-toggle [class.disabled]="notificationMetadata[item.key]?.disabled"
            [(ngModel)]="thermostatSettings.dashboard_notifications[item.key].dashboard"
            (ngModelChange)="parentNotificationToggled($event, thermostatSettings.dashboard_notifications[item.key])">
            {{ notificationMetadata[item.key]?.title || item.key }}
          </mat-slide-toggle>

          <div *ngIf="notificationMetadata[item.key]?.tooltip" [matTooltip]="notificationMetadata[item.key].tooltip">
            <sbnb-icon icon="question"></sbnb-icon>
          </div>
        </div>

        <div *ngIf="item.key === 'temperature' && thermostatSettings?.dashboard_notifications?.temperature?.dashboard">
          <div class="ml-4 space-y-4">
            <div class="flex items-center gap-2">
              <span class="w-12">Below:</span>
              <mat-form-field class="fw nopadding input__mini nomargin">
                <input 
                  matInput
                  name="number"
                  class="nopadding"
                  type="number"
                  step="1"
                  pattern="^[0-9]+$"
                  [placeholder]="0"
                  min="0"
                  [(ngModel)]="thermostatSettings.dashboard_notifications.temperature.below"
                  oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
              </mat-form-field>
              <span>°F</span>
            </div>
            <div class="flex items-center gap-2">
              <span class="w-12">Above:</span>
              <mat-form-field class="fw nopadding input__mini nomargin">
                <input 
                  matInput
                  name="number"
                  class="nopadding"
                  type="number"
                  step="1"
                  pattern="^[0-9]+$"
                  [placeholder]="0"
                  min="0"
                  [(ngModel)]="thermostatSettings.dashboard_notifications.temperature.above"
                  oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
              </mat-form-field>
              <span>°F</span>
            </div>
          </div>
        </div>

        <div *ngIf="thermostatSettings.dashboard_notifications[item.key] as setting"
          style="display: flex; margin-left: 32px; font-size: 14px; gap: 12px" [class.disabled]="!setting.dashboard"
          [hidden]="!setting.dashboard">
          <mat-checkbox [(ngModel)]="setting.push">Push</mat-checkbox>
          <mat-checkbox [(ngModel)]="setting.email">Email</mat-checkbox>
        </div>
      </section>
    </ng-container>
  </section>
</div>
<div class="flex items-center justify-between gap-2 mt-6">
  <sbnb-button label="Save changes" size="mini" [loading]="savingSettings"
    (clicked)="saveThermostatSettings()"></sbnb-button>

  <sbnb-save-indicator [saveStatus]="saveStatus"></sbnb-save-indicator>
</div>
