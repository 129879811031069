<!-- Loading state-->
<mat-spinner *ngIf="loading" [diameter]="32"></mat-spinner>

<div *ngIf="smartlocksSettings">
  <div [class.disabled]="!canEditSettings">
    <!-- Code Style Setting -->
    <section class="setting-display" style="margin-top: 24px">
      <strong>Code style</strong>

      <div class="smartlock-settings-description">Changes only apply to codes created in the future.</div>

      <mat-radio-group [(ngModel)]="smartlocksSettings.use_phone">
        <div fxLayout="column">
          <mat-radio-button [value]="true">Guest phone number</mat-radio-button>
          <mat-radio-button [value]="false">Random number</mat-radio-button>
        </div>
      </mat-radio-group>
    </section>

    <!-- Code Timing Buffers-->
    <div class="setting-display">
      <div fxLayout="column" fxLayoutGap="16px">
        <div>
          <strong>Code Timing Buffers</strong>
          <div class="smartlock-settings-description">
            How long before check-in and after checkout you want codes actively usable.
          </div>

          <div fxLayout fxLayoutAlign="start center" fxLayoutGap="16px">
            <span>
              Before
              <strong>check-in:</strong>
            </span>

            <div fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
              <mat-form-field class="fw nopadding input__mini nomargin">
                <input
                  name="number"
                  class="nopadding"
                  [(ngModel)]="smartlocksSettings.check_in_time_buffer.value"
                  type="number"
                  step="1"
                  pattern="^[0-9]+$"
                  matInput
                  [placeholder]="0"
                  min="0"
                  (input)="validateNumericInput($event)" />
              </mat-form-field>
              <sbnb-button
                type="text"
                size="mini"
                [label]="smartlocksSettings.check_in_time_buffer.unit"
                [matMenuTriggerFor]="checkMenu"></sbnb-button>
              <mat-menu #checkMenu="matMenu">
                <button mat-menu-item (click)="changeCheckInBufferUnits('minutes')">minutes</button>
                <button mat-menu-item (click)="changeCheckInBufferUnits('hours')">hours</button>
              </mat-menu>
            </div>
          </div>
        </div>

        <div>
          <div fxLayout fxLayoutAlign="start center" fxLayoutGap="16px">
            <span>
              After
              <strong>check-out:</strong>
            </span>

            <div fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
              <mat-form-field class="fw nopadding input__mini nomargin">
                <input
                  name="number"
                  class="nopadding"
                  [(ngModel)]="smartlocksSettings.check_out_time_buffer.value"
                  type="number"
                  step="1"
                  pattern="^[0-9]+$"
                  matInput
                  [placeholder]="0"
                  min="0"
                  (input)="validateNumericInput($event)" />
              </mat-form-field>
              <sbnb-button
                type="text"
                size="mini"
                [label]="smartlocksSettings.check_out_time_buffer.unit"
                [matMenuTriggerFor]="checkOutMenu"></sbnb-button>
              <mat-menu #checkOutMenu="matMenu">
                <button mat-menu-item (click)="changeCheckOutBufferUnits('minutes')">minutes</button>
                <button mat-menu-item (click)="changeCheckOutBufferUnits('hours')">hours</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Code communication & Backup-->
    <div class="setting-display" style="margin-bottom: 12px">
      <div fxLayout="column" fxLayoutGap="8px" [class.is-disabled]="smartlocksSettings.is_using_code_communication">
        <mat-slide-toggle
          *ngIf="smartlocksSettings.is_using_code_communication === false"
          [(ngModel)]="smartlocksSettings.is_using_code_communication">
          <strong>Code communication + Backup</strong>
        </mat-slide-toggle>

        <div
          *ngIf="smartlocksSettings.is_using_code_communication"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          class="strong disabled-title">
          <mat-slide-toggle
            [checked]="true"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            [matTooltip]="'Setting cannot be disabled'"></mat-slide-toggle>

          <strong>Code communication + Backup</strong>
        </div>

        <div class="smartlock-settings-description">
          If the code has not been communicated via the %smartlock_code% short code or if a backup code has to be sent.
        </div>

        <div fxLayout="column" fxLayoutGap="0px">
          <span style="padding-top: 16px">Guest messages</span>
          <span class="smartlock-settings-description">
            A greeting will be added if there has been no guest communication in the past hour.
          </span>
        </div>

        <mat-tab-group class="smartlock-tabs" dynamicHeight>
          <mat-tab label="Code issue">
            <div matTooltip="This message cannot be edited." class="code-communication-message">
              We encountered an issue while setting your code on the lock. No need to worry! Please use this code
              instead:
              <span class="shortcode">%smartlock_code%</span>
              .
            </div>
          </mat-tab>
          <mat-tab label="Code not sent">
            <div class="code-communication-message" matTooltip="This message cannot be edited.">
              Please note that your entry code is
              <span class="shortcode">%smartlock_code%</span>
              .
            </div>
          </mat-tab>
        </mat-tab-group>
        <div
          fxLayoutAlign="start center"
          fxLayoutAlign.xs="start start"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap="12px"
          fxLayoutGap.lt-md="0"
          class="text__small">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
            <sbnb-icon icon="time" class="shrink-0 text-smoke-darker"></sbnb-icon>
            <strong>Timing</strong>
          </div>

          <span *ngIf="+smartlocksSettings.check_in_time_buffer.value > 0">
            If needed, the message is sent
            <strong>
              <button
                mat-flat-button
                class="autohost-method button__text button__small"
                [matMenuTriggerFor]="checkMenu">
                {{ this.smartlocksSettings.add_buffers_to_comms == true ? 'including' : 'not including' }}
              </button>
              <mat-menu #checkMenu="matMenu">
                <button mat-menu-item (click)="updateAddBuffersToTimings(true)">including</button>
                <button mat-menu-item (click)="updateAddBuffersToTimings(false)">not including</button>
              </mat-menu>
            </strong>
            the
            <strong class="grass-darker">
              {{ smartlocksSettings.check_in_time_buffer.value }}
              {{ smartlocksSettings.check_in_time_buffer.unit.slice(0, -1) }} buffer
            </strong>
            before check-in.
          </span>

          <span *ngIf="+smartlocksSettings.check_in_time_buffer.value === 0">
            If needed, the message is sent
            <strong class="grass-darker">at</strong>
            check-in.
          </span>
        </div>
      </div>
    </div>

    <!-- Autohost -->
    <div *ngIf="user.autohost_enabled">
      <mat-checkbox [(ngModel)]="smartlocksSettings.delay.enabled">
        <div class="text__small">
          Require Autohost guest verification

          <strong>
            <button mat-flat-button class="autohost-method button__text button__small" [matMenuTriggerFor]="checkMenu">
              {{ this.smartlocksSettings.delay.method == 'always' ? 'including' : 'except for' }}
            </button>
            <mat-menu #checkMenu="matMenu">
              <button mat-menu-item (click)="updateAutohostMethod('always')">including</button>
              <button mat-menu-item (click)="updateAutohostMethod('exclude_last_minute')">except for</button>
            </mat-menu>
          </strong>
          <span>last-minute bookings made less than 10 minutes before code start time.</span>
        </div>
      </mat-checkbox>
    </div>
  </div>

  <!-- Notification settings, can be updated regardless of user permissions -->
  <section *ngIf="showNotifications" class="mt-7">
    <strong class="block mb-3">Notifications</strong>

    <ng-container *ngFor="let item of smartlocksSettings.dashboard_notifications | keyvalue">
      <section *ngIf="!notificationMetadata[item.key]?.hidden">
        <div class="flex items-center gap-1.5">
          <mat-slide-toggle
            [class.disabled]="notificationMetadata[item.key]?.disabled"
            [(ngModel)]="smartlocksSettings.dashboard_notifications[item.key].dashboard"
            (ngModelChange)="parentNotificationToggled($event, smartlocksSettings.dashboard_notifications[item.key])">
            {{ notificationMetadata[item.key]?.title || item.key }}
          </mat-slide-toggle>

          <div *ngIf="notificationMetadata[item.key]?.tooltip" [matTooltip]="notificationMetadata[item.key].tooltip">
            <sbnb-icon icon="question"></sbnb-icon>
          </div>
        </div>

        <div
          *ngIf="smartlocksSettings.dashboard_notifications[item.key] as setting"
          class="flex ml-8 text-sm gap-3"
          [class.disabled]="!setting.dashboard"
          [hidden]="!setting.dashboard">
          <mat-checkbox [(ngModel)]="setting.push">Push</mat-checkbox>
          <mat-checkbox [(ngModel)]="setting.email">Email</mat-checkbox>
        </div>
      </section>
    </ng-container>
  </section>

  <div class="flex items-center justify-between gap-2 mt-6">
    <sbnb-button
      label="Save changes"
      size="mini"
      [loading]="savingSettings"
      (clicked)="saveSmartlockSettings()"></sbnb-button>

    <sbnb-save-indicator [saveStatus]="saveStatus"></sbnb-save-indicator>
  </div>
</div>
