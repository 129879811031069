<ng-container [ngSwitch]="platform | lowercase">
  <sbnb-icon-airbnb
    [ngClass]="cssClass"
    *ngSwitchCase="Channel.Airbnb"
    [height]="height"
    [width]="width"></sbnb-icon-airbnb>
  <sbnb-icon-airbnb
    [ngClass]="cssClass"
    *ngSwitchCase="Channel.AirbnbOfficial"
    [height]="height"
    [width]="width"></sbnb-icon-airbnb>
  <sbnb-icon-homeaway
    [ngClass]="cssClass"
    *ngSwitchCase="Channel.Vrbo"
    [height]="height"
    [width]="width"></sbnb-icon-homeaway>
  <sbnb-icon-booking
    [ngClass]="cssClass"
    *ngSwitchCase="Channel.Booking"
    [height]="height"
    [width]="width"></sbnb-icon-booking>
  <sbnb-icon-agoda
    [ngClass]="cssClass"
    *ngSwitchCase="Channel.Agoda"
    [height]="height"
    [width]="width"></sbnb-icon-agoda>
  <sbnb-icon-smartbnb
    [ngClass]="cssClass"
    *ngSwitchCase="Channel.Manual"
    [height]="height"
    [width]="width"></sbnb-icon-smartbnb>
  <sbnb-icon-smartbnb
    [ngClass]="cssClass"
    *ngSwitchCase="Channel.Direct"
    [height]="height"
    [width]="width"></sbnb-icon-smartbnb>
  <sbnb-icon-smartbnb [ngClass]="cssClass" *ngSwitchDefault [height]="height" [width]="width"></sbnb-icon-smartbnb>
</ng-container>
