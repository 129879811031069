import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss'],
})
export class StarsComponent implements OnInit, OnChanges {
  maxCount = 5;

  /**
   * Initial number of filled stars (1-5)
   */
  @Input() startingCount: number;

  /**
   * Color theme for the stars
   * @default 'gold'
   */
  @Input() color: 'gold' | 'dark' = 'gold';

  /**
   * Emits the new star count when user clicks a star
   */
  @Output() updated: EventEmitter<number> = new EventEmitter();

  starArray: any[];
  hoverArray;

  constructor() {}

  ngOnInit() {
    this.createStars();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.startingCount.currentValue) {
      this.createStars();
    }
  }

  mouseOver(newStar, event) {
    if (!this.hoverArray) {
      this.hoverArray = [];
    }

    for (let index = 0; index < this.maxCount; index++) {
      this.hoverArray.push(index < newStar ? true : false);
    }
  }

  mouseOut(index, event) {
    if (this.hoverArray) {
      delete this.hoverArray;
    }
  }

  starClicked(number) {
    this.updated.emit(number);

    this.startingCount = number;
    this.createStars();
  }

  private createStars() {
    this.starArray = [];

    for (let index = 0; index < this.maxCount; index++) {
      this.starArray.push(index < this.startingCount ? true : false);
    }
  }
}
