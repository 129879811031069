<h3>Delete Segment</h3>
<p class="text__small help__text">
  Deleting this segment
  <strong>({{ dialogData.segmentName }})</strong>
  will permanently remove it from your account
</p>

<div fxLayout="row" fxLayoutGap="16px">
  <button fxFlex type="submit" mat-flat-button class="button__text" (click)="cancelClicked()">Cancel</button>
  <button class="button__delete" fxFlex type="submit" mat-flat-button (click)="okClicked()">Delete</button>
</div>
