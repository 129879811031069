import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThreadService } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-resolve-conversation',
  template: `
    <button
      [ngClass]="{ 'text-raspberry-darker': resolved, 'text-night-lighter': !resolved }"
      [matTooltip]="resolved ? 'Unresolve this conversation' : 'Resolve this conversation'"
      (click)="resolved ? markAsUnresolved() : markAsResolved()">
      <sbnb-icon [size]="24" icon="resolve"></sbnb-icon>
    </button>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResolveConversationComponent implements OnInit {
  @Input() resolved = false;
  @Input() threadUuid: string;
  @Output() resolvedToggled = new EventEmitter<boolean>();

  constructor(private readonly threadService: ThreadService) { }

  ngOnInit(): void { }

  public markAsResolved() {
    this.threadService.markAsResolved(this.threadUuid).subscribe((res) => {
      if (res.success) {
        this.resolvedToggled.emit(true);
      }
    });
  }

  public markAsUnresolved() {
    this.threadService.markAsUnresolved(this.threadUuid).subscribe((res) => {
      if (res.success) {
        this.resolvedToggled.emit(false);
      }
    });
  }
}
