import { OptimizelyFeatureFlag, PosthogFeatureFlag } from '@app/shared/interfaces';
import { environment } from '@env/environment';

interface appConfig {
  API_URL: string;
  DEFAULT_PAGE: string;
  ONBOARDING_PAGE: string;
  MIN_PASSWORD_LENGTH: number;
  TOS_URL: string;
  PRIVACY_POLICY_URL: string;
  SECURITY_URL: string;
  MAILCHIMP_CLIENT_ID: string;
  MAILCHIMP_CLIENT_REDIRECT: string;
  CHARGEBEE_SITE: string;
  CHARGEBEE_PUBLISHABLEKEY: string;
  CHARGEBEE_SITE_V2: string;
  CHARGEBEE_PUBLISHABLEKEY_V2: string;
  ONESIGNAL_APP_ID: string;
  OPTIMIZELY_SDK_KEY: string;
  OPTIMIZELY_FEATURE_FLAGS: OptimizelyFeatureFlag[];
  HIDE_TOAST_URLS: string[]; // URLs matching elements in this array (via indexOf) will not show Toast notifications
  EMAIL_PLACEHOLDER: string;
  ALGOLIA_PLACES_APPID: string;
  ALGOLA_PLACES_APIKEY: string;
  REMOTELOCK_INTEGRATION_ID: string;
  AUGUST_INTEGRATION_ID: string;
  YALE_INTEGRATION_ID: string;
  SMARTTHINGS_INTEGRATION_ID: string;
  NUKI_INTEGRATION_ID: string;
  IGLOO_INTEGRATION_ID: string;
  TTLOCK_INTEGRATION_ID: string;
  HONEYWELL_INTEGRATION_ID: string;
  ECOBEE_INTEGRATION_ID: string;
  SEAM_SCHLAGE_INTEGRATION_ID: string;
  BOOKING_WIDGET_BASE_URL: string;
  FAIRMINT_API_URL: string;
  ALGOLIA_APPLICATION_ID: string;
  URLS_TO_CACHE: { url: string; ttl_seconds: number }[];
  ALGOLIA_SEARCH_KEY_NAME: string;
  CHURNKEY_APP_ID: string;
  CHURNKEY_MODE: 'test' | 'live';
  POSTHOG_FEATURE_FLAGS: PosthogFeatureFlag[];
  GOOGLE_PLACES_API_KEY: string;
  GUEST_PORTAL_URL: string;
  YEAR_IN_REVIEW_URL: string;
}

export const config: appConfig = {
  API_URL: environment.apiUrl,
  DEFAULT_PAGE: '/dashboard',
  ONBOARDING_PAGE: '/accounts', // Where do we take users after registration?
  MIN_PASSWORD_LENGTH: 8,
  TOS_URL: 'https://www.hospitable.com/terms/',
  PRIVACY_POLICY_URL: 'https://www.hospitable.com/privacy/',
  SECURITY_URL: 'https://hospitable.com/security',
  MAILCHIMP_CLIENT_ID: '168631934700',
  MAILCHIMP_CLIENT_REDIRECT: 'https://my.hospitable.com/apps/mailchimp',
  CHARGEBEE_SITE: environment.chargebee.site,
  CHARGEBEE_PUBLISHABLEKEY: environment.chargebee.publishableKey,
  CHARGEBEE_SITE_V2: environment.chargebeev2.site,
  CHARGEBEE_PUBLISHABLEKEY_V2: environment.chargebeev2.publishableKey,
  ONESIGNAL_APP_ID: 'cfc5cce8-cd38-4c74-9f75-ce8c971c3791',
  OPTIMIZELY_SDK_KEY: environment.optimizely.sdkKey,
  OPTIMIZELY_FEATURE_FLAGS: environment.optimizely?.flags ?? [],
  HIDE_TOAST_URLS: ['/accounts'],
  EMAIL_PLACEHOLDER: 'username@example.com',
  ALGOLIA_PLACES_APPID: 'pl3NOTS0TEC7',
  ALGOLA_PLACES_APIKEY: 'fae71f47483dd1a06646447b161f395d',
  REMOTELOCK_INTEGRATION_ID: 'c1f4ea9e-6ade-4ce6-bfee-5545b5238197',
  AUGUST_INTEGRATION_ID: '09f0f1ba-bcd6-483d-8fec-875f239c28b4',
  YALE_INTEGRATION_ID: '09f0f1ba-bcd6-483d-8fec-64257d250b96',
  SMARTTHINGS_INTEGRATION_ID: '09f0f1ba-bcd6-483d-8fec-15257d250b54',
  NUKI_INTEGRATION_ID: '09f0f1ba-bcd6-483d-8fec-81108165878s',
  IGLOO_INTEGRATION_ID: '09f0f1ba-bcd6-483d-8fec-811081654444',
  TTLOCK_INTEGRATION_ID: '09f0f1ba-bcd6-483d-8fec-912482892340',
  HONEYWELL_INTEGRATION_ID: '2bf3b13e-5cc9-4688-84dc-5a3c2223d0e6',
  ECOBEE_INTEGRATION_ID: '2bf3b13e-5cc9-4688-84dc-63f673c7dde6',
  SEAM_SCHLAGE_INTEGRATION_ID: '09f0f1ba-bcd6-483d-8fec-61439745946c',
  BOOKING_WIDGET_BASE_URL: 'https://booking.hospitable.com/widget/',
  FAIRMINT_API_URL: 'https://api.invest.fairmint.co/service1/offering/status?orgId=hospitable',
  ALGOLIA_APPLICATION_ID: 'CGOMIYRCJJ',
  URLS_TO_CACHE: [
    { url: '/filters', ttl_seconds: 3600 }, // Filters are very static and should never really change during the lifetime of a session
    { url: 'filter-values/generics', ttl_seconds: 3600 },
    { url: '/teams', ttl_seconds: 300 }, // Teams are rarely updated, so we can cache them for a while
    { url: '/counts', ttl_seconds: 60 }, // Counts are updated frequently, so we can cache them for a short time. We'll tweak this value based on feedback
    { url: '/segments', ttl_seconds: 60 }, // Segments can contain saved segments, but we'll empty our cache when one is created during the current user session
    { url: '/sites/templates', ttl_seconds: 60 },
  ],
  ALGOLIA_SEARCH_KEY_NAME: 'search_key',
  CHURNKEY_APP_ID: environment.churnkey.appId,
  CHURNKEY_MODE: environment.churnkey.mode as 'test' | 'live',
  POSTHOG_FEATURE_FLAGS: environment.posthog?.flags ?? [],
  GOOGLE_PLACES_API_KEY: environment.google?.places?.apiKey,
  GUEST_PORTAL_URL: `https://booking.hospitable.com/guest-portal/`,
  YEAR_IN_REVIEW_URL: `https://journey.hospitable.com/2024/`,
};
