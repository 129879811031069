import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PaginationMeta } from '@app/shared/interfaces';

@Component({
  selector: 'hospitable-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() pagination: PaginationMeta;

  // This component currently supports both cursor and non-cursor based pagination as we move from one to the other
  @Output() pageChanged = new EventEmitter<number>(); // non-cursor pagination
  @Output() cursorChanged = new EventEmitter<string>(); // cursor pagination

  pages: number[];
  isCursorPagination = false;

  ngOnChanges() {
    this.isCursorPagination = this.computeCursorPagination();

    // If we're in non-cursor mode - we want to determine the total number of pages and throw an error if the current page is invalid
    if (!this.isCursorPagination) {
      this.pages = Array.from(new Array(this.pagination.page_numbers.total_pages), (val, index) => index + 1);

      if (this.pagination.page_numbers.this_page < 1 || this.pagination.page_numbers.this_page > this.pages.length) {
        throw Error(`You entered an invalid current page: Page ${this.pagination.this_page} of ${this.pages.length}`);
      }
    }
  }

  computeCursorPagination() {
    return Object.prototype.hasOwnProperty.call(this.pagination, 'has_more');
  }

  goToPage(page: number) {
    this.pageChanged.emit(page);
  }

  goToPreviousPage() {
    const page = this.pagination.page_numbers.this_page - 1;

    if (page >= 1) {
      this.pageChanged.emit(page);
    }
  }

  goToNextPage() {
    const page = this.pagination.page_numbers.this_page + 1;

    if (page < this.pagination.total) {
      this.pageChanged.emit(page);
    }
  }

  /* cursor methods */
  onCursorNext(): void {
    if (this.pagination?._links?.next) {
      this.cursorChanged.emit(this.pagination?._links?.next);
    }
  }

  onCursorPrevious(): void {
    if (this.pagination?._links?.prev) {
      this.cursorChanged.emit(this.pagination?._links?.prev);
    }
  }
}
