import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ThreadService } from '@app/shared/services/thread/thread.service';

@Component({
  standalone: false,
  selector: 'sbnb-guest-email',
  templateUrl: './guest-email.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestEmailComponent implements OnInit {
  @Input() email: string;
  @Input() threadUuid: string;
  @Input() canChangeEmail: boolean;
  @Output() emailUpdated = new EventEmitter<string>();

  originalEmail: string;

  constructor(private readonly threadService: ThreadService) {}

  ngOnInit(): void {
    this.originalEmail = this.email;
  }

  public updateGuestEmail(newEmail: string) {
    if (!newEmail?.length || this.originalEmail === newEmail) {
      return;
    }

    this.emailUpdated.emit(newEmail);

    if (!this.canChangeEmail || !this.threadUuid) {
      return;
    }

    this.threadService.updateGuestEmail(this.threadUuid, newEmail).subscribe((res) => {});
  }
}
