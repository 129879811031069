import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { ApiResponse, NotificationType, Reservation } from '@app/shared/interfaces';
import { environment } from '@env/environment';
import { differenceInCalendarDays, format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { captureException } from 'src/sentry';
import { ToastNotificationsService } from '../toast-notifications/toast-notifications.service';
export interface ReservationFinancialItem {
  type: 'revenue' | 'accomodation' | 'mixed' | 'host_service_fee' | 'host_tax' | 'guest_total_price';
  title: string;
  info?: string;
  amount: string;
  amount_formatted: string;
  items: ReservationFinancialItem[];
}

interface ReservationFinancialsPayload {
  data: {
    currency: string;
    host: ReservationFinancialItem[];
    guest: ReservationFinancialItem[];
  };
}

interface ReservationExtendedGroup {
  group_id:
    | 'booking-details'
    | 'listing-details'
    | 'payment-details'
    | 'reservation-details'
    | 'cancellation-policy'
    | 'debug';
  label:
    | 'Booking Details'
    | 'Listing Details'
    | 'Payment Details'
    | 'Reservation Details'
    | 'Cancellation Policy'
    | 'Debug info';
  values: Array<{
    id: string;
    key: string;
    value: string;
  }>;
}
interface ReservationExtended {
  data: ReservationExtendedGroup[];
}

interface GetReservationsForThreadOptions {
  with_scheduled_messages_paused_status?: boolean;
}

export interface AutohostSdkToken {
  token: string;
  valid_until: string;
  autohost_reservation_id: string;
}

export interface IcalReservationEmailUpdated {
  success: boolean;
  message?: string;
  thread_uuid: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  private getReservationsUrl = `${config.API_URL}/reservations/`;
  private getBookingsUrl = `${config.API_URL}/bookings`; // like getReservations, but only confirmed reservations, with pagination
  private getReservationBlocksUrl = `${config.API_URL}/reservation-blocks/`;

  constructor(
    private http: HttpClient,
    private toast: ToastNotificationsService
  ) {}

  getReservationsForThread(threadId: string, options: GetReservationsForThreadOptions = {}): Observable<Reservation[]> {
    const params = new URLSearchParams({
      timezones: 'false',
      thread_id: threadId,
      with_cancelled: 'true',
      with_scheduled_messages_paused_status: options.with_scheduled_messages_paused_status?.toString() || 'false',
    });

    const url = `${this.getReservationsUrl}?${params.toString()}`;

    return this.http.get(url).pipe(
      map((response: any) => {
        const result: Reservation[] = [];

        response.data.forEach((reservation) => {
          reservation.num_nights = differenceInCalendarDays(reservation.end_date, reservation.start_date);
          result.push(reservation);
        });

        return result;
      })
    );
  }

  getReservationsByDate(
    startDate: Date,
    endDate: Date,
    propertyIDs?: any[],
    withTimezones = false,
    confirmedOnly = false,
    includeParentChildReservations = false
  ): Observable<any> {
    const dateUrlParam = `${format(startDate, 'YYYY-MM-DD')}_${format(endDate, 'YYYY-MM-DD')}`;
    let url = `${this.getReservationsUrl}?starts_or_ends_between=${dateUrlParam}&timezones=${withTimezones}`;

    if (propertyIDs) {
      url = `${url}&property_ids=${propertyIDs}`;
    }

    if (confirmedOnly) {
      url = `${url}&calendar_blockable=true`;
    }

    if (includeParentChildReservations) {
      url = `${url}&include_family_reservations=true`;
    }

    return this.http.get(url).pipe(
      map((response: any) => {
        const res = response.data;

        res.forEach((event) => {
          event.num_nights = differenceInCalendarDays(event.end_date, event.start_date);
        });

        return res;
      })
    );
  }

  getCheckIns({
    startDate,
    offset,
    cursor,
  }: {
    startDate: Date;
    offset?: number;
    cursor?: string;
  }): Observable<ApiResponse<Reservation[]>> {
    const dateUrlParam = `${format(startDate, 'YYYY-MM-DD')}`;
    let url = `${this.getBookingsUrl}?starts_from=${dateUrlParam}&timezones=false&limit=20&confirmed=true`;

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (cursor) {
      url = `${url}&cursor=${cursor}`;
    }

    return this.http.get<ApiResponse<Reservation[]>>(url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCheckOuts({ startDate, offset, cursor }: { startDate: Date; offset?: number; cursor?: string }) {
    const dateUrlParam = `${format(startDate, 'YYYY-MM-DD')}`;
    let url = `${this.getBookingsUrl}?ends_to=${dateUrlParam}&timezones=false&limit=20&confirmed=true`;

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (cursor) {
      url = `${url}&cursor=${cursor}`;
    }

    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getReservationDetails(resUuid: string, url: string): Observable<ReservationExtended> {
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateReservationTimes(uuid: string, payload: any) {
    return this.http.put(`${this.getReservationsUrl}${uuid}/times`, payload).pipe(
      map((res) => res),
      catchError((err: any) => {
        this.toast.open('Failed to update reservation times.', 'Dismiss', NotificationType.Error);
        return of(false);
      })
    );
  }

  updateIcalReservationEmail(uuid: string, email: string): Observable<IcalReservationEmailUpdated> {
    return this.http
      .put<{ data: IcalReservationEmailUpdated }>(`${this.getReservationsUrl}${uuid}/ical`, {
        email: email,
      })
      .pipe(
        map((res) => {
          if ('message' in res) {
            return res as unknown as IcalReservationEmailUpdated;
          }
          return res.data;
        }),
        catchError((err: any) => {
          if ('error' in err) {
            this.toast.open(
              err.error.message || 'Failed to update reservation email.',
              'Dismiss',
              NotificationType.Error
            );
          }
          return of(null);
        })
      );
  }

  getReservationBlocksForThread(threadId: string, listingId: string): Observable<any[]> {
    const url = `${this.getReservationBlocksUrl}?for_thread=${threadId}&listing_id=${listingId}&timezones=false&confirmed=true`;

    return this.http.get(url).pipe(
      map((res: any) =>
        res.data.map((block) => {
          return {
            start: format(block.start, 'YYYY-MM-DD'),
            end: format(block.end, 'YYYY-MM-DD'),
            highlight: block.this_thread,
          };
        })
      )
    );
  }

  getAutohostSdkToken(reservationUuid: string): Observable<AutohostSdkToken> {
    return this.http
      .post<{ data: AutohostSdkToken }>(`${environment.apiUrl}/reservations/${reservationUuid}/autohost-sdk-token`, {})
      .pipe(map((res: { data: AutohostSdkToken }) => res.data));
  }

  getReservationFinancials(reservationUuid: string): Observable<ReservationFinancialsPayload> {
    return this.http.get<ReservationFinancialsPayload>(`${this.getReservationsUrl}${reservationUuid}/financials`);
  }

  extendPaymentGracePeriod(reservationUuid: string, paymentUuid: string, date: string) {
    return this.http
      .post(`${environment.bookingApiUrl}/payments/${reservationUuid}/${paymentUuid}/extend-grace-period`, { date })
      .pipe(
        map((res) => res),
        catchError((err: any) => {
          captureException(err);
          this.toast.open('Failed to extend payment grace period.', 'Dismiss', NotificationType.Error);
          return of(false);
        })
      );
  }

  reportInvalidPaymentDetails(reservationUuid: string): Observable<boolean> {
    return this.http
      .post(
        `${this.getReservationsUrl}${reservationUuid}/report-invalid-payment-details`,
        {},
        {
          headers: {
            'X-Skip-Interceptor': 'true',
          },
        }
      )
      .pipe(
        catchError((err: unknown) => {
          const message =
            err instanceof HttpErrorResponse ? err.error.error : 'Failed to report invalid payment details.';
          this.toast.open(message, 'Dismiss', NotificationType.Error);
          return of(false);
        }),
        map(() => true)
      );
  }
}
