import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableData } from './table.component.interface';

@Component({
  selector: 'hospitable-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() data: TableData;
  @Output() pageChanged = new EventEmitter<number>();
  dataSource: MatTableDataSource<unknown>;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.dataSource);
  }

  onPageChanged(page: number) {
    this.pageChanged.emit(page);
  }
}
