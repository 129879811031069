<div *ngIf="progressBar">
  <div class="w-full grid grid-cols-6 mb-2 justify-between items-center">
    <div class="text-sm md:text-lg text-gray col-span-2" *ngIf="progressBar.title">{{ progressBar.title }}</div>

    <div class="flex col-span-3">
      <div class="flex flex-grow h-2 bg-alt-gray rounded-full overflow-hidden" role="progressbar">
        <div
          class="flex flex-col justify-center rounded-full overflow-hidden text-xs text-white text-center whitespace-nowrap transition duration-500"
          [ngClass]="ProgressBarColourMapper.bar[progressBar.progress.colour]"
          [style.width.%]="progressBar.progress.value"></div>
      </div>
    </div>

    <div class="flex col-span-1 flex-row justify-between">
      <span
        class="text-md text-gray ml-2"
        *ngIf="progressBar.progress && progressBar && progressBar.progress.formatted">
        {{ progressBar.progress.formatted }}
      </span>

      <span
        *ngIf="progressBar.difference && progressBar.difference.formatted"
        class="text-xs font-medium ml-2 px-2.5 py-1 rounded-full text-white"
        [ngClass]="ProgressBarColourMapper.difference[progressBar.difference.colour]">
        {{ progressBar.difference.formatted }}
      </span>
    </div>
  </div>
</div>
