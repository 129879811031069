import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogAddDirectQuoteComponent } from '@app/shared/components/dialog-add-direct-booking/dialog-add-direct-quote.component';
import { Observable } from 'rxjs';
import { SegmentEvent, SegmentIoService } from '../segmentIo/segment-io.service';
import { NewDirectCustomQuote } from './direct-quotes.service';

export interface DialogDirectQuotesData {
  quote: NewDirectCustomQuote | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  propertyId: string | null | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class DialogDirectQuotesService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly segmentIoService: SegmentIoService
  ) {}

  public open(quote?: NewDirectCustomQuote, startDate?: Date, endDate?: Date, propertyId?: number): Observable<any> {
    // Track the open event
    this.segmentIoService.track(SegmentEvent.DirectQuoteDialogOpened, {});

    const dialogRef = this.dialog.open(DialogAddDirectQuoteComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
      data: {
        quote,
        startDate,
        endDate,
        propertyId,
      },
    });

    return dialogRef.afterClosed();
  }
}
