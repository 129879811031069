<div class="subnav__wrapper">
  <div *ngIf="header" class="header__container" fxLayout fxLayoutAlign="start center">
    <h2 class="nomargin" fxFlex fxLayoutAlign="start center" fxLayoutGap="8px">
      <span>{{ header | transloco }}</span>
      <span *ngIf="badge" class="badge badge-grass">{{ badge }}</span>
    </h2>
    <img *ngIf="settingsLink" class="clickable" src="/assets/iconography/settings.svg" [routerLink]="[settingsLink]" />
  </div>
  <ng-container *ngIf="items">
    <nav class="nav__subnav" *ngFor="let area of items">
      <h4>{{ area.heading }}</h4>
      <a *ngFor="let item of area.items" [routerLink]="item.link" routerLinkActive="subnav__active-link">
        {{ item.text | transloco }}
      </a>
    </nav>
  </ng-container>

  <ng-content></ng-content>
</div>
