import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-team-role-badge',
  templateUrl: './team-role-badge.component.html',
  styleUrls: ['./team-role-badge.component.scss'],
})
export class TeamRoleBadgeComponent implements OnInit {
  @Input() member: any;
  @Input() individualBadges = false;

  constructor() { }

  ngOnInit() { }
}
