<ng-container *ngIf="review; else loading">
  <div class="flex flex-col gap-2 mb-6">
    <h3>How would you rate {{ review.guest?.name }}?</h3>
    <div class="night-lighter">
      Hospitable will use your rating to personalize the review with AI
      <img class="ai-icon" src="/assets/iconography/magic.svg" onload="SVGInject(this)" alt="Generated with AI" />
    </div>
  </div>
  <form [formGroup]="reviewForm" (ngSubmit)="onSaveReview()" class="flex flex-col gap-9 pb-8">
    <mat-button-toggle-group formControlName="rating" class="material-radio-buttons">
      <mat-button-toggle [value]="1">😣 Terrible</mat-button-toggle>
      <mat-button-toggle [value]="2">🙁 Bad</mat-button-toggle>
      <mat-button-toggle [value]="3">😅 Okay</mat-button-toggle>
      <mat-button-toggle [value]="4">😊 Good</mat-button-toggle>
      <mat-button-toggle [value]="5">🤩 Excellent</mat-button-toggle>
    </mat-button-toggle-group>

    <div>
      <sbnb-textarea formControlName="review" [ngModel]="reviewForm.get('review').value" [minRows]="4">
      </sbnb-textarea>
      <mat-error *ngIf="reviewForm.get('review').hasError('required')">Review message is required</mat-error>
    </div>
    <div class="flex gap">
      <div class="form-group-icon">
        <mat-slide-toggle formControlName="leavePrivateFeedback" class="pt-1"></mat-slide-toggle>
      </div>
      <div class="flex flex-col w-full gap-3">
        <h3>Leave private feedback for {{ review.guest?.name }}</h3>
        <sbnb-textarea formControlName="privateFeedback" [ngModel]="reviewForm.get('privateFeedback').value" [minRows]="4">
        </sbnb-textarea>
      </div>
    </div>
    <div class="flex gap">
      <div class="form-group-icon">
        <mat-slide-toggle formControlName="delay" class="pt-1"></mat-slide-toggle>
      </div>
      <div class="flex flex-col gap-3">
        <h3>Delay publishing this review until the end</h3>
        <div class="night-lighter">
          Expecting a bad guest review? Delay publishing this review to Airbnb until the very last minute of the review
          period.
        </div>
      </div>
    </div>
    <div class="flex gap">
      <div class="flex justify-center smoke-darker form-group-icon">
        <img height="24" width="24" src="/assets/iconography/like-bubble.svg" onload="SVGInject(this)" alt="reviews" />
      </div>
      <div class="flex flex-col w-full gap-3">
        <h3>Would you recommend {{ review.guest?.name }} to other hosts?</h3>
        <mat-button-toggle-group formControlName="recommend">
          <mat-button-toggle class="flex flex-1 items-center" [value]="true">
            <img
              [src]="
                !reviewForm.controls.recommend.value
                  ? '/assets/iconography/thumbs-up.svg'
                  : '/assets/iconography/thumbs-up-green.svg'
              "
              (click)="reviewForm.controls.recommend.setValue(true)" />
            Yes
          </mat-button-toggle>
          <mat-button-toggle class="flex flex-1 items-center" [value]="false">
            <img
              [class]="{ clickable: reviewForm.controls.recommend.value }"
              [src]="
                reviewForm.controls.recommend.value
                  ? '/assets/iconography/thumbs-down.svg'
                  : '/assets/iconography/thumbs-down-red.svg'
              "
              (click)="reviewForm.controls.recommend.setValue(false)" />
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="flex gap">
      <div class="flex justify-center form-group-icon">
        <img height="24" width="24" src="/assets/iconography/review.svg" alt="reviews" onload="SVGInject(this)" />
      </div>
      <div class="flex flex-col gap-3">
        <h3>How did {{ review.guest?.name }} perform on these criteria?</h3>

        <div class="flex flex-col gap-6">
          <!-- Cleanliness -->
          <div class="flex flex-col gap-2 pb-2">
            <div class="text-base font-medium text-night">Cleanliness</div>
            <div class="flex flex-col gap-2">
              <sbnb-stars
                color="dark"
                [startingCount]="reviewForm.controls.cleanliness.value"
                (updated)="setStarField($event, 'cleanliness')"
                data-test="cleanliness-rating">
              </sbnb-stars>
              <sbnb-review-tags
                *ngIf="tagsEnabled"
                category="cleanliness"
                [selectedTags]="Array.from(selectedTags.cleanliness)"
                [disabled]="!!review.sent_at"
                (tagsChange)="onTagsChange('cleanliness', $event)"
                data-test="cleanliness-tags">
              </sbnb-review-tags>
            </div>
          </div>

          <!-- Communication -->
          <div class="flex flex-col gap-2 py-2">
            <div class="text-base font-medium text-night">Communication</div>
            <div class="flex flex-col gap-2">
              <sbnb-stars
                color="dark"
                [startingCount]="reviewForm.controls.communication.value"
                (updated)="setStarField($event, 'communication')"
                data-test="communication-rating">
              </sbnb-stars>
              <sbnb-review-tags
                *ngIf="tagsEnabled"
                category="communication"
                [selectedTags]="Array.from(selectedTags.communication)"
                [disabled]="!!review.sent_at"
                (tagsChange)="onTagsChange('communication', $event)"
                data-test="communication-tags">
              </sbnb-review-tags>
            </div>
          </div>

          <!-- House Rules -->
          <div class="flex flex-col gap-2 pt-2">
            <div class="text-base font-medium text-night">Respect of house rules</div>
            <div class="flex flex-col gap-2">
              <sbnb-stars
                color="dark"
                [startingCount]="reviewForm.controls.respectHouseRules.value"
                (updated)="setStarField($event, 'respectHouseRules')"
                data-test="house-rules-rating">
              </sbnb-stars>
              <sbnb-review-tags
                *ngIf="tagsEnabled"
                category="respect_house_rules"
                [selectedTags]="Array.from(selectedTags.respect_house_rules)"
                [disabled]="!!review.sent_at"
                (tagsChange)="onTagsChange('respect_house_rules', $event)"
                data-test="house-rules-tags">
              </sbnb-review-tags>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
    <!-- Form actions -->
    <div class="flex justify-between w-full flex-wrap btn-stack gap-3">
      <!-- Close and Delete -->
      <div class="flex btn-stack gap-3">
        <button
          *ngIf="!review.sent_at && review.scheduled_for"
          type="button"
          [disabled]="(submitting$ | async) === true"
          (click)="onCancelReview()"
          mat-flat-button
          class="button__text !text-rose-darker">
          <span class="flex gap-1 items-center justify-center">
            <sbnb-icon icon="not-allowed"></sbnb-icon>
            <span>Don't send</span>
          </span>
        </button>
        <button type="button" (click)="this.cancelled.emit()" mat-flat-button class="button__secondary">Cancel</button>
      </div>
      <!-- Publish now / Save -->
      <div *ngIf="review.editable && !review.sent_at" class="flex btn-stack gap-3">
        <ng-container *ngIf="!review.scheduled_for; else scheduled">
          <button
            [disabled]="reviewForm.invalid || (submitting$ | async) === true"
            type="submit"
            mat-flat-button
            class="button__primary">
            {{ reviewForm.get('delay')?.value === true ? 'Save' : 'Publish now' }}
          </button>
        </ng-container>
        <ng-template #scheduled>
          <button
            [disabled]="reviewForm.invalid || (submitting$ | async) === true || reviewForm.get('delay')?.value === true"
            type="button"
            (click)="onSaveReview(true)"
            mat-flat-button
            class="button__secondary">
            Publish now
          </button>
          <button
            [disabled]="reviewForm.invalid || (submitting$ | async) === true"
            type="submit"
            mat-flat-button
            class="button__primary">
            Save
          </button>
        </ng-template>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #loading>
  <sbnb-loading-spinner></sbnb-loading-spinner>
</ng-template>
