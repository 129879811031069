<div class="flex items-center gap-4 rounded-[4px] overflow-hidden border border-smoke"
  [ngClass.lt-md]="{ mobile: true }">
  <img class="properties__img hidden md:block" width="136" [src]="listing.picture" />
  <div class="flex-1">
    <div class="flex items-center gap-2">
      <h4>{{ listing.name }}</h4>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <sbnb-icon-platform [platform]="listing.platform_key" [cssClass]="'icon-platform'" />
        <span class="text__small night-lighter">{{ listing.listing_type }}</span>
        <ng-container *ngIf="listing.host && listing.platform_key !== 'booking'">
          <span>•</span>
          <img *ngIf="listing.host.picture_url" [src]="listing.host.picture_url"
            class="h-[14px] w-[14px] rounded-full" />
          <span class="night-lighter text__small">{{ listing.host.name }}</span>
        </ng-container>

        <ng-container *ngIf="listing.platform_key === 'booking'">
          <span>•</span>
          <span class="night-lighter text__small">Room ID: {{ listing.listing_id }}</span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="flex items-center gap-2 self-center mr-4" data-element="listing-badges">
    <ng-container *ngIf="listing.is_lead_listing">
      <span class="badge badge-grass">Lead listing</span>
    </ng-container>
  </div>
</div>