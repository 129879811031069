import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Host } from '@app/shared/models/host';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-hosts',
  templateUrl: './dialog-hosts.component.html',
  styleUrls: ['./dialog-hosts.component.scss'],
})
export class DialogHostsComponent implements OnInit {
  hosts: Host[];
  ruleName: string;

  constructor(
    public dialogRef: MatDialogRef<DialogHostsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit() {
    this.ruleName = this.dialogData.ruleName;
    this.hosts = this.dialogData.hosts;
  }

  close() {
    this.dialogRef.close();
  }
}
