<sbnb-button
  [loading]="loadingState"
  label=""
  type="primary"
  size="mini"
  class="chatgpt-button"
  [ngClass]="{ 'chatgpt-button--disabled': isDisabled }"
  [disabled]="isDisabled"
  [matTooltipDisabled]="isMobile$ | async"
  [matTooltip]="
    mode === AIMode.SUGGEST
      ? 'Automatically generate a reply with AI. You can review the reply before sending.'
      : 'Automatically improve this message with AI. You can review the improvements before sending.'
  "
  (clicked)="onButtonClick(isDisabled || loadingState)">
  <div class="reply-text" fxLayoutAlign="start center" fxLayoutGap="8px">
    <span *ngIf="mode === AIMode.SUGGEST">{{ loadingState ? 'Thinking...' : 'Suggest with AI' }}</span>
    <span *ngIf="mode === AIMode.IMPROVE">{{ loadingState ? 'Thinking...' : 'Improve with AI' }}</span>
    <div *ngIf="!loadingState" style="height: 18px">
      <img
        class="chatgpt-button--icon"
        src="/assets/iconography/magic.svg"
        onload="SVGInject(this)"
        alt="Generate a reply with AI" />
    </div>
  </div>
</sbnb-button>
