import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import {
  ReservationAgreement,
  ReservationAgreementService,
} from '@app/modules/guest-experience/services/reservation-agreements.service';
import { DialogDeleteGenericComponent } from '@app/shared/components/dialog-delete-generic/dialog-delete-generic.component';
import { NotificationType } from '@app/shared/interfaces';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  standalone: false,
  selector: 'dialog-rental-agrements-details',
  templateUrl: './rental-agreements-details-dialog.component.html',
})
export class RentalAgreementsDetailsDialogComponent {
  Features = Features;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      agreement: ReservationAgreement;
      showConversationButton: false;
    },
    public dialogRef: MatDialogRef<RentalAgreementsDetailsDialogComponent>,
    private service: ReservationAgreementService,
    private segmentIoService: SegmentIoService,
    private dialog: MatDialog,
    private toast: ToastNotificationsService,
    public userPermissionsService: UserPermissionsService
  ) {}

  deleteAgreement(): void {
    const dialogRef = this.dialog.open(DialogDeleteGenericComponent, {
      width: '500px',
      data: {
        name: 'this rental agreement',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service
          .deleteReservationAgreement(
            this.dialogData.agreement.reservation.reservation_code,
            this.dialogData.agreement.uuid
          )
          .subscribe(
            () => {
              this.segmentIoService.track(SegmentEvent.DeleteRentalAgreementTemplate, this.dialogData.agreement);
              this.toast.open('Rental agreement deleted successfully', 'Close', NotificationType.Success);
              this.dialogRef.close({ deleted: true });
            },
            () => {
              this.segmentIoService.track(SegmentEvent.DeleteRentalAgreementTemplateError, {});
              this.toast.open('Failed to delete rental agreement', 'Close', NotificationType.Error);
            }
          );
      }
    });
  }
}
