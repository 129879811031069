import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ReviewTag {
  key: string;
  description: string;
  category: 'cleanliness' | 'respect_house_rules' | 'communication';
}

@Component({
  selector: 'sbnb-review-tags',
  templateUrl: './review-tags.component.html',
  styleUrls: ['./review-tags.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ReviewTagsComponent {
  @Input() category: 'cleanliness' | 'respect_house_rules' | 'communication';
  @Input() selectedTags: string[] = [];
  @Input() disabled = false;
  @Output() tagsChange = new EventEmitter<string[]>();

  public readonly allTags: ReviewTag[] = [
    // Cleanliness tags
    { key: 'host_review_guest_positive_neat_and_tidy', description: 'Neat & tidy', category: 'cleanliness' },
    { key: 'host_review_guest_positive_kept_in_good_condition', description: 'Kept in good condition', category: 'cleanliness' },
    { key: 'host_review_guest_positive_took_care_of_garbage', description: 'Took care of garbage', category: 'cleanliness' },
    { key: 'host_review_guest_negative_ignored_checkout_directions', description: 'Ignored check-out directions', category: 'cleanliness' },
    { key: 'host_review_guest_negative_garbage', description: 'Excessive garbage', category: 'cleanliness' },
    { key: 'host_review_guest_negative_messy_kitchen', description: 'Messy kitchen', category: 'cleanliness' },
    { key: 'host_review_guest_negative_damage', description: 'Damaged property', category: 'cleanliness' },
    { key: 'host_review_guest_negative_ruined_bed_linens', description: 'Ruined bed linens', category: 'cleanliness' },

    // House Rules tags
    { key: 'host_review_guest_negative_arrived_early', description: 'Arrived too early', category: 'respect_house_rules' },
    { key: 'host_review_guest_negative_stayed_past_checkout', description: 'Stayed past checkout', category: 'respect_house_rules' },
    { key: 'host_review_guest_negative_unapproved_guests', description: 'Unapproved guests', category: 'respect_house_rules' },
    { key: 'host_review_guest_negative_unapproved_pet', description: 'Unapproved pet', category: 'respect_house_rules' },
    { key: 'host_review_guest_negative_did_not_respect_quiet_hours', description: 'Didn\'t respect quiet hours', category: 'respect_house_rules' },
    { key: 'host_review_guest_negative_unapproved_filming', description: 'Unapproved filming or photography', category: 'respect_house_rules' },
    { key: 'host_review_guest_negative_unapproved_event', description: 'Unapproved event', category: 'respect_house_rules' },
    { key: 'host_review_guest_negative_smoking', description: 'Smoking', category: 'respect_house_rules' },

    // Communication tags
    { key: 'host_review_guest_positive_helpful_messages', description: 'Helpful messages', category: 'communication' },
    { key: 'host_review_guest_positive_respectful', description: 'Respectful', category: 'communication' },
    { key: 'host_review_guest_positive_always_responded', description: 'Always responded', category: 'communication' },
    { key: 'host_review_guest_negative_unhelpful_messages', description: 'Unhelpful responses', category: 'communication' },
    { key: 'host_review_guest_negative_disrespectful', description: 'Disrespectful', category: 'communication' },
    { key: 'host_review_guest_negative_unreachable', description: 'Unreachable', category: 'communication' },
    { key: 'host_review_guest_negative_slow_responses', description: 'Slow responses', category: 'communication' },
  ];

  public get tags(): ReviewTag[] {
    return this.allTags.filter(tag => tag.category === this.category);
  }

  public isSelected(tagKey: string): boolean {
    return this.selectedTags.includes(tagKey);
  }

  public toggleTag(tagKey: string): void {
    if (this.disabled) return;

    const updatedTags = new Set(this.selectedTags);
    if (this.isSelected(tagKey)) {
      updatedTags.delete(tagKey);
    } else {
      updatedTags.add(tagKey);
    }
    this.tagsChange.emit(Array.from(updatedTags));
  }
}
