<section fxFlexFill fxLayout fxLayoutAlign="center center" class="add-manual-booking">
  <div class="fs-dialog-contents" style="margin: auto">
    <div
      class="overview__item"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap.lt-md="24px">
      <h2 fxFlex class="nomargin">Add guest details</h2>
      <span fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
        <img src="/assets/iconography/direct.svg" />
      </span>
    </div>

    <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="column" fxLayoutGap.lt-md="0">
      <div class="cdkScrollable" fxFlex fxLayout="column" fxLayoutGap="4px">
        <label class="form-label required" for="name">Guest name</label>

        <mat-form-field class="fw">
          <input
            type="text"
            name="name"
            matInput
            [placeholder]="'Eg. John Doe'"
            [(ngModel)]="quote.computed.guestName"
            (ngModelChange)="onGuestNameChange($event)"
            [matAutocomplete]="guestAutocomplete" />
          <mat-autocomplete #guestAutocomplete="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            <mat-option *ngIf="isLoadingGuestResults">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-option>

            <mat-option *ngFor="let option of guestSearchResults" [value]="option" class="multiline-mat-option">
              <div>
                <div class="text__medium">{{ option.first_name }} {{ option.last_name }}</div>
                <div class="text__small">{{ option.email }}</div>
                <div class="text__small">{{ option.phone }}</div>
              </div>
            </mat-option>

            <!-- <mat-option *ngIf="!isLoadingGuestResults && guestSearchResults.length === 0">
              <span>No guests found</span>
            </mat-option> -->
          </mat-autocomplete>
        </mat-form-field>

        <span
          *ngIf="!additionalGuestDetails"
          class="grass-darker text__xsmall clickable"
          style="position: relative; top: -20px"
          (click)="additionalGuestDetails = true">
          Add additional guest details
        </span>
      </div>

      <div fxFlex fxLayout="column" fxLayoutGap="4px">
        <label class="form-label required" for="email">Guest email</label>

        <mat-form-field class="fw">
          <input
            name="email"
            [(ngModel)]="quote.guest_details.email"
            (ngModelChange)="onGuestNameChange($event)"
            matInput
            [placeholder]="'Eg. guest@example.com'"
            [matAutocomplete]="guestAutocomplete" />
        </mat-form-field>
      </div>
    </div>

    <section [hidden]="!additionalGuestDetails">
      <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="column" fxLayoutGap.lt-md="0">
        <div fxFlex fxLayout="column" fxLayoutGap="4px">
          <label class="form-label" for="mobile">Mobile number</label>

          <mat-form-field class="fw">
            <ngx-mat-intl-tel-input
              [enablePlaceholder]="true"
              [enableSearch]="true"
              name="mobile_phone"
              [(ngModel)]="quote.guest_details.phone"
              [preferredCountries]="['us']"
              #phone></ngx-mat-intl-tel-input>
            <mat-hint>Eg. {{ phone.selectedCountry.placeHolder }}</mat-hint>
          </mat-form-field>
        </div>

        <div fxFlex fxLayout="column" fxLayoutGap="4px">
          <label class="form-label required" for="language">Language</label>

          <mat-form-field class="fw">
            <mat-select [(ngModel)]="quote.locale">
              <mat-option *ngFor="let option of languages" [value]="option.code">
                {{ option.english }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </section>

    <ng-container *ngIf="isPremium$ | async">
      <div
        *ngIf="this.quote.computed.guestName && this.quote.guest_details.email"
        fxLayout
        fxLayoutGap="24px"
        fxLayout.lt-md="column"
        style="margin: 24px 0">
        <div class="cdkScrollable" fxFlex fxLayout="column" fxLayoutGap="4px">
          <label class="form-label" for="name">Guest verification</label>

          <div class="guest-verification-badge verification-success" *ngIf="this.quote.computed.isSuperhogVerified">
            <span class="guest-verification-badge--icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>check-shield</title>
                <path
                  d="M20.418,0H3.582A1.5,1.5,0,0,0,2.091,1.5V7.763a18.256,18.256,0,0,0,9.675,16.178A.494.494,0,0,0,12,24a.5.5,0,0,0,.235-.058A18.261,18.261,0,0,0,21.909,7.763V1.5A1.5,1.5,0,0,0,20.418,0ZM17.9,7.945l-7.1,6.7a1,1,0,0,1-1.4-.024L7.027,12.225a1,1,0,1,1,1.421-1.407l1.682,1.7,6.395-6.027A1,1,0,1,1,17.9,7.945Z" />
              </svg>
            </span>
            <span class="guest-verification-badge--content">
              {{ this.quote.computed.guestName }}
            </span>
            <span class="guest-verification-badge--date">Verified</span>
          </div>

          <div class="guest-verification-badge" *ngIf="!this.quote.computed.isSuperhogVerified">
            <span class="guest-verification-badge--icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>check-shield</title>
                <path
                  d="M20.418,0H3.582A1.5,1.5,0,0,0,2.091,1.5V7.763a18.256,18.256,0,0,0,9.675,16.178A.494.494,0,0,0,12,24a.5.5,0,0,0,.235-.058A18.261,18.261,0,0,0,21.909,7.763V1.5A1.5,1.5,0,0,0,20.418,0ZM17.9,7.945l-7.1,6.7a1,1,0,0,1-1.4-.024L7.027,12.225a1,1,0,1,1,1.421-1.407l1.682,1.7,6.395-6.027A1,1,0,1,1,17.9,7.945Z" />
              </svg>
            </span>
            <span class="guest-verification-badge--content">
              No verification on file. Guest will need to verify when they pay.
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="row" fxLayoutGap.lt-md="8px">
      <div fxFlex>
        <sbnb-button [fullWidth]="true" type="secondary" label="Go Back" (clicked)="onGoBack()"></sbnb-button>
      </div>
      <div fxFlex>
        <sbnb-button
          [disabled]="
            isNullUndefinedOrEmptyString(quote.property_id) ||
            isNullUndefinedOrEmptyString(quote.checkin) ||
            isNullUndefinedOrEmptyString(quote.checkout) ||
            isNullUndefinedOrEmptyString(quote.computed.guestName) ||
            isNullUndefinedOrEmptyString(quote.guest_details.email)
          "
          [loading]="loadingState === LoadingState.Pending"
          [fullWidth]="true"
          label="Continue"
          (clicked)="onNext()"></sbnb-button>
      </div>
    </div>
  </div>
</section>
