<section *ngIf="localQuote" fxFlexFill fxLayout fxLayoutAlign="center center" class="add-manual-booking">
  <div class="fs-dialog-contents" style="margin: auto; margin-top: -24px">
    <div
      class="overview__item"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap.lt-md="24px">
      <h2 fxFlex class="nomargin">Set amount</h2>
    </div>

    <div>
      <p>
        Your current rate is calculated below for reference. Adjust values in the Quote column to customize your Quote.
        You can add any additional fees or discounts as desired.
      </p>
    </div>

    <div class="flex flex-col md:flex-row gap-0 md:gap-6 mb-6">
      <div class="flex-1">
        <label class="form-label required" for="property">Property</label>
        <sbnb-property-select-dropdown
          [selectedPropertyId]="localQuote.property_id"
          [properties]="properties"
          (selectedPropertyChanged)="onSelectedPropertyChanged($event)"
          (ngModelChange)="onRecalculate($event)"></sbnb-property-select-dropdown>
      </div>

      <div class="flex-1">
        <label class="form-label required" for="email">Guests</label>
        <button
          class="dropdown-btn w-full flex items-center justify-between gap-2"
          style="min-width: 200px"
          [matMenuTriggerFor]="numGuestsMenu">
          <span class="ellipsis">
            {{ localQuote.num_guests.adults }} guest, {{ localQuote.num_guests.children }} child,
            {{ localQuote.num_guests.pets }} pet
          </span>
          <div class="mat-select-arrow-wrapper">
            <div class="mat-select-arrow"></div>
          </div>
        </button>

        <mat-menu #numGuestsMenu="matMenu" (closed)="onRecalculate()">
          <section (click)="$event.preventDefault(); $event.stopPropagation()" style="padding: 18px 18px 0px 18px">
            <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="column" fxLayoutGap.lt-md="0">
              <div fxFlex fxLayout="column" fxLayoutGap="4px">
                <label class="form-label" for="adults">Adults</label>

                <mat-form-field class="fw">
                  <input name="adults" [(ngModel)]="localQuote.num_guests.adults" type="number" matInput />
                </mat-form-field>
              </div>

              <div fxFlex fxLayout="column" fxLayoutGap="4px">
                <label class="form-label" for="children">Children</label>

                <mat-form-field class="fw">
                  <input name="children" [(ngModel)]="localQuote.num_guests.children" type="number" matInput />
                </mat-form-field>
              </div>

              <div fxFlex fxLayout="column" fxLayoutGap="4px">
                <label class="form-label" for="infants">Infants</label>

                <mat-form-field class="fw">
                  <input name="infants" [(ngModel)]="localQuote.num_guests.infants" type="number" matInput />
                </mat-form-field>
              </div>

              <div fxFlex fxLayout="column" fxLayoutGap="4px">
                <label class="form-label" for="pets">Pets</label>

                <mat-form-field class="fw">
                  <input name="pets" matInput type="number" [(ngModel)]="localQuote.num_guests.pets" />
                </mat-form-field>
              </div>
            </div>
          </section>
        </mat-menu>
      </div>
    </div>

    <div class="flex flex-col md:flex-row mb-4">
      <div class="flex-1" *ngIf="localQuote.property_id">
        <label class="form-label required" for="checkin">Check-in / Check-out</label>
        <ng-container *ngIf="!localQuote.checkin || !localQuote.checkout">
          <div [ngTemplateOutlet]="dateCalendar"></div>
        </ng-container>
        <mat-menu #datesMenu="matMenu" class="custom-quotes-mat-menu">
          <section class="date-picker-within-menu">
            <div [ngTemplateOutlet]="dateCalendar"></div>
          </section>
        </mat-menu>

        <ng-container *ngIf="localQuote.checkin && localQuote.checkout">
          <button class="dropdown-btn w-full flex items-center justify-between gap-2" [matMenuTriggerFor]="datesMenu">
            <span class="ellipsis">
              {{ localQuote.checkin | date: 'mediumDate' }}-{{ localQuote.checkout | date: 'mediumDate' }} ({{
                localQuote.checkin | numNights: localQuote.checkout
              }}
              night stay)
            </span>
            <div class="mat-select-arrow-wrapper">
              <div class="mat-select-arrow"></div>
            </div>
          </button>
        </ng-container>
      </div>
      <ng-template #dateCalendar>
        <div class="date-picker-container">
          <sbnb-date-picker
            (click)="$event.stopPropagation()"
            *ngIf="reservations"
            [rangeSelect]="true"
            [initialDateRange]="[localQuote.checkin, localQuote.checkout]"
            [reservations]="reservations"
            (datesSelected)="datesSelected($event)"></sbnb-date-picker>
        </div>
      </ng-template>
    </div>

    <ng-container *ngIf="canShowAmountTable(); else amountTableLoading">
      <div>
        <section fxFlexFill fxLayout fxLayoutAlign="center center" class="margin-top: 24px;">
          <div class="fs-dialog-contents direct-quote" style="margin: auto" fxLayout="row">
            <section fxLayout="column" fxFlex="grow">
              <div fxLayout="row">
                <div class="row--header description-column" fxLayout="column" fxLayoutAlign="start left">Item</div>
                <div class="row--header amount-column" fxLayout="column" fxLayoutAlign="start center">
                  Current rates
                </div>
                <div
                  class="row--header quote--column"
                  fxFlexOffset="1"
                  fxLayout="column"
                  fxLayoutGap="14px"
                  fxLayoutAlign="start center">
                  Quote
                </div>
              </div>

              <div fxLayout="row">
                <div class="column description-column" fxLayout="column" fxLayoutAlign="start left">
                  {{ localQuote?.computed?.pricing?.original?.nightly?.title }}

                  <ng-container *ngIf="localQuote?.computed?.pricing?.original?.nightly?.subtitle">
                    <small class="text-grass-darker">
                      {{ localQuote?.computed?.pricing?.original?.nightly?.subtitle }}
                    </small>
                  </ng-container>
                </div>
                <div class="column amount-column" fxLayout="column" fxLayoutAlign="start center">
                  {{
                    localQuote?.computed?.pricing?.original?.nightly?.value
                      | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                  }}
                </div>
                <div
                  class="column quote--column"
                  fxFlexOffset="1"
                  fxLayout="column"
                  fxLayoutGap="14px"
                  fxLayoutAlign="start center">
                  <mat-form-field class="nopadding" style="width: 80%; text-align: right">
                    <input
                      matInput
                      class="text-right amount-input"
                      type="number"
                      [(ngModel)]="localQuote.computed.pricing.estimated.nightly.value"
                      [placeholder]="
                        localQuote?.computed?.pricing?.original?.nightly | subTotalMinusDiscounts: localQuote.discounts
                      "
                      (ngModelChange)="priceModelChange($event)"
                      (blur)="
                        localQuote.computed.pricing.estimated.nightly.value
                          ? (localQuote.computed.pricing.estimated.nightly.value =
                              localQuote.computed.pricing.estimated.nightly.value.toFixed(2))
                          : null
                      " />
                    <span matPrefix>{{ getCurrencySymbol(localQuote.currency || defaultCurrency) }}&nbsp;</span>
                  </mat-form-field>
                </div>
              </div>

              <ng-container *ngIf="localQuote.computed.pricing.estimated.fees">
                <div
                  fxLayout="row"
                  *ngFor="
                    let fee of localQuote.computed.pricing.estimated.fees | keyvalue: originalOrder;
                    trackBy: trackByFn
                  ">
                  <ng-container
                    *ngIf="
                      fee.key !== PricePerExtraPerson &&
                      localQuote.computed.pricing.original.fees[fee.key].value !== '0.00'
                    ">
                    <div class="column description-column" fxLayout="column" fxLayoutAlign="start left">
                      {{ fee.key | humanize }}
                    </div>
                    <div class="column amount-column" fxLayout="column" fxLayoutAlign="start center">
                      <span
                        *ngIf="
                          localQuote.computed.pricing.original.fees &&
                          localQuote.computed.pricing.original.fees[fee.key].value
                            ? localQuote.computed.pricing.original.fees[fee.key].value
                            : null as feeValue
                        ">
                        {{ feeValue | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2' }}
                      </span>
                    </div>
                    <div
                      class="column quote--column"
                      fxFlexOffset="1"
                      fxLayout="column"
                      fxLayoutGap="14px"
                      fxLayoutAlign="start center">
                      <mat-form-field class="nopadding" style="width: 80%; text-align: right">
                        <input
                          matInput
                          type="number"
                          class="text-right amount-input"
                          [(ngModel)]="localQuote.computed.pricing.estimated.fees[fee.key].value"
                          (ngModelChange)="priceModelChange($event)"
                          (blur)="
                            localQuote.computed.pricing.estimated.fees[fee.key].value =
                              localQuote.computed.pricing.estimated.fees[fee.key].value.toFixed(2)
                          "
                          [placeholder]="
                            localQuote.computed.pricing.estimated.fees &&
                            localQuote.computed.pricing.estimated.fees[fee.key] &&
                            localQuote.computed.pricing.estimated.fees[fee.key].value
                              ? localQuote.computed.pricing.estimated.fees[fee.key].value
                              : ''
                          " />
                        <span matPrefix>{{ getCurrencySymbol(localQuote.currency || defaultCurrency) }}&nbsp;</span>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="localQuote.computed.pricing.customFees">
                <div
                  fxLayout="row"
                  *ngFor="
                    let fee of localQuote.computed.pricing.customFees | keyvalue: originalOrder;
                    trackBy: trackByFn
                  ">
                  <div class="column description-column" fxLayout="column" fxLayoutAlign="start left">
                    <span>
                      {{ fee.key | humanize }}
                      <a
                        style="vertical-align: middle; margin-left: 8px; cursor: pointer"
                        (click)="onRemoveFee(fee.key)">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="x-button">
                          <path
                            d="M3.75 16.25L16.25 3.75"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke="#B3AFBB"></path>
                          <path
                            d="M3.75 3.75L16.25 16.25"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke="#B3AFBB"></path>
                        </svg>
                      </a>
                    </span>
                  </div>
                  <div class="column amount-column" fxLayout="column" fxLayoutAlign="start center">
                    <span
                      *ngIf="
                        localQuote.computed.pricing.customFees && localQuote.computed.pricing.customFees[fee.key].value
                          ? localQuote.computed.pricing.customFees[fee.key].value
                          : null as feeValue
                      ">
                      {{ feeValue | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2' }}
                    </span>
                  </div>
                  <div
                    class="column quote--column"
                    fxFlexOffset="1"
                    fxLayout="column"
                    fxLayoutGap="14px"
                    fxLayoutAlign="start center">
                    <mat-form-field class="nopadding" style="width: 80%; text-align: right">
                      <input
                        matInput
                        type="number"
                        class="text-right amount-input"
                        [(ngModel)]="localQuote.computed.pricing.customFees[fee.key].value"
                        (ngModelChange)="priceModelChange($event)"
                        (blur)="
                          localQuote.computed.pricing.customFees[fee.key].value =
                            localQuote.computed.pricing.customFees[fee.key].value.toFixed(2)
                        "
                        [placeholder]="
                          localQuote.computed.pricing.customFees &&
                          localQuote.computed.pricing.customFees[fee.key] &&
                          localQuote.computed.pricing.customFees[fee.key].value
                            ? localQuote.computed.pricing.customFees[fee.key].value
                            : ''
                        " />
                      <span matPrefix>{{ getCurrencySymbol(localQuote.currency || defaultCurrency) }}&nbsp;</span>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>

              <div fxLayout="row">
                <div class="column" fxFlex="65" fxLayout="column" fxLayoutAlign="start left">
                  <mat-form-field class="nopadding" style="width: 80%">
                    <input
                      placeholder="Additional fee or discount"
                      matInput
                      class="text-right amount-input"
                      type="text"
                      inputmode="text"
                      [formControl]="newFee" />
                    <button
                      (click)="onAddFee(); $event.stopPropagation()"
                      style="height: 24px; width: 24px"
                      [disabled]="!newFee.value || newFee.value === ''"
                      mat-button
                      matSuffix
                      mat-icon-button
                      [ngClass]="{ 'bg-grass-darker text-white': !newFee.value || newFee.value !== '' }"
                      aria-label="add">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div
                  class="column quote--column"
                  fxFlexOffset="1"
                  fxLayout="column"
                  fxLayoutGap="14px"
                  fxLayoutAlign="start center">
                  <mat-form-field class="nopadding" style="width: 80%; text-align: right">
                    <input
                      matInput
                      class="text-right amount-input"
                      autocomplete="off"
                      type="number"
                      [(ngModel)]="newFeeAmount"
                      type="number"
                      (blur)="newFeeAmount = newFeeAmount.toFixed(2)" />

                    <span matPrefix>{{ getCurrencySymbol(localQuote.currency || defaultCurrency) }}&nbsp;</span>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" [ngStyle]="{ 'min-height': '60px' }">
                <div class="column description-column" fxLayout="column" fxLayoutAlign="start left">
                  Estimated taxes
                </div>
                <div class="column amount-column" fxLayout="column" fxLayoutAlign="start center">
                  {{
                    localQuote?.computed?.pricing?.original?.tax
                      | currency: localQuote.currency || defaultCurrency : 'symbol'
                  }}
                </div>
                <div
                  class="column quote--column"
                  fxFlexOffset="1"
                  fxLayout="column"
                  fxLayoutGap="14px"
                  fxLayoutAlign="start center"
                  [ngStyle]="{ 'align-items': 'end', 'padding-right': '24px' }">
                  <ng-container *ngIf="isEstimatingPricing">
                    <mat-spinner [diameter]="16"></mat-spinner>
                  </ng-container>
                  <ng-container *ngIf="!isEstimatingPricing">
                    <ng-container
                      *ngIf="
                        localQuote.computed && localQuote.computed.pricing && localQuote.computed.pricing.estimated;
                        else showOriginalTax
                      ">
                      {{
                        localQuote?.computed?.pricing?.estimated?.tax
                          | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                      }}
                    </ng-container>

                    <ng-template #showOriginalTax>
                      {{
                        localQuote?.computed?.pricing?.original?.tax
                          | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                      }}
                    </ng-template>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="
                  localQuote?.computed?.pricing?.estimated && +localQuote.computed.pricing.estimated.service_fee !== 0
                "
                fxLayout="row"
                [ngStyle]="{ 'min-height': '60px' }">
                <div class="column description-column" fxLayout="column" fxLayoutAlign="start left">
                  Guest service fee
                </div>
                <div class="column amount-column" fxLayout="column" fxLayoutAlign="start center">
                  {{
                    localQuote.computed.pricing.original.service_fee
                      | currency: localQuote.currency || defaultCurrency : 'symbol'
                  }}
                </div>
                <div
                  class="column quote--column"
                  fxFlexOffset="1"
                  fxLayout="column"
                  fxLayoutGap="14px"
                  fxLayoutAlign="start center"
                  [ngStyle]="{ 'align-items': 'end', 'padding-right': '24px' }">
                  <ng-container *ngIf="isEstimatingPricing">
                    <mat-spinner [diameter]="16"></mat-spinner>
                  </ng-container>
                  <ng-container *ngIf="!isEstimatingPricing">
                    <ng-container
                      *ngIf="
                        localQuote.computed && localQuote.computed.pricing.estimated.service_fee;
                        else showOriginalGuestServiceFee
                      ">
                      {{
                        localQuote?.computed?.pricing?.estimated?.service_fee
                          | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                      }}
                    </ng-container>

                    <ng-template #showOriginalGuestServiceFee>
                      {{
                        localQuote?.computed?.pricing?.original?.service_fee
                          | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                      }}
                    </ng-template>
                  </ng-container>
                </div>
              </div>

              <div fxLayout="row" [ngStyle]="{ 'min-height': '60px' }">
                <div class="row--footer description-column" fxLayout="column" fxLayoutAlign="start left">
                  Total cost to guest
                </div>
                <div class="row--footer amount-column" fxLayout="column" fxLayoutAlign="start center">
                  {{
                    localQuote?.computed?.pricing?.original?.total
                      | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                  }}
                </div>
                <div
                  class="row--footer quote--column"
                  [ngStyle]="{ 'align-items': 'end', 'padding-right': '24px' }"
                  fxFlexOffset="1"
                  fxLayout="column"
                  fxLayoutGap="14px"
                  fxLayoutAlign="start center">
                  <ng-container *ngIf="isEstimatingPricing">
                    <mat-spinner [diameter]="16"></mat-spinner>
                  </ng-container>
                  <ng-container *ngIf="!isEstimatingPricing">
                    <b
                      *ngIf="
                        localQuote.computed && localQuote?.computed?.pricing?.original?.total;
                        else showOriginalCostToGuest
                      ">
                      {{
                        localQuote?.computed?.pricing?.estimated?.total
                          | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                      }}
                    </b>

                    <ng-template #showOriginalCostToGuest>
                      <b>
                        {{
                          localQuote?.computed?.pricing?.original?.total
                            | currency: localQuote.currency || defaultCurrency : 'symbol' : '1.2-2'
                        }}
                      </b>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </section>
          </div>
        </section>

        <div fxLayout fxLayoutGap="24px" fxLayout.lt-md="row" fxLayoutGap.lt-md="8px">
          <div style="display: flex; flex-grow: 1; flex-direction: row-reverse">
            <sbnb-button
              [fullWidth]="false"
              label="Continue"
              (clicked)="onNext()"
              style="margin-left: 12px"></sbnb-button>

            <sbnb-button
              [fullWidth]="false"
              type="secondary"
              [label]="'Reset rates'"
              (clicked)="onReset()"></sbnb-button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #amountTableLoading>
      <div class="fs-dialog-contents" *ngIf="isEstimatingPricing">
        <mat-spinner diameter="32"></mat-spinner>
      </div>
    </ng-template>
  </div>

  <ng-template #loadingTemplate>
    <div class="fs-dialog-contents">
      <mat-spinner diameter="32"></mat-spinner>
    </div>
  </ng-template>
</section>
