import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  DirectService,
  SecurityDeposit,
  SecurityDepositClaim,
  SecurityDepositClaimIncidentType,
} from '@app/modules/direct/services/direct.service';
import { Reservation } from '@app/shared/interfaces';

@Component({
  selector: 'dialog-claim-security-deposit',
  templateUrl: './dialog-claim-security-deposit.component.html',
})
export class DialogClaimSecurityDepositComponent {
  securityDepositClaim: SecurityDepositClaim = {
    amount: 0,
    incident_type: SecurityDepositClaimIncidentType.ACCIDENTAL_DAMAGE,
    incident_description: '',
    images: [],
  };

  submitting = false;
  userConfirmation = false;
  daysUntilRelease: number;

  constructor(
    private dialogRef: MatDialogRef<DialogClaimSecurityDepositComponent>,
    private directService: DirectService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { reservation: Reservation; securityDeposit: SecurityDeposit }
  ) {
    this.daysUntilRelease = this.calculateDaysUntilRelease();
  }

  close(): void {
    this.dialogRef.close();
  }

  private calculateDaysUntilRelease(): number {
    const releaseDate = new Date(this.dialogData.securityDeposit.release_at);
    const today = new Date();
    const timeDiff = releaseDate.getTime() - today.getTime();
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  }

  claim(): void {
    if (!this.userConfirmation) {
      return;
    }

    this.submitting = true;

    this.directService
      .claimSecurityDeposit(this.dialogData.reservation.uuid, this.securityDepositClaim)
      .subscribe(() => {
        this.submitting = false;
        this.close();
      });
  }
}
