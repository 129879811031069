/// <reference types="@types/google.maps" />

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { config } from '@app/core/app-config';
import { Address } from '@app/shared/interfaces';
import { ScriptLoaderService } from '@app/shared/services/script-loader/script-loader.service';

@Component({
  standalone: false,
  selector: 'sbnb-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements AfterViewInit {
  @ViewChild('addresstext') addresstext: ElementRef;
  autocompleteInput: string;

  @Input() placeholder: string | null = null;
  @Input() adressType: 'establishment' | 'address' | 'geocode' = 'address';
  @Input() existingAddressLabel: string;
  @Output() setAddress: EventEmitter<Address> = new EventEmitter();

  constructor(private scriptLoader: ScriptLoaderService) { }

  ngAfterViewInit() {
    this.scriptLoader
      .load({
        name: 'Google places',
        src: `https://maps.googleapis.com/maps/api/js?libraries=places&key=${config.GOOGLE_PLACES_API_KEY}`,
      })
      .subscribe(() => {
        if (this.existingAddressLabel) {
          this.autocompleteInput = this.existingAddressLabel;
        }

        if (typeof google !== 'undefined') {
          this.getPlaceAutocomplete();
        }
      });
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      types: [this.adressType],
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();

      this.parseAddressFromPlace(place);
    });
  }

  parseAddressFromPlace(place: google.maps.places.PlaceResult) {
    let address1 = '';
    const address2 = '';
    let postcode = '';
    let city = '';
    let state = '';
    let country = '';

    if (!place.address_components) {
      return;
    }

    for (const component of place.address_components) {
      for (const componentType of component.types) {
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.long_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          // case 'postal_code_suffix': {
          //   postcode = `${postcode}-${component.long_name}`;
          //   break;
          // }

          case 'sublocality': {
            city = component.long_name;
            break;
          }

          case 'locality': {
            city = component.long_name;
            break;
          }

          case 'postal_town': {
            city = component.long_name;
            break;
          }

          case 'administrative_area_level_1': {
            state = component.long_name;
            break;
          }

          case 'country': {
            country = component.short_name;
            break;
          }
        }
      }
    }

    this.setAddress.emit({
      line1: address1,
      line2: address2,
      postalCode: postcode,
      city,
      state,
      country,
    });
  }
}
