import { Component, Input } from '@angular/core';
import { ProgressBar, ProgressBarColourMapper } from './progress-bar.component.interface';

@Component({
  selector: 'hospitable-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() progressBar: ProgressBar;

  ProgressBarColourMapper = ProgressBarColourMapper;
}
