<div>
  <div class="flex flex-col" style="gap: 24px">
    <div class="flex" style="gap: 12px">
      <sbnb-profile-img *ngIf="showGuestPicture" [src]="message.from.thumbnail_url"></sbnb-profile-img>
      <div class="flex flex-col" style="gap: 6px" *ngIf="showReviewContent">
        <div class="review">"{{ message.translated_public_review ?? message.public_review }}"</div>
        <div *ngIf="message.private_review" class="private-feedback" matTooltip="Private feedback, visible only to you">
          <span>
            <sbnb-icon icon="padlock" [size]="12" class="text-night-lighter"></sbnb-icon>
          </span>
          <span>"{{ message.translated_private_review ?? message.private_review }}"</span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!message.sent_response; else readonlyReply">
      <div class="flex flex-col" style="gap: 12px">
        <sbnb-textarea [maxRows]="8" [formControl]="reply" placeholder="Write a review reply..."></sbnb-textarea>

        <div class="flex justify-end items-center" style="gap: 12px">
          <!-- AI suggestion section -->
          <ng-container *ngIf="message.ai_proposed_response?.length">
            <span
              *ngIf="message.ai_proposed_response?.length && reply.value === message.ai_proposed_response; else writeWithAi"
              class="text__xsmall night-lighter text-center"
              style="flex-shrink: 10">
              This review reply was written with AI
              <ng-container *ngTemplateOutlet="aiIcon"></ng-container>
            </span>
            <ng-template #writeWithAi>
              <sbnb-button size="mini" type="text-secondary" (click)="useAIReply()" [disabled]="submitting$ | async">
                Write reply with AI
                <ng-container *ngTemplateOutlet="aiIcon"></ng-container>
              </sbnb-button>
            </ng-template>
          </ng-container>

          <sbnb-button
            size="mini"
            type="secondary"
            (click)="clearReply()"
            [disabled]="!reply.value || !reply.valid || (submitting$ | async)">
            Clear
          </sbnb-button>
          <sbnb-button size="mini" [disabled]="!reply.valid || (submitting$ | async)" (click)="submitReviewReply()">
            {{ (submitting$ | async) ? 'Replying...' : 'Reply' }}
          </sbnb-button>
        </div>
      </div>
    </ng-container>
    <!-- Already submitted, readonly state -->
    <ng-template #readonlyReply>
      <sbnb-textarea [maxRows]="8" [formControl]="reply"></sbnb-textarea>
    </ng-template>
  </div>
</div>

<ng-template #aiIcon>
  <img height="12px" width="12px" src="/assets/iconography/magic.svg" />
</ng-template>
