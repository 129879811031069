<!-- All plans (that can reach this flow), is cancelling, apply cancellation policy -->
<div *ngIf="isCancelling" class="initiated-by-container">
  <div *ngIf="initiatedBy === 'guest' && !refundDetails.cancellation_policy_applied">
    <h2 class="nomargin">Cancel this booking without issuing a refund</h2>

    <p>This booking has a non-refundable cancellation policy.</p>

    <div class="buttons-container" fxLayoutAlign="end center">
      <sbnb-button label="Continue" (clicked)="continue.emit(0)"></sbnb-button>
    </div>
  </div>

  <div *ngIf="initiatedBy === 'guest' && refundDetails.cancellation_policy_applied">
    <h2 class="nomargin">Cancel this booking according to the Cancellation Policy</h2>

    <p>
      According to the cancellation policy in place for this booking the guest will be refunded
      <strong>{{ refundDetails.cancellation_policy_amount_formatted }}</strong>
      .
    </p>

    <div class="buttons-container" fxLayoutAlign="end center">
      <sbnb-button label="Continue" (clicked)="continue.emit(+refundDetails.cancellation_policy_amount)"></sbnb-button>
    </div>
  </div>

  <div *ngIf="initiatedBy === 'host'">
    <h2 class="nomargin">Cancel this booking and issue a full refund</h2>

    <p>
      Host-initiated cancellations are fully-refundable to the guest. The full amount of
      <strong>{{ refundDetails.max_refundable_amount_formatted }}</strong>
      paid by the guest will be refunded.
    </p>

    <div class="buttons-container" fxLayoutAlign="end center">
      <sbnb-button label="Continue" (clicked)="continue.emit(refundDetails.max_refundable_amount)"></sbnb-button>
    </div>
  </div>
</div>

<!-- All plans (that can reach this flow), issue a refund, cancellation policy does not apply since it's been applied on the cancellation itself -->
<div *ngIf="!isCancelling" class="initiated-by-container">
  <h2 class="nomargin">How much would you like to refund?</h2>

  <p *ngIf="directPlan !== 'premium'">
    You may refund any amount, up to 100% of the amount paid by the guest, at your discretion.
  </p>
  <p *ngIf="directPlan === 'premium'">
    You may refund any amount, up to 100% of the amount paid by the guest, provided you have sufficient balance to cover
    the refund.
  </p>

  <p>We'll credit the exact amount to the guest's payment method.</p>

  <div *ngIf="refundDetails.payments?.length > 0" class="refund-table">
    <div fxLayout *ngFor="let payment of refundDetails.payments" class="payment">
      <span fxFlex>{{ payment.label }}</span>
      <span
        [class.grass-darker]="payment.amount_formatted[0] !== '-'"
        [class.raspberry-darker]="payment.amount_formatted[0] === '-'">
        {{ payment.amount_formatted }}
      </span>
    </div>

    <div fxLayout class="max-refundable-container">
      <span fxFlex>Maximum refundable amount:</span>
      <span class="grass-darker">{{ refundDetails.max_refundable_amount_formatted }}</span>
    </div>
  </div>

  <div fxLayout fxLayoutAlign="start center" class="refund-amount">
    <span fxFlex>Refund amount:</span>

    <mat-form-field class="nopadding" style="width: 180px">
      <input
        matInput
        class="text-right amount-input"
        type="number"
        autocomplete="off"
        inputmode="decimal"
        placeholder="0 - {{ refundDetails.max_refundable_amount }}"
        [(ngModel)]="amountToRefund" />
      <span matPrefix class="prefix-bg">{{ refundDetails.max_refundable_amount_formatted[0] }}</span>
    </mat-form-field>
  </div>

  <div class="buttons-container" fxLayoutAlign="end center">
    <sbnb-button
      label="Continue"
      [disabled]="amountToRefund === null || amountToRefund < 0 || amountToRefund > refundDetails.max_refundable_amount"
      (clicked)="continue.emit(amountToRefund)"></sbnb-button>
  </div>
</div>
