export enum BadgeType {
  Primary = 'bg-pink-dark text-white',
  Success = 'bg-green-dark text-white',
  Danger = 'bg-red-dark text-white',
}

export enum BadgeSize {
  XXSmall = 'text-xxs',
  XSmall = 'text-xs',
  Small = 'text-xs md:text-sm',
  Medium = 'text-sm md:text-md',
  Large = 'text-md md:text-lg',
  XLarge = 'text-lg md:text-xl',
}
