import { Component, Input } from '@angular/core';
import { BadgeSize, BadgeType } from './badge.component.interface';

@Component({
  selector: 'hospitable-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeMonoComponent {
  @Input() type: BadgeType = BadgeType.Primary;
  @Input() size: BadgeSize = BadgeSize.XSmall;
  @Input() label: string;
}
