<div class="flex w-full items-center gap-2">
  <sbnb-icon
    icon="other"
    [size]="16"
    [style.opacity]="scoped.includes('hospitable') || listingCounts.hospitable > 0 ? 1 : 0.3"
    [matTooltip]="
      showTooltips
        ? (listingCounts.hospitable >= 1 ? 1 : 0) +
          ' Hospitable ' +
          (listingCounts.hospitable === 0 ? 'listings' : 'listing')
        : null
    "></sbnb-icon>

  <sbnb-icon
    icon="airbnb"
    [size]="16"
    [style.opacity]="scoped.includes('airbnb') || listingCounts.airbnb > 0 ? 1 : 0.3"
    [matTooltip]="
      showTooltips
        ? listingCounts.airbnb + ' ' + 'Airbnb ' + (listingCounts.airbnb === 1 ? 'listing' : 'listings')
        : null
    "></sbnb-icon>

  <sbnb-icon
    icon="homeaway"
    [size]="16"
    [style.opacity]="scoped.includes('homeaway') || listingCounts.homeaway > 0 ? 1 : 0.3"
    [matTooltip]="
      showTooltips
        ? listingCounts.homeaway + ' ' + 'Vrbo ' + (listingCounts.homeaway === 1 ? 'listing' : 'listings')
        : null
    "></sbnb-icon>

  <sbnb-icon
    icon="booking"
    [size]="16"
    [style.opacity]="scoped.includes('booking') || listingCounts.booking > 0 ? 1 : 0.3"
    [matTooltip]="
      showTooltips
        ? listingCounts.booking + ' ' + 'Booking.com ' + (listingCounts.booking === 1 ? 'listing' : 'listings')
        : null
    "></sbnb-icon>

  <sbnb-icon
    icon="agoda-icon"
    [size]="16"
    [style.opacity]="scoped.includes('agoda') || listingCounts.agoda > 0 ? 1 : 0.3"
    [matTooltip]="
      showTooltips ? listingCounts.agoda + ' ' + 'Agoda ' + (listingCounts.agoda === 1 ? 'listing' : 'listings') : null
    "></sbnb-icon>
</div>
