import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
@Component({
  standalone: false,
  selector: 'sbnb-guest-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './guest-summary.component.html',
  styleUrls: ['./guest-summary.component.scss'],
})
export class GuestSummaryComponent implements OnInit {
  @Input() summary: string[];

  constructor() { }

  ngOnInit(): void { }
}
