import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingMonoComponent } from './loading.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [LoadingMonoComponent],
  exports: [LoadingMonoComponent],
})
export class LoadingMonoComponentModule {}
