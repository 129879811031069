import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PropertyList } from '@app/shared/models/property-list';
import { DialogPropertiesComponent } from '../dialog-properties/dialog-properties.component';

@Component({
  standalone: false,
  selector: 'sbnb-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
})
export class PropertiesComponent implements OnInit {
  @Input() ruleName: string;
  @Input() properties: PropertyList[];
  @Input() overrideLength: number;
  @Input() allProperties: boolean;
  @Input() labelForAllProperties: string = 'All'; // Can be used to override the default 'All' label
  @Input() showIndividualAsWellAsAll: boolean = false; // Sometimes we need to show the 'All' label as well as the individually selected properties. In Direct we use 'Default' but want to show the individually selected properties too

  tooltip: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.checkForRequiredInputs();
    this.buildTooltip();

    if (!this.overrideLength) {
      this.overrideLength = this.properties.length;
    }
  }

  checkForRequiredInputs() {
    if (
      this.allProperties === null ||
      this.allProperties === undefined ||
      this.properties === null ||
      this.properties === undefined
    ) {
      throw new TypeError('The inputs ’properties’ and ’allProperties’ are required');
    }
  }

  buildTooltip(): void {
    this.tooltip = '';

    if ((this.allProperties && !this.showIndividualAsWellAsAll) || !this.properties || this.properties.length === 0) {
      return;
    }

    if (this.overrideLength > 10) {
      this.tooltip = 'See more properties';
      return;
    }

    this.properties.forEach((property: PropertyList) => {
      this.tooltip += `${property.name} \n`;
    });
  }

  openPropertiesModal(): void {
    if ((this.allProperties && !this.showIndividualAsWellAsAll) || !this.properties || this.properties.length === 0) {
      return;
    }

    this.dialog.open(DialogPropertiesComponent, {
      width: '486px',
      maxHeight: '90vh',
      data: {
        properties: this.properties,
        propertyCount: this.overrideLength,
        ruleName: this.ruleName,
      },
    });
  }
}
