import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface RefundDetails {
  payments: {
    label: string; // Booking amount paid by the guest / Partial refund issued on Tue 24th Aug, 2022
    amount: number; // 560
    amount_formatted: string; // £560
  }[];
  max_refundable_amount: number; // 538
  max_refundable_amount_formatted: string; // £538
  payment_enabled: boolean;
  payment_provider: string; // stripe-oauth / adyen
  cancellation_policy_amount: string; // 35.88
  cancellation_policy_amount_formatted: string; // $35.88
  cancellation_policy_applied: boolean;
  cancellation_policy_percentage: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationCancellationRefundService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  fetchRefundDetails(reservationUuid: string): Observable<RefundDetails | null> {
    return this.http.get(`${this.baseUrl}/reservations/${reservationUuid}/refund-details`).pipe(
      map((res: { data: RefundDetails }) => {
        if (res.data?.max_refundable_amount_formatted) {
          return res.data;
        } else {
          return null;
        }
      }),
      catchError((err) => of(null))
    );
  }

  fetchCancellationQuote(reservationUuid: string, initiated_by: string): Observable<RefundDetails | null> {
    return this.http.post(`${this.baseUrl}/reservations/${reservationUuid}/cancel-quote`, { initiator: initiated_by }).pipe(
      map((res: { data: RefundDetails }) => res.data),
      catchError((err) => of(null))
    );
  }

  // manual
  // non payment processed bookings
  // basic
  // premium
  // existing bookings (of all types)
  cancelReservation(
    reservationUuid: string,
    initiated_by: 'host' | 'guest' = 'host',
    waive_fees?: boolean,
    reason?: 'invalid_cc' | 'no_show',
    skip_interceptor = false,
  ) {
    return this.http.put(`${this.baseUrl}/reservations/${reservationUuid}/cancel`, {
      initiated_by,
      waive_fees,
      reason,
    }, {
        headers: {
          'X-Skip-Interceptor': skip_interceptor ? 'true' : 'false',
        },
    });
  }

  issueRefund(reservationUuid: string, initiatedBy: string, amountToRefund: number): Observable<any> {
    return this.http.put(`${this.baseUrl}/reservations/${reservationUuid}/refund`, {
      issued_by: initiatedBy,
      refund_amount: amountToRefund,
    });
  }

  adhocChargeGuest(reserationUuid: string, chargeAmount: number, chargeName: string, chargeNoteToGuest: string) {
    return this.http.put(`${this.baseUrl}/reservations/${reserationUuid}/charge`, {
      charge_amount: chargeAmount,
      reason: chargeName,
      note_to_guest: chargeNoteToGuest,
    });
  }

  // cancelAndRefund(reservationUuid: string, initiatedBy: string, amountToRefund: number): Observable<any> {
  //   return this.http.put(`${this.baseUrl}/reservations/${reservationUuid}/cancel`, {}).pipe(
  //     mergeMap((res) => {
  //       return this.http.put(`${this.baseUrl}/reservations/${reservationUuid}/refund`, {
  //         issued_by: initiatedBy,
  //         refund_amount: amountToRefund,
  //       });
  //     }),
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }
}
