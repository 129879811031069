import { Component, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DirectService, SecurityDeposit, SecurityDepositStatus } from '@app/modules/direct/services/direct.service';
import { DialogClaimSecurityDepositComponent } from '@app/shared/components/dialog-claim-security-deposit/dialog-claim-security-deposit.component';
import { Reservation } from '@app/shared/interfaces';
import { DeviceDetectionService } from '@app/shared/services/device-detection/device-detection.service';

@Component({
  standalone: false,
  selector: 'sbnb-security-deposit-sidebar',
  templateUrl: './security-deposit-sidebar.component.html',
})
export class SecurityDepositSidebarComponent implements OnInit {
  @Input() reservation: Reservation;

  securityDeposit: SecurityDeposit | null = null;
  SecurityDepositStatus = SecurityDepositStatus;

  isMobile = toSignal(inject(DeviceDetectionService).isMobileViewport());

  constructor(
    private dialog: MatDialog,
    private directService: DirectService
  ) {}

  ngOnInit() {
    this.fetchSecurityDeposit();
  }

  private fetchSecurityDeposit() {
    if (!this.reservation) {
      return;
    }

    this.directService.getSecurityDeposit(this.reservation.uuid).subscribe((res) => (this.securityDeposit = res));
  }

  openClaimSecurityDepositDialog() {
    this.dialog.open(DialogClaimSecurityDepositComponent, {
      width: this.isMobile() ? '100%' : '720px',
      maxWidth: this.isMobile() ? '100%' : '720px',
      maxHeight: this.isMobile() ? '100%' : '90vh',
      height: this.isMobile() ? '100%' : 'auto',
      data: {
        reservation: this.reservation,
        securityDeposit: this.securityDeposit,
      },
    });
  }
}
