import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-team-icon',
  templateUrl: './team-icon.component.html',
  styleUrls: ['./team-icon.component.scss'],
})
export class TeamIconComponent implements OnInit {
  @Input('member') member: any;
  @Input('size') size: number = 24;

  constructor() { }

  ngOnInit() {
    if (!this.member.services_labels) {
      this.member.services_labels = [];
    }
  }
}
