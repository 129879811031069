export class ReasonMapping {
  constructor(reason: string, code: string, message: string) {
    this.reason = reason;
    this.code = code;
    this.message = message;
  }

  reason: string;
  code: string;
  message: string;
}
