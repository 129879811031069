import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorService } from '@app/shared/services/error/error.service';
import { SelectedReservationService } from '@app/shared/services/selected-reservation.service';
import { SentimentAnalysisService } from '@app/shared/services/sentiment-analysis/sentiment-analysis.service';
import { ThreadPayload } from '@app/shared/services/thread/thread.service';
import { EMPTY } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

interface ThreadUpdateEvent {
  message: string;
  res: ThreadPayload;
}

@Component({
  selector: 'sbnb-issue-detected',
  templateUrl: './issue-detected.component.html',
})
export class IssueDetectedComponent {
  @Input() label: string;
  @Input() threadId: string;
  @Output() threadUpdated = new EventEmitter<void>();
  loading = false;

  constructor(
    private sentimentAnalysisService: SentimentAnalysisService,
    private errorService: ErrorService,
    private selectedReservationService: SelectedReservationService
  ) {}

  handleNo() {
    this.loading = true;
    this.sentimentAnalysisService
      .dismissAlert(this.threadId)
      .pipe(
        catchError((error) => {
          this.errorService.handle500(error);
          return EMPTY;
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe((updatedThread) => {
        if (updatedThread) {
          this.threadUpdated.emit();
          this.selectedReservationService.threadData$.next(updatedThread);
        }
      });
  }

  handleYes() {
    this.loading = true;
    this.sentimentAnalysisService
      .cancelScheduledReviews(this.threadId)
      .pipe(
        catchError((error) => {
          this.errorService.handle500(error);
          return EMPTY;
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe((updatedThread) => {
        if (updatedThread) {
          this.threadUpdated.emit();
          this.selectedReservationService.threadData$.next(updatedThread);
        }
      });
  }
}
