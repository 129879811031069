import { Pipe, PipeTransform } from '@angular/core';
import MarkdownIt from 'markdown-it';

@Pipe({
  standalone: false,
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  private md: MarkdownIt;

  constructor() {
    // Initialize markdown-it with options
    this.md = new MarkdownIt({
      html: false, // Disable HTML tags in source
      xhtmlOut: true, // Use '/' to close single tags (<br />)
      breaks: true, // Convert '\n' in paragraphs into <br>
      linkify: true, // Autoconvert URL-like text to links
      typographer: true, // Enable smartquotes and other typographic replacements
    });

    // Override default renderer rules to add Tailwind classes
    const defaultRender =
      this.md.renderer.rules.link_open ||
      function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };

    this.md.renderer.rules.paragraph_open = (tokens, idx, options, env, self) => {
      return '<div class="leading-snug">';
    };

    this.md.renderer.rules.paragraph_close = (tokens, idx, options, env, self) => {
      return '</div>';
    };

    this.md.renderer.rules.list_item_open = (tokens, idx, options, env, self) => {
      return '<li class="mb-0 font-normal leading-snug opacity-100">';
    };

    this.md.renderer.rules.bullet_list_open = (tokens, idx, options, env, self) => {
      return '<ul class="list-disc mb-0 space-y-2 pl-6 font-normal">';
    };

    this.md.renderer.rules.ordered_list_open = (tokens, idx, options, env, self) => {
      return '<ol class="list-decimal mb-0 space-y-2 pl-6 font-normal">';
    };

    this.md.renderer.rules.heading_open = (tokens, idx, options, env, self) => {
      const level = tokens[idx].markup.length;
      const classes =
        level === 1
          ? 'text-xl font-heading !-mb-2'
          : level === 2
            ? 'text-lg font-heading !-mb-2'
            : 'text-base font-heading';
      return `<div class="${classes}">`;
    };

    this.md.renderer.rules.heading_close = (tokens, idx, options, env, self) => {
      return '</div>';
    };


    this.md.renderer.rules.strong_open = () => '<strong class="font-heading">';

    this.md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
      tokens[idx].attrPush(['class', 'text-primary hover:text-primary-darker underline']);
      return defaultRender(tokens, idx, options, env, self);
    };
  }

  transform(value: string): string {
    if (!value) return '';

    // Convert markdown to HTML
    const renderedContent = this.md.render(value);
    return renderedContent;
  }
}
