<div [hidden]="lite" fxLayout="row" (click)="expanded = !expanded" class="clickable">
  <div fxFlex class="cera-heading night-darker">Activity</div>
  <img src="/assets/iconography/small-arrow-bottom.svg" alt="expand" />
</div>

<div [hidden]="!expanded" class="activity__container">
  <mat-spinner *ngIf="loading" diameter="16"></mat-spinner>
  <ng-container *ngIf="!loading && activity?.length">
    <div
      *ngFor="let event of activity"
      fxLayout
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
      class="activity__entry"
      [class.old__entry]="isOldEntry(event.sort_time)">
      <div fxFlex="16px" class="activity__img">
        <sbnb-icon
          *ngIf="(event.type === 'scheduled' || event.type === 'activity') && isOldEntry(event.sort_time)"
          [size]="16"
          icon="message"
          class="text-smoke-darker"></sbnb-icon>
        <sbnb-icon
          *ngIf="(event.type === 'scheduled' || event.type === 'activity') && !isOldEntry(event.sort_time)"
          [size]="16"
          icon="time"
          class="text-night-darker"></sbnb-icon>

        <img
          *ngIf="event.type === 'review' && isOldEntry(event.sort_time)"
          src="/assets/iconography/review2.svg"
          width="16"
          height="16" />
        <img
          *ngIf="event.type === 'review' && !isOldEntry(event.sort_time)"
          src="/assets/iconography/review2-night-darker.svg"
          width="16"
          height="16" />
        <img *ngIf="event.entity_type === 'event'" src="/assets/iconography/event.svg" width="16" height="16" />
        <sbnb-icon *ngIf="event.type === 'question'" [size]="16" icon="message" class="text-smoke-darker"></sbnb-icon>
      </div>

      <div fxFlex class="text__small activity__text" [class.event__cancelled]="event.cancelled_at">
        <span *ngIf="event.status">{{ event.status }}</span>
        <a
          class="activity__show"
          *ngIf="event._links?.show && event.title"
          [routerLink]="[
            '/gx/log/' +
              (event.type === 'review'
                ? 'reviews'
                : event.type === 'activity'
                  ? 'activities'
                  : event.type === 'question'
                    ? 'questions'
                    : event.type) +
              '/' +
              (event.randomkey ? event.randomkey : event.review_id)
          ]">
          <span>{{ event.title }}</span>
          <img
            *ngIf="
              event.ai_improvement?.has === true &&
              event.ai_improvement?.edited === false &&
              event.ai_improvement?.rejected === false
            "
            style="margin-left: 6px; display: inline-block"
            src="/assets/iconography/magic.svg"
            alt="AI improved icon"
            matTooltip="Message set to improve with AI"
            width="16" />
        </a>
      </div>

      <span
        *ngIf="event._links?.update"
        class="clickable text__xsmall ocean"
        (click)="openEditMessageDialog(event.randomkey ? event.randomkey : event.review_id, event.type)"
        matTooltipPosition="right"
        [matTooltip]="(isMobile$ | async) ? null : eventTooltip(event)">
        Edit
      </span>

      <span
        *ngIf="!event._links?.update"
        class="text__xsmall night-lighter"
        [class.event__cancelled]="event.cancelled_at"
        matTooltipPosition="right"
        [matTooltip]="(isMobile$ | async) ? null : eventTooltip(event)">
        {{ event.sort_time_short_label }}
      </span>
    </div>
  </ng-container>
  <span *ngIf="!loading && activity.length === 0" class="text__small">No activity yet</span>
</div>
