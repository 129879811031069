import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Filter, FilterKey, FilterState, FilterType } from '@app/shared/interfaces/lib/filter.interface';

@Component({
  selector: 'hospitable-applied-filter-button',
  templateUrl: './applied-filter-button.component.html',
  styleUrls: ['./applied-filter-button.component.scss'],
})
export class AppliedFilterButtonComponent implements OnInit {
  @Input() filter: Filter;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('dynamicMenuCmp') dynamicMenuCmp: any;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  @Output() applyFilter: EventEmitter<Filter> = new EventEmitter();
  @Output() removeFilter: EventEmitter<Filter> = new EventEmitter();

  FilterState = FilterState;
  FilterType = FilterType;
  FilterKey = FilterKey;

  ngOnInit(): void {
    if (this.filter.recently_added) {
      setTimeout(() => {
        this.menuTrigger ? this.menuTrigger.openMenu() : null;
      }, 100);
    }
  }

  onApplyFilter(filter: Filter) {
    this.applyFilter.emit(filter);
  }

  onRemoveFilter(filter: Filter) {
    this.removeFilter.emit(filter);
  }
}
