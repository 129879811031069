<ng-container *ngIf="(isAddDirectBookingEnabled$ | async) === true">
  <sbnb-button
    label="+ Add booking"
    [size]="size"
    [type]="type"
    [fullWidth]="fullWidth"
    [matMenuTriggerFor]="menu"
    [ngStyle]="{ width: fullWidth ? '100%' : 'auto' }"></sbnb-button>
  <mat-menu #menu>
    <div class="manual-booking-menu-item clickable" fxLayout="column" fxLayoutGap="4px" (click)="openDirectDialog()">
      <span class="night-darker strong">Add a Direct booking</span>
      <span class="text__xsmall">Hospitable will collect payment from the guest.</span>
    </div>
    <div class="manual-booking-menu-item clickable" fxLayout="column" fxLayoutGap="4px" (click)="openDialog()">
      <span class="night-darker strong">Add a manual booking</span>
      <span class="text__xsmall">I will manage any payments off-platform</span>
    </div>
  </mat-menu>
</ng-container>

<ng-container *ngIf="(isAddDirectBookingEnabled$ | async) === false">
  <sbnb-button
    label="+ Add booking"
    [size]="size"
    [type]="type"
    [fullWidth]="fullWidth"
    (click)="openDialog()"
    [ngStyle]="{ width: fullWidth ? '100%' : 'auto' }"></sbnb-button>
</ng-container>
