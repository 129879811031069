<header>
  <a [routerLink]="[logoLink]">
    <img src="/assets/images/logo-transparent-dark.svg" alt="Hospitable logo" />
  </a>

  <div *ngIf="showBack" class="flex gap-2">
    <a class="flex items-center justify-center back-button" (click)="backClicked.emit()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8H1" stroke="#B3AFBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 15L1 8L8 1" stroke="#B3AFBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>

    <app-feature-flag-tabs>
      <sbnb-close [border]="true" [routerLink]="[logoLink]"></sbnb-close>
      <sbnb-close navTabs [border]="true" [routerLink]="[logoLink]" [replaceUrl]="true"></sbnb-close>
    </app-feature-flag-tabs>
  </div>
</header>
