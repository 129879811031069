<div fxLayout fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">
  <button
    *ngIf="meta.modes && meta.modes.length > 1; else nonButtonModes"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="modesMenu">
    <span>{{ ruleset.mode_label }}</span>
  </button>

  <ng-template #nonButtonModes>
    <span>{{ ruleset.mode_label }}</span>
  </ng-template>

  <span *ngIf="meta.event">
    <strong></strong>
    this {{ ruleset.event === 'review' ? 'review' : 'message' }}
  </span>

  <ng-container *ngIf="!overrideUnits && meta.units && meta.units.length > 0">
    <button mat-flat-button class="filter__option-btn button__small button__text" [matMenuTriggerFor]="timingMenu">
      <span>{{ ruleset.timing.unit.value_label }}</span>
    </button>

    <ng-container *ngIf="!overrideTiming && ruleset.timing.unit.value !== 0">
      <button
        *ngIf="meta.when && meta.when.length > 1; else nonButtonWhen"
        mat-flat-button
        class="filter__option-btn button__small button__text"
        [matMenuTriggerFor]="whenMenu">
        <span>{{ ruleset.timing.when }}</span>
      </button>

      <ng-template #nonButtonWhen>
        <span>
          <strong></strong>
          {{ ruleset.timing.when }}
        </span>
      </ng-template>
    </ng-container>
  </ng-container>

  <span *ngIf="meta.event">
    {{ overrideUnits || overrideTiming ? 'after' : '' }}
    <strong>{{ meta.event.label }}.</strong>
  </span>
</div>

<mat-menu #modesMenu="matMenu" class="filters__cdk-menu filters__cdk-menu-320" (closed)="save()">
  <button *ngFor="let mode of meta.modes" mat-menu-item (click)="modeChanged(mode)">
    {{ mode.label }}
  </button>
</mat-menu>

<mat-menu #timingMenu="matMenu" class="filters__cdk-menu" (closed)="save()">
  <ng-template matMenuContent>
    <mat-radio-group fxLayout="column" class="filters__input-number-rg" (change)="unitsChanged($event)">
      <ng-container *ngFor="let unit of meta.units">
        <ng-container [ngSwitch]="unit.type">
          <ng-container *ngSwitchCase="'static'">
            <mat-radio-button [value]="unit" (click)="$event.stopPropagation()">{{ unit.text }}</mat-radio-button>
          </ng-container>

          <ng-container *ngSwitchCase="'int'">
            <mat-radio-button
              [value]="unit"
              [checked]="unit.value_append === ruleset.timing.unit.value_append"
              (click)="$event.stopPropagation()">
              {{ unit.text_after }}
            </mat-radio-button>
            <mat-form-field
              *ngIf="unit.value_append === ruleset.timing.unit.value_append"
              (click)="$event.stopPropagation()">
              <input
                matInput
                type="number"
                [value]="ruleset.timing.unit.value"
                (change)="unitIntInputChange($event, unit)" />
            </mat-form-field>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-radio-group>

    <button class="filter__btn-done">Done</button>
  </ng-template>
</mat-menu>

<mat-menu #whenMenu="matMenu" class="filters__cdk-menu" (closed)="save()">
  <button *ngFor="let when of meta.when" mat-menu-item (click)="whenChanged(when)">
    {{ when.value }}
  </button>
</mat-menu>
