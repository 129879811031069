import { Component, Input, OnChanges } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-guest-portal-sidebar-link',
  templateUrl: './guest-portal-sidebar-link.component.html',
})
export class GuestPortalSidebarLinkComponent implements OnChanges {
  @Input() reservationUuid: string;
  guestPortalUrl: string;

  ngOnChanges() {
    this.guestPortalUrl = `https://booking.hospitable.com/guest-portal/${this.reservationUuid}`;
  }
}
