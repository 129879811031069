<div
  class="thread__guest-container w-full flex"
  [ngClass.lt-md]="{ mobile: true }"
  *ngIf="thread?.guest?.first_name || thread?.guest?.last_name">
  <img
    class="thread__guest-img hidden lg:block min-w-20 min-h-20"
    [src]="thread.guest.picture"
    onerror="this.src='/assets/images/profile-default.png'" />
  <a
    *ngIf="thread.guest?.profile_url"
    class="hidden lg:block"
    [attr.href]="thread.guest.profile_url.indexOf('https://') == 0 ? thread.guest.profile_url : null"
    target="_blank">
    <img
      class="inbox__guest-platform box-shadow"
      [class.inbox__guest-platform--with-guests]="thread.has_additional_guests"
      [src]="'/assets/images/' + (thread.guest.platform | lowercase) + '-16.svg'"
      alt="{{ thread.guest.platform }}" />
  </a>
  <img
    *ngIf="!thread.guest?.profile_url"
    class="inbox__guest-platform box-shadow hidden lg:block"
    [class.inbox__guest-platform--with-guests]="thread.has_additional_guests"
    [src]="'/assets/images/' + (thread.guest.platform | lowercase) + '-16.svg'"
    alt="{{ thread.guest.platform }}" />
  <sbnb-additional-guests-badge
    *ngIf="thread.has_additional_guests && thread.guests?.length"
    class="inbox__additional-guests-badge hidden lg:block"
    [guests]="thread.guests"></sbnb-additional-guests-badge>
  <div class="thread__guest-details w-full py-0 md:py-2 space-y-1">
    <div class="flex items-center gap-1">
      <sbnb-icon icon="left-arrow" class="clickable py-3 pr-2 lg:hidden" (click)="goBack()"></sbnb-icon>

      <div class="flex items-center relative gap-1 flex-1">
        <img
          class="thread__guest-img--small lg:hidden"
          [src]="thread.guest.picture"
          onerror="this.src='/assets/images/profile-default.png'" />

        <sbnb-additional-guests-badge
          *ngIf="thread.has_additional_guests && thread.guests?.length"
          class="inbox__additional-guests-badge inbox__additional-guests-badge--mobile lg:hidden"
          [guests]="thread.guests"></sbnb-additional-guests-badge>

        <a
          *ngIf="thread.guest?.profile_url"
          [attr.href]="thread.guest.profile_url.indexOf('https://') == 0 ? thread.guest.profile_url : null"
          target="_blank"
          class="lg:hidden">
          <img
            class="absolute rounded-sm left-6 box-shadow"
            [class.inbox__guest-platform--with-guests]="thread.has_additional_guests"
            [src]="'/assets/images/' + (thread.guest.platform | lowercase) + '-16.svg'"
            alt="{{ thread.guest.platform }}" />
        </a>

        <!-- Name and checkin/checkout -->
        <div class="flex flex-col justify-center">
          <div class="flex items-center text-sm -ml-1">
            <div class="input__editable strong lg:text-xl">
              <span
                #nameSpan
                style="padding-left: 0; padding-right: 8px; visibility: hidden; position: absolute; left: -9999px">
                {{ thread.guest.custom_first_name }}
              </span>

              <input
                [matTooltip]="
                  thread.guest.first_name !== thread.guest.custom_first_name ? thread.guest.first_name : null
                "
                class="input__borderless"
                type="text"
                [style.width.px]="nameSpan.getBoundingClientRect().width"
                [(ngModel)]="thread.guest.custom_first_name"
                (change)="updateGuestName()" />
            </div>

            <div class="input__editable strong lg:text-xl">
              <span #nameSpan2 style="padding: 0 4px; visibility: hidden; position: absolute; left: -9999px">
                {{ thread.guest.custom_last_name }}
              </span>

              <input
                [matTooltip]="thread.guest.last_name !== thread.guest.custom_last_name ? thread.guest.last_name : null"
                class="input__borderless"
                type="text"
                [style.width.px]="nameSpan2.getBoundingClientRect().width"
                [(ngModel)]="thread.guest.custom_last_name"
                (change)="updateGuestName()" />
            </div>

            <mat-spinner *ngIf="updatingGuestName" style="margin-left: 12px" [diameter]="16"></mat-spinner>
            <img
              *ngIf="recentlyUpdatedGuestName"
              style="margin-left: 12px"
              src="/assets/images/onboarding/check.svg"
              alt="Completed" />
          </div>

          <span
            class="lg:hidden text-xs text-night text-ellipsis overflow-hidden flex-1 whitespace-nowrap"
            *ngIf="activeReservation$ | async as reservation">
            {{ [reservation.start_date, reservation.end_date] | shortDateRange }} &middot;
            {{ reservation.property?.name || reservation.listing?.property_name }}
          </span>
        </div>
      </div>

      <div class="flex gap-3">
        <!-- Start:  Star and unstar the conversation -->
        <sbnb-star-conversation
          [starred]="thread.starred"
          [threadUuid]="thread.uuid"
          (starredToggled)="thread.starred = $event"></sbnb-star-conversation>
        <!-- End:  Star and unstar the conversation -->

        <!-- Start:  Resolve the conversation -->
        <sbnb-resolve-conversation
          [resolved]="thread.resolved"
          [threadUuid]="thread.uuid"
          (resolvedToggled)="thread.resolved = $event"></sbnb-resolve-conversation>
        <!-- End:  Resolve the conversation -->

        <!-- Start:  Translate the conversation -->
        <sbnb-translate-conversation
          [usingTranslations]="thread.using_translations"
          [threadUuid]="thread.uuid"
          (translationsToggled)="translationsToggled($event)"></sbnb-translate-conversation>
        <!-- End:  Translate the conversation -->
      </div>
    </div>
    <div class="guest__details hidden lg:flex flex-wrap gap-2">
      <div class="flex items-center gap-1" *ngIf="thread?.guest?.phone">
        <a [href]="'tel:' + thread.guest.phone" class="flex items-center gap-1 underline">
          <sbnb-icon icon="phone" class="text-smoke-darker"></sbnb-icon>
          <span>{{ thread.guest.phone }}</span>
        </a>
        <sbnb-copy-to-clipboard [mini]="true" [content]="thread.guest.phone"></sbnb-copy-to-clipboard>
      </div>

      <sbnb-guest-email
        *ngIf="thread?.guest?.email?.length"
        [email]="thread?.guest?.email"
        [canChangeEmail]="canChangeEmail"
        [threadUuid]="thread.uuid"></sbnb-guest-email>
    </div>
  </div>
</div>
