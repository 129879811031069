import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { Timeline } from '@app/shared/components/timeline/timeline.component';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface CaughtApiError {
  error: boolean;
  message: string;
  errors: any[];
}

export interface MatchedLock {
  additional_info: AdditionalInfo;
  code_length: number;
  connection_id: string;
  integration_id: string;
  lock_id: string;
  is_supported: boolean;
  unsupported_message: string | undefined;
  properties: number[];
  errors: Error[];
  warnings: Warning[];
  property_list: {
    id: number;
    name: string;
    picture: string;
  }[];

  resyncing?: boolean;
}

export interface AdditionalInfo {
  created_at: string;
  description: string;
  display_name: string;
  id: string;
  is_linked: boolean;
  is_onboarded: boolean;
  manufacturer: string;
  model: string;
  serial_number: string;
  min_length_pincode: number;
  max_length_pincode: number;
  max_length_name: number;
  max_pincodes: number;
  lock_disconnected: boolean;
}

export interface UnmatchedLock {
  id: string;
  display_name: string;
  created_at: string;
  description: string;
  manufacturer: string;
  model: string;
  serial_number: string;
  integration_id: string;
  is_supported: boolean;
  unsupported_message: string | undefined;
  is_ready: boolean;
  min_length_pincode: number;
  max_length_pincode: number;
  max_length_name: number;
  max_pincodes: number;
  connection_id: string;
  errors: Error[];
  warnings: Warning[];
}

export interface SmartlockAccount {
  email: string;
  disconnected: boolean;
}

// TODO
export interface SmartLockSettings {
  check_in_time_buffer: BufferSetting;
  check_out_time_buffer: BufferSetting;
  use_phone: boolean;
  is_using_code_communication: boolean;
  delay: {
    enabled: boolean;
    method: 'always' | 'exclude_last_minute';
  };
  add_buffers_to_comms: boolean;
  dashboard_notifications: {
    battery_status: NotificationSetting;
    code_first_used: NotificationSetting;
    device_errors: NotificationSetting;
    other_issues: NotificationSetting;
  };
}

export interface NotificationSetting {
  dashboard: boolean;
  push: boolean;
  email: boolean;
}

export type BufferTimeUnits = 'minutes' | 'hours';

interface BufferSetting {
  unit: BufferTimeUnits;
  value: string;
}

export interface LockPayload {
  integration_id: string;
  code?: string;
}

export interface SmartlockCode {
  reservation_id: string;
  pin_code: string;
  statuses: SmartlockStatus[];
  is_checked_out: boolean;
  is_reservation_accepted: boolean;
  smartlock: {
    display_name: string;
    serial_number: string;
    lock_id: string;
  };

  // FE computed
  latestStatus?: SmartlockStatus | undefined;
  timelines?: Timeline[] | undefined;
}

export interface Error {
  error_code: string;
  message: string;
  created_at: string;
}

export interface Warning {
  warning_code: string;
  message: string;
  created_at: string;
}

export interface SmartlockStatus {
  name: SmartlockStatuses;
  estimated_completion_at: string;
  is_latest: boolean;
}

export enum SmartlockStatuses {
  Initial = 'initial',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  WaitingWebhook = 'waiting_webhook',
  Resolved = 'resolved',
  Modified = 'modified',
  CancelledExternally = 'cancelled_externally',

  Pending = 'pending',
  Created = 'created',
  VisibleOnLock = 'visible on lock',
  Active = 'active',
}

@Injectable({
  providedIn: 'root',
})
export class SmartlocksService {
  private smartlocksUrl = `${config.API_URL}/smartlocks`;
  private smartlocksSettingsUrl = `${config.API_URL}/settings/smartlocks`;

  constructor(
    private readonly http: HttpClient,
    private datePipe: DatePipe
  ) {}

  getLinkedLocks(payload: LockPayload): Observable<MatchedLock[]> {
    return this.http.get(this.smartlocksUrl + '/linked-locks' + '?integration_id=' + payload.integration_id).pipe(
      map((res: { data: MatchedLock[] }) => {
        return res.data;
      })
    );
  }

  getOtherLocks(payload: LockPayload): Observable<UnmatchedLock[]> {
    return this.http.get(this.smartlocksUrl + '/other-locks' + '?integration_id=' + payload.integration_id).pipe(
      map((res: UnmatchedLock[]) => {
        return res;
      })
    );
  }

  getSmartlockAccounts(payload: any): Observable<SmartlockAccount[]> {
    return this.http.get(this.smartlocksUrl + '/accounts' + '?integration_id=' + payload.integration_id).pipe(
      map((res: { data: SmartlockAccount[] }) => {
        return res.data;
      })
    );
  }

  initiateNewSmartlockConnection(payload: { integration_id: string }): Observable<any> {
    return this.http.post(this.smartlocksUrl + '/initiate-new-connection', payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  connectionSuccess(payload: LockPayload): Observable<any> {
    return this.http
      .get(
        this.smartlocksUrl +
          '/confirm-account-connection' +
          '?integration_id=' +
          payload.integration_id +
          '&code=' +
          payload.code
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getAllConnectionLocks(payload: any): Observable<any> {
    return this.http
      .get(
        this.smartlocksUrl +
          '/locks' +
          '?integration_id=' +
          payload.integration_id +
          '&connection_id=' +
          payload.connection_id
      )
      .pipe(
        map((res: UnmatchedLock[]) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  storeSmartlock(payload: any): Observable<any> {
    return this.http.post(this.smartlocksUrl + '/locks/store', payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  editSmartlock(payload: any): Observable<any> {
    return this.http.put(this.smartlocksUrl + '/locks/' + payload.lock.lock_id, payload.lock).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getAdditionalLockInfo(payload: any): Observable<AdditionalInfo> {
    return this.http
      .get(
        this.smartlocksUrl +
          '/locks/lock-information' +
          '?integration_id=' +
          payload.integration_id +
          '&connection_id=' +
          payload.connection_id +
          '&lock_id=' +
          payload.lock_id
      )
      .pipe(
        map((res: any) => {
          return res.locks ?? res;
        })
      );
  }

  getSmartlockSettings(): Observable<SmartLockSettings> {
    return this.http.get(this.smartlocksSettingsUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveSmartlockSettings(payload: any): Observable<SmartLockSettings> {
    return this.http.put(this.smartlocksSettingsUrl, payload).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  initiateLockResync(payload: any): Observable<any> {
    return this.http
      .get(
        this.smartlocksUrl +
          '/lock-resync' +
          '?integration_id=' +
          payload.integration_id +
          '&lock_id=' +
          payload.lock_id
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getlinkedPropertiesList(payload: any): Observable<any> {
    return this.http
      .get(
        this.smartlocksUrl +
          '/locks/' +
          payload.lock_id +
          '/matched-properties' +
          '?integration_id=' +
          payload.integration_id +
          '&connection_id=' +
          payload.connection_id
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getSmartlockCodesForAThread(payload: { uuid: string }): Observable<SmartlockCode[] | CaughtApiError> {
    return this.http.get(this.smartlocksUrl + '/pin-code' + '?uuid=' + payload.uuid).pipe(
      map((res: { data: SmartlockCode[] }) => {
        const codes = res.data;

        if (codes && codes.length > 0) {
          for (let i = 0; i < codes.length; i++) {
            const code = codes[i];

            if (code && code.statuses && code.statuses.length > 0) {
              let latestStatusIndex: number;
              const latestStatus: SmartlockStatus[] | undefined =
                code && code.statuses
                  ? code.statuses.filter((status, index: number) => {
                      if (status.is_latest) {
                        latestStatusIndex = index;
                      }
                      return status.is_latest;
                    })
                  : undefined;

              // send back latest status with highest index (just in case there is multiple is_latest)
              codes[i].latestStatus =
                latestStatus && latestStatus.length > 0 ? latestStatus[latestStatus.length - 1] : undefined;

              // generate timeline for code status
              const timelines: Timeline[] = [];

              for (let x = 0; x < code.statuses.length; x++) {
                const status = code.statuses[x];

                timelines.push({
                  title: status.name,
                  subtitle: status.estimated_completion_at
                    ? this.datePipe.transform(status.estimated_completion_at, 'MMM d') +
                      '-' +
                      this.datePipe.transform(status.estimated_completion_at, 'shortTime')
                    : undefined,
                  cssClass: status.is_latest ? 'is-active-timeline' : '',
                  isFilled: status.is_latest ? true : latestStatusIndex && x < latestStatusIndex,
                });
              }
              timelines ? (codes[i].timelines = timelines.reverse()) : null;
            }
          }
        }

        return codes;
      }),
      catchError(this.handleError)
    );
  }

  triggerPinCodeSet(payload: { reservation_id: string; lock_id: string; reservation_uuid: string }): Observable<any> {
    return this.http
      .get(
        this.smartlocksUrl +
          '/trigger-pin-code-set' +
          '?reservation_id=' +
          payload.reservation_id +
          '&lock_id=' +
          payload.lock_id +
          '&reservation_uuid=' +
          payload.reservation_uuid
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  validateCodeLengthAgainstProperty(payload: {
    property_id: number;
    code_length: number;
    lock_id: string;
  }): Observable<any> {
    return this.http.post(this.smartlocksUrl + '/validate-code-length', payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(err): Observable<CaughtApiError> {
    return of({
      error: true,
      message: err.error.message,
      errors: err.error.errors,
    });
  }
}
