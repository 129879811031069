<div
  class="empty-state-container"
  fxFlexFill
  fxLayout
  fxLayoutGap="120px"
  fxLayoutGap.lt-lg="60px"
  fxLayoutAlign="center center">
  <article class="empty-state-main">
    <header>
      <h1>{{ title }}</h1>
    </header>
    <section class="content">
      <div class="empty-state-body">
        <ng-content select="[body]"></ng-content>
      </div>

      <div class="empty-state-actions">
        <ng-content select="[actions]"></ng-content>
      </div>
    </section>
  </article>

  <aside fxHide.lt-md>
    <img [src]="image" [alt]="imageAlt" />
  </aside>
</div>
