import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  standalone: false,
  selector: '[sbnbResizeObserver]',
})
export class ResizeObserverDirective implements OnDestroy, OnInit {
  private observer: ResizeObserver | undefined;

  @Output() resized = new EventEmitter<ResizeObserverEntry[]>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.resized.emit(entries);
    });

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }
}
