<div fxLayout>
  <h3 fxFlex>Hosts for {{ ruleName }}</h3>
  <img class="clickable" src="/assets/iconography/cancel.svg" width="24px" height="24px" (click)="close()" />
</div>

<div class="host__container">
  <div *ngFor="let host of hosts" class="host" fxLayout fxLayoutAlign="start center" fxLayoutGap="14px ">
    <img class="host__picture" [src]="host.picture" />
    <span>{{ host.name }}</span>
  </div>
</div>
