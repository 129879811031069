import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

interface DialogData {
  reservationUuid: string;
}

@Component({
  selector: 'sbnb-dialog-report-invalid-payment',
  templateUrl: './dialog-report-invalid-payment.component.html',
})
export class DialogReportInvalidPaymentComponent {
  saving = false;

  constructor(
    public dialogRef: MatDialogRef<DialogReportInvalidPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onConfirm(): void {
    this.saving = true;
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
