import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-checkin-activity-list',
  templateUrl: './checkin-activity-list.component.html',
  styleUrls: ['./checkin-activity-list.component.scss'],
})
export class CheckinActivityListComponent implements OnInit {
  @Input() guests: { name: string; picture: string; check_in_date: string }[];

  constructor() { }

  ngOnInit(): void { }
}
