import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss'],
})
export class CloseComponent {
  @Input() border = false;
  @Input() color = '#B3AFBB';
}
