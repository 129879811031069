import { Pipe, PipeTransform } from '@angular/core';
import { MessageEvent, ThreadMessage } from '@app/shared/services/thread/thread.service';

@Pipe({
  standalone: false,
  name: 'isEventMessage',
})
export class IsEventMessagePipe implements PipeTransform {
  transform(value: ThreadMessage): value is MessageEvent {
    return value.entity_type === 'event';
  }
}
