<!-- Non-cursor paginarion -->
<div class="pagination__container flex flex-row" *ngIf="!isCursorPagination; else CursorPagination">
  <div class="flex-col items-start hidden sbnbDesktop:block">
    Showing {{ pagination.offset ? pagination.offset : 1 }}-{{
      pagination.total < pagination.per_page
        ? pagination.total
        : pagination.page_numbers.this_page * pagination.per_page
    }}
    of {{ pagination.total }} entries
  </div>

  <div class="flex flex-col flex-grow items-end">
    <mat-button-toggle-group class="paginator">
      <mat-button-toggle
        class="paginator__prevnext"
        [class.paginator__disabled]="pagination.page_numbers.this_page === 1"
        value="back"
        (click)="goToPreviousPage()"
        disableRipple>
        <img src="./assets/iconography/left-arrow.svg" />
      </mat-button-toggle>
      <ng-container *ngIf="pages.length <= 10; else lotsOfPages">
        <mat-button-toggle
          *ngFor="let page of pages"
          (click)="goToPage(page)"
          [class.page__active]="pagination.page_numbers.this_page === page"
          disableRipple>
          {{ page }}
        </mat-button-toggle>
      </ng-container>
      <ng-template #lotsOfPages>
        <mat-button-toggle
          (click)="goToPage(1)"
          [class.page__active]="pagination.page_numbers.this_page === 1"
          disableRipple>
          1
        </mat-button-toggle>
        <mat-button-toggle *ngIf="pagination.page_numbers.this_page > 4" disabled="true" disableRipple>
          ...
        </mat-button-toggle>

        <ng-container *ngFor="let index of [-2, -1, 0, +1, +2]">
          <mat-button-toggle
            *ngIf="
              pagination.page_numbers.this_page + index > 1 && pagination.page_numbers.this_page + index < pages.length
            "
            (click)="goToPage(pagination.page_numbers.this_page + index)"
            [class.page__active]="pagination.page_numbers.this_page === pagination.page_numbers.this_page + index"
            disableRipple>
            {{ pagination.page_numbers.this_page + index }}
          </mat-button-toggle>
        </ng-container>

        <mat-button-toggle *ngIf="pagination.page_numbers.this_page < pages.length - 3" disabled="true" disableRipple>
          ...
        </mat-button-toggle>
        <mat-button-toggle
          (click)="goToPage(pages.length)"
          [class.page__active]="pagination.page_numbers.this_page === pages.length"
          disableRipple>
          {{ pages.length }}
        </mat-button-toggle>
      </ng-template>
      <mat-button-toggle
        class="paginator__prevnext"
        value="forward"
        [class.paginator__disabled]="pagination.page_numbers.this_page === pages.length"
        (click)="goToNextPage()"
        disableRipple>
        <img src="assets/iconography/right-arrow.svg" />
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<!-- Cursor pagination -->
<ng-template #CursorPagination>
  <div class="flex justify-end" data-element="cursor-pagination">
    <mat-button-toggle-group class="cursor-paginator">
      <mat-button-toggle
        class="paginator__prevnext"
        [class.paginator__disabled]="!pagination?._links?.prev"
        [disabled]="!pagination?._links?.prev"
        value="back"
        (click)="onCursorPrevious()"
        disableRipple
        data-element="load-previous">
        <div class="img-container">
          <img src="/assets/iconography/left-arrow.svg" class="mr-1" alt="Previous" />
          Previous
        </div>
      </mat-button-toggle>
      <mat-button-toggle
        class="paginator__prevnext"
        [class.paginator__disabled]="!pagination?._links?.next"
        [disabled]="!pagination?._links?.next"
        value="forward"
        (click)="onCursorNext()"
        disableRipple
        data-element="load-next">
        <div class="img-container">
          Next
          <img src="/assets/iconography/right-arrow.svg" class="ml-1" alt="Next" />
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-template>
