import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  constructor(private location: Location) {}

  @Input() disableRedirect = false;
  @Input() iconOnly = false;
  @Input() label = '';

  goBack() {
    if (!this.disableRedirect) {
      this.location.back();
    }
  }
}
