import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() logoLink = '/';
  @Input() showBack = false;
  @Output() backClicked = new EventEmitter<void>();
}
