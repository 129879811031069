import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PropertyList } from '@app/shared/models/property-list';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-properties',
  templateUrl: './dialog-properties.component.html',
  styleUrls: ['./dialog-properties.component.scss'],
})
export class DialogPropertiesComponent implements OnInit {
  properties: PropertyList[];
  ruleName: string;

  constructor(
    public dialogRef: MatDialogRef<DialogPropertiesComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit() {
    this.ruleName = this.dialogData.ruleName;
    this.properties = this.dialogData.properties;
  }

  close() {
    this.dialogRef.close();
  }
}
