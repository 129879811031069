import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type GuestReview = {
  comment: string;
  date: string;
};

@Component({
  standalone: false,
  selector: 'sbnb-guest-reviews',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './guest-reviews.component.html',
  styleUrls: ['./guest-reviews.component.scss'],
})
export class GuestReviewsComponent implements OnInit {
  @Input() reviews: GuestReview[] = [];

  public sortedReviews: GuestReview[] = [];
  public activeReview$ = new BehaviorSubject<GuestReview>(null);

  constructor() { }

  ngOnInit(): void { }

  public ngOnChanges(): void {
    this.sortedReviews = this.reviews.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
    this.activeReview$.next(this.sortedReviews[0]);
  }

  public onReviewChange(index: number): void {
    this.activeReview$.next(this.sortedReviews[index]);
  }
}
