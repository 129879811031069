import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Features } from '@app/core/user-permissions/user-permissions.model';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { Filter } from '@app/shared/models/filter';
import { TasksService } from '@app/shared/services/tasks/tasks.service';
import { TeamService } from '@app/shared/services/team/team.service';
import { addDays, format, getHours, getMinutes, isBefore, setHours, setMinutes } from 'date-fns';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-menu-task-assign',
  templateUrl: './menu-task-assign.component.html',
  styleUrls: ['./menu-task-assign.component.scss'],
})
export class MenuTaskAssignComponent implements OnInit, OnChanges {
  possibleTeammates: any;
  numListOptionsForSearchBar = 10;
  selectedTeammate: string;
  notes = new FormControl('');
  saving: boolean;
  loading: boolean;
  task: any; // working copy of the task with unsaved changes
  taskType: any;
  deleting: boolean;

  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
  public timeDirty = false;

  allMatesHidden: boolean;
  searchCriteria = '';
  @Input('checkListHidden') taskChecklistHidden = false;

  @Input('task') originalTask: any; // reference to actual task on calendar
  @Input() mode: string;
  @Input() newDate: { date; hour };
  @Input() propertyId: any;

  @Output() taskAssigned: EventEmitter<any> = new EventEmitter();
  @Output() closeMenu: EventEmitter<any> = new EventEmitter();
  @Output() updatedTask: EventEmitter<any> = new EventEmitter();
  @Output() createdTask: EventEmitter<any> = new EventEmitter();
  @Output() taskDeleted: EventEmitter<any> = new EventEmitter();

  public canManageAllTasks$ = this.userPermissionsService.hasAccessTo(Features.PERM_TASKS_UPDATE_ALL);

  constructor(
    private teamService: TeamService,
    private taskService: TasksService,
    private userPermissionsService: UserPermissionsService
  ) {}

  ngOnInit() {
    if (this.mode === 'new') {
      this.task = {
        start_date: setHours(this.newDate.date, +this.newDate.hour),
        end_date: setHours(this.newDate.date, +this.newDate.hour + 1),
      };

      this.task.start_date = setMinutes(this.task.start_date, 0);
      this.task.end_date = setMinutes(this.task.end_date, 0);
    } else {
      this.task = Object.assign({}, this.originalTask);
    }

    this.possibleTeammates = [];

    if (this.task) {
      this.startHour = getHours(this.task.start_date);
      this.startMin = getMinutes(this.task.start_date);
      this.endHour = getHours(this.task.end_date);
      this.endMin = getMinutes(this.task.end_date);

      this.selectedTeammate = this.task?.teammate?.uuid;

      this.notes.setValue(this.task.note);

      this.loading = true;

      const filters: Filter[] = [
        {
          uuid: 'notused',
          id: 'teammates-services',
          label: 'Services',
          loading: false,
          lockable: false,
          selectedValue: { key: this.task.service_id, label: 'notusedhere' },
          type: 'list',
          values: 'notusedhere',
        },
      ];

      this.canManageAllTasks$
        .pipe(
          take(1),
          switchMap((canManageAllTasks) =>
            canManageAllTasks
              ? this.teamService.getTeamMembers(this.task.service_id ? filters : null, null, null, 0, false)
              : of({ data: [] })
          )
        )
        .subscribe((res: any) => {
          this.possibleTeammates = res.data;
          this.filterTeamMembers(this.taskType ? this.taskType : this.task.service_id);
          this.loading = false;
          if (!this.allMatesHidden && res.data.length === 1) {
            this.selectedTeamMember(res.data[0]);
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mode !== 'new') {
      if (changes.originalTask && !changes.originalTask.firstChange) {
        this.ngOnInit();
      }
    } else {
      if (changes.newDate) {
        this.ngOnInit();
      }
    }
  }

  selectedTeamMember(member) {
    this.selectedTeammate = this.selectedTeammate === member.uuid ? null : member.uuid;
  }

  startUpdated(newTime: any) {
    this.timeDirty = true;
    this.startHour = newTime.hours;
    this.startMin = newTime.minutes;
  }

  endUpdated(newTime: any) {
    this.timeDirty = true;
    this.endHour = newTime.hours;
    this.endMin = newTime.minutes;
  }

  deleteTask() {
    this.deleting = true;

    this.taskService.deleteTask(this.task.uuid).subscribe((res) => {
      if (res.errors || res.error) {
        this.deleting = false;
        return;
      }

      this.deleting = false;
      this.taskDeleted.emit(this.task.uuid);
    });
  }

  updateTask() {
    this.saving = true;

    this.task.start_date = setHours(this.task.start_date, this.startHour);
    this.task.start_date = setMinutes(this.task.start_date, this.startMin);
    this.task.end_date = setHours(this.task.end_date, this.endHour);
    this.task.end_date = setMinutes(this.task.end_date, this.endMin);

    // If the end date is before the start date, roll it over to the next day
    if (isBefore(this.task.end_date, this.task.start_date)) {
      this.task.end_date = addDays(this.task.end_date, 1);
    }

    const payload = {
      note: this.notes.value,
      teammate_uuid: this.selectedTeammate,
      start_date: format(this.task.start_date, 'YYYY-MM-DD HH:mm:ss'),
      end_date: format(this.task.end_date, 'YYYY-MM-DD HH:mm:ss'),
    };

    if (this.mode === 'new') {
      payload['task_type'] = +this.taskType;
      payload['property_id'] = this.propertyId;

      this.taskService.createTask(payload).subscribe(
        (res) => {
          if (res.errors || res.error) {
            this.saving = false;
            return;
          }

          this.createdTask.emit(res.data);
          this.saving = false;
        },
        () => {
          this.saving = false;
        }
      );
    } else {
      this.taskService.updateTask(this.task.uuid, payload).subscribe(
        (res) => {
          if (res.errors || res.error) {
            this.saving = false;
            return;
          }

          if (res.data && res.data.teammate) {
            this.taskAssigned.emit(true);
          } else {
            this.closeMenu.emit(true);
          }

          res.data.computed = { can_edit: false };

          this.saving = false;
          this.updatedTask.emit(res.data);
        },
        () => {
          this.saving = false;
        }
      );
    }
  }

  close() {
    this.closeMenu.emit(true);
  }

  filterTeamMembers(value: string) {
    if (!value || !this.possibleTeammates?.length) {
      return;
    }

    this.allMatesHidden = false;

    this.possibleTeammates?.forEach((mate, index) => {
      const mutableMate = this.possibleTeammates[index];
      mutableMate.hidden = false;

      if (mate.services.includes(+value) || mate.all_services) {
        mutableMate.hidden = false;
      } else {
        mutableMate.hidden = true;
      }

      if (+value === 5 && mate.services.includes(8)) {
        mutableMate.hidden = false;
      }
    });

    this.allMatesHidden = this.possibleTeammates.every((x) => x.hidden === true);
  }

  toggleTaskChecklist() {
    this.taskChecklistHidden = !this.taskChecklistHidden;
  }
}
