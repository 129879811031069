<header class="flex items-center justify-between mb-8">
  <div class="flex items-center gap-3">
    <sbnb-icon class="text-grape-darker" [size]="20" [icon]="'key-simple'" />
    <h2 class="mb-0 text-lg sbnbDesktop:text-xl">Guest Check-in Requirements</h2>
  </div>

  <sbnb-close [border]="true" (click)="close()" />
</header>

<mat-tab-group mat-stretch-tabs="true" #tabgroup [selectedIndex]="selectedIndex()" [animationDuration]="0">
  <mat-tab label="Guest Verification" data-type="guest-verification" #tab>
    <sbnb-guest-verification-details class="block mt-6" [reservationUuid]="data.reservation.uuid" />
  </mat-tab>
  <mat-tab label="Rental Agreements" data-type="rental-agreement" #tab>
    <sbnb-rental-agreements-sidebar-list
      class="block mt-6"
      [reservationUuid]="data.reservation.uuid"
      [updatedStyling]="true"
      [reservation]="data.reservation"
      [propertyId]="data.propertyId"
      [canAddAgreement]="(userPermissionsService.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS) | async) === true" />
  </mat-tab>
</mat-tab-group>
