<span
  *ngIf="!individualBadges"
  [matTooltip]="member.services_labels.length > 1 ? member.services_labels.join(', ') : ''"
  class="team__role text__xsmall"
  [class.allRoles]="member.all_services"
  [class.moreThan1Role]="member.services.length > 1 && !member.all_services"
  [class.checkInOutRole]="
    member.services.length === 1 &&
    (member.services_labels.indexOf('Checkin') > -1 || member.services_labels.indexOf('Checkout') > -1)
  "
  [class.maintenanceRole]="member.services.length === 1 && member.services_labels.indexOf('Maintenance') > -1"
  [class.cleaningRole]="member.services.length === 1 && member.services_labels.indexOf('Cleaning') > -1">
  {{
    member.all_services
      ? 'All'
      : member.services.length === 1
        ? member.services_labels[0]
        : member.services.length === 0
          ? 'None'
          : member.services.length + ' roles'
  }}
</span>

<div *ngIf="individualBadges && member.services_labels?.length > 0" fxLayout fxLayoutGap="6px">
  <span
    *ngFor="let service of member.services_labels"
    class="team__role ellipsis"
    [class.checkInOutRole]="service === 'Check-in' || service === 'Check-out'"
    [class.maintenanceRole]="service === 'Maintenance'"
    [class.cleaningRole]="service === 'Cleaning'"
    [class.ownerRole]="service === 'Owner'">
    {{ service }}
  </span>
</div>
