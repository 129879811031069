<div class="relative gx-subnav">
  <h4
    [routerLink]="['/gx/overview']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla="routerLinkActive">
    <sbnb-icon
      [size]="20"
      [ngClass]="{ 'text-smoke-darker': !rla.isActive, 'text-raspberry-darker': rla.isActive }"
      [icon]="'heart'"></sbnb-icon>
    <span fxFlex>Overview</span>
  </h4>

  <div class="h-px w-full bg-gray-200 my-4 sbnbDesktop:hidden mobile-separator" data-section-separator></div>

  <h4
    [routerLink]="['/gx/rules']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla2="routerLinkActive">
    <sbnb-icon
      [size]="20"
      [ngClass]="{ 'text-smoke-darker': !rla2.isActive, 'text-raspberry-darker': rla2.isActive }"
      [icon]="'message'"></sbnb-icon>
    <span fxFlex>Messaging rules</span>
  </h4>

  <sbnb-saved-segments
    [inMobileMenu]="true"
    *ngIf="
      showSegments === 'messaging-rules' ||
      (featureFlagMobileNavTabsEnabled && deviceDetectionService.$isMobile | async)
    "
    key="messaging-rules"></sbnb-saved-segments>

  <div class="h-px w-full bg-gray-200 my-4 sbnbDesktop:hidden mobile-separator" data-section-separator></div>

  <h4
    [routerLink]="['/gx/reviews']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla3="routerLinkActive">
    <img
      [src]="rla3.isActive ? 'assets/iconography/review-active.svg' : 'assets/iconography/review.svg'"
      alt=""
      width="20px"
      height="20px" />
    <span fxFlex>Review rules</span>
  </h4>

  <h4
    [routerLink]="['/gx/questions']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla4="routerLinkActive">
    <img
      [src]="rla4.isActive ? 'assets/iconography/question-active.svg' : 'assets/iconography/question.svg'"
      alt=""
      width="20px"
      height="20px" />
    <span fxFlex>Questions</span>
  </h4>

  <h4
    [routerLink]="['/gx/custom-codes']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla5="routerLinkActive">
    <img
      [src]="rla5.isActive ? 'assets/iconography/custom-code-active.svg' : 'assets/iconography/custom-code.svg'"
      alt=""
      width="20px"
      height="20px" />
    <span fxFlex>Custom codes</span>
  </h4>

  <h4
    [routerLink]="['/gx/canned-responses']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla6="routerLinkActive">
    <img
      [src]="rla6.isActive ? 'assets/iconography/canned-response-active.svg' : 'assets/iconography/canned-response.svg'"
      alt=""
      width="20px"
      height="20px" />
    <span fxFlex>Canned responses</span>
  </h4>

  <h4
    *ngIf="isUpsellEnabled"
    [routerLink]="['/gx/upsells']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla7="routerLinkActive">
    <img
      [src]="rla7.isActive ? 'assets/iconography/credit-card-active.svg' : 'assets/iconography/credit-card-grey.svg'"
      alt=""
      width="20px"
      height="20px" />
    <span fxFlex>Upsells</span>
    <sbnb-badge context="positive">new</sbnb-badge>
  </h4>
  <div class="h-px w-full bg-gray-200 my-4 sbnbDesktop:hidden mobile-separator" data-section-separator></div>

  <h4
    [routerLink]="['/gx/log']"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    class="no-details"
    routerLinkActive="gx-active-link"
    #rla8="routerLinkActive">
    <img
      [src]="rla8.isActive ? 'assets/iconography/log-active.svg' : 'assets/iconography/log.svg'"
      width="20px"
      height="20px" />

    <span fxFlex>Log</span>
  </h4>

  <div
    *ngIf="showSegments === 'log' || (featureFlagMobileNavTabsEnabled && deviceDetectionService.$isMobile | async)"
    fxLayout="column"
    class="log__subnav">
    <a fxFlex [routerLink]="['/gx/log/events']" routerLinkActive="gx-active-link">Events</a>
    <a fxFlex [routerLink]="['/gx/log/scheduled']" routerLinkActive="gx-active-link">Scheduled</a>
    <a fxFlex [routerLink]="['/gx/log/questions']" routerLinkActive="gx-active-link">Questions</a>
    <a fxFlex [routerLink]="['/gx/log/reviews']" routerLinkActive="gx-active-link">Reviews</a>
  </div>
  <div class="h-px w-full bg-gray-200 my-4 sbnbDesktop:hidden mobile-separator" data-section-separator></div>

  <h4
    [routerLink]="['/settings/gx']"
    routerLinkActive="gx-active-link"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    #rla6="routerLinkActive">
    <img src="assets/iconography/settings.svg" alt="" width="20px" height="20px" />
    <span fxFlex>Settings</span>
  </h4>

  <div *ngIf="isInboxAIEnabled">
    <div class="h-px w-full bg-gray-200 my-4 sbnbDesktop:hidden mobile-separator" data-section-separator></div>

    <h4
      [routerLink]="['/gx/inbox-ai']"
      routerLinkActive="gx-active-link"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      #rla9="routerLinkActive"
      class="mt-8 !mb-3 flex">
      <sbnb-icon
        [size]="20"
        class="magic"
        [ngClass]="{ 'text-smoke-darker': !rla9.isActive, 'text-raspberry-darker': rla9.isActive }"
        [icon]="'magic'"
        onload="SVGInject(this)"></sbnb-icon>
      <span fxFlex>Inbox AI</span>
      <sbnb-badge context="positive">new</sbnb-badge>
    </h4>
  </div>

  <div class="h-px w-full bg-gray-200 my-4 sbnbDesktop:hidden mobile-separator" data-section-separator></div>

  <h4 routerLink="/gx/rental-agreements" class="mt-8 !mb-3 flex gap-2 no-details">
    <img src="assets/iconography/team.svg" alt="Guest portal icon" width="20px" height="20px" />
    <span class="flex-1">Guest portal</span>
  </h4>

  <div class="pl-7">
    <a class="block mb-4 rental-agreements" routerLink="/gx/rental-agreements" routerLinkActive="gx-active-link">
      Rental agreements
    </a>
  </div>
</div>
