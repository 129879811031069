<div fxLayout="row" (click)="expanded = !expanded" class="clickable" style="margin-bottom: 12px">
  <div fxFlex class="cera-heading night-darker">Property access</div>
  <img src="/assets/iconography/small-arrow-bottom.svg" alt="expand" />
</div>

<mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>

<div *ngIf="!loading && smartlockCodes?.length" [hidden]="!expanded">
  <ng-container *ngFor="let smartlockCode of smartlockCodes">
    <div class="text__small" style="margin-bottom: 8px" fxLayout fxLayoutAlign="start center">
      <sbnb-icon
        icon="padlock"
        [size]="16"
        class="mr-1"
        [class.text-night-lighter]="smartlockCode.smartlock"
        [class.lock-danger]="!smartlockCode.smartlock"></sbnb-icon>
      <span fxFlex>
        <span
          [ngClass]="{
            'raspberry-darker': !smartlockCode.smartlock
          }">
          {{ smartlockCode?.smartlock?.display_name || 'Lock Disconnected' }}
        </span>
        <sbnb-cs-debug-item
          *ngIf="smartlockCode?.smartlock?.lock_id"
          label="lock_id"
          [value]="smartlockCode.smartlock.lock_id"
          style="font-size: 10px"></sbnb-cs-debug-item>
      </span>
      <mat-spinner *ngIf="codeProvisionRecentlyRequested" [diameter]="16" style="margin-right: 10px"></mat-spinner>

      <ng-container
        *ngIf="
          smartlockCode.pin_code &&
            smartlockCode.is_reservation_accepted === true &&
            smartlockCode.pin_code &&
            smartlockCode.statuses.length > 1 &&
            (smartlockCode.latestStatus || smartlockCode.is_checked_out === true);
          else resetLockCode
        ">
        <span
          class="text__pincode clickable strong"
          *ngIf="
            smartlockCode.is_checked_out === true ||
            (smartlockCode.latestStatus && smartlockCode.latestStatus.name.toLowerCase()) as status
          "
          [ngClass]="{
            pincode__default:
              (smartlockCode.is_checked_out === true || status === SmartlockStatuses.Pending) &&
              smartlockCode.smartlock,
            pincode__info:
              (status === SmartlockStatuses.Created && smartlockCode.smartlock) ||
              (status === SmartlockStatuses.Pending && !smartlockCode.smartlock) ||
              (status === SmartlockStatuses.Created && !smartlockCode.smartlock),
            'pincode__light-success': status === SmartlockStatuses.VisibleOnLock,
            pincode__success: status === SmartlockStatuses.Active
          }"
          [matTooltip]="'Click to copy to clipboard'"
          ngxClipboard
          [cbContent]="smartlockCode.pin_code"
          (click)="copiedToClipboard()">
          {{ smartlockCode.pin_code }}
        </span>
      </ng-container>

      <ng-template #resetLockCode>
        <span class="text__medium raspberry-darker clickable" (click)="triggerPinCodeSet(smartlockCode)">
          Retry Code
        </span>
      </ng-template>
    </div>

    <div fxLayout fxLayoutAlign="start center">
      <sbnb-timeline
        fxFlex
        *ngIf="smartlockCode.timelines"
        [timelines]="smartlockCode.timelines"
        [ngClass]="{
          text__small: smartlockCode.statuses.length === 1
        }"></sbnb-timeline>
    </div>
  </ng-container>

  <a
    fxFlex
    *ngIf="!loading && smartlockCodes.length === 0"
    class="text__medium raspberry-darker clickable"
    style="font-size: 0.875em"
    [routerLink]="['/apps']"
    (click)="trackEvent()">
    + Add a smart lock
  </a>
</div>
