import { Pipe, PipeTransform } from '@angular/core';
import { isTomorrow } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isTomorrow',
})
export class IsTomorrowPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return isTomorrow(value);
  }
}
