export enum LocalStorageKey {
  isProduction = 'isProduction',
  isCS = 'isCS',
  analyticsEnabled = 'analyticsEnabled',
  user = 'user',
  featureFlags = 'feature-flags',
  featureFlagsPostHog = 'feature-flags-ph',
  optimizely = 'optimizely',
  signUpUser = 'signup-user',
  isAirbnbSignUp = 'is-airbnb-signup',
  signUpAirbnbState = 'signup-airbnb-state',
  signUpAirbnbStateNew = 'signup-airbnb-state-new',
  signUpAirbnbChannelUserId = 'signup-airbnb-channel-user-id',
  isTester = 'is-tester',
  sidebarCardsState = 'sidebarCardsState',
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  twoFactorAuthUuid = 'hospitable-twoFactorAuthUuid',
  QuickbooksUpsellDismissed = 'quickbooksUpsellDismissed',
}

export type LocalStoragePayload<D = unknown> = string | { data: D; expiresAt?: number };
