import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-more-menu-trigger',
  template: `
    <button [disabled]="disabled" mat-icon-button class="more-menu-trigger">
      <sbnb-icon icon="more"></sbnb-icon>
    </button>
  `,
  styles: [
    `
      @import 'variables';
      button.more-menu-trigger {
        color: $night-lighter;

        &:disabled {
          background-color: transparent;
          color: $smoke-darker;
        }

        &:hover:not(:disabled),
        &:active:not(:disabled),
        &:focus:not(:disabled) {
          color: $raspberry-darker;
          background-color: $smoke-lighter;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreMenuTriggerComponent implements OnInit {
  @Input() disabled = false;
  @Input() suppressClick = false;
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (!this.suppressClick) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  constructor() { }

  ngOnInit(): void { }
}
