import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import * as confetti from 'canvas-confetti';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const COUNT = 200;

/**
 * Full page confetti component
 * Fire a little confetti with specific options
 * or make it rain with a preset explosion
 */
@Component({
  standalone: false,
  selector: 'sbnb-confetti',
  template: `
    <canvas id="confetti" *ngIf="showCanvas$ | async"></canvas>
  `,
  styles: [
    `
      canvas#confetti {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
      }
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfettiComponent implements OnInit {
  @Input() time = 0;
  private _confettiFiring$ = new BehaviorSubject<boolean>(false);
  showCanvas$ = this._confettiFiring$.pipe(debounceTime(100));

  constructor() { }

  ngOnInit(): void { }

  public fireConfetti(opts: confetti.Options = {}) {
    this._confettiFiring$.next(true);
    // use non-blocking worker version
    const confettiInstance = confetti.create(document.getElementById('confetti') as HTMLCanvasElement, {
      resize: true,
      useWorker: true,
    });

    // returns a promise, in case there are clean up items to do.
    confettiInstance(opts).then(() => {
      this._confettiFiring$.next(false);
    });

    if (this.time) {
      setTimeout(() => {
        confettiInstance.reset();
      }, this.time);
    }
  }

  public makeItRain() {
    this.fireConfetti({
      particleCount: this.particleCountFromRatio(0.25),
      spread: 26,
      startVelocity: 55,
    });
    this.fireConfetti({
      particleCount: this.particleCountFromRatio(0.2),
      spread: 60,
    });
    this.fireConfetti({
      particleCount: this.particleCountFromRatio(0.35),
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    this.fireConfetti({
      particleCount: this.particleCountFromRatio(0.1),
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    this.fireConfetti({
      particleCount: this.particleCountFromRatio(0.1),
      spread: 120,
      startVelocity: 45,
    });
  }

  private particleCountFromRatio(ratio: number) {
    return Math.floor(COUNT * ratio);
  }
}
