export interface GuestVettingData {
  reservation: {
    status: string;
    source: string;
    integration_source: string;
    user_id: string;
    listing_id: string;
    listing_name: string;
    listing_nickname: string;
    listing_city: string;
    listing_address: string;
    listing_timezone_name: string;
    confirmation_code: string;
    number_of_guests: number;
    nights: number;
    total_price: number;
    check_in_date: string;
    check_in_time: number;
    check_out_date: string;
    check_out_time: string;
    guest: {
      first_name: string;
      last_name: string;
      location: string;
      birth_date: string | null;
      email: string;
      phone: string;
      metadata: null;
    };
    average_daily_rate: number;
    created_at: string;
    updated_at: string;
    id: string;
    guest_portal_url: string;
    origin_reservation_id: string;
    verification_status: {
      id: string;
      listing_id: string;
      confirmation_code: string;
      pms_status: string;
      guestportal_completed: string;
      reason: string;
      business_rules: unknown[];
      status: string;
      supervised: null;
    };
    guestportal: {
      id: string;
      listing_id: string;
      confirmation_code: string;
      address: string;
      address_location: {
        zipcode: string;
        geo: {
          lat: number;
          lon: number;
        };
        country: string;
        country_code: string;
        city: string;
        street: string;
        state: string;
      };
      guest_portal_url: string;
    };
    identification: {
      first_name: string | null;
      last_name: string | null;
      middle_name: string | null;
      document_number: unknown | null;
      document_type: string | null;
      nationality: string | null;
      sex: string | null;
      address: unknown | null;
      birth_date: string | null;
      expiry_date: string | null;
      issue_date: string | null;
    };
  };
  summary: {
    guest_portal_status: GuestPortalStatus;
    guest_portal_last_screen: unknown | null;
    verification_composite_status: string;
    verification_status: VerificationStatus;
    verification_status_reason: string;
    verification_status_notes: string | null;
    supervised_status: SupervisedStatus | null;
    supervised_decline_reason: unknown | null;
    reservation_status: ReservationStatus;
    risk_color: string;
    top_risks: string | null[];
    top_recommendations: string | null[];
    details: any; // todo
  };
}

export enum GuestPortalStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum VerificationStatus {
  PENDING = 'pending',
  REVIEW = 'review',
  VERIFIED = 'verified',
}

export enum ReservationStatus {
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  PENDING = 'pending',
  DENIED = 'denied',
}

export enum SupervisedStatus {
  APPROVE = 'approve',
  DECLINE = 'decline',
}
