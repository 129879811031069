import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Language } from '@app/shared/models/language';

@Component({
  standalone: false,
  selector: 'sbnb-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent implements OnInit {
  /**
   * Can the language be changed - dictated by the backend
   */
  @Input() canChangeLanguage: boolean;

  /**
   * The currently active Language object
   */
  @Input() currentLanguage: Language;

  /**
   * A list of available languages according to the backend
   */
  @Input() availableLanguages: Language[];

  /**
   * Emits the language code when a new language is selected. For example: fr
   */
  @Output() languageChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;

  /**
   * The current search criteria typed by the user for the available languages
   */
  searchCriteria: string;

  constructor() { }

  ngOnInit(): void { }

  /**
   * Fires when the menu is opened, to auto focus the searchInput
   */
  langMenuOpened(): void {
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }, 100);
  }

  /**
   * Fires when a new language has been selected
   */
  selectedNewLanguage(newLangCode: string): void {
    this.languageChange.emit(newLangCode);
  }
}
