import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProgressType } from './progress-bar.interface';

@Component({
  standalone: false,
  selector: 'sbnb-progress-bar-simple',
  templateUrl: './progress-bar-simple.component.html',
  styleUrls: ['./progress-bar-simple.component.scss'],
})
export class ProgressBarSimpleComponent implements OnInit, OnChanges {
  @Input() progress: number;
  @Input() type: ProgressType = ProgressType.Success;

  ProgressType = ProgressType;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['progress'] && changes['progress'].currentValue) {
      this.progress = changes['progress'].currentValue;
    }
  }

  getProgress() {
    return this.progress;
  }
}
