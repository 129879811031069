import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DonutChart } from './donut-chart.component.interface';

@Component({
  selector: 'hospitable-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit {
  @Input() chart: DonutChart;

  chartData: ChartData<'doughnut'>;
  chartOptions: ChartConfiguration<'doughnut'>['options'];

  ngOnInit() {
    this.getChartOptions();
  }

  getChartOptions() {
    if (this.chart && this.chart.datasets) {
      this.chartOptions = {
        responsive: false,
        cutout: 90,
        plugins: {
          legend: {
            position: 'left',
            display: false,
          },
        },
      };

      this.chartData = this.chart;
    }
  }
}
