import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-hero-card',
  template: `
    <div
      class="hero-card"
      [ngClass]="{
        active: active,
        emphasized: emphasized,
        positive: positive,
        hover: useHover,
        outlined: outlined
      }">
      <span *ngIf="active" class="ribbon">
        <mat-icon [matTooltip]="activeTooltip">check</mat-icon>
      </span>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      .hero-card {
        border-radius: 8px;
        padding: 24px;
        border: 1px solid $smoke;
        width: 100%;
        height: 100%;
        color: $night-darker;
        background-color: $white;

        &.hover:hover {
          box-shadow: $box-shadow;
          border-color: $grape-darker;
        }

        &.hover:not(.active):hover {
          cursor: pointer;
        }
      }

      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      .active,
      .outlined {
        background-color: $white;
        border-color: $grape-darker;
        position: relative;
        overflow: hidden;

        .ribbon {
          color: $white;
          background-color: $grape-darker;
          border-radius-top-right: 8px;
          position: absolute;
          top: -45px;
          right: -45px;
          height: 80px;
          width: 80px;
          transform: rotate(45deg);
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding: 2px 0;

          * {
            transform: rotate(-45deg);
          }
        }
      }

      .emphasized {
        background-color: $smoke-lighter;
      }

      .positive {
        border-color: $grass-darker;
        background-color: $grass-lighter;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroCardComponent implements OnInit {
  @Input() emphasized = false;
  @Input() positive = false;
  @Input() useHover = false;
  @Input() active = false;
  @Input() activeTooltip = 'Activated';
  @Input() outlined = false;
  constructor() { }

  ngOnInit(): void { }
}
