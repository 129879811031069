import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-mini-listing-img',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mini-listing-img.component.html',
  styleUrls: ['./mini-listing-img.component.scss'],
})
export class MiniListingImgComponent {
  @Input() imgSrc = '';
  @Input() imgAlt = '';
  @Input() imgWidth = '40px';
  @Input() imgHeight = '32px';
}
