import { Pipe, PipeTransform } from '@angular/core';
import { isThisMonth } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isThisMonth',
})
export class IsThisMonthPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return isThisMonth(value);
  }
}
