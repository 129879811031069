<mat-menu class="filters__cdk-menu-new" (closed)="modalClose()">
  <!-- If we have more than 9 items, we display a local search -->
  <input *ngIf="workingFilter.options && workingFilter.options.length >= 9" class="filters__input-list-search"
    [(ngModel)]="searchCriteria[filter.key]" type="text" placeholder="Search" (click)="$event.stopPropagation()" />

  <div *ngIf="workingFilter" (click)="$event.stopPropagation()" class="filters__cdk-menu-new-inner">
    <!-- This select filter has the ability to select from multiple fields -->
    <div *ngIf="workingFilter.columns && workingFilter.columns.length > 0" class="filters__select-columns">
      <mat-form-field class="input__mini fw">
        <mat-select [(ngModel)]="workingFilter.initial_payload.column">
          <mat-option *ngFor="let option of workingFilter.columns" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="!workingFilter.options || workingFilter.options.length === 0">
      <div [ngSwitch]="workingFilter.key">

        <div *ngSwitchDefault>No {{ workingFilter.entity_plural }} found.</div>
      </div>
    </ng-container>

    <div *ngIf="workingFilter && workingFilter.options && workingFilter.options.length > 0"
      class="filters__select-btn-container flex flex-start  items-center gap-2">
      <button class="filters__select-btns  flex-grow items-center" (click)="selectAllClicked()">Select
        all</button>
      <button class="filters__select-btns  flex-grow  items-center" (click)="clearClicked()">Clear</button>
    </div>

    <div *ngIf="workingFilter && workingFilter.options && workingFilter.options.length > 0" class="cdk__scrollable">
      <ng-container *ngFor="let item of workingFilter.options" trackBy="selectTrackById">
        <mat-checkbox *ngIf="filtersMatchSearchCriteria(item.label, searchCriteria[filter.key])" [checked]="
          isCheckboxChecked(workingFilter, item.value)
          " (change)="checkboxValueChanged($event, item.value)">
          <div class="flex flex-start items-center gap-2">
            <img *ngIf="item.picture" [src]="item.picture" class="filters__option-pic" />
            <span class="flex">{{ item.label }}</span>
            <img *ngIf="item.secondary_icon" class="filter__icon"
              src="/assets/icons/filters/{{ item.secondary_icon }}.svg" onload="SVGInject(this)" />
          </div>
        </mat-checkbox>
      </ng-container>
    </div>
  </div>

  <button class="filter__btn-done">Done</button>
</mat-menu>