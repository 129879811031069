import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DirectSubscriptionService } from '@app/modules/direct/services/direct-subscription.service';
import { UsersFacade } from '@app/shared/+state/users';
import { EntitlementEnum } from '@app/shared/interfaces';
import { DialogDirectQuotesService } from '@app/shared/services/direct-quotes/dialog-direct-quotes.service';
import { NewDirectCustomQuote } from '@app/shared/services/direct-quotes/direct-quotes.service';
import { DialogManualBookingsService } from '@app/shared/services/manual-bookings/dialog-manual-bookings.service';
import { ManualBooking } from '@app/shared/services/manual-bookings/manual-bookings.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-add-manual-booking-button',
  templateUrl: './add-manual-booking-button.component.html',
  styleUrls: ['./add-manual-booking-button.component.scss'],
})
export class AddManualBookingButtonComponent {
  constructor(
    private readonly dialog: DialogManualBookingsService,
    private directDialog: DialogDirectQuotesService,
    private directSubscriptionService: DirectSubscriptionService,
    private readonly usersFacade: UsersFacade
  ) { }

  @Input() startDate: Date; // An optional default start date for a new booking
  @Input() endDate: Date; // An optional default end date for a new
  @Input() propertyId: number; // An optional property ID for the new booking
  @Input() type: 'primary' | 'primary-teal' | 'secondary' | 'text' | 'text-secondary' = 'primary';
  @Input() size: 'normal' | 'mini' = 'mini';
  @Input() fullWidth = false;
  @Output() bookingChanged: EventEmitter<{
    created?: ManualBooking;
    updated: ManualBooking;
    cancelled: ManualBooking;
  }> = new EventEmitter();

  @Output() customQuoteCreated: EventEmitter<{
    quote: NewDirectCustomQuote;
  }> = new EventEmitter();

  public isAddDirectBookingEnabled$ = combineLatest([
    this.directSubscriptionService.isOnboarded$,
    this.directSubscriptionService.isBasic$,
    this.directSubscriptionService.isPremium$,
    this.usersFacade.entitlementForFeature$(EntitlementEnum.Direct),
  ]).pipe(
    map(
      ([onboarded, isBasic, isPremium, directEntitlement]) =>
        onboarded && (isBasic || isPremium) && directEntitlement?.available
    )
  );

  openDialog() {
    this.dialog.openDialog(false, 'create', null, this.startDate, this.endDate, this.propertyId).subscribe((res) => {
      this.bookingChanged.emit(res);
    });
  }

  openDirectDialog() {
    this.directDialog.open(null, this.startDate, this.endDate, this.propertyId).subscribe((res) => {
      if (res?.quote?.is_custom_quote) {
        this.customQuoteCreated.emit(res.quote);
      } else {
        this.bookingChanged.emit(res);
      }
    });
  }
}
