import { Component, ViewChild, Input, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { cloneDeep } from 'lodash-es';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Filter, FilterSelectOption } from '@app/shared/interfaces/lib/filter.interface';
import { isArray } from '@app/shared/utils';

@Component({
  selector: 'hospitable-filter-menu-select',
  templateUrl: './filter-menu-select.component.html',
  styleUrls: ['./filter-menu-select.component.scss'],
})
export class FilterMenuSelectComponent implements OnChanges {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  @Input() filter: Filter;
  @Input() scopeKey: string;

  @Output() applyFilter: EventEmitter<Filter> = new EventEmitter();

  workingFilter: Filter; // a working, local copy of the input
  searchCriteria: any = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter']) {
      this.workingFilter = cloneDeep(changes['filter'].currentValue);
    }
  }

  modalClose() {
    // Lets update the service with our working copy of the filter
    // TODO - A basic stringify comparison may not be enough to know something has changed. Revisit
    if (JSON.stringify(this.workingFilter) !== JSON.stringify(this.filter)) {
      this.applyFilter.emit(this.workingFilter);
    }
  }

  isCheckboxChecked(filter: Filter, value: string) {
    if (filter && filter.initial_payload) {
      if (isArray(filter.initial_payload.values)) {
        return filter.initial_payload.values.indexOf(value) > -1;
      }
      if (isArray(filter.initial_payload)) {
        return filter.initial_payload.indexOf(value) > -1;
      }
    }

    return false;
  }

  // Given a list of options, and a search string, identify if the string appears in any options and return a filtered list
  public filtersMatchSearchCriteria(option: string, searchCriteria: any) {
    if (!searchCriteria || searchCriteria === '') {
      return true;
    }

    return option.toLowerCase().includes(searchCriteria.toLowerCase());
  }

  selectTrackById(index: number, obj: FilterSelectOption): any {
    return obj.value;
  }

  checkboxValueChanged(change: MatCheckboxChange, value: string) {
    if (change.checked) {
      this.workingFilter.initial_payload.values.push(value);
    } else {
      const index = this.workingFilter.initial_payload.values.findIndex((item: any) => item === value);

      if (index > -1) {
        this.workingFilter.initial_payload.values.splice(index, 1);
      }
    }

    if (
      this.workingFilter &&
      this.workingFilter.options &&
      this.workingFilter.initial_payload.values.length === this.workingFilter.options.length
    ) {
      this.workingFilter.initial_payload.all_selected = true;
    } else {
      this.workingFilter.initial_payload.all_selected = false;
    }
  }

  selectAllClicked() {
    this.clearClicked();

    this.workingFilter.initial_payload.all_selected = true;

    if (this.workingFilter && this.workingFilter.options) {
      this.workingFilter.options.forEach((option) => {
        this.workingFilter.initial_payload.values.push(option.value);
      });
    }
  }

  clearClicked() {
    this.workingFilter.initial_payload.all_selected = false;
    this.workingFilter.initial_payload.values = [];
  }
}
