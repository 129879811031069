import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { BillingFacade } from '@app/shared/+state/billing';
import { UsersFacade } from '@app/shared/+state/users';
import { EntitlementEnum } from '@app/shared/interfaces';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

const CONTACT_SALES_URL = 'https://meetings.hubspot.com/vkovacevic/mogul-discovery';

const CONTACT_CUSTOMER_SUCCESS_URL = `https://meetings.hubspot.com/brian-seelos/brian-mogul-plan`;

/**
 * - Upsells depend on data from the user slice and the billing slice.
 * - Its notoriously tricky to make sure that we don't accidentally try to
 * use subscription data for secondary users.
 * - This service serves as a facade to make it easier to access the right data,
 * accessible by both primary and secondary users.
 */
@Injectable({
  providedIn: 'root',
})
export class UpsellService {
  #router = inject(Router);
  #usersFacade = inject(UsersFacade);
  #billingFacade = inject(BillingFacade);
  #userPermissionsService = inject(UserPermissionsService);

  public hasSmartDeviceEntitlement$ = this.#usersFacade
    .entitlementForFeature$(EntitlementEnum.SmartDevices)
    .pipe(filter((entitlement) => !!entitlement));

  /**
   * Legacy users now have to agree to be billed for smart devices before they can connect
   * an account.
   *
   * Note: Host subscriptions don't have the smart device entitlement. Pro subscriptions have
   * the smart device entitlement, but they don't have to agree to be billed for smart devices and the
   * endpoint will always indicated that they have agreed.
   */
  public requiresDeviceAddon$ = combineLatest([
    this.hasSmartDeviceEntitlement$,
    this.#billingFacade.deviceAddonActivated$,
  ]).pipe(map(([entitlement, deviceAddonActivated]) => entitlement.available && !deviceAddonActivated));

  public ownerStatementLimitedPreview$ = this.#usersFacade.ownerStatementsEntitlement$.pipe(
    filter((entitlement) => !entitlement.unlimited),
    map((entitlement) => Number(entitlement.value) === 1)
  );

  public accountingEnabled$ = combineLatest([
    this.#usersFacade.accountingEntitlement$,
    this.#userPermissionsService.isPrimaryUser(),
  ]).pipe(map(([entitlement, isPrimaryUser]) => isPrimaryUser && entitlement?.available));

  constructor() {
    this.#billingFacade.loadDeviceAddon();
  }

  public contactSalesForMogul(
    source: 'YourPlanPage' | 'StatementsDemoPage' | 'StatementsPublishDialog' | 'StatementsAccounting'
  ) {
    this.#userPermissionsService
      .isPrimaryUser()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.#billingFacade.isActiveSubscription$.pipe(take(1)))
      )
      .subscribe((isActiveCustomer) => {
        const url = isActiveCustomer ? CONTACT_CUSTOMER_SUCCESS_URL : CONTACT_SALES_URL;
        const params = new URLSearchParams();
        params.set('utm_source', source);
        params.set('utm_medium', source);
        params.set('utm_campaign', source);
        window.open(`${url}?${params.toString()}`, '_blank');
      });
  }

  public redirectToPlans() {
    this.#router.navigate(['/settings/your-plan'], {
      queryParams: {
        showPlans: true,
      },
    });
  }
}
