import { Pipe, PipeTransform } from '@angular/core';
import { isWeekend } from 'date-fns';

@Pipe({
  standalone: false,
  name: 'isWeekend',
})
export class IsWeekendPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return isWeekend(value);
  }
}
