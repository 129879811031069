import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RefundDetails } from '@app/shared/services/reservation-cancellation-refund.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-cancel-reservation-amount',
  templateUrl: './dialog-cancel-reservation-amount.component.html',
  styleUrls: ['./dialog-cancel-reservation-amount.component.scss'],
})
export class DialogCancelReservationAmountComponent implements OnInit {
  @Input() initiatedBy: string;
  @Input() refundDetails: RefundDetails;
  @Input() directPlan: 'premium' | 'basic';
  @Input() isCancelling: boolean;

  // The amount to refund is passed to the parent component
  @Output() continue: EventEmitter<number> = new EventEmitter();

  amountToRefund: number = null;

  constructor() { }

  ngOnInit(): void {
    this.setAmountIfInitiatedByHost();
  }

  setAmountIfInitiatedByHost() {
    if (this.initiatedBy === 'host') {
      this.amountToRefund = this.refundDetails.max_refundable_amount;
    }
  }
}
