import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent {
  @Input() title: string;
  @Input() backLink: string;
  @Input() saving: boolean = false;
  @Input() recentlySaved: boolean = false;
  @Input() novaContentId: string;
  @Input() saveButtonShown: boolean = true;
  @Input() sticky: boolean = true;
  @Input() navigateBack: boolean = true;
  @Output() saveClicked: EventEmitter<boolean> = new EventEmitter();

  isScrolled: boolean = false;

  constructor(private el: ElementRef) { }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    let rect = this.el.nativeElement.querySelector('.helptext-wrapper-type-content').getBoundingClientRect();

    // Add the scroll position to the top of the rectangle to get the distance from the page top
    // and then add the element's height to find the bottom position
    let bottomDistance = rect.top + window.pageYOffset + rect.height;

    let offset = 0;
    if (document.body.classList.contains('has__global-notifications')) {
      offset = -116;
    } else {
      offset = -72;
    }

    bottomDistance += offset;

    // Check if the scroll position is greater than the bottom distance
    this.isScrolled = window.pageYOffset > bottomDistance ? true : false;
  }
}
