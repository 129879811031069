import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-full-size-dialog',
  template: `
    <sbnb-close [border]="true" (click)="this.closeDialog.emit()" class="close"></sbnb-close>
    <section class="dialog-content" [ngStyle]="{ 'max-width': maxWidth }"><ng-content></ng-content></section>
  `,
  styles: [
    `
      @import 'variables';
      :host {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
        padding-top: 24px;
      }

      .close {
        position: absolute;
        top: -24px;
        right: -14px;
      }
      .dialog-content {
        max-width: 640px;
        width: 100%;
        margin-top: 18px;
        max-height: 100%;
      }

      @media #{$mobile} {
        :host {
          align-items: flex-start;
        }

        .close {
          top: -12px;
          right: -6px;
        }
      }
    `,
  ],
})
export class FullSizeDialogComponent implements OnInit {
  @Output() closeDialog = new EventEmitter<void>();
  @Input() maxWidth = '750px';

  constructor() { }

  ngOnInit(): void { }
}
