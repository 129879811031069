export interface TributeValue {
  key: string;
  value: string;
  type: TributeValueType;
}

export enum TributeValueType {
  ShortCode = 'sc',
  CustomCode = 'cc',
}
