<div fxLayout fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">
  <span *ngIf="meta.event">
    <strong></strong>
    Send this message
  </span>

  <button
    data-intercom-target="frequency-timing-rule"
    *ngIf="meta.frequency && meta.frequency.length > 1"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="frequencyMenu">
    <span class="frequency">{{ ruleset.metadata.recurring.frequency }}</span>
  </button>

  <button
    data-intercom-target="day-timing-rule"
    *ngIf="meta.day && meta.day.length > 1"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="dayMenu">
    <span class="day">{{ dayLabel }}</span>
  </button>

  <span>at</span>

  <sbnb-time-picker
    class="recurring-ruleset"
    data-intercom-target="time-timing-rule"
    [hours]="ruleset.metadata.recurring.hours"
    [minutes]="ruleset.metadata.recurring.minutes"
    (timeUpdated)="timeChanged($event)"
    (closed)="save()"></sbnb-time-picker>

  <span>
    <strong>during the guest's stay,</strong>
  </span>

  <button
    data-intercom-target="checkin-day-timing-rule"
    *ngIf="meta.checkin_day && meta.checkin_day.length > 1"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="checkinDayMenu">
    <span class="checkin_day">{{ ruleset.metadata.recurring.checkin_day }}</span>
  </button>

  <span>the days that a guest checks in.</span>
</div>

<!-- tobys code -->
<mat-menu #frequencyMenu="matMenu" class="filters__cdk-menu">
  <button *ngFor="let frequency of meta.frequency" mat-menu-item (click)="frequencyChanged(frequency)">
    {{ frequency }}
  </button>
</mat-menu>

<mat-menu #dayMenu="matMenu" class="filters__cdk-menu">
  <button *ngFor="let day of meta.day" mat-menu-item (click)="dayChanged(day)">
    {{ day }}
  </button>
</mat-menu>

<mat-menu #checkinDayMenu="matMenu" class="filters__cdk-menu">
  <button *ngFor="let checkinDay of meta.checkin_day" mat-menu-item (click)="checkinDayChanged(checkinDay)">
    {{ checkinDay }}
  </button>
</mat-menu>
