<div
  *ngIf="rulesetType === 'inquiry'"
  class="setting__container"
  [class.setting__container-active]="settings.preapprove"
  fxLayout
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start"
  fxLayoutAlign="start center">
  <mat-checkbox [(ngModel)]="settings.preapprove" (ngModelChange)="save()">
    <strong>Automatically pre-approve inquiries</strong>
  </mat-checkbox>
  <span class="card__collapsed-helper-text">
    Activating this setting will deactivate pre-approval rules as they would be repetitive.
  </span>
</div>

<!-- autohost -->
<div *ngIf="currentUser.autohost_enabled">
  <div
    *ngIf="
      rulesetType === 'checkin' ||
      rulesetType === 'checkout' ||
      rulesetType === 'recurring:weekly' ||
      rulesetType === 'scheduled:accepted'
    "
    class="setting__container"
    [class.setting__container-active]="settings.delay.has_delay_condition"
    fxLayout
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
    fxLayoutAlign="start center"
    fxLayoutGap="16px">
    <mat-checkbox
      fxFlex.gt-md="1 1 240px"
      [disabled]="!currentUser.autohost_enabled"
      [(ngModel)]="settings.delay.has_delay_condition"
      (ngModelChange)="save()">
      <strong>Require guest verification</strong>
    </mat-checkbox>

    <span fxFlex="1 1 160px" style="margin-bottom: 5px; margin-right: 10px">
      <img style="float: left" height="25px" src="/assets/images/apps/integrations/autohost.jpg" />
      <span class="rose text__small" style="padding-top: 4px; word-break: break-all"><b>Autohost</b></span>
    </span>

    <span class="card__collapsed-helper-text" fxFlex="1 1 auto">Delay messages until guests are verified.</span>
  </div>
</div>
<!--end autohost -->

<ng-container *ngIf="rulesetType === 'checkin' && showSkipLastMinute">
  <div class="setting__container" [class.setting__container-active]="settings.skip_on_last_minute">
    <div fxLayout fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
      <mat-checkbox [(ngModel)]="settings.skip_on_last_minute" (ngModelChange)="save()">
        <strong>Exclude last minute bookings</strong>
      </mat-checkbox>

      <span fxFlex class="card__collapsed-helper-text" *ngIf="!settings.skip_on_last_minute">
        Do not send this message to reservations made {{ rulesetTiming?.unit.value_label }} before the check-in date
      </span>
    </div>

    <div *ngIf="settings.skip_on_last_minute" class="text__small setting__activated-text">
      Any booking confirmed less than
      {{ rulesetTiming?.unit.value_label }} before check-in is considered to be a last minute booking by this rule. In
      case of last minute bookings, the message would be sent 30 minutes after confirming the booking unless this
      setting is activated.
    </div>
  </div>
</ng-container>

<div
  *ngIf="rulesetType === 'checkout'"
  class="setting__container"
  [class.setting__container-active]="settings.review_request"
  fxLayout
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start"
  fxLayoutAlign="start center"
  fxLayoutGap="12px">
  <mat-checkbox [(ngModel)]="settings.review_request" (ngModelChange)="save()">
    <div fxLayoutAlign="start center" fxLayoutGap="12px">
      <strong>Do not send if a guest has already published a review</strong>
    </div>
  </mat-checkbox>

  <img style="width: 24px; height: 24px" src="/assets/iconography/airbnb-rose.svg" alt="airbnb" />
  <span class="rose text__small" style="padding-top: 4px">Airbnb only</span>
</div>

<div
  *ngIf="
    rulesetType === 'checkin' ||
    rulesetType === 'checkout' ||
    rulesetType === 'recurring:weekly' ||
    rulesetType === 'scheduled:accepted'
  "
  class="setting__container"
  [class.setting__container-active]="imageCheckbox?.checked">
  <div
    fxLayout
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
    fxLayoutAlign="start center"
    fxLayoutGap="16px">
    <mat-checkbox [checked]="image || false" #imageCheckbox (change)="maybeDeleteImage($event)">
      <strong>Add a picture</strong>
    </mat-checkbox>

    <span *ngIf="!imageCheckbox.checked" class="card__collapsed-helper-text" fxFlex="1 1 auto">
      The picture should be under 2MB in size. Only one picture can be attached to each rule. Supported on all
      platforms.
    </span>
  </div>

  <div *ngIf="imageCheckbox.checked" class="setting__activated-text">
    Only one picture can be attached to each rule. The picture will be sent first; the picture will appear below the
    text message on the Web, or above the message on mobile apps.
  </div>

  <div *ngIf="!image && imageCheckbox.checked" style="margin-top: 12px">
    <mat-spinner *ngIf="imageLoading" [diameter]="16"></mat-spinner>
    <input
      id="file"
      [style.opacity]="imageLoading ? 0 : 1"
      class="image__input"
      #imageInput
      type="file"
      accept="image/*"
      (change)="processImage(imageInput)" />
    <label for="file" [style.opacity]="imageLoading ? 0 : 1" class="upload__file">Upload File</label>
  </div>

  <div *ngIf="imageCheckbox.checked" style="margin-top: 12px">
    <a *ngIf="image" style="display: block" class="margin-20" [href]="image" target="_blank">
      <img class="settings__image" [src]="image" />
      <br />
    </a>

    <button
      *ngIf="image"
      class="button__small button__secondary upload__file"
      [disabled]="removingImage"
      type="submit"
      mat-flat-button
      (click)="removeImage()">
      Delete
      <mat-spinner *ngIf="removingImage" class="button__spinner" diameter="16"></mat-spinner>
    </button>
  </div>
</div>

<div *ngIf="isQuestionRuleset" class="setting__container">
  <div
    fxLayout
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
    fxLayoutAlign="start center"
    fxLayoutGap="8px">
    <mat-checkbox
      #relevantCheckbox
      [checked]="questionStages.prebooking || questionStages.precheckin || questionStages.staying">
      <strong>This reply is relevant...</strong>
    </mat-checkbox>

    <span *ngIf="!relevantCheckbox.checked" class="card__collapsed-helper-text">
      You can refine at what stage of the guest’s stay this reply is relevant.
    </span>
  </div>

  <div *ngIf="relevantCheckbox.checked" class="setting__activated-text">
    You can refine here at what stage of the guest's travel that reply is relevant. The same reply wouldn't necessarily
    make sense at every step of the process. A question about WiFi before a booking is about checking that WiFi is
    provided, fast and reliable; a question about WiFi during the stay is an issue faced by the guest.
  </div>

  <div
    *ngIf="relevantCheckbox.checked"
    fxLayout
    fxLayoutAlign="stretch"
    fxLayout.lt-md="column"
    fxLayoutGap="18px"
    style="margin-top: 20px">
    <div
      fxFlex
      class="selection__box"
      [class.selection__box-selected]="questionStages.prebooking"
      fxLayout="column"
      fxLayoutGap="16px"
      (click)="questionCheckboxChange(!questionStages.prebooking, 'prebooking')">
      <strong>Before reservation</strong>
      <div class="text__small">When asked before reservation is accepted.</div>
    </div>

    <div
      fxFlex
      class="selection__box"
      [class.selection__box-selected]="questionStages.precheckin"
      fxLayout="column"
      fxLayoutGap="16px"
      (click)="questionCheckboxChange(!questionStages.precheckin, 'precheckin')">
      <strong>Before check-in</strong>
      <div class="text__small">Before the guest checks in.</div>
    </div>

    <div
      fxFlex
      class="selection__box"
      [class.selection__box-selected]="questionStages.staying"
      fxLayout="column"
      fxLayoutGap="16px"
      (click)="questionCheckboxChange(!questionStages.staying, 'staying')">
      <strong>Staying</strong>
      <div class="text__small">While the guest is staying.</div>
    </div>
  </div>
</div>

<div
  *ngIf="rulesetType === 'checkin' || rulesetType === 'checkout'"
  class="setting__container"
  [class.setting__container-active]="settings.conditions.nights">
  <div
    fxLayout
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
    fxLayoutAlign="start center"
    fxLayoutGap="16px">
    <mat-checkbox
      #durationCheckbox
      [checked]="!!settings.conditions.nights || false"
      (change)="removeNightRestriction($event)">
      <strong>Specify a reservation duration</strong>
    </mat-checkbox>

    <span class="card__collapsed-helper-text">
      Sometimes you may want to send different messages depending on the duration of the reservation.
    </span>
  </div>

  <div>
    <mat-form-field *ngIf="durationCheckbox.checked" class="settings__select">
      <mat-select placeholder="Select..." [(ngModel)]="settings.conditions.nights" (ngModelChange)="save()">
        <mat-option value="1">Include this message for 1 night reservations only.</mat-option>
        <mat-option value="2-">Include this message for 1 and 2 nights reservations only.</mat-option>
        <mat-option value="2">Include this message for 2 nights reservations only.</mat-option>
        <mat-option value="2+">Include this message for 2 nights reservations and longer.</mat-option>
        <mat-option value="3">Include this message for 3 nights reservations only.</mat-option>
        <mat-option value="3+">Include this message for 3 nights reservations and longer.</mat-option>
        <mat-option value="4+">Include this message for 4 nights reservations and longer.</mat-option>
        <mat-option value="5+">Include this message for 5 nights reservations and longer.</mat-option>
        <mat-option value="6+">Include this message for 6 nights reservations and longer.</mat-option>
        <mat-option value="7+">Include this message for 7 nights reservations and longer.</mat-option>
        <mat-option value="8+">Include this message for 8 nights reservations and longer.</mat-option>
        <mat-option value="9+">Include this message for 9 nights reservations and longer.</mat-option>
        <mat-option value="10+">Include this message for 10 nights reservations and longer.</mat-option>
        <mat-option value="11+">Include this message for 11 nights reservations and longer.</mat-option>
        <mat-option value="12+">Include this message for 12 nights reservations and longer.</mat-option>
        <mat-option value="13+">Include this message for 13 nights reservations and longer.</mat-option>
        <mat-option value="14+">Include this message for 14 nights reservations and longer.</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div
  *ngIf="rulesetType === 'checkpoint'"
  class="setting__container"
  [class.setting__container-active]="settings.msg_to_guest_start_only"
  fxLayout
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start"
  fxLayoutAlign="start center">
  <mat-checkbox [(ngModel)]="settings.msg_to_guest_start_only" (ngModelChange)="save()">
    <strong>Never send this message if a conversation has already started</strong>
  </mat-checkbox>
  <span class="card__collapsed-helper-text">
    This message will not be sent if a conversation with the guest has started
  </span>
</div>

<div *ngIf="isTeamRuleset" class="setting__container" [class.setting__container-active]="settings.send_me_a_copy">
  <div fxLayout fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutAlign="start center">
    <mat-checkbox [(ngModel)]="settings.send_me_a_copy" (ngModelChange)="save()">
      <strong>Send a copy via email</strong>
    </mat-checkbox>
    <span class="card__collapsed-helper-text">
      A copy of the message will be sent to {{ currentUser?.team?.owner_email || 'the primary users email address.' }}.
    </span>
  </div>
</div>

<div
  *ngIf="
    isTeamRuleset &&
    notifyVia === 'email' &&
    (rulesetType === 'team:accepted' || rulesetType === 'team:cancelled' || rulesetType === 'team:alteration')
  "
  class="setting__container"
  [class.setting__container-active]="settings.send_ical"
  fxLayout
  fxLayoutAlign="start center">
  <mat-checkbox [(ngModel)]="settings.send_ical" (ngModelChange)="save()">
    <strong>Send an iCal attachment</strong>
  </mat-checkbox>
  <span class="card__collapsed-helper-text">
    An iCal attachment will be included in the message, allowing teammates to see the task on their calendar.
  </span>
</div>
