import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'currencyCode',
})
export class CurrencyCodePipe implements PipeTransform {
  transform(currencyCode: string, ...args: unknown[]): unknown {
    return getCurrencySymbol(currencyCode, 'narrow');
  }
}
