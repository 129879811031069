import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  /**
   * The diameter of the spinner in pixels
   * @default 32
   */
  @Input() size = 32;

  /**
   * Minimum height of the spinner container
   * @default '100%'
   */
  @Input() minHeight = '100%';

  /**
   * Whether to show the spinner in a muted state
   * @default false
   */
  @Input() muted = false;
}
