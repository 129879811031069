import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NotificationType } from '@app/shared/interfaces';
import { CustomCodeService } from '@app/shared/services/custom-codes/custom-code.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-create-custom-code',
  templateUrl: './dialog-create-custom-code.component.html',
  styleUrls: ['./dialog-create-custom-code.component.scss'],
})
export class DialogCreateCustomCodeComponent implements OnInit {
  cardsCollapsed: any[];
  cc: any;
  conditionsMeta: any;
  saving: boolean;
  routeOnSave: boolean;

  constructor(
    private customCodeService: CustomCodeService,
    private router: Router,
    public dialogRef: MatDialogRef<DialogCreateCustomCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private toast: ToastNotificationsService
  ) { }

  ngOnInit() {
    this.customCodeService.getConditionsMeta().subscribe((res) => {
      this.conditionsMeta = { conditions: res };
    });

    this.routeOnSave = this.dialogData.routeOnCreate ? this.dialogData.routeOnCreate : false;

    if (this.dialogData.rule) {
      // If we passed in a rule, use its data to prepopulate the fields
      this.cardsCollapsed = [];
      this.cc = this.dialogData.rule;
      this.cc.all_hosts = false;
      this.cc.all_platforms = false;
      this.cc.all_properties = false;
      this.cc.hosts = [];
      this.cc.platforms = [];
      this.cc.properties = [];
      this.cc.updated_text = this.cc.text;
    } else {
      this.cardsCollapsed = ['scope'];
      this.cc = {
        all_conditions: true,
        all_hosts: true,
        all_platforms: true,
        all_properties: true,
        key: '',
        text: '',
        hosts: [],
        platforms: [],
        properties: [],
      };
    }
  }

  toggleCard(cardName: string) {
    const exists = this.cardsCollapsed.findIndex((ele) => ele === cardName);

    if (exists !== -1) {
      this.cardsCollapsed.splice(exists, 1);
    } else {
      this.cardsCollapsed.push(cardName);
    }
  }

  isCardCollapsed(cardName: string): boolean {
    return this.cardsCollapsed.find((ele) => ele === cardName) ? true : false;
  }

  handleConditionUpdate(newScopeKeys) {
    this.cc.all_conditions = newScopeKeys.all_conditions;
    this.cc.condition = newScopeKeys.condition;
  }

  handleScopeChange(event) {
    console.log(event);
    this.cc.all_platforms = event.all_platforms;
    this.cc.all_hosts = event.all_hosts;
    this.cc.all_properties = event.all_properties;
    this.cc.properties = event.properties;
    this.cc.hosts = event.hosts;
    this.cc.platforms = event.platforms;
  }

  close() {
    this.dialogRef.close();
  }

  createCustomCode() {
    this.saving = true;

    if (!this.cc.updated_text) {
      this.toast.open(`Please enter some text for your custom code`, 'Dismiss', NotificationType.Error);
      this.saving = false;
      return;
    }

    this.cc.text = this.cc.updated_text;

    this.customCodeService.createCustomCode(this.cc).subscribe((res: any) => {
      if (res.error) {
        this.saving = false;
        return;
      }

      this.saving = false;

      if (this.routeOnSave) {
        this.router.navigate(['/gx/custom-codes/code', res.data.id]);
      }

      this.dialogRef.close();
    });
  }
}
