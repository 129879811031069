import { Pipe, PipeTransform } from '@angular/core';
import { formatToTimeZone } from 'date-fns-timezone';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  standalone: false,
  name: 'currentTimeInTz',
})
export class CurrentTimeInTzPipe implements PipeTransform {
  transform(timeZone: string, format: string): Observable<string> {
    return timer(0, 90000).pipe(map(() => formatToTimeZone(new Date(), format, { timeZone })));
  }
}
