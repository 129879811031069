<div *ngIf="task" class="outer" (click)="$event.stopPropagation()">
  <div class="assign__header" fxLayout fxLayoutAlign="start center" fxLayoutGap="16px">
    <strong class="ellipsis">{{ task.task_rule?.name ? task.task_rule.name : task.task_type_string }}</strong>

    <strong *ngIf="mode === 'new'" style="padding: 6px 0">New task</strong>

    <span *ngIf="task.duration" class="duration strong" [matTooltip]="'This task usually takes ' + task.duration">
      {{ task.duration }}
    </span>
  </div>

  <div class="assign__date-container">
    <sbnb-cs-debug-item *ngIf="task && task.id" label="task_id" [value]="task.id"></sbnb-cs-debug-item>
    <sbnb-cs-debug-item
      *ngIf="task && task.teammate?.id"
      label="teammate_id"
      [value]="task.teammate?.id"
      [borderBottom]="true"></sbnb-cs-debug-item>

    <!-- Start -->
    <div class="assign__dates" fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
      <span style="width: 35px">Start:</span>
      <span class="date-picker" #dateMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="dateMenu">
        {{ task.start_date | date: 'mediumDate' }}
      </span>

      <sbnb-time-picker
        [hours]="startHour"
        [minutes]="startMin"
        (timeUpdated)="startUpdated($event)"></sbnb-time-picker>

      <mat-menu #dateMenu="matMenu" class="filters__cdk-menu filters__cdk-menu-320">
        <sbnb-date-picker
          *ngIf="task.start_date"
          [startDate]="task.start_date"
          [numMonths]="1"
          [checkInOutStyling]="false"
          (click)="$event.stopPropagation()"
          (datesSelected)="task.start_date = $event; dateMenuTrigger.closeMenu()"></sbnb-date-picker>
      </mat-menu>
    </div>

    <!-- End -->
    <div class="assign__dates" fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
      <span style="width: 35px">End:</span>
      <span class="date-picker" #dateMenuTrigger2="matMenuTrigger" [matMenuTriggerFor]="dateMenu2">
        {{ task.end_date | date: 'mediumDate' }}
      </span>

      <sbnb-time-picker [hours]="endHour" [minutes]="endMin" (timeUpdated)="endUpdated($event)"></sbnb-time-picker>

      <mat-menu #dateMenu2="matMenu" class="filters__cdk-menu filters__cdk-menu-320">
        <sbnb-date-picker
          *ngIf="task.end_date"
          [startDate]="task.end_date"
          [numMonths]="1"
          [checkInOutStyling]="false"
          (click)="$event.stopPropagation()"
          (datesSelected)="task.end_date = $event; dateMenuTrigger2.closeMenu()"></sbnb-date-picker>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="mode === 'new'" class="assign__box">
    <mat-radio-group
      class="assign__radio"
      [(ngModel)]="taskType"
      (ngModelChange)="filterTeamMembers($event)"
      fxLayout="row wrap">
      <mat-radio-button fxFlex="50" value="1">Cleaning</mat-radio-button>
      <mat-radio-button fxFlex="50" value="2">Check-in</mat-radio-button>
      <mat-radio-button fxFlex="50" value="4">Check-out</mat-radio-button>
      <mat-radio-button fxFlex="50" value="5">Maintenance</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- You have to be able to manage all tasks to be able to see all teammates.
   If you're just managing your own tasks, there's no need to see other teammates. -->
  <div class="teammate__selection" *ngIf="canManageAllTasks$ | async">
    <input
      *ngIf="possibleTeammates && possibleTeammates.length >= numListOptionsForSearchBar"
      class="filters__input-list-search"
      [(ngModel)]="searchCriteria"
      type="text"
      placeholder="Search" />

    <mat-spinner *ngIf="loading" diameter="16" style="margin: 16px"></mat-spinner>

    <div *ngIf="possibleTeammates && possibleTeammates.length > 0">
      <ng-container *ngFor="let member of possibleTeammates">
        <ng-container *ngIf="!member.hidden && member.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1">
          <button
            class="assign__menu-item items-center !flex gap-2"
            [class.assign__selected]="selectedTeammate === member.uuid"
            mat-menu-item
            (click)="selectedTeamMember(member)">
            <mat-checkbox
              class="-ml-2"
              [checked]="member.uuid === selectedTeammate"
              (click)="$event.stopPropagation()"
              (change)="selectedTeamMember(member)"></mat-checkbox>
            <div class="flex gap-2">
              <div class="relative overflow-hidden pb-1">
                <sbnb-team-icon fxFlex="32px" [member]="member"></sbnb-team-icon>
                <span
                  class="preferred__property-indicator"
                  [class.preferred__property-indicator-no]="
                    !member.all_properties && member.properties.indexOf(propertyId) === -1
                  "></span>
              </div>
              <div fxLayout="column">
                <span>{{ member.name }}</span>
                <span class="night-lighter">{{ member.company_name }}</span>
              </div>
            </div>
          </button>
        </ng-container>
      </ng-container>
    </div>
    <div
      *ngIf="(possibleTeammates && possibleTeammates.length === 0 && !loading) || allMatesHidden"
      class="no__teammates text__small">
      You don't have any teammmates that can be assigned this task.
    </div>
  </div>

  <textarea [formControl]="notes" class="assign__textarea noresize text__small" placeholder="Add a note"></textarea>

  <div *ngIf="task?.task_list?.length > 0" class="checklist__dashboard">
    <div class="collapse-checklist" (click)="toggleTaskChecklist()">
      <p>Task Checklist</p>
      <span>
        <img
          [src]="taskChecklistHidden ? '/assets/images/up-arrow.svg' : '/assets/images/down-arrow.svg'"
          class="list__icon"
          width="20px"
          height="20px" />
      </span>
    </div>
    <div [hidden]="!taskChecklistHidden">
      <div *ngFor="let task of task.task_list">
        <ul>
          <li>{{ task.entry | humanize }}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="assign__button flex items-center justify-between">
    <div class="flex gap-2">
      <sbnb-button
        *ngIf="mode !== 'new'"
        [loading]="deleting"
        loadingLabel="Deleting..."
        [disabled]="saving"
        label="Delete"
        (clicked)="deleteTask()"
        type="secondary-danger"></sbnb-button>

      <sbnb-button *ngIf="mode === 'new'" (click)="close()" label="Cancel"></sbnb-button>
    </div>

    <sbnb-button
      [loading]="saving"
      loadingLabel="Saving..."
      [disabled]="(!selectedTeammate && !allMatesHidden && !timeDirty && !notes.dirty) || saving"
      label="Save"
      (clicked)="updateTask()"></sbnb-button>
  </div>
</div>
