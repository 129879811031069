import { Pipe, PipeTransform } from '@angular/core';
import { MessageAlteration, ThreadMessage } from '@app/shared/services/thread/thread.service';

@Pipe({
  standalone: false,
  name: 'isAlterationMessage',
})
export class IsAlterationMessagePipe implements PipeTransform {
  transform(value: ThreadMessage): value is MessageAlteration {
    return value.entity_type === 'alteration';
  }
}
