import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { NotificationType } from '@app/shared/interfaces';
import { DialogDirectQuotesData } from '@app/shared/services/direct-quotes/dialog-direct-quotes.service';
import { PropertiesService, SimpleProperty } from '@app/shared/services/properties/properties.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { isNullUndefinedOrEmptyString } from '@app/shared/utils';
import { format } from 'date-fns';
import { LoadingState } from '../../interfaces/lib/loading.interface';
import {
  CreateDirectCustomQuotePayload,
  DirectQuotesService,
  NewDirectCustomQuote,
  QuoteExpirations,
} from '../../services/direct-quotes/direct-quotes.service';

enum AddDirectBookingPhase {
  SetAmount = 'SetAmount',
  GuestDetails = 'GuestDetails',
  HoldDuration = 'HoldDuration',
  ReviewAndConfirm = 'ReviewAndConfirm',
}

@Component({
  standalone: false,
  templateUrl: './dialog-add-direct-quote.component.html',
  styleUrls: ['./dialog-add-direct-quote.component.scss'],
})
export class DialogAddDirectQuoteComponent implements OnInit {
  AddDirectBookingPhase = AddDirectBookingPhase;
  phase: AddDirectBookingPhase;
  quote: NewDirectCustomQuote = {
    checkin: '',
    checkout: '',
    num_guests: {
      adults: 2,
      children: 0,
      infants: 0,
      pets: 0,
    },
    guest_details: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      note: '',
    },
    locale: 'en',
    currency: '',
    expires_in: QuoteExpirations.TwentyFourHours,
    computed: {
      guestName: '',
    },
  };
  properties: SimpleProperty[] = [];
  LoadingState = LoadingState;
  loadingState = LoadingState.NotSent;
  pageLoadingState: LoadingState = LoadingState.Pending;
  public calendarRestricted = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAddDirectQuoteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: DialogDirectQuotesData,
    public datepipe: DatePipe,
    private segmentIoService: SegmentIoService,
    private propertyService: PropertiesService,
    private directQuotesService: DirectQuotesService,
    private toast: ToastNotificationsService
  ) {}

  ngOnInit(): void {
    if (this.dialogData.startDate) {
      this.quote.checkin = format(this.dialogData.startDate, 'YYYY-MM-DD');
    }

    if (this.dialogData.endDate) {
      this.quote.checkout = format(this.dialogData.endDate, 'YYYY-MM-DD');
    }

    if (this.dialogData.propertyId) {
      this.quote.property_id = this.dialogData.propertyId;
    }

    this.phase = AddDirectBookingPhase.SetAmount;
    this.fetchProperties();
  }

  fetchProperties() {
    this.propertyService
      .getProperties({
        filterCriteria: [],
        paginate: false,
        transformer: 'simple',
      })
      .subscribe((res) => {
        this.properties = res.data;
        this.pageLoadingState = LoadingState.Success;
      });
  }

  onGoBack(phase: AddDirectBookingPhase) {
    this.phase = phase;
  }

  onSetAmount(quote: NewDirectCustomQuote) {
    this.quote = quote;
    this.phase = AddDirectBookingPhase.GuestDetails;

    this.segmentIoService.track(SegmentEvent.SetAmountOnDirectQuote, {
      quote,
    });
  }

  onAddBookingDetails(quote: NewDirectCustomQuote) {
    this.quote = quote;
    this.phase = AddDirectBookingPhase.HoldDuration;
    this.segmentIoService.track(SegmentEvent.SetAmountOnDirectQuote, {
      quote,
    });
  }

  onHoldDuration(quote: NewDirectCustomQuote) {
    this.quote = quote;
    this.phase = AddDirectBookingPhase.ReviewAndConfirm;
    this.setCalendarRestricted();

    this.segmentIoService.track(SegmentEvent.SetExpirationOnDirectQuote, {
      quote,
    });
  }

  private setCalendarRestricted() {
    this.propertyService.getProperty(this.quote.property_id).subscribe((res) => {
      this.calendarRestricted = res.calendar_restricted;
    });
  }

  onConfirm(quote: NewDirectCustomQuote) {
    this.loadingState = LoadingState.Pending;
    this.quote = quote;
    const { property_id, checkin, checkout, num_guests, guest_details, locale, currency, expires_in, computed } =
      this.quote;

    const computedPricing = computed.pricing;

    const { estimated, original, customFees } = computedPricing;

    const newPricing: CreateDirectCustomQuotePayload['pricing'] = {} as CreateDirectCustomQuotePayload['pricing'];

    newPricing['nightly_total'] =
      estimated && estimated.nightly && estimated.nightly.value ? +estimated.nightly.value : +original.nightly.value;

    let fees = estimated && estimated.fees ? estimated.fees : {};

    fees = { ...fees, ...customFees };

    const feesWithoutEmptyStrings = {};
    Object.keys(fees).forEach((key) => {
      if (!isNullUndefinedOrEmptyString(fees[key].value)) {
        feesWithoutEmptyStrings[key] = fees[key].value;
      }
    });

    newPricing['fees'] = feesWithoutEmptyStrings;

    const customQuote: CreateDirectCustomQuotePayload = {
      property_id,
      checkin,
      checkout,
      num_guests,
      guest_details,
      locale,
      currency,
      pricing: newPricing,
      expires_in,
    };
    this.directQuotesService.createCustomQuote(customQuote).subscribe(
      (res) => {
        this.loadingState = LoadingState.Success;
        this.toast.open('Your quote has been successfully created.', 'Dismiss', NotificationType.Success);

        this.segmentIoService.track(SegmentEvent.CreateDirectCustomQuote, {
          quote: customQuote,
        });

        this.dialogRef.close({ quote: res.data });
      },
      (err) => {
        this.loadingState = LoadingState.Error;
        this.segmentIoService.track(SegmentEvent.CreateDirectCustomQuoteError, {
          quote: customQuote,
        });

        const error = err.error?.error ?? err.error.message ?? 'We are unable to create a quote at this time';

        this.toast.open(error, 'Dismiss', NotificationType.Error);
      }
    );
  }
}
