import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '@app/core/app-config';
import { combineLatest } from 'rxjs';

const LOG_PREFIX = `[Push Handler]`;

@Component({
  standalone: false,
  template: `
    <div class="flex justify-center items-center h-screen">
      <mat-spinner class="text-center" diameter="24"></mat-spinner>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnesignalPushNotificationHandlerComponent implements OnInit {
  action: string | null = null;
  queryParams: { [key: string]: unknown } | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    combineLatest([this.route.paramMap, this.route.queryParamMap]).subscribe(([params, queryParams]) => {
      this.action = params.get('action');
      this.queryParams = queryParams.keys.reduce((acc, key) => ({ ...acc, [key]: queryParams.get(key) }), {});

      this.actionPushNotification();
    });
  }

  actionPushNotification() {
    const threadId = this.queryParams?.thread_id;

    if (!this.action) {
      console.error(`${LOG_PREFIX} Action is missing`);
      this.routeToDefaultPage();
    }

    switch (this.action) {
      case 'reply':
        if (threadId) {
          this.router.navigate([`/inbox/thread/${threadId}`]);
        } else {
          console.error(`${LOG_PREFIX} Thread ID is missing`);
          this.routeToDefaultPage();
        }
        break;
      case 'replyAI':
        if (threadId) {
          this.router.navigate([`/inbox/thread/${threadId}`], { queryParams: { draft: true } });
        } else {
          console.error(`${LOG_PREFIX} Thread ID is missing`);
          this.routeToDefaultPage();
        }
        break;
      default:
        this.routeToDefaultPage();
        break;
    }
  }

  routeToDefaultPage() {
    this.router.navigate([config.DEFAULT_PAGE]);
  }
}
