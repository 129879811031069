<section class="flex justify-end">
  <sbnb-close [border]="true" (click)="dialogRef.close()"></sbnb-close>
</section>

<section class="add-manual-booking" *ngIf="!showReviewAndConfirm">
  <ng-container *ngIf="properties.length > 0 && (loadingReservation$ | async) === false; else loadingTemplate">
    <div class="fs-dialog-contents mx-auto">
      <!-- Heading-->
      <section class="flex justify-between items-center mb-8">
        <h2 class="m-0">{{ dialogData.mode === 'create' ? 'Add a booking' : 'Edit booking' }}</h2>

        <ng-container *ngTemplateOutlet="codeAndChannel"></ng-container>
      </section>

      <!-- Property and guests -->
      <section class="flex flex-col md:flex-row gap-0 md:gap-6 mb-6">
        <div class="flex-1">
          <label class="form-label required">Property</label>
          <sbnb-property-select-dropdown
            [defaultOpen]="dialogData.mode === 'create' && !manualBooking.propertyId"
            [selectedPropertyId]="manualBooking.propertyId"
            [properties]="properties"
            (selectedPropertyChanged)="onSelectedPropertyChanged($event)"></sbnb-property-select-dropdown>
        </div>

        <div class="flex-1 md:flex-none">
          <label class="form-label required">Guests</label>
          <button
            class="dropdown-btn w-full md:w-52 flex items-center justify-between gap-2"
            [matMenuTriggerFor]="numGuestsMenu">
            <span class="ellipsis">
              {{ manualBooking.adults ? manualBooking.adults + ' adults, ' : '' }}
              {{ manualBooking.children ? manualBooking.children + ' children, ' : '' }}
              {{ manualBooking.infants ? manualBooking.infants + ' infants, ' : '' }}
              {{ manualBooking.pets ? manualBooking.pets + ' pets' : '' }}
            </span>

            <div class="mat-select-arrow-wrapper">
              <div class="mat-select-arrow"></div>
            </div>
          </button>

          <mat-menu class="num-guests-menu" #numGuestsMenu="matMenu" (closed)="onGuestsChanged()">
            <section class="p-4 pb-0" (click)="$event.preventDefault(); $event.stopPropagation()">
              <div class="flex flex-row gap-2 md:gap-6">
                <div class="flex-1 flex-col gap-1">
                  <label class="form-label" for="adults">Adults</label>

                  <mat-form-field class="fw">
                    <input name="adults" [(ngModel)]="manualBooking.adults" type="number" matInput />
                  </mat-form-field>
                </div>

                <div class="flex-1 flex-col gap-1">
                  <label class="form-label" for="children">Children</label>

                  <mat-form-field class="fw">
                    <input name="children" [(ngModel)]="manualBooking.children" type="number" matInput />
                  </mat-form-field>
                </div>

                <div class="flex-1 flex-col gap-1">
                  <label class="form-label" for="infants">Infants</label>

                  <mat-form-field class="fw">
                    <input name="infants" [(ngModel)]="manualBooking.infants" type="number" matInput />
                  </mat-form-field>
                </div>

                <div class="flex-1 flex-col gap-1">
                  <label class="form-label" for="pets">Pets</label>

                  <mat-form-field class="fw">
                    <input name="pets" matInput type="number" [(ngModel)]="manualBooking.pets" />
                  </mat-form-field>
                </div>
              </div>
            </section>
          </mat-menu>
        </div>
      </section>

      <!-- Check in / check out -->
      <section [hidden]="!(manualBooking.propertyId && manualBooking.adults > 0)" class="mb-6">
        <label class="form-label required mb-1" for="email">Check-in / Check-out</label>

        <!-- If we don't have a check-in / check-out, show the date picker inline -->
        <div
          *ngIf="reservations && !manualBooking.checkIn && !manualBooking.checkOut"
          class="border rounded border-smoke p-4 px-6">
          <sbnb-date-picker
            [disabled]="manualBooking.platform === 'ical'"
            [rangeSelect]="true"
            [numMonths]="(isMobile$ | async) === true ? 1 : 2"
            [initialDateRange]="[dialogData.startDate, dialogData.endDate]"
            [reservations]="reservations"
            (click)="$event.stopPropagation()"
            (datesSelected)="checkInOutTrigger.closeMenu(); datesSelected($event)"></sbnb-date-picker>
        </div>

        <!-- If we have a check-in / check-out, show the dropdown -->
        <button
          [hidden]="!manualBooking.checkIn && !manualBooking.checkOut"
          class="dropdown-btn w-full flex items-center justify-between gap-2"
          #checkInOutTrigger="matMenuTrigger"
          [matMenuTriggerFor]="checkInOutMenu">
          <span class="ellipsis">
            {{ manualBooking.checkIn | date: 'mediumDate' }} - {{ manualBooking.checkOut | date: 'mediumDate' }}
          </span>

          <div class="mat-select-arrow-wrapper">
            <div class="mat-select-arrow"></div>
          </div>
        </button>

        <mat-menu class="date-menu" #checkInOutMenu="matMenu">
          <sbnb-date-picker
            *ngIf="reservations"
            [disabled]="manualBooking.platform === 'ical'"
            [rangeSelect]="true"
            [numMonths]="(isMobile$ | async) === true ? 1 : 2"
            [initialDateRange]="[dialogData.startDate, dialogData.endDate]"
            [startDate]="dialogData.startDate"
            [reservations]="reservations"
            (click)="$event.stopPropagation()"
            (datesSelected)="checkInOutTrigger.closeMenu(); datesSelected($event)"></sbnb-date-picker>
        </mat-menu>
      </section>

      <!-- Financials -->
      <label [hidden]="!manualBooking.checkOut" class="mt-8 mb-2 block form-label required">Guest payment</label>
      <section
        [hidden]="!manualBooking.checkOut"
        class="mb-12 text-sm md:text-base border rounded border-smoke p-4 px-6">
        <ng-container *ngIf="financialQuote?.fees?.length > 0; else loadingFees">
          <div class="flex flex-col gap-1 mb-4">
            <div class="border-b border-smoke flex pb-3 gap-8 text-sm items-center">
              <div class="w-6/12">Item</div>
              <div class="w-3/12 flex flex-row flex-wrap items-center justify-end gap-1">
                <span>{{ dialogData.mode === 'create' ? 'Suggested rates' : 'Current rates' }}</span>
                <sbnb-icon
                  [size]="14"
                  *ngIf="dialogData.mode === 'create'"
                  icon="details"
                  class="text-raspberry-darker"
                  matTooltip="Suggested rates are based on the prices you've set for the selected dates and fees for this property."></sbnb-icon>
              </div>
              <div class="w-2/12 text-center min-w-24">Guest payment</div>
            </div>

            <div *ngFor="let fee of financialQuote.fees" class="flex items-center gap-8">
              <div class="w-6/12">
                <mat-form-field *ngIf="fee.type === 'other_fee_manual'" class="fw nopadding">
                  <input
                    matInput
                    type="text"
                    [(ngModel)]="fee.label"
                    (change)="recalculateQuoteTotal()"
                    placeholder="New fee / discount" />
                  <span matSuffix>
                    <mat-icon class="cursor-pointer hover:brightness-125" (click)="removeFee(fee)">close</mat-icon>
                  </span>
                </mat-form-field>

                <div *ngIf="fee.type !== 'other_fee_manual'" class="flex items-center gap-2">
                  <span>{{ fee.label }}</span>
                  <mat-icon
                    *ngIf="fee.type === 'other_fee' || fee.type === 'guest_discount'"
                    class="cursor-pointer hover:brightness-125"
                    (click)="removeFee(fee)">
                    close
                  </mat-icon>
                </div>
              </div>
              <div class="w-3/12 text-right text-sm pr-2">{{ fee.amount.formatted_string }}</div>
              <div class="w-2/12 min-w-24">
                <mat-form-field class="fw nopadding">
                  <input
                    matInput
                    class="!text-right"
                    type="number"
                    [min]="
                      fee.type === 'other_fee_manual' || fee.type === 'guest_discount' || fee.type === 'other_fee'
                        ? null
                        : 0
                    "
                    [ngModel]="fee.amount.formatted_decimal"
                    (ngModelChange)="fee.amount.amount = $event; recalculateQuoteTotal()" />
                  <span matPrefix>{{ fee.amount.currency | currencyCode }}</span>
                </mat-form-field>
              </div>
            </div>
          </div>

          <sbnb-button size="mini" type="secondary" (clicked)="addFee()">+ Add a fee or discount</sbnb-button>
        </ng-container>

        <ng-template #loadingFees>
          <div *ngFor="let i of [].constructor(8)" class="flex gap-8 mb-4 animate-pulse items-center">
            <div class="w-6/12 h-6 rounded bg-smoke"></div>
            <div class="w-3/12 h-6 rounded bg-smoke"></div>
            <div class="w-2/12 h-8 rounded bg-smoke"></div>
          </div>
        </ng-template>

        <div class="border-t border-smoke flex pt-4 mt-4 gap-8 items-center">
          <div class="w-6/12 strong">Total cost to guest</div>
          <div class="w-3/12 flex justify-end pr-2 text-sm">
            <mat-spinner *ngIf="recalculatingQuote" diameter="16"></mat-spinner>
            <span *ngIf="!recalculatingQuote">{{ financialQuote?.orig_total?.formatted_string }}</span>
          </div>
          <div class="w-2/12 min-w-24 flex justify-end strong pr-2">
            <mat-spinner *ngIf="recalculatingQuote" diameter="16"></mat-spinner>
            <span *ngIf="!recalculatingQuote">{{ financialQuote?.total?.formatted_string }}</span>
          </div>
        </div>
      </section>

      <!-- Guest details -->
      <section [hidden]="!manualBooking.checkOut">
        <div class="flex flex-col gap-0 md:flex-row md:gap-6">
          <div class="flex-1 flex-col gap-1">
            <label class="form-label required" for="name">Guest first name</label>

            <mat-form-field class="fw">
              <input
                type="text"
                matInput
                [placeholder]="'First name'"
                [(ngModel)]="manualBooking.guestFirstName"
                (ngModelChange)="onGuestNameChange($event)"
                [matAutocomplete]="guestAutocomplete" />

              <mat-autocomplete #guestAutocomplete="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                <mat-option *ngIf="isLoadingGuestResults">
                  <mat-spinner diameter="20"></mat-spinner>
                </mat-option>

                <mat-option *ngFor="let option of guestSearchResults" [value]="option" class="multiline-mat-option">
                  <div>
                    <!-- TODO: types-->
                    <div class="text__medium">{{ option.first_name }} {{ option.last_name }}</div>
                    <div class="text__small">{{ option.email }}</div>
                    <div class="text__small">{{ option.phone }}</div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="flex-1 flex-col gap-1">
            <label class="form-label required" for="lastname">Guest last name</label>

            <mat-form-field class="fw">
              <input
                type="text"
                name="lastname"
                matInput
                [placeholder]="'Last name'"
                [(ngModel)]="manualBooking.guestLastName"
                (ngModelChange)="onGuestNameChange($event)"
                [matAutocomplete]="guestAutocomplete" />
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-col gap-0 md:flex-row md:gap-6">
          <div class="flex-1 flex-col gap-1">
            <label class="form-label required" for="email">Guest email</label>

            <mat-form-field class="fw">
              <input
                name="email"
                [(ngModel)]="manualBooking.guestEmail"
                (ngModelChange)="onGuestNameChange($event)"
                matInput
                [placeholder]="'Eg. guest@example.com'"
                [matAutocomplete]="guestAutocomplete" />
            </mat-form-field>
          </div>

          <div class="flex-1 flex-col gap-1">
            <label class="form-label" for="mobile">Mobile number</label>

            <mat-form-field class="fw">
              <ngx-mat-intl-tel-input
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="mobile_phone"
                [(ngModel)]="manualBooking.guestPhone"
                [preferredCountries]="['us']"
                #phone
                #phoneModel="ngModel"></ngx-mat-intl-tel-input>
              <mat-hint>Eg. {{ phone.selectedCountry.placeHolder }}</mat-hint>
              <mat-error *ngIf="isPhoneInvalid">Invalid phone format</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-col gap-0 md:flex-row md:gap-6">
          <div class="flex-1 flex-col gap-1">
            <label class="form-label required" for="language">Language</label>

            <mat-form-field class="fw">
              <mat-select [(ngModel)]="manualBooking.guestLanguage">
                <mat-option *ngFor="let option of languages" [value]="option.code">
                  {{ option.english }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </section>

      <!-- Reservation Code -->
      <section [hidden]="!manualBooking.checkOut || manualBooking.platform === 'ical'">
        <div class="flex flex-col mb-6">
          <mat-checkbox
            class="text__small grass-darker flex"
            [(ngModel)]="hasReservationCode"
            [disabled]="dialogData.mode === 'update'">
            Use a reservation code from another channel
          </mat-checkbox>

          <div
            *ngIf="hasReservationCode"
            class="pl-4 pt-3 border-l-smoke border-l-2 ml-4 flex flex-col md:flex-row gap-3">
            <div fxFlex fxLayout="column" fxLayoutGap="4px">
              <label class="form-label" for="code">Reservation code</label>

              <mat-form-field class="fw">
                <input
                  matInput
                  name="code"
                  [disabled]="dialogData.mode === 'update'"
                  #reservationCode="ngModel"
                  [(ngModel)]="manualBooking.reservationCode"
                  [placeholder]="'ABC123'"
                  pattern="^[a-zA-Z0-9]*$"
                  maxlength="50" />
              </mat-form-field>
            </div>

            <div fxFlex fxLayout="column" fxLayoutGap="4px">
              <label class="form-label" for="channel">Channel</label>

              <mat-form-field class="fw">
                <mat-select [(ngModel)]="manualBooking.channel" placeholder="Select a channel">
                  <mat-option *ngFor="let option of channelOptions" [value]="option.value">
                    <div fxLayout fxLayoutGap="6px" fxLayoutAlign="start center">
                      <img src="/assets/iconography/{{ option.value }}.svg" />
                      <span class="grape-darker text__medium">{{ option.label }}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </section>

      <!-- Note -->
      <section [hidden]="!manualBooking.checkOut" class="flex flex-col md:flex-row gap-0 md:gap-6 mb-9">
        <div class="flex-1 flex flex-col gap-1">
          <label class="form-label" for="note"><strong>Note</strong></label>
          <sbnb-textarea name="note" placeholder="Add a note" [(ngModel)]="manualBooking.note"></sbnb-textarea>
        </div>
      </section>

      <!-- Actions -->
      <ng-container *ngIf="manualBooking.checkOut && !requiresReviewAndConfirm">
        <ng-container *ngTemplateOutlet="actions; context: { closeOrBack: 'close' }"></ng-container>
      </ng-container>

      <section
        *ngIf="manualBooking.checkOut && requiresReviewAndConfirm"
        class="flex justify-end flex-col md:flex-row gap-2 md:gap-6">
        <sbnb-button
          *ngIf="dialogData.mode === 'create'"
          [fullWidth]="true"
          type="secondary"
          label="Cancel"
          (clicked)="dialogRef.close()"></sbnb-button>
        <sbnb-button label="Continue" (clicked)="continue()"></sbnb-button>
      </section>
    </div>
  </ng-container>

  <!-- TODO: Pulse loader -->
  <ng-template #loadingTemplate>
    <sbnb-loading-spinner class="fs-dialog-contents mx-auto"></sbnb-loading-spinner>
  </ng-template>
</section>

<section class="flex flex-col items-center justify-center" *ngIf="showReviewAndConfirm">
  <!-- Heading-->
  <section class="flex flex-col gap-12">
    <section class="flex justify-between items-center">
      <h2 class="m-0">Review &amp; Confirm</h2>

      <ng-container *ngTemplateOutlet="codeAndChannel"></ng-container>
    </section>

    <ul class="list list__looser">
      <li>This is a manual booking. You are responsible for collecting any payment from the guests.</li>
      <li>
        Your calendar is limited. Hospitable is unable to automatically block these dates on any connected channels.
      </li>
    </ul>

    <ng-container *ngTemplateOutlet="actions; context: { closeOrBack: 'back' }"></ng-container>
  </section>
</section>

<!-- Actions -->
<ng-template #actions let-closeOrBack="closeOrBack">
  <section class="flex flex-col md:flex-row gap-2 md:gap-6">
    <div class="flex-1">
      <sbnb-button
        *ngIf="dialogData.mode === 'create'"
        [fullWidth]="true"
        type="secondary"
        [label]="closeOrBack === 'close' ? 'Cancel' : 'Go back'"
        (clicked)="closeOrBack === 'close' ? dialogRef.close() : goBack()"></sbnb-button>
    </div>

    <div class="flex-1">
      <sbnb-button
        [disabled]="(loadingProperty$ | async) || isPhoneInvalid"
        *ngIf="dialogData.mode === 'create'"
        [loading]="saving"
        [fullWidth]="true"
        label="Create booking"
        (clicked)="createBooking()"></sbnb-button>

      <sbnb-button
        [disabled]="(loadingProperty$ | async) || isPhoneInvalid"
        *ngIf="dialogData.mode === 'update'"
        [loading]="saving"
        [fullWidth]="true"
        label="Update booking"
        (clicked)="updateBooking()"></sbnb-button>
    </div>
  </section>
</ng-template>

<ng-template #codeAndChannel>
  <div *ngIf="manualBooking.reservationCode" class="flex items-center gap-2">
    <!-- TODO: wut -->
    <img
      *ngIf="manualBooking.platform === 'manual' && manualBooking.channel"
      src="/assets/iconography/{{ getSelectedChannelObj(manualBooking.channel).value }}.svg" />
    <span class="text-sm">{{ manualBooking.reservationCode }}</span>
  </div>
</ng-template>
