import { Component, input, OnInit, signal } from '@angular/core';
import { AutohostSdkToken, ReservationService } from '@app/shared/services/reservation/reservation.service';
import { ScriptLoaderService } from '@app/shared/services/script-loader/script-loader.service';
import { environment } from '@env/environment';

interface AutohostSdkClient {
  init: (config: { sandbox: boolean; reservationId: string }) => Promise<{
    component: (name: string, options: AutohostSdkOptions) => { mount: (selector: string) => void };
  }>;
}

interface AutohostSdkOptions {
  reservationId: string;
  apiToken: string;
  styles: Record<string, Record<string, string | number | boolean>>;
}

declare const AutohostSDK: AutohostSdkClient;

@Component({
  selector: 'sbnb-autohost-sdk-embed',
  standalone: false,
  template: `
    @if (loading()) {
      <div class="flex items-center justify-center">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
    } @else if (error()) {
      <div class="flex items-center justify-center text-smoke-darker">
        {{ error() }}
      </div>
    }

    <!-- Cant put this in an else, otherwise Autohost SDK can't find it and will not load -->
    <!-- fixed height as otherwise it scrolls within a scroll -->
    <div id="autohost-sdk" class="h-[800px] border-0"></div>
  `,
  styles: [
    `
      :host ::ng-deep #autohost-sdk iframe {
        border: 0;
        height: 95%;
      }
    `,
  ],
})
export class AutohostSdkEmbedComponent implements OnInit {
  reservationUuid = input.required<string>();
  showOnlyIdVerification = input<boolean>(true);

  loading = signal(true);
  error = signal<string | null>(null);

  constructor(
    private scriptLoader: ScriptLoaderService,
    private reservationService: ReservationService
  ) {}

  ngOnInit(): void {
    if (!this.reservationUuid()) {
      console.error('Autohost SDK embed requires a reservation UUID');
      this.error.set('Missing required reservation UUID');
      this.loading.set(false);
      return;
    }

    this.loadAutohostSDK();
  }

  private loadAutohostSDK(): void {
    this.scriptLoader
      .load({
        name: 'Autohost SDK',
        src: environment.autohostSdk.scriptSrc,
      })
      .subscribe(() => {
        this.reservationService.getAutohostSdkToken(this.reservationUuid()).subscribe({
          next: (token: AutohostSdkToken) => {
            this.initializeSdk(token);
          },
          error: (err) => {
            console.error('Failed to get Autohost SDK token', err);
            this.loading.set(false);
            this.error.set('Failed to load guest vetting data. Please try again later.');
          },
        });
      });
  }

  private initializeSdk(token: AutohostSdkToken): void {
    AutohostSDK.init({
      sandbox: environment.autohostSdk.sandbox,
      reservationId: token.autohost_reservation_id,
    }).then((client) => {
      const options: AutohostSdkOptions = {
        reservationId: token.autohost_reservation_id,
        apiToken: token.token,
        styles: this.getSdkStyles(),
      };

      client.component('ReservationResults', options).mount('#autohost-sdk');
      this.loading.set(false);
    });
  }

  private getSdkStyles(): Record<string, Record<string, string | number | boolean>> {
    const baseStyles = {
      card: { margin: '0px', 'margin-bottom': '12px', 'box-shadow': 'none', display: 'none' },
      header: { padding: '0px', 'margin-bottom': '12px', 'font-size': '18px', color: 'rgb(35, 11, 65)' },
      'MuiCardContent-root': { padding: '0px' },
    };

    if (this.showOnlyIdVerification()) {
      return {
        ...baseStyles,
        'definition-list__item-bookingSource': { display: 'none' },
        'definition-list__item-confirmationCode': { display: 'none' },
        'card_top-verifications': { display: 'none' },
        'card_id-verification-document': { display: 'block' },
        'id-image_container_back': { display: 'none' },
        'card_top-concerns': { display: 'none' },
        'card_top-recommendations': { display: 'none' },
        'MuiCardHeader-title': { display: 'none' },
        'MuiCardContent-root': { display: 'none' },
      };
    }

    return baseStyles;
  }
}
