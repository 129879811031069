import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkify-string';
import { Opts } from 'linkifyjs';

const defaultOptions: Opts = {
  attributes: null,
  className: 'linkified',
  defaultProtocol: 'http',
  events: null,
  format: function (value) {
    return value;
  },
  formatHref: function (href) {
    return href;
  },
  ignoreTags: [],
  nl2br: false,
  tagName: 'a',
  target: {
    url: '_blank',
  },
  validate: true,
};

@Pipe({
  name: 'linkify',
})
export class LinkifyjsPipe implements PipeTransform {
  transform(value: string, options = defaultOptions): string {
    return value ? linkifyStr(value, options) : value;
  }
}
