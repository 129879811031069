import { LaravelPagination } from '@app/shared/interfaces';
export interface GenericState<T> {
  data: T;
  loading: boolean;
  error: string | null;
  meta?: LaravelPagination;
}

export type OwnersState = GenericState<Owner[]>;
export type PropertiesState = GenericState<Property[]>;
export type TransactionsState = GenericState<Transaction[]>;
export type RecurringTransactionsState = GenericState<RecurringTransaction[]>;
export type StatementsState = GenericState<Statement[]>;
export type CommissionsState = GenericState<Agreement[]>;
export type ConnectionState = GenericState<Connection[]>;
export type ReservationState = GenericState<Reservation[]>;

export interface Adjustment {
  id: number;
  uuid: string;
  property_id: number;
  reservation_uuid: string;
  status: 'processed' | 'not_processed';
  amount: number;
  currency: string;
  account_classification: AdjustmentClassification[]; // the current breakdown applied to the adjustment
  transaction_classification: { amount: number; type: TransactionType; category: TransactionCategory }[]; // the current breakdown applied to the adjustment
  suggested_classification: AdjustmentClassification[]; // the original AI suggestion on how to break down the adjustment
  ai_reasoning: string;
  details: string; // string describing the adjustment, like "AirCover damage reimbursement for resolution CLSF-04009018"
  date: string;
  reservation: {
    code: string;
    guest_name: string;
    property_name: string;
  };
}

export interface AdjustmentsState {
  data: Adjustment[];
  loading: boolean;
  error: string | null;
  meta?: LaravelPagination;
}

export interface AdjustmentClassification {
  type: Account | StandardAccount;
  amount: number;
}

export enum ConnectionAccountCategory {
  Asset = 'asset',
  Liability = 'liability',
  Expense = 'expense',
  Revenue = 'revenue',
  Equity = 'equity',
}

export interface TeamState {
  data: StatementTeam | null;
  loading: boolean;
  error: string | null;
}

export interface StatementTeam {
  id?: number;
  uid?: number;
  status: TeamStatus;
  team_id?: string; // the id in vrp
  created_at?: string;
  updated_at?: string;
  owner?: Owner;
}

export enum TeamStatus {
  CreateOwner = 'create_owner',
  AssignProperties = 'assign_properties',
  CreateCommissionRate = 'create_commission_rate',
  Finished = 'finished',
}
export interface Statement {
  id: number;
  type: StatementType | null; // can be null if the statement doesnt have a reservation commission yet
  property: Property;
  startsAt: string;
  endsAt: string;
  status: StatementStatus;
}

export enum StatementType {
  Statement = 'statement',
  Invoice = 'invoice',
}

export enum StatementStatus {
  Draft = 'draft',
  Published = 'published',
  InReview = 'in_review',
}
export interface Owner {
  id?: number;
  type: 'individual' | 'company';
  name: string;
  firstName: string;
  email: string;
  phone: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  split?: number;
  properties: Property[];
  externalType?: 'supplier' | 'customer'; // optional when the `external_type` include is passed
}

export type EditableOwner = Omit<Owner, 'properties'> & { properties: number[] };
export type EditableCommission = Omit<Agreement, 'properties'> & { properties: number[] };

export interface Property {
  id: number;
  name: string;
  picture: string;
  currency: string;
  owners: Owner[];
  commissionRates?: Array<Omit<Agreement, 'properties'> | null>;
  nextOpenPeriodAt: string;
}

export interface Expense {
  id?: unknown;
  description: string;
  property: Property['id'] | null;
  amount: number;
  date: string;
}

export enum AgreementAction {
  Delete = 'delete_period',
  Modify = 'modify',
}

export type Agreement = {
  id?: number;
  starts_at?: string; // yyyy-mm-dd
  ends_at?: string; // yyyy-mm-dd
  actions?: AgreementAction[];
} & AgreementData;

export enum Recognition {
  CheckIn = 'check-in',
  CheckOut = 'check-out',
  ProRated = 'pro-rated',
}

export interface AgreementData {
  name: string;
  type: PayoutParties;
  commission: {
    rates: PlatformRate[];
    accounts: Account[];
    recognition: Recognition;
  };
  splits: { account: Account; rate: number }[];
  flat_fees: PlatformRate[];
  taxes: PayoutParties;
  properties?: Property[];
}

interface PlatformRate {
  scope: Platform;
  rate: number;
}

export enum Platform {
  Airbnb = 'airbnb',
  Vrbo = 'vrbo',
  Booking = 'booking',
  Agoda = 'agoda',
  Direct = 'direct',
  Manual = 'manual',
}

export enum StandardAccount {
  Accommodation = 'accomodation', // typo to match the API
  GuestDiscount = 'guest_discount',
}

export enum Account {
  HostServiceFee = 'host_service_fee',
  CleaningFee = 'cleaning_fee',
  ExtraGuestFee = 'extra_guest_fee',
  LinenFee = 'linen_fee',
  ManagementFee = 'management_fee',
  UncategorizedRevenue = 'other_fee',
  PetFee = 'pet_fee',
  ResortFee = 'resort_fee',
  CommunityFee = 'community_fee',
  Upsell = 'upsell',
}

export const AccountLabels: { [key in Account | StandardAccount]: string } = {
  [StandardAccount.Accommodation]: 'Accommodation',
  [Account.HostServiceFee]: 'Host Service Fee',
  [StandardAccount.GuestDiscount]: 'Guest Discount',
  [Account.CleaningFee]: 'Cleaning Fee',
  [Account.ExtraGuestFee]: 'Extra Guest Fee',
  [Account.LinenFee]: 'Linen Fee',
  [Account.ManagementFee]: 'Management Fee',
  [Account.UncategorizedRevenue]: 'Uncategorized Revenue',
  [Account.PetFee]: 'Pet Fee',
  [Account.ResortFee]: 'Resort Fee',
  [Account.CommunityFee]: 'Community Fee',
  [Account.Upsell]: 'Upsell',
};

export const AccountRecognitionPresets: { [key in Account]: Recognition } = {
  [Account.HostServiceFee]: Recognition.ProRated,
  [Account.CleaningFee]: Recognition.CheckIn,
  [Account.ExtraGuestFee]: Recognition.ProRated,
  [Account.LinenFee]: Recognition.CheckIn,
  [Account.ManagementFee]: Recognition.ProRated,
  [Account.UncategorizedRevenue]: Recognition.CheckIn,
  [Account.PetFee]: Recognition.CheckIn,
  [Account.ResortFee]: Recognition.CheckIn,
  [Account.CommunityFee]: Recognition.CheckIn,
  [Account.Upsell]: Recognition.CheckIn,
};

export enum PayoutParties {
  Host = 'host',
  Owner = 'owner',
}

export enum UICommissionAccountSelection {
  Accommodation = 'accommodation',
  Payout = 'payout',
  Custom = 'custom',
}

export enum UISplitsSelection {
  Host = 'host',
  Owner = 'owner',
  Custom = 'custom',
}

export enum CommissionAccounts {
  CleaningFeesCommission = 'cleaning_fees_commission',
  CommunityFeesCommission = 'community_fees_commission',
  AdditionalGuestsCommission = 'additional_guests_commission',
  LinensFeesCommission = 'linens_fees_commission',
  ManagementFeesCommission = 'management_fees_commission',
  UncategorizedRevenueCommission = 'uncategorized_revenue_commission',
  PetFeesCommission = 'pet_fees_commission',
  ResortFeesCommission = 'resort_fees_commission',
}

export const CommissionAccountLabels: { [key in CommissionAccounts]: string } = {
  [CommissionAccounts.CleaningFeesCommission]: 'Cleaning fee',
  [CommissionAccounts.CommunityFeesCommission]: 'Community fee',
  [CommissionAccounts.AdditionalGuestsCommission]: 'Extra guest fee',
  [CommissionAccounts.LinensFeesCommission]: 'Linens fee',
  [CommissionAccounts.ManagementFeesCommission]: 'Management fee',
  [CommissionAccounts.UncategorizedRevenueCommission]: 'Other fee',
  [CommissionAccounts.PetFeesCommission]: 'Pet fee',
  [CommissionAccounts.ResortFeesCommission]: 'Resort fee',
};

export interface SavedAttachment {
  id: number;
  temporary_url: string;
  url: string;
}

export interface Transaction {
  id?: number;
  type: TransactionType;
  date: string; // yyyy-mm-dd
  lines: TransactionLine[];
  currency?: string;
  locked?: boolean;
  computed?: {
    deleting?: boolean;
  };
  // we send the files to the server when saving a transaction,
  // the server sends back an id and a temporary_url when editing a transaction
  attachments?: Array<File | SavedAttachment>;
  adjustment_uuid?: string;
}

export enum RecurrenceType {
  PerReservation = 'per_reservation',
  EveryBookedNight = 'every_booked_night',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

interface Recurrence {
  type: RecurrenceType | null;
  day?: 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU' | number;
}

export interface RecurringTransaction extends Transaction {
  recurrence: Recurrence;
  upcoming_dates?: string[];
  property: Property;
}

export interface BulkRecurringTransaction extends Transaction {
  recurrence: Recurrence;
  upcoming_dates?: string[];
}

export interface TransactionLine {
  id?: number;
  category: TransactionCategory | null;
  description: string;
  amount: number | null;
  property_id?: number; // when editing a transaction
  property?: {
    address?: any;
    currency?: string;
    id: number;
    picture?: string;
    name: string;
  };
  quantity?: number;
  markup?: number | null;
  total?: number;
}

export enum TransactionType {
  Expense = 'expense',
  Credit = 'credit',
}

export enum TransactionCategory {
  Supplies = 'supplies',
  Reimbursement = 'reimbursement',
  Maintenance = 'maintenance',
  Uncategorized = 'uncategorized',
}

/* Statement Details */
export interface StatementDetail {
  type: StatementType | null;
  actions: StatementDetailAction[];
  headline: StatementDetailHeadline;
  owner: StatementDetailPerson;
  manager: StatementDetailPerson;
  tables: Array<StatementDetailTable>;
  summary: Array<StatementDetailSummary>;
  adjustments?: Adjustment[];
  status: StatementStatus;
  note: string;
  attachments?: string[];
  property: Property;
  starts_at: string; // yyyy-mm-dd
  customizations: {
    headline?: HeadlineCustomizationOptions[];
    reservation_columns?: ReservationColumnCustomizationOptions[];
    fee_columns?: FeeColumnCustomizationOptions[];
    brand_settings?: BrandSettingsCustomizationOptions[];
  };
}

type StatementDetailActionName =
  | 'download_pdf_url'
  | 'publish_statement'
  | 'preview_pdf'
  | 'unpublish_statement'
  | 'add_note';
export interface StatementDetailActionDetailed {
  type: StatementDetailActionName;
  enabled: boolean;
  description: string | null;
}

export type StatementDetailAction = StatementDetailActionDetailed;

type StatementDetailColor = 'grey' | 'dark' | 'lightest';
type ColumnFormat = 'normal' | 'numeric';
type RowType = 'normal' | 'header';

export interface StatementDetailRow {
  id?: number;
  color?: StatementDetailColor;
  columns: Array<StatementDetailColumn>;
  type: RowType;
}

export interface StatementDetailColumn {
  value: string;
  rawValue?: string;
  url: string | null;
  alignment: 'left' | 'right';
  color?: StatementDetailColor;
  format: ColumnFormat;
  span?: number;
  divider?: boolean;
}
export interface StatementDetailHeadline {
  title: string;
  subtitles: string[];
}
export interface StatementDetailPerson {
  name: string;
  company_name: string | null;
  address_label: string;
  brand_name: string | null;
  brand_logo_url: string | null;
}
export interface StatementDetailSummary {
  title: string;
  value: string;
}
export interface StatementDetailTable {
  title: string;
  subtitle: string;
  rows: Array<StatementDetailRow>;
  actions: StatementDetailTableAction[];
  notes?: { [key: string]: string };
}

export interface Connection {
  id: number;
  name: string;
  provider: string;
  state: 'available' | 'callable' | 'added' | 'authorized' | 'invalid';
  can_create_tracking_categories: boolean;
  enabled: boolean;
}

export interface Reservation {
  uuid: string;
  guest_name: string;
  code: string;
  checkin: string;
  platform: string;
}

export interface Session {
  token: string;
}

type StatementDetailTableAction = 'add_transaction';

export interface AccountMapping {
  available_accounts: AvailableAccounts;
  accounts_to_map: AccountToMap[];
}

export interface AvailableAccounts {
  liability?: LedgerAccount[];
  asset?: LedgerAccount[];
  expense?: LedgerAccount[];
  revenue?: LedgerAccount[];
  equity?: LedgerAccount[];
}

export interface LedgerAccount {
  id: number;
  name: string;
  class: ConnectionAccountCategory;
  type: AccountType;
}

export interface AccountToMap {
  account_reference: string;
  category: ConnectionAccountCategory;
  name: string;
  info: string | null;
}

export interface TrackingCategory {
  id: number;
  name: string;
}

export interface Supplier {
  id: number;
  name: string;
}

export interface Customer {
  id: number;
  name: string;
}

export type AccountType =
  | 'accounts_payable'
  | 'other_liability'
  | 'credit_card'
  | 'non_current_liability'
  | 'accounts_receivable'
  | 'bank'
  | 'current_asset'
  | 'fixed_asset'
  | 'expense'
  | 'costs_of_sales'
  | 'other_expense'
  | 'revenue'
  | 'other_income'
  | 'equity';

export interface AccountMap {
  reference: string;
  use_default: boolean;
  account?: LedgerAccount['id'];
}

export interface PropertyMap {
  use_default?: boolean;
  id: number;
  tracking_category: string | number;
}

export interface OwnerMap {
  id: number;
  use_default: boolean;
  external_id: number;
}

export interface AccountEditPayload {
  currency: string;
  accounts: AccountMap[];
  properties: PropertyMap[];
  owners: OwnerMap[];
}

export interface AccountingIntegrationReservationPreview {
  type: string;
  description: string;
  total_amount: string;
  account_name: string;
}

export type ReservationPreview = AccountingIntegrationReservationPreview[];

interface BaseFieldMap {
  id: number;
  label: string;
  value: number;
  isDefault: boolean;
}

export interface OwnerFieldMap extends BaseFieldMap {
  propertyIds: number[];
  externalType?: 'supplier' | 'customer';
}

export interface AccountingSetupFormState {
  data?: {
    accounts: {
      fieldMap: {
        [key: number]: BaseFieldMap & { category: ConnectionAccountCategory };
      };
      options: {
        [key in ConnectionAccountCategory]: { id: number; name: string }[];
      };
    };
    properties: {
      fieldMap: {
        [key: number]: BaseFieldMap;
      };
      options: Array<{ id: number; name: string }>;
    };
    owners: {
      fieldMap: {
        [key: number]: OwnerFieldMap;
      };
      options: {
        suppliers: Array<{ id: number; name: string }>;
        customers: Array<{ id: number; name: string }>;
      };
    };
  };
  loading: boolean;
  error: string | null;
}

export enum HeadlineCustomizationOptions {
  PropertyName = 'property_name',
  Address = 'address',
}
interface HeadlineCustomization {
  key: 'headline';
  values: HeadlineCustomizationOptions[];
}

export enum ReservationColumnCustomizationOptions {
  ReservationCode = 'reservation_code',
  GuestFirstName = 'guest_first_name',
  GuestFullName = 'guest_full_name',
}

export enum FeeColumnCustomizationOptions {
  RentalCommissions = 'rental_commissions',
  FeesSum = 'fees_sum',
  HostServiceFee = 'host_service_fee',
  CleaningFee = 'cleaning_fee',
  ExtraGuestFee = 'extra_guest_fee',
  LinenFee = 'linen_fee',
  ManagementFee = 'management_fee',
  UncategorizedRevenue = 'uncategorized_revenue',
  PetFee = 'pet_fee',
  ResortFee = 'resort_fee',
  CommunityFee = 'community_fee',
  Upsell = 'upsell',
  FlatFees = 'flat_fees',
}

export interface FeeColumnsCustomization {
  key: 'fee_columns';
  values: FeeColumnCustomizationOptions[];
}

export interface ReservationColumnsCustomization {
  key: 'reservation_columns';
  values: ReservationColumnCustomizationOptions[];
}

export enum BrandSettingsCustomizationOptions {
  BrandInfo = 'brand_info',
  PersonalInfo = 'personal_info',
}

export interface BrandSettingsCustomization {
  key: 'brand_settings';
  values: BrandSettingsCustomizationOptions[];
}

export type Customization =
  | HeadlineCustomization
  | ReservationColumnsCustomization
  | FeeColumnsCustomization
  | BrandSettingsCustomization;
