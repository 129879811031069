import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NotificationType } from '@app/shared/interfaces';
import { LogService } from '@app/shared/services/logs/log.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  standalone: false,
  selector: 'sbnb-dialog-delete-upcoming-scheduled-messages',
  templateUrl: './dialog-delete-upcoming-scheduled-messages.component.html',
  styleUrls: ['./dialog-delete-upcoming-scheduled-messages.component.scss'],
})
export class DialogDeleteUpcomingScheduledMessagesComponent implements OnInit {
  deleting: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteUpcomingScheduledMessagesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      mode: 'review_deleted' | 'review_marked_bad';
      guest_name: string;
      scheduled_messages: { name: string; scheduled_for: string }[];
      reservation_code: string;
    },
    private logService: LogService,
    private toast: ToastNotificationsService
  ) { }

  ngOnInit(): void { }

  deleteMessages() {
    this.deleting = true;
    this.logService.deleteScheduledMessagesForReservation(this.dialogData.reservation_code).subscribe((res) => {
      this.deleting = false;
      this.toast.open(
        `Upcoming scheduled messages for this guest have been cancelled.`,
        'Dismiss',
        NotificationType.Error
      );
      this.dialogRef.close();
    });
  }
}
