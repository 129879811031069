<div class="flex flex-row flex-wrap flex-start gap-2">
  <ng-container *ngIf="appliedFilters && appliedFilters.length > 0">
    <hospitable-applied-filter-button
      *ngFor="let filter of appliedFilters; trackBy: trackAppliedFilter"
      [filter]="filter"
      (applyFilter)="onApplyFilter($event)"
      (removeFilter)="onRemoveFilter($event)"></hospitable-applied-filter-button>
  </ng-container>

  <ng-container *ngIf="filters && filters.length > 0">
    <button [matMenuTriggerFor]="addNewMenu" mat-flat-button class="button__small button__text button_add-filter">
      + Filter
    </button>

    <mat-menu class="filters__cdk-menu" #addNewMenu="matMenu" yPosition="below">
      <ng-container *ngFor="let filter of filters">
        <button
          *ngIf="filter.key !== FilterKey.Columns && !isFilterAlreadyApplied(filter)"
          mat-menu-item
          (click)="onAddFilter(filter)">
          <span class="flex flex-start items-center gap-2">
            <img class="filter__icon" src="/assets/icons/filters/{{ filter.key }}.svg" onload="SVGInject(this)" />
            <span>{{ filter.label }}</span>
          </span>
        </button>
      </ng-container>
    </mat-menu>

    <ng-container *ngIf="appliedFilters && appliedFilters.length > 0">
      <button
        *ngIf="dynamicMenuCmp?.menu"
        [matMenuTriggerFor]="dynamicMenuCmp.menu"
        mat-flat-button
        class="button__small button__text button_add-filter grass-darker">
        + Columns
      </button>

      <ng-container *ngFor="let filter of appliedFilters">
        <ng-container *ngIf="filter.key === FilterKey.Columns">
          <hospitable-filter-menu-select
            [filter]="filter"
            (applyFilter)="onApplyFilter($event)"
            #dynamicMenuCmp></hospitable-filter-menu-select>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
