import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  standalone: false,
  selector: 'sbnb-thermostats',
  templateUrl: './thermostats.component.html',
  styleUrls: ['./thermostats.component.scss'],
})
export class ThermostatsComponent implements OnInit {
  @Input() thermostatName: string;
  @Input() thermostats: any[];
  @Input() overrideLength: number;
  @Input() allThermostats: boolean;
  @Input() labelForAllThermostats: string = 'All'; // Can be used to override the default 'All' label
  @Input() showIndividualAsWellAsAll: boolean = false; // Sometimes we need to show the 'All' label as well as the individually selected properties. In Direct we use 'Default' but want to show the individually selected properties too

  tooltip: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.checkForRequiredInputs();
    this.buildTooltip();

    if (!this.overrideLength) {
      this.overrideLength = this.thermostats.length;
    }
  }

  checkForRequiredInputs() {
    if (
      this.allThermostats === null ||
      this.allThermostats === undefined ||
      this.thermostats === null ||
      this.thermostats === undefined
    ) {
      throw new TypeError('The inputs thermostats and ’allThermostats’ are required');
    }
  }

  buildTooltip(): void {
    this.tooltip = '';

    if (
      (this.allThermostats && !this.showIndividualAsWellAsAll) ||
      !this.thermostats ||
      this.thermostats.length === 0
    ) {
      return;
    }

    if (this.overrideLength > 10) {
      this.tooltip = 'See more thermostats';
      return;
    }

    this.thermostats.forEach((thermostat) => {
      this.tooltip += `${thermostat.display_name} \n`;
    });
  }
}
