import { LocalStorageKey } from '@app/shared/interfaces';
import { ChannelLabelMap, Channel } from '@app/shared/models/channel';

export function countWords(str): number {
  const arr = str.split(' ');
  return arr.filter((word) => word !== '').length;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getBoolean(value: any) {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
}

export const retrieveUserFromLocalStorage = () => {
  const userObj = JSON.parse(localStorage.getItem(LocalStorageKey.user) ?? '{}');
  return userObj ? userObj : {};
};

export function decodeToRealUserId(obfuscatedId: string | number, email: string): string | undefined {
  if (!obfuscatedId || !email) {
    return undefined;
  }

  const code = email.charCodeAt(0) + '';
  const xor = parseInt(code, 10).toString(2);
  const actual_id = Number(obfuscatedId) ^ +xor;

  return actual_id + '';
}

export const dedupeArray = (arr: any[]) => Array.from(new Set(arr));

export class Logger {
  /**
   * High contrast log for logs containing information about concrete events
   * @param msg
   */
  static log(...msg) {
    // eslint-disable-next-line no-console
    console.log(`%c ${msg}`, 'background: #230b41; color: #ed3c6a');
  }

  /**
   * Slightly more muted log for logs containing read only information, like that a service initialized
   * @param msg
   */
  static info(...msg) {
    // eslint-disable-next-line no-console
    console.info(`%c ${msg}`, 'background: #f6f0ff; color: #9571c3');
  }

  static error(...msg) {
    // eslint-disable-next-line no-console
    console.error(`%c ${msg}`, 'background: #f6f0ff; color: #9571c3');
  }
}

export function humanize(value: string): string {
  if (!value) {
    return '';
  }

  return value.split('_').map(capitalize).join(' ');
}

export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function getRealUserId(): string | undefined {
  const userObj = JSON.parse(localStorage.getItem(LocalStorageKey.user) as string);

  if (userObj && userObj.id && userObj.email) {
    const code = userObj.email.charCodeAt(0) + '';
    const xor = parseInt(code, 10).toString(2);
    const actual_id = userObj.id ^ +xor;

    return actual_id + '';
  } else {
    return undefined;
  }
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function toPlatformLabel(value: string): string {
  if (!value?.length) {
    return value;
  }

  return ChannelLabelMap[value as Channel] ?? capitalize(value);
}
