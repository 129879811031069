<svg
  [attr.width]="width"
  [attr.height]="height"
  style="margin-top: 2px"
  viewBox="0 0 15 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.57587 10.1621L7.94166 9.39285L6.70204 3.06005C6.35657 1.27742 6.77113 0.500066 8.16926 0.231451V0.207031H6.87681C6.50289 0.394248 5.71035 0.955897 6.15336 3.05598L7.57587 10.1621Z"
    [attr.fill]="color" />
  <path
    d="M6.8443 11.6924L7.20602 10.9272L5.5803 3.01935C5.27547 1.48499 5.40553 0.687282 5.91357 0.231451V0.207031H5.03568C4.76744 0.78089 4.68615 1.35475 4.99504 2.87283L6.8443 11.6924ZM6.09646 13.2186L6.45819 12.4616L4.42603 2.85248C4.06837 1.35882 4.14966 0.675072 4.29597 0.207031H3.51969C3.51159 1.01521 3.61554 1.82059 3.82858 2.60015L6.09646 13.2186ZM2.80844 0.207031H1.89397V0.231451C2.3004 0.727981 2.42233 1.3344 2.74747 2.6734L5.18606 14H5.73474L3.29615 2.63678C3.08547 1.83729 2.92261 1.02594 2.80844 0.207031ZM0.979499 0.207031H0V0.231451C0.894148 0.585534 1.33309 1.26521 1.68669 2.85655L4.09682 13.9959H4.64551L2.21911 2.78736C1.80049 0.841939 1.36154 0.483786 0.979499 0.207031ZM13.4407 0.207031C12.2864 0.882638 11.5995 2.1321 10.1608 5.22932L10.1974 5.24967C11.8597 2.1321 12.8066 0.890778 14.9526 0.243661V0.207031H13.4407ZM10.0795 5.16827L10.1161 5.19269C11.4817 1.97338 11.8393 1.07392 12.3961 0.231451V0.207031H11.2907V0.239591C11.4045 1.52976 10.9736 2.71817 10.0795 5.16827Z"
    [attr.fill]="color" />
</svg>
