import { Component, Input, OnInit } from '@angular/core';
import { Video } from '../../../modules/support-documentation/models/support-documentation';

@Component({
  standalone: false,
  selector: 'sbnb-video-card-grid',
  templateUrl: './video-card-grid.component.html',
  styleUrls: ['./video-card-grid.component.scss'],
})
export class VideoCardGridComponent implements OnInit {
  @Input() videos: Video[];

  constructor() { }

  ngOnInit(): void { }
}
