import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SegmentEvent } from '@app/shared/interfaces';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CopilotService } from '../../../services/copilot/copilot.service';
import { PusherService } from '../../../services/pusher/pusher.service';
import { DialogDeleteGenericComponent } from '../../dialog-delete-generic/dialog-delete-generic.component';
import { CopilotProgress } from '../models/copilot.interface';

enum CopilotFeedbackRating {
  LIKE = 'liked',
  DISLIKE = 'disliked',
}

interface ConversationItem {
  question: string;
  response?: string;
  streamedResponse?: string;
  isGenerating: boolean;
  loadingMessage: string;
  error?: boolean;
  feedback?: CopilotFeedbackRating;
  uuid?: string; // For tracking which message received feedback
}

interface CopilotMessage {
  id: number;
  uuid: string;
  role: 'user' | 'assistant';
  content: string;
  created_at: string;
}

interface CopilotConversation {
  id: number;
  uuid: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface CopilotConversationDetail extends CopilotConversation {
  messages: CopilotMessage[];
}

@Component({
  selector: 'app-copilot-modal',
  templateUrl: './copilot-modal.component.html',
})
export class CopilotModalComponent implements OnInit, OnDestroy {
  @ViewChild('conversationContainer') conversationContainer: ElementRef;
  conversations$: Observable<{ data: CopilotConversation[] }>;
  conversation: ConversationItem[] = [];
  currentConversation?: CopilotConversationDetail;
  questionControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
  isSubmitting = false;
  isLoading = false;
  questionSuggestions: string[] = [];
  showSuggestions = true;
  private pusherSubscription: Subscription;
  private conversationsSubject = new BehaviorSubject<{ data: CopilotConversation[] }>({ data: [] });
  openDropdownId: string | null = null;
  isCS: boolean;
  debugMode$ = this.csService.debugMode;

  CopilotFeedbackRating = CopilotFeedbackRating;

  constructor(
    private dialogRef: MatDialogRef<CopilotModalComponent>,
    private copilotService: CopilotService,
    private pusherService: PusherService,
    private dialog: MatDialog,
    private segmentIoService: SegmentIoService,
    private csService: InternalCsService
  ) {
    this.conversations$ = this.conversationsSubject.asObservable();
    this.loadConversations();
    this.loadQuestionSuggestions();
  }

  private loadConversations() {
    this.copilotService.getConversations().subscribe((conversations) => {
      // Sort conversations by updated_at in descending order (newest first)
      const sortedConversations = {
        data: [...conversations.data].sort(
          (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        ),
      };
      this.conversationsSubject.next(sortedConversations);
    });
  }

  ngOnInit(): void {
    // Initialize isCS
    this.isCS = this.csService.isCS();

    // Start with a new chat when the modal opens
    this.startNewChat();

    this.pusherSubscription = this.pusherService.copilotProgress.subscribe((progress: CopilotProgress) => {
      const lastItem = this.conversation[this.conversation.length - 1];
      if (lastItem && lastItem.isGenerating) {
        if (progress.status === 'thinking') {
          lastItem.loadingMessage = progress.message;
          lastItem.streamedResponse = progress.message;
        } else if (progress.status === 'done') {
          // Reset the submitting state to enable the ask button
          this.isSubmitting = false;
          lastItem.isGenerating = false;
          lastItem.response = progress.message;
          lastItem.streamedResponse = undefined;

          // When we receive the 'done' status, fetch the updated conversation using the ID from the event
          this.copilotService.getConversationDetail(progress.conversation_id).subscribe({
            next: (response) => {
              this.updateConversationDisplay(response.data);
              // Refresh the conversations list to get the updated description/timestamp
              this.loadConversations();
            },
          });
        } else if (progress.status === 'error') {
          // Reset the submitting state to enable the ask button
          this.isSubmitting = false;
          lastItem.isGenerating = false;
          lastItem.error = true;
          lastItem.streamedResponse = undefined;
        }
      }
    });
  }

  private updateConversationDisplay(conversationDetail: CopilotConversationDetail) {
    this.currentConversation = conversationDetail;
    this.conversation = [];

    // Convert messages object to array and sort by ID
    const messages = Object.values(conversationDetail.messages)
      .filter((message) => message !== null && typeof message === 'object')
      .sort((a, b) => a.id - b.id);

    // Process messages in pairs (user + assistant)
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];

      if (message.role === 'user') {
        // Create new conversation item for user message
        const item: ConversationItem = {
          question: message.content,
          isGenerating: false,
          loadingMessage: '',
        };

        // Look ahead for assistant response
        const assistantMessage = messages[i + 1];
        if (assistantMessage && assistantMessage.role === 'assistant') {
          item.response = assistantMessage.content;
          item.uuid = assistantMessage.uuid || null;
          i++; // Skip the next message since we've processed it
        }

        this.conversation.push(item);
      }
    }

    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    if (this.pusherSubscription) {
      this.pusherSubscription.unsubscribe();
    }
  }

  startNewChat(): void {
    this.conversation = [];
    this.currentConversation = undefined;
    this.questionControl.reset();
    this.showSuggestions = true;
  }

  openConversation(conversation: CopilotConversation): void {
    this.isLoading = true;
    this.conversation = [];

    this.copilotService.getConversationDetail(conversation.uuid).subscribe({
      next: (response) => {
        if (!response || !response.data) {
          this.handleConversationError();
          return;
        }

        this.updateConversationDisplay(response.data);
      },
      error: (error) => {
        console.error('Error loading conversation:', error);
        this.handleConversationError();
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  private handleConversationError(): void {
    this.conversation = [];
    this.currentConversation = undefined;
    this.isLoading = false;
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.questionControl.invalid || this.isSubmitting) {
      return;
    }

    this.showSuggestions = false;
    const question = this.questionControl.value;
    if (!question) {
      return;
    }

    this.isSubmitting = true;
    const conversationItem: ConversationItem = {
      question,
      isGenerating: true,
      loadingMessage: 'Analyzing your question...',
    };
    this.conversation.push(conversationItem);
    this.scrollToBottom();

    this.copilotService.askQuestion(question, this.currentConversation?.uuid).subscribe({
      next: (response) => {
        // Update the current conversation with the response
        this.currentConversation = response.data;
        // Refresh the conversations list to show the new conversation
        this.loadConversations();
        this.scrollToBottom();
      },
      error: (error) => {
        console.error('Error from ask endpoint:', error);
        conversationItem.isGenerating = false;
        conversationItem.response = 'Sorry, there was an error processing your request.';
        console.error('Error asking question:', error);
        this.isSubmitting = false;
      },
    });

    this.questionControl.reset();
  }

  onKeyDown(event: KeyboardEvent): void {
    // If Enter is pressed without shift key, submit the message
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent new line
      this.onSubmit();
    }
  }

  loadQuestionSuggestions(): void {
    this.copilotService.getQuestionSuggestions().subscribe({
      next: (response) => {
        this.questionSuggestions = response.data;
      },
      error: (error) => {
        console.error('Error loading question suggestions:', error);
        this.questionSuggestions = [];
      },
    });
  }

  selectSuggestion(suggestion: string): void {
    this.questionControl.setValue(suggestion);
    this.onSubmit();
  }

  deleteConversation(event: Event, conversation: CopilotConversation): void {
    event.stopPropagation();
    this.closeDropdown();

    const dialogRef = this.dialog.open(DialogDeleteGenericComponent, {
      width: '400px',
      data: {
        name: `"${conversation.description}"`,
        action: '',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.confirmDelete(event, conversation);
      }
    });
  }

  confirmDelete(event: Event, conversation: CopilotConversation | null): void {
    if (!conversation) {
      return;
    }

    this.copilotService.deleteConversation(conversation.uuid).subscribe({
      next: (response) => {
        this.conversationsSubject.next(response);

        // If the deleted conversation was the current one, reset the view
        if (this.currentConversation?.uuid === conversation.uuid) {
          this.currentConversation = undefined;
          this.conversation = [];
        }
      },
      error: (error) => {
        console.error('Error deleting conversation:', error);
      },
    });
  }

  toggleDropdown(event: Event, conversationUuid: string): void {
    event.stopPropagation();

    event.preventDefault();

    const currentlyOpenDropdown = this.openDropdownId;

    this.openDropdownId = null;

    if (currentlyOpenDropdown !== conversationUuid) {
      this.openDropdownId = conversationUuid;
    }
  }

  @HostListener('document:click')
  closeDropdown(): void {
    this.openDropdownId = null;
  }

  onFeedbackClick(type: CopilotFeedbackRating, item: ConversationItem, event: MouseEvent): void {
    if (item.feedback) {
      return;
    }

    item.feedback = type;

    const metadata = {
      conversation_uuid: this.currentConversation?.uuid,
      message_uuid: item.uuid,
      question: item.question,
      response: item.response,
      $el: event.target,
    };

    if (type === CopilotFeedbackRating.LIKE) {
      this.segmentIoService.track(SegmentEvent.CopilotMessageLiked, metadata);
    } else {
      this.segmentIoService.track(SegmentEvent.CopilotMessageDisliked, metadata);

      setTimeout(() => {
        if (item.feedback === CopilotFeedbackRating.DISLIKE) {
          this.copilotService.enabled$.subscribe(() => {
            this.segmentIoService.track(SegmentEvent.CopilotSurveyTriggered, metadata);
          });
        }
      }, 500);
    }
  }

  private scrollToBottom(): void {
    // Ensure the container is rendered & the conversation items are updated
    setTimeout(() => {
      if (this.conversationContainer) {
        const element = this.conversationContainer.nativeElement;
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'instant',
        });
      }
    }, 0);
  }
}
