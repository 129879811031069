import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-show-more',
  template: `
    <ng-container *ngIf="length > limit">
      <button
        mat-flat-button
        class="button__text button__small "
        style="background-color: transparent"
        (click)="showMoreItems()">
        <div class="flex items-center" style="gap:6px">
          <span class="">{{ expanded ? 'Show less' : 'Show more' }}</span>
          <mat-icon *ngIf="expanded" class="grass-darker">unfold_less</mat-icon>
          <mat-icon *ngIf="!expanded" class="grass-darker">unfold_more</mat-icon>
        </div>
      </button>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreComponent implements OnInit {
  public expanded = false;
  @Input() length: number;
  @Input() limit = 5;
  @Output() showMore = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void { }

  public showMoreItems() {
    this.expanded = !this.expanded;
    this.showMore.emit(this.expanded ? this.length : this.limit);
  }
}
