import { Pipe, PipeTransform } from '@angular/core';
import { hasConflict, isFullyOverlapping } from '@app/shared/utils';

@Pipe({
  standalone: false,
  name: 'hasOverlapWithOtherReservations',
})
export class HasOverlapWithOtherReservationsPipe implements PipeTransform {
  transform(event: any, otherEvents: any[], checkIfFullyOverlapped = false): boolean {
    // Filter out the current event from the list of other events using its code.
    const filteredEvents = otherEvents.filter((e) => e.code !== event.code);

    const overlappingEvent = filteredEvents.find((otherEvent) => hasConflict(event, otherEvent));

    if (checkIfFullyOverlapped && overlappingEvent) {
      return isFullyOverlapping(event, overlappingEvent);
    }

    return Boolean(overlappingEvent);
  }
}
