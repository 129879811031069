import { Pipe, PipeTransform } from '@angular/core';

const MAP = {
  properties: {
    singular: 'property',
    plural: 'properties',
  },
  listings: {
    singular: 'listing',
    plural: 'listings',
  },
  users: {
    singular: 'user',
    plural: 'users',
  },
  nights: {
    singular: 'night',
    plural: 'nights',
  },
  hours: {
    singular: 'hour',
    plural: 'hours',
  },
  hosts: {
    singular: 'host',
    plural: 'hosts',
  },
  beds: {
    singular: 'bed',
    plural: 'beds',
  },
  baths: {
    singular: 'bath',
    plural: 'baths',
  },
  days: {
    singular: 'day',
    plural: 'days',
  },
};

// Extremely dumb pipe for pluralizing common Hospitable terms
@Pipe({
  standalone: true,
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(value: keyof typeof MAP, count = 1): string {
    if (count === null || typeof count === 'undefined' || isNaN(count) || count === 0) {
      return value;
    }
    return count > 1 ? MAP[value as string].plural : MAP[value as string].singular;
  }
}
