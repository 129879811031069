import { Pipe, PipeTransform } from '@angular/core';
import { NotificationData } from '@app/core/notifications/notifications.service';

@Pipe({
  standalone: false,
  name: 'isUpdatedWithin30Days',
})
export class IsUpdatedWithin30DaysPipe implements PipeTransform {
  transform(value: NotificationData): boolean {
    // Parse the date string into a Date object
    let createdDate = new Date(value.created_at);
    let updatedDate = new Date(value.updated_at);

    // Set the time to the start of the day (00:00:00)
    createdDate = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());
    updatedDate = new Date(updatedDate.getFullYear(), updatedDate.getMonth(), updatedDate.getDate());

    if (createdDate.getDate() === updatedDate.getDate()) {
      return false;
    }

    // Get the date 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    return updatedDate > thirtyDaysAgo;
  }
}
