<span class="text-sm text-night">{{ label }}</span>

<div
  class="flex flex-wrap gap-2"
  [ngClass]="{
    'justify-center': isMobile$ | async
  }">
  <div *ngFor="let thumbnail of filteredThumbnails; let i = index" class="asset-wrapper">
    <img [src]="thumbnail" />
    <div (click)="remove.emit(i)" class="remove-asset">
      <sbnb-close></sbnb-close>
    </div>
  </div>

  @if (filteredThumbnails.length < options.maxFiles) {
    <div class="add-asset-wrapper">
      <ng-picker-overlay [pickerOptions]="options" (uploadSuccess)="uploadSuccess.emit($event)">
        <button>
          Add {{ assetType }}
          <br />
          +
        </button>
      </ng-picker-overlay>
    </div>
  }
</div>
