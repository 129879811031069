import { Node, mergeAttributes } from '@tiptap/core';

export const ShortCodeExtension = Node.create({
  name: 'shortcode',

  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      class: {
        default: 'shortcode',
      },
      text: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.shortcode',
        getAttrs: (dom) => ({ text: dom.innerText }),
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes), node.attrs.text];
  },

  addNodeView() {
    return ({ node }) => {
      const span = document.createElement('span');
      span.classList.add('shortcode');
      span.textContent = node.attrs.text;
      return {
        dom: span,
      };
    };
  },
});
